import React, { useEffect } from "react";
import getBrowserFingerprint from "get-browser-fingerprint";
import { eventTrackingHistory } from "../service/app";

export default function useEventTrackingHistory(eventName: string) {
  const fingerprint = getBrowserFingerprint();

  useEffect(() => {
    if (eventName) {
      eventTrackingHistory({
        fingerprint: fingerprint,
        eventName: eventName,
      });
    }
  }, [eventName]);

  return null;
}
