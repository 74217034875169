// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/assets/img/bg_log-out-popup.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-comment-modal {
  min-width: 400px;
  max-height: 240px;
}
.confirm-comment-modal .ant-modal-content {
  overflow: hidden;
}
.confirm-comment-modal .confirm-comment-modal-icon {
  width: 48px;
  height: 48px;
}
.confirm-comment-modal .bg-icon-circle {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 336px;
  height: 336px;
  color: black;
  position: absolute;
  left: -120px;
  top: -126px;
  overflow: hidden;
}
.confirm-comment-modal .button-default {
  background-color: #fff;
  color: #344054;
}
.confirm-comment-modal .button-danger {
  background-color: #D92D20;
}
.confirm-comment-modal .button-confirm {
  width: 100%;
  height: 44px;
}`, "",{"version":3,"sources":["webpack://./src/components/comment-modal/confirm-review-modal/ConfirmReviewModal.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;AAGE;EACE,yDAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;AADJ;AAKE;EACE,sBAAA;EACA,cAAA;AAHJ;AAME;EACE,yBAAA;AAJJ;AAOE;EACE,WAAA;EACA,YAAA;AALJ","sourcesContent":[".confirm-comment-modal {\n  min-width: 400px;\n  max-height: 240px;\n\n  .ant-modal-content {\n    overflow: hidden;\n  }\n\n  .confirm-comment-modal-icon {\n    width: 48px;\n    height: 48px;\n  }\n\n  .bg-icon-circle {\n    background-image: url('../../../../public/assets/img/bg_log-out-popup.png');\n    width: 336px;\n    height: 336px;\n    color: black;\n    position: absolute;\n    left: -120px;\n    top: -126px;\n    overflow: hidden;\n\n  }\n\n  .button-default {\n    background-color: #fff;\n    color: #344054;\n  }\n\n  .button-danger {\n    background-color: #D92D20;\n  }\n\n  .button-confirm {\n    width: 100%;\n    height: 44px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
