import { ArrowRightOutlined, CheckOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../../config/routes";
import { globalStore } from "../../../../stores/stores";
import {
  addIndustryOfInterest,
  addStudentInterests,
  getIndustryGroup,
  getInterest,
} from "../../services";
import "../RegisterForm/RegisterForm.scss";
import "./RegisterInfo.scss";

interface IIndustry {
  id: number;
  code: string;
  name: string;
  status: boolean;
}

export const RegisterInfo: FunctionComponent = () => {
  const [skillTab, setSkillTab] = useState<number>(1);
  const [selectedIndustries, setSelectedIndustries] = useState<number[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<number[]>([]);
  const [listIndustry, setListIndustry] = useState<IIndustry[]>([]);
  const [totalIndustry, setTotalIndustry] = useState<number>(0);
  const [listInterest, setListInterest] = useState<IIndustry[]>([]);
  const [totalInterest, setTotalInterest] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { registerStep, setRegisterStep } = globalStore();

  const handleItemClick = (item: number) => {
    if (selectedIndustries.includes(item)) {
      setSelectedIndustries(selectedIndustries.filter((i) => i !== item));
    } else {
      setSelectedIndustries([...selectedIndustries, item]);
    }
  };

  const handleItemClickSkill = (item: number) => {
    if (selectedSkills.includes(item)) {
      setSelectedSkills(selectedSkills.filter((i) => i !== item));
    } else {
      setSelectedSkills([...selectedSkills, item]);
    }
  };

  const getListIndustry = async () => {
    setLoading(true);
    try {
      const response = await getIndustryGroup().then((res) => {
        setListIndustry(res?.data?.content);
        setTotalIndustry(res?.data?.totalElements);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const getListInterests = async () => {
    try {
      const response = await getInterest().then((res) => {
        setLoading(false);
        setListInterest(res?.data?.data.data);
        setTotalInterest(res?.data?.data.total._count.id);
      });
    } catch (error) {}
  };

  useEffect(() => {
    getListIndustry();
    getListInterests();
  }, []);

  const handleSkip = () => {
    setSkillTab(skillTab + 1);
  };
  useEffect(() => {
    if (skillTab > 3) {
      navigate(`${routesConfig.welcome}`);
      setRegisterStep(1);
      setSkillTab(1);
    } else if (skillTab === 3) {
      setRegisterStep(3);
    }
  }, [skillTab]);

  const handleChooseRegister = async () => {
    const postUrl =
      skillTab === 1
        ? addIndustryOfInterest({ ids: selectedIndustries })
        : addStudentInterests({ tag_ids: selectedSkills });
    try {
      await postUrl;
      setSkillTab(skillTab + 1);
    } catch (error) {}
  };

  const RenderOption = (item: {
    item: {
      name: string;
      id: number;
    };
    idx: any;
  }) => {
    return (
      <div
        onClick={() => {
          handleItemClick(item.item.id);
        }}
        key={item.idx}
        className={`register-form__content__item ${selectedIndustries.includes(item.item.id) ? "active" : ""}`}
      >
        <span className="text">{item.item.name}</span>
        <span className="icon">
          <CheckOutlined
            className="fontSemiBold"
            style={{ color: "#7F56D9" }}
          />
        </span>
      </div>
    );
  };
  const RenderOptionSkill = (item: {
    item: {
      name: string;
      id: number;
    };
    idx: any;
  }) => {
    return (
      <div
        onClick={() => {
          handleItemClickSkill(item.item.id);
        }}
        key={item.idx}
        className={`register-form__content__item ${selectedSkills.includes(item.item.id) ? "active" : ""}`}
      >
        <span className="text">{item.item.name}</span>
        <span className="icon">
          <CheckOutlined
            className="fontSemiBold"
            twoToneColor="#7F56D9"
            style={{ color: "#7F56D9" }}
          />
        </span>
      </div>
    );
  };

  return (
    <div className="register-interest register-form register-info ">
      <div className="register-form__container">
        <div className="register-form__header">
          <span onClick={handleSkip} className="skip fontSemiBold">
            Bỏ qua{" "}
            <ArrowRightOutlined
              className="fontSemiBold"
              style={{ color: "#7F56D9" }}
            />
          </span>
          <span className="register-title">
            {skillTab === 1
              ? "Chuyên ngành"
              : skillTab === 2
                ? "Sở thích"
                : "Xác thực thông tin tài khoản"}
          </span>
          <span className="register-description">
            {skillTab === 1
              ? "Hãy cùng tìm hiểu những chuyên ngành mà bạn quan tâm nhé!"
              : skillTab === 2
                ? "Hãy chọn sở thích của bạn nhé!"
                : "Xác thực bảo mật để bảo vệ tài khoản của bạn tốt nhất!"}
          </span>
          <span className="choose fontSemiBold">
            {skillTab === 1
              ? `Đã chọn ${selectedIndustries?.length}/${totalIndustry}`
              : skillTab === 2
                ? `Đã chọn ${selectedSkills?.length}/${totalInterest}`
                : null}
          </span>
        </div>

        <div className="register-form__content">
          {loading ? (
            <div className="register-form__loading">
              <Spin />
            </div>
          ) : (
            <>
              {skillTab === 1 ? (
                listIndustry?.map((item: any, index: any) => {
                  return (
                    <RenderOption
                      item={item}
                      idx={`${index} + ${item?.item?.id}`}
                      key={index}
                    />
                  );
                })
              ) : skillTab === 2 ? (
                listInterest?.length > 0 ? (
                  listInterest?.map((item: any, index: number) => {
                    return (
                      <RenderOptionSkill
                        item={item}
                        idx={`${index} + ${item?.item?.id}`}
                        key={index}
                      />
                    );
                  })
                ) : null
              ) : (
                <div className="register-description-txt">
                  Để tài khoản của bạn được bảo mật tốt nhất và sử dụng các tiện
                  ích nhanh chóng Hệ thống đề xuất bạn{" "}
                  <span className="register-description-bold">
                    xác thực thông tin tài khoản
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        <div className="register-form__action">
          {skillTab === 1 || skillTab === 2 ? (
            <Button
              onClick={handleChooseRegister}
              className="next-register-btn"
            >
              <span>Tiếp theo</span>
            </Button>
          ) : (
            <Button
              onClick={() => navigate(`${routesConfig.identifyEkyc}`)}
              className="next-register-btn"
            >
              <span>Xác thực thông tin tài khoản</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
