// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.form-input {
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1 0;
}

.input-header-title::placeholder {
  color: black;
}

.containerImage {
  width: 325px;
  height: 210px;
  margin: 12px 0;
  display: flex; /* Đảm bảo nội dung con được căn giữa */
  justify-content: center; /* Căn giữa theo chiều ngang */
  align-items: center; /* Căn giữa theo chiều dọc */
}

.containerImage img {
  max-width: 100%; /* Giới hạn chiều rộng tối đa của ảnh không vượt quá thẻ cha */
  max-height: 100%; /* Giới hạn chiều cao tối đa của ảnh không vượt quá thẻ cha */
  object-fit: contain; /* Đảm bảo ảnh vừa vặn mà không mất tỉ lệ */
}`, "",{"version":3,"sources":["webpack://./src/pages/my-surveys/components/survey-respond/component/detail-question/question-type-2/detail.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,aAAA;EACA,mBAAA;EACA,WAAA;EACA,SAAA;AAEJ;;AACA;EACI,YAAA;AAEJ;;AACA;EACE,YAAA;EACA,aAAA;EACA,cAAA;EACA,aAAA,EAAA,uCAAA;EACA,uBAAA,EAAA,8BAAA;EACA,mBAAA,EAAA,4BAAA;AAEF;;AACA;EACE,eAAA,EAAA,8DAAA;EACA,gBAAA,EAAA,6DAAA;EACA,mBAAA,EAAA,2CAAA;AAEF","sourcesContent":[".form-input {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    flex: 1 0 0;\n  }\n\n.input-header-title::placeholder{\n    color:black;\n}\n\n.containerImage {\n  width: 325px;\n  height: 210px;\n  margin: 12px 0;\n  display: flex; /* Đảm bảo nội dung con được căn giữa */\n  justify-content: center; /* Căn giữa theo chiều ngang */\n  align-items: center; /* Căn giữa theo chiều dọc */\n}\n\n.containerImage img {\n  max-width: 100%; /* Giới hạn chiều rộng tối đa của ảnh không vượt quá thẻ cha */\n  max-height: 100%; /* Giới hạn chiều cao tối đa của ảnh không vượt quá thẻ cha */\n  object-fit: contain; /* Đảm bảo ảnh vừa vặn mà không mất tỉ lệ */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
