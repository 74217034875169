export const mockPhotoAccept = [
  { name: "Căn cước công dân", img: "cccd.png" },
  { name: "Chứng minh nhân dân", img: "cmtnd.png" },
  { name: "Hộ chiếu", img: "passport.png" },
];
export const mockPhotoInstructor = [
  { name: "Không chụp mờ", img: "opacity_err.png" },
  { name: "Không mất góc, thiếu thông tin", img: "lost_err.png" },
  { name: "Không loá sáng", img: "light_err.png" },
];
export const mockPhotoBefore = [
  { name: "Căn cước công dân", img: "cccd_before.png" , id:"idcard"},
  // { name: "Chứng minh nhân dân", img: "cmtnd_before.png" , id:""},
  { name: "Hộ chiếu", img: "passport_before.png" , id:"passport"},
];
export const mockPhotoAfter = [
  { name: "Căn cước công dân", img: "cccd_after.png" },
  // { name: "Chứng minh nhân dân", img: "cmtnd_after.png" },
  // { name: "Hộ chiếu", img: "passport_after.png" },
];
