// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 576px) {
  .banner-content,
  .right-wrap,
  .statistics-chart-header {
    flex-direction: column;
  }
  .statistics-chart-header {
    gap: 16px;
  }
  .right-wrap {
    gap: 16px !important;
  }
}
@media screen and (max-width: 900px) {
  .right-wrap {
    flex-direction: column;
    gap: 16px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/statistics/style.scss"],"names":[],"mappings":"AAAA;EACE;;;IAGE,sBAAA;EACF;EACA;IACE,SAAA;EACF;EACA;IACE,oBAAA;EACF;AACF;AAEA;EACE;IACE,sBAAA;IACA,oBAAA;EAAF;AACF","sourcesContent":["@media screen and (max-width: 576px) {\n  .banner-content,\n  .right-wrap,\n  .statistics-chart-header {\n    flex-direction: column;\n  }\n  .statistics-chart-header {\n    gap: 16px;\n  }\n  .right-wrap {\n    gap: 16px !important;\n  }\n}\n\n@media screen and (max-width: 900px) {\n  .right-wrap {\n    flex-direction: column;\n    gap: 16px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
