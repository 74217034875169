import { Button, Checkbox, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { CheckboxProps } from "antd/lib";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../../config/routes";
import {
  getReasonDeleteAccount,
  requestDeleteAccount,
} from "../../../../service/personal/personalApi";
import "./AccountDelete.scss";
import AccountDeleteModal from "./components/AccountDeleteModal";

type Props = {
  inAccountInfo?: boolean;
};

interface ReasonDeleteAccount {
  id: number;
  reason: string;
}

const AccountDelete = (props: Props) => {
  const { inAccountInfo } = props;
  const navigate = useNavigate();

  const [chooseReasonAccountDel, setChooseReasonAccountDel] = useState<
    number | undefined
  >(undefined);
  const [reasonAccountDel, setReasonAccountDel] = useState<string>("");
  const [checkSystemPolicy, setCheckSystemPolicy] = useState<boolean>(false);
  const [isDeleteAccount, setIsDeleteAccount] = useState<boolean>(false);
  const [reasonDeleteAccountList, setReasonDeleteAccountList] = useState<
    ReasonDeleteAccount[]
  >([]);

  const handleCheck: CheckboxProps["onChange"] = (e) => {
    setCheckSystemPolicy(e.target.checked);
  };

  const handleChangeReasonDelete = (value: number) => {
    setChooseReasonAccountDel(value);
  };

  const handleConfirmDeleteAccount = async () => {
    const payload = {
      reasonId: chooseReasonAccountDel,
      description: reasonAccountDel,
    };

    try {
      const response = await requestDeleteAccount(payload);

      if (response.status === 200) {
        setIsDeleteAccount(true);
      } else {
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleResetForm = () => {
    setReasonAccountDel("");
    setChooseReasonAccountDel(undefined);
    setCheckSystemPolicy(false);
  };

  useEffect(() => {
    const fetchReasonDeleteAccount = async () => {
      const res = await getReasonDeleteAccount();
      setReasonDeleteAccountList(
        res.data.data.map((it: ReasonDeleteAccount) => ({
          value: it.id,
          label: it.reason,
        }))
      );
    };
    fetchReasonDeleteAccount();
  }, []);

  return (
    <div className="account-delete">
      {inAccountInfo ? (
        <div className="basic-information">
          <div className="text-title-14">Xoá tài khoản</div>
          <div className="text-description-14">
            Việc thực hiện thao tác này sẽ xoá tất cả thông tin tài khoản của
            bạn{" "}
          </div>
        </div>
      ) : null}

      <div className="account-info-card">
        {inAccountInfo ? (
          <div className="account-info-card___item">
            <div className="icon-warnning">
              <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.3">
                  <path
                    d="M6 19C6 11.8203 11.8203 6 19 6C26.1797 6 32 11.8203 32 19C32 26.1797 26.1797 32 19 32C11.8203 32 6 26.1797 6 19Z"
                    stroke="#D92D20"
                    strokeWidth="2"
                  />
                </g>
                <g opacity="0.1">
                  <path
                    d="M1 19C1 9.05888 9.05888 1 19 1C28.9411 1 37 9.05888 37 19C37 28.9411 28.9411 37 19 37C9.05888 37 1 28.9411 1 19Z"
                    stroke="#D92D20"
                    strokeWidth="2"
                  />
                </g>
                <g clipPath="url(#clip0_1143_21436)">
                  <path
                    d="M19.0001 15.667V19.0003M19.0001 22.3337H19.0084M27.3334 19.0003C27.3334 23.6027 23.6025 27.3337 19.0001 27.3337C14.3977 27.3337 10.6667 23.6027 10.6667 19.0003C10.6667 14.398 14.3977 10.667 19.0001 10.667C23.6025 10.667 27.3334 14.398 27.3334 19.0003Z"
                    stroke="#D92D20"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1143_21436">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(9 9)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="text-title-14 mt-1">
              Điều khoản dịch vụ về Xoá tài khoản
            </div>
            <div className="delete-description">
              Bằng việc bạn bấm vào “Xoá tài khoản” bạn đã đồng ý với chúng tôi
              về{" "}
              <span
                className="delete-description-danger"
                onClick={() => navigate(routesConfig.accountDeletionPolicy)}
              >
                Chính sách hệ thống
              </span>{" "}
              và bạn chịu hoàn toàn trách nhiệm về quyết định của mình.
            </div>
          </div>
        ) : null}

        <div className="account-info-card___item">
          <div className="item-title">
            Lý do xoá tài khoản<span className="text-danger"> *</span>
          </div>
          <Select
            style={{ height: 44, width: "100%" }}
            placeholder="Chọn lý do xoá tài khoản"
            onChange={handleChangeReasonDelete}
            options={reasonDeleteAccountList}
            allowClear
            value={chooseReasonAccountDel}
          />
        </div>
        <div className="account-info-card___item">
          <div className="item-title">
            Mô tả lý do xoá khoá tài khoản
            <span className="text-danger"> *</span>
          </div>
          <TextArea
            rows={4}
            placeholder="Nội dung lời nhắn"
            onChange={(e) => setReasonAccountDel(e.target.value)}
            value={reasonAccountDel}
          />
        </div>
        <div className="account-info-card___item">
          <Checkbox onChange={handleCheck} checked={checkSystemPolicy}>
            Tôi đã đọc và đồng ý với{" "}
            <span className="system-policy-txt">chính sách hệ thống</span>
          </Checkbox>
          <div className="flex justify-end mt-4 gap-24">
            {chooseReasonAccountDel !== undefined ||
            reasonAccountDel !== "" ||
            checkSystemPolicy ? (
              <Button
                className="cancel-delete-account"
                onClick={() => {
                  setChooseReasonAccountDel(undefined);
                  setReasonAccountDel("");
                  setCheckSystemPolicy(false);
                }}
              >
                Huỷ bỏ
              </Button>
            ) : null}
            <Button
              className="delete-account"
              disabled={
                chooseReasonAccountDel === undefined ||
                reasonAccountDel === "" ||
                !checkSystemPolicy
              }
              onClick={handleConfirmDeleteAccount}
            >
              Xoá tài khoản
            </Button>
          </div>
        </div>
      </div>
      <AccountDeleteModal
        open={isDeleteAccount}
        handleCancel={() => {
          setIsDeleteAccount(false);
        }}
        handleResetForm={handleResetForm}
      />
    </div>
  );
};

export default AccountDelete;
