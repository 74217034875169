import React from "react";
import Banner from "../../components/banner/Banner";
import "./style.scss";
import ResponsiveList from "../../components/list/ResponsiveList";
import { routesConfig } from "../../config/routes";
import { useNavigate } from "react-router-dom";
import { message, Collapse } from "antd";

const { Panel } = Collapse;

function SupportPage() {
  const navigate = useNavigate();

  const nextIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.16699 9.9974H15.8337M15.8337 9.9974L10.0003 4.16406M15.8337 9.9974L10.0003 15.8307"
        stroke="#6941C6"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  // Custom expand and collapse icons
  const expandIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke="#98A2B3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const collapseIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke="#98A2B3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <div>
      <Banner>
        <section className="center flex-column course-info-banner-content">
          <span className="mb-2 text-violet">Trợ giúp</span>
          <h3 className="mb-3 text-white banner-title">Trợ giúp</h3>
          <span className="mb-4 text-violet">
            Chúng tôi có thể giúp gì cho bạn?
          </span>
        </section>
      </Banner>

      <section className="support-content-page">
        <ResponsiveList
          grid={{
            gutter: 32,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          data={[
            {
              content: (
                <div className="support-item">
                  <div className="sup-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 19.9963H21M3 19.9963H4.67454C5.16372 19.9963 5.40832 19.9963 5.63849 19.9411C5.84256 19.8921 6.03765 19.8113 6.2166 19.7016C6.41843 19.5779 6.59138 19.405 6.93729 19.0591L19.5 6.49632C20.3285 5.66789 20.3285 4.32475 19.5 3.49632C18.6716 2.66789 17.3285 2.66789 16.5 3.49632L3.93726 16.0591C3.59136 16.405 3.4184 16.5779 3.29472 16.7797C3.18506 16.9587 3.10425 17.1538 3.05526 17.3579C3 17.588 3 17.8326 3 18.3218V19.9963Z"
                        stroke="#344054"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <span className="font-size-20 mt-2">
                    <b>Đăng ký tài khoản </b>
                  </span>
                  <div
                    className="span text-primary flex align-center gap-12 mt-2 pointer"
                    onClick={() =>
                      navigate(routesConfig.supportAccountRegister)
                    }
                  >
                    <b>Xem hướng dẫn</b>
                    {nextIcon}
                  </div>
                </div>
              ),
            },
            {
              content: (
                <div className="support-item">
                  <div className="sup-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M16 8.00227V4.50292C16 3.67119 16 3.25532 15.8248 2.99974C15.6717 2.77645 15.4346 2.62477 15.1678 2.57935C14.8623 2.52737 14.4847 2.70164 13.7295 3.05019L4.85901 7.14426C4.18551 7.45511 3.84875 7.61054 3.60211 7.85159C3.38406 8.06469 3.21762 8.32482 3.1155 8.6121C3 8.93706 3 9.30795 3 10.0497V15.0023M16.5 14.5023H16.51M3 11.2023L3 17.8023C3 18.9224 3 19.4824 3.21799 19.9102C3.40973 20.2866 3.71569 20.5925 4.09202 20.7843C4.51984 21.0023 5.07989 21.0023 6.2 21.0023H17.8C18.9201 21.0023 19.4802 21.0023 19.908 20.7843C20.2843 20.5925 20.5903 20.2866 20.782 19.9102C21 19.4824 21 18.9224 21 17.8023V11.2023C21 10.0822 21 9.52211 20.782 9.09429C20.5903 8.71797 20.2843 8.412 19.908 8.22026C19.4802 8.00227 18.9201 8.00227 17.8 8.00227L6.2 8.00227C5.0799 8.00227 4.51984 8.00227 4.09202 8.22026C3.7157 8.412 3.40973 8.71796 3.21799 9.09429C3 9.52211 3 10.0822 3 11.2023ZM17 14.5023C17 14.7784 16.7761 15.0023 16.5 15.0023C16.2239 15.0023 16 14.7784 16 14.5023C16 14.2261 16.2239 14.0023 16.5 14.0023C16.7761 14.0023 17 14.2261 17 14.5023Z"
                        stroke="#344054"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <span className="font-size-20 mt-2">
                    <b>Thanh toán và hoá đơn </b>
                  </span>
                  <div
                    className="span text-primary flex align-center gap-12 mt-2 pointer"
                    onClick={() => navigate(routesConfig.supportDetail)}
                  >
                    <b>Xem hướng dẫn</b>
                    {nextIcon}
                  </div>
                </div>
              ),
            },
            {
              content: (
                <div className="support-item">
                  <div className="sup-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M9 9H9.01M15 15H15.01M16 8L8 16M9.5 9C9.5 9.27614 9.27614 9.5 9 9.5C8.72386 9.5 8.5 9.27614 8.5 9C8.5 8.72386 8.72386 8.5 9 8.5C9.27614 8.5 9.5 8.72386 9.5 9ZM15.5 15C15.5 15.2761 15.2761 15.5 15 15.5C14.7239 15.5 14.5 15.2761 14.5 15C14.5 14.7239 14.7239 14.5 15 14.5C15.2761 14.5 15.5 14.7239 15.5 15ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                        stroke="#344054"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <span className="font-size-20 mt-2">
                    <b>Khuyến mãi</b>
                  </span>
                  <div
                    className="span text-primary flex align-center gap-12 mt-2 pointer"
                    onClick={() =>
                      message.info("Nội dung đang được phát triển")
                    }
                  >
                    <b>Xem hướng dẫn</b>
                    {nextIcon}
                  </div>
                </div>
              ),
            },
            {
              content: (
                <div className="support-item">
                  <div className="sup-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M17 14.5035V11.4979C17 11.3184 17 11.2287 16.9727 11.1495C16.9485 11.0794 16.9091 11.0156 16.8572 10.9626C16.7986 10.9028 16.7183 10.8626 16.5578 10.7824L12 8.50348M4 9.50348V16.3101C4 16.682 4 16.868 4.05802 17.0308C4.10931 17.1747 4.1929 17.305 4.30238 17.4116C4.42622 17.5322 4.59527 17.6096 4.93335 17.7646L11.3334 20.6979C11.5786 20.8103 11.7012 20.8665 11.8289 20.8887C11.9421 20.9084 12.0579 20.9084 12.1711 20.8887C12.2988 20.8665 12.4214 20.8103 12.6666 20.6979L19.0666 17.7646C19.4047 17.6096 19.5738 17.5322 19.6976 17.4116C19.8071 17.305 19.8907 17.1747 19.942 17.0308C20 16.868 20 16.682 20 16.3101V9.50348M2 8.50348L11.6422 3.68237C11.7734 3.61678 11.839 3.58398 11.9078 3.57107C11.9687 3.55964 12.0313 3.55964 12.0922 3.57107C12.161 3.58398 12.2266 3.61678 12.3578 3.68237L22 8.50348L12.3578 13.3246C12.2266 13.3902 12.161 13.423 12.0922 13.4359C12.0313 13.4473 11.9687 13.4473 11.9078 13.4359C11.839 13.423 11.7734 13.3902 11.6422 13.3246L2 8.50348Z"
                        stroke="#344054"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <span className="font-size-20 mt-2">
                    <b>Học bổng</b>
                  </span>
                  <div
                    onClick={() =>
                      message.info("Nội dung đang được phát triển")
                    }
                    className="span text-primary flex align-center gap-12 mt-2 pointer"
                  >
                    <b>Xem hướng dẫn</b>
                    {nextIcon}
                  </div>
                </div>
              ),
            },
            {
              content: (
                <div className="support-item">
                  <div className="sup-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M6.5 20H5C3.89543 20 3 19.1046 3 18V4C3 2.89543 3.89543 2 5 2H19C20.1046 2 21 2.89543 21 4V18C21 19.1046 20.1046 20 19 20H17.5M12 19C13.6569 19 15 17.6569 15 16C15 14.3431 13.6569 13 12 13C10.3431 13 9 14.3431 9 16C9 17.6569 10.3431 19 12 19ZM12 19L12.0214 18.9998L8.82867 22.1926L6.00024 19.3641L9.01965 16.3447M12 19L15.1928 22.1926L18.0212 19.3641L15.0018 16.3447M9 6H15M7 9.5H17"
                        stroke="#344054"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <span className="font-size-20 mt-2">
                    <b>Chứng nhận/ chứng chỉ </b>
                  </span>
                  <div
                    onClick={() =>
                      message.info("Nội dung đang được phát triển")
                    }
                    className="span text-primary flex align-center gap-12 mt-2 pointer"
                  >
                    <b>Xem hướng dẫn</b>
                    {nextIcon}
                  </div>
                </div>
              ),
            },
            {
              content: (
                <div className="support-item">
                  <div className="sup-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M22 17V13C22 7.47715 17.5228 3 12 3C6.47715 3 2 7.47715 2 13V17M7.5 21C6.11929 21 5 19.8807 5 18.5V15.5C5 14.1193 6.11929 13 7.5 13C8.88071 13 10 14.1193 10 15.5V18.5C10 19.8807 8.88071 21 7.5 21ZM16.5 21C15.1193 21 14 19.8807 14 18.5V15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V18.5C19 19.8807 17.8807 21 16.5 21Z"
                        stroke="#344054"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <span className="font-size-20 mt-2">
                    <b>Xử lý sự cố </b>
                  </span>
                  <div
                    className="span text-primary flex align-center gap-12 mt-2 pointer"
                    onClick={() =>
                      message.info("Nội dung đang được phát triển")
                    }
                  >
                    <b>Xem hướng dẫn</b>
                    {nextIcon}
                  </div>
                </div>
              ),
            },
            {
              content: (
                <div className="support-item">
                  <div className="sup-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.2827 3.45649C11.5131 2.98955 11.6284 2.75608 11.7848 2.68149C11.9209 2.61659 12.0791 2.61659 12.2152 2.68149C12.3717 2.75608 12.4869 2.98955 12.7174 3.45649L14.9041 7.88646C14.9721 8.02431 15.0061 8.09323 15.0558 8.14675C15.0999 8.19413 15.1527 8.23252 15.2113 8.2598C15.2776 8.2906 15.3536 8.30171 15.5057 8.32395L20.397 9.03888C20.9121 9.11416 21.1696 9.15181 21.2888 9.27761C21.3925 9.38707 21.4412 9.53747 21.4215 9.68695C21.3988 9.85875 21.2124 10.0404 20.8395 10.4036L17.3014 13.8496C17.1912 13.957 17.136 14.0107 17.1004 14.0746C17.0689 14.1312 17.0487 14.1934 17.0409 14.2577C17.0321 14.3303 17.0451 14.4062 17.0711 14.5579L17.906 19.4253C17.994 19.9387 18.038 20.1953 17.9553 20.3477C17.8833 20.4802 17.7554 20.5732 17.6071 20.6006C17.4366 20.6322 17.2061 20.511 16.7451 20.2686L12.3724 17.969C12.2361 17.8974 12.168 17.8615 12.0962 17.8475C12.0327 17.835 11.9673 17.835 11.9038 17.8475C11.832 17.8615 11.7639 17.8974 11.6277 17.969L7.25492 20.2686C6.79392 20.511 6.56341 20.6322 6.39297 20.6006C6.24468 20.5732 6.11672 20.4802 6.04474 20.3477C5.962 20.1953 6.00603 19.9387 6.09407 19.4253L6.92889 14.5579C6.95491 14.4062 6.96793 14.3303 6.95912 14.2577C6.95132 14.1934 6.93111 14.1312 6.89961 14.0746C6.86402 14.0107 6.80888 13.957 6.69859 13.8496L3.16056 10.4036C2.78766 10.0404 2.60121 9.85875 2.57853 9.68695C2.55879 9.53747 2.60755 9.38707 2.71125 9.27761C2.83044 9.15181 3.08797 9.11416 3.60304 9.03888L8.49431 8.32395C8.64642 8.30171 8.72248 8.2906 8.78872 8.2598C8.84736 8.23252 8.90016 8.19413 8.94419 8.14675C8.99391 8.09323 9.02793 8.02431 9.09597 7.88646L11.2827 3.45649Z"
                        stroke="#344054"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <span className="font-size-20 mt-2">
                    <b>Kiểm tra và đánh giá </b>
                  </span>
                  <div
                    onClick={() =>
                      message.info("Nội dung đang được phát triển")
                    }
                    className="span text-primary flex align-center gap-12 mt-2 pointer"
                  >
                    <b>Xem hướng dẫn</b>
                    {nextIcon}
                  </div>
                </div>
              ),
            },
          ]}
        />
      </section>

      {/* FAQ Section with Collapse and Custom Icons */}
      <section className="content-layout ph-96 flex quest-ans">
        <div>
          <p className="font-size-36">
            <b>Những câu hỏi thường gặp</b>
          </p>
          <p className="text-secondary font-size-18 line-height-28 mt-2">
            Nếu bạn không tìm thấy câu trả lời cho vấn đề của bạn. Vui lòng liên
            hệ với chúng tôi:{" "}
            <span className="phone-contact-support">+84.868.706.680</span>
          </p>
        </div>
        <div style={{ width: "100%" }}>
          <Collapse
            accordion
            expandIcon={({ isActive }) =>
              isActive ? collapseIcon : expandIcon
            }
            expandIconPosition="right"
            className="custom-collapse"
          >
            <Panel header="Làm sao để ghi danh khoá học?" key="1">
              <p className="text-secondary font-size-16 mt-2 line-height-24">
                Để ghi danh khoá học, bạn vui lòng nhấn vào nút “ghi danh ngay”
                để thực hiện ghi danh vào khoá học.
              </p>
            </Panel>
            <Panel
              header="Các bài kiểm tra của tôi sẽ được đánh giá như thế nào?"
              key="2"
            >
              <p className="text-secondary font-size-16 mt-2 line-height-24">
                Các bài kiểm tra sẽ được đánh giá theo hệ thống chấm điểm tiêu
                chuẩn của khoá học.
              </p>
            </Panel>
            <Panel
              header="Các bài kiểm tra của tôi sẽ được đánh giá như thế nào?"
              key="3"
            >
              <p className="text-secondary font-size-16 mt-2 line-height-24">
                Các bài kiểm tra của bạn sẽ được đánh giá dựa trên tiêu chí được
                công bố.
              </p>
            </Panel>
            <Panel
              header="Các bài kiểm tra của tôi sẽ được đánh giá như thế nào?"
              key="4"
            >
              <p className="text-secondary font-size-16 mt-2 line-height-24">
                Các tiêu chuẩn đánh giá sẽ được công bố trên hệ thống và email
                thông báo.
              </p>
            </Panel>
          </Collapse>
        </div>
      </section>
    </div>
  );
}

export default SupportPage;
