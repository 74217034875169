// import PropTypes from 'prop-types'
import type { FC, ReactElement } from 'react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthStore } from "../../stores/stores";

interface AuthGuardProps {
  children: ReactElement<any, any>
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props
  const location = useLocation()
  const navigate = useNavigate()
  const accessToken = useAuthStore((state) => state.accessToken);
  const [checked, setChecked] = useState(false)

  useEffect(
    () => {
      // Check first
      if (!accessToken) {
        navigate('/login', {
          state: { returnUrl: location.pathname }
        })
      } else {
        setChecked(true)
      }
    },
    [accessToken, location]
  )

  if (!checked) {
    return null
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return children
}
