import { Button, ConfigProvider, Form, Select } from "antd";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomRangePicker from "../../../components/custom-range-picker/CustomRangePicker";
import FormInput from "../../../components/form-input/FormInput";
import ConfirmModal from "../../../components/modal/confirm-modal/ConfirmModal";
import SecondaryTab from "../../../components/tabs/secondary-tab/SecondaryTab";
import {
  DEFAULT_PAGE_NUM,
  DEFAULT_PAGE_SIZE,
  MY_COURSE_SORT,
} from "../../../constants";
import {
  deleteNote,
  getNoteHistory,
  getNoteList,
  getNoteListPinned,
  getSectionByCourse,
  getSequenceBySection,
  getUnitBySequence,
} from "../../../service/learning-course";
import { globalStore } from "../../../stores/stores";
import { MyNote, NoteHistoryType } from "../../../types/myNote";
import { filterOption } from "../../../utils/element";
import { notifyError, notifySuccess } from "../../../utils/notification";
import { viLocale } from "../../../utils/picker";
import "./MyNotes.scss";
import NoteHistory from "./components/note-history/NoteHistory";
import NoteList from "./components/note-list/NoteList";

type Props = {
  tab: string;
};
interface FormValues {
  industryName?: string;
  uuid?: string;
  registrationForm?: number;
  registeredDate?: [dayjs.Dayjs, dayjs.Dayjs];
  keyword?: string;
  sortByName?: number;
  unit?: number;
  section?: number;
  sequence?: number;
}

const MyNotes = (props: Props) => {
  const { id } = useParams();
  const { tab } = props;
  const { reload, setReload } = globalStore();
  const [form] = Form.useForm();
  const sectionId = form.getFieldValue("section");
  const sequenceId = form.getFieldValue("sequence");

  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [pageNum, setPageNum] = useState<number>(DEFAULT_PAGE_NUM);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [pageSizeHistory, setPageSizeHistory] = useState<number>(10);
  const [pageNumHistory, setPageNumHistory] =
    useState<number>(DEFAULT_PAGE_NUM);
  const [totalPageHistory, setTotalPageHistory] = useState<number>(0);

  const [myNoteList, setMyNoteList] = useState<MyNote[]>([]);
  const [noteHistory, setNoteHistory] = useState<NoteHistoryType[]>([]);
  const [totalNotePinned, setTotalNotePinned] = useState<number>(0);
  const [myPinnedNoteList, setMyPinnedNoteList] = useState<MyNote[]>([]);
  const [isToggle, setIsToggle] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDelNote, setIsDelNote] = useState<boolean>(false);
  const [selectedNotes, setSelectedNotes] = useState<number[]>([]);
  const [sections, setSections] = useState([]);
  const [sequences, setSequences] = useState([]);
  const [units, setUnits] = useState([]);

  const fetchMyNoteList = async (values: FormValues = {}) => {
    setIsLoading(true);
    const payload = {
      courseId: Number(id),
      page: pageNum,
      size: pageSize,
      keyword: values.keyword?.trim() || undefined,
      unitId: values.unit || undefined,
      sectionId: values.section || undefined,
      sequenceId: values.sequence || undefined,
      sortByName:
        values?.sortByName === 1
          ? "asc"
          : values?.sortByName === 2
            ? "desc"
            : undefined,
      sortByCreateDate:
        values?.sortByName === 3
          ? "desc"
          : values?.sortByName === 4
            ? "asc"
            : undefined,
      createdFromDate: values?.registeredDate?.[0]
        ? dayjs(values?.registeredDate[0]).endOf("day").toISOString()
        : undefined,
      createdToDate: values?.registeredDate?.[1]
        ? dayjs(values?.registeredDate[1]).endOf("day").toISOString()
        : undefined,
    };
    try {
      const response = await getNoteList(payload);

      const myCourseList = response.data.data.notes;
      setMyNoteList(myCourseList);
      setTotalPage(response.data?.data.total);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchMyNotePinnedList = async (values: FormValues = {}) => {
    const payload = {
      courseId: Number(id),
      page: pageNum,
      size: pageSize,
      keyword: values.keyword?.trim() || undefined,
      unitId: values.unit || undefined,
      sectionId: values.section || undefined,
      sequenceId: values.sequence || undefined,
      sortByName:
        values?.sortByName === 1
          ? "asc"
          : values?.sortByName === 2
            ? "desc"
            : undefined,

      sortByCreateDate:
        values?.sortByName === 3
          ? "desc"
          : values?.sortByName === 4
            ? "asc"
            : undefined,
      createdFromDate: values?.registeredDate?.[0]
        ? dayjs(values?.registeredDate[0]).endOf("day").toISOString()
        : undefined,
      createdToDate: values?.registeredDate?.[1]
        ? dayjs(values?.registeredDate[1]).endOf("day").toISOString()
        : undefined,
    };
    try {
      const response = await getNoteListPinned(payload);
      const { data } = response.data;
      const pinnedNoteList = data;
      setMyPinnedNoteList(pinnedNoteList.notes);
      setTotalNotePinned(pinnedNoteList.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchNoteHistoryList = async (values: FormValues = {}) => {
    setIsLoading(true);
    const payload = {
      courseId: Number(id),
      page: pageNumHistory,
      size: pageSizeHistory,
      keyword: values.keyword?.trim() || undefined,
      unitId: values.unit || undefined,
      sectionId: values.section || undefined,
      sequenceId: values.sequence || undefined,
      sortByName:
        values?.sortByName === 1
          ? "asc"
          : values?.sortByName === 2
            ? "desc"
            : undefined,
      sortByCreateDate:
        values?.sortByName === 3
          ? "desc"
          : values?.sortByName === 4
            ? "asc"
            : undefined,
      createdFromDate: values?.registeredDate?.[0]
        ? dayjs(values?.registeredDate[0]).endOf("day").toISOString()
        : undefined,
      createdToDate: values?.registeredDate?.[1]
        ? dayjs(values?.registeredDate[1]).endOf("day").toISOString()
        : undefined,
    };
    try {
      const noteHistory = await getNoteHistory(payload);

      setNoteHistory(noteHistory?.data.data.notes);
      setTotalPageHistory(noteHistory?.data.data.total);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (tab === "4") {
      form.setFieldsValue({ sortByName: 3 });
      fetchMyNoteList(form.getFieldsValue());
      fetchMyNotePinnedList(form.getFieldsValue());
    }
  }, [pageSize, pageNum, reload, tab]);

  useEffect(() => {
    if (tab === "4") {
      fetchNoteHistoryList(form.getFieldsValue());
    }
  }, [pageSizeHistory, pageNumHistory, reload, tab]);

  const debouncedFetchMyCourseList = debounce((values: FormValues) => {
    fetchMyNoteList(values);
    fetchMyNotePinnedList(values);
  }, 300);
  const onValuesChange = (changedValues: any, allValues: FormValues) => {
    debouncedFetchMyCourseList(allValues);
    setPageNum(1);
    setPageNumHistory(1);
  };
  useEffect(() => {
    const fetchSectionByCourse = async () => {
      try {
        const res = await getSectionByCourse(Number(id));
        setSections(
          res.data.map((it: any) => ({
            value: it.id,
            label: it.name,
          }))
        );
        form.setFieldsValue({ sequence: undefined, unit: undefined });
      } catch (error) {}
    };
    fetchSectionByCourse();
  }, []);

  useEffect(() => {
    const fetchSequenceBySection = async () => {
      try {
        const res = await getSequenceBySection(Number(sectionId));
        setSequences(
          res.data.map((it: any) => ({
            value: it.id,
            label: it.name,
          }))
        );
        form.setFieldsValue({ unit: undefined });
      } catch (error) {}
    };
    if (sectionId) {
      {
        fetchSequenceBySection();
      }
    }
  }, [sectionId]);
  useEffect(() => {
    const fetchUnitBySequence = async () => {
      try {
        const res = await getUnitBySequence(Number(sequenceId), false);
        setUnits(
          res.data.data.map((it: any) => ({
            value: it.id,
            label: it.name,
          }))
        );
      } catch (error) {}
    };
    if (sectionId && sequenceId) {
      fetchUnitBySequence();
    }
  }, [sequenceId]);

  const handleSelectAll = () => {
    const currentNoteIds = [...myPinnedNoteList, ...myNoteList].map(
      (note: any) => note.id
    );
    const allSelected = currentNoteIds.every((id) =>
      selectedNotes.includes(id)
    );
    if (allSelected) {
      setSelectedNotes((prev) =>
        prev.filter((id) => !currentNoteIds.includes(id))
      );
    } else {
      setSelectedNotes((prev) => [
        ...prev,
        ...currentNoteIds.filter((id) => !prev.includes(id)),
      ]);
    }
  };

  const handleCheck = (id: number, checked: boolean) => {
    setSelectedNotes((prev) => {
      if (checked) {
        return [...prev, id];
      } else {
        return prev.filter((noteId) => noteId !== id);
      }
    });
  };

  const handleDelNote = async () => {
    try {
      const res = await deleteNote({
        ids: selectedNotes,
      });
      notifySuccess("Xoá ghi chú thành công");

      setReload(!reload);
      setIsDelNote(false);
    } catch (error) {
      notifyError("Có lỗi xảy ra vui lòng thử lại sau");
    }
  };

  const RenderAdvanceSearch = () => (
    <div className="advanced-filtering-field">
      <div className="item registered-date">
        <span className="title">Thời gian</span>
        <Form.Item name="registeredDate">
          <ConfigProvider locale={viLocale}>
            <CustomRangePicker
              value={form.getFieldValue("registeredDate") || null}
              onChangeRangePicker={(dates) => {
                form.setFieldsValue({ registeredDate: dates });
                fetchMyNoteList(form.getFieldsValue());
                fetchMyNotePinnedList(form.getFieldsValue());
              }}
              placeholder="Chọn thời gian"
            />
          </ConfigProvider>
        </Form.Item>
      </div>
      <div className="item">
        <span className="title">Chương</span>
        <Form.Item name="section">
          <Select
            showSearch
            className="btn-filter"
            style={{ width: "100%", height: 44 }}
            filterOption={filterOption}
            allowClear
            placeholder="Chọn chương"
            notFoundContent={<span>Không có kết quả</span>}
            options={sections}
          />
        </Form.Item>
      </div>
      <div className="item">
        <span className="title">Bài</span>
        <Form.Item name="sequence">
          <Select
            showSearch
            className="btn-filter"
            style={{ width: "100%", height: 44 }}
            filterOption={filterOption}
            allowClear
            placeholder="Chọn bài"
            notFoundContent={<span>Không có kết quả</span>}
            disabled={!sectionId}
            options={sequences}
          />
        </Form.Item>
      </div>
      <div className="item">
        <span className="title">Học liệu</span>
        <Form.Item name="unit">
          <Select
            className="btn-filter"
            style={{ width: "100%", height: 44 }}
            placeholder="Chọn học liệu"
            allowClear
            disabled={!sequenceId || (sequenceId && !sectionId)}
            options={units}
          />
        </Form.Item>
      </div>

      <div className="item">
        <div className="title">&nbsp;</div>
        <div
          className="text-clear-filters"
          onClick={() => {
            form.resetFields();
            fetchMyNoteList(form.getFieldsValue());
            fetchMyNotePinnedList(form.getFieldsValue());
          }}
        >
          Xóa bộ lọc
        </div>
      </div>
    </div>
  );

  const tabItems = [
    {
      key: "1",
      label: (
        <div>
          Toàn bộ học liệu{" "}
          <span className="total-notes">{totalPage + totalNotePinned}</span>
        </div>
      ),
      children: (
        <NoteList
          myNoteList={myNoteList}
          myPinnedNoteList={myPinnedNoteList}
          isLoading={isLoading}
          totalPage={totalPage}
          pageSize={pageSize}
          pageNum={pageNum}
          setPageSize={setPageSize}
          setPageNum={setPageNum}
          selectedNotes={selectedNotes}
          handleCheck={handleCheck}
        />
      ),
    },
    {
      key: "2",
      label: "Lịch sử ghi chú",
      children: (
        <NoteHistory
          noteHistory={noteHistory}
          totalPage={totalPageHistory}
          pageSize={pageSizeHistory}
          pageNum={pageNumHistory}
          setPageSize={setPageSizeHistory}
          setPageNum={setPageNumHistory}
          isLoading={isLoading}
        />
      ),
    },
  ];

  return (
    <div className="my-notes">
      <Form form={form} onValuesChange={onValuesChange}>
        <div className="flex justify-between align-center gap-16">
          <div>
            <div className="text-title-18">Ghi chú của bạn</div>
            <div className="my-notes-des">
              Tổng hợp các ghi chú của bạn trong học liệu
            </div>
          </div>
          <div className="my-notes-search">
            <Form.Item name="keyword">
              <FormInput
                placeholder="Nhập chủ đề cần tìm"
                prefixIcon={
                  <img
                    src="/assets/icons/search-lg.svg"
                    alt="search"
                    className="search-icon"
                  />
                }
              />
            </Form.Item>
            {selectedNotes?.length > 0 ? (
              <>
                <Button className="select-all-btn" onClick={handleSelectAll}>
                  Chọn tất cả
                </Button>
                <Button
                  className="delete-btn"
                  disabled={selectedNotes.length === 0}
                  onClick={() => setIsDelNote(true)}
                >
                  Xoá
                </Button>
              </>
            ) : (
              <div className="flex gap-8">
                <Button
                  className="advanced-filtering"
                  onClick={() => setIsToggle(!isToggle)}
                >
                  <img
                    src="/assets/icons/filter-lines.svg"
                    alt="search"
                    className="search-icon"
                  />
                  Bộ lọc nâng cao
                </Button>
                <Form.Item name="sortByName">
                  <Select
                    defaultValue={3}
                    style={{
                      width: 145,
                      height: 44,
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "24px",
                      textAlign: "left",
                    }}
                    options={[...MY_COURSE_SORT]}
                  />
                </Form.Item>
              </div>
            )}
          </div>
        </div>
        {isToggle && <RenderAdvanceSearch />}
      </Form>
      <div className="mt-3">
        <SecondaryTab tabItems={tabItems} />
      </div>
      <ConfirmModal
        open={isDelNote}
        handleOk={handleDelNote}
        handleCancel={() => {
          setIsDelNote(false);
        }}
        title="Xác nhận xoá ghi chú"
        confirmIcon={`${process.env.PUBLIC_URL}/assets/icons/delete-icon.svg`}
        content={`Ghi chú đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá ghi chú?`}
      />
    </div>
  );
};

export default MyNotes;
