import React, { useState } from "react";
import {
  globalStore,
  useAuthStore,
  useCourseDetailStore,
  useCurriculumStore,
} from "../../../stores/stores";
import { Button } from "antd";
import { routesConfig } from "../../../config/routes";
import { useNavigate, useParams } from "react-router-dom";
import {
  savedCourse,
  unsaveCourse,
} from "../../../service/saved-course/saved-course-service";
import { notifyError, notifySuccess } from "../../../utils/notification";
import { HeartOutlined } from "@ant-design/icons";
import "./CourseTagAction.scss";
import ConfirmModal from "../../../components/modal/confirm-modal/ConfirmModal";
import { postCourseEnrollment } from "../../../service/courses";
import { BUY_COURSE } from "../../../constants";
import { getStartLearningCourse } from "../../../service/my-course/my-course-service";

type Props = {};

const CourseTagAction = (props: Props) => {
  const { screen } = useCourseDetailStore();
  const accessToken = useAuthStore((state) => state.accessToken);
  const navigate = useNavigate();
  const { id } = useParams();
  const { reload, setReload } = globalStore();
  const { detail } =
    screen === "course" ? useCourseDetailStore() : useCurriculumStore();

  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [isLoginRequired, setIsLoginRequired] = useState<boolean>(false);

  const handleEnrollCourse = () => {
    if (accessToken) {
      if (detail?.cost === 0) {
        setIsConfirm(true);
      } else {
        navigate(`${routesConfig.courseEnrollment}/${id}`);
      }
    } else {
      setIsLoginRequired(true);
    }
  };

  const handleSaveCourse = async () => {
    if (accessToken) {
      const apiUrl = detail?.isSaved ? unsaveCourse : savedCourse;
      try {
        const response = await apiUrl(Number(id));
        if (response?.data.codeStatus === 200) {
          setReload(!reload);
          notifySuccess(
            detail?.isSaved
              ? "Bỏ Lưu khoá học thành công"
              : "Lưu khoá học thành công"
          );
        }
      } catch (error) {}
    } else {
      setIsLoginRequired(true);
    }
  };
  const startLearningCourse = async () => {
    if (detail?.learningStatus === 1) {
      try {
        getStartLearningCourse(Number(id));
        navigate(`${routesConfig.learningCourseStructure}/${id}`);
      } catch (error) {}
    } else {
      navigate(`${routesConfig.learningCourseStructure}/${id}`);
    }
  };

  const handleConfirmEnroll = async () => {
    const payload = {
      courseId: id,
      registrationForm: BUY_COURSE,
    };
    try {
      const res = await postCourseEnrollment(payload);
      if (res.status === 200) {
        setReload(!reload);
      } else {
      }
    } catch (error) {
      notifyError((error as any)?.response?.data?.message);
    }
  };
  return (
    <div>
      <div className="course-evaluation-tag-action">
        {detail?.isRegistered ? (
          <Button className="continue-learning" onClick={startLearningCourse}>
            {detail?.completedPercentage === 0
              ? "Bắt đầu học"
              : " Tiếp tục học"}
          </Button>
        ) : (
          <>
            <Button className="enroll-now" onClick={handleEnrollCourse}>
              Ghi danh khoá học
            </Button>
            <Button
              className={detail?.isSaved ? "saved-course" : "save-course"}
              onClick={handleSaveCourse}
            >
              <HeartOutlined style={{ fontSize: "20px" }} />
            </Button>
          </>
        )}
      </div>
      <ConfirmModal
        open={isConfirm}
        handleOk={handleConfirmEnroll}
        handleCancel={() => setIsConfirm(false)}
        title="Xác nhận ghi danh khoá học"
        content={`Bạn xác nhận muốn ghi danh khoá học ${detail?.name}`}
      />
      <ConfirmModal
        open={isLoginRequired}
        handleOk={() => navigate(routesConfig.login)}
        handleCancel={() => setIsLoginRequired(false)}
        title="Yêu cầu đăng nhập"
        content={`Bạn cần đăng nhập để sử dụng tính năng này`}
        confirmIcon={`${process.env.PUBLIC_URL}/assets/icons/info-circle-border.svg`}
      />
    </div>
  );
};

export default CourseTagAction;
