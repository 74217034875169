import { Menu, MenuProps } from "antd";
import React, { useState } from "react";
import { scrollToElement } from "../../../utils/element";
import "./CourseTab.scss";

type Props = {
  menuItem?: any;
};

const CourseTab = (props: Props) => {
  const { menuItem } = props;
  const [currentMenu, setCurrentMenu] = useState("introduction");

  const handleChangeMenu: MenuProps["onClick"] = (e) => {
    setCurrentMenu(e.key);
    scrollToElement(e.key);
  };
  return (
    <div className="course-tab">
      <Menu
        onClick={(e) => handleChangeMenu(e)}
        selectedKeys={[currentMenu]}
        mode="horizontal"
        items={menuItem}
      />
    </div>
  );
};

export default CourseTab;
