import { KeyValue } from "../types/app";

export const DEFAULT_TIME_GET_OTP = 60;
export const MIN_LENGTH_PASSWORD = 8;
export const MAX_LENGTH_PASSWORD = 25;
export const DEFAULT_CHANGE_PASSWORD_TIMEOUT_TO_LOGIN = 5000

export const COURSE_TABS = [
  { label: "Thiết kế Website", value: 1 },
  { label: "Thiết kế đồ hoạ", value: 2 },
  { label: "Công cụ thiết kế", value: 3 },
  { label: "Thiết kế Game", value: 4 },
  { label: "Thiết kế nội thất", value: 5 },
  { label: "Thiết kế thời trang", value: 6 },
  { label: "Thiết kế thời trang", value: 7 },
  { label: "Thiết kế thời trang", value: 8 },
  { label: "Thiết kế thời trang", value: 9 },
  { label: "Thiết kế thời trang", value: 10 },
  { label: "Thiết kế thời trang", value: 11 },
  { label: "Thiết kế thời trang", value: 12 },
  { label: "Thiết kế thời trang", value: 13 },
];

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NUM = 1;
export const PAID_VALUE_FILTER = 0; // option trả phí
export const DEFAULT_DRAGABLE_OPACITY = 1;
export const MAX_FILE_UPLOAD_SIZE = 2097152;

export const FILTER_SORT: KeyValue[] = [
  {
    label: "A - Z",
    value: 1,
  },
  {
    label: "Z - A",
    value: 2,
  },
  {
    label: "Mới xuất bản",
    value: 3,
  },
  {
    label: "Cũ đến mới",
    value: 4,
  },
];

export const SORT_NOTIFICATIONS: KeyValue[] = [
  {
    label: "Mặc định",
    value: 0,
  },
  {
    label: "Mới nhất",
    value: 1,
  },
  {
    label: "Cũ nhất",
    value: 2,
  },
];

export const FILTER_STAR_ARR = [
  { value: 4.5 },
  { value: 4 },
  { value: 3 },
  { value: 2 },
  { value: -2 },
];

export const FREE_OPTIONS = [{ value: 1 }, { value: 0 }];
export const COURSE_SCHEDULE_TYPE = [{ value: true }, { value: false }];
export const COURSE_TYPE = [{ value: 3 }, { value: 1 }];
export const CURRICULUM_SCHEDULE_TYPE = [{ value: 1 }, { value: 0 }];

export const DEFAULT_LOADING_TIME = 500;

export const FILTER_SORT_NAME: KeyValue[] = [
  {
    label: "Sắp xếp theo",
    value: 0,
  },
  {
    label: "A - Z",
    value: 1,
  },
  {
    label: "Z - A",
    value: 2,
  },
];

export const REGISTRATION_METHOD_FILTER: KeyValue[] = [
  {
    label: "Học trước",
    value: 1,
  },
  {
    label: "Học thử",
    value: 3,
  },
  {
    label: "Đã mua",
    value: 2,
  },
];

export const MY_COURSE_SORT: KeyValue[] = [
  {
    label: "Mới nhất",
    value: 3,
  },
  {
    label: "Cũ nhất",
    value: 4,
  },
  {
    label: "A - Z",
    value: 1,
  },
  {
    label: "Z - A",
    value: 2,
  },
];

// register course
export const PRE_LEARNING = 1;
export const BUY_COURSE = 2;
export const TRIAL_LEARNING = 3;

//unit type

//Discussion
export const LIKE_REACTION = 1;
export const DISLIKE_REACTION = 2;

export const TEST_STATUS = [
  {
    label: "Đã làm",
    value: 1,
  },
  {
    label: "Chưa làm",
    value: 2,
  },
  {
    label: "Sắp đến hạn nộp",
    value: 3,
  },
];

export const TEST_RESULT = [
  {
    label: "Đạt",
    value: 1,
  },
  {
    label: "Chưa đạt",
    value: 2,
  },
  {
    label: "Chưa có kết quả",
    value: 3,
  },
  {
    label: "Khác",
    value: 4,
  },
];

export const DOCUMENT_TYPE = [
  {
    label: "Tất cả",
    value: 0,
  },
  {
    label: "PDF",
    value: 1,
  },
  {
    label: "Video",
    value: 2,
  },
  {
    label: "Âm thanh",
    value: 3,
  },
];

export const CLASS_SCHEDULE = 0
export const EXERCISE = 1
export const TEST = 2
export const EXAM = 3
export const EXTRA_CLASS_SCHEDULE = 4

export const UNIT_VIEW = 1
export const UNIT_SHARE = 2
export const UNIT_DOWNLOAD = 3

/// icon của tài liệu tham khảo

export const REFERENCE_PDF = 1
export const REFERENCE_MP4 = 2
export const REFERENCE_MP3 = 3