// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar-upload .avatar-choose-upload {
  width: 105px;
  height: 105px;
  border-radius: 50%;
  border: 4px solid #FFF;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  position: relative;
}
.avatar-upload .icon-edit-upload-avat {
  position: absolute;
  right: 0px;
  bottom: -12px;
}

.upload-avatar-modal .modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upload-avatar-modal .modal-content h2 {
  margin-bottom: 20px;
}
.upload-avatar-modal .modal-content .ant-slider {
  margin: 10px 0;
}
.upload-avatar-modal .upload-avatar-content {
  width: 100%;
}
.upload-avatar-modal .upload-avatar-content canvas {
  width: 100% !important;
  height: 100% !important;
}

.avatar-editor {
  border-radius: 50% !important;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/profile-document/components/AvatarUploader/AvatarUpload.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,8FAAA;EACA,kBAAA;AACJ;AAEE;EACE,kBAAA;EACA,UAAA;EACA,aAAA;AAAJ;;AAKE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAFJ;AAII;EACE,mBAAA;AAFN;AAKI;EACE,cAAA;AAHN;AAOE;EACE,WAAA;AALJ;AAOI;EACE,sBAAA;EACA,uBAAA;AALN;;AAYA;EACE,6BAAA;EACA,gBAAA;AATF","sourcesContent":[".avatar-upload {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  .avatar-choose-upload {\n    width: 105px;\n    height: 105px;\n    border-radius: 50%;\n    border: 4px solid #FFF;\n    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);\n    position: relative;\n  }\n\n  .icon-edit-upload-avat {\n    position: absolute;\n    right: 0px;\n    bottom: -12px;\n  }\n}\n\n.upload-avatar-modal {\n  .modal-content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    h2 {\n      margin-bottom: 20px;\n    }\n\n    .ant-slider {\n      margin: 10px 0;\n    }\n  }\n\n  .upload-avatar-content {\n    width: 100%;\n\n    canvas {\n      width: 100% !important;\n      height: 100% !important;\n\n    }\n\n  }\n}\n\n.avatar-editor {\n  border-radius: 50% !important;\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
