import { FunctionComponent, useEffect, useState } from "react";
import "./login-page.scss";

import { useNavigate } from "react-router-dom";
import LoadingFullScreen from "../../../../components/loading-full-screen/LoadingFullScreen";
import { routesConfig } from "../../../../config/routes";
import { useAuthStore } from "../../../../stores/stores";
import { LoginCard } from "../login-card/LoginCard";

const LoginPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCountTimeLeft, setIsCountTimeLeft] = useState(false);
  const [timeLeftLocate, setTimeLeftLocate] = useState(0);
  const [spinning, setSpinning] = useState(false);
  const accessToken = useAuthStore((state) => state.accessToken);

  useEffect(() => {
    if (accessToken) {
      setSpinning(true);
      setTimeout(() => {
        navigate(routesConfig.homePage);
        setSpinning(false);
      }, 500);
    }
  }, [accessToken]);

  useEffect(() => {
    if (timeLeftLocate > 0) {
      const intervalId = setInterval(() => {
        setTimeLeftLocate((timeLeft) => timeLeft - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    } else if (timeLeftLocate === 0) {
      setSpinning(true);
      setTimeout(() => {
        setSpinning(false);
        backToLogin("update-success");
        handleAddClassShow("login-card");
      }, 1000);
    }
  }, [timeLeftLocate]);

  useEffect(() => {
    if (isCountTimeLeft && timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    } else if (timeLeft === 0) {
      setIsCountTimeLeft(false);
    }
  }, [isCountTimeLeft, timeLeft]);

  const backToLogin = (id: string) => {
    var element = document.getElementById(id);
    element?.classList.remove("inactive");
    element?.classList.remove("active");
  };
  const handleAddClassShow = (id: string) => {
    var element = document.getElementById(id);
    element?.classList.add("active");
    element?.classList.remove("inactive");
  };

  return (
    <div className="login-text">
      <div className="secondary-button">
        <div className="login-card">
          {LoginCard(
            loadingLogin,
            setLoadingLogin
          )}
        </div>
        {spinning && <LoadingFullScreen spinning={spinning} />}
      </div>
    </div>
  );
};

export default LoginPage;
