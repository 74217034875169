import React, { useEffect, useState } from 'react';
import { Divider, Input, Radio, Select } from 'antd';
import "./detail.scss";
import { useSurveyRespond } from '../../../../../../../stores/stores';

const { Option } = Select;

const QuestionType4 = (props: any) => {
  const questionAnswer = props.questionAnswer;
  const { updateAnswer } = useSurveyRespond();
  const [selectedOption, setSelectedOption] = useState<number | null>(questionAnswer);

  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  
  const [maxWidth, setMaxWidth] = useState(0);
  const handleSelectChange = (value: any) => {
    setSelectedOption(value);
    setIsOtherSelected(false);
  };

  const handleOtherSelected = () => {
    setIsOtherSelected(true);
    setSelectedOption(null);
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }

  useEffect(() => {
    const tempDiv = document.createElement('div');
    tempDiv.style.display = 'inline-block';
    tempDiv.style.visibility = 'hidden';
    document.body.appendChild(tempDiv);

    let maxWidthTemp = 0;

    props.listOptions.forEach((option: any) => {
      tempDiv.innerText = option.answerText;
      maxWidthTemp = Math.max(maxWidthTemp, tempDiv.offsetWidth);
    });

    setMaxWidth(maxWidthTemp);
    document.body.removeChild(tempDiv);
  }, [props.listOptions]);

  useEffect(() => {
    if (typeof questionAnswer === "number") {
      setSelectedOption(questionAnswer);
    } else if (typeof questionAnswer === "string") {
      setIsOtherSelected(true);
      setSelectedOption(null);
      setInputValue(questionAnswer);
    }
  }, []);

  useEffect(() => {
    updateAnswer(props.surveyId, props.sectionId, props.questionId, selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    updateAnswer(props.surveyId, props.sectionId, props.questionId, inputValue)
  }, [inputValue]);

  return (
    <div>
      <Select 
        placeholder="Lựa chọn đáp án"
        dropdownStyle={{ width: maxWidth + 32}}
        style={{ width: "100%", marginBottom: "15px" }}
        onChange={handleSelectChange}
        value={selectedOption}
        showSearch
        filterOption={(input, option) =>
          ((option?.children as unknown) as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={props.typeSurvey === "2"}
      >
        {props.listOptions.map((option: any) => (
          !option.isOther && (
          <Option key={option.answerId} value={option.answerId}>
            {option.answerText}
          </Option>
          )
        ))}
      </Select>
      {
        props.listOptions.map((Option: any) => (
          Option.isOther ? (
            <>
              <Radio
                checked={isOtherSelected}
                onChange={handleOtherSelected}
                disabled={props.typeSurvey === "2"}
              >
                Khác
              </Radio>
              {isOtherSelected && (
                <>
                  <Input
                    placeholder="Vui lòng nhập..."
                    value={inputValue}
                    onChange={handleChangeInput}
                    style={{ marginTop: "12px", marginBottom: "12px" }}
                    disabled={props.typeSurvey === "2"}
                  />
                </>
              )}
              {!isOtherSelected && <Divider style={{ margin: "12px 0" }} />}
            </>
          ) : null
        ))
      }
    </div>
  );
};

export default QuestionType4;

