import { FunctionComponent, useEffect } from "react";
import "./pagination.scss";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Pagination } from "antd";
import { useViewport } from "../../hooks/useViewport";

interface CustomPaginationProps {
  totalPage?: number;
  pageSize: number;
  pageNumber: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

const CustomPagination: FunctionComponent<CustomPaginationProps> = ({
  pageSize,
  pageNumber,
  setPageSize,
  setPageNumber,
  totalPage,
}) => {
  const { width } = useViewport();
  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };
  useEffect(() => {
    setPageNumber(1);
  }, [pageSize]);
  return (
    <div className="pagination">
      <Pagination
        itemRender={(current, type, originalElement) =>
          pagingRender(current, type, originalElement, width)
        }
        current={pageNumber}
        total={totalPage}
        pageSize={pageSize}
        onChange={handlePageChange}
        onShowSizeChange={handlePageSizeChange}
        showSizeChanger
        pageSizeOptions={["5", "10", "20", "50"]}
        locale={{
          items_per_page: "/ trang",
          jump_to: "Đi đến trang",
          page: "",
        }}
      />
    </div>
  );
};

export default CustomPagination;
const pagingRender = (
  current: number,
  type: string,
  originalElement: React.ReactNode,
  width: number
) => {
  if (type === "prev") {
    return (
      <div className="pagination-arrow">
        <ArrowLeftOutlined className="pagination-arrow-icon" />{" "}
        {width > 576 && "Trang trước"}
      </div>
    );
  }
  if (type === "next") {
    return (
      <div className="pagination-arrow">
        {width > 576 && "Trang sau"}
        <ArrowRightOutlined className="pagination-arrow-icon" />
      </div>
    );
  }
  return originalElement;
};
