import React, { useState } from "react";

type Props = {
  setVoteProps: (value: number) => void;
  star?: number;
};

export default function VoteStar(props: Props) {
  const { setVoteProps, star } = props;
  const [vote, setVotePoint] = useState(star || 5);

  const votePoint = (value: number) => {
    setVotePoint(value);
    setVoteProps(value);
  };
  console.log("star 123 ===========", star, vote);

  return (
    <div className="flex">
      {[1, 2, 3, 4, 5].map((item, index) => (
        <div key={index} onClick={() => votePoint(item)}>
          <img
            onClick={() => votePoint(item)}
            className={`avatar-star ml-1 cursor-pointer ${index + 1 > vote ? "star-gray" : ""}`}
            src={`${process.env.PUBLIC_URL}/assets/icons/star.svg`}
          />
        </div>
      ))}
    </div>
  );
}
