import React, { useEffect, useState } from "react";
import TableReport from "../../components/TableReport";
import { Table, TableProps } from "antd";
import {
  BaseChartProps,
  ChartDataProps,
  StatisticChartPayload,
  StatisticChartResponseData,
} from "../../../../../types/statistic";
import {
  fetchStarRatingExam,
  fetchStudentClassFilter,
} from "../../../../../service/statistics";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { getPercent, mapToDataChart } from "../../components/utils";

interface DataType {
  key: string;
  criteria: number;
  one: number;
  two: number;
  three: number;
  four: number;
  five: number;
}

interface ClassFilterProps {
  id: number;
  name: string;
}

function RateByClass({ filterValues }: BaseChartProps) {
  const [data, setData] = useState<StatisticChartResponseData>();
  const [classFilter, setClassFilter] = useState<ClassFilterProps[]>([]);
  const [classFilterSelected, setClassFilterSelected] = useState<number>()

  useEffect(() => {
    getClassFilter();
  }, []);

  const getClassFilter = async () => {
    const res = await fetchStudentClassFilter();
    setClassFilter(res.data.data);
  };

  const dataTable = mapToDataChart(data?.statistic as ChartDataProps[]).map(
    (item: any, index) => ({
      key: `${index}`,
      criteria: item?.criteria,
      one: item["1 sao"],
      two: item["2 sao"],
      three: item["3 sao"],
      four: item["4 sao"],
      five: item["5 sao"],
    })
  );

  useEffect(() => {
    if (!isEmpty(filterValues)) {
      getData({
        timeUnit: filterValues?.unit || "day",
        from: filterValues?.date?.length
          ? dayjs(filterValues?.date[0]).toISOString()
          : undefined,
        to: filterValues?.date?.length
          ? dayjs(filterValues?.date[1]).toISOString()
          : undefined,
        courseIds: filterValues?.course,
        courseLevelIds: filterValues?.level,
        industryGroupIds: filterValues?.department,
        classId: classFilterSelected
      });
    }
  }, [filterValues, classFilterSelected]);

  const getData = async (payload: StatisticChartPayload) => {
    const res = await fetchStarRatingExam(payload);
    setData(res.data?.data);
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Nội dung",
      dataIndex: "criteria",
      key: "criteria",
      render: (text) => <span className="font-weight-5">{text}</span>,
    },
    {
      title: "1 sao",
      dataIndex: "one",
      key: "one",
      render: (value, record) => {
        return (
          <div>
            <p>{value}</p>
            <p className="text-secondary font-size-14">{`(${getPercent(value, record)}%)`}</p>
          </div>
        );
      },
    },
    {
      title: "2 sao",
      dataIndex: "two",
      key: "two",
      render: (value, record) => {
        return (
          <div>
            <p>{value}</p>
            <p className="text-secondary font-size-14">{`(${getPercent(value, record)}%)`}</p>
          </div>
        );
      },
    },
    {
      title: "3 sao",
      key: "three",
      dataIndex: "three",
      render: (value, record) => {
        return (
          <div>
            <p>{value}</p>
            <p className="text-secondary font-size-14">{`(${getPercent(value, record)}%)`}</p>
          </div>
        );
      },
    },
    {
      title: "4 sao",
      key: "four",
      dataIndex: "four",
      render: (value, record) => {
        return (
          <div>
            <p>{value}</p>
            <p className="text-secondary font-size-14">{`(${getPercent(value, record)}%)`}</p>
          </div>
        );
      },
    },
    {
      title: "5 sao",
      key: "five",
      dataIndex: "five",
      render: (value, record) => {
        return (
          <div>
            <p>{value}</p>
            <p className="text-secondary font-size-14">{`(${getPercent(value, record)}%)`}</p>
          </div>
        );
      },
    },
  ];

  return (
    <TableReport
      data={dataTable}
      columns={columns}
      filterOptions={classFilter.map((item: ClassFilterProps) => ({
        label: item.name,
        value: item.id,
      }))}
      showFilter
      title="Tỉ lệ kết quả làm đánh giá theo lớp"
      onChangeFilter={setClassFilterSelected}
      summary={(pageData) => {
        let totalOne = 0;
        let totalTwo = 0;
        let totalThree = 0;
        let totalFour = 0;
        let totalFive = 0;

        pageData.forEach(
          ({ one = 0, two = 0, three = 0, four = 0, five = 0 }) => {
            totalOne += one;
            totalTwo += two;
            totalThree += three;
            totalFour += four;
            totalFive += five;
          }
        );
        const total = totalOne + totalTwo + totalThree + totalFour + totalFive;

        return (
          <Table.Summary.Row className="text-primary font-weight-5 sticky-summary-row">
            <Table.Summary.Cell index={0}>Tổng</Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              <div>
                {totalOne}
                <p className="text-secondary">{`(${getPercent(totalOne, total)}%)`}</p>
              </div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              <div>
                {totalTwo}
                <p className="text-secondary">{`(${getPercent(totalTwo, total)}%)`}</p>
              </div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              <div>
                {totalThree}
                <p className="text-secondary">{`(${getPercent(totalThree, total)}%)`}</p>
              </div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4}>
              <div>
                {totalFour}
                <p className="text-secondary">{`(${getPercent(totalFour, total)}%)`}</p>
              </div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5}>
              <div>
                {totalFive}
                <p className="text-secondary">{`(${getPercent(totalFive, total)}%)`}</p>
              </div>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
    />
  );
}

export default RateByClass;
