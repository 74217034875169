import React, { useEffect, useState } from "react";
import TableReport from "../../components/TableReport";
import { Table, TableProps } from "antd";
import {
  BaseChartProps,
  ChartDataProps,
  StatisticChartPayload,
  StatisticChartResponseData,
} from "../../../../../types/statistic";
import { fetchRateClassAction } from "../../../../../service/statistics";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { mapToDataChart } from "../../components/utils";

interface DataType {
  key: string;
  name: number;
  material: number;
  document: number;
  exam: number;
  scorm: number;
  multimedia: number;
}

function ActionClass({ filterValues }: BaseChartProps) {
  const [data, setData] = useState<StatisticChartResponseData>();

  const dataTable = mapToDataChart(data?.statistic as ChartDataProps[]).map(
    (item: any, index) => ({
      key: `${index}`,
      name: item?.criteria,
      document: item["Tài liệu tham khảo"],
      exam: item["Bài tập/Bài kiểm tra/Bài thi"],
      scorm: item["Scorm & xAPI"],
      multimedia: item["Đa phương tiện"],
      material: item["Học liệu"],
    })
  );

  useEffect(() => {
    
    if (!isEmpty(filterValues)) {
      getData({
        timeUnit: filterValues?.unit || "day",
        from: filterValues?.date?.length
          ? dayjs(filterValues?.date[0]).toISOString()
          : undefined,
        to: filterValues?.date?.length
          ? dayjs(filterValues?.date[1]).toISOString()
          : undefined,
        courseIds: filterValues?.course,
        courseLevelIds: filterValues?.level,
        industryGroupIds: filterValues?.department,
      });
    }
  }, [filterValues]);

  const getData = async (payload: StatisticChartPayload) => {
    const res = await fetchRateClassAction(payload);
    setData(res.data?.data);
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Lớp",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="font-weight-5">{text}</span>,
    },
    {
      title: "Học liệu",
      dataIndex: "material",
      key: "material",
    },
    {
      title: "Tài liệu tham khảo",
      dataIndex: "document",
      key: "document",
    },
    {
      title: "Bài tập/Bài kiểm tra/Bài thi",
      key: "exam",
      dataIndex: "exam",
    },
    {
      title: "Scorm & xAPI",
      key: "scorm",
      dataIndex: "scorm",
    },
    {
      title: "Đa phương tiện",
      key: "multimedia",
      dataIndex: "multimedia",
    },
    {
      title: "Tổng",
      key: "total",
      dataIndex: "total",
      render(value, record, index) {
        const {
          document = 0,
          exam = 0,
          material = 0,
          scorm = 0,
          multimedia = 0,
        } = record;

        return (
          <span className="font-size-14 font-weight-5 text-primary">
            {document + exam + material + scorm + multimedia}
          </span>
        );
      },
    },
  ];

  return (
    <TableReport
      data={dataTable}
      columns={columns}
      title="Theo lớp"
      onChangeFilter={(v) => console.log(v)}
      summary={(pageData) => {
        let totalMaterial = 0;
        let totalDocument = 0;
        let totalExam = 0;
        let totalScorm = 0;
        let totalMedia = 0;

        pageData.forEach(
          ({
            document = 0,
            exam = 0,
            material = 0,
            scorm = 0,
            multimedia = 0,
          }) => {
            totalMaterial += material;
            totalDocument += document;
            totalExam += exam;
            totalScorm += scorm;
            totalMedia += multimedia;
          }
        );

        return (
          <Table.Summary.Row className="text-primary font-weight-5 sticky-summary-row">
            <Table.Summary.Cell index={0}>Tổng</Table.Summary.Cell>
            <Table.Summary.Cell index={1}>{totalMaterial}</Table.Summary.Cell>
            <Table.Summary.Cell index={2}>{totalDocument}</Table.Summary.Cell>
            <Table.Summary.Cell index={3}>{totalExam}</Table.Summary.Cell>
            <Table.Summary.Cell index={4}>{totalScorm}</Table.Summary.Cell>
            <Table.Summary.Cell index={4}>{totalMedia}</Table.Summary.Cell>
            <Table.Summary.Cell index={5}>
              {totalMaterial +
                totalDocument +
                totalExam +
                totalScorm +
                totalMedia}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
    />
  );
}

export default ActionClass;
