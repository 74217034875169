import { Empty, Image } from "antd";
import React from "react";
export function renderStarRating(rating: number) {
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    stars.push(
      <Image
        preview={false}
        key={i}
        className={`start-icon ${i > rating ? "star-gray" : ""}`}
        src={`${process.env.PUBLIC_URL}/assets/icons/star.svg`}
        alt="card-img"
      />
    );
  }

  return stars;
}

export const getTimeAgo = (input: Date | string): string => {
  let date: Date;
  if (input instanceof Date) {
    date = input;
  } else {
    date = new Date(input);
  }

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date provided");
  }
  const now = new Date();
  const diffInSeconds = (now.getTime() - date.getTime()) / 1000;

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInWeek = 604800;
  const secondsInMonth = 2628000;
  const secondsInYear = 31536000;

  if (diffInSeconds < 60) {
    return `Vừa xong`;
  } else if (diffInSeconds < secondsInMinute) {
    const seconds = Math.floor(diffInSeconds);
    return `${seconds} giây trước`;
  } else if (diffInSeconds < secondsInHour) {
    const minutes = Math.floor(diffInSeconds / secondsInMinute);
    return `${minutes} phút trước`;
  } else if (diffInSeconds < secondsInDay) {
    const hours = Math.floor(diffInSeconds / secondsInHour);
    return `${hours} giờ trước`;
  } else if (diffInSeconds < secondsInWeek) {
    const days = Math.floor(diffInSeconds / secondsInDay);
    return `${days} ngày trước`;
  } else if (diffInSeconds < secondsInMonth) {
    const weeks = Math.floor(diffInSeconds / secondsInWeek);
    return `${weeks} tuần trước`;
  } else if (diffInSeconds < secondsInYear) {
    const months = Math.floor(diffInSeconds / secondsInMonth);
    return `${months} tháng trước`;
  } else {
    const years = Math.floor(diffInSeconds / secondsInYear);
    return `${years} năm trước`;
  }
};

try {
} catch (error) {
  console.error();
}

export const tableLocale = {
  emptyText: <Empty description="Không có kết quả"></Empty>,
  triggerDesc: "Sắp xếp theo thứ tự Z-A",
  triggerAsc: "Sắp xếp thứ tự A-Z",
  cancelSort: "Huỷ sắp xếp",
};
