export const triggerReloadChatbot = () => {
  const iframe = document.getElementById("dify-chatbot-bubble-window");
  const chatbotIcon = document.getElementById("dify-chatbot-bubble-button");
  const scriptConfig = document.getElementById("difyChatbotConfig");
  const chatMessageIframe = document.getElementById(
    "dify-chatbot-bubble-window"
  );
  if (chatMessageIframe) {
    chatMessageIframe.remove();
  }
  if (iframe) {
    iframe.remove();
  }
  if (scriptConfig) {
    scriptConfig.remove();
  }
  if (chatbotIcon) {
    chatbotIcon.remove();
  }
};
