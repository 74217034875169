// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.university-card {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 16px;
  cursor: pointer;
  transition: all 333ms ease-in-out;
}
.university-card:hover {
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}
.university-card .university-card-logo {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  aspect-ratio: 1.3/1;
}`, "",{"version":3,"sources":["webpack://./src/components/card/university/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;EACA,iCAAA;AACF;AACE;EACE,8FACE;AAAN;AAIE;EACE,WAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;AAFJ","sourcesContent":[".university-card {\n  width: 100%;\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 20px;\n  border-radius: 16px;\n  cursor: pointer;\n  transition: all 333ms ease-in-out;\n \n  &:hover {\n    box-shadow:\n      0px 20px 24px -4px rgba(16, 24, 40, 0.08),\n      0px 8px 8px -4px rgba(16, 24, 40, 0.03);\n  }\n\n  .university-card-logo {\n    width: 100%;\n    border-radius: 10px;\n    object-fit: cover;\n    aspect-ratio: 1.3/1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
