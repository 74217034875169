import { Button, Form, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import VoteStar from "../../components/vote-star";
import { isEmpty } from "lodash";
import {
  createCommentReview,
  editCourseCommentReview,
} from "../../service/training-school";
import "./index.scss";
import { useCourseDetailStore } from "../../stores/stores";

type Props = {
  open: boolean;
  title: string;
  mode?: string;
  id?: string;
  comment?: string;
  star?: string;
  enterpriseUuid?: string;
  handleCancel: (value: any) => void;
  handleOk: () => void;
};

const ReviewCourseModal = (props: Props) => {
  const {
    open,
    title,
    handleCancel,
    handleOk,
    mode,
    enterpriseUuid,
    comment,
    star,
    id,
  } = props;
  const [voteProps, setVoteProps] = useState(5);
  const [idReview, setIdReview] = useState(0);
  const [enterpriseUuidProps, setEnterpriseUuidProps] = useState<any>("");
  const [form] = Form.useForm();
  const { setScreen } = useCourseDetailStore();

  const validations = {
    review: {
      required: true,
      message: "Vui lòng nhập đánh giá của bạn",
    },
  };

  useEffect(() => {
    if (mode === "edit") {
      form.setFieldValue("review", comment);
      setVoteProps(Number(star));
      setIdReview(Number(id));
      setEnterpriseUuidProps(enterpriseUuid);
    } else form.resetFields();
  }, [open, enterpriseUuid, star, id, mode]);

  useEffect(() => {
    setScreen("school");
  }, []);
  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        if (name === "review-course-modal") {
          if (mode !== "edit") {
            const data = {
              enterpriseUuid: enterpriseUuid,
              star: voteProps,
              comment: values.review,
            };
            await createCommentReview(data).then((res) => {
              if (res.status === 200) {
                handleOk();
              }
            });
          } else {
            const data = {
              id: idReview,
              enterpriseUuid: enterpriseUuidProps,
              star: voteProps,
              comment: values.review,
            };
            await editCourseCommentReview(data).then((res) => {
              if (res.status === 200) {
                handleOk();
              }
            });
          }
        }
      }}
    >
      <Form form={form} name="review-course-modal" style={{ maxWidth: 600 }}>
        <Modal
          title={title}
          open={open}
          className="review-course-modal"
          maskClosable={false}
          // onOk={handleOk}
          onCancel={handleCancel}
          centered
          footer={() => (
            <>
              <Button onClick={handleCancel} className="cancel-message-btn">
                Huỷ
              </Button>
              <Button
                onClick={() => form.submit()}
                className="send-message-btn"
              >
                {!isEmpty(mode) && mode === "edit"
                  ? "Cập nhật"
                  : "Gửi nhận xét"}
              </Button>
            </>
          )}
        >
          <div>
            <div className="hr-tag"></div>
            <p className="fs-14 ml-2">Nhận xét của bạn về trường</p>
            <div className="comment-modal-rating">
              <Form.Item rules={[validations.review]} name="review">
                <TextArea rows={4} />
              </Form.Item>
              <div className="hr-tag"></div>
              <div className="flex">
                <p className="mr-3">Đánh giá của bạn</p>
                <VoteStar setVoteProps={setVoteProps} />
              </div>
            </div>
          </div>
        </Modal>
      </Form>
    </Form.Provider>
  );
};

export default ReviewCourseModal;
