import { Outlet, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import Header from "../header/Header";
import { useViewport } from "../../hooks/useViewport";
import { menuItems } from "../sidebar/MenuItem";
import { useEffect, useState } from "react";
import { UpCircleOutlined } from "@ant-design/icons";
import Footer from "../../components/footer";
import ScrollToTop from "../../hooks/ScrollToTop";
import { AuthGuard } from "../../components/authentication/auth-guard";

export default function AuthPage() {
  const location = useLocation();
  const { width } = useViewport();
  const isMobile = width <= 768;
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  });

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 200) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 200) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <AuthGuard>
      <main>
        <Layout>
          <Header />
          <ScrollToTop />
          {isMobile && (
            <Menu
              mode="horizontal"
              defaultSelectedKeys={[location.pathname]}
              items={menuItems}
              className="menu-mobile"
            />
          )}
          <Layout style={{ minHeight: "calc(100vh - 370px)" }}>
            <Outlet />
          </Layout>
          <div className="scrollTop">
            <div
              onClick={scrollTop}
              className="scrollTopButton"
              style={{ display: showScroll ? "flex" : "none" }}
            >
              <UpCircleOutlined className="scroll-top-icon" />
            </div>
          </div>
          <Footer />
        </Layout>
      </main>
    </AuthGuard>
  );
}
