"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelOutputShapeNotFound = exports.ModelOutputNotFound = exports.ModelInputShapeNotFound = exports.ModelInputNotFound = void 0;
class ModelInputNotFound extends Error {
    constructor(message) {
        super(message || "Model input not found");
        this.name = "ModelInputNotFound";
    }
}
exports.ModelInputNotFound = ModelInputNotFound;
class ModelInputShapeNotFound extends Error {
    constructor(message) {
        super(message || "Model input shape not found");
        this.name = "ModelInputShapeNotFound";
    }
}
exports.ModelInputShapeNotFound = ModelInputShapeNotFound;
class ModelOutputNotFound extends Error {
    constructor(message) {
        super(message || "Model Output not found");
        this.name = "ModelOutputNotFound";
    }
}
exports.ModelOutputNotFound = ModelOutputNotFound;
class ModelOutputShapeNotFound extends Error {
    constructor(message) {
        super(message || "Model Output shape not found");
        this.name = "ModelOutputShapeNotFound";
    }
}
exports.ModelOutputShapeNotFound = ModelOutputShapeNotFound;
