import { ArrowRightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import { getPopularTopics } from "../../../service/courses";
import { GroupIndustry } from "../../../types/course";
import "./HomeTopic.scss";

type Props = {};

const HomeTopic = (props: Props) => {
  const [groupIndustry, setGroupIndustry] = useState<GroupIndustry[]>();
  const [pageNum, setPageNum] = useState<number>(1);
  const getGroupOfIndustry = async () => {
    const params = {
      page: pageNum,
      size: 6,
    };
    const res = await getPopularTopics(params);
    setGroupIndustry(res.data.content);
  };
  useEffect(() => {
    getGroupOfIndustry();
  }, [pageNum]);

  const tabList = [
    { id: 1, content: "" },
    { id: 2, content: "" },
    { id: 3, content: "" },
  ];

  const navigate = useNavigate();
  return (
    <>
      <div className="home-topic">
        <div className="topic">
          <div className="topic-item">
            <div className="text-title-36">
              Đa dạng các chủ đề của nhiều lĩnh vực khác nhau
            </div>
            <div className="font-size-20 mt-2">
              1000+ đa dạng các khoá học khác nhau
            </div>
            <div className="action">
              <button
                className="course"
                onClick={() => navigate(routesConfig.courseInfo)}
              >
                Khoá học
              </button>
              <button
                className="topic"
                onClick={() => navigate(routesConfig.courseTopics)}
              >
                Danh sách các chủ đề
              </button>
            </div>
          </div>
          <div className="topic-item">
            {groupIndustry?.slice(0, 6)?.map((it) => (
              <div key={it?.id} className="industry">
                {it?.name}
              </div>
            ))}
            <div className="select-tab">
              {tabList?.map((it, index) => (
                <div
                  className={`select-tab-item ${pageNum === index + 1 ? "bg-primary" : ""}`}
                  key={it?.id}
                  onClick={() => setPageNum(index + 1)}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="home-topic-introduction">
        <div className="text-primary font-weight-6 font-size-20">Về chúng tôi</div>
        <div className="text-title-36 mt-2 mb-3">
          Hệ thống phát triển nền tảng khoá học
        </div>
        <div className="font-size-20">
          Với đa dạng các khoá học trực tuyến cung cấp mở ở nhiều lĩnh vực khác
          nhau
        </div>
      </div>
      <div className="home-topic-view">
        <div className="content">
          <div className="content-item">
            <div className="title">Học tập online hiệu quả</div>
            <div className="font-size-16">
              Các khoá học đều được số hoá để học tập hoàn toàn trực tuyến trên
              hệ thống Mooc với đa dạng học liệu, bài tập, . . .
            </div>
            <div
              className="more-btn"
              onClick={() => navigate(routesConfig.courseInfo)}
            >
              Khoá học của chúng tôi
              <ArrowRightOutlined className="more-btn-icon" />
            </div>
          </div>
          <div className="content-item">
            <div className="title">Chứng chỉ học tập online được công nhận</div>
            <div className="font-size-16">
              Với hệ thống liên kết các trường đại học, nền tảng cung cấp các
              chương trình học đạt chuẩn và cung cấp chứng chỉ đạt chuẩn
            </div>
            {/* <div
              className="more-btn"
              onClick={() => navigate(routesConfig.curriculumPrograms)}
            >
              Tìm hiểu thêm <ArrowRightOutlined className="more-btn-icon" />
            </div> */}
          </div>
          <div className="content-item">
            <div className="title">Đa dạng thiết bị học tập</div>
            <div className="font-size-16">
              Hệ thống được thiết kế để phù hợp học tập mọi lúc mọi nơi trên đa
              dạng các thiết bị: Laptop, máy tính, smartphone, . . .
            </div>
          </div>
        </div>
        <div className="img-intro">
          <img
            className="screen-mockup"
            src={`${process.env.PUBLIC_URL}/assets/img/3_2 screen-mockup.png`}
          />
          <img
            className="iPhone-mockup"
            src={`${process.env.PUBLIC_URL}/assets/img/iPhone-mockup.png`}
          />
        </div>
      </div>
    </>
  );
};

export default HomeTopic;
