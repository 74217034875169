import { Col, Collapse } from "antd";
import { useCurriculumStore } from "../../../stores/stores";
import "./CurriculumProgramRoadmap.scss";
import { StarOutlined } from "@ant-design/icons";
import { courses } from "../../../types/curriculum";

interface Props {
  idElmTag?: string;
}
const { Panel } = Collapse;

const CurriculumProgramRoadmapTemplate: React.FC<Props> = (props: Props) => {
  const { idElmTag } = props;
  const { roadmap } = useCurriculumStore();

  return (
    <div className="curriculum-program-roadmap" id={idElmTag}>
      <div className="text-title-18">Lộ trình</div>
      <div className="description">
        Tổng quan về thời gian biểu của khoá học
      </div>
      <div className="hr-tag"></div>
      <div className="roadmap">
        {roadmap?.courses?.map((it: courses, index) => (
          <div key={index} className="roadmap-item">
            <div className="roadmap-item-img">
              <img
                src={it?.thumbnailUrl || "/assets/img/3_2 screen-mockup.png"}
                className="roadmap-item-img-card"
              />
              <div className="course-introduction">
                <div className="course-introduction-content">
                  <div className="font-size-24 font-weight-6">{it?.name}</div>
                  <div className="course-introduction-scholl">
                    {it?.universityName}{" "}
                    {it?.slug ? "(" + it?.slug + ")" : null}
                  </div>
                  <div>
                    Những kỹ năng nền tảng và cơ bản nhất từ giảng viên có kinh
                    nghiệm
                  </div>
                  <ul className="course-introduction-list">
                    <li className="flex">
                      <img
                        className="course-introduction-list__icon"
                        alt="icon"
                        src="/assets/icons/check.svg"
                      />
                      Cung cấp những kiến thức cơ bản về ngôn ngữ Anh (ngữ pháp,
                      kỹ năng nghe/nói, ...)
                    </li>
                    <li className="flex">
                      <img
                        className="course-introduction-list__icon"
                        alt="icon"
                        src="/assets/icons/check.svg"
                      />
                      Another Step
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="roadmap-item-info">
              <p className="university-name">
                {it?.universityName} {it?.slug ? "(" + it?.slug + ")" : null}
              </p>
              <p className="font-weight-6 font-size-18">{it?.name}</p>
              <p className="font-weight-4 font-size-16">
                {it?.reviewDescription}
              </p>
              <div className="type-5 rate">
                {it?.avgStar ? (
                  <div className="rate-star flex align-center">
                    <StarOutlined style={{ color: "#EF6820" }} />
                    {it?.avgStar}
                  </div>
                ) : (
                  ""
                )}
                {it?.industryName ? (
                  <div className="subject">{it?.industryName}</div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CurriculumProgramRoadmapTemplate;
