import { Col } from "antd";
import React from "react";
import { KeyValue } from "../../types/app";

interface FilterTopicProps{
    title?: string;
    items?: KeyValue[]
    onChange?: (val?: any) => void
}

function FilterTopic({title = "", items = [], onChange = () => {}}: FilterTopicProps) {
  return (
    <Col span={24} className="search-topic-results-col">
      <img
        className="course-introduction-list__icon"
        alt="icon"
        src="/assets/icons/bar-chart-square.svg"
      />
      <div className="search-topic-results-content">{title}</div>
      <div className="topic-list">
        {items?.map((itm: any, i: number) => (
          <div
            className="item"
            key={i}
            onClick={() => onChange(itm)}
          >
            {itm?.label}
          </div>
        ))}
      </div>
    </Col>
  );
}

export default FilterTopic;
