import { StarOutlined, UserOutlined } from "@ant-design/icons";
import { CourseDetail } from "../../../types/course";
import { formatCurrencyToVND } from "../../../utils/format";
import "./ListProminentCourses.scss";
import { Col, Row } from "antd";
import { routesConfig } from "../../../config/routes";
import { useNavigate } from "react-router-dom";
import EmptyComponent from "../../../components/empty";

type Props = {
  courseList: Array<CourseDetail>;
};

const ListProminentCourses = ({ courseList }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="list-prominent-courses">
      <div className="font-size-20 text-primary text-center font-weight-6">
        Danh sách khoá học
      </div>
      <div className="font-size-36 font-weight-6 mt-2 text-center">
        Bắt đầu với những khoá học nổi bật
      </div>
      <div className="prominent-description">
        Hệ thống phát triển nền tảng cung cấp khoá học trực tuyến mở cung cấp mở
        <div>với đa dạng khoá học ở nhiều lĩnh vực khác nhau</div>
      </div>
      <div className="font-size-24 font-weight-6 mb-4">
        Các khoá học nổi bật
      </div>
      <div className="prominent-courses-item">
        {courseList?.length > 0 ? (
          <Row gutter={[24, 24]}>
            <Col xs={24} lg={12} xl={12} xxl={12}>
              <div className="item-first">
                {courseList.slice(0, 1)?.map((course) => (
                  <div
                    key={course?.id}
                    onClick={() =>
                      navigate(`${routesConfig.courseInfo}/detail/${course.id}`)
                    }
                  >
                    <img
                      className="card-img"
                      src={
                        course?.thumbnailUrl ||
                        `${process.env.PUBLIC_URL}/assets/icons/empty-course.png`
                      }
                    />
                    <div
                      className="mt-3 font-weight-6"
                      style={{ color: "#475467" }}
                    >
                      {course?.time && (
                        <div className="d-flex align-center">
                          Tổng số <span>{course?.time}</span> giờ
                          <div className="dot"></div>
                        </div>
                      )}
                      <div>
                        {course?.universityName}{" "}
                        {course?.slug && "(" + course?.slug + ")"}
                      </div>
                      <div className="font-size-24 font-weight-6 mt-1 mb-1 flex justify-between">
                        {course?.name}
                        <img
                          className="view-icon"
                          src="./assets/icons/arrow-up-right.svg"
                          alt=""
                        />
                      </div>
                      <div className="cost">
                        <img
                          className="course-info-img"
                          src={`${process.env.PUBLIC_URL}/assets/icons/wallet.svg`}
                        />
                        {course?.cost && formatCurrencyToVND(course?.cost)}
                      </div>
                      <div>
                        Giảng viên:{" "}
                        {course?.teachers?.map((it: any, index: number) => (
                          <span key={index}>
                            {it?.firstName + " " + it?.lastName}
                            {index !== (course?.teachers?.length ?? 0) - 1 &&
                              ", "}
                          </span>
                        ))}
                      </div>
                      <div className="rating">
                        <div className="popular">Thịnh hành</div>
                        {course?.avgStar ? (
                          <div className="rate-star flex align-center">
                            <StarOutlined
                              style={{ color: "#EF6820", marginRight: 6 }}
                            />
                            {course?.avgStar}
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="total-student">
                          <UserOutlined className="mr-1" />
                          {course?.totalStudents || 0} học viên
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col xs={24} lg={12} xl={12} xxl={12}>
              <Row gutter={[24, 24]}>
                {courseList.slice(1, 3)?.map((course) => (
                  <Col xs={24} md={12} lg={24} xl={24} xxl={24}>
                    <div
                      key={course?.id}
                      className="item-child"
                      onClick={() =>
                        navigate(
                          `${routesConfig.courseInfo}/detail/${course.id}`
                        )
                      }
                    >
                      <img
                        className="card-img"
                        src={
                          course?.thumbnailUrl ||
                          `${process.env.PUBLIC_URL}/assets/icons/empty-course.png`
                        }
                      />
                      <div
                        className="font-weight-6 w-full-100"
                        style={{ color: "#475467" }}
                      >
                        {course?.time && (
                          <div className="d-flex align-center">
                            Tổng số <span>{course?.time}</span> giờ
                            <div className="dot"></div>
                          </div>
                        )}
                        <div>
                          {course?.universityName}{" "}
                          {course?.slug && "(" + course?.slug + ")"}
                        </div>
                        <div className="font-size-24 font-weight-6 mt-1 mb-1 flex justify-between">
                          {course?.name}
                          <img
                            className="view-icon"
                            src="./assets/icons/arrow-up-right.svg"
                            alt=""
                          />
                        </div>
                        <div className="cost">
                          <img
                            className="course-info-img"
                            src={`${process.env.PUBLIC_URL}/assets/icons/wallet.svg`}
                          />
                          {course?.cost && formatCurrencyToVND(course?.cost)}
                        </div>
                        <div>
                          Giảng viên:{" "}
                          {course?.teachers?.map((it: any, index: number) => (
                            <span key={index}>
                              {it?.firstName + " " + it?.lastName}
                              {index !== (course?.teachers?.length ?? 0) - 1 &&
                                ", "}
                            </span>
                          ))}
                        </div>
                        <div className="rating">
                          <div className="popular">Thịnh hành</div>
                          {course?.avgStar ? (
                            <div className="rate-star flex align-center">
                              <StarOutlined
                                style={{ color: "#EF6820", marginRight: 6 }}
                              />
                              {course?.avgStar}
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="total-student">
                            <UserOutlined className="mr-1" />
                            {course?.totalStudents || 0} học viên
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        ) : (
          <EmptyComponent description="Không có khoá học" />
        )}
      </div>
    </div>
  );
};

export default ListProminentCourses;
