import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { axiosConfigV3 } from "../../config/api/configApiV3";

export const getIndustryGroup: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.post(`/open-api/course/get-all-industry`, {
    page: 1,
    size: 999999,
  });
};

export const getInterest: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV3.post(`/recommendation-tag/get-all-tag`, {
    skip: 0,
    take: 99999,
  });
};

export const addIndustryOfInterest: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/api/industry-student-interest/create`, payload);
};

export const addStudentInterests: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV3.post(`/student-favorite/create`, payload);
};
