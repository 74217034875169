import React, { useEffect, useState } from "react";
import {
  BaseChartProps,
  ChartDataProps,
  StatisticChartPayload,
  StatisticChartResponseData,
} from "../../../../../types/statistic";
import { fetchRateUnitBySequence } from "../../../../../service/statistics";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import BarChartHorizontal from "../../../../../components/charts/BarChartHorizontal";
import {
  getDataKeys,
  getLegends,
  mapToDataPercentChart,
} from "../../components/utils";
import { LegendItemProps } from "../../../../../components/charts/components/LegendCustom";

function UnitBySequence({ filterValues }: BaseChartProps) {
  // Tỷ lệ kết quả làm đánh giá theo bài giảng
  const [rateUnitBySequenceValues, setRateUnitBySequenceValues] =
    useState<StatisticChartResponseData>();

  useEffect(() => {
    
    if (!isEmpty(filterValues)) {
      // Tỷ lệ kết quả làm đánh giá theo bài giảng
      getRateUnitBySequence({
        from: filterValues?.date?.length
          ? dayjs(filterValues?.date[0]).toISOString()
          : undefined,
        to: filterValues?.date?.length
          ? dayjs(filterValues?.date[1]).toISOString()
          : undefined,
        courseIds: filterValues?.course,
        courseLevelIds: filterValues?.level,
        industryGroupIds: filterValues?.department,
      });
    }
  }, [filterValues]);

  const getRateUnitBySequence = async (payload: StatisticChartPayload) => {
    const res = await fetchRateUnitBySequence(payload);
    setRateUnitBySequenceValues(res.data?.data);
  };

  return (
    <BarChartHorizontal
      data={mapToDataPercentChart(
        rateUnitBySequenceValues?.statistic as ChartDataProps[]
      )}
      yDataKey="criteria"
      xAxisType="percent"
      colors={["#D9D6FE", "#BDB4FE", "#9B8AFB", "#7A5AF8", "#6938EF"]}
      dataKeys={getDataKeys(
        rateUnitBySequenceValues?.statistic as ChartDataProps[]
      )}
      title="Tỷ lệ kết quả làm đánh giá theo bài giảng"
      total={rateUnitBySequenceValues?.total}
      legends={
        getLegends(
          getDataKeys(rateUnitBySequenceValues?.statistic as ChartDataProps[]),
          [
            "#6938EF",
            "#774ee9",
            "#7A5AF8",
            "#9B8AFB",
            "#BDB4FE",
            "#D9D6FE",
          ].reverse(),
          true
        ) as LegendItemProps[]
      }
    />
  );
}

export default React.memo(UnitBySequence);
