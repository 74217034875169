import { createBrowserRouter } from "react-router-dom";
import { routesConfig } from "../config/routes";

import AuthPage from "../layout/auth-page";
import DashboardRootPage from "../layout/dashboard/DashboardRootPage";
import Page500 from "../pages/500";
import CourseDetail from "../pages/course-detail";
import CourseEnrollment from "../pages/course-enrollment";
import CourseEvaluation from "../pages/course-evaluation";
import CourseInformation from "../pages/course-info";
import SearchCourseInfo from "../pages/course-info/search";
import CourseTopics from "../pages/course-info/search-by-topic";
import ForgotPassword from "../pages/forgot-password";
import HomePage from "../pages/home-page/HomePage";
import IdentifyEkyc from "../pages/identify-ekyc/IdentifyEkyc";
import InfoUser from "../pages/info-user/InfoUser";
import LearningCourseStructure from "../pages/learning-course-structure";
import Login from "../pages/login-page/Login";
import MyCourse from "../pages/my-courses";
import NotificationPage from "../pages/notification";
import PolicyInfo from "../pages/policy-info/PolicyInfo";
import Register from "../pages/register/Register";
import ResultDelete from "../pages/result-delete/ResultDelete";
import SavedCourses from "../pages/saved-courses";
import SearchSchools from "../pages/school/SearchSchools";
import SearchByTopic from "../pages/search-by-topic";
import SearchEducationalPrograms from "../pages/search-educational-programs";
import StatisticsCourses from "../pages/statistics/course";
import StatisticsUniversities from "../pages/statistics/university";
import StudySchedule from "../pages/study-schedule";
import SupportPage from "../pages/support";
import DetailSupport from "../pages/support/detail";
import SystemInfo from "../pages/system-info/SystemInfo";
import VerificationPage from "../pages/verification-page/VerficationPage";
import ViewSystemSupportInformation from "../pages/view system-support-information";
import Welcome from "../pages/welcome/Welcome";
// import { BlogAndComment } from "../pages/blog";
// import { TopicComponent } from "../pages/blog/components/topic";
import DeleteSuccess from "../components/pages/delete-success/DeleteSuccess";
import BlogPage from "../pages/blog";
import { CommentComponent } from "../pages/blog/components/comment";
import { TopicComponent } from "../pages/blog/components/topic";
import GeneralCourseDetail from "../pages/course-detail/general-course-detail";
import CourseProgramDetailTemplate from "../pages/course-program-detail/course-program-detail-template";
import AccountDeletionPolicy from "../pages/info-user/components/account-deletion-policy/AccountDeletionPolicy";
import JoinPlatform from "../pages/join-platform/JoinPlatform";
import MySurvey from "../pages/my-surveys";
import SurveyRespond from "../pages/my-surveys/components/survey-respond/SurveyRespond";
import AuthenticateRegister from "../pages/register/components/authenticate-register/AuthenticateRegister";
import StatisticsReports from "../pages/statistics/reports/charts";
import StatisticsReportsDetail from "../pages/statistics/reports/details";
import AccountRegister from "../pages/support/detail/AccountRegister";
import ContactUs from "../pages/support/contact-us/ContactUs";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.homePage,
        errorElement: <Page500 />,
        children: [{ index: true, element: <HomePage /> }],
      },
      {
        path: routesConfig.welcome,
        errorElement: <Page500 />,
        children: [{ index: true, element: <Welcome /> }],
      },
      {
        path: routesConfig.infoUser,
        errorElement: <Page500 />,
        children: [{ index: true, element: <InfoUser /> }],
      },
      {
        path: routesConfig.deletePolicy,
        errorElement: <Page500 />,
        children: [{ index: true, element: <PolicyInfo /> }],
      },
      {
        path: routesConfig.verification,
        errorElement: <Page500 />,
        children: [{ index: true, element: <VerificationPage /> }],
      },
      {
        path: routesConfig.resultDelete,
        errorElement: <Page500 />,
        children: [{ index: true, element: <ResultDelete /> }],
      },
      {
        path: routesConfig.courseInfo,
        errorElement: <Page500 />,
        children: [{ index: true, element: <CourseInformation /> }],
      },
      {
        path: routesConfig.searchCourseInfo,
        errorElement: <Page500 />,
        children: [{ index: true, element: <SearchCourseInfo /> }],
      },
      {
        path: routesConfig.curriculumPrograms,
        errorElement: <Page500 />,
        children: [{ index: true, element: <SearchEducationalPrograms /> }],
      },
      {
        path: routesConfig.statisticsCourses,
        errorElement: <Page500 />,
        children: [{ index: true, element: <StatisticsCourses /> }],
      },
      {
        path: `${routesConfig.searchSchool}/${routesConfig.universityDetail}/:slug`,
        errorElement: <Page500 />,
        element: <CourseDetail />,
      },
      {
        path: `${routesConfig.searchSchool}/${routesConfig.universityTemplateDetail}`,
        errorElement: <Page500 />,
        element: <GeneralCourseDetail tab="1" />,
      },
      {
        path: `${routesConfig.searchSchool}/${routesConfig.universityCourseTemplateDetail}`,
        errorElement: <Page500 />,
        element: <GeneralCourseDetail tab="2" />,
      },
      {
        path: `${routesConfig.searchSchool}/${routesConfig.universityCourseProgramTemplateDetail}`,
        errorElement: <Page500 />,
        element: <GeneralCourseDetail tab="3" />,
      },
      {
        path: routesConfig.searchSchool,
        errorElement: <Page500 />,
        children: [{ index: true, element: <SearchSchools /> }],
      },
      {
        path: routesConfig.searchByTopic,
        errorElement: <Page500 />,
        children: [{ index: true, element: <SearchByTopic /> }],
      },
      {
        path: `${routesConfig.courseInfo}/detail/:id`,
        children: [{ index: true, element: <CourseEvaluation /> }],
      },
      {
        path: routesConfig.courseTopics,
        errorElement: <Page500 />,
        children: [{ index: true, element: <CourseTopics /> }],
      },
      {
        path: routesConfig.courseTopics,
        children: [{ index: true, element: <CourseTopics /> }],
      },
      {
        path: `${routesConfig.curriculumPrograms}/detail/:id`,
        errorElement: <Page500 />,
        children: [{ index: true, element: <CourseProgramDetailTemplate /> }],
      },
      {
        path: routesConfig.systemInfo,
        children: [{ index: true, element: <SystemInfo /> }],
      },
      {
        path: routesConfig.joinPlatform,
        children: [{ index: true, element: <JoinPlatform /> }],
      },
      {
        path: `${routesConfig.searchSchool}/${routesConfig.universityTemplateDetail}/${routesConfig.blog}`,
        children: [{ index: true, element: <BlogPage /> }],
      },
      {
        path: routesConfig.support,
        children: [
          { index: true, element: <SupportPage /> },
          {
            path: routesConfig.supportAccountRegister,
            element: <AccountRegister />,
          },
          {
            path: routesConfig.contactUs,
            element: <ContactUs />,
          },
        ],
      },

      {
        path: routesConfig.statisticsUniversities,
        children: [{ index: true, element: <StatisticsUniversities /> }],
      },
      {
        path: routesConfig.supportDetail,
        children: [{ index: true, element: <DetailSupport /> }],
      },
      {
        path: routesConfig.viewSystemSupportInformation,
        children: [{ index: true, element: <ViewSystemSupportInformation /> }],
      },
      {
        path: routesConfig.viewSystemSupportInformation,
        children: [{ index: true, element: <ViewSystemSupportInformation /> }],
      },
      {
        path: `${routesConfig.courseEnrollment}/:id`,
        children: [{ index: true, element: <CourseEnrollment /> }],
      },
      {
        path: `${routesConfig.savedCourses}`,
        children: [{ index: true, element: <SavedCourses /> }],
      },
      {
        path: `${routesConfig.studySchedule}`,
        children: [{ index: true, element: <StudySchedule /> }],
      },
      {
        path: `${routesConfig.accountDeletionPolicy}`,
        children: [{ index: true, element: <AccountDeletionPolicy /> }],
      },
      {
        path: routesConfig.deleteSuccess,
        element: <DeleteSuccess />,
      },
    ],
  },
  {
    path: routesConfig.notification,
    element: <AuthPage />,
    children: [
      {
        path: routesConfig.notification,
        children: [{ index: true, element: <NotificationPage /> }],
      },
    ],
  },
  {
    path: `${routesConfig.learningCourseStructure}/:id`,
    element: <AuthPage />,
    children: [{ index: true, element: <LearningCourseStructure /> }],
  },
  {
    path: routesConfig.myCourses, // my-survey
    element: <AuthPage />,
    children: [
      {
        path: routesConfig.myCourses,
        errorElement: <Page500 />,
        children: [{ index: true, element: <MyCourse /> }],
      },
    ],
  },
  {
    path: routesConfig.statisticsReport,
    element: <AuthPage />,
    children: [
      {
        path: routesConfig.statisticsReport,
        errorElement: <Page500 />,
        children: [{ index: true, element: <StatisticsReports /> }],
      },
      {
        path: `${routesConfig.statisticsReport}/detail`,
        errorElement: <Page500 />,
        children: [{ index: true, element: <StatisticsReportsDetail /> }],
      },
    ],
  },
  {
    path: routesConfig.mySurveys,
    element: <AuthPage />,
    children: [
      {
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <MySurvey />,
          },
          {
            path: `${routesConfig.surveyRespond}/:id/:type/:theme`, // survey-res
            element: <SurveyRespond />,
          },
        ],
      },
    ],
  },
  {
    path: routesConfig.blog,
    element: <AuthPage />,
    children: [
      {
        path: `/${routesConfig.blog}/${routesConfig.topic}`,
        errorElement: <Page500 />,
        children: [{ index: true, element: <TopicComponent /> }],
      },
      {
        path: `/${routesConfig.blog}/${routesConfig.topic}/${routesConfig.comment}`,
        errorElement: <Page500 />,
        children: [{ index: true, element: <CommentComponent /> }],
      },
    ],
  },
  {
    errorElement: <Page500 />,
    path: routesConfig.identifyEkyc,
    element: <AuthPage />,
    children: [{ index: true, element: <IdentifyEkyc /> }],
  },
  {
    path: routesConfig.login,
    element: <Login />,
  },
  {
    path: routesConfig.register,
    element: <Register />,
  },
  {
    path: `${routesConfig.register}/${routesConfig.authenticateRegister}`,
    element: <AuthenticateRegister />,
  },
  {
    path: routesConfig.forgotPassword,
    element: <ForgotPassword />,
  },
]);
