import { StarOutlined } from "@ant-design/icons";
import React from "react";

interface StarFilterProps {
  star: number;
  content?: string;
  totalRecord?: number;
}

function FilterStar({ star = 5, content, totalRecord = 0 }: StarFilterProps) {
  const renderTextContent = (star: number) => {
    switch (star) {
      case 1.5:
        return "Dưới 2 sao";
      case 2:
        return "Từ 2.0 sao";
      case 3:
        return "Từ 3.0 sao";
      case 4:
        return "Từ 4.0 sao";
      case 5:
        return "Từ 4.5 sao";
      default:
        return "Dưới 2 sao";
    }
  };
  return (
    <div className="star-filter">
      {Array(5)
        .fill(1)
        .map((_, index) => (
          <div key={index}>
            {index + 1 <= star ? (
              <img src="/assets/icons/star.svg" style={{ width: 18 }} />
            ) : (
              <StarOutlined
                className="center"
                style={{ color: "#F2F4F7", fontSize: 16, marginTop: 3 }}
                key={index}
              />
            )}
          </div>
        ))}
      <div className="font-size-12 flex-1">
        {" "}
        {content || renderTextContent(star)}
      </div>
      {totalRecord > 0 ? (
        <span>
          {" "}
          {" ("}
          {totalRecord}
          {")"}
        </span>
      ) : (
        ""
      )}
    </div>
  );
}

export default FilterStar;
