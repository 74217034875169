import { useState } from "react";
import MoocMenu from "../../../components/menu/MoocMenu";
import { useCourseDetailStore } from "../../../stores/stores";
import { converDataLearningCourse } from "../../learning-course-structure/learning-course/converDataLearningCourse";
import "./CoursePathway.scss";
interface Props {
  idElmTag?: string;
}
const CoursePathway: React.FC<Props> = (props: Props) => {
  const { idElmTag } = props;
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const { detail } = useCourseDetailStore();
  const sections = Array.isArray(detail?.sections)
    ? converDataLearningCourse(detail.sections)
    : [];

  return (
    <div className="course-pathway" id={idElmTag}>
      <div className="text-title-24">Lộ trình</div>
      <div className="mt-1 mb-1 text-description-14">
        Tổng quan về thời gian biểu của khoá học
      </div>
      <div className="hr-tag"></div>
      <MoocMenu
        data={sections}
        openKeys={openKeys}
        onOpenChange={(keys) => setOpenKeys(keys)}
      />
    </div>
  );
};

export default CoursePathway;
