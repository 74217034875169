import React, { useEffect, useState } from "react";
import {
  BaseChartProps,
  ChartDataProps,
  StatisticChartPayload,
  StatisticChartResponseData,
} from "../../../../../types/statistic";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { fetchRateClassAction } from "../../../../../service/statistics";
import BarChartHorizontal from "../../../../../components/charts/BarChartHorizontal";
import {
  getDataKeys,
  getLegends,
  mapToDataChart,
} from "../../components/utils";
import { LegendItemProps } from "../../../../../components/charts/components/LegendCustom";

function ClassAction({ filterValues }: BaseChartProps) {
  // Thống kê số lượng phản hồi theo lớp
  const [rateClassActionValues, setRateClassActionValues] =
    useState<StatisticChartResponseData>();

  useEffect(() => {
    if (!isEmpty(filterValues)) {
      getRateClassAction({
        from: filterValues?.date?.length
          ? dayjs(filterValues?.date[0]).toISOString()
          : undefined,
        to: filterValues?.date?.length
          ? dayjs(filterValues?.date[1]).toISOString()
          : undefined,
        courseIds: filterValues?.course,
        courseLevelIds: filterValues?.level,
        industryGroupIds: filterValues?.department,
      });
    }
  }, [filterValues]);

  const getRateClassAction = async (payload: StatisticChartPayload) => {
    const res = await fetchRateClassAction(payload);
    setRateClassActionValues(res.data?.data);
  };

  return (
    <BarChartHorizontal
      data={mapToDataChart(
        rateClassActionValues?.statistic as ChartDataProps[]
      )}
      dataKeys={getDataKeys(
        rateClassActionValues?.statistic as ChartDataProps[]
      )}
      yDataKey="criteria"
      title="Thống kê số lượng phản hồi theo lớp"
      total={rateClassActionValues?.total}
      legends={
        getLegends(
          getDataKeys(rateClassActionValues?.statistic as ChartDataProps[]),
          [
            "#6938EF",
            "#774ee9",
            "#7A5AF8",
            "#9B8AFB",
            "#BDB4FE",
            "#D9D6FE",
          ].reverse()
        ) as LegendItemProps[]
      }
    />
  );
}

export default React.memo(ClassAction);
