// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-info-banner-content .search {
  gap: 18px;
}
.course-info-banner-content .banner-title {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
}
.course-info-banner-content .search-input {
  height: 48px;
}
.course-info-banner-content .mooc-btn-violet {
  height: 48px;
  padding: 12px 18px;
}

.content-layout {
  padding: 0 80px;
}
.content-layout .tabs-ss {
  padding: 48px 0;
}
.content-layout .filter-content {
  padding: 15px 0;
  display: flex;
  position: relative;
}
.content-layout .filter-content .ant-form-item {
  margin-bottom: 0;
}
.content-layout .filter-content .btn-filter-mobile {
  position: absolute;
  top: 60px;
  z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/pages/course-info/style.scss"],"names":[],"mappings":"AACE;EACE,SAAA;AAAJ;AAGE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,uBAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,YAAA;EACA,kBAAA;AAHJ;;AAOA;EACE,eAAA;AAJF;AAKE;EACE,eAAA;AAHJ;AAME;EACE,eAAA;EACA,aAAA;EACA,kBAAA;AAJJ;AAKI;EACE,gBAAA;AAHN;AAKI;EACE,kBAAA;EACA,SAAA;EACA,YAAA;AAHN","sourcesContent":[".course-info-banner-content {\n  .search {\n    gap: 18px;\n  }\n\n  .banner-title {\n    font-size: 48px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 60px; /* 125% */\n    letter-spacing: -0.96px;\n  }\n\n  .search-input {\n    height: 48px;\n  }\n\n  .mooc-btn-violet {\n    height: 48px;\n    padding: 12px 18px;\n  }\n}\n\n.content-layout {\n  padding: 0 80px;\n  .tabs-ss {\n    padding: 48px 0;\n  }\n\n  .filter-content {\n    padding: 15px 0;\n    display: flex;\n    position: relative;\n    .ant-form-item {\n      margin-bottom: 0;\n    }\n    .btn-filter-mobile{\n      position: absolute;\n      top: 60px;\n      z-index: 100;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
