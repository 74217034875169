// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-progress {
  display: flex;
  align-items: center;
}
.course-progress .price {
  font-size: 24px;
  color: #ff5722;
  font-weight: bold;
  margin-left: 16px;
}
.course-progress h1 {
  margin: 0;
  color: #333;
}
.course-progress p {
  color: #666;
}`, "",{"version":3,"sources":["webpack://./src/components/course-progess/CourseProges.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,mBAAA;AAAF;AAEE;EACE,eAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,SAAA;EACA,WAAA;AADJ;AAIE;EACE,WAAA;AAFJ","sourcesContent":[".course-progress {\n  // padding: 16px 0px;\n  display: flex;\n  align-items: center;\n\n  .price {\n    font-size: 24px;\n    color: #ff5722;\n    font-weight: bold;\n    margin-left: 16px;\n  }\n\n  h1 {\n    margin: 0;\n    color: #333;\n  }\n\n  p {\n    color: #666;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
