import React from "react";

interface QuizPanelItemProps {
  active?: boolean;
  isCorrect?: boolean;
  isIncorrect?: boolean;
  displayNumber?: number;
  isDone?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

function QuizPanelItem({
  active = false,
  displayNumber = 0,
  isCorrect,
  isIncorrect,
  isDone = false,
  disabled = false,
  onClick = () => {},
}: QuizPanelItemProps) {
  const renderContent = () => {
    if (isCorrect || isIncorrect) {
      return (
        <div
          className={`quiz-item-control ${disabled ? "disabled-btn" : ""} ${isCorrect ? "isCorrect" : "isInCorrect"} ${active ? "active" : ""}`}
          onClick={disabled ? () => {} : onClick}
        >
          <span>
            <b>{displayNumber}</b>
          </span>
        </div>
      );
    }
    return (
      <div
        className={`quiz-item-control ${active ? "active" : ""}  ${isDone ? "isDone" : ""} ${disabled ? "disabled-btn" : ""}`}
        onClick={disabled ? () => {} : onClick}
      >
        <span>
          <b>{displayNumber}</b>
        </span>
      </div>
    );
  };
  return renderContent();
}

export default QuizPanelItem;
