import { Button, Typography } from "antd";
import React from "react";
import "./DeleteSuccess.scss";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
const DeleteSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="delete-success">
      <div className="content">
        <img src="/assets/img/content-bg.png" alt="" className="bg-img" />
        <img src="/assets/img/MOOCS.png" alt="" className="mooc-bg-img" />
        <div className="z-1">
          <div className="delete-warning">Tài khoản đã xoá</div>
          <div className="delete-thank">
            Cảm ơn bạn đã sử dụng hệ thống Mooc!
          </div>
          <Button onClick={() => navigate(routesConfig.homePage)}>
            Đi đến trang chủ
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteSuccess;
