import React, { useState } from 'react'
import { blogAndForumState } from '../../../stores/blog-and-forum'
import { blogAndForumStore } from '../../../stores/stores'

export default function SearchInputBlock() {
  const {setRefresh, setSearchValue , refresh}  = blogAndForumStore()

  const handleSearchBlog = () => {
    setRefresh(!refresh)
  }
  return (
    <div className="flex gap-4 mt-3">
      <input type="text" onChange={(e) => setSearchValue(e.target.value)} placeholder="Nhập từ khoá cần tìm" id="i8ujj" className="w-full px-4 py-2 text-gray-700 focus:outline-none mr-2 rounded" />
      <button id="iytk3" className="text-white px-4 py-2 hover:bg-gray-700 rounded bg-blue-400 whitespace-pre" onClick={handleSearchBlog}>Tìm kiếm</button>
    </div>
  )
}
