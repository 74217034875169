import { Breadcrumb } from "antd";
import { debounce, isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CourseCard, { CourseCardProps } from "../../components/card/CourseCard";
import Filter from "../../components/filter";
import FormInput from "../../components/form-input/FormInput";
import ResponsiveList from "../../components/list/ResponsiveList";
import CustomPagination from "../../components/pagination/Pagination";
import RelevantSimilarCourses from "../../components/relevant-similar-courses/RelevantSimilarCourses";
import { routesConfig } from "../../config/routes";
import {
  COURSE_SCHEDULE_TYPE,
  COURSE_TYPE,
  DEFAULT_PAGE_NUM,
  DEFAULT_PAGE_SIZE,
  FILTER_STAR_ARR,
  FREE_OPTIONS,
  PAID_VALUE_FILTER,
} from "../../constants";
import {
  getIndustrySavedCourse,
  getSavedCourseList,
  getUniversitySavedCourse,
} from "../../service/saved-course/saved-course-service";
import { globalStore, savedCourseStore } from "../../stores/stores";
import { CourseInfo, CoursePayload } from "../../types/course";
import { getArrOrUndefined, getArrayFilterChecked } from "../../utils/arrays";
import {
  convertSomeObjKeyToArray,
  mapCourseInfoBeToFe,
} from "../../utils/format";
import { useFilters } from "./Filters";
import "./style.scss";

type Props = {};

const SavedCourses = (props: Props) => {
  const { industries, setIndustries, universities, setUniversities } =
    savedCourseStore();
  const { reload } = globalStore();
  const navigate = useNavigate();
  const [searchValues, setSearchValues] = useState<CoursePayload>({
    sortByName: "asc",
  });
  const [initialCourses, setInitialCourses] = useState<CourseCardProps[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [pageNum, setPageNum] = useState<number>(DEFAULT_PAGE_NUM);
  const [courses, setCourses] = useState<CourseCardProps[]>([]);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [keySearch, setKeySearch] = useState("");
  const [pageSizeIndustries, setPageSizeIndustries] =
    useState<number>(DEFAULT_PAGE_SIZE);
  const [pageNumIndustries, setPageNumIndustries] =
    useState<number>(DEFAULT_PAGE_NUM);

  const filterMenus = useFilters({
    hasPaid: searchValues?.isFreeOptions?.includes(PAID_VALUE_FILTER)
      ? true
      : false,
  });

  const handleChangeFilter = (value: any) => {
    // Filter chuyên ngành
    const industriesFilter = getArrayFilterChecked(
      industries,
      convertSomeObjKeyToArray(value, "industry"),
      "id"
    );
    // Xếp hạng
    const stars = getArrayFilterChecked(
      FILTER_STAR_ARR,
      convertSomeObjKeyToArray(value, "rank"),
      "value"
    );
    // Chi phí
    const freeOptions = getArrayFilterChecked(
      FREE_OPTIONS,
      convertSomeObjKeyToArray(value, "price"),
      "value"
    );
    // Filter trường học
    const universitiesFilter = getArrayFilterChecked(
      universities,
      convertSomeObjKeyToArray(value, "university"),
      "uuid"
    );
    // Filter lịch trình học
    const courseScheduleType = getArrayFilterChecked(
      COURSE_SCHEDULE_TYPE,
      convertSomeObjKeyToArray(value, "schedule"),
      "value"
    );
    // Filter loại khoá học
    const courseType = getArrayFilterChecked(
      COURSE_TYPE,
      convertSomeObjKeyToArray(value, "kindOfCourse"),
      "value"
    );

    const payload = {
      industries: getArrOrUndefined(industriesFilter),
      universities: getArrOrUndefined(universitiesFilter),
      stars: getArrOrUndefined(stars),
      courseScheduleType: getArrOrUndefined(courseScheduleType),
      courseType: getArrOrUndefined(courseType),
      isFreeOptions: getArrOrUndefined(freeOptions),
      minCost: freeOptions?.includes(PAID_VALUE_FILTER) ? 0 : undefined,
      maxCost:
        freeOptions?.includes(PAID_VALUE_FILTER) && value?.cost
          ? value?.cost
          : freeOptions?.includes(PAID_VALUE_FILTER) && !value?.cost
            ? 0
            : undefined,
    };

    setSearchValues((prevState: CoursePayload) => ({
      ...prevState,
      ...payload,
    }));
  };

  const handleChangeSort = (value: number) => {
    switch (value) {
      case 1:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByName: "asc", sortByCreatedAt: undefined },
        }));
        break;
      case 2:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByName: "desc", sortByCreatedAt: undefined },
        }));
        break;
      case 3:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByCreatedAt: "asc", sortByName: undefined },
        }));
        break;
      case 4:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByCreatedAt: "desc", sortByName: undefined },
        }));
        break;
      default:
        break;
    }
  };

  const debouncedSetKeySearch = useCallback(
    debounce((value) => {
      setKeySearch(value);
    }, 500),
    []
  );

  const handleChangeKeySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetKeySearch(e.target.value);
  };

  const renderCard = (courses: any) => {
    if (!courses.length) return [];

    return courses?.map((item: CourseCardProps) => ({
      content: (
        <CourseCard
          key={item.id}
          {...item}
          onClick={() =>
            navigate(`${routesConfig.courseInfo}/detail/${item.id}`)
          }
          isPopular={false}
          teachers={item.teachers?.filter(
            (teacher: string, index) => teacher !== " "
          )}
        />
      ),
    }));
  };

  const getCourses = async (payload = {}) => {
    try {
      const res = await getSavedCourseList(payload);
      const coursesInfo = res.data.data.courses.map((item: CourseInfo) =>
        mapCourseInfoBeToFe(item)
      );
      setTotalRecords(res.data.data.total);
      return coursesInfo;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  useEffect(() => {
    if (
      isEmpty(searchValues) &&
      pageNum === DEFAULT_PAGE_NUM &&
      pageSize === DEFAULT_PAGE_SIZE
    )
      setCourses(initialCourses);
    else {
      // Lọc danh sách khoá học
      const searchCourse = async () => {
        try {
          setLoadingSearch(true);
          const values = await getCourses({
            ...searchValues,
            page: pageNum,
            size: pageSize,
            keyword: keySearch?.trim(),
          });
          setCourses(renderCard(values));
        } catch (err) {
        } finally {
          setTimeout(() => setLoadingSearch(false), 500);
        }
      };
      searchCourse();
    }
  }, [initialCourses, searchValues, pageNum, pageSize, keySearch, reload]);

  useEffect(() => {
    const getGroupOfIndustry = async () => {
      const payload = {
        ...searchValues,
        page: pageNum,
        size: 99999999,
        keyword: keySearch?.trim(),
      };
      const res = await getIndustrySavedCourse(payload);
      setIndustries(res.data.data.content);
    };
    getGroupOfIndustry();
  }, []);

  useEffect(() => {
    const getUniversities = async (payload = {}) => {
      const universityList = await getUniversitySavedCourse({
        ...searchValues,
        page: pageNum,
        size: 99999999,
        keyword: keySearch?.trim(),
      });
      setUniversities(universityList?.data.data);
    };
    getUniversities();
  }, [searchValues, keySearch]);

  return (
    <>
      <div className="saved-courses">
        <Breadcrumb
          items={[
            {
              href: "",
              title: (
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/home-line.svg`}
                />
              ),
            },
            {
              title: "Khóa học đã lưu",
            },
          ]}
        />
        <div className="mt-2 flex justify-between">
          <div className="text-title-30">Khoá học đã lưu</div>
        </div>
        <div className="text-secondary font-size-16 mt-1">
          Tổng hợp các khoá học đã lưu của bạn
        </div>
        <div className="hr-tag"></div>
        <div className="saved-courses-search">
          <FormInput
            placeholder="Nhập từ khóa cần tìm kiếm"
            prefixIcon={
              <img
                src="/assets/icons/search-lg.svg"
                alt="search"
                className="search-icon"
              />
            }
            onChange={handleChangeKeySearch}
          />
        </div>
        <div className="saved-courses-content">
          <div className="saved-courses-filter">
            <Filter
              items={filterMenus}
              onChange={handleChangeFilter}
              onChangeSort={handleChangeSort}
            />
          </div>
          <div className="saved-courses-list">
            <ResponsiveList
              loading={loadingSearch}
              className="mt-5"
              showMenu={false}
              totalRecords={totalRecords}
              grid={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3 }}
              data={courses}
              onClickReport={() => navigate(routesConfig.statisticsCourses)}
            />
            {totalRecords > 0 && (
              <CustomPagination
                totalPage={totalRecords}
                pageSize={pageSize}
                pageNumber={pageNum}
                setPageSize={(value) => setPageSize(value)}
                setPageNumber={(value) => setPageNum(value)}
              />
            )}
          </div>
        </div>
      </div>
      <RelevantSimilarCourses />
    </>
  );
};

export default SavedCourses;
