import { useNavigate } from "react-router-dom";
import "../welcome/welcome.scss";
import { routesConfig } from "../../config/routes";
import { useEffect } from "react";
import { globalStore } from "../../stores/stores";

export default function Welcome() {
  const { setFooterDefault, isFooterDefault } = globalStore();
  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate(routesConfig.homePage);
  };

  useEffect(() => {
    setFooterDefault();
  }, []);

  return (
    <>
      <div className="welcome">
        <div className="welcome__container container">
          <div className="welcome__left">
            <div className="welcome__left__intro">
              <h1>
                Yay! Tài khoản của bạn đã được <span>đăng ký thành công</span>
              </h1>
              <span className="welcome__left__intro__description">
                Chúc bạn có những trải nghiệm tuyệt vời cùng Mooc
              </span>
            </div>
            <div className="welcome__left__search">
              <button
                className="custom-btn"
                onClick={() => navigate(routesConfig.systemInfo)}
              >
                <span>Tìm hiểu thêm</span>
              </button>
              <button onClick={handleGoHome}>
                <span>Đến trang chủ</span>
              </button>
            </div>
          </div>
          <div className="welcome__right">
            <img
              className="welcome__right__banner"
              alt="welcome-banner"
              src="../../assets/img/svg_welcome.svg"
            />
            <div className=""></div>
          </div>
        </div>
      </div>
    </>
  );
}
