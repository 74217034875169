import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Form, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import FormInput from "../../../../components/form-input/FormInput";
import { configCalendar } from "../../../../service/calendar/calendar";
import { globalStore } from "../../../../stores/stores";
import { notifySuccess } from "../../../../utils/notification";
import "./SettingScheduleCalendar.scss";
import ConfirmModal from "../../../../components/modal/confirm-modal/ConfirmModal";

const { Option } = Select;

const dateFormats = [
  { value: 1, label: "DD/MM/YYYY" },
  { value: 2, label: "YYYY/MM/DD" },
  { value: 3, label: "MM/DD/YYYY" },
];

const timeFormats = [
  { format: "HH:mm", label: "24 giờ (Ví dụ: 13:00)", value: 1 },
  { format: "hh:mm A", label: "12 giờ (Ví dụ: 01AM)", value: 2 },
];

const notificationTimes = [
  { value: 1, label: "Trước 01 tuần" },
  { value: 2, label: "Trước 01 ngày" },
  { value: 3, label: "Trước 01 tiếng" },
  { value: 4, label: "Tùy chọn" },
];

const timeUnits = [
  { value: 3, label: "Ngày" },
  { value: 2, label: "Giờ" },
  { value: 1, label: "Phút" },
  ,
];

interface Props {
  onBack: () => void;
  configData: any;
}

const SettingScheduleCalendar = (props: Props) => {
  const { onBack, configData } = props;
  const { reload, setReload } = globalStore();
  const [form] = Form.useForm();
  const [customNotification, setCustomNotification] = useState<number>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSetting, setIsSetting] = useState<boolean>(false);


  const handleNotificationTimeChange = (value: number) => {
    setCustomNotification(value);
  };

  const onSetting = async (values: any) => {
    setLoading(true)
    try {
      await configCalendar(values);
      setReload(!reload);
      notifySuccess({
        message: "Cài đặt lịch thành công!",
        description: "Thông tin đã được cập nhật trên hệ thống Mooc!",
      });
      setIsSetting(false);
      setLoading(false)
    } catch (error) { }
  };
  useEffect(() => {
    form.setFieldsValue({
      dateForm: configData?.dateForm || 1,
      timeForm: configData?.timeForm || 1,
      notificationTime: configData?.timeLimitBefore,
      notifyStudy:
        configData?.notifyStudy !== undefined ? configData?.notifyStudy : true,
      notifyExercise:
        configData?.notifyExercise !== undefined
          ? configData?.notifyExercise
          : true,
      notifyTest:
        configData?.notifyTest !== undefined ? configData?.notifyTest : true,
      notifyExam:
        configData?.notifyExam !== undefined ? configData?.notifyExam : true,
      notifyLearnMore:
        configData?.notifyLearnMore !== undefined
          ? configData?.notifyLearnMore
          : true,
      timeLimitBefore: configData?.timeLimitBefore,
      timeTypeBefore: configData?.timeTypeBefore,
      selectedOption: configData?.selectedOption || 1,
    });
    setCustomNotification(configData?.selectedOption);
  }, [configData, form]);

  return (
    <div className="setting-schedule-calendar">
      <div className="back-btn" onClick={onBack}>
        <ArrowLeftOutlined />
        Quay lại
      </div>
      <Form
        form={form}
        name="setting-schedule-calendar"
        initialValues={{
          dateFormat: dateFormats[0].value,
          timeFormat: timeFormats[0].value,
          notificationTime: notificationTimes[1],
        }}
        onFinish={onSetting}
      >
        <div className="setting-schedule-calendar-form">
          <div className="w-300">
            <h3>Định dạng thời gian</h3>
            <label>Tùy chọn hiển thị cho thời gian</label>
          </div>
          <div className="form-list-item">
            <div className="setting-schedule-calendar-item">
              <div className="field-setting-title">Định dạng ngày</div>
              <Form.Item name="dateForm">
                <Select style={{ width: "100%", height: "44px" }}>
                  {dateFormats.map((format) => (
                    <Option key={format.value} value={format.value}>
                      {format.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="setting-schedule-calendar-item">
              <div className="field-setting-title">Định dạng giờ</div>
              <Form.Item name="timeForm">
                <Select style={{ width: "100%", height: "44px" }}>
                  {timeFormats.map((time) => (
                    <Option key={time.value} value={time.value}>
                      {time.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="hr-tag-item"></div>

        <div className="setting-schedule-calendar-form">
          <div className="w-300">
            <h3>Cài đặt thông báo</h3>
            <label>Bật / tắt thông báo</label>
          </div>
          <div className="switch-group">
            <div className="flex align-center">
              <Form.Item name="notifyStudy" valuePropName="checked">
                <Switch />
              </Form.Item>
              Lịch học
            </div>
            <div className="flex align-center">
              <Form.Item name="notifyExercise" valuePropName="checked">
                <Switch />
              </Form.Item>
              Bài tập
            </div>
            <div className="flex align-center">
              <Form.Item name="notifyTest" valuePropName="checked">
                <Switch />
              </Form.Item>
              Bài kiểm tra
            </div>
            <div className="flex align-center">
              <Form.Item name="notifyExam" valuePropName="checked">
                <Switch />
              </Form.Item>
              Bài thi
            </div>
            <div className="flex align-center">
              <Form.Item name="notifyLearnMore" valuePropName="checked">
                <Switch />
              </Form.Item>
              Lịch học thêm
            </div>
          </div>
        </div>
        <div className="hr-tag-item"></div>

        <div className="setting-schedule-calendar-form">
          <div className="w-300">
            <h3>Cài đặt thời gian thông báo</h3>
            <label>Tùy chọn thời gian cho thông báo</label>
          </div>
          <div className="form-list-item">
            <div className="setting-schedule-calendar-item">
              <div className="field-setting-title">Thời gian thông báo</div>
              <Form.Item name="selectedOption">
                <Select
                  style={{ width: "100%", height: "44px" }}
                  onChange={handleNotificationTimeChange}
                >
                  {notificationTimes.map((time) => (
                    <Option key={time.value} value={time.value}>
                      {time.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            {customNotification === 4 && (
              <div className="custom-notification-time">
                <div className="setting-schedule-calendar-item">
                  <div className="field-setting-title">Thời gian</div>
                  <Form.Item name="timeTypeBefore">
                    <FormInput
                      placeholder="Nhập số thời gian"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="setting-schedule-calendar-item">
                  <div className="field-setting-title">
                    Đơn vị tính thời gian
                  </div>
                  <Form.Item name="timeLimitBefore">
                    <Select style={{ width: "100%", height: "44px" }}>
                      {timeUnits.map((unit) => (
                        <Option key={unit?.value} value={unit?.value}>
                          {unit?.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="hr-tag-item"></div>
        <Form.Item className="action-btn">
          <Button
            type="primary"
            // htmlType="submit"
            className="save-button"
            onClick={() => setIsSetting(true)}
          >
            Lưu thay đổi
          </Button>
        </Form.Item>
      </Form>
      <ConfirmModal
        open={isSetting}
        handleOk={() => onSetting(
          form.getFieldsValue()
        )}
        handleCancel={() => setIsSetting(false)}
        title="Xác nhận lưu thay đổi"
        content={`Bạn có chắc chắn muốn lưu thay đổi không?`}
        loading={isLoading}
        confirmIcon={`${process.env.PUBLIC_URL}/assets/icons/info-circle-border.svg`}
      />
    </div>
  );
};

export default SettingScheduleCalendar;
