import React from "react";
import "./ScormViewer.scss";

interface ScormViewerProps {
  url: string;
}

const ScormViewer: React.FC<ScormViewerProps> = ({ url }) => {
  return (
    <div className="scorm-container">
      <div className="scorm-content">
        <iframe
          src={url}
          allow="fullscreen"
          scrolling="yes"
          width="100%"
          height="650px"
          style={{ borderRadius: "8px" }}
        ></iframe>
      </div>
    </div>
  );
};

export default ScormViewer;
