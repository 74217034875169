import React, { useState } from "react";
import "./MyNoteCard.scss";
import { Breadcrumb, Button, Checkbox } from "antd";
import moment from "moment";
import NoteDetailModal from "../../../pages/learning-course-structure/my-notes/components/note-detail-modal/NoteDetailModal";
import { deleteNote, pinNote } from "../../../service/learning-course";
import { globalStore, learningCourseStore } from "../../../stores/stores";
import { notifyError, notifySuccess } from "../../../utils/notification";
import ConfirmModal from "../../modal/confirm-modal/ConfirmModal";
import "./MyNoteCard.scss";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";

type Props = {
  id: number;
  title?: string;
  isChecked: boolean;
  onCheck: (id: number, checked: boolean) => void;
  isPinned?: boolean;
  createdDate?: string;
  sectionName?: string;
  sequenceName?: string;
  unitName?: string;
  content?: string;
  sectionId: number;
  sequenceId: number;
  unitId: number;
};

const MyNoteCard = (props: Props) => {
  const {
    id,
    title,
    isChecked,
    onCheck,
    isPinned,
    createdDate,
    sectionName,
    sequenceName,
    unitName,
    content,
    sectionId,
    sequenceId,
    unitId,
  } = props;
  const { reload, setReload } = globalStore();
  const { tab, setTab, setSectionId, setSequenceId, setUnitId } =
    learningCourseStore();
  const navigate = useNavigate()

  const [isDelNote, setIsDelNote] = useState<boolean>(false);
  const [isNoteDetail, setIsNoteDetail] = useState<boolean>(false);

  const handleDelNote = async () => {
    try {
      await deleteNote({
        ids: [id],
      });
      notifySuccess("Xoá ghi chú thành công");
      setIsDelNote(false);
      setReload(!reload);
    } catch (error) {
      notifyError("Có lỗi xảy ra vui lòng thử lại sau");
      console.error("Error pinning note:", error);
    }
  };
  const handlePinNote = async () => {
    try {
      const payload = {
        id: id,
        isPinned: !isPinned,
      };
      await pinNote(payload);
      notifySuccess(`${!isPinned ? "Ghim" : "Bỏ ghim"} ghi chú thành công`);
      setReload(!reload);
    } catch (error) {
      notifyError("Có lỗi xảy ra vui lòng thử lại sau");
      console.error("Error pinning note:", error);
    }
  };

  return (
    <div className={`my-note-card ${isChecked ? "my-note-card-checked" : ""}`}>
      <div>
        <div className="time-create">
          <div>
            Thời gian tạo:&nbsp;{moment(createdDate).format("DD/MM/YYYY")}
            &nbsp;•&nbsp;
            {moment(createdDate).format("HH:mm")}
          </div>
          <Checkbox
            checked={isChecked}
            onChange={(e) => onCheck(id, e.target.checked)}
          />
        </div>
        <div className="note-name">{title}</div>
        <div className="note-location mb-2">
          <Breadcrumb>
            <Breadcrumb.Item>{sectionName}</Breadcrumb.Item>
            <Breadcrumb.Item>{sequenceName}</Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => {
                setTab("1");
                setSectionId(sectionId);
                setSequenceId(sequenceId);
                setUnitId(unitId);
                navigate(`${routesConfig.learningCourseStructure}/${id}?unitId=${unitId}`)
              }}
              className="breadcrumb-unit"
            >
              {unitName}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="note-content" onClick={() => setIsNoteDetail(true)}>
          {content}
        </div>
      </div>

      <div className="note-action">
        <Button
          className={isPinned ? "pinned-note-btn" : ""}
          onClick={handlePinNote}
        >
          <img
            src={`/assets/icons/${isPinned ? "pin-white-icon.svg" : "pin-icon.svg"}`}
            alt="a"
          />
          {isPinned ? "Bỏ ghim ghi chú" : "Ghim ghi chú"}
        </Button>
        <Button onClick={() => setIsDelNote(true)}>
          <img src="/assets/icons/trash-icon.svg" alt="trash" />
          Xoá ghi chú
        </Button>
      </div>
      <ConfirmModal
        open={isDelNote}
        handleOk={handleDelNote}
        handleCancel={() => {
          setIsDelNote(false);
        }}
        title="Xác nhận xoá ghi chú"
        confirmIcon={`${process.env.PUBLIC_URL}/assets/icons/delete-icon.svg`}
        content={`Ghi chú đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá ghi chú?`}
      />

      <NoteDetailModal
        open={isNoteDetail}
        handleOk={handleDelNote}
        handleCancel={() => setIsNoteDetail(false)}
        id={id}
        noteDetail={props}
      />
    </div>
  );
};

export default MyNoteCard;
