import { Button, Dropdown, Empty, Input } from "antd";
import { MenuProps } from "antd/lib";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { getNoteListByUnit } from "../../../../../service/learning-course";
import { globalStore, learningCourseStore } from "../../../../../stores/stores";
import { Unit } from "../../../../../types/course";
import UnitNoteItem from "./unit-notes-item/UnitNoteItem";
import "./UnitNotes.scss";

interface Props {
  unitId: number;
  unitData: Unit;
}
const UnitNotes = (props: Props) => {
  const { unitId, unitData } = props;
  const { setTotalNoteByUnit, notes, setNotes, setIsAddMarked, setHighlights } =
    learningCourseStore();
  const [keySearch, setKeySearch] = useState<string>("");
  const { reload } = globalStore();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [noteAreaSelect, setNoteAreaSelect] = useState<boolean | undefined>(
    undefined
  );
  const [activeEditNoteId, setActiveEditNoteId] = useState<number | null>(null);

  const fetchNoteByUnitId = async (unitId: number, keySearch: string) => {
    const res = await getNoteListByUnit(unitId, keySearch);
    setNotes(res?.data?.data.notes);
    setTotalNoteByUnit(res?.data?.data?.notes.length);

    setHighlights(
      res?.data?.data.notes.map((note: any) => ({
        start: note?.markerTextStart,
        end: note?.markerTextEnd,
        text: note?.markerText,
        id: note?.id,
      }))
    );
  };

  const debouncedFetchNotes = debounce(fetchNoteByUnitId, 500);

  useEffect(() => {
    debouncedFetchNotes(unitId, keySearch.trim());
    return () => {
      debouncedFetchNotes.cancel();
    };
  }, [unitId, keySearch, reload]);

  useEffect(() => {
    setIsAdding(false);
  }, [unitId]);

  const addNote = () => {
    if (activeEditNoteId !== null) {
      setActiveEditNoteId(null);
    }

    if (isAdding) return;

    const note = {
      id: new Date().getTime(),
      title: "",
      content: "",
      area: "Toàn bộ học liệu",
      createdTime: new Date(),
      isEditing: false,
      isAdd: true,
    };
    setNotes([note, ...notes]);
    setIsAdding(true);
  };

  const handleEditNote = (id: number) => {
    const note = notes.find((note) => note.id === id);
    if (!note) return;

    if (isAdding) {
      setNotes(notes.filter((note) => note.isAdd !== true));
      setIsAdding(false);
    }
    setActiveEditNoteId(id);
  };

  const cancelEdit = (id: number) => {
    const note = notes.find((note) => note.id === id);

    if (note?.isAdd) {
      setNotes(notes.filter((note) => note.id !== id));
      setIsAdding(false);
    } else {
      setActiveEditNoteId(null);
    }
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "1") {
      addNote();
      setNoteAreaSelect(true);
    } else {
      addNote();
      setNoteAreaSelect(false);
      setIsAddMarked(true);
    }
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <div
          onClick={() => {
            addNote();
            setNoteAreaSelect(true);
          }}
        >
          Toàn bộ học liệu
        </div>
      ),
      key: "1",
    },
    ...(unitData?.module !== "reference"
      ? [
          {
            label: (
              <div
                onClick={() => {
                  addNote();
                  setNoteAreaSelect(false);
                  setIsAddMarked(true);
                }}
              >
                Chọn vùng ghi chú
              </div>
            ),
            key: "2",
          },
        ]
      : []),
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div className="unit-notes">
      <div className="unit-notes-action">
        <Input
          placeholder="Nhập từ khoá cần tìm"
          prefix={
            <img
              src="/assets/icons/search-lg.svg"
              alt="search"
              className="search-icon"
            />
          }
          onChange={(e) => setKeySearch(e.target.value)}
          style={{ height: "44px", maxWidth: "535px" }}
        />
        <Dropdown menu={menuProps}>
          <Button className="add-note-btn">
            <img src="/assets/icons/plus-icon.svg" alt="" />
            Thêm ghi chú
          </Button>
        </Dropdown>
      </div>
      <div className="unit-note-list">
        {notes?.length > 0 ? (
          notes?.map((note, index) => (
            <UnitNoteItem
              key={note.id}
              note={note}
              cancelEdit={cancelEdit}
              unitId={unitId}
              position={index}
              noteArea={noteAreaSelect}
              unitData={unitData}
              setIsAdding={setIsAdding}
              isEditing={activeEditNoteId === note.id}
              onEdit={handleEditNote}
            />
          ))
        ) : (
          <Empty description="Bạn chưa có ghi chú nào cho học liệu này" />
        )}
      </div>
    </div>
  );
};

export default UnitNotes;
