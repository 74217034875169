import { Breadcrumb, Col, MenuProps, Row, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageBuilderView from "../../components/PageBuilderView/PageBuilderView";
import SecondaryTab from "../../components/tabs/secondary-tab/SecondaryTab";
import {
  getCourseDetail,
  getCourseRating,
  getCourseReviewsList,
} from "../../service/courses";
import { globalStore, useCourseDetailStore } from "../../stores/stores";
import { objectToQueryString } from "../../utils/getPageFromLink";
import RelatedCourses from "./RelatedCourses/RelatedCourses";
import CoursePathway from "./course-pathway/CoursePathway";
import CourseReview from "./course-review/CourseReview";
import CourseTab from "./course-tab/CourseTab";
import EnrollmentHistory from "./enrollment-history/EnrollmentHistory";
import "./style.scss";
import useEventTrackingHistory from "../../hooks/useEventTrackingHistory";

type Props = {};
type MenuItem = Required<MenuProps>["items"][number];
const menuItem: MenuItem[] = [
  { label: "Giới thiệu", key: "introduction" },
  { label: "Lộ trình", key: "curriculum" },
  { label: "Nhận xét về khóa học", key: "review" },
  // { label: "Khóa học tương tự", key: "recommendCourse" },
];
const CourseEvaluation: React.FC<Props> = () => {
  const { id } = useParams();
  const { detail, setDetail, rating, setRating, setScreen } =
    useCourseDetailStore();
  const { reload } = globalStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [commentList, setCommentList] = useState<any[]>([]);

  useEventTrackingHistory("course-info-page")

  useEffect(() => {
    const getDataFromApi = async () => {
      if (isFirstLoad) {
        setIsLoading(true);
      }
      try {
        const detail = await getCourseDetail(Number(id));
        setDetail(detail.data.data);
      } catch (error) {
      } finally {
        if (isFirstLoad) {
          setIsLoading(false);
          setIsFirstLoad(false);
        }
      }
    };

    getDataFromApi();
  }, [id, reload, setDetail]);

  useEffect(() => {
    setScreen("course");
  }, [setScreen]);

  useEffect(() => {
    const getStarRating = async () => {
      const rating = await getCourseRating(Number(id));
      setRating(rating.data.data);
      const params = { page: 1, size: 6, timeSort: "desc" };
      const comment = await getCourseReviewsList(
        Number(id),
        objectToQueryString(params)
      );
      setCommentList(comment.data.data.comments);
    };
    getStarRating();
  }, [id, reload, setRating]);

  const schoolInfo = detail?.assigners?.find((it) => it?.isSponsor === true);
  const tabItems = [
    {
      key: "1",
      label: "Thông tin chung",
      children: (
        <>
          <div className="flex-1 w-full-100 mt-4">
            <PageBuilderView templateData={detail?.template || ""} />
          </div>
          <Row
          // className="course-evaluation-content"
          >
            <Col
              // md={{ span: 18 }}
              // lg={{ span: 18 }}
              xl={{ span: 18 }}
              xs={{ span: 24 }}
            >
              <CoursePathway idElmTag="curriculum" />
              <CourseReview
                courseReviewData={rating}
                commentList={commentList}
                isRegistered={detail?.isRegistered}
                isCommented={detail?.isCommented}
                completedPercentage={detail?.completedPercentage as any}
                learningStatus={detail?.learningStatus}
                screen="course"
                isAbleToComment={detail?.isAbleToComment}
              />
              {/* <RelatedCourses /> */}
            </Col>
            <Col
              // md={{ span: 6 }}
              // lg={{ span: 6 }}
              xl={{ span: 6 }}
              xs={{ span: 0 }}
            >
              {/* <CourseDetailTag /> */}
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "2",
      label: "Lịch sử ghi danh",
      children: <EnrollmentHistory />,
    },
  ];

  return (
    <>
      {isLoading ? (
        <div className="course-evaluation-loading">
          <Spin size="large" />
        </div>
      ) : (
        <div className="course-evaluation">
          {/* <Banner>
            <img
              className="course-evaluation-banner"
              src={`${process.env.PUBLIC_URL}/assets/img/bgg.png`}
            />
          </Banner> */}
          <div className="course-evaluation-content flex">
            <div className="w-full-100">
              <Breadcrumb
                items={[
                  {
                    key: "1",
                    title: (
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/icons/home-line.svg`}
                      />
                    ),
                  },
                  ...(detail?.isRegistered
                    ? [{ href: "", title: <span>Khoá học của tôi</span> }]
                    : []),
                  { title: detail?.name },
                ]}
              />
              <div className="course-evaluation-name">{detail?.name}</div>
              <div className="course-evaluation-rate">
                {detail?.isTrending && (
                  <div className="popular">Thịnh hành</div>
                )}
                <div className="school flex-gap-6 font-weight-6">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/graduation-hat-02.svg`}
                    style={{ width: "24px" }}
                  />
                  {schoolInfo?.name}
                  {schoolInfo?.slug && " (" + schoolInfo?.slug + ")"}
                </div>
                <div className="rate flex-gap-6">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/star.svg`}
                    style={{ width: "24px" }}
                  />
                  {rating?.rate === null
                    ? null
                    : Number(rating?.rate?.toFixed(1))}{" "}
                  {" ("}
                  {rating?.count ? rating?.count : 0} bình luận{")"}
                </div>
                <div className="flex-gap-6">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/user-circle.svg`}
                    style={{ width: "24px" }}
                  />
                  {detail?.totalStudents || 0} học viên
                </div>
                <div className="flex-gap-6">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/clock.svg`}
                    style={{ width: "24px" }}
                  />
                  Lần cập nhật gần đây nhất{" "}
                  {moment(detail?.createdDate).format("MM/yyyy")}
                </div>
              </div>
              {detail?.isRegistered ? (
                <SecondaryTab tabItems={tabItems} />
              ) : (
                <CourseTab menuItem={menuItem} />
              )}
            </div>
            {/* <CourseDetailTag {...props} /> */}
          </div>
          {!detail?.isRegistered && (
            <>
              <div className="flex-1 w-full-100">
                <PageBuilderView templateData={detail?.template || ""} />
              </div>
              <Row className="course-evaluation-content">
                <Col span={24}>
                  <CoursePathway idElmTag="curriculum" />
                  <div id="review">
                    <CourseReview
                      courseReviewData={rating}
                      commentList={commentList}
                      isRegistered={detail?.isRegistered}
                      isCommented={detail?.isCommented}
                      completedPercentage={detail?.completedPercentage as any}
                      screen="course"
                      learningStatus={detail?.learningStatus}
                      isAbleToComment={detail?.isAbleToComment}
                    />
                  </div>
                  <div id="recommendCourse">
                    <RelatedCourses />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CourseEvaluation;
