import { isArray, isNumber } from "lodash";
import { KeyValue } from "../../../types/app";
import { Flex } from "antd";

export const DiamondDot = (props: any) => {
  const { cx, cy, fill, stroke, value } = props;

  return (
    <path
      d={`M${cx},${cy - 5} L${cx - 5},${cy} L${cx},${cy + 5} L${cx + 5},${cy} Z`}
      stroke={stroke}
      fill={fill}
      strokeWidth={1}
    />
  );
};

// Custom dot component for square shape
export const SquareDot = (props: any) => {
  const { cx, cy, fill, stroke, value } = props;

  return (
    <rect
      x={cx - 5}
      y={cy - 5}
      width={10}
      height={10}
      stroke={stroke}
      fill={fill}
      strokeWidth={1}
    />
  );
};

// Custom dot component for triangle shape
export const TriangleDot = (props: any) => {
  const { cx, cy, fill, stroke, value } = props;

  return (
    <path
      d={`M${cx},${cy - 6} L${cx - 6},${cy + 6} L${cx + 6},${cy + 6} Z`}
      stroke={stroke}
      fill={fill}
      strokeWidth={1}
    />
  );
};

export const renderTotal = (total: number | KeyValue[] | undefined) => {
  if (isNumber(total))
    return (
      <div className="total-record mb-1">
        <span className="text-secondary">Tổng số:</span> <b>{total}</b>
      </div>
    );
  if (isArray(total)) {
    return (
      <div className="total-record mb-1">
        <Flex gap={16}>
          {total.map((item: KeyValue, index: number) => (
            <Flex gap={8} key={index}>
              <span className="text-secondary">{item.label}:</span>{" "}
              <b>{item.value}</b>
            </Flex>
          ))}
        </Flex>
      </div>
    );
  }
  return null
};