import { FunctionComponent, useEffect, useMemo, useState } from "react";
import "./list-survey.scss";
import {
  AutoComplete,
  Breadcrumb,
  Form,
  Layout,
  Space,
  TableProps,
  Tabs,
  TabsProps,
  Input,
  Button,
  Col,
  ConfigProvider,
} from "antd";
import { DataType } from "../../types/listTrainingNeeds";
import { EyeOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import TableData from "../../components/table-data-student/TableData";
import { useAuthStore, useSurveyRespond } from "../../stores/stores";
import { routesConfig } from "../../config/routes";
import { FormInstance } from "antd/lib";
import {
  getListNumberSurveyStudent,
  getSurveyStudent,
} from "../../service/my-survey/my-survey-service";
import { ColumnType } from "antd/es/table";
import CustomRangePicker from "../../components/custom-range-picker/CustomRangePicker";
import { viLocale } from "../../utils/picker";
import { DEFAULT_PAGE_NUM } from "../../constants";
const MySurvey: FunctionComponent = () => {
  const navigate = useNavigate();
  const userInfo = useAuthStore((state) => state.user);
  const [isToggle, setIsToggle] = useState(false);
  const userId: any = userInfo?.id;
  const [tab, setTab] = useState<string>("1");
  const [loading, setLoading] = useState<boolean>(true);
  const showFilter = false;
  const [listSurveyStudent, setListSurveyStudent] = useState<DataType[]>([]);
  const { setSurveyData } = useSurveyRespond();
  const [countTrueRecord, setCountTrueRecord] = useState<number>();
  const [countFalseRecord, setCountFalseRecord] = useState<number>();
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [keywordOptions, setKeywordOptions] = useState<any>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(DEFAULT_PAGE_NUM);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [surveyOpen, setSurveyOpen] = useState<any>(null);
  const [surveyClose, setSurveyClose] = useState<any>(null);
  const [form] = Form.useForm();
  const handleClickSurvey = (
    event: any,
    id: any,
    typeSurvey: any,
    theme: any,
    checkEnd: any
  ) => {
    event.preventDefault();
    setSurveyData([]);
    navigate(`${routesConfig.surveyRespond}/${id}/${typeSurvey}/${theme}`, {
      state: { checkEnd },
    });
  };

  const fetchData = async () =>
    await getSurveyStudent(
      {
        keyword: keyword,
        pageNumber: pageNumber,
        pageSize: pageSize,
        surveyOpen: surveyOpen,
        surveyClose: surveyClose,
        sortField: fieldSortOrder,
        sortOrder: statusOrder,
      },
      userId,
      tab
    )
      .then((res) => {
        const { data, totalRecords } = res.data.data;
        setListSurveyStudent(data);
        setTotalRecords(totalRecords);
      })
      .finally(() => setLoading(false));

  useEffect(() => {
    fetchData();
  }, [pageSize, pageNumber, statusOrder]);

  useEffect(() => {
    const fetchData = async () =>
      await getListNumberSurveyStudent(userId).then((res) => {
        const { countNotIn, countIn } = res.data.data;
        setCountFalseRecord(countNotIn);
        setCountTrueRecord(countIn);
      });
    fetchData();
  }, []);

  const TableStatus: React.FC<{ text: any; color: any }> = ({
    text,
    color,
  }) => {
    return (
      <div className="table-status">
        <div className="color-icon" style={{ backgroundColor: color }}></div>
        {text}
      </div>
    );
  };
  const RenderAdvanceSearch = () => (
    <div className="advanced-filtering-field">
      <Col span={5} className="item">
        <span className="title">Ngày bắt đầu</span>
        <Form.Item name="surveyOpen">
          <ConfigProvider locale={viLocale}>
            <CustomRangePicker
              value={surveyOpen ? surveyOpen : null}
              onChangeRangePicker={(dates) => {
                setSurveyOpen(dates);
              }}
            />
          </ConfigProvider>
        </Form.Item>
      </Col>
      <Col span={5} className="item">
        <span className="title">Ngày kết thúc</span>
        <Form.Item name="surveyClose">
          <ConfigProvider locale={viLocale}>
            <CustomRangePicker
              value={surveyClose ? surveyClose : null}
              onChangeRangePicker={(dates) => {
                setSurveyClose(dates);
              }}
            />
          </ConfigProvider>
        </Form.Item>
      </Col>
      <Col span={4} className="item">
        <div className="title">&nbsp;</div>
        <div
          className="text-clear-filters"
          onClick={() => {
            form.resetFields();
          }}
        >
          Xóa bộ lọc
        </div>
      </Col>
    </div>
  );
  const dataColumns = useMemo(() => {
    return [
      {
        title: "STT",
        width: 2,
        render: (_: any, record: any, index: number) => {
          const orderNumber = (index + 1).toString().padStart(2, "0");
          return <span className="table-order-header">{orderNumber}</span>;
        },
      },
      {
        title: "Tên đợt khảo sát",
        dataIndex: "name",
        key: "name",
        sorter: true,
        width: 4,
      },
      {
        title: "Trạng thái",
        dataIndex: "status",
        key: "status",
        render(_: any, record: any) {
          if (tab === "1") {
            const currentDate = Date.now();
            const surveyOpenDate = Date.parse(record.surveyOpen);
            const surveyCloseDate = Date.parse(record.surveyClose);
            if (!isNaN(surveyOpenDate) && !isNaN(surveyCloseDate)) {
              const isInSurveyPeriod =
                currentDate >= surveyOpenDate && currentDate <= surveyCloseDate;
              return isInSurveyPeriod ? (
                <TableStatus text="Đang mở" color="#52C41A" />
              ) : (
                <TableStatus text="Kết thúc" color="#1677FF" />
              );
            } else {
              return "Sai định dạng ngày";
            }
          } else if (tab === "2") {
            return record.status === 1 ? (
              <TableStatus text="Lưu nháp" color="#52C41A" />
            ) : (
              <TableStatus text="Đã gửi" color="#1677FF" />
            );
          }
        },
        width: 2.5,
      },
      {
        title: "Ngày bắt đầu",
        dataIndex: "surveyOpen",
        key: "surveyOpen",
        width: 2,
        render: (date: any) => convertDate(date),
      },
      {
        title: "Ngày kết thúc",
        dataIndex: "surveyClose",
        key: "surveyClose",
        width: 2,
        render: (date: any) => convertDate(date),
      },
      {
        title: "",
        dataIndex: "action",
        key: "operation",
        render: (_: any, record: any) => {
          const checkEnd =
            Date.now() >= Date.parse(record.surveyClose) ? true : false;
          const location = {
            id: `${record.surveyId}`,
            typeSurvey: `${record.status}`,
            themeSurvey: `${record.surveyTheme}`,
          };

          return (
            <Space>
              <Link
                className="action-table"
                to={`${routesConfig.surveyRespond}/${location.id}/0`}
                onClick={(e) =>
                  handleClickSurvey(
                    e,
                    location.id,
                    location.typeSurvey,
                    location.themeSurvey,
                    checkEnd
                  )
                }
              >
                <EyeOutlined />
              </Link>
            </Space>
          );
        },
        fixed: "right",
        width: 0.5,
      },
    ];
  }, [tab]);

  const convertDate = (date: string) => {
    const dateConvert = new Date(date);
    const day = dateConvert.getDate();
    const month = dateConvert.getMonth() + 1;
    const year = dateConvert.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const onChangeKeyWord = (keyword: any) => {
    setKeyword(keyword);
  };

  const clearFilter = (formInstance: FormInstance) => {
    setLoading(true);
    getSurveyStudent({}, userId, tab)
      .then((users) => {
        const { data, totalRecords } = users.data.data;
        setListSurveyStudent(data);
        setLoading(false);
        formInstance.resetFields();
        setTotalRecords(totalRecords);
      })
      .catch((err) => console.error(err));
  };

  const handleSearchClick = () => {
    setPageNumber(1);
    fetchData();
  };

  const handleTableChange: TableProps["onChange"] = (sorter: any) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    if (sorter.field === "name") {
      setFieldSortOrder("survey_name");
    }
  };

  const onChange = (key: string) => {
    setTab(key);
    setPageNumber(1);
    form.resetFields();
  };

  useEffect(() => {
    clearFilter(form);
  }, [tab]);

  const TabLabel = ({ count, text }: { count: any; text: any }) => (
    <div>
      {text}
      <span id="border-count">{count}</span>
    </div>
  );

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: <TabLabel count={countFalseRecord} text="Chờ tham gia" />,
      children: (
        <div className="waiting-assignment">
          <TableData
            dataTable={listSurveyStudent}
            dataColumns={dataColumns as ColumnType<any>[]}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            pagination={true}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: <TabLabel count={countTrueRecord} text="Đã tham gia" />,
      children: (
        <div className="assigned">
          <TableData
            dataTable={listSurveyStudent}
            dataColumns={dataColumns as ColumnType<any>[]}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            pagination={true}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
  ];
  const handleSearch = async (value: string) => {
    setKeyword(value);
    if (value) {
      const payload = {
        keyword: value,
      };
      try {
      } catch (error) {
        console.error("Error fetching keyword suggestions:", error);
        setKeywordOptions([]); // Clear the options in case of an error
      }
    } else {
      setKeywordOptions([]);
    }
  };
  const handleSelect = (value: string) => {
    setKeyword(value);
    form.setFieldsValue({ keyword: value });
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const inputValue = event.currentTarget.value;
      setKeyword(inputValue);
      form.setFieldsValue({ keyword: inputValue });

      setPageNumber(1);
    }
  };
  const onValuesChange = () => {
    setPageNumber(1);
  };
  return (
    <div className="my-surveys">
      <div className="my-surveys-list">
        <Breadcrumb
          items={[
            {
              href: "",
              title: (
                <img
                  key="home-icon"
                  src={`${process.env.PUBLIC_URL}/assets/icons/home-line.svg`}
                />
              ),
            },
            { title: "Khảo sát của tôi", key: "my-courses" },
          ]}
        />

        <div className="my-course-search">
          <Form form={form} onValuesChange={onValuesChange}>
            <div className="form-search">
              <div className="search-item">
                <AutoComplete
                  options={keyword ? keywordOptions : []}
                  onSearch={handleSearch}
                  onSelect={handleSelect}
                  value={keyword}
                  style={{ width: "100%" }}
                  notFoundContent={
                    keyword && keywordOptions.length === 0 ? (
                      <span>Không có kết quả</span>
                    ) : undefined
                  }
                  className="autocomplete-search"
                >
                  <Input
                    placeholder="Nhập từ khoá cần tìm"
                    prefix={
                      <img
                        src="/assets/icons/search-lg.svg"
                        alt="search"
                        className="search-icon"
                      />
                    }
                    allowClear
                    className="autocomplete-search"
                    onKeyDown={handleKeyPress}
                    onChange={(e) => onChangeKeyWord(e.target.value)}
                  />
                </AutoComplete>
                <Button onClick={handleSearchClick}>Tìm kiếm</Button>
              </div>

              <div className="flex gap-8">
                <Button
                  className="advanced-filtering"
                  onClick={() => setIsToggle(!isToggle)}
                >
                  <img
                    src="/assets/icons/filter-lines.svg"
                    alt="search"
                    className="search-icon"
                  />
                  Bộ lọc nâng cao
                </Button>
              </div>
            </div>
            {isToggle && <RenderAdvanceSearch />}
          </Form>
        </div>

        <Layout className="page-header-group">
          <Form.Provider onFormFinish={() => {}}>
            <div
              className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`}
            ></div>

            <div className="tabs-heading">
              <Tabs items={itemsTab} activeKey={tab} onChange={onChange} />
            </div>
          </Form.Provider>
        </Layout>
      </div>
    </div>
  );
};

export default MySurvey;
