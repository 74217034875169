import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import moment from "moment";
import { viLocale } from "../../../utils/picker";
const { RangePicker } = DatePicker;

interface NotificationFilterProps {
  onChange?: (value?: any) => void;
  onClearId?: () => void;
}

function NotificationFilter({
  onChange = () => {},
  onClearId = () => {},
}: NotificationFilterProps) {
  const [openPicker, setOpenPicker] = useState(false);
  const [values, setValues] = useState<any>();
  const [dates, setDates] = useState<any>();
  const [dateString, setDateString] = useState<string[]>([]);

  const handleOpenChange = () => {
    setOpenPicker(true);
  };

  const handleFilterDate = () => {
    setValues((prevValue: any) => ({ ...prevValue, date: dates }));
    setOpenPicker(false);
    onClearId()
  };
  useEffect(() => {
    onChange(values);
  }, [values]);

  const handleRangePickerChange = (
    dates: any,
    dateStrings: [string, string]
  ) => {
    setDates(dates);
    setDateString(dateStrings);
  };

  const renderPrevDate = () => {
    if (isEmpty(dates)) return "";

    return (
      <div className="flex flex-1">
        <div className="date-preview">
          <span className="font-size-16">
            {moment(dateString[0]).format("MMM DD, YYYY")}
          </span>
        </div>
        <span className="ml-1 mr-1">-</span>
        {dateString[1] ? (
          <div className="date-preview">
            <span className="font-size-16">
              {moment(dateString[1]).format("MMM DD, YYYY")}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const filterSeenOptions = [
    {
      value: 0,
      label: "Tất cả",
    },
    {
      value: 1,
      label: "Thông báo chưa xem",
    },
    {
      value: 2,
      label: "Thông báo đã xem",
    },
  ];

  const disabledBtnFill = () => {
    if (isEmpty(dates)) return true;

    if (isEmpty(dates[0]) || isEmpty(dates[1])) return true;

    return false;
  };

  const renderFooter = () => {
    return (
      <div className="date-picker-footer">
        <div className="flex">
          <div className="flex-1">{renderPrevDate()}</div>
          <div className="btn-footer flex gap-16">
            <Button
              className="h-40"
              onClick={() => {
                setOpenPicker(false);
                setDates(undefined);
                setValues((prevValue: any) => ({
                  ...prevValue,
                  date: null,
                }));
              }}
            >
              Huỷ
            </Button>
            <Button
              htmlType="submit"
              className="btn-primary h-40"
              onClick={handleFilterDate}
              disabled={disabledBtnFill()}
            >
              Lọc
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex align-center notification-filter">
      <h3 className="font-size-30 flex-1">Thông báo của tôi</h3>

      <Form
        onValuesChange={(value: any, values: any) => {
          setValues((preValue: any) => ({ ...preValue, ...values }));
          onClearId()
        }}
      >
        <div className="flex align-center gap-16 group-filter">
          <Row gutter={[16, 16]}>
            <Col>
              <Form.Item name="seen" initialValue={0}>
                <Select
                  className="height-44 font-weight-6 w-200"
                  options={filterSeenOptions}
                />
              </Form.Item>
            </Col>
            <Col>
              <label
                className="height-44 filter-date-btn flex align-center pointer"
                htmlFor="range-picker"
              >
                <FilterOutlined className="font-size-20" />

                <span style={{ marginLeft: 6 }} className="no-wrap">
                  Bộ lọc
                </span>
                <ConfigProvider locale={viLocale}>
                  <RangePicker
                    value={dates}
                    open={openPicker}
                    id="range-picker"
                    className="range-picker"
                    popupClassName="custom-date-picker"
                    renderExtraFooter={renderFooter}
                    onCalendarChange={handleRangePickerChange}
                    onOpenChange={handleOpenChange}
                  />
                </ConfigProvider>
              </label>
            </Col>
            <Col>
              <Form.Item name="keyword">
                <Input
                  prefix={
                    <SearchOutlined
                      style={{ color: "#667085", fontSize: 20 }}
                    />
                  }
                  className="height-44 w-250"
                  placeholder="Tìm kiếm"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default NotificationFilter;
