import { Form, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { globalStore, useCourseDetailStore } from "../../../stores/stores";
import VoteStar from "../../vote-star";
import ConfirmReviewCard from "../confirm-review-modal/ConfirmReviewModal";
import "./CommentModal.scss";
import {
  addCurriculumReview,
  editCurriculumReview,
} from "../../../service/curriculum/curriculum-service";
import { addCourseComment, editCourseComment } from "../../../service/courses";
import { notifySuccess } from "../../../utils/notification";
import { debounce } from "lodash";

type Props = {
  open: boolean;
  itemData?: any;
  handleCancel: (value: any) => void;
  handleOk: () => void;
  isEdit?: boolean;
  title: string;
  courseId?: number;
};

const CommentModal = (props: Props) => {
  const {
    open,
    handleCancel,
    handleOk,
    itemData,
    isEdit = false,
    title,
    courseId,
  } = props;

  const [form] = Form.useForm();
  const { id } = useParams();
  const { screen, setScreen } = useCourseDetailStore();
  const { setReload, reload } = globalStore();
  const [voteProps, setVoteProps] = useState(5);
  const [idReview, setIdReview] = useState(0);
  const [isConfirm, setIsConfirm] = useState(false);

  const addApiUrl = () => {
    switch (screen) {
      case "curriculum":
        return addCurriculumReview;
      case "course":
        return addCourseComment;

      default:
        return null;
    }
  };

  const editApiUrl = () => {
    switch (screen) {
      case "curriculum":
        return editCurriculumReview;
      case "course":
        return editCourseComment;
      default:
        return null;
    }
  };

  const contentModal = {
    title: "Cảm ơn bạn đã đánh giá",
    notice: "Đánh giá của bạn đã được ghi nhận trên hệ thống!",
  };

  const validations = {
    review: {
      required: true,
      message: "Nhận xét của bạn",
    },
  };

  const debouncedApiCall = debounce(async (payload, apiUrl) => {
    if (apiUrl) {
      await apiUrl(payload).then((res: any) => {
        if (res.status === 200) {
          handleOk();
          if (!isEdit) {
            setIsConfirm(true);
          } else {
            notifySuccess("Chỉnh sửa bình luận thành công");
          }
          setTimeout(() => {
            setIsConfirm(false);
          }, 2000);
          setReload(!reload);
        }
      });
    }
  }, 500);

  useEffect(() => {
    if (isEdit) {
      form.setFieldValue("review", itemData?.comment);
      setVoteProps(Number(itemData?.star));
      setIdReview(Number(itemData?.id));
    } else form.resetFields();
  }, [open, itemData]);

  useEffect(() => {
    setScreen("course");
  }, [setScreen]);

  useEffect(() => {
    setVoteProps(5);
  }, [open]);

  const VoteStar = () => {
    return (
      <div className="flex">
        {[1, 2, 3, 4, 5].map((item, index) => (
          <div key={index} onClick={() => setVoteProps(item)}>
            <img
              onClick={() => setVoteProps(item)}
              className={`avatar-star ml-1 cursor-pointer ${index + 1 > voteProps ? "star-gray" : ""}`}
              src={`${process.env.PUBLIC_URL}/assets/icons/star.svg`}
            />
          </div>
        ))}
      </div>
    );
  };
  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        const payload = !isEdit
          ? {
              [screen === "course" ? "courseId" : "curriculumId"]:
                id || courseId,
              star: voteProps,
              comment: values.review,
            }
          : {
              id: idReview,
              star: voteProps,
              comment: values.review,
              [screen === "course" ? "courseId" : "curriculumId"]: id,
            };

        const apiUrl = isEdit ? editApiUrl() : addApiUrl();
        debouncedApiCall(payload, apiUrl);
      }}
    >
      <Form form={form} name="comment-modal">
        <Modal
          title={title}
          open={open}
          className="comment-modal"
          centered
          maskClosable={false}
          // onOk={handleOk}
          onCancel={handleCancel}
          footer={() => (
            <div className="action">
              {isEdit && (
                <button onClick={handleCancel} className="cancel-btn">
                  Huỷ bỏ
                </button>
              )}
              <button onClick={() => form.submit()} className="send-message">
                {isEdit ? "Cập nhật" : "Gửi nhận xét"}
              </button>
            </div>
          )}
        >
          <div className="hr-tag"></div>
          <div className="comment-description">
            Nhận xét của bạn về{" "}
            {screen === "curriculum" ? "chương trình học" : "khóa học"}
          </div>
          <div className="comment-modal-rating">
            <Form.Item rules={[validations.review]} name="review">
              <TextArea rows={4} />
            </Form.Item>
            <div className="hr-tag"></div>
            <div className="flex">
              <p className="mr-3">Đánh giá của bạn</p>
              <VoteStar />
            </div>
          </div>
        </Modal>
      </Form>
      <ConfirmReviewCard
        open={isConfirm}
        title=""
        mode="edit"
        desc={contentModal}
        handleOk={() => setIsConfirm(false)}
      />
    </Form.Provider>
  );
};

export default CommentModal;
