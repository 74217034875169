import { Button, Col, Form, Modal, Row } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormInput from "../../../../components/form-input/FormInput";
import { CheckPrimary, CheckSecondary } from "../../../../components/icons";
import { routesConfig } from "../../../../config/routes";
import {
  getRevokeEdxToken,
  getRevokeLogOut,
} from "../../../../layout/header/services";
import { changePassword } from "../../../../service/info-user";
import { useAuthStore } from "../../../../stores/stores";
import { notifySuccess } from "../../../../utils/notification";
import { regexPassword } from "../../../../utils/regex";
import "./ModalChangePasswords.scss";
import ConfirmModal from "../../../../components/modal/confirm-modal/ConfirmModal";

type ChangePasswordProps = {
  open?: boolean;
  hideModal?: () => void;
};

const validate = {
  oldPassword: {
    required: true,
    message: "Vui lòng nhập mật khẩu.",
  },
  newPassword: {
    required: true,
    message: "Vui lòng nhập mật khẩu mới.",
  },
  confirmPassword: {
    required: true,
    message: "Vui lòng nhập lại mật khẩu mới.",
  },
};

const ModalChangePassword: FunctionComponent<ChangePasswordProps> = ({
  open,
  hideModal,
}) => {
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
  const accessToken = useAuthStore((state) => state.accessToken);
  const refreshToken = useAuthStore((state) => state.refreshToken);
  const logout = useAuthStore((state) => state.logout);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [messageError, setMessageError] = useState<string>("");
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const newPassword = allValues.newPassword;
    setIsPasswordValid(newPassword ? regexPassword.test(newPassword) : false);
    setIsPasswordLengthValid(
      newPassword ? newPassword.length >= 8 && newPassword.length <= 25 : false
    );

    if (changedValues.oldPassword && messageError) {
      setMessageError("");
      form.setFields([
        {
          name: "oldPassword",
          errors: [],
        },
      ]);
    }
  };

  const handleRevokeLogOut = async () => {
    if (refreshToken) {
      try {
        await getRevokeLogOut(refreshToken).then((res) => {
          if (res?.data?.statusCode == "200") {
            logout();
            setTimeout(() => {
              navigate(routesConfig.login);
            }, 1000);
          }
        });
      } catch (error) { }
    }
  };

  const handleLogout = async () => {
    if (accessToken) {
      try {
        await getRevokeEdxToken(accessToken).then((res) => {
          if (res?.data?.statusCode == "200") {
            handleRevokeLogOut();
          }
        });
        navigate(routesConfig.login);
      } catch (error) {
        localStorage.removeItem("auth-storage");
        window.location.replace(routesConfig.login);
      }
    }
  };

  const handlePasswordChange = async (values: any) => {
    setIsLoading(true);
    try {
      const payload = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      await changePassword(payload);
      form?.resetFields();
      setIsConfirm(false);
      setTimeout(() => {
        handleLogout();
      }, 5000);
      notifySuccess({
        message: "Thông tin cá nhân được cập nhật",
        description: "Mật khẩu của bạn đã được cập nhật trên hệ thống Mooc",
      });
      setIsLoading(false);
      setIsClose(true);
      setIsConfirm(false);
      form?.resetFields();
    } catch (error) {
      setMessageError((error as any).response.data.message);
      form.setFields([
        {
          name: "oldPassword",
          errors: [
            (error as any).response.data.message + ". Vui lòng nhập lại",
          ],
        },
      ]);
      setIsConfirm(false);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    form?.resetFields();
    hideModal && hideModal();
  };

  const handleSaveChanges = () => {
    form
      .validateFields()
      .then((values) => {
        setIsConfirm(true);
      })
      .catch((errorInfo) => {
      });
  };

  useEffect(() => {
    if (hideModal && isClose) {
      hideModal();
    }
  }, [isConfirm]);

  return (
    <>
      <Modal
        centered
        title={
          <div className="title-container">
            <img
              className="bg-icon-img"
              loading="eager"
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/img/bg_log-out-popup.png`}
            />
            <img
              className="shield-icon"
              src={`${process.env.PUBLIC_URL}/assets/icons/shield.svg`}
            />
            <div className="change-psw-title">Thay đổi mật khẩu</div>
            <div className="change-psw-description">
              Nhập thông tin bên dưới để đổi mật khẩu
            </div>
          </div>
        }
        open={open}
        onCancel={handleCancel}
        width={400}
        className="change-password-modal"
        footer={null}
      >
        <div className="form-wrapper">
          <Form
            onFinish={handlePasswordChange}
            form={form}
            layout={"vertical"}
            name="formChangePassword"
            onValuesChange={handleValuesChange}
          >
            <Row gutter={16} justify={"start"} wrap={true}>
              <Col span={24}>
                <Form.Item
                  label={
                    <div>
                      Mật khẩu hiện tại{" "}
                      <span className="icon-required-s"> *</span>
                    </div>
                  }
                  name="oldPassword"
                  rules={[validate.oldPassword]}
                  hasFeedback
                >
                  <FormInput
                    passwordInput={true}
                    placeholder="Nhập mật khẩu hiện tại"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={
                    <div>
                      Mật khẩu mới <span className="icon-required-s"> *</span>
                    </div>
                  }
                  name="newPassword"
                  rules={[
                    validate.newPassword,
                    {
                      pattern: regexPassword,
                      message: "Mật khẩu không đúng định dạng",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && getFieldValue("oldPassword") === value) {
                          return Promise.reject(
                            new Error(
                              "Mật khẩu mới phải khác mật khẩu hiện tại"
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  hasFeedback
                >
                  <FormInput
                    passwordInput={true}
                    placeholder="Nhập mật khẩu mới"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={
                    <div>
                      Xác nhận mật khẩu mới
                      <span className="icon-required-s"> *</span>
                    </div>
                  }
                  name="reWriteNewPassword"
                  dependencies={["newPassword"]}
                  hasFeedback
                  rules={[
                    validate.confirmPassword,
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Mật khẩu xác nhận chưa chính xác")
                        );
                      },
                    }),
                  ]}
                >
                  <FormInput
                    passwordInput={true}
                    placeholder="Nhập mật khẩu mới"
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="condition-container">
              <div
                className={`password-check-message ${isPasswordLengthValid ? "valid" : "invalid"}`}
              >
                {isPasswordLengthValid ? <CheckPrimary /> : <CheckSecondary />}
                Độ dài mật khẩu từ 8-25 ký tự
              </div>
              <div
                className={`password-check-message ${isPasswordValid ? "valid" : "invalid"}`}
              >
                {isPasswordValid ? <CheckPrimary /> : <CheckSecondary />}
                Bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt
              </div>
            </div>
            <Form.Item>
              <div className="footer-action">
                <Button className="footer-action-cancel" onClick={handleCancel}>
                  Huỷ bỏ
                </Button>
                <Button
                  className="footer-action-save"
                  // key="submit"
                  // type="primary"
                  // htmlType="submit"
                  onClick={handleSaveChanges}
                >
                  Lưu thay đổi
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <ConfirmModal
          open={isConfirm}
          handleOk={() => handlePasswordChange(form.getFieldsValue())}
          handleCancel={() => setIsConfirm(false)}
          title="Xác nhận lưu thay đổi"
          content={`Bạn có chắc chắn muốn thay đổi mật khẩu không?`}
          loading={isLoading}
          confirmIcon={`${process.env.PUBLIC_URL}/assets/icons/info-circle-border.svg`}
        />
      </Modal>
    </>
  );
};

export default ModalChangePassword;
