import { ArrowRightOutlined, UpOutlined } from "@ant-design/icons";
import { EventContentArg, EventInput } from "@fullcalendar/core";
import viLocale from "@fullcalendar/core/locales/vi";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Collapse, Popover } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../../config/routes";
import {
  CLASS_SCHEDULE,
  EXAM,
  EXERCISE,
  EXTRA_CLASS_SCHEDULE,
  TEST,
} from "../../../../constants";
import {
  learningCourseStore,
  studyScheduleStore,
} from "../../../../stores/stores";
import { StudyCalendarProps } from "../../../../types/calendar";
import { convertSeconds } from "../../../../utils/format";
import "./StudyScheduleCalendar.scss";
import {
  Circle,
  EventContentDayWeek,
  EventContentMonthYear,
  renderCalendarDotColor,
  renderEventClass,
  renderEventName,
} from "./event-content";
const { Panel } = Collapse;
interface Props {
  calendarList: StudyCalendarProps[];
  setDateChange: (data: any) => void;
  configData: any;
}
const StudyScheduleCalendar = (props: Props) => {
  const navigate = useNavigate();
  const { tab, setTab, setSectionId, setSequenceId, setUnitId } =
    learningCourseStore();
  const { calendarList, setDateChange, configData } = props;
  const calendarRef = useRef<FullCalendar>(null);
  const {
    initialDate = new Date().toISOString(),
    setInitialDate,
    setCurrentMonth,
    currentMonth = dayjs(new Date()).format("YYYY-MM-DD"),
    dateRange,
    setDateRange,
    currentView,
    setCurrentView,
  } = studyScheduleStore();
  const [hiddenDays, setHiddenDays] = useState<number[]>([]);

  console.log("hiddenDays", hiddenDays);

  const events: EventInput[] = calendarList;

  const customViLocale = {
    ...viLocale,
    code: "vi-custom",
    buttonText: {
      ...viLocale.buttonText,
      year: "Năm",
    },
    weekText: "Tuần",
    allDayText: "Cả ngày",
    moreLinkText: (n: number) => `+ thêm ${n}`,
    noEventsText: "Không có sự kiện để hiển thị",
    dayPopoverFormat: {
      month: "long" as
        | "long"
        | "numeric"
        | "narrow"
        | "short"
        | "2-digit"
        | undefined,
      day: "numeric" as "numeric" | "2-digit" | undefined,
      weekday: "long" as "long" | "narrow" | "short" | undefined,
    },
    weekTextLong: "Tuần",
    weekTextShort: "Tuần",
    weekTextNarrow: "Tuần",
    weekHeader: "Tuần",
    weekFormat: { week: "narrow", month: "narrow", day: "2-digit" },
    dayHeaderFormat: {
      weekday: "long" as "long" | "narrow" | "short" | undefined,
      day: "2-digit" as "2-digit" | "numeric" | undefined,
    },
    dayNamesShort: ["CN", "Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7"],
    dayNames: [
      "Chủ Nhật",
      "Thứ Hai",
      "Thứ Ba",
      "Thứ Tư",
      "Thứ Năm",
      "Thứ Sáu",
      "Thứ Bảy",
    ],
  };

  const customTitleFormat = (date: Date) => {
    const day = date.toLocaleDateString("vi-VN", { day: "2-digit" });
    const month = date.toLocaleDateString("vi-VN", { month: "2-digit" });
    const year = date.toLocaleDateString("vi-VN", { year: "numeric" });
    if (currentView === "timeGridDay") {
      return `${day} Tháng ${month}/${year}`;
    } else if (currentView === "multiMonthYear") {
      return year;
    } else {
      return `Tháng ${month}/${year}`;
    }
  };

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const updateTitle = () => {
        const titleElement = document.querySelector(".fc-toolbar-title");
        if (titleElement) {
          const view = calendarApi.view;
          const title = customTitleFormat(view.currentStart);
          titleElement.innerHTML = title;
        }
      };
      calendarApi.on("datesSet", updateTitle);
      updateTitle();
    }
  }, [currentView]);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const currentStart = dayjs(calendarApi.getDate()).startOf("day");
      const initialStart = dayjs(initialDate).startOf("day");
      if (!currentStart.isSame(initialStart)) {
        calendarApi.gotoDate(initialDate);
      }
    }
  }, [initialDate]);

  const renderEventContent = (eventInfo: any) => {
    let timeText = eventInfo.timeText;
    if (!timeText) {
      const start = moment.utc(eventInfo.event.start).format("HH:mm");
      const end = moment.utc(eventInfo.event.end).format("HH:mm");
      timeText = `${start} - ${end}`;
    }
    return (
      <Popover
        placement="right"
        content={
          <div className="calendar-dayWeek-popover">
            <div
              className={`popover-calendar-content ${renderEventClass(eventInfo?.event.extendedProps?.type)}`}
            >
              <div className="popover-calendar-dayWeek">
                Ngày{" "}
                {moment
                  .utc(eventInfo.event.startStr)
                  .format(
                    configData.dateForm === 1
                      ? "DD/MM/YYYY"
                      : configData.dateForm === 2
                        ? "YYYY/MM/DD"
                        : configData.dateForm === 2
                          ? "MM/DD/YYYY"
                          : "DD/MM/YYYY"
                  )}
              </div>
              <div className="popover-calendar-time">
                <img src="/assets/icons/clock.svg" alt="clock" />
                {timeText}
              </div>
              <div
                className="popover-calendar-action"
                onClick={() => {
                  navigate(
                    `${routesConfig.learningCourseStructure}/${eventInfo.courseId}`
                  );
                }}
              >
                Đi tới khoá học
                <ArrowRightOutlined />
              </div>
            </div>
          </div>
        }
        trigger="hover"
      >
        <div className="fc-event-content">
          <span className="fc-event-title">{eventInfo.event.title} </span>
          <div className="flex align-center gap-8">
            <img src="/assets/icons/clock.svg" />
            <div className="fc-event-time">{timeText}</div>
          </div>
        </div>
      </Popover>
    );
  };

  function isSameDay(d1: Date, d2: Date): boolean {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  const renderPopoverEvent = (type: number, event: any) => {
    switch (type) {
      case CLASS_SCHEDULE:
      case EXTRA_CLASS_SCHEDULE:
        return (
          <div>
            <div className="popover-calendar-time">
              <img src="/assets/icons/clock.svg" alt="clock" />
              {moment.utc(event?.start || "").format("HH:mm")} -&nbsp;
              {moment.utc(event?.end || "").format("HH:mm")}
            </div>
            <div className="flex gap-8">
              <div>
                <img src="/assets/icons/info-circle.svg" alt="info-circle" />
              </div>
              <div>
                <div className="pop-info-description">Thông tin</div>
                <div
                  className="pop-info-name"
                  onClick={() => {
                    navigate(
                      `${routesConfig.learningCourseStructure}/${event?.courseId}`
                    );
                    setTab("1");
                    setSectionId(event?.sectionId);
                    setSequenceId(event?.sequenceId);
                    setUnitId(event?.unitId);
                  }}
                >
                  {event.title}
                </div>
              </div>
            </div>
          </div>
        );
      case EXERCISE:
      case TEST:
      case EXAM:
        return (
          <div>
            <div className="popover-calendar-time">
              <img src="/assets/icons/clock.svg" alt="clock" />
              Hoàn thành trước ngày{" "}
              {moment(event.end).format(
                configData.dateForm === 1
                  ? "DD/MM/YYYY"
                  : configData.dateForm === 2
                    ? "YYYY/MM/DD"
                    : configData.dateForm === 2
                      ? "MM/DD/YYYY"
                      : "DD/MM/YYYY"
              )}
            </div>
            <div className="flex gap-8">
              <div>
                <img src="/assets/icons/info-circle.svg" alt="info-circle" />
              </div>
              <div>
                <div className="pop-info-description">Thông tin</div>
                <div className="pop-quiz-name">
                  <div>
                    <span>Tên:</span>
                    <span> {event.title}</span>
                  </div>
                  <div>
                    <span>Thời gian: </span>
                    <span>
                      <>
                        {convertSeconds(event.quizInfo.totalTime).hours !==
                        "00" ? (
                          <span>
                            {`${convertSeconds(event.quizInfo.totalTime).hours} giờ`}
                            &nbsp;
                          </span>
                        ) : null}
                        {convertSeconds(event.quizInfo.totalTime).minutes !==
                        "00" ? (
                          <span>
                            {`${convertSeconds(event.quizInfo.totalTime).minutes} phút`}
                            &nbsp;
                          </span>
                        ) : null}
                        {convertSeconds(event.quizInfo.totalTime).seconds !==
                        "00" ? (
                          <span>{`${convertSeconds(event.quizInfo.totalTime).seconds} giây`}</span>
                        ) : null}
                      </>
                    </span>
                  </div>
                  <div>
                    <span>Câu hỏi:</span>
                    <span> {event.quizInfo.totalQuestions} câu</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return "";
    }
  };
  const getEventsForDay = (date: Date) => {
    return events.filter((event) => {
      if (!event.start || !event.end) {
        return false;
      }
      const startDate =
        typeof event.start === "string" || typeof event.start === "number"
          ? new Date(event.start)
          : event.start instanceof Date
            ? event.start
            : new Date();
      const endDate =
        typeof event.end === "string" || typeof event.end === "number"
          ? new Date(event.end)
          : event.end instanceof Date
            ? event.end
            : new Date();

      return date >= startDate && date <= endDate;
    });
  };

  const renderDayCell = (info: any) => {
    const dayEvents = getEventsForDay(info.date);
    const defaultActiveKeys = dayEvents.map((_, index) => index.toString());

    return (
      <Popover
        placement="right"
        content={
          <div className="calendar-month-popover">
            <div className={`popover-calendar-content`}>
              <div className="popover-calendar-date">
                Ngày{" "}
                {moment(info.date).format(
                  configData.dateForm === 1
                    ? "DD/MM/YYYY"
                    : configData.dateForm === 2
                      ? "YYYY/MM/DD"
                      : configData.dateForm === 2
                        ? "MM/DD/YYYY"
                        : "DD/MM/YYYY"
                )}
              </div>
              <Collapse
                expandIcon={({ isActive }) => (
                  <UpOutlined rotate={isActive ? 180 : 0} />
                )}
                expandIconPosition="end"
                bordered={false}
                defaultActiveKey={defaultActiveKeys}
              >
                {dayEvents.map((event, index) => {
                  return (
                    <Panel
                      header={`${renderEventName(event.type)} (${event.courseName})`}
                      key={index}
                      className={event?.classNames?.[0]}
                    >
                      {renderPopoverEvent(event?.type, event)}
                      <div
                        className="popover-calendar-action"
                        onClick={() =>
                          navigate(
                            `${routesConfig.learningCourseStructure}/${event.courseId}`
                          )
                        }
                      >
                        Đi tới khoá học
                        <ArrowRightOutlined />
                      </div>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </div>
        }
        trigger="hover"
      >
        <div className="custom-day-cell">
          <div>{info.dayNumberText}</div>
          <div className="day-events">
            {dayEvents.slice(0, 4).map((event, index) => (
              <div key={index} className="event-title">
                <div className="day-event-content">
                  <div className="event-title-dot">
                    <Circle color={renderCalendarDotColor(event.type)} />
                  </div>
                  <div className="event-width-month">
                    {renderEventName(event.type)}
                  </div>
                </div>
                <div className="day-event-content">
                  {moment.utc(event.start || "").format("HH:mm")}
                </div>
              </div>
            ))}
            {dayEvents.length > 4 ? (
              <div className="more-events">
                + {dayEvents.length - 4} lịch khác
              </div>
            ) : null}
          </div>
        </div>
        {/* )} */}
      </Popover>
    );
  };

  const renderDayHeader = (info: { date: Date }) => {
    const day = info.date.toLocaleDateString("vi-VN", { day: "2-digit" });
    const weekday = info.date.toLocaleDateString("vi-VN", { weekday: "long" });

    return (
      <div
        className={
          isSameDay(info.date, new Date())
            ? "custom-day-header is-today"
            : "custom-day-header"
        }
      >
        <div className="day">{day}</div>
        <div className="weekday">{weekday}</div>
      </div>
    );
  };

  const renderEventContentDayWeek = (eventInfo: EventContentArg) => {
    return <EventContentDayWeek eventInfo={eventInfo} />;
  };

  const renderEventContentMonthYear = (eventInfo: EventContentArg) => {
    return <EventContentMonthYear eventInfo={eventInfo} />;
  };

  const filterEventsForMonthYear = (
    events: EventInput[],
    currentView: string
  ): EventInput[] => {
    if (currentView === "dayGridMonth") {
      return events.map((event) => ({
        ...event,
        classNames: [],
      }));
    }
    return events;
  };

  const handleViewChange = (view: any) => {
    setDateChange(dayjs(view.startStr).toISOString());
    if (view.view.type === "multiMonthYear") {
      const currentYear = dayjs(view.view.currentStart).year();
      const newDate = dayjs(initialDate).year(currentYear).format("YYYY-MM-DD");
      if (dayjs(view.view.currentStart).year() !== dayjs(initialDate).year()) {
        setInitialDate(newDate);
      }
      return;
    }

    if (view.view.type === "dayGridMonth") {
      const currentInitialDate = dayjs(initialDate);
      const newMonthStart = dayjs(view.view.currentStart);
      const newDate = newMonthStart
        .date(currentInitialDate.date())
        .format("YYYY-MM-DD");

      if (
        dayjs(newDate).format("MM/YYYY") !==
        dayjs(currentMonth).format("MM/YYYY")
      ) {
        setCurrentMonth(newDate);
      }
      return;
    } else if (view.view.type === "timeGridWeek") {
      const newWeekDate = dayjs(view.startStr)
        .startOf("week")
        .add(dayjs(initialDate).day() - 1, "day")
        .format("YYYY-MM-DD");
      if (newWeekDate !== initialDate) {
        setInitialDate(newWeekDate);
      }
    } else if (view.view.type === "timeGridDay") {
      const newDate = dayjs(view.startStr).format("YYYY-MM-DD");
      if (initialDate !== newDate) {
        setInitialDate(newDate);
      }
    }
  };

  const filteredEvents = filterEventsForMonthYear(events, currentView);

  const handleDateClick = (arg: any) => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.gotoDate(arg.date);
      calendarApi.changeView("timeGridDay");
      setInitialDate(arg.date.toISOString());
    }
  };
  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi && currentView) {
      calendarApi.changeView(currentView);
    }
  }, [currentView]);

  useEffect(() => {
    if (
      dateRange &&
      Array.isArray(dateRange) &&
      dateRange.length === 2 &&
      dayjs(dateRange[0]).isValid() &&
      dayjs(dateRange[1]).isValid()
    ) {
      const startDay = dayjs(dateRange[0]).day();
      const endDay = dayjs(dateRange[1]).day();

      const allDays = [0, 1, 2, 3, 4, 5, 6];

      const daysToHide = allDays.filter(
        (day) => day < startDay || day > endDay
      );

      setHiddenDays(daysToHide);
    } else {
      setHiddenDays([]);
    }
  }, [dateRange]);
  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi && dateRange && dateRange.length === 2) {
      const startDate = dayjs(dateRange[0]).toDate();
      calendarApi.gotoDate(startDate);
    }
  }, [dateRange]);

  return (
    <div className="study-schedule-calendar">
      <FullCalendar
        ref={calendarRef}
        plugins={[
          dayGridPlugin,
          multiMonthPlugin,
          timeGridPlugin,
          interactionPlugin,
        ]}
        initialView="dayGridMonth"
        hiddenDays={
          hiddenDays.length > 0 && hiddenDays.length < 7 ? hiddenDays : []
        }
        firstDay={1}
        views={{
          dayGridMonth: {
            type: "dayGridMonth",
            buttonText: "Tháng",
            dayHeaderFormat: { weekday: "long" },
            eventContent: renderEventContentMonthYear,
            dayMaxEventRows: 5,
          },
          multiMonthYear: {
            type: "multiMonth",
            buttonText: "Năm",
            multiMonthMaxColumns: 4,
            dayHeaderFormat: { weekday: "long" },
            showNonCurrentDates: true,
            multiMonthTitleFormat: { month: "long" },
            fixedWeekCount: false,

            dayCellContent: (info) => <div>{info.dayNumberText}</div>,
          },
          timeGridWeek: {
            type: "timeGridWeek",
            buttonText: "Tuần",
            dayHeaderContent: renderDayHeader,
            eventContent: (eventInfo) => {
              if (eventInfo.event.allDay) {
                return renderEventContentDayWeek(eventInfo);
              } else {
                return renderEventContent(eventInfo);
              }
            },
          },
          timeGridDay: {
            type: "timeGridDay",
            buttonText: "Ngày",
            dayHeaderContent: renderDayHeader,
            eventContent: (eventInfo) => {
              if (eventInfo.event.allDay) {
                return renderEventContentDayWeek(eventInfo);
              } else {
                return renderEventContent(eventInfo);
              }
            },
          },
        }}
        slotLabelFormat={{
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
          meridiem: false,
        }}
        slotLabelContent={(info) => {
          const time =
            configData?.timeForm === 2
              ? moment(info.date).locale("en-au").format("h A")
              : moment(info.date).format("HH:00");
          return <span>{time}</span>;
        }}
        slotLabelClassNames="custom-time-slot"
        events={filteredEvents}
        locale={customViLocale}
        eventColor="#378006"
        headerToolbar={{
          left: "prev,title,next",
          right: "today",
          center: "timeGridDay,timeGridWeek,dayGridMonth,multiMonthYear",
        }}
        eventContent={renderEventContent}
        dayHeaderFormat={{ weekday: "long", day: "2-digit" }}
        titleFormat={{ month: "2-digit", year: "numeric" }}
        datesSet={handleViewChange}
        dateClick={handleDateClick}
        dayCellContent={(info) =>
          currentView === "dayGridMonth" && renderDayCell(info)
        }
      />
    </div>
  );
};

export default StudyScheduleCalendar;
