import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const getTemplateUniversity: (
  id: string,
  type: string
) => Promise<AxiosResponse<any>> = (id: string, type: string) => {
  return axiosConfig.get(`/v2/open-api/university-template/get-template/${id}/${type}`);
};

export const getListContentBlog: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/v2/open-api/university-blog/search`, data)
}