import { Children } from "react";
import { Sections } from "../../../types/course";

export const converDataLearningCourse = (sections: Sections[]) => {

  return sections.map((section) => ({
    id: section?.id,
    name: section?.name,
    progress: section?.completedPercentage,
    time: section?.totalTime,
    totalVideo: section?.videoNumber,
    children: section?.children,
    totalUnit: section?.totalUnit,

  }));
};
