import React, { useEffect } from "react";
import { useCoursesStore } from "../stores/stores";
import {
  fetchAllIndustry,
  getGroupIndustry,
  getIndustryFilter,
  getUniversityFilter,
} from "../service/courses";
import { isEmpty } from "lodash";
import { getIndustriesFilter, getTrainingLevelFilter } from "../service/university";
import { getIndustry } from "../service/personal/personalApi";

function useMetaData() {
  const {
    industries,
    allIndustry,
    groupIndustry,
    universities,
    trainingLevelsFilter,
    industriesFilterUniversity,
    setIndustries,
    setGroupIndustry,
    setUniversities,
    setTrainingLevelFilter,
    setIndustriesFilterUniversity,
    setAllIndustry
  } = useCoursesStore((state) => state);

  useEffect(() => {
    // Get nhóm chủ đề
    if (isEmpty(groupIndustry) || groupIndustry.length === 0) {
      const getGroupOfIndustry = async () => {
        const res = await getGroupIndustry("");

        setGroupIndustry(res.data.data);
      };
      getGroupOfIndustry();
    }
  }, [groupIndustry?.length]);

  useEffect(() => {
    // get universities
    if (isEmpty(universities) || universities.length === 0) {
      const getUniversities = async () => {
        const universityList = await getUniversityFilter();

        setUniversities(universityList.data.data);
      };
      getUniversities();
    }
  }, [universities?.length]);

  useEffect(() => {
    // get industries filter
    if (isEmpty(industries) || industries.length === 0) {
      const getIndustries = async () => {
        const industryList = await getIndustryFilter();

        setIndustries(industryList.data.data);
      };
      getIndustries();
    }
  }, [industries?.length]);

  useEffect(() => {
    // get training Level Filter
    if (isEmpty(trainingLevelsFilter) || trainingLevelsFilter.length === 0) {
      const getTrainingList = async () => {
        const trainingLevelFilter = await getTrainingLevelFilter();

        setTrainingLevelFilter(trainingLevelFilter.data || []);
      };
      getTrainingList();
    }
  }, [trainingLevelsFilter?.length]);

  useEffect(() => {
    // get industriesFilterUniversity
    if (
      isEmpty(industriesFilterUniversity) ||
      industriesFilterUniversity.length === 0
    ) {
      const getIndustriesFilterUniversity = async () => {
        const res = await getIndustriesFilter();

        setIndustriesFilterUniversity(res.data || []);
      };
      getIndustriesFilterUniversity();
    }
  }, [industriesFilterUniversity?.length]);
  
  useEffect(() => {
    // get all industry
    if (
      isEmpty(allIndustry) ||
      allIndustry.length === 0
    ) {
      const getAllIndustry = async () => {
        const res = await fetchAllIndustry();
        
        setAllIndustry(res.data.data || []);
      };
      getAllIndustry();
    }
  }, [allIndustry?.length]);

  return {
    groupIndustry,
    universities,
    industries,
    trainingLevelsFilter,
    industriesFilterUniversity,
    allIndustry
  };
}

export default useMetaData;
