import React, { useState } from "react";
import "./HomeRegister.scss";
import { Col, Row } from "antd";
import FormInput from "../../../components/form-input/FormInput";
type Props = {};

const HomeRegister = (props: Props) => {
  const [email, setEmail] = useState<string>("");
  return (
    <div className="home-register">
      <Row>
        <Col xs={24} span={14}>
          <div className="text-title-36">
            Trở thành đối tác phát triển khoá học
          </div>
          <div className="home-page-description mt-2 mb-1">
            Cùng Mooc xây dựng phát triển hệ thống học trực tuyến
          </div>
        </Col>
        <Col xs={24} span={10}>
          <div className="form-register">
            <FormInput
              placeholder="Địa chỉ email của bạn"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="register-btn">Đăng ký</button>
          </div>
          <div className="view-more">
            Xem thêm tại <a>Chính sách riêng tư</a>.
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HomeRegister;
