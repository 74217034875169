import { ReactNode } from 'react';
import "./detail.scss";
type DetailContentProps = {
    children?: ReactNode
}

export function DetailContent({ children }: DetailContentProps) {
    return (
        <div className="wrapper">
            <section className="detail-account detail-survey">
                <div className="form-detail">
                    {children}
                </div>
            </section>
        </div>
    );
}


