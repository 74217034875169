import { axiosConfig } from "../../config/api/configApi";
import { AxiosResponse } from "axios";
import { NotificationSearchPayload } from "../../types/notification";

export const searchNotifications: (
  payload: NotificationSearchPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(
    `v2/api/mooc-notifications/search-notification`,
    payload
  );
};

export const editNotificationViewed: (
  id: number,
  isViewed: boolean) => Promise<AxiosResponse<any>> = (id, isViewed) => {
    return axiosConfig.put(
      `/v2/api/mooc-notifications/edit-notification-viewed/${id}?isViewed=${isViewed}`
    );
  };

export const deleteNotification: (id: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfig.delete(
    `/v2/api/mooc-notifications/delete-notification/${id}`
  );
};

export const editAllNotificationsViewed: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.put(
    `/v2/api/mooc-notifications/edit-all-notifications-viewed`
  );
};

export const deleteAllNotifications: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.delete(
    `/v2/api/mooc-notifications/delete-all-notifications`
  );
};

export const countUnviewed: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(
    `/v2/api/mooc-notifications/count-unviewed`
  );
};
