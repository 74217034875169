// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/assets/img/bg-result-delete.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/assets/img/MOOCS.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-page--result {
  padding: 96px 0;
  height: 900px;
}
.wrapper-page--result section {
  height: 100%;
}

.content-result {
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center 300%;
  background-repeat: no-repeat;
}
.content-result .ant-result {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-position: center 30%;
  background-repeat: no-repeat;
  transform: translateY(50%);
}
.content-result .ant-result .ant-result-icon svg {
  font-size: 28px;
}
.content-result .ant-result .ant-result-title {
  width: 100%;
  position: relative;
  font-size: 60px;
  letter-spacing: -0.02em;
  line-height: 72px;
  font-weight: 600;
}
.content-result .ant-result .ant-result-subtitle {
  position: relative;
  font-size: 20px;
  line-height: 30px;
}
.content-result .ant-result .ant-result-extra {
  display: flex;
  justify-content: center;
}
.content-result .ant-result .ant-result-extra button {
  height: 50px;
  padding: 0 22px;
}
.content-result .ant-result .ant-result-extra button span {
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/pages/result-delete/result-delete.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;AACF;AACE;EACE,YAAA;AACJ;;AAIA;EACE,YAAA;EACA,yDAAA;EACA,gCAAA;EACA,4BAAA;AADF;AAGE;EACE,yDAAA;EACA,+BAAA;EACA,4BAAA;EACA,0BAAA;AADJ;AAIM;EACE,eAAA;AAFR;AAMI;EACE,WAAA;EACA,kBAAA;EACA,eAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;AAJN;AAOI;EACE,kBAAA;EACA,eAAA;EACA,iBAAA;AALN;AAQI;EACE,aAAA;EACA,uBAAA;AANN;AAQM;EACE,YAAA;EACA,eAAA;AANR;AAQQ;EACE,eAAA;AANV","sourcesContent":[".wrapper-page--result {\n  padding: 96px 0;\n  height: 900px;\n\n  section {\n    height: 100%;\n  }\n\n}\n\n.content-result {\n  height: 100%;\n  background-image: url(\"../../../public/assets/img/bg-result-delete.png\");\n  background-position: center 300%;\n  background-repeat: no-repeat;\n\n  .ant-result {\n    background-image: url(\"../../../public/assets/img/MOOCS.png\");\n    background-position: center 30%;\n    background-repeat: no-repeat;\n    transform: translateY(50%);\n\n    .ant-result-icon {\n      svg {\n        font-size: 28px\n      }\n    }\n\n    .ant-result-title {\n      width: 100%;\n      position: relative;\n      font-size: 60px;\n      letter-spacing: -0.02em;\n      line-height: 72px;\n      font-weight: 600;\n    }\n\n    .ant-result-subtitle {\n      position: relative;\n      font-size: 20px;\n      line-height: 30px;\n    }\n\n    .ant-result-extra {\n      display: flex;\n      justify-content: center;\n\n      button {\n        height: 50px;\n        padding: 0 22px;\n\n        span {\n          font-size: 18px;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
