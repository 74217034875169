import type { ReactNode } from "react";
import React, { useMemo, useState } from "react";
import type { Active, UniqueIdentifier } from "@dnd-kit/core";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import "./style.scss";
import { SortableOverlay } from "./components/SoftableOverlay";
import { DragHandle, SortableItem } from "./components/SoftableItem";

interface BaseItem {
  id: UniqueIdentifier;
}

interface Props<T extends BaseItem> {
  items: T[];
  className?: string;
  disabled?: boolean;
  onChange(items: T[]): void;

  renderItem(item: T, index?: number): ReactNode;
}

export function SortableList<T extends BaseItem>({
  items,
  className = "",
  disabled = false,
  onChange,
  renderItem,
}: Props<T>) {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => items?.find((item) => item.id === active?.id),
    [active, items]
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  function swapArrayElements(items: any, index1: any, index2: any) {
    const result = [...items];
    const temp = result[index1];
    result[index1] = result[index2];
    result[index2] = temp;
    return result;
  }
  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);
      
          onChange(swapArrayElements(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext disabled={disabled} items={items}>
        <ul className={`sortableList ${className}`} role="application">
          {items.map((item, index) => (
            <React.Fragment key={index + 1}>
              {renderItem(item, index)}
            </React.Fragment>
          ))}
        </ul>
      </SortableContext>
      <SortableOverlay>
        {activeItem ? renderItem(activeItem) : null}
      </SortableOverlay>
    </DndContext>
  );
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
