import { axiosConfig } from "../../config/api/configApi";
import { AxiosResponse } from "axios";
import { CoursePayload, HistorySearchProps } from "../../types/course";
import { axiosConfigV3 } from "../../config/api/configApiV3";
import { objectToQueryString } from "../../utils/getPageFromLink";

export const getResultsQuiz: (
  sessionId: string
) => Promise<AxiosResponse<any>> = (sessionId) => {
  return axiosConfig.get(`v2/api/course/structure/exam/result/${sessionId}`);
};

export const getCoursesBlockQuiz: (
  payload: CoursePayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(`v2/open-api/course/get-course-by-filter`, payload);
};

export const getIndustryFilter: (
  payload?: CoursePayload
) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfig.post(`v2/open-api/course/get-industry-filter`, payload);
};

export const getUniversityFilter: (
  payload?: CoursePayload
) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfig.post(`v2/open-api/course/get-university-filter`, payload);
};

export const getGroupIndustry: (
  keyword?: string
) => Promise<AxiosResponse<any>> = (keyword) => {
  return axiosConfig.get(
    `v2/open-api/course/get-all-group-industry?keyword=${keyword}`
  );
};

export const exportExcelCourse: (
  payload?: CoursePayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(`v2/open-api/course/export-excel`, payload, {
    responseType: "arraybuffer",
  });
};

export const getCourseDetail: (id?: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfig.get(`/v2/open-api/course/${id}`);
};

export const getCourseRating: (id?: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfig.get(`/v2/open-api/comment/course/count?courseId=${id}`);
};

export const exportCourseReview: (
  id: number,
  params: any
) => Promise<AxiosResponse<any>> = (id: number, payload: any) => {
  return axiosConfig.post(
    `/v2/open-api/comment/course/${id}/export-excel`,
    payload,
    {
      responseType: "arraybuffer",
    }
  );
};

export const getCourseReviewsList: (
  id: number,
  params: any
) => Promise<AxiosResponse<any>> = (id: number, payload: any) => {
  return axiosConfig.get(`/v2/open-api/comment/course/${id}?${payload}`);
};

export const addCourseComment: (
  payload?: any
) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfig.post(`/v2/api/course-review/comment`, payload);
};

export const editCourseComment: (
  payload?: any
) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfig.put(`/v2/api/course-review/edit-comment`, payload);
};

export const deleteCourseComment: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfig.delete(`/v2/api/course-review/delete`, { data });
};

export const getCourseRecommend: (id: number) => Promise<AxiosResponse<any>> = (
  id: number
) => {
  return axiosConfig.get(`/v2/open-api/course/get-similar-course/${id}`);
};

export const getPopularTopics: (
  payload?: CoursePayload
) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfig.post(`/v2/open-api/course/get-all-industry`, payload);
};

export const postCourseEnrollment: (
  payload?: any
) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfig.post(`/v2/api/my-registration/register-a-course`, payload);
};

export const getFeaturedCourses: (
  payload: CoursePayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(`/v2/open-api/course/get-featured-course`, payload);
};

export const getCoursesToStarted: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV3.get(
    `/student-favorite/get-suggest-course?${objectToQueryString(payload)}`
  );
};

export const updateBehaviour: (
  behaviour_id: any,
  course_id: any,
  status: any
) => Promise<AxiosResponse<any>> = (behaviour_id, course_id, status) => {
  return axiosConfigV3.put(
    `/student-favorite/update-behaviour/${behaviour_id}?course_id=${course_id}&status=${status}`
  );
};

export const fetchAllIndustry: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/v2/open-api/course/get-all-industry`);
};

export const saveSearchHistory: (
  payload: HistorySearchProps
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(
    `/v2/open-api/log-history/save-search-history`,
    payload
  );
};
