// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-card {
  padding: 24px;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0;
  border-radius: 8px;
}
.wrapper-card .ant-card-body {
  padding: 0;
}
.wrapper-card .ant-card-head {
  padding: 0 0 16px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/custom-card/custom-card.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;EACA,SAAA;EACA,kBAAA;AACF;AACE;EACE,UAAA;AACJ;AAEE;EACE,mBAAA;AAAJ","sourcesContent":[".wrapper-card {\n  padding: 24px;\n  box-sizing: border-box;\n  max-width: 100%;\n  margin: 0;\n  border-radius: 8px;\n\n  .ant-card-body {\n    padding: 0;\n  }\n\n  .ant-card-head {\n    padding: 0 0 16px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
