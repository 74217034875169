import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Image, message, Upload, UploadFile } from "antd";
import { UploadProps } from "antd/lib";
import React, { useEffect, useState } from "react";
import "./style.scss";

type Props = {
  fileData: any;
  mode: string;
  setAttachmentsRemoved?: any;
  setAddAttachments?: any;
};

const UploadToDiscuss = (props: Props) => {
  const { fileData, mode, setAttachmentsRemoved, setAddAttachments } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<any>(null);
  const [fileDelete, setFileDelete] = useState<any[]>();

  const handlePreview = (file: any) => {
    const isImage =
      (file.type && file.type.startsWith("image/")) ||
      file?.attachmentType === "image";

    if (isImage) {
      setPreviewImage(file.mainKey);
      setPreviewVisible(true);
    } else {
      window.open(file.mainKey, "_blank");
    }
  };
  useEffect(() => {
    if (fileData) {
      setFileList(
        fileData?.map((attachment: any) => {
          return {
            uid: attachment.id,
            ...attachment,
          };
        })
      );
    }
  }, [fileData]);

  const mockUpload = ({ file, onSuccess, onError }: any) => {
    setTimeout(() => {
      if (Math.random() > 0) {
        onSuccess("ok");
      } else {
        onError("upload failed");
      }
    }, 1000);
  };
  const customFileRender = (originNode: React.ReactNode, file: any) => {
    const isImage =
      (file.type && file.type.startsWith("image/")) ||
      file?.attachmentType === "image";
    const icon = getFileIcon(file.type || file?.attachmentType || "");
    const imageUrl = isImage
      ? file?.mainKey
        ? file?.mainKey
        : URL.createObjectURL(file.originFileObj as File)
      : "";
    return (
      <div className="custom-upload-list-item">
        <div
          className="custom-upload-list-item-icon"
          onClick={() => handlePreview(file)}
        >
          {isImage ? (
            <img
              src={imageUrl}
              alt="file"
              style={{ width: "40px", height: "40px" }}
            />
          ) : (
            icon
          )}
        </div>
        <div className="custom-upload-list-item-info">
          <span className="custom-upload-list-item-name">
            <div className="name">{file.name ? file.name : file.fileName}</div>
            {mode !== "view" ? (
              <button
                className="custom-upload-list-item-remove"
                onClick={() => handleRemoveFile(file)}
              >
                <CloseOutlined />
              </button>
            ) : null}
          </span>
          {file.attachmentType ? null : (
            <span className="custom-upload-list-item-size">
              {file.size && (file.size / 1024).toFixed(2)} KB
              {file.status === "done" && (
                <span className="custom-upload-list-item-status">
                  {" "}
                  - 100% uploaded
                </span>
              )}
            </span>
          )}
        </div>
      </div>
    );
  };
  const handleRemoveFile = (file: UploadFile) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };
  useEffect(() => {
    const deleteElm = fileData?.filter(
      (item1: any) => !fileList.some((item2) => item1.id === item2?.uid)
    );
    setFileDelete(deleteElm);
    if (setAttachmentsRemoved) {
      setAttachmentsRemoved(deleteElm);
    }
  }, [fileList]);

  // useEffect(() => {
  //   if (setAddAttachments) {
  //     setAddAttachments(fileList);
  //   }
  // }, [fileList]);

  const getFileIcon = (fileType: string) => {
    switch (fileType) {
      case "application/pdf":
      case "pdf":
        return (
          <img
            src={"/assets/icons/pdf-icon.svg"}
            alt="file"
            style={{ width: "48px", height: "48px" }}
          />
        );
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "word":
        return (
          <img
            src={"/assets/icons/word-icon.svg"}
            alt="file"
            style={{ width: "48px", height: "48px" }}
          />
        );
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return (
          <img
            src={"/assets/icons/pptx-icon.svg"}
            alt="file"
            style={{ width: "48px", height: "48px" }}
          />
        );
      case "application/vnd.ms-excel":
      case "ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return (
          <img
            src={"/assets/icons/xlsx-icon.svg"}
            alt="file"
            style={{ width: "48px", height: "48px" }}
          />
        );
      case "text/plain":
      case "text":
        return (
          <img
            src={"/assets/icons/txt-icon.svg"}
            alt="file"
            style={{ width: "48px", height: "48px" }}
          />
        );
      case "image/jpeg":
      case "image/png":
      case "image/gif":
        return (
          <img
            src={URL.createObjectURL(new Blob([fileType], { type: fileType }))}
            alt="file"
            style={{ width: "48px", height: "48px" }}
          />
        );
      case "video":
        return (
          <img
            src={"/assets/icons/mp4-icon.svg"}
            alt="file"
            style={{ width: "48px", height: "48px" }}
          />
        );
      case "audio":
      case "mp3":
        return (
          <img
            src={"/assets/icons/mp3-icon.svg"}
            alt="file"
            style={{ width: "48px", height: "48px" }}
          />
        );
      default:
        return (
          <UploadOutlined style={{ fontSize: "24px", color: "#8c8c8c" }} />
        );
    }
  };
  const handleFileUploadChange: UploadProps["onChange"] = ({
    file,
    fileList: newFileList,
  }) => {
    if (file.status === "error") {
      message.error(`${file.name} tải lên thất bại.`);
    }
    setFileList(newFileList);
    if (setAddAttachments) {
      setAddAttachments(newFileList);
    }
  };
  const beforeFileUpload = (file: UploadFile) => {
    const isFile =
      file.type &&
      [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/plain",
        "image/jpeg",
        "image/png",
        "image/gif",
      ].includes(file.type);
    if (!isFile) {
      message.error(
        "Bạn chỉ có thể tải lên các tệp có định dạng PDF, DOC, DOCX, PPTX, XLSX, TXT hoặc ảnh!"
      );
    }
    return isFile || Upload.LIST_IGNORE;
  };

  return (
    <>
      <Upload
        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
        listType="picture"
        multiple
        customRequest={mockUpload}
        itemRender={customFileRender}
        onChange={handleFileUploadChange}
        beforeUpload={beforeFileUpload}
        fileList={fileList}
        showUploadList={{ showPreviewIcon: true }}
      >
        {mode !== "view" ? (
          <div className="upload-discussion-area">
            Thêm vào tài liệu của bạn
            <Button className="upload-discussion-btn">
              <img
                src={"/assets/icons/file-05.svg"}
                alt="file"
                style={{ width: "24px", height: "24px" }}
              />
              Tải tệp đính kèm
            </Button>
          </div>
        ) : null}
      </Upload>
      {previewVisible && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewVisible,
            onVisibleChange: (visible) => setPreviewVisible(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};

export default UploadToDiscuss;
