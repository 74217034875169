import type { GetProp, TableProps } from "antd";
import { Breadcrumb, Button, Checkbox, Popover, Table, Tooltip } from "antd";
import type { SorterResult } from "antd/es/table/interface";
import { CheckboxProps } from "antd/lib";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomPagination from "../../../../../components/pagination/Pagination";
import { routesConfig } from "../../../../../config/routes";
import {
  REFERENCE_MP3,
  REFERENCE_MP4,
  REFERENCE_PDF,
} from "../../../../../constants";
import {
  favoriteDocument,
  getDocumentOfCourse,
} from "../../../../../service/learning-course";
import { globalStore, learningCourseStore } from "../../../../../stores/stores";
import { notifySuccess } from "../../../../../utils/notification";
import { tableLocale } from "../../../../../utils/renderStarRating";
import HistoryUpdateModal from "../history-update-modal/HistoryUpdateModal";
import "./DocumentTable.scss";

type ColumnsType<T> = TableProps<T>["columns"];
type TablePaginationConfig = Exclude<
  GetProp<TableProps, "pagination">,
  boolean
>;

interface DataType {
  unitName: string;
  description: string;
  iconType: number;
  sectionId: number;
  sequenceId: number;
  unitId: number;
  sectionName: string;
  sequenceName: string;
  isFavorite: boolean;
  referenceType: number;
}
interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: SorterResult<any>["field"];
  sortOrder?: SorterResult<any>["order"];
  filters?: Parameters<GetProp<TableProps, "onChange">>[1];
}

const renderIcon = (iconType: number) => {
  console.log("iconType", iconType);

  switch (iconType) {
    case REFERENCE_PDF:
      return <img src={`/assets/icons/pdf-icon.svg`} alt="preview" />;
    case REFERENCE_MP4:
      return <img src={`/assets/icons/mp4-icon.svg`} alt="preview" />;
    case REFERENCE_MP3:
      return <img src={`/assets/icons/mp3-icon.svg`} alt="preview" />;

    default:
      return null;
  }
};

interface FilterParamsProps {
  documentType: string;
  keyword: string;
  section: string;
  sequence: string;
}
type Props = {
  filterParams: FilterParamsProps;
  documentTab: string;
  setDocumentDownList: (data: any) => void;
};

const DocumentTable = (props: Props) => {
  const { filterParams, documentTab, setDocumentDownList } = props;

  const { reload, setReload } = globalStore();
  const { tab, setTab, setSectionId, setSequenceId, setUnitId } =
    learningCourseStore();
  const navigate = useNavigate();

  const { id } = useParams();
  const [data, setData] = useState<DataType[]>();
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPage, setTotalPage] = useState(0);

  const [documentDownload, setDocumentDownload] = useState<number[]>([]);
  const [isHistory, setIsHistory] = useState<boolean>(false);
  const [documentId, setDocumentId] = useState<number>(0);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const fetchData = async () => {
    setLoading(true);
    const payloads = {
      page: pageNumber,
      size: pageSize,
      sort: tableParams?.sortOrder
        ? [`title,${tableParams?.sortOrder === "ascend" ? "asc" : "desc"}`]
        : undefined,
      keyword:
        filterParams && filterParams.keyword
          ? filterParams.keyword.trim()
          : undefined,
      courseId: Number(id),
      sectionId:
        filterParams && filterParams.section ? filterParams.section : undefined,
      sequenceId:
        filterParams && filterParams.sequence
          ? filterParams.sequence
          : undefined,
      type:
        filterParams && filterParams.documentType
          ? filterParams.documentType
          : undefined,
      isFavorite: documentTab === "1" ? undefined : true,
    };

    try {
      const res = await getDocumentOfCourse(payloads);

      setData(res?.data.data.references);
      setTotalPage(res?.data.data.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [documentTab, tableParams, filterParams, pageNumber, pageSize, reload]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : sorter.field,
    });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  const handleCheckDocument: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      setDocumentDownload((oldState) => [...oldState, e.target.value]);
    } else {
      setDocumentDownload((oldState) =>
        oldState.filter((it) => it !== e.target.value)
      );
    }
  };
  useEffect(() => {
    setDocumentDownList(documentDownload);
  }, [documentDownload]);

  const handleFavoriteDocument = async (records: DataType) => {
    const payload = {
      unitId: records.unitId,
      isFavorite: !records.isFavorite,
    };

    try {
      await favoriteDocument(payload);
      notifySuccess(
        !records.isFavorite
          ? "Thêm vào tài liệu yêu thích thành công!"
          : "Bỏ thích tài liệu thành công!"
      );
      setReload(!reload);
    } catch (error) {}
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleDownloadLink = async (record: any) => {
    try {
      const response = await fetch(record.mainKey || record.referenceUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${record.unitName}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Download failed", error);
    }
  };

  const popoverContent = (records: any) => {
    return (
      <div className="my-course-popover__content">
        <div
          className="popover-item"
          onClick={() => openInNewTab(records.mainKey || records.referenceUrl)}
        >
          <img
            src="/assets/icons/eye-icon.svg"
            alt="search"
            className="popover-item-icon"
          />
          Xem trước
        </div>
        <div
          className="popover-item"
          onClick={() => handleDownloadLink(records)}
        >
          <img
            src="/assets/icons/download-cloud-02.svg"
            alt="search"
            className="popover-item-icon"
          />
          Tải xuống
        </div>
        <div
          className="popover-item"
          onClick={() => {
            setDocumentId(records?.unitId);
            setIsHistory(true);
          }}
        >
          <img
            src="/assets/icons/clock-fast-forward.svg"
            alt="search"
            className="popover-item-icon"
          />
          Lịch sử cập nhật
        </div>
      </div>
    );
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "Tên tài liệu",
      dataIndex: "name",
      sorter: true,
      render: (name, records) => (
        <>
          <div className="flex gap-12">
            <Checkbox
              value={records.unitId}
              onChange={(e) => handleCheckDocument(e)}
            />
            {renderIcon(records.referenceType)}
            <div>
              <div
                className="document-name"
                onClick={() => {
                  setTab("1");
                  setSectionId(records.sectionId);
                  setSequenceId(records.sequenceId);
                  setUnitId(records.unitId);
                  navigate(
                    `${routesConfig.learningCourseStructure}/${id}?unitId=${records.unitId}`
                  );
                }}
              >
                {records.unitName}
              </div>
              <Tooltip
                placement="topLeft"
                title={records.description}
                overlayStyle={{ maxWidth: "40%" }}
              >
                <div className="document-description">
                  {records.description}
                </div>
              </Tooltip>
            </div>
          </div>
        </>
      ),
      width: "60%",
    },
    {
      title: "Vị trí",
      dataIndex: "location",
      width: "50%",
      render: (name, records) => (
        <div className="flex justify-between">
          <div className="breadcrumb-location">
            <Breadcrumb
              items={[
                {
                  title: records.sectionName,
                },
                { title: records.sequenceName, key: "my-courses" },
              ]}
            />
          </div>
          <div className="action-favorite">
            <div
              onClick={() => handleFavoriteDocument(records)}
              className="flex align-center pointer"
            >
              {records.isFavorite ? (
                <img
                  src={`/assets/icons/heart-rounded-primary.svg`}
                  alt="preview"
                  className="document-favorite-icon"
                />
              ) : (
                <img
                  src={`/assets/icons/heart-rounded.svg`}
                  alt="preview"
                  className="document-favorite-icon"
                />
              )}
            </div>

            <Popover
              placement="bottomLeft"
              content={popoverContent(records)}
              className="card-popover"
            >
              <Button className="document-popover-btn">
                <img
                  src="/assets/icons/dots-vertical.svg"
                  alt="search"
                  className="card-popover-img"
                />
              </Button>
            </Popover>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="document-table">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        onChange={handleTableChange}
        locale={tableLocale}
      />
      <CustomPagination
        totalPage={totalPage}
        pageSize={pageSize}
        pageNumber={pageNumber}
        setPageSize={setPageSize}
        setPageNumber={setPageNumber}
      />
      <HistoryUpdateModal
        open={isHistory}
        handleOk={() => setIsHistory(false)}
        handleCancel={() => setIsHistory(false)}
        id={documentId}
      />
    </div>
  );
};

export default DocumentTable;
