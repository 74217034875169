// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../../public/assets/fonts/Inter/Inter.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../../../public/assets/fonts/Inter/Inter.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../../../../public/assets/fonts/Inter/Inter.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../../../../public/assets/fonts/Inter/Inter-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../../../../../public/assets/fonts/Inter/Inter-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../../../../../../public/assets/fonts/Inter/Inter-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../../../../../../public/assets/fonts/Inter/Inter-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../../../../../../public/assets/fonts/Inter/Inter-SemiBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../../../../../../public/assets/fonts/Inter/Inter-SemiBold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Inter";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2");
  font-style: normal;
}
@font-face {
  font-family: "Inter-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype"), url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff2");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Inter-Semi-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("truetype"), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format("woff2");
  font-style: normal;
}
.text-viewer {
  max-height: 650px;
  overflow-y: scroll;
  position: relative;
  overflow: auto;
}
.text-viewer .highlight {
  background-color: #7f56d9;
  display: inline-block;
}
.text-viewer p {
  font-weight: 400;
}
.text-viewer mark {
  cursor: pointer;
}

.text-viewer-popover {
  height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/styles/_font.scss","webpack://./src/pages/learning-course-structure/learning-course/components/text-viewer/TextViewer.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,gLACE;EAGF,kBAAA;ACFF;ADKA;EACE,yBAAA;EACA,gLACE;EAGF,iBAAA;EACA,kBAAA;ACNF;ADSA;EACE,8BAAA;EACA,gLACE;EAGF,kBAAA;ACVF;AAbA;EACE,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;AAeF;AAdE;EACE,yBAAA;EACA,qBAAA;AAgBJ;AAbE;EACE,gBAAA;AAeJ;AAZE;EACE,eAAA;AAcJ;;AAXA;EACE,mBAAA;AAcF","sourcesContent":["@font-face {\n  font-family: \"Inter\";\n  src:\n    url(\"../../public/assets/fonts/Inter/Inter.ttf\") format(\"truetype\"),\n    url(\"../../public/assets/fonts/Inter/Inter.woff\") format(\"woff\"),\n    url(\"../../public/assets/fonts/Inter/Inter.woff2\") format(\"woff2\");\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"Inter-Bold\";\n  src:\n    url(\"../../public/assets/fonts/Inter/Inter-Bold.ttf\") format(\"truetype\"),\n    url(\"../../public/assets/fonts/Inter/Inter-Bold.woff\") format(\"woff\"),\n    url(\"../../public/assets/fonts/Inter/Inter-Bold.woff2\") format(\"woff2\");\n  font-weight: bold;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"Inter-Semi-Bold\";\n  src:\n    url(\"../../public/assets/fonts/Inter/Inter-SemiBold.ttf\") format(\"truetype\"),\n    url(\"../../public/assets/fonts/Inter/Inter-SemiBold.woff\") format(\"woff\"),\n    url(\"../../public/assets/fonts/Inter/Inter-SemiBold.woff2\") format(\"woff2\");\n  font-style: normal;\n}","@import \"../../../../../styles/variables\";\n\n.text-viewer {\n  max-height: 650px;\n  overflow-y: scroll;\n  position: relative;\n  overflow: auto;\n  .highlight {\n    background-color: $primary;\n    display: inline-block;\n  }\n\n  p {\n    font-weight: 400;\n  }\n\n  mark {\n    cursor: pointer;\n  }\n}\n.text-viewer-popover {\n  height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
