import { Spin } from "antd";
import "./loading-full-screen.scss";
import React from "react";

interface SpinnerProps {
  spinning?: boolean;
}
const LoadingFullScreen: React.FC<SpinnerProps> = ({ spinning }) => {
  return (
    <div className="loading-container">
      <div className="loading-content">
        <Spin size="large" />
      </div>
    </div>
  );
};

export default LoadingFullScreen;
