import { getDocument } from "pdfjs-dist/build/pdf";
import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigToUpload } from "../../config/api/configApiUpload";

export const changePassword: (params: any) => Promise<AxiosResponse<any>> = (
  params: any
) => {
  return axiosConfig.post(`v2/auth/change-password`, params);
};
