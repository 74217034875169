import { Avatar, Button, MenuProps } from "antd";
import MoocBreadcrumb from "../../../components/breadcrumb";
import { routesConfig } from "../../../config/routes";
import "../styles.scss";
import { getFirstLetter } from "../../../utils/element";
import { EyeOutlined, HeartOutlined, LikeOutlined } from "@ant-design/icons";
import { stripHtmlTags } from "../../../utils/format";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getTopicData,
  postAllDiscussionTopic,
  postReactTopic,
  postTopicFollow,
  postTopicView,
} from "../../../service/blog/blog";
import Discuss from "../discussion/Discussion";
import { notifyError, notifySuccess } from "../../../utils/notification";

interface TopicData {
  id: number;
  name: string;
  description: string;
  blogId: number;
  enterpriseUuid: string;
  status: number;
  comment: number;
  react: number;
  follow: number;
  view: number;
  isCreated: boolean;
  isFollow: boolean;
  myReaction: boolean;
  authorName: string;
  authorAvatar: string;
  createdBy: number;
  createdDate: string;
  updatedDate: string;
  lastDiscussDate: string;
}

export const CommentComponent = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const blogId = searchParams.get("blogId");
  const topicId = searchParams.get("topicId");
  const uuidValue = searchParams.get("uuid");
  const slugValue = searchParams.get("slug");
  const [page, setPage] = useState<number>(1);
  const [newPage, setNewPage] = useState(false);
  const [pageSize, setPageSize] = useState<number>(6);
  const [topicData, setTopicData] = useState<TopicData>();
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [dataTopic, setDataTopic] = useState();

  const handleGetTopicDiscussion = async () => {
    setLoading(true);
    try {
      const dataParam = {
        page: page,
        size: pageSize,
        topicId: Number(topicId),
        enterpriseUUID: uuidValue,
      };
      const res = getTopicData(topicId).then((result) => {
        setTopicData(result.data.data);
        setLoading(false)
      });
    } catch (error) {
      setLoading(false)
      console.error("Error:", error);
    }
  };

  const breadcrumbItem = [
    {
      href: "",
      title: (
        <img src={`${process.env.PUBLIC_URL}/assets/icons/home-line.svg`} />
      ),
    },
    {
      href: `/${routesConfig.blog}/${routesConfig.topic}?blogId=${blogId}&uuid=${uuidValue}&slug=${slugValue}`,
      title: (
        <>
          <span>Danh sách chủ đề</span>
        </>
      ),
    },
    { title: `${stripHtmlTags(topicData?.name || "Chủ đề của tôi")}` },
  ];

  const handleLoadMore = () => {
    setNewPage(true);
    setPage((prevPage) => prevPage + 1);
  };

  const handleReactionTopic = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const data = {
          id: Number(topicId),
          reactionType: 1,
        };
        postReactTopic(data).then((result) => {
          // notifySuccess('')
          handleGetTopicDiscussion();
        });
      } catch (error) {
        console.error("Err:", error);
        notifyError("Có lỗi xảy ra");
        setLoading(false);
      }
    }
  };

  const handleFollowTopic = async () => {
    if (!loading) {
      setLoading(true);
      try {
        postTopicFollow(topicId).then((result) => {
          // notifySuccess('')
          handleGetTopicDiscussion();
        });
      } catch (error) {
        console.error("Err:", error);
        notifyError("Có lỗi xảy ra");
        setLoading(false);
      }
    }
  };

  const handleViewTopic = async () => {
      setLoading(true);
      try {
        postTopicView(topicId).then((result) => {
          // notifySuccess('')
          handleGetTopicDiscussion();
        });
      } catch (error) {
        console.error("Err:", error);
        notifyError("Có lỗi xảy ra");
      }
    
  };

  useEffect(() => {
    handleViewTopic();
  }, []);

  useEffect(() => {
    handleGetTopicDiscussion();
  }, [page, search]);

  return (
    <div className="blog-comment">
      <div className="container">
        {/* Header */}
        <div className="comment-container">
          <div className="header-left">
            <div className="header-breadcrumb">
              <MoocBreadcrumb
                items={breadcrumbItem}
                className={"header-breadcrumb"}
              />
            </div>
            <div className="avatar-container">
              <Avatar
                src={topicData?.authorAvatar}
                size={48}
                className="mt-2 mb-2"
              >
                {getFirstLetter(topicData?.authorName || "")}
              </Avatar>
              <div className="author-container">
                <div className="author-style">{topicData?.authorName}</div>
                <div className="create-date-style">
                  {moment(topicData?.createdDate).format("DD/MM/YYYY")}
                </div>
              </div>
            </div>
            <div className="text-6xl mt-2 mb-2 learning-title font-semibold">
              {stripHtmlTags(topicData?.name || "")}
            </div>
            <p>{stripHtmlTags(topicData?.description || "")}</p>
            <div className="flex align-center action-container">
              <div
                onClick={() => {
                  handleFollowTopic();
                }}
                className="action"
              >
                {topicData?.isFollow ? (
                  <HeartOutlined className="icon-action" />
                ) : (
                  <HeartOutlined />
                )}
                <div className="total">{topicData?.follow}</div>
              </div>
              <div className="diviner"></div>
              <div
                onClick={() => {
                  handleReactionTopic();
                }}
                className="action"
              >
                {topicData?.myReaction ? (
                  <LikeOutlined className="icon-action" />
                ) : (
                  <LikeOutlined />
                )}
                <div className="total">{topicData?.react}</div>
              </div>
              <div className="diviner"></div>
              <div className="action">
                <EyeOutlined />
                <div className="total">
                  {topicData?.view ? topicData.view : 0}
                </div>
              </div>
            </div>
            {/* Comment part */}
            <Discuss />
            {/* <div className="comment-component-container">
              <div className="header-component">
                <div className="title-comment">Tất cả bình luận</div>
                <div>Tổng hợp tất cả các bình luận của bài đăng</div>
              </div>
              <Button onClick={() => {}} className="add-button bg-primary">
                <img src="/assets/icons/plus-icon.svg" alt="" />
                Thêm bình luận
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
