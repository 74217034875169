import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import StatisticIntro from "../../components/statistic-intro/StatisticIntro";
import ResponsiveList from "../../components/list/ResponsiveList";
import { Button, Collapse, CollapseProps, Input, Row, Typography, Form, Col } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import PaginatedSelect from "../../components/PaginatedSelect/PaginatedSelect";
import { useForm } from "antd/es/form/Form";
import { CustomCaptcha, CustomCaptchaElement } from "../../components/captcha/CustomCaptcha";
import { createJoinMooc, getCountry, getDistricts, getManagementAgencys, getProvinces, getRoles, getTypes, getTypesSchool, getWards } from "../../service/personal/personalApi";
import { isEmpty } from "lodash";
import { message } from "antd/lib";
import ConfirmModal from "../../components/modal/confirm-modal/ConfirmModal";
import { notifySuccess } from "../../utils/notification";

const JoinPlatform = () => {
  const [captchaToken, setCaptchaToken] = useState("");
  let captchaRef = useRef<CustomCaptchaElement>(null);
  const [checkedCaptcha, setCheckedCaptcha] = useState(false);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [dataSave, setDataSave] = useState<any>();

  const navigate = useNavigate()

  const [dataOptions, setDataOptions] = useState<any>({
    dataProvinces: [],
    dataDistricts: [],
    dataWards: [],
    dataRoles: [],
    dataCountry: [],
    dataTypeSchool: [],
    dataType: [],
    dataManageAgency: [],
  });

  const [form] = useForm();

  const listOptionAgency = [
    {
      value: '1',
      label: 'Cơ sở 1'
    },
    {
      value: '2',
      label: 'Cơ sở 2'
    },
  ]

  const listOptionSchool = [
    {
      value: '1',
      label: 'Đại học'
    },
    {
      value: '2',
      label: 'Cao đẳng'
    },
    {
      value: '3',
      label: 'Liên kết'
    },
    {
      value: '4',
      label: 'Chất lượng cao'
    },
    {
      value: '5',
      label: 'Cao học'
    }
  ]

  const listOptionType = [
    {
      value: '1',
      label: 'Công lập'
    },
    {
      value: '2',
      label: 'Ngoài công lập'
    },
  ]

  const validations = {
    managementAgency: {
      required: true,
      message: 'Vui lòng chọn cơ quan quản lý'
    },
    nameAgency: {
      required: true,
      message: "Vui lòng nhập tên cơ đào tạo"
    },
    typeSchool: {
      required: true,
      message: "Vui lòng chọn loại trường"
    },
    type: {
      required: true,
      message: "Vui lòng chọn loại trường"
    },
    provinceId: {
      required: true,
      message: "Vui lòng chọn Thành phố/Tỉnh"
    },
    districtId: {
      required: true,
      message: "Vui lòng chọn Quận/Huyện"
    },
    wardId: {
      required: true,
      message: "Vui lòng chọn Phường/Thị xã"
    },
    detailAddress: {
      required: true,
      message: "Vui lòng nhập địa chỉ chi tiết"
    },
    contactPeople: {
      required: true,
      message: "Vui lòng nhập tên người liên hệ"
    },
    contactRolePeople: {
      required: true,
      message: "Vui lòng nhập chức vụ của người liên hệ"
    },
    phone: {
      required: true,
      message: "Vui lòng nhập số điện thoại"
    },
    email: {
      required: true,
      message: "Vui lòng nhập email"
    }
  }

  const handleChangeCountry = (value: string) => {
    fetchProvinces(value);
    form.setFieldsValue({
      countryId: value,
      provinceId: undefined,
      districtId: undefined,
      wardId: undefined,
    });
  };

  const handleChangeProvince = (value: string) => {
    fetchDistrict(value);
    form.setFieldsValue({
      provinceId: value,
      districtId: undefined,
      wardId: undefined,
    });
  };

  const handleChangeDistrict = (value: string) => {
    fetchWard(value);
    form.setFieldsValue({
      districtId: value,
      wardId: undefined,
    });
  };

  const blockOne = () => (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="managementAgency" label="Cơ quan quản lý" rules={[validations.managementAgency]}>
            <PaginatedSelect
              options={dataOptions.dataManageAgency}
              pageSize={100}
              debounceTimeout={800}
              placeholder="Chọn cơ quan quản lý"
              style={{ width: "100%", minHeight: 44 }}
              allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="acronyms" label="Từ viết tắt">
            <Input
              className="default-height-input" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} className="mt-3">
        <Col span={24}>
          <Form.Item name="nameAgency" label="Tên cơ sở đào tạo" rules={[validations.nameAgency]}>
            <Input
              placeholder="Nhập tên cơ sở đào tạo"
              className="default-height-input" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} className="mt-3">
        <Col span={24}>
          <Form.Item name="englishNameAgency" label="Tên tiếng anh của cơ sở đào tạo">
            <Input
              placeholder="Nhập tiếng anh"
              className="default-height-input" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} className="mt-3">
        <Col span={12}>
          <Form.Item name="typeSchool" label="Loại trường" rules={[validations.typeSchool]}>
            <PaginatedSelect
              options={dataOptions.dataTypeSchool}
              pageSize={100}
              debounceTimeout={800}
              placeholder="Chọn loại trường"
              style={{ width: "100%", minHeight: 44 }}
              allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="type" label="Loại hình" rules={[validations.type]}>
            <PaginatedSelect
              options={dataOptions.dataType}
              pageSize={100}
              debounceTimeout={800}
              placeholder="Chọn loại hình"
              style={{ width: "100%", minHeight: 44 }}
              allowClear={true} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )

  const blockTwo = () => (
    <>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="provinceId" label="Thành phố/Tỉnh" rules={[validations.provinceId]}>
            <PaginatedSelect
              onChange={handleChangeProvince}
              options={dataOptions?.dataProvinces}
              pageSize={100}
              debounceTimeout={800}
              placeholder="Chọn thông tin"
              style={{ width: "100%", minHeight: 44 }}
              allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="districtId" label="Quận/Huyện" rules={[validations.districtId]}>
            <PaginatedSelect
              onChange={handleChangeDistrict}
              options={dataOptions?.dataDistricts}
              pageSize={100}
              debounceTimeout={800}
              placeholder="Chọn thông tin"
              style={{ width: "100%", minHeight: 44 }}
              allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="wardId" label="Phường/Thị xã" rules={[validations.wardId]}>
            <PaginatedSelect
              options={dataOptions?.dataWards}
              pageSize={100}
              debounceTimeout={800}
              placeholder="Chọn thông tin"
              style={{ width: "100%", minHeight: 44 }}
              allowClear={true} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} className="mt-3">
        <Col span={24}>
          <Form.Item name="detailAddress" label="Địa chỉ chi tiết" rules={[validations.detailAddress]}>
            <Input
              placeholder="Nhập số nhà, đường, thôn, xóm,..."
              className="default-height-input" />
          </Form.Item>
        </Col>
      </Row>
    </>
  )

  const blockThree = () => (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="contactPeople" label="Người liên hệ" rules={[validations.contactPeople]}>
            <Input
              placeholder="Nhập tên người liên hệ"
              className="default-height-input" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="contactRolePeople" label="Chức vụ" rules={[validations.contactRolePeople]}>
            <PaginatedSelect
              options={dataOptions?.dataRoles}
              pageSize={100}
              debounceTimeout={800}
              placeholder="Nhập chức vụ của người liên hệ"
              style={{ width: "100%", minHeight: 44 }}
              allowClear={true} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} className="mt-3">
        <Col span={12}>
          <Form.Item name="phone" label="Điện thoại" rules={[validations.phone]}>
            <Input
              placeholder="Nhập số điện thoại"
              className="default-height-input" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="email" label="Email" rules={[validations.email]}>
            <Input
              placeholder="Nhập địa chỉ email"
              className="default-height-input" />
          </Form.Item>
        </Col>
      </Row>
    </>
  )

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: <Typography.Title level={5}><strong>Thông tin cơ sở đào tạo</strong></Typography.Title>,
      children: blockOne(),
    },
    {
      key: '2',
      label: <Typography.Title level={5}><strong>Địa chỉ cơ sở đào tạo</strong></Typography.Title>,
      children: blockTwo(),
    },
    {
      key: '3',
      label: <Typography.Title level={5}><strong>Thông tin liên hệ</strong></Typography.Title>,
      children: blockThree(),
    },
  ];

  const getListRole = async () => {
    try {
      const res = await getRoles();
      const roles = res.data.data?.map((data: any) => ({
        ...data,
        value: data.id,
        label: data.name,
      }));
      setDataOptions((prevOptions: any) => ({
        ...prevOptions,
        dataRoles: roles,
      }));
    } catch (error) {
      console.error("Failed to fetch countries:", error);
    }
  };

  const getManagementAgency = async () => {
    try {
      const res = await getManagementAgencys();
      const roles = res.data.data?.map((data: any) => ({
        ...data,
        value: data.id,
        label: data.name,
      }));
      setDataOptions((prevOptions: any) => ({
        ...prevOptions,
        dataManageAgency: roles,
      }));
    } catch (error) {
      console.error("Failed to fetch countries:", error);
    }
  };

  const getTypeSchool = async () => {
    try {
      const res = await getTypesSchool();
      const roles = res.data.data?.map((data: any) => ({
        ...data,
        value: data.id,
        label: data.name,
      }));
      setDataOptions((prevOptions: any) => ({
        ...prevOptions,
        dataTypeSchool: roles,
      }));
    } catch (error) {
      console.error("Failed to fetch countries:", error);
    }
  };

  const getType = async () => {
    try {
      const res = await getTypes();
      const roles = res.data.data?.map((data: any) => ({
        ...data,
        value: data.id,
        label: data.name,
      }));
      setDataOptions((prevOptions: any) => ({
        ...prevOptions,
        dataType: roles,
      }));
    } catch (error) {
      console.error("Failed to fetch countries:", error);
    }
  };

  const fetchProvinces = async (countryId: string) => {
    try {
      const res = await getProvinces(countryId);
      const provinces = res.data.data?.map((data: any) => ({
        ...data,
        value: data.code,
        label: data.name,
      }));
      setDataOptions((prevOptions: any) => ({
        ...prevOptions,
        dataProvinces: provinces,
      }));
    } catch (error) {
      console.error("Failed to fetch provinces:", error);
    }
  };

  const fetchDistrict = async (provinceId: string) => {
    if (!isEmpty(form.getFieldValue("provinceId"))) {
      try {
        const res = await getDistricts(provinceId);
        setDataOptions((prevOptions: any) => ({
          ...prevOptions,
          dataDistricts: res.data.data?.map((data: any) => ({
            ...data,
            value: data.code,
            label: data.name,
          })),
        }));
      } catch (error) {
        console.error("Failed to fetch districts:", error);
      }
    }
  };

  const fetchWard = async (districtId: string) => {
    if (!isEmpty(form.getFieldValue("districtId"))) {
      try {
        const res = await getWards(districtId);
        setDataOptions((prevOptions: any) => ({
          ...prevOptions,
          dataWards: res.data.data?.map((data: any) => ({
            ...data,
            value: data.code,
            label: data.name,
          })),
        }));
      } catch (error) {
        console.error("Failed to fetch wards:", error);
      }
    }
  };

  const saveData = async () => {
    console.log('1231231', dataSave)
    const data = {
      organizationId: Number(dataSave?.managementAgency),
      name: dataSave?.nameAgency,
      nameEn: dataSave?.englishNameAgency,
      acronym: dataSave?.acronyms,
      universityTypeId: Number(dataSave?.typeSchool),
      trainingTypeId: Number(dataSave?.type),
      provinceCode: dataSave?.provinceId,
      districtCode: dataSave?.districtId,
      wardCode: dataSave?.wardId,
      address: dataSave?.detailAddress,
      userContact: dataSave?.contactPeople,
      userContactPositionId: Number(dataSave?.contactRolePeople),
      userContactPhone: dataSave?.phone,
      userContactEmail: dataSave?.email
    }

    await createJoinMooc(data).then((res) => {
      if (res.status === 200) {
        navigate(routesConfig.systemInfo)
        const msg = {
          message: 'Đăng ký thông tin thành công!',
          description: 'Hệ thống đã gửi ghi nhận đăng ký trở thành đối tác của bạn.'
        }
        setTimeout(() => {
          notifySuccess(msg);
        }, 1000);
      }
    })
  }

  useEffect(() => {
    handleChangeCountry("4")
    getListRole()
    getManagementAgency()
    getType()
    getTypeSchool()
  }, []);

  useEffect(() => {
    fetchProvinces(form.getFieldValue("countryId"));
  }, [form.getFieldValue("countryId")]);

  useEffect(() => {
    fetchDistrict(form.getFieldValue("provinceId"));
  }, [form.getFieldValue("provinceId")]);

  useEffect(() => {
    fetchWard(form.getFieldValue("districtId"));
  }, [form.getFieldValue("districtId")]);

  return (
    <div>
      <Form.Provider onFormFinish={(name, { values, forms }) => {
        if (name === "form-join-platform") {
          console.log("values", values, captchaToken)
          if (isEmpty(captchaToken)) {
            message.error("Xác thực capcha thất bại")
          } else {
            setIsConfirm(true)
            setDataSave(values)
          }
        }
      }}>
        <section className="header-join-platform mt-20 w-full flex justify-center flex-col">
          <p className="text-primary font-size-16 mb-2 w-full text-center">
            <b>Đăng ký</b>
          </p>
          <p className="text-center title-sign font-semibold">Đăng ký tham gia nền tảng</p>
          <span className="text-center mt-4 font-size-20 font-normal text-secondary">Nhập đầy đủ thông tin để trở thành đối tác của Mooc!</span>
        </section>

        <section className="join-platform mt-5">
          <Form
            layout="vertical"
            form={form}
            className="form-edit--info"
            name="form-join-platform"
          >
            <Collapse defaultActiveKey={['1', '2', '3']} ghost items={items} />
            <div className="captcha-container mt-2">
              <CustomCaptcha
                ref={captchaRef}
                setToken={setCaptchaToken}
                checked={checkedCaptcha}
                setChecked={setCheckedCaptcha}
              />
            </div>
            <Button
              className="height-48 btn-primary mb-20"
              onClick={() => form.submit()}
            >
              Gửi thông tin
            </Button>
          </Form>
        </section>
      </Form.Provider>
      <ConfirmModal
        open={isConfirm}
        specialClass="join-platform-modal"
        handleOk={saveData}
        handleCancel={() => setIsConfirm(false)}
        title="Xác nhận đăng ký tham giá nền tảng Mooc"
        content={`Đảm bảo rằng tất cả thông tin liên quan đã được kiểm tra đầy đủ trước khi gửi đi.`}
      />
    </div>
  );
}

export default JoinPlatform;
