import {FunctionComponent, useState} from "react";
import {Button, Checkbox, CheckboxProps, Form, Input, Layout, Typography} from "antd";
import {DataType} from "../../types/listUsers";
import "./policy-info.scss";
import FormItemDropdown from "../../components/form-dropdown/FormItemDropdown";
import {routesConfig} from "../../config/routes";
import {useNavigate} from "react-router-dom";


const {Content} = Layout;
type ProfileInfoProps = {
  dataUser?: DataType;
}

const validation = {
  reasonDelete: {
    required: true,
    message: "Vui lòng chọn lý do xóa tài khoản"
  },
  description: {
    required: true,
    message: "Vui lòng viết mô tả lý do xóa tài khoản"
  }
}

const PolicyInfo: FunctionComponent<ProfileInfoProps> = ({dataUser}) => {
  const navigate = useNavigate()
  const [accept, setAccept] = useState(false)

  const onChange: CheckboxProps['onChange'] = (e) => {
    setAccept(e.target.checked)
  }

  const onFinish = (values: any) => {
    console.log(values)
  }

  return (
    <>
      <Content className="container-lg">
        <div className="policy-title--wrapper">
          <Typography.Text className="policy-subtitle-1">
            Cập nhật, 20 tháng 12 năm 2024
          </Typography.Text>
          <Typography.Title level={1} className="policy-title">
            Chính sách hệ thống
          </Typography.Title>
          <Typography.Text className="policy-subtitle-2">
            Vui lòng đọc kĩ trước khi thực hiện thao tác xoá tài khoản.
          </Typography.Text>
        </div>
        <div className="wrapper wrapper-prolicy">
          <section className="content-page">
            <div className="title-content">
              <Typography.Title level={5}>
                Chính sách xoá tài khoản
              </Typography.Title>
            </div>
            <div className="policy-content">
              <p>
                Hãy đảm bảo rằng bạn đã đọc và hiểu rõ các điều khoản và cảnh báo trên trước khi tiến hành xóa tài khoản
                của mình. Việc xóa tài khoản là một hành động không thể hoàn tác và có thể gây mất mát toàn bộ dữ liệu
                và quyền truy cập vào dịch vụ.
              </p>
              <p>
                1. Quyền truy cập và dịch vụ: Xóa tài khoản sẽ dẫn đến mất quyền truy cập vào hệ thống và tất cả các
                tính năng, chức năng, và lợi ích liên quan đến tài khoản. Bạn sẽ không thể đăng nhập, sử dụng hay tận
                dụng bất kỳ dịch vụ nào liên quan đến tài khoản sau khi xóa.
              </p>
              <p>
                2. Mất dữ liệu: Xóa tài khoản sẽ gây mất mát toàn bộ dữ liệu liên quan, bao gồm thông tin cá nhân, cài
                đặt, lịch sử giao dịch và bất kỳ dữ liệu liên quan nào khác. Một khi tài khoản đã bị xóa, bạn sẽ không
                thể khôi phục lại dữ liệu.
              </p>
              <p>
                3. Không thể hoàn tác: Việc xóa tài khoản là hành động không thể hoàn tác. Một khi tài khoản đã bị xóa,
                bạn sẽ không thể khôi phục hoặc lấy lại tài khoản. Hãy đảm bảo rằng bạn đã xem xét kỹ lưỡng và quyết
                định xóa tài khoản một cách chín chắn.
              </p>
              <p>
                4. Không thể sử dụng lại thông tin: Các thông tin và dữ liệu liên quan đến tài khoản sau khi xóa không
                thể được sử dụng lại. Điều này có nghĩa là tên người dùng, địa chỉ email và bất kỳ thông tin cá nhân nào
                khác không thể được tái sử dụng hoặc khôi phục.
              </p>
              <p>
                5. Không thể xóa một phần tài khoản: Việc xóa tài khoản sẽ áp dụng cho toàn bộ tài khoản và toàn bộ dữ
                liệu liên quan. Không có tùy chọn xóa một phần hoặc chỉ một phần dữ liệu của tài khoản.
              </p>
              <p>
                6. Chú ý đến hậu quả: Hãy cân nhắc kỹ lưỡng hậu quả của việc xóa tài khoản. Nếu có thắc mắc hay vấn đề,
                hãy liên hệ với bộ phận hỗ trợ khách hàng hoặc tìm hiểu các tùy chọn và cài đặt bảo mật khác có sẵn để
                xem xét các giải pháp thay thế.
              </p>
            </div>
          </section>
          <section className="quote-policy">
            <div className="divider"></div>
            <Typography.Title level={5}>
              Khi bạn thực hiện yêu cầu xoá tài khoản. Tài khoản của bạn sẽ được xoá khỏi hệ thống và không thể hoàn tác
            </Typography.Title>
          </section>
          <section className="form-accept">
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item label="Lý do xoá tài khoản" name="reasonDelete" rules={[validation.reasonDelete]}>
                <FormItemDropdown placeholder="Chọn lí do xoá tài khoản"/>
              </Form.Item>
              <Form.Item label="Mô tả lý do xoá khoá tài khoản" name="description" rules={[validation.description]}>
                <Input.TextArea placeholder="Để lại lời nhắn cho chúng tôi nhé ..."/>
              </Form.Item>
              <Form.Item>
                <div className="check-policy">
                  <Checkbox onChange={onChange}>Tôi đã đọc và đồng ý với chính sách
                    hệ
                    thống</Checkbox>
                </div>
              </Form.Item>
              <Form.Item>
                <div className="button-group">
                  <Button className="btn btn-cancel" onClick={() => navigate(routesConfig.infoUser)}>
                    <Typography.Text>Hủy bỏ</Typography.Text>
                  </Button>
                  <Button className="btn btn-filled--danger" disabled={!accept}
                          onClick={() => navigate(routesConfig.verification)}>
                    <Typography.Text>Xóa tài khoản</Typography.Text>
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </section>
        </div>
      </Content>
    </>
  );
};

export default PolicyInfo;
