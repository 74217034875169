import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const eventTrackingHistory: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload: any) => {
  return axiosConfig.post(
    `v2/open-api/tracking/event-tracking-history`,
    payload
  );
};
