import { EyeOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Image, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  mockPhotoAccept,
  mockPhotoAfter,
  mockPhotoBefore,
  mockPhotoInstructor,
} from "../../../../constants/identify-ekyc";
import { useIdentifyEkycStore } from "../../../../stores/stores";
import ModalIntroduce from "./ModalIntroduce";
import ModalTakePhoto from "./ModalTakePhoto";
import { config } from "../../../../constants/sdkConfig";
import { HelpService } from "../../../../service/helper.service";
import { CardType } from "../../../../constants/cardType";
import { getEkycSettingCard } from "../../../../service/ekyc";

const { Title, Text } = Typography;

const CommonPhoto = ({ val }: { val: any }) => {
  return (
    <div className="w-full md:w-1/2 lg:w-1/3 p-3 text-center take-photo-form">
      <Image src={`/assets/img/img-kyc/${val.img}`} preview={false} />
      <p className="xl:text-lg font-semibold lg:text-start">{val.name}</p>
    </div>
  );
};

const CommonTakePhotoForm = ({ val }: any) => {
  return (
    <Flex align="end" className="w-1/2 sm:w-1/3 take-photo-form pt-2">
      <div className="text-center lg:border-r px-3 w-full ">
        <p className="text-sm xl:text-base font-semibold">{val.name}</p>
        <Image src={`/assets/img/img-kyc/${val.img}`} preview={false} />
      </div>
    </Flex>
  );
};
const IdentifyEkycPhotoDocs = () => {
  const [sessionId, setSessionId] = useState("session_id_default");
  const [open, setOpen] = useState(false);
  const [imageBefore, setImageBefore] = useState<{
    type: null | CardType;
    url: string;
  }>({ type: null, url: "" });
  const [imageAfter, setImageAfter] = useState<{
    type: null | CardType;
    url: string;
  }>({ type: null, url: "" });
  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isOpenBefore, setIsOpenBefore] = useState(false);
  const [isOpenAfter, setIsOpenAfter] = useState(false);
  const { changeStep } = useIdentifyEkycStore();
  const helpService = useRef(new HelpService());
  const [cardType, setCardType] = useState<CardType[]>([]);
  const handleClose = () => {
    setOpen(false);
    setIsOpenBefore(false);
    setIsOpenAfter(false);
  };
  const handleSubmitPhoto = () => {
    if (!imageBefore?.url) {
      helpService.current.errorMessage("Bạn chưa có thẻ mặt trước");
      return;
    }
    if (!imageAfter?.url) {
      if (imageBefore.type === CardType.PASSPORT) {
        changeStep(2);
      } else {
        helpService.current.errorMessage("Bạn chưa có thẻ mặt sau");
      }
    } else {
      changeStep(2);
    }
  };
  const randomSessionId = () => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setSessionId(result);
  };
  const checkIsMobile = () => {
    let agent = navigator.userAgent;
    let isWebkit = agent.indexOf("AppleWebKit") > 0;
    // let isIPad = agent.indexOf("iPad") > 0;
    let isIOS = agent.indexOf("iPhone") > 0 || agent.indexOf("iPod") > 0;
    let isAndroid = agent.indexOf("Android") > 0;
    let isNewBlackBerry =
      agent.indexOf("AppleWebKit") > 0 && agent.indexOf("BlackBerry") > 0;
    let isWebOS = agent.indexOf("webOS") > 0;
    let isWindowsMobile = agent.indexOf("IEMobile") > 0;
    let isSmallScreen =
      window.screen.width < 767 || (isAndroid && window.screen.width < 1000);
    let isUnknownMobile = isWebkit && isSmallScreen;
    let isMobile =
      isIOS ||
      isAndroid ||
      isNewBlackBerry ||
      isWebOS ||
      isWindowsMobile ||
      isUnknownMobile;
    setIsIOS(isIOS);
    setIsMobile(isMobile);
    // let isTablet = isIPad || (isMobile && !isSmallScreen);
  };
  const getCardSetting = async () => {
    try {
      const response = await getEkycSettingCard();
      const { data } = response;
      if (data.data) {
        const listCardType = data.data.map((item) => item.type) as CardType[];
        setCardType(listCardType);
      }
    } catch (error) {
      helpService.current.errorMessage("Có lỗi xảy ra");
    }
  };
  useEffect(() => {
    checkIsMobile();
    randomSessionId();
    getCardSetting();
  }, []);

  return (
    <div>
      <div className="border-b py-4">
        <Title level={4} className="!mb-0 !font-semibold mt-4">
          Hướng dẫn Chụp giấy tờ tuỳ thân
        </Title>
        <Text className="text-[#475467]">
          Vui lòng đọc kĩ các thông tin hướng dẫn của hệ thống
        </Text>
      </div>
      <Card>
        <Text>
          Thông tin của bạn sẽ được lưu trữ một cách an toàn, đảm bảo tuân thủ
          luật pháp và các quy định nhà nước. Bạn có thể tham khảo{" "}
          <Link to={"#"}>Chính sách bảo mật</Link> của chúng tôi để biết thêm
          chi tiết
        </Text>
      </Card>
      {/* <Card>
        <div className="px-3">
          <div className="border-b py-4">
            <Title level={4} className="!mb-0 !font-semibold">
              Các loại thẻ hợp lệ
            </Title>
            <Text className="text-[#475467]">
              Danh sách các loại thẻ được chấp nhận hợp lệ
            </Text>
          </div>
        </div>
        <Flex wrap="wrap" justify="space-between" className="pt-2">
          {mockPhotoAccept?.map((val, index) => (
            <CommonPhoto key={index} val={val} />
          ))}
        </Flex>
      </Card> */}
      <Card>
        <Flex
          className="border-b"
          align="center"
          justify="space-between"
          wrap="wrap"
        >
          <div className="py-4 px-3">
            <Title level={4} className="!mb-0 !font-semibold">
              Cách chụp giấy tờ hợp lệ
            </Title>
            <Text className="text-[#475467]">
              Vui lòng đọc kĩ hướng dẫn của hệ thống
            </Text>
          </div>
          <Text
            className="px-3 text-[#6941C6] cursor-pointer font-semibold block md:inline"
            onClick={() => setOpen(true)}
          >
            Xem chi tiết
          </Text>
        </Flex>
        <Flex wrap="wrap" justify="space-between" className="pt-2">
          {mockPhotoInstructor?.map((val, index) => (
            <CommonPhoto key={index} val={val} />
          ))}
        </Flex>
      </Card>
      <Card>
        <div className="py-4 px-3 border-b">
          <Title level={4} className="!mb-0 !font-semibold">
            Chụp giấy tờ của bạn
          </Title>
          <Text className="text-[#475467]">
            Cung cấp ảnh chụp giấy tờ cá nhân của bạn
          </Text>
        </div>
        <Flex
          wrap="wrap"
          align="end"
          className="pt-4 justify-center lg:!justify-between"
        >
          <Flex
            className="h-[170px] sm:h-[300px] md:h-[326px] max-w-[604px] border-dashed border border-[#D0D5DD] w-full md:w-2/3 lg:w-1/2 rounded-2xl bg-[#EAECF0] relative overflow-hidden"
            align="center"
            justify="center"
          >
            <div className="absolute h-full">
              {imageBefore?.url && (
                <Image
                  src={imageBefore.url}
                  alt={`capture-front`}
                  className="w-full"
                />
              )}
            </div>
            {!imageBefore?.url && (
              <Button
                className="flex items-center"
                onClick={() => setIsOpenBefore(true)}
              >
                <PlusOutlined />
                <span>Chọn để chụp ảnh mặt trước giấy tờ</span>
              </Button>
            )}
            {imageBefore?.url && (
              <Flex className="absolute bottom-4">
                <Button
                  className="flex items-center"
                  onClick={() => setIsOpenBefore(true)}
                >
                  <ReloadOutlined />
                  <span>Chụp lại mặt trước</span>
                </Button>
              </Flex>
            )}
          </Flex>
          <Flex
            wrap="wrap"
            // justify="space-between"
            className="pt-2 w-full lg:w-1/2"
          >
            {mockPhotoBefore?.map((val) => {
              if (cardType.includes(val?.id as any)) {
                return <CommonTakePhotoForm val={val} />;
              }
            })}
          </Flex>
        </Flex>
        {!(
          (cardType.length === 1 && cardType.includes(CardType.PASSPORT)) ||
          imageBefore?.type === CardType.PASSPORT
        ) && (
          <Flex
            wrap="wrap"
            align="end"
            className="pt-4 justify-center lg:!justify-between"
          >
            <Flex
              className="h-[170px] sm:h-[300px] md:h-[326px] max-w-[604px] border-dashed border border-[#D0D5DD] w-full md:w-2/3 lg:w-1/2 rounded-2xl bg-[#EAECF0] relative overflow-hidden"
              align="center"
              justify="center"
            >
              <div className="absolute h-full">
                {imageAfter?.url && (
                  <Image src={imageAfter.url} alt={`capture-back`} />
                )}
              </div>
              {!imageAfter?.url && (
                <Button
                  className="flex items-center"
                  onClick={() => {
                    if (!imageBefore?.url) {
                      helpService.current.errorMessage(
                        "Bạn cần chụp mặt trước"
                      );
                    } else {
                      setIsOpenAfter(true);
                    }
                  }}
                >
                  <PlusOutlined />
                  <span>Chọn để chụp ảnh mặt sau giấy tờ</span>
                </Button>
              )}
              {imageAfter?.url && (
                <Flex className="absolute bottom-4">
                  <Button
                    className="flex items-center"
                    onClick={() => {
                      if (!imageBefore?.url) {
                        helpService.current.errorMessage(
                          "Bạn cần chụp mặt trước"
                        );
                      } else {
                        setIsOpenAfter(true);
                      }
                    }}
                  >
                    <ReloadOutlined />
                    <span>Chụp lại mặt sau</span>
                  </Button>
                </Flex>
              )}
            </Flex>
            <Flex
              wrap="wrap"
              justify="space-between"
              className="pt-2 w-full lg:w-1/2"
            >
              {mockPhotoAfter?.map((val, index) => (
                <CommonTakePhotoForm key={index} val={val} />
              ))}
            </Flex>
          </Flex>
        )}
      </Card>
      <div className="w-full py-4 mb-8 px-6">
        <Button
          className="w-full"
          size="large"
          type="primary"
          onClick={handleSubmitPhoto}
        >
          Tiếp theo
        </Button>
      </div>
      <ModalIntroduce isOpen={open} handleClose={handleClose} />
      {isOpenBefore && (
        <ModalTakePhoto
          isMobile={isMobile}
          key={"front"}
          type="front"
          cardType={cardType}
          sessionId={sessionId}
          isOpen={isOpenBefore}
          configApi={config.api}
          configStep={config.steps[2]}
          handleClose={handleClose}
          title={"Chụp mặt trước giấy tờ"}
          setImage={setImageBefore}
        />
      )}
      {isOpenAfter && (
        <ModalTakePhoto
          type="back"
          isMobile={isMobile}
          sessionId={sessionId}
          key={"back"}
          cardType={cardType}
          isOpen={isOpenAfter}
          handleClose={handleClose}
          configApi={config.api}
          configStep={config.steps[3]}
          title={"Chụp mặt sau giấy tờ"}
          setImage={setImageAfter}
        />
      )}
    </div>
  );
};

export default IdentifyEkycPhotoDocs;
