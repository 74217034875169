import React, { useEffect, useState } from "react";
import {
  BaseChartProps,
  ChartDataProps,
  StatisticChartPayload,
  StatisticChartResponseData,
} from "../../../../../types/statistic";
import { fetchRateUnitByCourse } from "../../../../../service/statistics";
import BarChartHorizontal from "../../../../../components/charts/BarChartHorizontal";
import {
  getDataKeys,
  getLegends,
  mapToDataPercentChart,
} from "../../components/utils";
import { LegendItemProps } from "../../../../../components/charts/components/LegendCustom";
import dayjs from "dayjs";

function UnitByCourse({ filterValues }: BaseChartProps) {
  // Tỷ lệ kết quả đánh giá khóa học
  const [rateUnitByCourseValues, setRateUnitByCourseValues] =
    useState<StatisticChartResponseData>();

  const getRateUnitByCourse = async (payload: StatisticChartPayload) => {
    const res = await fetchRateUnitByCourse(payload);
    setRateUnitByCourseValues(res.data?.data);
  };

  useEffect(() => {
    getRateUnitByCourse({
      timeUnit: filterValues?.unit || "day",
      from: filterValues?.date?.length
        ? dayjs(filterValues?.date[0]).toISOString()
        : undefined,
      to: filterValues?.date?.length
        ? dayjs(filterValues?.date[1]).toISOString()
        : undefined,
      courseIds: filterValues?.course,
      courseLevelIds: filterValues?.level,
      industryGroupIds: filterValues?.department,
    });
  }, [filterValues]);

  return (
    <BarChartHorizontal
      data={mapToDataPercentChart(
        rateUnitByCourseValues?.statistic as ChartDataProps[]
      )}
      dataKeys={getDataKeys(
        rateUnitByCourseValues?.statistic as ChartDataProps[]
      )}
      yDataKey="criteria"
      xAxisType="percent"
      title="Tỷ lệ kết quả đánh giá khoá học"
      total={rateUnitByCourseValues?.total}
      colors={["#D9D6FE", "#BDB4FE", "#9B8AFB", "#7A5AF8", "#6938EF"]}
      legends={
        getLegends(
          getDataKeys(rateUnitByCourseValues?.statistic as ChartDataProps[]),
          [
            "#6938EF",
            "#774ee9",
            "#7A5AF8",
            "#9B8AFB",
            "#BDB4FE",
            "#D9D6FE",
          ].reverse(),
          true
        ) as LegendItemProps[]
      }
    />
  );
}

export default React.memo(UnitByCourse);
