import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { CoursePayload } from "../../types/course";

export const getCourseProgramList: (
  params: any
) => Promise<AxiosResponse<any>> = (params: any) => {
  return axiosConfig.post(
    `/v2/open-api/curriculum/get-curriculum-by-filter`,
    params
  );
};

export const getCurriculumDetail: (
  id: number
) => Promise<AxiosResponse<any>> = (id: number) => {
  return axiosConfig.get(`/v2/open-api/curriculum/${id}`);
};

export const getCurriculumRecommend: (
  id: number
) => Promise<AxiosResponse<any>> = (id: number) => {
  return axiosConfig.get(
    `/v2/open-api/curriculum/get-curriculum-recommend/${id}`
  );
};

export const getCurriculumRoadmap: (
  id: number
) => Promise<AxiosResponse<any>> = (id: number) => {
  return axiosConfig.get(`v2/open-api/curriculum/get-curriculum-roadmap/${id}`);
};

export const getEnterprise: (flug: string) => Promise<AxiosResponse<any>> = (
  flug: string
) => {
  return axiosConfig.get(`v2/open-api/enterprise/get-enterprise/${flug}`);
};

export const getCourseInsights: (
  id: number,
  params: any
) => Promise<AxiosResponse<any>> = (id: number, params: any) => {
  return axiosConfig.get(`/v2/open-api/comment/curriculum/${id}?${params}`);
};

export const getCurriculumReviewList: (
  id: number,
  params: any
) => Promise<AxiosResponse<any>> = (id: number, params: any) => {
  return axiosConfig.get(`/v2/open-api/comment/curriculum/${id}?${params}`);
};

export const addCurriculumReview: (
  payload?: any
) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfig.post(`/v2/api/curriculum-review/comment`, payload);
};

export const editCurriculumReview: (
  payload?: any
) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfig.put(`/v2/api/curriculum-review/edit-comment`, payload);
};

export const deleteCurriculumReview: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.delete(`/v2/api/curriculum-review/delete`, { data });
};

export const getCurriculumRating: (
  id?: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(
    `/v2/open-api/comment/curriculum/count?curriculum=${id}`
  );
};

export const exportCurriculumReview: (
  id: number,
  params: any
) => Promise<AxiosResponse<any>> = (id: number, payload: any) => {
  return axiosConfig.post(
    `/v2/open-api/comment/curriculum/${id}/export-excel`, payload, {
    responseType: "arraybuffer",
  });
};

export const exportCurriculumPrograms: (
  payload?: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(`v2/open-api/curriculum/export-excel`, payload, {
    responseType: "arraybuffer",
  });
};

export const getIndustryCurriculum: (
  payload?: CoursePayload
) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfig.post(`v2/open-api/curriculum/get-industry-filter`, payload);
};

export const getUniversityCurriculum: (
  payload?: CoursePayload
) => Promise<AxiosResponse<any>> = (payload = {}) => {
  return axiosConfig.post(`/v2/open-api/curriculum/get-university-filter`, payload);
};

