import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { OTPProps } from "antd/es/input/OTP";
import { GetProp } from "antd/lib";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RegisterModal from "../../../../components/register-modal";
import { routesConfig } from "../../../../config/routes";
import {
  endCreateAccount,
  getInfoLoginUser,
  reSendOtp,
} from "../../../../service/login";
import {
  globalStore,
  useAuthStore,
  useUserInfoStore,
} from "../../../../stores/stores";
import "./AuthenticateRegister.scss";
import { renderErrorMessageOtp } from "../../../../utils/getPageFromLink";
type Props = {};

const AuthenticateRegister = (props: Props) => {
  const navigate = useNavigate();
  const { setUserInfo } = useUserInfoStore();

  const [countdown, setCountdown] = useState<number | null>(60);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const email = sessionStorage.getItem("moocAuthenticateRegisterEmail");
  const [otp, setOtp] = useState<any>(null);
  const { setRegisterStep } = globalStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isResendOtp, setIsResendOtp] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const login = useAuthStore((state) => state.login);

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleConfirm = () => {
    setIsModalVisible(false);
    handleCreateAccount();
  };

  const handleCreateAccount = async () => {
    setIsLoading(true);
    try {
      const response = await endCreateAccount(
        encodeURIComponent(String(email)),
        encodeURIComponent(otp)
      );
      login(
        response?.data?.data?.accessToken,
        response?.data?.data?.refreshToken,
        response?.data?.data?.edxToken
      );
      await getInfoLoginUser().then((res) => {
        setUserInfo(res.data.data);
      });
      navigate(`${routesConfig.register}`);
      setRegisterStep(2);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage((error as any)?.response?.data?.messageCode);
    }
  };

  const handleResendOtp = async () => {
    setIsResendOtp(true);
    try {
      setErrorMessage("");
      await reSendOtp(encodeURIComponent(String(email)));
      setCountdown(60);
    } catch (error) {
      setErrorMessage((error as any)?.response?.data?.message);
    } finally {
      setIsResendOtp(false);
    }
  };

  const onChange: GetProp<typeof Input.OTP, "onChange"> = (text) => {
    setOtp(text);
  };
  const sharedProps: OTPProps = {
    onChange,
  };
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown !== null) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev && prev > 0) {
            return prev - 1;
          } else {
            clearInterval(timer);
            return null;
          }
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  useEffect(() => {
    if (errorMessage === "OTP_FAIL_5_TURNS") {
      setTimeout(() => {
        navigate(routesConfig.register);
      }, 2000);
    }
  }, [errorMessage]);

  return (
    <div className="authenticate-register">
      <div className="back-page" onClick={() => navigate(-1)}>
        <ArrowLeftOutlined style={{ fontSize: "20px" }} />
        Quay về trang trước
      </div>
      <div className="authenticate-register-content">
        <div className="text-title-30 text-center mt-4">
          Xác nhận Mã bảo mật
        </div>
        <div className="text-description-16 mt-12">
          Mã bảo mật đã được gửi qua e-mail
          <div>({sessionStorage.getItem("moocAuthenticateRegisterEmail")})</div>
        </div>
        <div className="flex justify-center mt-4">
          <Input.OTP length={6} {...sharedProps} />
        </div>
        {errorMessage ? (
          <div className="error-message mt-1">
            {renderErrorMessageOtp(errorMessage)}
          </div>
        ) : null}
        <Button
          className="authenticate-register-btn"
          onClick={() => {
            setIsModalVisible(true);
            sessionStorage.setItem("moocAuthenticateRegisterOtp", otp);
          }}
          loading={isLoading}
        >
          Xác nhận
        </Button>
        <div className="resend-otp mt-3">
          Gửi lại mã bảo mật:{" "}
          {countdown !== null ? (
            <span className="text-primary font-weight-6">{countdown}s</span>
          ) : (
            <Button
              className="text-primary font-weight-6 pointer"
              onClick={handleResendOtp}
              type="text"
              loading={isResendOtp}
            >
              Gửi lại
            </Button>
          )}
        </div>
      </div>
      <RegisterModal
        visible={isModalVisible}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default AuthenticateRegister;
