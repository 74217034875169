import React, { FunctionComponent, useEffect, useState } from "react";
import { Select } from "antd";
import "./form-item-dropdown.scss";
import {
  IDataSelect,
} from "../../types/listUsers";

type FormItemDropdownProps = {
  modeDropdown?: "multiple" | "tags";
  disabled?: boolean;
  allowClear?: boolean;
  placeholder?: string;
  options?: any;
  defaultValue?: string[];
  onChange?: (values: any) => void;
  onPopupScroll?: (e: any) => void;
  value?: any;
  className?: string;
  priority?: string;
  onBlur?: (values: any) => void;
  showSearch?: boolean;
};

const locale = () => (
  <span>
    <p>
      <img
        className="image-empty-data"
        src="/images/empty-img-gray.png"
        alt="empty-img"
      ></img>
    </p>
    <p className="nodata-text">Không tìm thấy</p>
  </span>
);

const FormItemDropdown: FunctionComponent<FormItemDropdownProps> = ({
  modeDropdown,
  options,
  placeholder,
  disabled,
  allowClear,
  value,
  className,
  defaultValue,
  priority,
  onChange,
  onPopupScroll,
  onBlur,
  showSearch = true
}) => {
  const [fetching, setFetching] = useState(false);
  const [optionsState, setOptionsState] = useState(options);
  const debounceFetcher = (value: string) => {

  };
  useEffect(() => {
    if (options) {
      setOptionsState(options);
    }
  }, [options]);
  return (
    <Select
      onChange={onChange}
      onBlur={onBlur}
      showSearch={showSearch}
      disabled={disabled}
      optionFilterProp="children"
      maxTagCount="responsive"
      allowClear={allowClear}
      value={value}
      placeholder={placeholder}
      mode={modeDropdown}
      onPopupScroll={onPopupScroll}
      virtual={false}
      defaultValue={defaultValue}
      notFoundContent={
        <span style={{ textAlign: "center" }}>
          <p>
            <img
              className="image-empty-data"
              src="/images/empty-img-gray.png"
              alt="empty-img"
            ></img>
          </p>
          <p className="nodata-text">Không tìm thấy</p>
        </span>
      }
      className={className}
      onSearch={(value) => debounceFetcher(value)}
    >
      {optionsState?.map((option: any, index: number) => (
        <Select.Option
          key={index}
          value={
            typeof option?.value === "boolean"
              ? option?.value
              : priority
                ? option?.code
                : option?.id ||
                option?.assignUserId ||
                option?.enterpriseId ||
                option?.code
          }
        >
          {option?.nameEn ||
            option?.name ||
            option?.label ||
            option?.ten ||
            option?.email ||
            option?.assignUserName ||
            option?.enterpriseName}
        </Select.Option>
      ))}
    </Select>
  );
};

export default FormItemDropdown;
