// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-search {
  padding: 80px 80px 50px 80px;
}
.banner-search .search-i {
  height: 44px;
}
.banner-search .content-wrap {
  border-bottom: 1px solid #eaecf0;
  padding-bottom: 30px;
}

@media screen and (max-width: 576px) {
  .banner-search {
    padding: 80px 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/course-info/search/style.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,gCAAA;EACA,oBAAA;AAAJ;;AAIA;EACE;IACE,kBAAA;EADF;AACF","sourcesContent":[".banner-search {\n  padding: 80px 80px 50px 80px;\n\n  .search-i {\n    height: 44px;\n  }\n\n  .content-wrap {\n    border-bottom: 1px solid #eaecf0;\n    padding-bottom: 30px;\n  }\n}\n\n@media screen and (max-width: 576px) {\n  .banner-search{\n    padding: 80px 30px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
