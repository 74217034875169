import { BellOutlined, CloseOutlined } from "@ant-design/icons";
import { Empty, Popover } from "antd";
import React, { useEffect, useState } from "react";
import NotificationItem from "./NotificationItem";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import {
  countUnviewed,
  editNotificationViewed,
  searchNotifications,
} from "../../service/notification";
import { isEmpty } from "lodash";
import {
  NotificationData,
  NotificationItemProps,
} from "../../types/notification";
import { useNotificationStore } from "../../stores/stores";
import EmptyComponent from "../empty";

function Notification() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [notifyData, setNotifyData] = useState<NotificationData>({
    totalElements: 0,
  });
  const [countOfUnViewed, setCountOfUnViewed] = useState<number>(0);
  const { refetch, onRefetch } = useNotificationStore();

  const hide = async (it?: NotificationItemProps) => {
    if (it && !it.isViewed) {
      try {
        await editNotificationViewed(Number(it?.id), true);
      } catch (err: any) {
        console.error(err);
      }
    }
    setOpen(false);
    onRefetch();
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    const getNotifications = async () => {
      const res = await searchNotifications({});
      setNotifyData(res.data);
    };
    const getCountNotificationViewed = async () => {
      const res = await countUnviewed();
      setCountOfUnViewed(res.data.data);
    };
    getNotifications();
    getCountNotificationViewed();
  }, [refetch]);

  const renderContent = () => {
    const notifications = notifyData?.content || [];
    if (isEmpty(notifications)) {
      return (
        <div className="notification-list pb-2 pt-2">
          <EmptyComponent description="Không có thông báo" />
        </div>
      );
    }
    return (
      <div>
        <div className="notification-list">
          {notifications?.map((item: NotificationItemProps) => (
            <NotificationItem
              key={item.id}
              {...item}
              onClick={() => {
                navigate(`${routesConfig.notification}?id=${item.id}`);
                hide(item);
              }}
              active={!item.isViewed}
            />
          ))}
        </div>
        <div
          className="btn-view-more w-full-100 center"
          onClick={() => {
            navigate(routesConfig.notification);
            hide();
          }}
        >
          <span className="text-primary font-size-14">
            <b>Xem thêm</b>
          </span>
        </div>
      </div>
    );
  };

  return (
    <Popover
      content={renderContent()}
      title={
        <div className="flex align-center justify-space-between">
          <span className="font-size-20">Thông báo</span>
          <div className="pointer">
            <CloseOutlined color="#98A2B3" />
          </div>
        </div>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      overlayClassName="notification-popup"
    >
      <div className="badgeon-icon">
        <a href="#" className="icon">
          <BellOutlined />
        </a>
        {countOfUnViewed !== 0 && (
          <div className="badge-wrapper">
            <div className="offset">
              <div className="badgecount">
                <div className="badge">
                  <div className="number">{countOfUnViewed}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Popover>
  );
}

export default Notification;
