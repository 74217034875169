import { Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../../components/banner/Banner";
import EducationalProgramCard from "../../components/card/educational-program-card/EducationalProgramCard";
import Filter from "../../components/filter";
import FormInput from "../../components/form-input/FormInput";
import ResponsiveList from "../../components/list/ResponsiveList";
import CustomPagination from "../../components/pagination/Pagination";
import {
  COURSE_TYPE,
  CURRICULUM_SCHEDULE_TYPE,
  DEFAULT_PAGE_NUM,
  DEFAULT_PAGE_SIZE,
  FILTER_STAR_ARR,
  FREE_OPTIONS,
  PAID_VALUE_FILTER,
} from "../../constants";
import useKeyword from "../../hooks/useKeyword";
import useMetaData from "../../hooks/useMetaData";
import {
  exportCurriculumPrograms,
  getCourseProgramList,
  getUniversityCurriculum,
} from "../../service/curriculum/curriculum-service";
import { CoursePayload } from "../../types/course";
import { CurriculumList } from "../../types/curriculum";
import { getArrOrUndefined, getArrayFilterChecked } from "../../utils/arrays";
import { convertSomeObjKeyToArray } from "../../utils/format";
import { useFilters } from "./Filters";
import "./style.scss";
import SimpleTabs from "../../components/tabs/SimpleTabs";
import { routesConfig } from "../../config/routes";
import { useCurriculumStore } from "../../stores/stores";

type Props = {};

const SearchEducationalProgramsAdminPortal = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dataState = location?.state?.dataState;
  const { industrieCurriculum } = useCurriculumStore();
  const { groupIndustry } = useMetaData();
  const industryGroupId = useKeyword("industryGroup");

  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState<number>(DEFAULT_PAGE_NUM);
  const [keyword, setKeyword] = useState<string>(useKeyword("keyword") || "");
  const [curriculumList, setCurriculumList] = useState<CurriculumList[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchValues, setSearchValues] = useState<CoursePayload>({
    sortByName: "asc",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const filterMenus = useFilters({
    hasPaid: searchValues?.isFreeOptions?.includes(PAID_VALUE_FILTER)
      ? true
      : false,
  });

  const [universities, setUniversities] = useState<any>();

  const getData = async () => {
    setIsLoading(true);
    try {
      const dataPath = location.search?.split("&slug=")
      const uuid = dataPath && dataPath[0].replace("?uuid=", "")

      const response = await getCourseProgramList({
        ...searchValues,
        keyword: keyword.trim(),
        page: pageNumber,
        size: pageSize,
        industryGroup: Number(industryGroupId) || undefined,
        universities: !location.pathname.includes(routesConfig.universityCourseProgramTemplateDetail) ? [] : [uuid]
      });
      const { data } = response.data;
      setTotalRecords(data.count);
      setCurriculumList(
        data.curriculumsData.map((item: CurriculumList) => ({
          content: <EducationalProgramCard key={item.id} {...item}  screen="schoolDetail"/>,
        }))
      );
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  useEffect(() => {
    getData();
  }, [pageNumber, pageSize, searchValues, industryGroupId]);

  useEffect(() => {
    const fetchUniversityCurriculum = async () => {
      const res = await getUniversityCurriculum();
      setUniversities(res?.data.data);
    };
    fetchUniversityCurriculum();
  }, []);

  const handleSearch = () => {
    if (keyword) {
      navigate(`?keyword=${keyword}`);
    } else navigate(`${routesConfig.curriculumPrograms}`);
    getData();
  };
  const handleChangeFilter = (value: any) => {
    // Filter chuyên ngành
    const industriesFilter = getArrayFilterChecked(
      industrieCurriculum,
      convertSomeObjKeyToArray(value, "industry"),
      "id"
    );
    // Xếp hạng
    const stars = getArrayFilterChecked(
      FILTER_STAR_ARR,
      convertSomeObjKeyToArray(value, "rank"),
      "value"
    );
    // Chi phí
    const freeOptions = getArrayFilterChecked(
      FREE_OPTIONS,
      convertSomeObjKeyToArray(value, "price"),
      "value"
    );
    // Filter lịch trình học
    const courseScheduleType = getArrayFilterChecked(
      CURRICULUM_SCHEDULE_TYPE,
      convertSomeObjKeyToArray(value, "schedule"),
      "value"
    );
    // Filter loại khoá học
    const courseType = getArrayFilterChecked(
      COURSE_TYPE,
      convertSomeObjKeyToArray(value, "kindOfCourse"),
      "value"
    );
    const payload = {
      industries: getArrOrUndefined(industriesFilter),
      stars: getArrOrUndefined(stars),
      scheduleType: getArrOrUndefined(courseScheduleType),
      type: getArrOrUndefined(courseType),
      isFreeOptions: getArrOrUndefined(freeOptions),
      minCost: freeOptions?.includes(PAID_VALUE_FILTER) ? 0 : undefined,
      maxCost:
        freeOptions?.includes(PAID_VALUE_FILTER) && value?.cost
          ? value?.cost
          : freeOptions?.includes(PAID_VALUE_FILTER) && !value?.cost
            ? 0
            : undefined,
    };

    setSearchValues((prevState: CoursePayload) => ({
      ...prevState,
      ...payload,
    }));
  };

  const handleChangeSort = (value: number) => {
    switch (value) {
      case 1:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByName: "asc", sortByCreatedAt: undefined },
        }));
        break;
      case 2:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByName: "desc", sortByCreatedAt: undefined },
        }));
        break;
      case 3:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByCreatedAt: "desc", sortByName: undefined },
        }));
        break;
      case 4:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByCreatedAt: "asc", sortByName: undefined },
        }));
        break;
      default:
        break;
    }
  };

  const handleExportExcel = async () => {
    try {
      const res = await exportCurriculumPrograms({
        ...searchValues,
        page: pageNumber,
        size: pageSize,
        industryGroup: Number(industryGroupId) || undefined,
      });
      const fileData = res.data;

      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);

      downloadLink.download = "Danh sách chương trình học.xlsx";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  const checkDisplay = location.pathname.includes(routesConfig.universityCourseProgramTemplateDetail)

  return (
    <div className="search-programs">
      {
        !checkDisplay ?
          <Banner>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="search-programs__header">
                <div className="search-programs__list-name">
                  Danh sách chương trình học
                </div>
                <div className="search-programs__title">
                  {dataState?.name
                    ? decodeURIComponent(dataState?.name)
                    : "Các chương trình học"}
                </div>
                <div className="search-programs__Overview">
                  Với 1000+ các chương trình học với đa dạng chủ đề
                </div>
                <div className="search-programs__search">
                  <FormInput
                    placeholder="Nhập từ khoá cần tìm"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onPressEnter={handleSearch}
                  />
                  <Button className="search-btn" onClick={handleSearch}>
                    Tìm kiếm
                  </Button>
                </div>
              </div>
            </div>
          </Banner> : null
      }
      <div className="tab">
        <SimpleTabs
          tabs={groupIndustry?.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
          active={industryGroupId || undefined}
          show={10}
          onClick={(item) => {
            navigate(`?industryGroup=${item?.value}`, {
              state: { dataState: item },
            });
          }}
          onClickMore={() => navigate("/search-by-topic")}
        />
      </div>
      <div className="search-programs-result">
        <Filter
          items={filterMenus}
          onChange={handleChangeFilter}
          onChangeSort={handleChangeSort}
        />

        <div className="search-programs-result__content">
          <ResponsiveList
            loading={isLoading}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 3,
            }}
            data={curriculumList}
            showMenu={true}
            totalRecords={totalRecords}
            // onClickReport = () => {},
            onClickExportExcel={handleExportExcel}
            isViewReport={false}
          />

          {totalRecords > 0 && (
            <CustomPagination
              totalPage={totalRecords}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchEducationalProgramsAdminPortal;
