import { Button, Col } from "antd";
import { useState } from "react";
import ReviewCard from "../../../components/comment-modal/review-card/ReviewCard";
import ReviewListModal from "../../../components/comment-modal/review-list-modal/ReviewListModal";
import CommentModal from "../../../components/comment-modal/review-modal/CommentModal";
import { renderStarRating } from "../../../utils/renderStarRating";
import "./CourseReview.scss";
type Props = {
  idElmTag?: string;
  courseReviewData?: any;
  commentList?: any;
  isRegistered?: boolean;
  screen: string;
  isCommented?: boolean;
  completedPercentage: number;
  learningStatus: number;
  isAbleToComment: boolean;
};
const CourseReview = ({
  idElmTag,
  courseReviewData,
  commentList,
  isAbleToComment,
  screen,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenComment, setIsOpenComment] = useState<boolean>(false);

  return (
    <div className="course-review" id={idElmTag}>
      <div className="reviewHeader">
        <div className="flex justify-between">
          <div className="text-title-24">
            Nhận xét về{" "}
            {screen === "curriculum" ? "chương trình học" : "khóa học"}
          </div>
          {isAbleToComment ? (
            <button
              className="add-comment-btn"
              onClick={() => setIsOpenComment(true)}
            >
              Viết nhận xét về{" "}
              {screen === "curriculum" ? "chương trình học" : "khóa học"}
            </button>
          ) : null}
        </div>
        <p className="description">
          Những đánh giá của học viên về{" "}
          {screen === "curriculum" ? "chương trình học" : "khóa học"}
        </p>
        <div className="hr-tag"></div>
        <div className="rating-summary">
          <span className="text-description-14">
            {screen === "curriculum" ? "Chương trình học" : "Khóa học"} đã được
            các bạn học viên đánh giá thế nào?
          </span>
          <div className="flex">
            <span className="stars">
              {renderStarRating(
                courseReviewData?.starRate || courseReviewData?.rate
              )}
            </span>
            <span className="rating text-description-16">
              {courseReviewData?.starRate ||
                Number(courseReviewData?.rate?.toFixed(1)) ||
                0}
              +
            </span>
            <span className="reviewsCount text-description-16">
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/message-chat-square.svg`}
                alt="card-img"
                className="card-img"
              />
              {courseReviewData?.totalCmt || courseReviewData?.count} bình luận
            </span>
          </div>
        </div>
        <div className="flex flex-wrap">
          {commentList?.length > 0
            ? commentList?.map((it: any) => (
                <Col key={it?.id} xs={24} xl={12} className="comment-item">
                  <ReviewCard itemData={it} />
                </Col>
              ))
            : null}
        </div>
        {commentList?.length > 0 ? (
          <div className="flex justify-center">
            <Button
              className="view-all-comment"
              onClick={() => setIsOpen(true)}
            >
              Xem tất cả
            </Button>
          </div>
        ) : null}

        <ReviewListModal
          open={isOpen}
          handleCancel={() => setIsOpen(false)}
          screen={screen}
          title={` ${screen === "curriculum" ? "chương trình học" : "khóa học"}`}
        />
        <CommentModal
          open={isOpenComment}
          title={`Nhận xét của bạn về ${screen === "curriculum" ? "chương trình học" : "khóa học"}`}
          handleCancel={() => setIsOpenComment(false)}
          handleOk={() => setIsOpenComment(false)}
        />
      </div>
    </div>
  );
};

export default CourseReview;
