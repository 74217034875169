import { Steps, Typography } from "antd";
import { useState } from "react";
import PageHeader from "../../components/page-header/PageHeader";
import "./identify-ekyc.scss";
import IdentifyEkycVideoFace from "./components/IdentifyEkycVideoFace";
import { useIdentifyEkycStore } from "../../stores/stores";
import IdentifyEkycPhotoDocs from "./components/IdentifyEkycPhotoDocs";
import IdentifyEkycConfirmInfo from "./components/IdentifyEkycConfirmInfo";
import IdentifySuccess from "./components/IdentifySuccess";

const { Title, Text } = Typography;

const IdentifyEkyc = () => {
  const [current, setCurrent] = useState(0);
  const { step } = useIdentifyEkycStore();
  const items = [
    {
      title: (
        <Title level={5} className="!text-[#6941C6] !font-semibold">
          Quay Video khuôn mặt
        </Title>
      ),
      description: (
        <Text className="!text-[#7F56D9]">
          Thực hiện quay các góc khuôn mặt
        </Text>
      ),
    },
    {
      title: (
        <Title
          level={5}
          className={`${step >= 1 ? "!text-[#6941C6]" : "!text-[#344054]"} !font-semibold`}
        >
          Chụp giấy tờ tuỳ thân
        </Title>
      ),
      description: (
        <Text
          className={`${step >= 1 ? "!text-[#7F56D9]" : "!text-[#475467]"}`}
        >
          Sử dụng giấy tờ gốc và còn hiệu lực
        </Text>
      ),
    },
    {
      title: (
        <Title
          level={5}
          className={`${step >= 2 ? "!text-[#6941C6]" : "!text-[#344054]"} !font-semibold`}
        >
          Xác nhận thông tin
        </Title>
      ),
      description: (
        <Text
          className={`${step >= 2 ? "!text-[#7F56D9]" : "!text-[#475467]"}`}
        >
          Kiểm tra, chỉnh sửa và xác nhận
        </Text>
      ),
    },
  ];
  const onChange = (value: number) => {
    console.log("onChange:", value);
    setCurrent(value);
  };
  return (
    <>
      {step > 2 ? (
        <IdentifySuccess />
      ) : (
        <section className="container">
          <PageHeader
            title="Thực hiện xác nhận eKYC"
            positionItem="column"
            breadcrumb={true}
          />
          <div>
            <Steps
              current={step}
              responsive
              items={items}
              className="max-w-[1200px] m-auto"
            />
            {step === 0 ? (
              <IdentifyEkycVideoFace />
            ) : step === 1 ? (
              <IdentifyEkycPhotoDocs />
            ) : (
              <IdentifyEkycConfirmInfo />
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default IdentifyEkyc;
