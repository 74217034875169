import React, { useEffect, useState } from 'react'
import { getTemplateUniversity } from '../../service/blog';
import { useLocation } from 'react-router-dom';
import { Layout } from "antd";
import { isEmpty } from 'lodash';
import { renderTemplate } from '../../utils/renderTemplate';
import parse from "html-react-parser";

const { Content } = Layout;

type Props = {
  tab: string
}

export default function GeneralCourseDetail(props: Props) {
  const { tab } = props
  const location = useLocation()
  const [html, setHTML] = useState<string>("");

  const getDetailTemplatesByCoueseId = (cb?: any) => {
    const dataPath = location.search?.split("&slug=")
    const paramSearch = dataPath && dataPath[0].replace("?uuid=", "")

    //change later
    getTemplateUniversity(paramSearch, tab).then((res) => {
      const { data } = res.data;
      if (data?.template) {
        setTimeout(() => {
          setHTML(data?.template);
        }, 100);
      }
    });
  };

  useEffect(() => {
    getDetailTemplatesByCoueseId()
  }, [tab])

  return (
    <Content className="page-builder-view">
      {!isEmpty(html) ? renderTemplate(html) : null}
    </Content>
  )
}
