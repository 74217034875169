import { Breadcrumb, Form } from "antd";
import FormInput from "../../components/form-input/FormInput";
import "./style.scss";
import CustomPagination from "../../components/pagination/Pagination";
import { ChangeEvent, useCallback, useState } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";

type Props = {};

const ViewSystemSupportInformation = (props: Props) => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [keyword, setKeyword] = useState<string>("");
  const [form] = Form.useForm();

  let dataList: Array<any> = [];

  for (var i = 0; i < 1000; i++) {
    dataList.push({
      id: i,
      title: "title ne" + i,
    });
  }

  const totalPage = dataList?.length;
  const handleChangeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };
  const debouncedSubmit = useCallback(
    debounce(() => {}, 500),
    [form]
  );

  debouncedSubmit();

  return (
    <div className="view-system-support-information">
      <Form form={form}>
        <Breadcrumb
          items={[
            {
              href: "",
              title: (
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/home-line.svg`}
                />
              ),
            },
            {
              href: "",
              title: (
                <>
                  <span onClick={() => navigate(routesConfig.support)}>
                    Hỗ trợ
                  </span>
                </>
              ),
            },
            {
              title: "Tìm kiếm",
            },
          ]}
        />
        <div className="header-tab">
          <div className="header-title">{keyword}</div>
          <FormInput
            prefixIcon={
              <img
                src="/assets/icons/search-lg.svg"
                alt="search"
                className="search-icon"
              />
            }
            onChange={(e) => handleChangeKeyword(e)}
          />
        </div>
        <div className="total-result">có {totalPage} kết quả tìm kiếm</div>
        <div className="content">
          {dataList
            ?.slice(
              pageNumber === 1 ? 0 : pageSize * pageNumber - pageSize,
              pageNumber === 1 ? 10 : pageSize * pageNumber
            )
            .map((it) => (
              <div key={it?.id} className="item">
                {it?.title}
                <div className="hr-tag"></div>
              </div>
            ))}
        </div>

        <CustomPagination
          totalPage={totalPage}
          pageSize={pageSize}
          pageNumber={pageNumber}
          setPageSize={setPageSize}
          setPageNumber={setPageNumber}
        />
      </Form>
    </div>
  );
};

export default ViewSystemSupportInformation;
