import { axiosConfig } from "../../config/api/configApi";
import { AxiosResponse } from "axios";

export const getUniversities: (payload: {
  page?: number;
  size?: number;
  keyword?: string;
  industryGroupId?: number;
  traningLevelId?: number;
  rate?: number;
  orderBy?: string | number;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(`v2/open-api/enterprise/search-enterprise`, payload);
};

export const exportExcelListEnterprise: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.get(
    `v2/open-api/enterprise/export-excel-list-enterprise`,
    {
      responseType: "arraybuffer",
    }
  );
};

export const exportExcelChartEnterprise: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.get(
    `v2/open-api/enterprise/export-excel-chart-enterprise`,
    {
      responseType: "arraybuffer",
    }
  );
};

export const getTrainingLevelFilter: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.get(`v2/open-api/enterprise/get-training_level-filter`);
};

export const getIndustriesFilter: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.get(`v2/open-api/enterprise/get-industry-filter`);
};
