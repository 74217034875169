import { Button } from "antd";
import { useEffect, useState } from "react";
import { getCoursesToStarted, getFeaturedCourses } from "../../service/courses";
import { globalStore } from "../../stores/stores";
import { CourseDetail } from "../../types/course";
import HomeRegister from "./home-register/HomeRegister";
import HomeTopic from "./home-topic/HomeTopic";
import "./HomePage.scss";
import ListProminentCourses from "./list-prominent-courses/ListProminentCourses";
import StarterCourse from "./starter-course/StarterCourse";
import Statistics from "./statistics/Statistics";
import SystemEvaluation from "./system-evaluation/SystemEvaluation";
import useEventTrackingHistory from "../../hooks/useEventTrackingHistory";

export default function HomePage() {
  const [featuredCourseList, setFeaturedCourseList] = useState<CourseDetail[]>(
    []
  );
  const [starterCourseList, setStarterCourseList] = useState<CourseDetail[]>(
    []
  );
  const [behaviourId, setBehaviourId] = useState(0);

  const { setFooterDefault } = globalStore();

  useEventTrackingHistory("home-page")

  useEffect(() => {
    setFooterDefault();
  }, [setFooterDefault]);

  useEffect(() => {
    const initialCourse = async () => {
      const params = {
        page: 1,
        size: 6,
      };
      const courses = await getFeaturedCourses(params);
      setFeaturedCourseList(courses?.data.data.courses);
    };
    initialCourse();
  }, []);

  useEffect(() => {
    const initialCourse = async () => {
      const params = {
        pageIndex: 1,
        pageSize: 6,
      };
      const courses = await getCoursesToStarted(params);
      setBehaviourId(courses?.data?.behaviour_id)
      setStarterCourseList(courses?.data.data);
    };
    initialCourse();
  }, []);
  console.log("starterCourseList", starterCourseList);

  return (
    <div className="home">
      <div className="home-page">
        <div className="home-page__container container">
          <div className="home-page__left">
            <div className="home-page__left__intro">
              <h1>
                Hệ thống phát triển nền tảng cung cấp khoá học trực tuyến đại
                chúng mở
              </h1>
              <span>
                Mooc là hệ thống cung cấp các khoá học được phát triển hợp tác
                cùng các trường đại học trên toàn quốc
              </span>
            </div>
            <div className="home-page__left__search">
              <input
                type="text"
                placeholder="Nhập từ khóa bạn muốn tìm kiếm"
              ></input>
              <Button className="height-48 btn-primary">
                <span>Bắt Đầu</span>
              </Button>
            </div>
            <div className="home-page__left__policy">
              <span>Xem thêm</span>
              <a>Chính sách quyền riêng tư</a>
            </div>
          </div>
          <div className="home-page__right">
            <img
              className="home-page__right__banner"
              alt="home-page-banner"
              src="../../assets/img/home-banner.png"
            />
            <img
              className="home-page__right__arrow"
              alt="arrow"
              src="../../assets/img/arrow.png"
            />
            <div className=""></div>
          </div>
        </div>
      </div>
      <ListProminentCourses courseList={featuredCourseList} />
      <Statistics />
      <StarterCourse courseList={starterCourseList} behaviourId={behaviourId}/>
      <HomeTopic />
      <SystemEvaluation />
      <HomeRegister />
    </div>
  );
}
