import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getCalendarList: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfig.post(
    `/v2/api/course/structure/study/schedule-search`,
    payload
  );
};

export const getMyCourseListCalendar: (
  params: any
) => Promise<AxiosResponse<any>> = (params) => {
  return axiosConfig.get(`/v2/api/my-registration/get-all-my-course?${params}`);
};

export const getConfigCalendar: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/v2/api/course/structure/study/schedule-config`);
};

export const configCalendar: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfig.post(
    `/v2/api/course/structure/study/schedule-config/config`,
    payload
  );
};
