import React, { useEffect, useState } from "react";
import {
  BaseChartProps,
  ChartDataProps,
  StatisticChartPayload,
  StatisticChartResponseData,
} from "../../../../../types/statistic";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { fetchUnitDiscuss } from "../../../../../service/statistics";
import MoocAreaChart from "../../../../../components/charts/AreaChart";
import {
  getDataKeys,
  getLegends,
  mapToDataChart,
} from "../../components/utils";
import {
  DiamondDot,
  SquareDot,
  TriangleDot,
} from "../../../../../components/charts/components/ultis";
import { LegendItemProps } from "../../../../../components/charts/components/LegendCustom";

function Discuss({ filterValues }: BaseChartProps) {
  const [unitDiscussValues, setUnitDiscussValues] =
    useState<StatisticChartResponseData>();

  useEffect(() => {
    if (!isEmpty(filterValues)) {
      getUnitDiscuss({
        timeUnit: filterValues?.unit || "day",
        from: filterValues?.date?.length
          ? dayjs(filterValues?.date[0]).toISOString()
          : undefined,
        to: filterValues?.date?.length
          ? dayjs(filterValues?.date[1]).toISOString()
          : undefined,
        courseIds: filterValues?.course,
        courseLevelIds: filterValues?.level,
        industryGroupIds: filterValues?.department,
      });
    }
  }, [filterValues]);

  const getUnitDiscuss = async (payload: StatisticChartPayload) => {
    const res = await fetchUnitDiscuss(payload);
    setUnitDiscussValues(res.data?.data);
  };
  return (
    <MoocAreaChart
      title="Thống kê số lượng phản hồi theo thời gian"
      data={mapToDataChart(unitDiscussValues?.statistic as ChartDataProps[])}
      dataKeys={getDataKeys(unitDiscussValues?.statistic as ChartDataProps[])}
      xDataKey="criteria"
      yDescription="Số lượng phản hồi"
      total={unitDiscussValues?.total}
      colors={[
        "#6938EF",
        "#774ee9",
        "#7A5AF8",
        "#9B8AFB",
        "#BDB4FE",
        "#D9D6FE",
      ].reverse()}
      dots={[
        <TriangleDot fill="#D9D6FE" stroke="#fff" />,
        <SquareDot fill="#BDB4FE" stroke="#fff" />,
        <DiamondDot fill="#9B8AFB" stroke="#fff" />,
        { r: 5, fill: "#774ee9" },
        { r: 5, fill: "#7A5AF8" },
        { r: 5, fill: "#6938EF" },
      ]}
      legends={
        getLegends(
          getDataKeys(unitDiscussValues?.statistic as ChartDataProps[]),
          [
            "#6938EF",
            "#774ee9",
            "#7A5AF8",
            "#9B8AFB",
            "#BDB4FE",
            "#D9D6FE",
          ].reverse()
        ) as LegendItemProps[]
      }
    />
  );
}

export default React.memo(Discuss);
