import React from "react";
import { Form, Input, Button, Col, Row } from "antd";
import "./ContactUs.scss";

const { TextArea } = Input;

const ContactUs: React.FC = () => {
  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="contact-us-container">
      <Row className="contact-us" justify="center">
        {/* Sử dụng Col của Ant Design */}
        <Col xs={24} md={8} className="left-panel">
          <div className="text-title-24">Thông tin liên hệ</div>
          <div className="description-info">
            Đội ngũ thân thiện của chúng tôi luôn ở đây để trò chuyện.
          </div>
          <div className="contact-info">
            <div className="flex gap-16">
              <img
                className="mt-36"
                src="/assets/icons/message-chat-circle.svg"
                alt="Message"
              />
              <div>
                <div className="title">Trò chuyện với chúng tôi</div>
                <div className="contact-description">
                  Đội ngũ của chúng tôi sẵn sàng
                </div>
                <div className="mail-contact">
                  <a href="mailto:kythuat@trithucmoi.net.vn">
                    kythuat@trithucmoi.net.vn
                  </a>
                </div>
              </div>
            </div>

            <div className="flex gap-16 justify-start">
              <img
                className="mt-36"
                src="/assets/icons/phone.svg"
                alt="Phone"
              />
              <div>
                <div className="title">Số liên hệ</div>
                <div className="contact-description">
                  Thứ 2 - 6: từ 08:00 - 17:00
                </div>
                <div className="mail-contact">+84.868.706.680</div>
              </div>
            </div>
          </div>
        </Col>

        {/* Form panel chiếm không gian còn lại */}
        <Col xs={24} md={16} className="form-panel">
          <div className="content">
            <div className="text-title-36">Liên hệ với chúng tôi</div>
            <div className="contact-info">
              Bạn có thể liên hệ qua
              <span className="mail-contact">
                &nbsp;
                <a href="mailto:kythuat@trithucmoi.net.vn">
                  kythuat@trithucmoi.net.vn
                </a>
              </span>
            </div>
            <Form
              name="contact"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              requiredMark={false}
            >
              <Row gutter={32}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={
                      <span>
                        Họ của bạn <span className="required">*</span>
                      </span>
                    }
                    name="firstName"
                    rules={[
                      { required: true, message: "Vui lòng nhập họ của bạn" },
                    ]}
                  >
                    <Input
                      style={{ height: "44px" }}
                      placeholder="Nhập họ của bạn"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Form.Item
                    label={
                      <span>
                        Tên đệm và tên của bạn{" "}
                        <span className="required">*</span>
                      </span>
                    }
                    name="lastName"
                    rules={[
                      { required: true, message: "Vui lòng nhập tên của bạn" },
                    ]}
                  >
                    <Input
                      style={{ height: "44px" }}
                      placeholder="Nhập tên của bạn"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label={
                  <span>
                    Email <span className="required">*</span>
                  </span>
                }
                name="email"
                rules={[
                  { required: true, message: "Vui lòng nhập email của bạn" },
                  { type: "email", message: "Email không hợp lệ" },
                ]}
              >
                <Input
                  style={{ height: "44px" }}
                  placeholder="Nhập email của bạn"
                />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    Số điện thoại <span className="required">*</span>
                  </span>
                }
                name="phone"
                rules={[
                  { required: true, message: "Vui lòng nhập số điện thoại" },
                ]}
              >
                <Input
                  style={{ height: "44px" }}
                  placeholder="Nhập số điện thoại liên hệ của bạn"
                />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    Lời nhắn của bạn <span className="required">*</span>
                  </span>
                }
                name="message"
                rules={[
                  { required: true, message: "Vui lòng để lại lời nhắn" },
                ]}
              >
                <TextArea
                  rows={4}
                  placeholder="Để lại lời nhắn của bạn cho chúng tôi nhé"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                >
                  Gửi yêu cầu
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContactUs;
