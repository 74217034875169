import React, { useEffect, useState } from 'react'
import { getTemplateUniversity } from '../../service/blog';
import { useLocation } from 'react-router-dom';
import { Layout } from "antd";
import { isEmpty } from 'lodash';
import { renderTemplate } from '../../utils/renderTemplate';
import parse from "html-react-parser";
import { useCurriculumStore } from '../../stores/stores';
import { getCurriculumRoadmap } from '../../service/curriculum/curriculum-service';

const { Content } = Layout;

export default function CourseProgramDetailTemplate() {
  const location = useLocation()
  const [html, setHTML] = useState<string>("");
  const { setDetail, setRoadmap, detail, setCourseAdministrator } =
  useCurriculumStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if(location.state) {
      setHTML(location.state?.template)
    }
  }, [])

  useEffect(() => {
    const getCurriculumDetailFromApi = async () => {
      setIsLoading(true);
      const roadmap = await getCurriculumRoadmap(Number(location.state?.id));

      setRoadmap(roadmap.data.data);
      setIsLoading(false);
    };
    getCurriculumDetailFromApi();
  }, [location]);

  return (
    <Content className="page-builder-view">
      {!isEmpty(html) ? renderTemplate(html) : null}
    </Content>
  )
}
