import type { GetProp, UploadProps } from "antd";
import { useAuthStore } from "../stores/stores";

export type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const getBase64 = (img: FileType, callBack: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callBack(reader.result as string));
  reader.readAsDataURL(img);
};

export const getFileDetailsFromUrl = (url: string) => {
  if (url) {
    const fileName = url?.split("/")?.pop()?.split("?")[0];
    const fileType = fileName?.split(".").pop();

    return { fileName, fileType };
  }
  return {};
};

export const getFileSize = async (url: string) => {
  const accessToken = useAuthStore.getState().accessToken;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `${accessToken}`,
    },
  });
  return response.headers.get("Content-Length");
};

export const getFileInfoFromURL = async (url: string) => {
  const accessToken = useAuthStore.getState().accessToken;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `${accessToken}`,
    },
  });
  return response;
};
