import { StateCreator } from "zustand";
import { CalendarSettings } from "../../types/calendar";
import dayjs from "dayjs";

export interface StudyScheduleSlice {
  initialDate: string;
  currentMonth: string;
  dateRange: any;
  calendarSettings: CalendarSettings;
  currentView: string;

  setInitialDate: (date: string) => void;
  setCurrentMonth: (month: string) => void;
  setDateRange: (date: any) => void;
  setCalendarSettings: (settings: CalendarSettings) => void;
  setCurrentView: (currentView: string) => void;
}

export const studyScheduleSlice: StateCreator<StudyScheduleSlice> = (
  set,
  get
) => ({
  initialDate: new Date().toISOString().split("T")[0],
  currentMonth: dayjs(new Date()).format("YYYY-MM-DD"),
  dateRange: { dateFrom: "", dateTo: "" },
  calendarSettings: {
    id: null,
    userId: null,
    dateForm: null,
    timeForm: null,
    notifyStudy: true,
    notifyExercise: true,
    notifyTest: true,
    notifyExam: true,
    notifyLearnMore: true,
    timeLimitBefore: null,
    timeTypeBefore: null,
    selectedOption: null
  },
  currentView: "dayGridMonth",
  setInitialDate: (date) => set({ initialDate: date }),
  setCurrentMonth: (month) => set({ currentMonth: month }),
  setDateRange: (day) => set({ dateRange: day }),
  setCalendarSettings: (setting) => set({ calendarSettings: setting }),
  setCurrentView: (view) => set({ currentView: view }),

});
