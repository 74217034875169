import { useEffect, useState } from 'react';
import { Checkbox, Input, Row, Col, Divider } from 'antd';
import { useSurveyRespond } from '../../../../../../../stores/stores';
import { downloadFile } from '../../../../../../../service/my-survey/my-survey-service';

const QuestionType6 = (props: any) => {
  const questionAnswer = props.questionAnswer;
  const { updateAnswer } = useSurveyRespond();
  const [selectedOptions, setSelectedOptions] = useState(new Set());
  const [updatedOptions, setUpdatedOptions] = useState<any[]>([]);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleCheckboxChange = (optionId: any) => {
    setIsOtherSelected(false)
    setSelectedOptions((prevSelectedOptions) => {
      const newSelection = new Set(prevSelectedOptions);
      if (newSelection.has(optionId)) {
        newSelection.delete(optionId);
      } else {
        newSelection.add(optionId);
      }
      return newSelection;
    });
  };

  const handleOtherChange = (e: any) => {
    setSelectedOptions(new Set())
    setIsOtherSelected(e.target.checked);
  };

  const handleChangeInput = (e: any) => {
    setInputValue(e.target.value);
  };


  const changePathToShow = async (input: string) => {
    try {
      const res = await downloadFile({
        filePath: input,
        destination: "",
      });
      return res.data; 
    } catch (error) {
      console.error("Error downloading file:", error);
      return ""; 
    }
  };

  useEffect(() => {
    const updateOptions = async () => {
      const updatedOptions = await Promise.all(
        props.listOptions.map(async (option: any) => {
          if (option.answerImage) {
            const newImagePath = await changePathToShow(option.answerImage);
            return { ...option, answerImage: newImagePath };
          } else {
            return option;
          }
        })
      );
      setUpdatedOptions(updatedOptions);
    };
    updateOptions();
  }, [props.listOptions]);

  useEffect(() => {
    if (Array.isArray(questionAnswer)) {
      setSelectedOptions(new Set(questionAnswer));
    } else if (typeof questionAnswer === "string") {
      setIsOtherSelected(true);
      setInputValue(questionAnswer);
    }
  }, []);

  useEffect(() => {
    const selectedOptionsArray = Array.from(selectedOptions);
    updateAnswer(props.surveyId, props.sectionId, props.questionId, selectedOptionsArray)
  }, [selectedOptions]);

  useEffect(() => {
    updateAnswer(props.surveyId, props.sectionId, props.questionId, inputValue)
  }, [inputValue]);

  return (
    <Row gutter={24} style={{ width: '100%' }}>
      {updatedOptions.map((option: any) => (
        !option.isOther && (
          <Col span={12} key={option.answerId}>
            <div className="containerImage"
              onClick={props.typeSurvey !== "2" ? () => handleCheckboxChange(option.answerId) : undefined}>
              <img src={option.answerImage} alt="description" style={{ cursor: 'pointer' }} />
            </div>
            <Checkbox
              key={option.answerId}
              checked={selectedOptions.has(option.answerId)}
              onChange={() => handleCheckboxChange(option.answerId)}
              disabled={props.typeSurvey === "2"}
            >
              {option.answer_text}
            </Checkbox>
          </Col>
        )
      ))}
      {props.listOptions.map((option: any) => (
        option.isOther && (
          <Col span={24} style={{ marginTop: '12px' }}>
            <Checkbox
              checked={isOtherSelected}
              onChange={handleOtherChange}
              disabled={props.typeSurvey === "2"}
            >
              Khác
            </Checkbox>
            {isOtherSelected && (
              <Input
                placeholder="Vui lòng nhập..."
                value={inputValue}
                onChange={handleChangeInput}
                style={{ marginTop: '12px', marginBottom: '12px' }}
                disabled={props.typeSurvey === "2"}
              />
            )}
            {!isOtherSelected && <Divider style={{ margin: '12px 0' }} />}
          </Col>
        )
      ))}
    </Row>
  );
};

export default QuestionType6;
