import {
  CheckOutlined,
  HeartOutlined,
  StarOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Image, Popover } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import { BUY_COURSE } from "../../constants";
import { postCourseEnrollment } from "../../service/courses";
import { getStartLearningCourse } from "../../service/my-course/my-course-service";
import {
  savedCourse,
  unsaveCourse,
} from "../../service/saved-course/saved-course-service";
import { globalStore, useAuthStore } from "../../stores/stores";
import { formatCurrency } from "../../utils/format";
import { notifyError, notifySuccess } from "../../utils/notification";
import ConfirmModal from "../modal/confirm-modal/ConfirmModal";
import "./style.scss";

export interface CourseCardProps {
  id?: number;
  image?: string;
  school?: string;
  name?: string;
  hours?: number;
  title?: string;
  price?: number;
  teachers?: string[];
  isPopular?: boolean;
  star?: number;
  students?: number;
  skills?: string[];
  slug?: string;
  description?: string;
  isSaved?: boolean;
  onClick?: (val?: any) => void;
  isRegistered?: boolean;
  learningStatus?: number;
}

function CourseCard(props: CourseCardProps) {
  const {
    image,
    school,
    hours,
    title,
    price,
    teachers = [],
    star,
    students,
    skills = [],
    slug,
    description,
    onClick = () => {},
    id,
    isSaved,
    isRegistered,
    learningStatus,
  } = props;
  const navigate = useNavigate();
  const accessToken = useAuthStore((state) => state.accessToken);
  const { reload, setReload } = globalStore();
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [detailWidth, setDetailWidth] = useState<number | undefined>(0);
  const [detailHeight, setDetailHeight] = useState<number | undefined>(0);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoginRequired, setIsLoginRequired] = useState<boolean>(false);

  useEffect(() => {
    setDetailWidth(elementRef.current?.clientWidth);
    setDetailHeight(elementRef.current?.clientHeight);
  }, [elementRef.current, window.innerWidth]);

  const skillRender =
    skills.length > 0
      ? skills
      : ["Không có thông tin", "Không có thông tin", "Không có thông tin"];

  const handleEnrollCourse = () => {
    if (accessToken) {
      if (price === 0) {
        setIsConfirm(true);
      } else {
        navigate(`${routesConfig.courseEnrollment}/${id}`);
      }
    } else {
      setIsLoginRequired(true);
    }
  };

  const handleSaveCourse = async () => {
    if (accessToken) {
      const apiUrl = isSaved ? unsaveCourse : savedCourse;
      try {
        const response = await apiUrl(Number(id));
        if (response?.data.codeStatus === 200) {
          setReload(!reload);
          notifySuccess(
            isSaved ? "Bỏ Lưu khoá học thành công" : "Lưu khoá học thành công"
          );
        }
      } catch (error) {}
    } else {
      setIsLoginRequired(true);
    }
  };
  const handleConfirmEnroll = async () => {
    const payload = {
      courseId: id,
      registrationForm: BUY_COURSE,
    };
    try {
      const res = await postCourseEnrollment(payload);
      if (res.status === 200) {
        setIsSuccess(true);
        setReload(!reload);
      }
    } catch (error: unknown) {
      notifyError((error as any)?.response?.data?.message);
    }
  };

  const startLearningCourse = async () => {
    if (learningStatus === 1) {
      try {
        getStartLearningCourse(Number(id));
        navigate(`${routesConfig.learningCourseStructure}/${id}`);
      } catch (error) {}
    } else {
      navigate(`${routesConfig.learningCourseStructure}/${id}`);
    }
  };

  const detailCard = () => {
    return (
      <Card
        className="detail-card pointer"
        style={{
          width: detailWidth,
          height: detailHeight,
        }}
      >
        <div className="card-detail-content">
          <h3 className="font-size-24 text-black mb-2">{title}</h3>
          <p className="text-primary font-size-12">
            <b>
              {school || "Không có thông tin trường"} {slug ? ` (${slug})` : ""}{" "}
              • {hours ? `Tổng ${hours} giờ` : "Tổng 0 giờ"}
            </b>
          </p>
          <div
            className="mt-3"
            dangerouslySetInnerHTML={{ __html: `${description}` || "" }}
          />

          <div className="list-skill flex-1">
            {skillRender.map((item: string, i: number) => (
              <div key={i} className="flex align-center gap-12 mt-2">
                <CheckOutlined className="check-icon" />{" "}
                <span className="text-black font-size-14">{item}</span>
              </div>
            ))}
          </div>

          <div className="flex gap-12 mt-2 mb-2 ">
            {isRegistered ? (
              <Button
                className="btn-primary h-40 flex-1 w-full-100"
                onClick={startLearningCourse}
              >
                {learningStatus !== 1 ? "Tiếp tục học" : "Bắt đầu học"}
              </Button>
            ) : (
              <>
                <Button
                  className="btn-primary h-40 flex-1"
                  onClick={handleEnrollCourse}
                >
                  Ghi danh khoá học
                </Button>

                <div
                  className={`h-40 center ${!isSaved ? "btn-like" : "btn-liked"}`}
                  onClick={handleSaveCourse}
                >
                  <HeartOutlined />
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
    );
  };
  const moveToCourse = async () => {
    setIsConfirm(false);
    navigate(`${routesConfig.learningCourseStructure}/${id}`);
    try {
      getStartLearningCourse(Number(id));
      navigate(`${routesConfig.learningCourseStructure}/${id}`);
    } catch (error) {}
  };

  return (
    <div className="course-card">
      <Popover
        content={detailCard()}
        trigger="hover"
        placement="right"
        overlayClassName="detail-card-overlay"
      >
        <Card
          style={{ width: "100%" }}
          className="transition-3"
          onClick={onClick}
        >
          <div className="card-content" ref={elementRef}>
            <Image
              preview={false}
              className="card-img"
              src={image || "http://placehold.it/300x250"}
              alt="card-img"
            />
            <p className="type-1 mt-3">
              {school || "Không có thông tin trường"} {slug ? ` (${slug})` : ""}{" "}
              • {hours ? `Tổng ${hours} giờ` : "Tổng 0 giờ"}
            </p>

            <h3 className="type-2 mt-2">
              {title || "Title"}{" "}
              <img
                className="view-icon"
                src="./assets/icons/arrow-up-right.svg"
                alt=""
              />
            </h3>
            <div className="flex align-center mt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M13.3333 6.66628V3.75016C13.3333 3.05704 13.3333 2.71048 13.1873 2.49751C13.0598 2.31143 12.8622 2.18503 12.6398 2.14718C12.3852 2.10386 12.0706 2.24909 11.4413 2.53955L4.04918 5.95127C3.48792 6.21031 3.20729 6.33984 3.00175 6.54071C2.82005 6.71829 2.68135 6.93507 2.59625 7.17447C2.5 7.44527 2.5 7.75435 2.5 8.3725V12.4996M13.75 12.0829H13.7583M2.5 9.33295L2.5 14.8329C2.5 15.7664 2.5 16.2331 2.68166 16.5896C2.84144 16.9032 3.09641 17.1582 3.41002 17.318C3.76654 17.4996 4.23325 17.4996 5.16667 17.4996H14.8333C15.7668 17.4996 16.2335 17.4996 16.59 17.318C16.9036 17.1582 17.1586 16.9032 17.3183 16.5896C17.5 16.2331 17.5 15.7664 17.5 14.8329V9.33295C17.5 8.39953 17.5 7.93282 17.3183 7.5763C17.1586 7.26269 16.9036 7.00773 16.59 6.84794C16.2335 6.66628 15.7668 6.66628 14.8333 6.66628L5.16667 6.66628C4.23325 6.66628 3.76654 6.66628 3.41002 6.84793C3.09641 7.00772 2.84144 7.26269 2.68166 7.57629C2.5 7.93281 2.5 8.39952 2.5 9.33295ZM14.1667 12.0829C14.1667 12.3131 13.9801 12.4996 13.75 12.4996C13.5199 12.4996 13.3333 12.3131 13.3333 12.0829C13.3333 11.8528 13.5199 11.6663 13.75 11.6663C13.9801 11.6663 14.1667 11.8528 14.1667 12.0829Z"
                  stroke="#344054"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="type-3 ml-1">{formatCurrency(price)}</span>
            </div>

            <p className="type-4 mt-1">
              Giảng viên:
              {teachers?.length > 0
                ? teachers.map((item: string, index) => (
                    <span className="ml-1" key={index}>
                      {index !== teachers.length - 1 ? `${item},` : item}
                    </span>
                  ))
                : " Không có thông tin"}
            </p>

            <div className="flex flex-wrap align-center mt-2 type-5">
              <div className="popular">Thịnh hành</div>
              {star ? (
                <div className="rate-star flex align-center">
                  <StarOutlined style={{ color: "#EF6820", marginRight: 6 }} />
                  {star}
                </div>
              ) : (
                ""
              )}

              <div className="total-student">
                <UserOutlined className="mr-1" />
                {students || 0} học viên
              </div>
            </div>
          </div>
        </Card>
      </Popover>
      <ConfirmModal
        open={isConfirm}
        handleOk={handleConfirmEnroll}
        handleCancel={() => setIsConfirm(false)}
        title="Xác nhận ghi danh khoá học"
        content={`Bạn xác nhận muốn ghi danh khoá học ${title}`}
      />

      <ConfirmModal
        open={isLoginRequired}
        handleOk={() => navigate(routesConfig.login)}
        handleCancel={() => setIsLoginRequired(false)}
        title="Yêu cầu đăng nhập"
        content={`Bạn cần đăng nhập để sử dụng tính năng này`}
        confirmIcon={`${process.env.PUBLIC_URL}/assets/icons/info-circle-border.svg`}
      />

      <ConfirmModal
        open={isSuccess}
        handleOk={moveToCourse}
        handleCancel={() => {
          setIsConfirm(false);
          setIsSuccess(false);
        }}
        btnCancel={false}
        title="Ghi danh thành công!"
        confirmIcon={`${process.env.PUBLIC_URL}/assets/icons/check-circle-border.svg`}
        content={`Chúc mừng bạn đã ghi danh thành công khoá học`}
        confirmBtntitle="Đi đến khóa học"
      />
    </div>
  );
}

export default CourseCard;
