import { Button, Card, Flex, Form, FormProps, Modal, Typography } from "antd";
import { Link } from "react-router-dom";
import FormItemInput from "../../../../components/form-input/FormInput";
import { EditOutlined, ExclamationCircleTwoTone } from "@ant-design/icons";
import { useRef, useState } from "react";
import { useIdentifyEkycStore } from "../../../../stores/stores";
import dayjs from "dayjs";
import { EkycRegisterUserResponse } from "../../../../types/ekycIdentify";
import { ekycConfirmSession } from "../../../../service/ekyc";
import { HelpService } from "../../../../service/helper.service";

const { Title, Text } = Typography;

type FieldType = {
  hoVaTen: string;
  CCCD: string;
  queQuan: string;
  ngaySinh: string;
  ngayCap: string;
  ngayHetHan: string;
  noiCap: string;
  quocTich: string;
  dacDiem: string;
  diaChi: string;
};
const DEFAULT_FORM = [
  { label: "Số ID", name: "card_id" },
  { label: "Họ và tên", name: "name" },
  { label: "Quê quán", name: "place_of_birth" },
  { label: "Ngày sinh", name: "date_of_birth" },
  { label: "Ngày cấp", name: "issue_date" },
  { label: "Ngày hết hạn", name: "expiry_date" },
  { label: "Nơi cấp", name: "place_of_issue" },
  { label: "Quốc tịch", name: "nationality" },
  { label: "Địa chỉ thường trú", name: "address" },
  { label: "Đặc điểm nhận dạng", name: "personal_identification" },
];
const CommonFormInfo = ({ val }: any) => {
  return (
    <Form.Item
      label={val?.label}
      name={val?.name}
      className="w-full sm:w-1/2 sm:px-4 py-2"
    >
      <FormItemInput suffix={<EditOutlined />} placeholder={val?.label} />
    </Form.Item>
  );
};

const IdentifyEkycConfirmInfo = () => {
  const helpService = useRef(new HelpService());
  const [isOpen, setIsOpen] = useState(false);
  const { changeStep } = useIdentifyEkycStore();
  const { data } = useIdentifyEkycStore();
  const [metadata, setMetadata] = useState<
    Partial<EkycRegisterUserResponse["data"]>
  >({});
  const [initialValue] = useState({
    name: data?.name || "",
    card_id: data?.card_id || "",
    place_of_birth: data?.place_of_birth || "",
    date_of_birth: data?.date_of_birth
      ? dayjs(data.date_of_birth).format("DD/MM/YYYY")
      : "",
    issue_date: data?.issue_date
      ? dayjs(data.issue_date).format("DD/MM/YYYY")
      : "",
    expiry_date: data?.expiry_date
      ? dayjs(data.expiry_date).format("DD/MM/YYYY")
      : "",
    place_of_issue: data?.place_of_issue || "",
    nationality: data?.nationality || "",
    personal_identification: data?.personal_identification || "",
    address: data?.address || "",
  });
  const onFinishUpdate: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
    const cloned = { ...metadata };
    Object.entries(initialValue).forEach(([key, value]) => {
      //@ts-ignore
      if (values[key] !== value) {
        //@ts-ignore
        cloned[key] = values[key];
      }
    });
    setMetadata(cloned);
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleConfirm = async () => {
    try {
      await ekycConfirmSession({ data: metadata });
      changeStep(3);
      helpService.current.successMessage("Xác thực thông tin thành công");
    } catch (error) {
      helpService.current.errorMessage("Xác thực thông tin không thành công");
    }
  };

  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinishUpdate}
        initialValues={initialValue}
      >
        <div className="border-b py-4">
          <Title level={4} className="!mb-0 !font-semibold mt-4">
            Xác nhận thông tin
          </Title>
          <Text className="text-[#475467]">
            Vui lòng đọc kĩ thông tin Xác thực tài khoản. Nếu thông tin chưa
            chính xác, vui lòng bấm chọn chỉnh sửa thông tin
          </Text>
        </div>
        <Card>
          <Text>
            Thông tin của bạn sẽ được lưu trữ một cách an toàn, đảm bảo tuân thủ
            luật pháp và các quy định nhà nước. Bạn có thể tham khảo{" "}
            <Link to={"#"}>Chính sách bảo mật</Link> của chúng tôi để biết thêm
            chi tiết
          </Text>
        </Card>
        <Card>
          <div className="px-3">
            <div className="border-b py-4">
              <Title level={4} className="!mb-0 !font-semibold">
                Thông tin giấy tờ cá nhân của bạn
              </Title>
              <Text className="text-[#475467]">
                Hãy xác nhận thông tin. Nếu thông tin chưa chính xác, xin vui
                lòng bấm chọn chỉnh sửa lại thông tin
              </Text>
            </div>
          </div>

          <Flex justify="space-between" wrap="wrap">
            {DEFAULT_FORM?.map((value) => <CommonFormInfo val={value} />)}
          </Flex>
        </Card>
        <div className="w-full py-4 mb-8 px-6">
          <Button
            className="w-full"
            size="large"
            type="primary"
            htmlType="submit"
          >
            Xác nhận
          </Button>
        </div>
      </Form>
      <Modal
        title={<ExclamationCircleTwoTone />}
        centered
        open={isOpen}
        onCancel={handleClose}
        footer={[
          <Button key="back" onClick={handleClose}>
            <span>Hủy</span>
          </Button>,
          <Button key="submit" type="primary" onClick={handleConfirm}>
            <span>Xác nhận</span>
          </Button>,
        ]}
      >
        <div>
          <Title className="!font-semibold" level={5}>
            Xác nhận thông tin
          </Title>
          <Text>
            Đảm bảo rằng tất cả các thông tin đã được kiểm tra và hợp lệ
          </Text>
        </div>
      </Modal>
    </div>
  );
};

export default IdentifyEkycConfirmInfo;
