import { Button, Input } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../../../components/banner/Banner";
import FilterTopic from "../../../components/filter/FilterTopic";
import ResponsiveList from "../../../components/list/ResponsiveList";
import { routesConfig } from "../../../config/routes";
import { DEFAULT_LOADING_TIME } from "../../../constants";
import useKeyword from "../../../hooks/useKeyword";
import useMetaData from "../../../hooks/useMetaData";
import { getGroupIndustry } from "../../../service/courses";
import { HelpService } from "../../../service/helper.service";
import { GroupIndustry, IndustryInfo } from "../../../types/course";

function CourseTopics() {
  const navigate = useNavigate();
  const helpService = new HelpService();
  const { groupIndustry } = useMetaData();
  const [keySearch, setKeySearch] = useState("");
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [groups, setGroups] = useState<GroupIndustry[]>([]);
  const [mode, setMode] = useState<"search" | "default">("default");
  const keyWord = useKeyword("keyword");

  const dataRender = mode === "search" ? groups : groupIndustry;

  useEffect(() => {
    if (keyWord) {
      const getGroupsIndustry = async () => {
        try {
          setMode("search");
          setLoadingSearch(true);
          const res = await getGroupIndustry(keyWord);
          setGroups(res.data.data);
        } catch (err: any) {
          helpService.errorMessage(err);
        } finally {
          setTimeout(() => setLoadingSearch(false), DEFAULT_LOADING_TIME);
        }
      };
      getGroupsIndustry();
    }
  }, [keyWord]);

  const topics = dataRender?.map((item: GroupIndustry) => ({
    content: (
      <FilterTopic
        title={item.name}
        items={item.children.map((child: IndustryInfo) => ({
          label: child.name as string,
          value: child.id,
        }))}
        onChange={(itm) => navigate(`${routesConfig.courseInfo}?topic=${itm?.value}`)}
      />
    ),
  }));

  const handleClickSearch = () => {
    if (keySearch) {
      navigate(`${routesConfig.courseTopics}?keyword=${keySearch?.trim()}`);
    } else {
      navigate(`${routesConfig.courseTopics}`);
      setMode("default");
    }
  };
  return (
    <div>
      <Banner>
        <section className="center flex-column course-info-banner-content">
          <span className="mb-2 text-violet">Danh sách chuyên ngành</span>
          <h3 className="mb-3 text-white banner-title">
            Các chủ đề hiện có trên Mooc
          </h3>
          <span className="mb-4 text-violet">
            Với 1000+ các khoá học với nhiều chủ đề khác nhau
          </span>

          <div className="search flex align-center">
            <Input
              className="search-input"
              value={keySearch}
              onChange={(e) => setKeySearch(e.target.value)}
              placeholder="Nhập từ khoá cần tìm"
            />
            <Button
              className="mooc-btn-violet btn-primary"
              onClick={handleClickSearch}
            >
              Tìm kiếm
            </Button>
          </div>
        </section>
      </Banner>

      <section className="content-layout">
        <ResponsiveList
          onClickReport={() => navigate(routesConfig.statisticsCourses)}
          loading={loadingSearch}
          className="mt-5"
          grid={{ gutter: 30, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
          data={topics}
        />
      </section>
    </div>
  );
}

export default CourseTopics;
