import React, { useState } from "react";
import "./detail.scss";
import {
  Card,
  Row,
  Col,
} from "antd";
import {
  StarOutlined,
} from "@ant-design/icons";

import QuestionType1 from "../question-type-1/QuestionType1";
import {
  ListAnswer,
  ListQuestion,
  OptionTypeQuestion1,
} from "../../../../../../../types/surveyRespond";
import QuestionType2 from "../question-type-2/QuestionType2";
import QuestionType3 from "../question-type-3/QuestionType3";
import QuestionType4 from "../question-type-4/QuestionType4";
import QuestionType6 from "../question-type-6/QuestionType6";
import QuestionType5 from "../question-type-5/QuestionType5";
import QuestionType7 from "../question-type-7/QuestionType7";
import QuestionType8 from "../question-type-8/QuestionType8";

interface QuestionUI {
  listSectionQuestions: ListQuestion[];
  surveyId: number;
  typeSurvey: any;
}

const QuestionUI: React.FC<QuestionUI> = ({ listSectionQuestions, surveyId, typeSurvey }) => {


  const questionsUI = (typeQuestionId: number, questionId: number, surveySectionId: number, surveyId: number, questionAnswer: any, list_answer?: ListAnswer | ListAnswer[]) => {

    const optionsExample: OptionTypeQuestion1[] = [
      { id: 111, content: "vb 01", order: 1 },
      { id: 332, content: "vb 02", order: 2 },
      { id: 223, content: "vb 03", order: 3 },
      { id: 404, content: "vb 04", order: 4 },
    ];

    const [listAnswer, setListAnswer] = useState(list_answer);

    switch (typeQuestionId) {
      case 1:
        return (
          <QuestionType1
            listOptions={listAnswer}
            setListAnswer={setListAnswer}
            questionId={questionId} sectionId={surveySectionId}
            surveyId={surveyId}
            questionAnswer={questionAnswer}
            typeSurvey={typeSurvey}
          />
        );
      case 2:
        return <QuestionType2 listOptions={listAnswer} setListAnswer={setListAnswer} surveyId={surveyId} questionId={questionId} sectionId={surveySectionId} questionAnswer={questionAnswer} typeSurvey={typeSurvey} />;
      case 3:
        return <QuestionType3 listOptions={listAnswer} surveyId={surveyId} questionId={questionId} sectionId={surveySectionId} questionAnswer={questionAnswer} typeSurvey={typeSurvey} />;
      case 4:
        return <QuestionType4 listOptions={listAnswer} setListAnswer={setListAnswer} surveyId={surveyId} questionId={questionId} sectionId={surveySectionId} questionAnswer={questionAnswer} typeSurvey={typeSurvey} />;
      case 5:
        return <QuestionType5 listOptions={listAnswer} setListAnswer={setListAnswer} surveyId={surveyId} questionId={questionId} sectionId={surveySectionId} questionAnswer={questionAnswer} typeSurvey={typeSurvey} />;
      case 6:
        return <QuestionType6 listOptions={listAnswer} setListAnswer={setListAnswer} surveyId={surveyId} questionId={questionId} sectionId={surveySectionId} questionAnswer={questionAnswer} typeSurvey={typeSurvey} />;
      case 7:
        return <QuestionType7 listOptions={listAnswer} setListAnswer={setListAnswer} surveyId={surveyId} questionId={questionId} sectionId={surveySectionId} questionAnswer={questionAnswer} typeSurvey={typeSurvey} />;
      case 8:
        return <QuestionType8 listOptions={optionsExample} surveyId={surveyId} questionId={questionId} sectionId={surveySectionId} questionAnswer={questionAnswer} typeSurvey={typeSurvey} />;
      default:
        return null;
    }
  };
  return (
    <div className="question-ui">
      {listSectionQuestions.map(
        (sectionQuestion: ListQuestion, index: number) => {
          const {
            questionId,
            surveySectionId,
            questionName,
            questionDescription,
            questionType,
            questionAnswer,
            orderNumber,
            isRequired,
            questionsPoints,
            responseValidationType,
            responseValidationOperator,
            responseValidationValue,
            responseValidationCustomErrorText,
            moocSurveyQuestionAnswer,
          }: ListQuestion = sectionQuestion;
          return (
            <Card
              key={index}
              styles={{ header: { border: "none" } }}
              className="wrapper-settings--card form-setting--common"
            >
              <Row>
                <Row>
                  <Row style={{ paddingBottom: "20px" }}>
                    <Col span={24} className="surveyTitle">{isRequired && <StarOutlined style={{ marginRight: "10px", color: "red" }} />}{questionName}</Col>
                    <Col span={24} className="surveyDiscription" dangerouslySetInnerHTML={{ __html: questionDescription ?? "" }}></Col>
                  </Row>
                </Row>
                <Row style={{ width: '100%' }}>{questionsUI(Number(questionType), questionId, surveySectionId, surveyId, questionAnswer, moocSurveyQuestionAnswer)}</Row>
              </Row>
            </Card>
          );
        }
      )}
    </div>
  );
};

export default QuestionUI;
