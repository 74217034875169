import React, { useState } from "react";
import { renderStarRating } from "../../../utils/renderStarRating";
import "./SystemEvaluation.scss";
import { MinusOutlined } from "@ant-design/icons";

type Props = {};
interface EvaluatorType {
  id: number;
  rateStar: number;
  review: string;
  author: string;
  avatar: string;
  major: string;
  university: string;
}
const systemEvaluationList = [
  {
    id: 0,
    rateStar: 5,
    review:
      "Hệ thống tuyệt vời cung cấp các khoá học được cấp chứng chỉ rất tiện lợi cho tôi",
    author: "Nguyễn văn An",
    avatar: "https://st.quantrimang.com/photos/image/072015/22/avatar.jpg",
    major: "Ngành công nghệ thông tin",
    university: "Học viện công nghệ bưu chính viễn thông",
  },

  {
    id: 1,
    rateStar: 2,
    review:
      "Hệ thống tuyệt vời cung cấp các khoá học được cấp chứng chỉ rất tiện lợi cho tôi",
    author: "Trần Thị Thuỷ",
    avatar: "https://ngothoinhiem.edu.vn/data/user/nu.jpg",
    major: "Ngành ngôn ngữ học",
    university: "Đại học kinh tế quốc dân",
  },
  {
    id: 2,
    rateStar: 5,
    review:
      "Hệ thống tuyệt vời cung cấp các khoá học được cấp chứng chỉ rất tiện lợi cho tôi",
    author: "Nê Nam",
    avatar:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTht9-qZYmqErdGMhJVbRf7BfhLRGspNWaFnR8nddu3x7Da7nqh23vsG6VWtG_VE9G9kLU&usqp=CAU",
    major: "Công công nghệ phần mềm",
    university: "Đại học bách khoa Hà Nội",
  },
];
const SystemEvaluation = (props: Props) => {
  const [evaluator, setEvaluator] = useState<EvaluatorType>(
    systemEvaluationList?.[0]
  );

  return (
    <div className="system-evaluation">
      <div className="system-evaluation-rating">
        <div>{renderStarRating(evaluator?.rateStar)}</div>
        <div className="review">{evaluator?.review}</div>

        <div className="mt-3 mb-3 flex align-center">
          <MinusOutlined />{" "}
          <div className="font-size-18 font-weight-6 ml-1">
            {evaluator?.author}
          </div>
        </div>
        <div className="home-page-description">
          {evaluator?.major + ", " + evaluator?.university}
        </div>
        <div className="dot-select">
          {[...Array(3)].map((_, i) => (
            <div
              key={i}
              className={`dot ${evaluator.id === i ? "primary-dot" : ""}`}
              onClick={() => setEvaluator(systemEvaluationList[i])}
            ></div>
          ))}
        </div>
      </div>
      <img className="avatar-img" src={evaluator.avatar} />
    </div>
  );
};

export default SystemEvaluation;
