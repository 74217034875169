import React from "react";
import { Checkbox, Col, Flex, Form, Popover, Row } from "antd";
import {
  QuestionBeProps,
  QuestionProps,
} from "../../../../../../../types/course";
import { convertNumberingTypeToText } from "../../../../../../../utils/format";
import { isEmpty, toNumber } from "lodash";
import { QuestionMarkIcon } from "../../../../../../../components/icons";
import SingleMenu from "../../../../../../../components/menu/SingleMenu";


function ChooseMultiImage({ data, configs }: QuestionProps) {
  const result = data?.result;
  const isTrue = data?.result?.isTrue === true;

  const isChecked = (uuid: string) => {
    if (!result?.results && !result?.results?.length) return false;

    const ans = result?.results[0]?.find((res: any) => res?.uuid === uuid);

    if (ans) return true;
    return false;
  };

  const renderResultStyle = (uuid: string) => {
    if (!result?.results && !result?.results?.length) return "";
    const ans = result?.results[0]?.find((res: any) => res?.uuid === uuid);

    if (ans && ans?.isTrue === true) return "active-quiz-correct choose-correct";
    if (ans && ans?.isTrue === false) return "active-quiz-wrong choose-incorrect";
    return "active-quiz";
  };

  const renderFeedback = () => {
    if (isEmpty(data?.result) && !isTrue)
      return data?.settingResponse?.notYet || "Không có phản hồi";

    if (isTrue) return data?.settingResponse?.correct || "Không có phản hồi";

    return data?.settingResponse?.incorrect || "Không có phản hồi";
  };

  return (
    <div>
      <div className="question-header mb-3">
        <div className="question-title">
          <Flex
            justify="space-between"
            className="font-size-16 line-height-24 font-weight-6"
          >
            Câu hỏi {toNumber(data?.index) + 1}:{" "}
            {configs?.generalConfig?.showQuizScore && (
              <span className="total-point font-size-14">
                {`(${data?.totalPoints || 0} điểm)`}
              </span>
            )}
          </Flex>
          <Flex align="flex-start" gap={8}>
            <div
              className="font-size-16 line-height-24 font-weight-6"
              dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
            ></div>
            {data?.settingHint?.isDisplayInstruction || data?.isShowHint ? (
              <Popover
                content={
                  <div style={{ padding: "5px 16px" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${data?.settingHint?.content || "Không có gợi ý"}`,
                      }}
                    />
                  </div>
                }
              >
                <span className="quest-icon text-secondary w-max-content">
                  <QuestionMarkIcon />
                </span>
              </Popover>
            ) : (
              ""
            )}
          </Flex>
          <div className="flex align-center gap-8 mt-1">
            {result ? (
              <span
                className={`font-size-14 line-height-20 font-weight-6 ${data?.result?.isTrue === true ? "score-correct-wrapper text-correct" : "score-incorrect-wrapper text-danger"}`}
              >
                Điểm: {data?.result?.points || 0}/
                {data?.result?.totalPoints || 0}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Row gutter={[32, 32]}>
        {data?.questions &&
          data?.questions?.length > 0 &&
          data?.questions?.map((quiz: QuestionBeProps, index) => (
            <Col key={quiz.uuid + index} xs={24} md={12}>
              <div
                className={`image-quiz-wrap ${renderResultStyle(quiz?.uuid)}`}
                key={quiz?.uuid + index}
              >
                <Form.Item className="mb-12" valuePropName="checked">
                  <Checkbox
                    id={`${index}`}
                    disabled={true}
                    checked={isChecked(quiz?.uuid)}
                  >
                    <span
                      className={`font-size-16 line-height-24 font-weight-5 ${quiz?.isTrue && "text-result"}`}
                    >
                      {convertNumberingTypeToText(
                        index + 1,
                        data?.settingGeneral?.numberingType
                      )}
                      . {quiz?.content || "Không có nội dung"}
                    </span>
                  </Checkbox>
                </Form.Item>
                <label htmlFor={`${index}`}>
                  <img
                    className="mt-1"
                    src={quiz?.filePath || ""}
                    alt="quiz-card"
                  />
                </label>
              </div>
            </Col>
          ))}
      </Row>
      <div className="flex-1 mt-2">
       {configs?.generalConfig?.showFeedbackContent &&
          !data?.settingResponse?.isDisplayPerResponse && !isEmpty(data?.settingResponse) && (
            <SingleMenu
              titleClassName={`comment-menu ${isTrue ? "report-ans-wrapper-correct" : "report-ans-wrapper"} `}
              contentClassName={`${isTrue ? "bg-green" : "bg-purple"}`}
              className={`${isTrue ? "bg-green" : "bg-purple"}`}
              title={
                <p className="font-size-16 line-height-24 font-weight-6">
                  Phản hồi: {renderFeedback()}
                </p>
              }
              content={
                <p className="font-size-16 line-height-24 mt-1 font-weight-5">
                  {data?.result?.message || "Không có nhận xét"}
                </p>
              }
            />
          )}
      </div>
    </div>
  );
}

export default ChooseMultiImage;
