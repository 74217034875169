import "moment/locale/vi";
import viVN from "antd/lib/locale/vi_VN";
import dayjs, { Dayjs } from "dayjs";

export const viLocale = {
  ...viVN,
  lang: {
    ...viVN,
    rangePlaceholder: ["Ngày bắt đầu", "Ngày kết thúc"],
  },
  locale: "viVN",
};


export const getDisabledTimeRanges = (timeInSeconds: number) => {
  const startTime = dayjs().startOf('day');
  const endTime = startTime.add(timeInSeconds, 'second');

  return (date: Dayjs, range: 'start' | 'end') => {
    const disabledHours = () => {
      const allHours: number[] = Array.from(Array(24).keys());
      const allowedHours: number[] = [startTime.hour()];

      return allHours.filter(hour => !allowedHours.includes(hour));
    };

    const disabledMinutes = (selectedHour: number) => {
      if (selectedHour !== startTime.hour()) {
        return Array.from(Array(60).keys());
      }
      const allMinutes: number[] = Array.from(Array(60).keys());
      const allowedMinutes: number[] = Array.from({ length: endTime.minute() + 1 }, (_, i) => i);
      return allMinutes.filter(minute => !allowedMinutes.includes(minute));
    };

    const disabledSeconds = (selectedHour: number, selectedMinute: number) => {
      if (selectedHour !== startTime.hour()) {
        return Array.from(Array(60).keys());
      }

      if (selectedMinute < startTime.minute() || selectedMinute > endTime.minute()) {
        return Array.from(Array(60).keys());
      }

      if (selectedMinute === startTime.minute()) {
        return Array.from(Array(60).keys()).filter(second => second < startTime.second());
      }

      if (selectedMinute === endTime.minute()) {
        return Array.from(Array(60).keys()).filter(second => second > endTime.second());
      }

      return [];
    };

    return {
      disabledHours,
      disabledMinutes,
      disabledSeconds,
    };
  };
};
