import { ArrowDownOutlined, ClockCircleOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { Button } from 'antd';
import { getListContentBlog } from '../../../service/blog';
import { blogAndForumStore } from '../../../stores/stores';
import { useLocation, useNavigate } from 'react-router-dom';

export default function ListBlogContent() {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(6);
  const [listBlogContent, setListBlogContent] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const navigate = useNavigate()
  const {refresh , searchValue} = blogAndForumStore()
  const location = useLocation();

  const dataPath = location.search?.split("&slug=")
  const paramSearch = dataPath && dataPath[0].replace("?uuid=", "")
  const getListData = async () => {
    const data = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue ?? "",
      sort: [],
      status: '2',
      enterpriseId: paramSearch
    }
    await getListContentBlog(data).then((res) => {
      if (isLoadMore) {
        setListBlogContent([...listBlogContent, ...res.data.data.universityBlogData])
      } else {
        setListBlogContent(res.data.data.universityBlogData)
      }
      setTotalRecords(res.data.data.total)
    })
  };

  const handleClick = (item: any) =>{
    const searchParams = new URLSearchParams(location.search);
    const uuidValue = searchParams.get("uuid");
    const slugValue = searchParams.get("slug");
    const newPath = `/blog/topic?blogId=${item.id}&uuid=${uuidValue}&slug=${slugValue}`;
    navigate(newPath, {state: item})
  }
 
  useEffect(() => {
    getListData();
  }, []);

  useEffect(() => {
    getListData();
  }, [pageNumber, refresh]);

  const listContent = listBlogContent?.map((item, index) => (
    <div onClick={()=>handleClick(item)} key={'index' + index} id={'index' + index} className={`flex gap-3 rounded-sm bg-red flex-col cursor-pointer` }>
      <img src={item?.imagePath} alt="img-path" className='max-w-[400px] max-h-[250px] object-cover' />
      <div className='flex flex-col w-full'>
        <h3 className=''> <span className='size-4 font-medium'>Tổng số chủ đề:</span> <span className='rounded-xl px-1.5 py-0.5 bg-blue-400 text-white text-xs ml-2'>{item?.amountTopic}</span></h3>
        <div data-gjs-editable="false" data-gjs-removable="false"
          className='text-2xl mt-2 font-medium overflow-hidden text-ellipsis max-h-[65px] max-w-[99%] whitespace-nowrap' dangerouslySetInnerHTML={{ __html: item?.name }}
        ></div>
        <p className='mt-2 flex gap-3 text-gray-400'> <ClockCircleOutlined className='mt-1.5' /> Lần cập nhật ...</p>
        <div data-gjs-editable="false" data-gjs-removable="false" className='mt-2 font-medium overflow-hidden text-ellipsis max-h-[65px] max-w-[99%] whitespace-nowrap' dangerouslySetInnerHTML={{ __html: item?.description }}></div>
      </div>
    </div>
  ))
  return (
    <>
      <div className="container p-5 mx-auto grid lg:grid-cols-3 md:grid-cols-2 lg:max-w-7xl gap-10 text-gray-600 py-20 justify-center items-center">
        {listContent}
      </div>
      <div className='flex justify-center'>
        {totalRecords > 6 && totalRecords > 6 * pageNumber ? <Button onClick={() => {
          setPageNumber(pageNumber + 1)
          setIsLoadMore(true)
        }
        } className='border-spacing-1 border rounded-md p-3 mb-5 flex justify-center items-center gap-3 hover:bg-[#7f56d9] hover:text-white border-[#7f56d9] text-[#7f56d9]'><ArrowDownOutlined /> Xem thêm</Button> : null}
      </div>
    </>
  )
}
