import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { routesConfig } from '../../../config/routes'

export default function BlogHeaderLink(props: any) {
  const location = useLocation()
  const navigate = useNavigate()

  const gotoBlogPage = () => {
    const dataPath = location.search?.split("&slug=")
    const paramSearch = dataPath && dataPath[0].replace("?uuid=", "")
    const slug = dataPath && dataPath[1]
    navigate(`${routesConfig.searchSchool}/${routesConfig.universityTemplateDetail}/${routesConfig.blog}?uuid=${paramSearch}&slug=${slug}`)
  }

  return (
    <a className="text-white hover:bg-red-100 hover:text-red-700 p-4 rounded" onClick={gotoBlogPage}>Blog</a>
  )
}
