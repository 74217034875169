import React from 'react';
import {Button, Result} from "antd";
import {Content} from "antd/lib/layout/layout";
import {FrownOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {routesConfig} from "../../config/routes";
import './result-delete.scss'

const ResultDelete = () => {
  const navigate = useNavigate()
  return (
    <>
      <Content>
        <div className="wrapper wrapper-page--result">
          <section className="container-lg">
            <div className="content-result">
              <Result
                icon={<FrownOutlined/>}
                title="Tài khoản đã xoá!"
                subTitle="Cảm ơn bạn đã sử dụng hệ thống Mooc!"
                extra={<Button className="btn btn-primary" onClick={() => navigate(routesConfig.homePage)}>Đi đến trang
                  chủ</Button>}
              />
            </div>
          </section>
        </div>
      </Content>
    </>
  );
};

export default ResultDelete;
