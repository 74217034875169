import React from "react";
import "./StarterCourse.scss";
import { CourseDetail } from "../../../types/course";
import { StarOutlined, UserOutlined } from "@ant-design/icons";
import { formatCurrencyToVND } from "../../../utils/format";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import { Col, Empty, Row } from "antd";
import { updateBehaviour } from "../../../service/courses";

type Props = {
  courseList: Array<CourseDetail>;
  behaviourId: number
};

const StarterCourse = ({ courseList, behaviourId }: Props) => {
  const navigate = useNavigate();
  
  const handleBehavior = async (courseId: any) => {
    try {
      await updateBehaviour(behaviourId, courseId, '1')
    } catch (error) {}
    navigate(`${routesConfig.courseInfo}/detail/${courseId}`)
  }
  return (
    <div className="starter-course">
      <div className="font-size-16 text-primary text-center font-weight-6">
        Danh sách khoá học
      </div>
      <div className="text-title-36 text-center mt-2">
        Khoá học để bạn bắt đầu
      </div>
      <div className="text-center font-size-20 mt-3">
        Hệ thống phát triển nền tảng cung cấp khoá học trực tuyến mở cung cấp mở
        với
        <div>đa dạng khoá học ở nhiều lĩnh vực khác nhau</div>
      </div>

      <div className="starter-course-list">
        {courseList?.length > 0 ? (
          <Row gutter={[24, 24]}>
            {courseList?.map((course) => (
              <Col
                xs={24}
                md={12}
                xl={8}
                lg={8}
                xxl={8}
                key={course?.id}
                className="starter-course-item"
                onClick={() => handleBehavior(course?.id)}
              >
                <img
                  className="card-img"
                  src={
                    course?.thumbnailUrl ||
                    `${process.env.PUBLIC_URL}/assets/icons/empty-course.png`
                  }
                />
                <div
                  className="font-weight-6 mt-3"
                  style={{ color: "#475467" }}
                >
                  {course?.time && (
                    <div className="d-flex align-center">
                      Tổng số <span>{course?.time}</span> giờ
                      <div className="dot"></div>
                    </div>
                  )}
                  <div>
                    {course?.universityName}{" "}
                    {course?.slug && "(" + course?.slug + ")"}
                  </div>
                </div>

                <div className="font-size-24 font-weight-6 mt-1 mb-1 flex justify-between">
                  {course?.name}
                  <img
                    className="view-icon"
                    src="./assets/icons/arrow-up-right.svg"
                    alt=""
                  />
                </div>
                <div className="cost">
                  <img
                    className="course-info-img"
                    src={`${process.env.PUBLIC_URL}/assets/icons/wallet.svg`}
                  />
                  {course?.cost && formatCurrencyToVND(course?.cost)}
                </div>
                <div className="mt-1 mb-2">
                  {course?.teachers && course?.teachers?.length > 0 ? (
                    <>
                      Giảng viên:
                      {course?.teachers?.map((it: any, index: number) => (
                        <span key={index}>
                          {" " + it}
                          {index !== (course?.teachers?.length ?? 0) - 1 &&
                            ", "}
                        </span>
                      ))}
                    </>
                  ) : null}
                </div>
                <div className="rating">
                  <div className="popular">Thịnh hành</div>
                  {course?.avgStar ? (
                    <div className="rate-star flex align-center">
                      <StarOutlined
                        style={{ color: "#EF6820", marginRight: 6 }}
                      />
                      {course?.avgStar}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="total-student">
                    <UserOutlined className="mr-1" />
                    {course?.totalStudents || 0} học viên
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <div className="flex-1 flex justify-center">
            <Empty description="Không có kết quả" />
          </div>
        )}
      </div>
      <div className="text-center">
        <button
          className="link-to-course-list"
          onClick={() => navigate(routesConfig.courseInfo)}
        >
          Danh sách tất cả khoá học
        </button>
      </div>
    </div>
  );
};

export default StarterCourse;
