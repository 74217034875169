import { Outlet, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import Header from "../header/Header";
import { useViewport } from "../../hooks/useViewport";
import { menuItems } from "../sidebar/MenuItem";
import { useEffect, useState } from "react";
import { UpCircleOutlined } from "@ant-design/icons";
import Footer from "../../components/footer";
import ScrollToTop from "../../hooks/ScrollToTop";
import ChatbotComponent from "../../components/chat-bot/ChatBotComponent";
import { globalStore } from "../../stores/stores";
import ChatBotAssistant from "../../components/chat-bot/ChatBotAssistant";
import { routesConfig } from "../../config/routes";

export default function DashboardRootPage() {
  const location = useLocation();
  const { width } = useViewport();
  const isMobile = width <= 768;
  const [showScroll, setShowScroll] = useState(false);
  const { isFooterDefault } = globalStore();

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScroll]);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 200) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 200) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Cuộn lên đầu khi đường dẫn thay đổi
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Cuộn lên đầu khi trang được làm mới
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkRouter = location.pathname.includes(routesConfig.universityTemplateDetail)
    || location.pathname.includes(routesConfig.universityCourseTemplateDetail)
    || location.pathname.includes(routesConfig.universityCourseProgramTemplateDetail)
    || (location.pathname.includes(`${routesConfig.curriculumPrograms}/detail`) && location.state?.screen === "schoolDetail")

  return (
    // <AuthGuard>
    <main>
      <Layout>
        {checkRouter ? null : (
          <Header />
        )}
        <ScrollToTop />
        {isMobile && (
          <Menu
            mode="horizontal"
            defaultSelectedKeys={[location.pathname]}
            items={menuItems}
            className="menu-mobile"
          />
        )}
        <Layout style={{ minHeight: "calc(100vh - 370px)" }}>
          <Outlet />
        </Layout>
        <div className="scrollTop">
          <div
            onClick={scrollTop}
            className="scrollTopButton"
            style={{ display: showScroll ? "flex" : "none" }}
          >
            <UpCircleOutlined className="scroll-top-icon" />
          </div>
        </div>
        {checkRouter ||
          !isFooterDefault ? null : (
          <Footer />
        )}
      </Layout>
      {/* <ChatBotAssistant /> */}
      <ChatbotComponent />
    </main>
    // </AuthGuard>
  );
}
