import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Checkbox, ConfigProvider, GetProp } from "antd";
import locale from "antd/es/locale/vi_VN";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import {
  CLASS_SCHEDULE,
  EXAM,
  EXERCISE,
  EXTRA_CLASS_SCHEDULE,
  TEST,
} from "../../../../constants";
import { studyScheduleStore } from "../../../../stores/stores";
import "./StudyScheduleMenu.scss";

dayjs.locale("vi");

type Props = {
  setScheduleTypes: (data: number[]) => void;
  dateChange: any;
  setDateChange: (data: any) => void;
};

const StudyScheduleMenu = (props: Props) => {
  const { setScheduleTypes } = props;
  const {
    initialDate = new Date().toISOString(),
    setInitialDate,
    currentMonth = dayjs(new Date()).format("YYYY-MM-DD"),
  } = studyScheduleStore();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(initialDate));
  const [currentViewMonth, setCurrentViewMonth] = useState<Date>(
    new Date(initialDate)
  );

  const CheckboxGroup = Checkbox.Group;
  const options = [
    { label: "Lịch học", value: CLASS_SCHEDULE, color: "#B692F6" },
    { label: "Bài tập", value: EXERCISE, color: "#47CD89" },
    { label: "Bài kiểm tra", value: TEST, color: "#FDB022" },
    { label: "Bài thi", value: EXAM, color: "#F97066" },
    { label: "Lịch học thêm", value: EXTRA_CLASS_SCHEDULE, color: "#98A2B3" },
  ];

  const handleSelect = (value: Date) => {
    setSelectedDate(value);
    setTimeout(() => {
      setInitialDate(value.toISOString());
    }, 0);
  };

  const onChangeCheckbox: GetProp<typeof Checkbox.Group, "onChange"> = (
    checkedValues
  ) => {
    setScheduleTypes(checkedValues as any);
  };

  const handleMonthChange = (months: number) => {
    const newDate = dayjs(currentViewMonth).add(months, "month").toDate();
    setCurrentViewMonth(newDate);
  };

  useEffect(() => {
    const newDate = new Date(currentMonth);
    setSelectedDate(newDate);
    setCurrentViewMonth(newDate); // Cập nhật thêm dòng này để đổi luôn cả tháng
  }, [currentMonth]);

  useEffect(() => {
    const newDate = new Date(initialDate);
    setSelectedDate(newDate);
    setCurrentViewMonth(newDate);
  }, [initialDate]);

  return (
    <div className="study-schedule-menu">
      <div className="menu-content">
        <div className="note-item">
          <div className="schedule-type">Ghi chú</div>
          <CheckboxGroup
            style={{ display: "flex", flexDirection: "column" }}
            onChange={onChangeCheckbox}
            defaultValue={[0, 1, 2, 3, 4]}
          >
            {options.map((option) => (
              <div key={option.value} className="checkbox-item">
                <span
                  className="checkbox-content"
                  style={{ backgroundColor: option.color }}
                ></span>
                <span className="checkbox-label">{option.label}</span>
                <Checkbox value={option.value} className="checkbox-element" />
              </div>
            ))}
          </CheckboxGroup>
        </div>

        <ConfigProvider locale={locale}>
          <div className="site-calendar-card">
            <div className="custom-header-render">
              <LeftOutlined onClick={() => handleMonthChange(-1)} />
              <span style={{ margin: "0 16px" }}>
                Tháng {dayjs(currentViewMonth).format("MM/YYYY")}
              </span>
              <RightOutlined onClick={() => handleMonthChange(1)} />
            </div>
            <Calendar
              onChange={(value: any) => handleSelect(value as Date)}
              value={selectedDate}
              view="month"
              activeStartDate={currentViewMonth}
              locale="vi-VN"
              prevLabel={null}
              nextLabel={null}
              formatShortWeekday={(locale, date) =>
                ["CN", "T2", "T3", "T4", "T5", "T6", "T7"][date.getDay()]
              }
              formatMonthYear={(locale, date) =>
                `Tháng ${date.toLocaleString("vi-VN", { month: "2-digit" })}/${date.getFullYear()}`
              }
              tileClassName={({ date, view }) => {
                if (
                  view === "month" &&
                  date.toDateString() === new Date().toDateString()
                ) {
                  return "highlight";
                }
                return "";
              }}
            />
          </div>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default StudyScheduleMenu;
