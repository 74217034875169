import { Button, Pagination } from "antd";
import "../styles.scss";
import {
  ArrowDownOutlined,
  EyeOutlined,
  HeartOutlined,
  LikeOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { stripHtmlTags } from "../../../utils/format";
import { SetStateAction } from "react";

type TopicItemProps = {
  items?: any;
  index?: any[];
  handleLoadMore?: () => void;
  handleNavigate?: (item: any, uuid: any) => void;
  total: number;
  onPageChange?: React.Dispatch<React.SetStateAction<number>>;
  setPageSize?: (current: any, pageSize: SetStateAction<number>)=>void;
  page?: number;
  pageSize?: number;
};

export const TopicItem = (props: TopicItemProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uuidValue = searchParams.get("uuid");
  const convertDate = (date: Date) => {
    return moment(date).format("DD/MM/YYYY");
  };
  const placeholders =
    3 - (props?.items?.length % 3 === 0 ? 3 : props.items.length % 3);
  return (
    <>
      <div className="topic-items-container">
        {props.items.length != 0 &&
          props.items?.map((item: any, index: number) => {
            return (
              <div key={`topic${index}`} className="topic-items">
                <div className="author-style">
                  {item.authorName} • {convertDate(item.createdDate)}
                </div>
                <div
                  onClick={() => {
                    if (props && props?.handleNavigate !== undefined)
                      props.handleNavigate(item, uuidValue);
                  }}
                  className="title-style"
                >
                  {stripHtmlTags(item.name)}
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/ic_arr_up_right.svg`}
                  />
                </div>
                <div className="description-style">
                  {stripHtmlTags(item.description)}
                </div>
                <div className="flex align-center action-container">
                  <div className="action">
                    <HeartOutlined className="icon-action" />
                    <div className="total">{item.follow}</div>
                  </div>
                  <div className="diviner"></div>
                  <div className="action">
                    <LikeOutlined className="icon-action" />
                    <div className="total">{item.react}</div>
                  </div>
                  <div className="action">
                    <MessageOutlined className="icon-action" />
                    <div className="total">{item.comment}</div>
                  </div>
                  <div className="diviner"></div>
                  <div className="action">
                    <EyeOutlined className="icon-action" />
                    <div className="total">{item.view ? item.view : 0}</div>
                  </div>
                </div>
              </div>
            );
          })}
        {Array.from({ length: placeholders }).map((_, index) => (
          <div
            key={`placeholder-${index}`}
            className="topic-items placeholder"
          />
        ))}
      </div>
      {/* {props.items.length != 0 && props.items.length < props.total  && (
        <div className="view-more">
          <Button
            onClick={() => {
              if (props && props?.handleLoadMore !== undefined) {
                props?.handleLoadMore();
              }
            }}
            className="add-button"
          >
            <ArrowDownOutlined />
            Xem thêm
          </Button>
        </div>
      )} */}
      <div className="view-more">
        <Pagination
          current={props.page}
          total={props.total}
          pageSize={props.pageSize}
          defaultPageSize={6}
          onChange={props.onPageChange}
          showSizeChanger={true} // Hide page size changer
          onShowSizeChange={props.setPageSize} 
          pageSizeOptions={[3, 6, 9]} 
        />
      </div>
    </>
  );
};
