import { InfoOutlined, LoadingOutlined } from "@ant-design/icons";
import { Flex, Modal, Typography } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import * as faceTool from "mooc-ai-tool";
import Webcam from "react-webcam";
import { HelpService } from "../../../../service/helper.service";
import { modelsPath } from "../../../../constants/modelsPath";
import { ekycMatchingUserFace } from "../../../../service/ekyc";
import { useAuthStore } from "../../../../stores/stores";

const { Title } = Typography;

interface IPropsModal {
  isOpen: boolean;
  handleClose: () => void;
  handleNextStep?: () => void;
}
const videoConstraints = {
  width: 280,
  height: 280,
  facingMode: "environment",
};
const successCode = 0;

const PaymentFaceModal = ({
  isOpen,
  handleClose,
  handleNextStep,
}: IPropsModal) => {
  const helpService = useRef(new HelpService());
  const timeoutId = useRef<NodeJS.Timeout>();
  const [isLoadingModel, setIsLoadingModel] = useState(true);
  const [isChecking, setIsChecking] = useState(false);
  const userData = useAuthStore((state) => state.user);
  const repeatMessageData = useRef({
    count: 0,
    message: "",
  });
  const [identifyStatus, setIdentifyStatus] = useState<{
    messages: string[];
    status: number[];
    yaw: string | null;
    pitch: string | null;
  }>({
    messages: [],
    status: [],
    yaw: "",
    pitch: "",
  });
  const consecutiveCounterRef = useRef(0); // Ref to keep track of consecutive counts
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const onLoadedMetadata = () => {
    if (webcamRef.current && webcamRef.current.video) {
      // Execute faceTool for the first time
      faceTool.initialFace(webcamRef.current.video, modelsPath[1]).then(() => {
        setIsLoadingModel(false);
        timeoutId.current = setTimeout(() => {
          requestAnimationFrame(executeLoop);
        }, 300);
      });
    } else {
      helpService.current.errorMessage("Không tìm thấy camera");
    }
  };

  const executeLoop = async () => {
    if (webcamRef.current && webcamRef.current.video) {
      const { codes, messages, pose } = await faceTool.executeFace({
        left: 60,
        right: 50,
        up: 20,
        down: 20,
        centerX_left: 50,
        centerX_right: 40,
        centerY_up: 15,
        centerY_down: 15,
      });

      const { yaw, pitch } = pose;
      handleDisplayFaceError(codes, yaw, pitch, messages);

      if (
        codes.includes(successCode) &&
        codes.length === 1 &&
        yaw === "center" &&
        pitch === "center"
      ) {
        setIdentifyStatus((prev) => ({
          ...prev,
          messages: [...messages],
          status: [...codes],
          yaw,
          pitch,
        }));
        consecutiveCounterRef.current += 1;
      }
      if (consecutiveCounterRef.current >= 1) {
        // setResult(embeded);
        // Reset the counter and stop looping
        consecutiveCounterRef.current = 0;
      } else {
        // Continue the loop
        timeoutId.current = setTimeout(() => {
          requestAnimationFrame(executeLoop);
        }, 300);
      }
    }
  };
  function handleDisplayFaceError(
    codes: number[],
    yaw: string | null,
    pitch: string | null,
    messages: string[]
  ) {
    const { count, message } = repeatMessageData.current;
    if (message !== messages[0]) {
      repeatMessageData.current = {
        count: 1,
        message: messages[0],
      };
    } else {
      repeatMessageData.current = {
        count: count + 1,
        message: messages[0],
      };
    }
    if (repeatMessageData.current.count >= 3) {
      if (repeatMessageData.current.message) {
        setIdentifyStatus((prev) => ({
          ...prev,
          messages: [repeatMessageData.current.message],
        }));
      }
    }
  }

  const handleCancel = useCallback(() => {
    handleClose();
  }, []);

  const handleStopCaptureClick = useCallback(async () => {
    if (!webcamRef.current) {
      console.log("fail to stop");
      return;
    }
    if (!userData?.id) {
      helpService.current.errorMessage("Không tìm thấy user!!");
      return;
    }
    const base64Url = webcamRef.current.getScreenshot();
    webcamRef.current.video?.pause();
    if (!base64Url) return;
    setIsChecking(true);
    const response = await fetch(base64Url);
    const blobImage = await response.blob();
    const imageFile = new File([blobImage], "payment-verify.png", {
      type: "image/png",
    });
    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("user_id", `${userData.id}`);
    formData.append("check_liveness", `true`);
    try {
      const response = await ekycMatchingUserFace({ data: formData });
      const { data } = response;
      if (data.data) {
        handleNextStep?.();
        helpService.current.successMessage(data.response_message);
      } else {
        helpService.current.errorMessage(data.response_message);
        handleClose();
      }
    } catch (error) {
      helpService.current.errorMessage("Xác thực không thành công");
    }
    setIsChecking(false);
  }, [mediaRecorderRef.current, webcamRef, identifyStatus.status]);
  useEffect(() => {
    if (
      identifyStatus.status.length === 1 &&
      identifyStatus.status.includes(successCode) &&
      identifyStatus.yaw === "center" &&
      identifyStatus.pitch === "center"
    ) {
      handleStopCaptureClick();
    }
  }, [identifyStatus.status]);
  return (
    <Modal
      title={
        <Flex justify="space-between" align="start">
          <Title className="!text-lg !font-semibold" level={5}>
            Xác thực thanh toán
          </Title>
        </Flex>
      }
      centered
      open={isOpen}
      onCancel={handleCancel}
      footer={null}
    >
      <Flex
        gap={"16px"}
        className="border p-4 w-full font-semibold text-[#344054] rounded-xl"
      >
        <div>
          <div className="relative top-1 flex items-center justify-center pointer-events-none">
            <InfoOutlined style={{ color: "#7F56D9" }} />
            <span className=" absolute inset-0 animate-signal border border-[#7F56D9] rounded-full"></span>
          </div>
        </div>
        <p>
          {identifyStatus.status.length === 1 &&
          identifyStatus.status.includes(successCode) &&
          identifyStatus.yaw === "center" &&
          identifyStatus.pitch === "center"
            ? "Đã ghi nhận"
            : identifyStatus.messages[0]
              ? identifyStatus.messages[0]
              : "Đang xử lý !"}
        </p>
      </Flex>
      <div className="!mt-5 bg-[#00000080] p-4 rounded-md text-center">
        <Flex
          justify="center"
          className="relative"
          style={{
            width: "100%",
            height: 280,
          }}
        >
          {isLoadingModel && (
            <div className="absolute z-20 inset-0 grid place-content-center">
              <LoadingOutlined
                className=" text-3xl"
                style={{ fontSize: "32px" }}
              />
            </div>
          )}
          {!isLoadingModel && (
            <div
              className={`absolute rounded-full border-2  aspect-square h-full z-20 ${identifyStatus.status.length === 1 && identifyStatus.yaw === "center" && identifyStatus.pitch === "center" && identifyStatus.status.includes(successCode) ? "border-green-500" : "border-red-500"}`}
            />
          )}
          {isChecking && (
            <div className="absolute z-20 inset-0 grid place-content-center bg-black/50 rounded-lg">
              <span className="text-white">Đang kiểm tra...</span>
            </div>
          )}
          <Webcam
            audio={false}
            width={280}
            height={280}
            mirrored={true}
            ref={webcamRef}
            screenshotFormat="image/png"
            className="rounded-full"
            videoConstraints={videoConstraints}
            onLoadedMetadata={onLoadedMetadata}
          />
        </Flex>
      </div>
    </Modal>
  );
};

export default PaymentFaceModal;
