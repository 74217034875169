import React, { useState } from "react";
import "./style.scss";
import QuizPanel from "./QuizPanel";
import {
  ClockIcon,
  NextIcon,
  PreviousIcon,
} from "../../../../../../components/icons";
import {
  GroupsExam,
  QuizProps,
  QuizRequest,
} from "../../../../../../types/course";
import EmptyComponent from "../../../../../../components/empty";
import { Button, Pagination } from "antd";
import Countdown from "../../../../../../components/countdown/CountDown";

interface ControlPanelProps {
  correct?: number[];
  currentQuestion?: QuizProps;
  inCorrect?: number[];
  pause?: boolean;
  quizzesCompleted?: QuizRequest[];
  totalQuiz?: number;
  showTime?: boolean;
  showComplete?: boolean;
  quizzes?: QuizProps[];
  groups?: GroupsExam[];
  timeToCompleted?: number;
  quizzesDisabled?: number[];
  results?: any;
  mode?: string;
  showCountCompleted?: boolean;
  onChange?: (v: QuizProps) => void;
  onTimeEnd?: () => void;
}
const ControlPanel = ({
  currentQuestion,
  timeToCompleted = 0,
  totalQuiz = 0,
  quizzesCompleted = [],
  showTime = true,
  showComplete = true,
  pause = false,
  groups,
  quizzesDisabled = [],
  results = null,
  mode = "",
  showCountCompleted,
  onChange = () => {},
  onTimeEnd = () => {},
}: ControlPanelProps) => {
  const active = currentQuestion?.id;
  const [pageNum, setPageNum] = useState<number>(1);
  const itemsPerPage = 2;

  const handleClickQuizPanel = (value: QuizProps) => {
    onChange(value);
  };

  const getItemsForPage = () => {
    if (groups && groups?.length > 0) {
      const startIndex = (pageNum - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return groups.slice(startIndex, endIndex);
    }
    return groups;
  };

  const itemsRender = getItemsForPage() as GroupsExam[];

  return (
    <div className="control-panel">
      {showTime && (
        <div className="count-down flex gap-8">
          <ClockIcon />
          <span className="font-size-14 text-primary">
            <b className="flex gap-8">
              Thời gian:{" "}
              {
                <Countdown
                  pause={pause}
                  initialTime={timeToCompleted}
                  format="hh:mm:ss"
                  onFinish={onTimeEnd}
                />
              }
            </b>
          </span>
        </div>
      )}

      {showComplete && mode !== "result" && (
        <div className="total-finish mb-1">
          <span className="font-size-14 text-primary">
            <b>
              Đã hoàn thành: {quizzesCompleted?.length || 0}/{totalQuiz || 0}
            </b>
          </span>
        </div>
      )}

      {results ? (
        // -------------View------------------
        <div className="course-quiz-panel">
          {itemsRender.length > 0 ? (
            itemsRender.map((group: GroupsExam) => (
              <QuizPanel
                mode={mode}
                key={group?.id}
                quizzesCompleted={quizzesCompleted}
                totalQuizzes={group?.quiz?.length}
                active={active}
                title={group?.title}
                quizzes={group?.quiz}
                showCountCompleted={showCountCompleted}
                onClick={handleClickQuizPanel}
              />
            ))
          ) : (
            <EmptyComponent description="Không có kết quả" />
          )}
        </div>
      ) : (
        // -----------Train----------------
        <div className="course-quiz-panel">
          {itemsRender?.length > 0 ? (
            itemsRender.map((group: GroupsExam) => (
              <QuizPanel
                key={group?.id}
                quizzesDisabled={quizzesDisabled}
                quizzesCompleted={quizzesCompleted}
                active={active}
                title={group?.title}
                totalQuizzes={group?.quiz?.length}
                quizzes={group?.quiz}
                onClick={handleClickQuizPanel}
              />
            ))
          ) : (
            <EmptyComponent description="Không có kết quả" />
          )}
        </div>
      )}
      {groups && groups?.length > 0 && (
        <div className="control-panel-footer flex align-center justify-space-between mt-2">
          <Pagination
            pageSize={2}
            current={pageNum}
            showSizeChanger={false}
            onChange={setPageNum}
            total={groups?.length}
            className="quiz-paginate"
            prevIcon={
              <Button className="ctr-panel-btn center h-36">
                <PreviousIcon />
              </Button>
            }
            nextIcon={
              <Button className="ctr-panel-btn center h-36">
                <NextIcon />
              </Button>
            }
          />
          <div className="font-size-14 line-height-20 paginate-list-item">
            Trang {pageNum < 10 ? `0${pageNum}` : pageNum}/
            {Math.ceil((groups?.length as number) / 2) < 10
              ? `0${Math.ceil((groups?.length as number) / 2)}`
              : Math.ceil((groups?.length as number) / 2)}
          </div>
        </div>
      )}
    </div>
  );
};

export default ControlPanel;
