import { FunctionComponent, useEffect, useState } from "react";
import "./index.scss";
import { Button, Col, Layout, Row, Modal, message, Breadcrumb } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DetailContent } from "./component/detail-content/DetailContent";
import DetailSection from "./component/detail-section/DetailSection";
import { useAuthStore, useSurveyRespond } from "../../../../stores/stores";
import {
  getListAnswerStatusNotIn,
  getQuestionSurvey,
  getQuestionSurveyDraft,
  submitDraftAnswer,
  submitSurveyAnswer,
} from "../../../../service/my-survey/my-survey-service";
import { FileDoneOutlined } from "@ant-design/icons";
import SurveyDescription from "./component/survey-description/DetailSection";
import themeSurvey from "../../constants/theme-survey.const";
import CustomCard from "../../../../components/custom-card/CustomCard";

const { Content } = Layout;

const SurveyRespond: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: surveyId } = useParams();
  const { type } = useParams();
  const { idPar } = useParams();

  const { theme } = useParams();
  const themeValue: string = theme ?? "1";
  const userInfo = useAuthStore((state) => state.user);
  const userId: any = idPar ? idPar : userInfo?.id;
  const [nameSurvey, setNameSurvey] = useState<string>("");
  const [descriptionSurvey, setDescriptionSurvey] = useState<string>("");
  const setSurveyData = useSurveyRespond((state) => state.setSurveyData);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const surveyData = useSurveyRespond((state) => state.surveyData);

  useEffect(() => {
    if (isInitialRender) {
      setSurveyData([]);
      setIsInitialRender(false);
    }
    handelChangeTheme(themeValue);
  }, []);
  useEffect(() => {
    if (surveyData.length === 0) {
      if (type === "0") {
        const initializeUser = (userId: number, surveyId: number) => {
          const dataKey = `survey_${userId}_${surveyId}`;
          const storedData = localStorage.getItem(dataKey);
          if (storedData) {
            const parseData = JSON.parse(storedData);
            const { nameSurvey, surveyData, descriptionSurvey } = parseData;
            if (surveyData.length > 0) {
              setSurveyData(surveyData);
              setNameSurvey(nameSurvey);
              setDescriptionSurvey(descriptionSurvey);
              return;
            }
          }
          fetchSurveyData();
        };
        initializeUser(Number(userId), Number(surveyId));
      } else if (type === "1") {
        const fetchSurveyDataDraf = async () => {
          try {
            const res = await getQuestionSurveyDraft(userId, surveyId);
            const { nameSurvey, surveyData, descriptionSurvey } = res.data.data;
            setNameSurvey(nameSurvey);
            setSurveyData(surveyData);
            setDescriptionSurvey(descriptionSurvey);
          } catch (error) {
            console.error("Failed to fetch survey data", error);
          }
        };
        fetchSurveyDataDraf();
      } else if (type === "2") {
        const fetchSurveyDataDone = async () => {
          try {
            const res = await getQuestionSurvey(userId, surveyId);
            const { nameSurvey, surveyData, descriptionSurvey } = res.data.data;
            setNameSurvey(nameSurvey);
            setSurveyData(surveyData);
            setDescriptionSurvey(descriptionSurvey);
          } catch (error) {
            console.error("Failed to fetch survey data", error);
          }
        };
        fetchSurveyDataDone();
      }
    }
    if (
      !isInitialRender &&
      surveyData &&
      userId &&
      surveyId &&
      surveyData.length > 0 &&
      nameSurvey !== ""
    ) {
      const dataKey = `survey_${userId}_${surveyId}`;
      localStorage.setItem(
        dataKey,
        JSON.stringify({
          nameSurvey,
          surveyData,
          descriptionSurvey,
        })
      );
    }
  }, [surveyData, userId, surveyId, isInitialRender]);

  const fetchSurveyData = async () => {
    try {
      const res = await getListAnswerStatusNotIn(userId, surveyId);
      const { nameSurvey, surveyData, descriptionSurvey } = res.data.data;
      setNameSurvey(nameSurvey);
      setSurveyData(surveyData);
      setDescriptionSurvey(descriptionSurvey);
      const dataToStore = {
        descriptionSurvey,
        nameSurvey,
        surveyData,
      };
      const dataKey = `survey_${userId}_${surveyId}`;
      localStorage.setItem(dataKey, JSON.stringify(dataToStore));
    } catch (error) {
      console.error("Failed to fetch survey data", error);
    }
  };

  const handleSubmitDraftAnswer = async () => {
    try {
      Modal.confirm({
        title: "Xác nhận trả lời bản nháp",
        icon: <FileDoneOutlined style={{ color: "#1890FF" }} />,
        content: (
          <div>
            Bản nháp sẽ được tạo và lưu vào mục đã tham gia, bạn hãy quay trở
            lại làm khảo sát trong thời gian sớm nhất.
          </div>
        ),
        onOk: async () => {
          try {
            await submitDraftAnswer(userId, surveyId, surveyData);
            message.success("Lưu bản nháp thành công");
            navigate("/my-surveys");
          } catch (error) {
            message.error("Lưu bản nháp thất bại");
          }
        },
        okText: "Xác nhận",
        cancelText: "Huỷ",
        centered: true,
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        ),
      });
    } catch (error) {
      message.error("Lưu bản nháp thất bại");
    }
  };

  const handleSubmitSurveyAnswer = async () => {
    try {
      Modal.confirm({
        title: "Xác nhận trả lời đợt khảo sát",
        icon: <FileDoneOutlined style={{ color: "#1890FF" }} />,
        content: (
          <div>
            Đảm bảo rằng{" "}
            <span style={{ color: "orange" }}>
              tất cả các thông tin liên quan đã được kiểm tra và cập nhật đầy đủ
            </span>{" "}
            trước khi Xác nhận gửi kết quả khảo sát.
          </div>
        ),
        onOk: async () => {
          try {
            for (let section of surveyData) {
              for (let question of section.moocSurveyQuestion ?? []) {
                if (
                  question.isRequired &&
                  (question.questionAnswer === null ||
                    question.questionAnswer === undefined ||
                    question.questionAnswer === "")
                ) {
                  message.error(
                    `"${question.questionName}" cần được trả lời trước khi gửi đi.`
                  );
                  return;
                }
              }
            }
            await submitSurveyAnswer(userId, surveyId, surveyData);
            message.success("Gửi kết quả khảo sát thành công");
            navigate("/my-surveys");
          } catch (error) {
            message.error("Xác nhận gửi kết quả khảo sát thất bại");
          }
        },
        okText: "Xác nhận",
        cancelText: "Huỷ",
        centered: true,
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        ),
      });
    } catch (error) {
      message.error("Gửi khảo sát thất bại");
    }
  };

  // theme
  const handelChangeTheme = (value: string) => {
    let themeData = themeSurvey.filter((item) => {
      return item.value == themeValue.toString();
    })[0];
    let elementParent = document.getElementById("themeElement");
    while (elementParent?.className != "wrapper" && elementParent) {
      elementParent = elementParent?.parentElement ?? null;
    }
    elementParent != null
      ? (elementParent.style.backgroundColor =
          themeData.WapperBackgroundColor.toString())
      : null;
    const element: HTMLCollectionOf<Element> =
      document.getElementsByClassName("ant-card-head");
    for (let i = 0; i < element.length; i++) {
      element[i].className = `ant-card-head ${themeData.themeName}`;
    }
    const parentContainer = document.getElementsByClassName(
      "my-container-survey"
    ) as HTMLCollectionOf<HTMLElement>;
    parentContainer[0].style.backgroundColor =
      themeData.WapperBackgroundColor.toString();
  };

  return (
    <div className="my-container-survey">
      <div className="my-detail-survey">
        <Content>
          <Breadcrumb
            items={[
              {
                href: "/",
                title: (
                  <img
                    key="home-icon"
                    src={`${process.env.PUBLIC_URL}/assets/icons/home-line.svg`}
                  />
                ),
              },
              {
                href: "/my-surveys",
                title: "Khảo sát nhu cầu đào tạo",
                key: "my-surveys",
              },
            ]}
          />

          <div className="mt-2 flex justify-between">
            <div className="text-title-30">{nameSurvey}</div>
          </div>
          <DetailContent>
            <div id="themeElement">{}</div>
            {surveyData.length > 0 ? (
              <>
                <SurveyDescription
                  surveyName={nameSurvey}
                  surveyDescription={descriptionSurvey}
                />
                <DetailSection
                  dataSection={surveyData}
                  typeSurvey={type}
                  theme={theme}
                />
              </>
            ) : (
              <CustomCard
                title="Câu hỏi khảo sát"
                className="wrapper-settings--card form-setting--common card-setting"
              >
                <span>Khảo sát này chưa có câu hỏi nào</span>
              </CustomCard>
            )}
            {type !== "2" && surveyData.length > 0 && (
              <Row
                justify="start"
                align="middle"
                style={{ marginTop: 20, width: "100%" }}
                gutter={8}
              >
                <Col span={2}>
                  <Button
                    onClick={() => navigate("/my-surveys")}
                    disabled={location.state.mode === "view"}
                  >
                    Hủy
                  </Button>
                </Col>
                <Col span={3}>
                  <Button
                    type="primary"
                    onClick={() => handleSubmitDraftAnswer()}
                    disabled={location.state.mode === "view"}
                  >
                    Lưu nháp
                  </Button>
                </Col>
                <Col span={3}>
                  <Button
                    type="primary"
                    onClick={() => handleSubmitSurveyAnswer()}
                    disabled={location.state.mode === "view"}
                  >
                    Gửi trả lời
                  </Button>
                </Col>
              </Row>
            )}
          </DetailContent>
        </Content>
      </div>
    </div>
  );
};

export default SurveyRespond;
