import {FunctionComponent, ReactNode} from 'react';
import {Card} from "antd";
import "./custom-card.scss"


type CustomCardProps = {
    title?: string | ReactNode,
    children?: ReactNode,
    className?: string
}

const CustomCard: FunctionComponent<CustomCardProps> = ({title, children, className}) => {
    return (
        <>
            <Card title={title} className={`wrapper-card ${className}`}>
                {children}
            </Card>
        </>
    );
};

export default CustomCard;
