import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { isEmpty } from "lodash";
import { renderTemplate } from "../../utils/renderTemplate";

const { Content } = Layout;

interface PageBuilderViewProps {
  templateData: string;
}

const PageBuilderView: React.FC<PageBuilderViewProps> = ({ templateData }) => {
  const [html, setHTML] = useState<string>("");

  useEffect(() => {
    if (templateData) {
      setHTML(templateData);
    }
  }, [templateData]);

  return (
    <Content className="page-builder-view">
      {!isEmpty(html) ? renderTemplate(html) : null}
    </Content>
  );
};

export default PageBuilderView;
