// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-legend-custom {
  padding: 9px;
}
@media screen and (max-width: 576px) {
  .chart-legend-custom .legend-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/charts/components/Legend.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE;IACE,sBAAA;IACA,uBAAA;EACJ;AACF","sourcesContent":[".chart-legend-custom {\n  padding: 9px;\n\n  @media screen and (max-width: 576px) {\n    .legend-wrapper {\n      flex-direction: column;\n      align-items: flex-start;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
