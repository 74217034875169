import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../../config/api/configApi";
import { axiosConfigV2 } from "../../../../config/api/configApiv2";

export const postLogin: (body: any) => Promise<AxiosResponse<any>> = (
  body: any
) => {
  return axiosConfig.post(`/v2/auth/login`, body);
};
export const postLoginV2: (body: {
  username: string;
  password: string;
  captchaResponse: string;
  email?: boolean;
}) => Promise<AxiosResponse<any>> = (body) => {
  return axiosConfigV2.post(`/auth/login`, body);
};

export const getMailOtp: (param: string) => Promise<AxiosResponse<any>> = (
  param: string
) => {
  return axiosConfig.post(`/accounts/get-otp-forgot-password`, {
    email: param,
  });
};

export const putChangePassword: (body: any) => Promise<AxiosResponse<any>> = (
  body
) => {
  return axiosConfig.put(`/accounts/forgot-change-password`, body);
};
export const getRefreshToken: (payload: { refreshToken: string }) => Promise<
  AxiosResponse<{
    statusCode: number;
    message: string;
    data: {
      accessToken: string;
      expiresIn: string;
      edxToken: string;
      refreshToken: string;
      edxRefreshToken: string;
    };
  }>
> = (payload: { refreshToken: string }) => {
  const { refreshToken } = payload;
  return axiosConfigV2.post(`/auth/refresh-login`, {
    refreshToken: refreshToken,
  });
};
