import { BarChartOutlined, PlusOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Breadcrumb,
  Button,
  Col,
  ConfigProvider,
  Flex,
  Form,
  Input,
  Select,
  Tabs,
} from "antd";
import { TabsProps } from "antd/lib";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MyCourseCard from "../../components/card/my-course-card/MyCourseCard";
import CustomRangePicker from "../../components/custom-range-picker/CustomRangePicker";
import ResponsiveList from "../../components/list/ResponsiveList";
import CustomPagination from "../../components/pagination/Pagination";
import RelevantSimilarCourses from "../../components/relevant-similar-courses/RelevantSimilarCourses";
import { routesConfig } from "../../config/routes";
import {
  DEFAULT_PAGE_NUM,
  DEFAULT_PAGE_SIZE,
  MY_COURSE_SORT,
  REGISTRATION_METHOD_FILTER,
} from "../../constants";
import {
  getIndustryInMyCourse,
  getMyCourseList,
  getTotalMyCourseList,
  getUniversityInMyCourse,
} from "../../service/my-course/my-course-service";
import { globalStore } from "../../stores/stores";
import { filterOption } from "../../utils/element";
import { viLocale } from "../../utils/picker";
import "./style.scss";

interface FormValues {
  industryName?: string;
  uuid?: string;
  registrationForm?: number;
  registeredDate?: [dayjs.Dayjs, dayjs.Dayjs];
  keyword?: string;
  sortByName?: number;
}

const MyCourse: React.FC = () => {
  const { setFooterDefault, reload } = globalStore();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isToggle, setIsToggle] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState({
    totalAll: 0,
    notStarted: 0,
    learning: 0,
    complete: 0,
  });
  const [myCoursesList, setMyCourseList] = useState<any>([]);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [pageNum, setPageNum] = useState<number>(DEFAULT_PAGE_NUM);
  const [tab, setTab] = useState<number>(0);
  const [universities, setUniversities] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [keywordOptions, setKeywordOptions] = useState<any>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [initialRender, setInitialRender] = useState(true);

  const fetchMyCourseList = async (values: FormValues = {}) => {
    setIsLoading(true);
    try {
      const response = await getMyCourseList({
        page: pageNum,
        size: pageSize,
        learningStatus: tab,
        keyword: values?.keyword?.trim(),
        registeredFrom: values?.registeredDate?.[0]
          ? dayjs(values?.registeredDate[0]).startOf("day").toISOString()
          : undefined,
        registeredTo: values?.registeredDate?.[1]
          ? dayjs(values?.registeredDate[1]).endOf("day").toISOString()
          : undefined,
        industryName: values?.industryName,
        uuid: values?.uuid,
        registrationForm: values?.registrationForm,
        sortByName:
          values?.sortByName === 1
            ? "asc"
            : values?.sortByName === 2
              ? "desc"
              : undefined,
        sortByRegisteredDate:
          values?.sortByName === 3
            ? "desc"
            : values?.sortByName === 4
              ? "asc"
              : undefined,
      });
      const { data } = response.data;
      const myCourseList = data.courses.map((item: any) => ({
        content: <MyCourseCard key={item.id} {...item} />,
      }));
      setMyCourseList(myCourseList);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUniversities = async () => {
    try {
      const res = await getUniversityInMyCourse();
      setUniversities(
        res.data?.data.map((item: any) => ({
          label: item.name,
          value: item.uuid,
        }))
      );
    } catch (err) {
      console.error(err);
    }
  };

  const fetchIndustryInMyCourse = async () => {
    const params = {
      keyWord: form.getFieldValue("industryName"),
    };
    try {
      const res = await getIndustryInMyCourse(params);
      const industriesData = res.data?.map((item: any) => ({
        label: item.name,
        value: item.name,
      }));
      setIndustries(industriesData);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchTotalMyCourseList = useCallback(
    async (values: FormValues = {}) => {
      try {
        const response = await getTotalMyCourseList({
          registeredFrom: values?.registeredDate?.[0]
            ? dayjs(values?.registeredDate[0]).startOf("day").toISOString()
            : undefined,
          registeredTo: values?.registeredDate?.[1]
            ? dayjs(values?.registeredDate[1]).endOf("day").toISOString()
            : undefined,
          keyword: values?.keyword?.trim(),
          industryName: values?.industryName,
          uuid: values?.uuid,
          registrationForm: values?.registrationForm,
          sortByName:
            values?.sortByName === 1
              ? "asc"
              : values?.sortByName === 2
                ? "desc"
                : undefined,
          sortByPublicDate:
            values?.sortByName === 3
              ? "desc"
              : values?.sortByName === 4
                ? "asc"
                : undefined,
        });
        const { data } = response.data;
        setTotalRecords({
          totalAll: data?.totalNumber || 0,
          notStarted: data?.notStartedNumber || 0,
          learning: data?.learningNumber || 0,
          complete: data?.completedNumber || 0,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    []
  );

  const RenderAdvanceSearch = () => (
    <div className="advanced-filtering-field">
      <Col span={5} className="item">
        <span className="title">Chuyên ngành</span>
        <Form.Item name="industryName">
          <Select
            showSearch
            className="btn-filter"
            style={{ width: "100%", height: 44 }}
            options={industries}
            filterOption={filterOption}
            allowClear
            placeholder="Chọn chuyên ngành"
            notFoundContent={<span>Không có kết quả</span>}
          />
        </Form.Item>
      </Col>
      <Col span={5} className="item">
        <span className="title">Trường đại học</span>
        <Form.Item name="uuid">
          <Select
            showSearch
            className="btn-filter"
            style={{ width: "100%", height: 44 }}
            options={universities}
            filterOption={filterOption}
            allowClear
            placeholder="Chọn trường đại học"
            notFoundContent={<span>Không có kết quả</span>}
          />
        </Form.Item>
      </Col>
      <Col span={5} className="item">
        <span className="title">Hình thức ghi danh</span>
        <Form.Item name="registrationForm">
          <Select
            className="btn-filter"
            style={{ width: "100%", height: 44 }}
            placeholder="Chọn hình thức"
            allowClear
            options={REGISTRATION_METHOD_FILTER}
          />
        </Form.Item>
      </Col>
      <Col span={5} className="item">
        <span className="title">Ngày ghi danh</span>
        <Form.Item name="registeredDate">
          <ConfigProvider locale={viLocale}>
            <CustomRangePicker
              value={form.getFieldValue("registeredDate") || null}
              onChangeRangePicker={(dates) => {
                form.setFieldsValue({ registeredDate: dates });
                fetchMyCourseList(form.getFieldsValue());
                fetchTotalMyCourseList(form.getFieldsValue());
              }}
            />
          </ConfigProvider>
        </Form.Item>
      </Col>
      <Col span={4} className="item">
        <div className="title">&nbsp;</div>
        <div
          className="text-clear-filters"
          onClick={() => {
            form.resetFields();
            setPageNum(1);
            fetchMyCourseList(form.getFieldsValue());
            fetchTotalMyCourseList(form.getFieldsValue());
            setKeyword("");
            setSearchKeyword("");
          }}
        >
          Xóa bộ lọc
        </div>
      </Col>
    </div>
  );

  const tabItems: TabsProps["items"] = [
    {
      key: "0",
      label: (
        <div className="flex align-center">
          Tất cả khóa học
          <div className="total">{totalRecords?.totalAll}</div>
        </div>
      ),
      children: (
        <ResponsiveList
          grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 3 }}
          data={myCoursesList}
          loading={isLoading}
        />
      ),
    },
    {
      key: "1",
      label: (
        <div className="flex align-center">
          Chưa bắt đầu
          <div className="total">{totalRecords?.notStarted}</div>
        </div>
      ),
      children: (
        <ResponsiveList
          grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 3 }}
          data={myCoursesList}
          loading={isLoading}
        />
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex align-center">
          Đang học
          <div className="total">{totalRecords?.learning}</div>
        </div>
      ),
      children: (
        <ResponsiveList
          grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 3 }}
          data={myCoursesList}
          loading={isLoading}
        />
      ),
    },
    {
      key: "3",
      label: (
        <div className="flex align-center">
          Hoàn thành
          <div className="total">{totalRecords?.complete}</div>
        </div>
      ),
      children: (
        <ResponsiveList
          grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 3 }}
          data={myCoursesList}
          loading={isLoading}
        />
      ),
    },
  ];

  const onChangeTab = (key: string) => {
    setTab(Number(key));
    setPageNum(DEFAULT_PAGE_NUM);
    // fetchMyCourseList({ ...form.getFieldsValue(), keyword: searchKeyword });
    // fetchTotalMyCourseList({
    //   ...form.getFieldsValue(),
    //   keyword: searchKeyword,
    // });
  };

  const onValuesChange = (changedValues: any, allValues: FormValues) => {
    setPageNum(1);
    fetchMyCourseList({ ...allValues, keyword: searchKeyword });
    fetchTotalMyCourseList({ ...allValues, keyword: searchKeyword });
  };

  const handleSearch = async (value: string) => {
    setKeyword(value);
    if (value) {
      const payload = {
        keyword: value,
      };
      try {
        const response = await getMyCourseList(payload);
        const suggestions = response.data?.data.courses.map((item: any) => ({
          value: item?.name,
        }));

        setKeywordOptions(suggestions);
      } catch (error) {
        console.error("Error fetching keyword suggestions:", error);
        setKeywordOptions([]); // Clear the options in case of an error
      }
    } else {
      setKeywordOptions([]);
    }
  };

  const handleSelect = (value: string) => {
    setKeyword(value);
    form.setFieldsValue({ keyword: value });
    setSearchKeyword(value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const inputValue = event.currentTarget.value;
      setKeyword(inputValue);
      form.setFieldsValue({ keyword: inputValue });

      setPageNum(1);
      setSearchKeyword(inputValue); // Cập nhật searchKeyword khi nhấn Enter
    }
  };

  const handleSearchClick = () => {
    setPageNum(1);
    setSearchKeyword(keyword);
  };
  useEffect(() => {
    if (!initialRender) {
      fetchMyCourseList({ ...form.getFieldsValue(), keyword: searchKeyword });
      fetchTotalMyCourseList({
        ...form.getFieldsValue(),
        keyword: searchKeyword,
      });
    }
  }, [pageSize, pageNum, tab, searchKeyword, reload]);

  useEffect(() => {
    form.setFieldsValue({ sortByName: 3 });
    fetchTotalMyCourseList(form.getFieldsValue());
    fetchMyCourseList(form.getFieldsValue());
    setInitialRender(false);
  }, []);

  useEffect(() => {
    fetchUniversities();
    fetchIndustryInMyCourse();
    setFooterDefault();
  }, []);

  return (
    <div className="my-courses">
      <div className="my-course-list">
        <Breadcrumb
          items={[
            {
              href: "",
              title: (
                <img
                  key="home-icon"
                  src={`${process.env.PUBLIC_URL}/assets/icons/home-line.svg`}
                />
              ),
            },
            { title: "Khoá học của tôi", key: "my-courses" },
          ]}
        />

        <div className="mt-2 flex justify-between">
          <div className="text-title-30">Thông tin khoá học</div>
          <Flex gap={16}>
            <Button icon={<BarChartOutlined />} onClick={() => navigate(routesConfig.statisticsReport)}>Thống kê</Button>
            <Button
              className="btn-primary"
              onClick={() => navigate(routesConfig.courseInfo)}
              icon={<PlusOutlined />}
            >
              Thêm khoá học
            </Button>
          </Flex>
        </div>
        <div className="text-secondary font-size-16 mt-1">
          Tổng hợp các khoá học của bạn
        </div>
        <div className="hr-tag"></div>
        <div className="my-course-search">
          <Form form={form} onValuesChange={onValuesChange}>
            <div className="form-search">
              <div className="search-item">
                <AutoComplete
                  options={keyword ? keywordOptions : []}
                  onSearch={handleSearch}
                  onSelect={handleSelect}
                  value={keyword}
                  style={{ width: "100%" }}
                  notFoundContent={
                    keyword && keywordOptions.length === 0 ? (
                      <span>Không có kết quả</span>
                    ) : undefined
                  }
                  className="autocomplete-search"
                >
                  <Input
                    placeholder="Nhập từ khoá cần tìm"
                    prefix={
                      <img
                        src="/assets/icons/search-lg.svg"
                        alt="search"
                        className="search-icon"
                      />
                    }
                    allowClear
                    className="autocomplete-search"
                    onKeyDown={handleKeyPress}
                  />
                </AutoComplete>
                <Button onClick={handleSearchClick}>Tìm kiếm</Button>
              </div>

              <div className="flex gap-8">
                <Form.Item name="sortByName">
                  <Select
                    className="btn-filter"
                    defaultValue={3}
                    style={{ width: 145, height: 44 }}
                    options={MY_COURSE_SORT}
                  />
                </Form.Item>
                <Button
                  className="advanced-filtering"
                  onClick={() => setIsToggle(!isToggle)}
                >
                  <img
                    src="/assets/icons/filter-lines.svg"
                    alt="search"
                    className="search-icon"
                  />
                  Bộ lọc nâng cao
                </Button>
              </div>
            </div>
            {isToggle && <RenderAdvanceSearch />}
          </Form>
        </div>
        <Tabs defaultActiveKey="0" items={tabItems} onChange={onChangeTab} />
        {myCoursesList?.length > 0 ? (
          <CustomPagination
            totalPage={
              tab === 0
                ? totalRecords?.totalAll
                : tab === 1
                  ? totalRecords?.notStarted
                  : tab === 2
                    ? totalRecords?.learning
                    : tab === 3
                      ? totalRecords?.complete
                      : 0
            }
            pageSize={pageSize}
            pageNumber={pageNum}
            setPageSize={setPageSize}
            setPageNumber={setPageNum}
          />
        ) : null}
      </div>
      <RelevantSimilarCourses />
    </div>
  );
};

export default MyCourse;
