import React, { ReactNode, useState } from "react";
import { App, Form, Input, Modal } from "antd";
import DOMPurify from "dompurify";
import { reportExam } from "../../../../../../service/learning-course";
import useKeyword from "../../../../../../hooks/useKeyword";
import { toNumber } from "lodash";
import { notifySuccess } from "../../../../../../utils/notification";

interface ReportModalProps {
  children?: ReactNode;
  sessionId: string;
}

function ReportModal({ children, sessionId }: ReportModalProps) {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const blockId = useKeyword("blockId");

  const showModal = () => {
    setOpen(true);
  };

  const handleSendReport = async (fieldsValue: any) => {
    if (fieldsValue) {
      try {
        setLoading(true);
        await reportExam({
          content: DOMPurify.sanitize(fieldsValue?.content),
          blockId: toNumber(blockId),
          sessionId: sessionId,
        });
        notifySuccess("Gửi báo cáo thành công");
        setOpen(false);
        form.resetFields();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <App>
      <div onClick={showModal}>{children}</div>
      <Modal
        title={
          <span className="font-size-18 line-height-32 font-weight-6">
            Báo cáo
          </span>
        }
        open={open}
        confirmLoading={loading}
        maskClosable={false}
        centered
        className="report-modal"
        onOk={form.submit}
        onCancel={() => {
          setOpen(false);
          form.resetFields();
        }}
        okText={"Gửi báo cáo"}
        cancelText="Hủy"
      >
        <p className="font-size-14 mb-3">
          Bạn muốn gửi feedback về bài tập/bài thi/bài kiểm tra? Hay có thắc mắc
          về kết quả bài làm?{" "}
        </p>
        <Form form={form} onFinish={handleSendReport} className="mb-3">
          <Form.Item
            name="content"
            rules={[
              { required: true, message: "Vui lòng nhập nội dung báo" },
              {
                validator: (_, value) => {
                  if (value && value.trim().length === 0) {
                    return Promise.reject(
                      new Error("Vui lòng nhập nội dung báo")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.TextArea placeholder="Mô tả vấn đề tại đây" />
          </Form.Item>
        </Form>
      </Modal>
    </App>
  );
}

export default ReportModal;
