import React, { useState } from "react";
import "./style.scss";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import useKeyword from "../../../hooks/useKeyword";

interface BannerSearchProps {
  keyword?: string;
  totalRecords?: number;
  topicName?: string;
}
function BannerSearch({ totalRecords = 0, topicName = "" }: BannerSearchProps) {
  const navigate = useNavigate();
  const [keySearch, setKeySearch] = useState("");

  const keyWord = useKeyword("keyword");

  const handleClickSearch = () => {
    if (keySearch) {
      navigate(`${routesConfig.searchCourseInfo}?keyword=${keySearch?.trim()}`);
    } else {
      navigate(`${routesConfig.searchCourseInfo}`);
    }
  };

  const onKeyBoardEnter = (e: any) => {
    if (e.key === "Enter") {
      navigate(
        `${routesConfig.searchCourseInfo}?keyword=${e.target.value.trim()}`
      );
    }
  };

  return (
    <div className="banner-search">
      <div className="content-wrap flex align-center justify-space-between">
        <div className="preview-result">
          {keyWord ? (
            <p className="font-size-30 font-weight-6">
              {totalRecords} kết quả cho “{keyWord || ""}”
            </p>
          ) : (
            <p className="font-size-30 font-weight-6">
              {totalRecords} kết quả cho “{topicName || ""}”
            </p>
          )}

          <p className="font-size-16 text-secondary mt-2">
            Các khoá học hệ thống lọc được có từ khoá bạn tìm kiếm
          </p>
        </div>
        <div className="search-box flex gap-12">
          <Input
            className="search-i"
            prefix={
              <SearchOutlined style={{ color: "#667085", fontSize: 20 }} />
            }
            placeholder="Nhập từ khoá cần tìm"
            onChange={(e) => setKeySearch(e.target.value)}
            onKeyDown={onKeyBoardEnter}
          />
          <Button onClick={handleClickSearch} className="btn-primary search-i">
            Tìm kiếm
          </Button>
        </div>
      </div>
    </div>
  );
}

export default BannerSearch;
