import React from "react";
import "./CourseProges.scss";
import { formatCurrencyToVND } from "../../utils/format";
import { Progress } from "antd";
import { useCourseDetailStore, useCurriculumStore } from "../../stores/stores";
type Props = {};

const CourseProges = (props: Props) => {
  const imgPath = "/assets/icons/";
  const { screen } = useCourseDetailStore();

  const { detail } =
    screen === "course" ? useCourseDetailStore() : useCurriculumStore();
  return (
    <div>
      <div className="course-progress">
        {detail?.isRegistered ? (
          <Progress percent={detail?.completedPercentage} />
        ) : (
          <>
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={imgPath + "wallet.svg"}
            />
            <p className="price">
              {detail?.cost && detail?.cost > 0
                ? formatCurrencyToVND(detail?.cost)
                : "Miễn phí"}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default CourseProges;
