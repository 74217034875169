import { Form, Input } from "antd";
import React from "react";
import { QuestionProps } from "../../../../../../../types/course";

function LongText({}: QuestionProps) {
  return (
    <Form.Item
      name="shortText"
      initialValue={"font-size-16 line-height-24 font-weight-5 text-result"}
    >
      <Input.TextArea
        rows={3}
        disabled
        placeholder="Nhập câu trả lời của bạn"
      />
    </Form.Item>
  );
}

export default LongText;
