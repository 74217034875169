import React from "react";
interface SvgProps {
  fill?: string;
  strokeWidth?: number;
  stroke?: string;
}

// Hình tròn với đường bao phía trên
const Circle = ({
  fill = "#7A5AF8",
  strokeWidth = 1,
  stroke = "#fff",
}: SvgProps) => {
  return (
    <svg width="20" height="20">
      <circle
        cx="10"
        cy="10"
        r="5"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={fill}
      />
    </svg>
  );
};

// Hình kim cương
const Diamond = ({
  fill = "#7A5AF8",
  strokeWidth = 1,
  stroke = "#fff",
}: SvgProps) => {
  return (
    <svg width="18" height="12">
      <polygon
        points="10,0 16,6 10,12 4,6"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={fill}
      />
    </svg>
  );
};

// Hình vuông
const Square = ({
  fill = "#7A5AF8",
  strokeWidth = 1,
  stroke = "#fff",
}: SvgProps) => {
  return (
    <svg width="20" height="20">
      <rect
        x="5"
        y="5"
        width="10"
        height="10"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={fill}
      />
    </svg>
  );
};

// Hình tam giác
const Triangle = ({
  fill = "#7A5AF8",
  strokeWidth = 1,
  stroke = "#fff",
}: SvgProps) => {
  return (
    <svg width="20" height="20">
      <polygon
        points="10,5 15,15 5,15"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={fill}
      />
    </svg>
  );
};

export { Circle, Diamond, Square, Triangle };
