import { ApartmentOutlined, TeamOutlined } from "@ant-design/icons";
import { Key, ReactNode } from "react";
import { MenuItem } from "../../types/sidebar";
import { routesConfig } from "../../config/routes";
import { Link } from "react-router-dom";

function getItem(label: ReactNode, key: Key, icon?: ReactNode, children?: MenuItem[], type?: string): MenuItem {
  return {
    key, label, icon, children, type
  } as MenuItem
}

export const menuItems: MenuItem[] = [
  // getItem('Quản lý người dùng', 'management-user', <TeamOutlined />, [getItem(<Link to={routesConfig.listUsers}>
  //   Quản trị cơ sở
  // </Link>, routesConfig.listUsers),
  // getItem(<Link to={routesConfig.listTeachers}>
  //   Giảng viên
  // </Link>, routesConfig.listTeachers),
  // getItem(<Link to={routesConfig.listMinistryLevels}>
  //   Cấp bộ
  // </Link>, routesConfig.listMinistryLevels),
  // ]),
  // getItem(<Link to={routesConfig.roleManagement}>Quản lý vai trò</Link>, routesConfig.roleManagement,
  //   <ApartmentOutlined />)
]
