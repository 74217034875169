import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Form, Input } from "antd";
import {
  CheckPrimary,
  CheckSecondary,
  PreviousIcon,
} from "../../components/icons";
import {
  checkOtpForgotPassword,
  getMailOtp,
  putChangePassword,
} from "../../service/login";
import { isNumber, toNumber } from "lodash";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import { getCleanText } from "../../utils/format";
import Countdown from "../../components/countdown/CountDown";
import {
  DEFAULT_CHANGE_PASSWORD_TIMEOUT_TO_LOGIN,
  DEFAULT_TIME_GET_OTP,
  MAX_LENGTH_PASSWORD,
  MIN_LENGTH_PASSWORD,
} from "../../constants";
import { notifyError } from "../../utils/notification";

function ForgotPassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [mode, setMode] = useState<"email" | "otp" | "password" | "success">(
    "email"
  );

  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [timeCountDown, setTimeCountdown] = useState<number>(0);
  const [otp, setOtp] = useState<number | string>("");
  const [countVerifyFail, setCountVerifyFail] = useState<number>(0);

  //validate password
  const [isPassedOptionLength, setIsPassedOptionLength] =
    useState<boolean>(false);
  const [isPassedOptionType, setIsPassedOptionType] = useState<boolean>(false);

  useEffect(() => {
    if (countVerifyFail >= 5) {
      setTimeout(() => navigate(routesConfig.login), 3000);
    }
  }, [countVerifyFail]);

  const handleGetOtp = async (value: any) => {
    const eml = getCleanText(value?.email);
    setEmail(eml);
    try {
      setLoading(true);
      const res = await getMailOtp(eml);
      if (res.data?.statusCode === 200) {
        setMode("otp");
        setTimeCountdown(DEFAULT_TIME_GET_OTP);
      }
    } catch (err: any) {
      form.setFields([
        {
          name: "email",
          errors: [
            err?.response?.data?.message ||
              "Có lỗi xảy ra, vui lòng thử lại sau",
          ],
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event: any) => {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode < 48 || charCode > 57 || event.target.value.length >= 1) {
      event.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    const codes = pastedText.split("").map((code) => toNumber(code));

    if (codes?.length === 6) {
      codes.forEach((code: number, index: number) => {
        if (isNumber(code)) form.setFieldValue(`otp${index}`, toNumber(code));
      });
    }
  };

  const handleVerifyOtp = async (value: any) => {
    const codes = Object.values(value);
    const code = codes.join("");
    if (code && email) {
      try {
        setLoading(true);
        const res = await checkOtpForgotPassword(email, code);
        if (res.data?.statusCode === 200) {
          setMode("password");
          setOtp(code);
        }
      } catch (err: any) {
        setCountVerifyFail((prevState: number) => prevState + 1);
        setErrorMessage(
          err?.response?.data?.message || "Có lỗi xảy ra, vui lòng thử lại sau"
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = getCleanText(e.target.value);
    if (
      password?.length >= MIN_LENGTH_PASSWORD &&
      password?.length < MAX_LENGTH_PASSWORD
    ) {
      setIsPassedOptionLength(true);
    } else setIsPassedOptionLength(false);

    const passwordRegex = /^(?=.*[^\w\s]).+$/;

    if (passwordRegex.test(password)) {
      setIsPassedOptionType(true);
    } else setIsPassedOptionType(false);
  };

  const handleChangePassword = async (values: any) => {
    const password = getCleanText(values?.password);
    const confirmPassword = getCleanText(values?.confirmPassword);
    try {
      setLoading(true);
      await putChangePassword({
        otp: otp,
        email: email,
        newPassword: password,
        reWriteNewPassword: confirmPassword,
      });
      setMode("success");
      setTimeout(
        () => navigate(routesConfig.login),
        DEFAULT_CHANGE_PASSWORD_TIMEOUT_TO_LOGIN
      );
    } catch (err: any) {
      notifyError(
        err?.response?.data?.message || "Có lỗi xảy ra, vui lòng thử lại sau"
      );
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    switch (mode) {
      case "email":
        return (
          <div className="forgot-password">
            <img
              className="forgot-pass-bg"
              src={`${process.env.PUBLIC_URL}/assets/img/bg-forgot-password.png`}
            />

            <img
              className="forgot-pass-icon"
              src={`${process.env.PUBLIC_URL}/assets/icons/key-icon.svg`}
            />

            <div className="forgot-pass-form">
              <h4 className="font-size-30 font-weight-6 line-height-38 text-center">
                Quên mật khẩu?
              </h4>
              <span className="font-size-16 line-height-24 text-secondary">
                Nhập thông tin Email để thiết lập mật khẩu mới
              </span>
              <label htmlFor="" className="mt-3">
                Email <span className="text-danger">*</span>
              </label>
              <Form onFinish={handleGetOtp} form={form}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Vui lòng nhập đúng E-mail!",
                    },
                    {
                      required: true,
                      message: "Vui lòng nhập E-mail!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Nhập địa chỉ Email"
                    className="w-full forgot-input"
                  />
                </Form.Item>

                <Button
                  loading={loading}
                  htmlType="submit"
                  className="btn-primary mt-3 w-full forgot-input"
                >
                  Đặt lại mật khẩu
                </Button>
              </Form>

              <div
                className="back-login center mt-4 gap-8 pointer"
                onClick={() => navigate(routesConfig.login)}
              >
                <PreviousIcon />
                <span className="font-size-14 text-secondary">
                  <b>Quay lại trang đăng nhập</b>
                </span>
              </div>
            </div>
          </div>
        );
      case "otp":
        return (
          <div className="forgot-password">
            <img
              className="forgot-pass-bg"
              src={`${process.env.PUBLIC_URL}/assets/img/bg-forgot-password.png`}
            />

            <img
              className="forgot-pass-icon"
              src={`${process.env.PUBLIC_URL}/assets/icons/email-forgot-icon.svg`}
            />

            <div className="forgot-pass-form">
              <h4 className="font-size-30 font-weight-6 line-height-38 text-center">
                Kiểm tra Email của bạn!
              </h4>
              <span className="font-size-16 line-height-24 text-secondary text-center mt-2">
                Mã bảo mật đã được gửi qua e-mail ({email})
              </span>

              <Form form={form} onFinish={handleVerifyOtp} className="mt-4">
                <div className="otp-form">
                  {Array(6)
                    .fill(1)
                    .map((_, index) => (
                      <Form.Item
                        key={index}
                        name={`otp${index}`}
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Input
                          onPaste={handlePaste}
                          maxLength={1}
                          className="otp-input-item"
                          onKeyPress={handleKeyPress}
                        />
                      </Form.Item>
                    ))}
                </div>
                {errorMessage ? (
                  <p className="text-danger font-size-14 mt-1">
                    {errorMessage}
                  </p>
                ) : (
                  ""
                )}
                <div className="center mt-3 pointer">
                  {timeCountDown === 0 ? (
                    <span
                      className="text-secondary font-size-14"
                      onClick={() => handleGetOtp({ email: email })}
                    >
                      <span className="text-primary">
                        <b>Gửi lại mã OTP</b>
                      </span>
                    </span>
                  ) : (
                    <span className="text-secondary font-size-14">
                      Gửi lại mã OTP:{" "}
                      <span className="text-primary">
                        <b>
                          <Countdown
                            initialTime={timeCountDown}
                            onFinish={() => setTimeCountdown(0)}
                          />
                        </b>
                      </span>
                    </span>
                  )}
                </div>
                <Button
                  loading={loading}
                  htmlType="submit"
                  className="btn-primary mt-3 w-full forgot-input"
                >
                  Xác nhận
                </Button>
              </Form>

              <div
                className="back-login center mt-4 gap-8 pointer"
                onClick={() => navigate(routesConfig.login)}
              >
                <PreviousIcon />
                <span className="font-size-14 text-secondary">
                  <b>Quay lại trang đăng nhập</b>
                </span>
              </div>
            </div>
          </div>
        );

      case "password":
        return (
          <div className="forgot-password">
            <img
              className="forgot-pass-bg"
              src={`${process.env.PUBLIC_URL}/assets/img/bg-forgot-password.png`}
            />

            <img
              className="forgot-pass-icon"
              src={`${process.env.PUBLIC_URL}/assets/icons/change-pass-banner-icon.svg`}
            />

            <div className="forgot-pass-form">
              <h4 className="font-size-30 font-weight-6 line-height-38 text-center">
                Đặt lại mật khẩu
              </h4>
              <span className="font-size-16 line-height-24 text-secondary">
                Đặt lại mật khẩu mới cho tài khoản của bạn
              </span>

              <Form
                onFinish={handleChangePassword}
                form={form}
                className="mt-4"
              >
                <label htmlFor="" className="mt-3">
                  Mật khẩu mới <span className="text-danger">*</span>
                </label>
                <Form.Item
                  name="password"
                  className="mb-3"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập đầy đủ thông tin",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={onChangePassword}
                    placeholder="Nhập mật khẩu"
                    className="w-full forgot-input"
                  />
                </Form.Item>
                <label htmlFor="" className="mt-3">
                  Xác nhận mật khẩu mới <span className="text-danger">*</span>
                </label>
                <Form.Item
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập đầy đủ thông tin",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(" Mật khẩu xác nhận chưa chính xác")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Xác nhận mật khẩu mới"
                    className="w-full forgot-input"
                  />
                </Form.Item>

                <div className="flex mt-3 gap-8">
                  {isPassedOptionLength ? <CheckPrimary /> : <CheckSecondary />}{" "}
                  <span className="text-secondary font-size-14">
                    Tối thiểu 8 đến 25 kí tự
                  </span>
                </div>
                <div className="flex mt-2 gap-8">
                  {isPassedOptionType ? <CheckPrimary /> : <CheckSecondary />}{" "}
                  <span className="text-secondary font-size-14">
                    Bao gồm chữ thường, chữ hoa, số và ký tự đặc biệt
                  </span>
                </div>

                <Button
                  loading={loading}
                  htmlType="submit"
                  className="btn-primary mt-3 w-full forgot-input"
                >
                  Đặt lại mật khẩu
                </Button>
              </Form>

              <div
                className="back-login center mt-4 gap-8 pointer"
                onClick={() => navigate(routesConfig.login)}
              >
                <PreviousIcon />
                <span className="font-size-14 text-secondary">
                  <b>Quay lại trang đăng nhập</b>
                </span>
              </div>
            </div>
          </div>
        );
      case "success":
        return (
          <div className="forgot-password">
            <img
              className="forgot-pass-bg"
              src={`${process.env.PUBLIC_URL}/assets/img/bg-forgot-password.png`}
            />

            <img
              className="forgot-pass-icon"
              src={`${process.env.PUBLIC_URL}/assets/icons/check-success-icon.svg`}
            />

            <div className="forgot-pass-form">
              <h4 className="font-size-30 font-weight-6 line-height-38 text-center">
                Mật khẩu đã được đổi!
              </h4>
              <span className="font-size-16 line-height-24 text-secondary mt-2 text-center">
                Mật khẩu mới của bạn đã được cập nhật thành công trên hệ thống
                Mooc
              </span>

              <Button
                className="btn-primary mt-3 w-full forgot-input"
                onClick={() => navigate(routesConfig.login)}
              >
                Đến trang đăng nhập
              </Button>
            </div>
          </div>
        );

      default:
        return <div></div>;
    }
  };

  return renderContent();
}

export default ForgotPassword;
