import React from "react";
import type { MenuProps } from "antd";
import { Menu, Progress, Tooltip } from "antd";
import "./style.scss";
import { MoocCourseProps, MoocLessonProps } from "../../types/course";
import {
  convertMinutesToTimeString,
  renderLessonIconByType,
} from "../../utils/format";
import { toNumber } from "lodash";

interface MoocMenuProps {
  data: MoocCourseProps[];
  className?: string;
  mode?: "default" | "small";
  display?: boolean;
  selectedKeys?: string[];
  openKeys?: string[];
  onOpenChange?: (keys: string[]) => void;
  onClick?: (v: any) => void;
}

const MoocMenu: React.FC<MoocMenuProps> = ({
  data = [],
  className = "",
  mode = "default",
  onClick = () => {},
  selectedKeys = [],
  openKeys = [],
  onOpenChange,
}) => {
  type MenuItem = Required<MenuProps>["items"][number];

  const items: MenuItem[] = data.map((item: MoocCourseProps) => ({
    key: `parent-${item.id}`,
    className: "mooc-menu-header",
    label: (
      <div className="flex menu-item-responsive">
        <p className="section-title font-size-14 line-height-18 flex-1 one-line w-max-content mr-3">
          {item.name}
        </p>

        <div className="progress-info flex gap-24">
          <div>
            <Progress percent={item.progress} size={[270, 8]} />
          </div>
          <span className="font-size-14 section-statistical">
            {item.totalUnit ? `${item.totalUnit} học liệu - ` : "0 học liệu"}
            {item.time
              ? convertMinutesToTimeString(toNumber(item.time) / 60)
              : ""}
          </span>
        </div>
      </div>
    ),
    children: (item.children ?? []).map((itemChild: MoocCourseProps) => ({
      key: `child-${itemChild.id}`,
      id: `sequence-${itemChild.id}`,
      label: (
        <div className="flex menu-item-responsive">
          <p className="sequence-title font-size-14 line-height-18 flex-1 one-line w-max-content mr-3">
            {itemChild.name}
          </p>
          <div className="progress-info flex gap-24">
            <div>
              <Progress
                percent={itemChild.completedPercentage}
                size={[270, 8]}
              />
            </div>
            <span className="font-size-14 sequence-statistical">
              {itemChild.totalUnit
                ? `${itemChild.totalUnit} học liệu - `
                : "0 học liệu"}
              {itemChild.totalTime
                ? convertMinutesToTimeString(toNumber(itemChild.totalTime) / 60)
                : ""}
            </span>
          </div>
        </div>
      ),
      children: (itemChild.children ?? []).map((itm: MoocLessonProps) => ({
        key: `parent-${item.id}-child-${itemChild.id}-young-${itm.id}`,
        id: `unit-${itm.id}`,
        onClick: () => {
          if (itm.isAccessible) {
            onClick(itm);
          }
        },
        label: (
          <Tooltip
            placement="leftBottom"
            title={
              itm?.lockedReason &&
              `Học liệu ${itm?.lockedReason?.toLowerCase()}`
            }
          >
            <div>
              <div
                className={
                  "font-size-14 flex align-center gap-12" +
                  (itm.isAccessible ? "" : " unit-disabled")
                }
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: renderLessonIconByType(
                      !itm.isAccessible && !itm?.isCompleted
                        ? "block"
                        : !itm?.isCompleted
                          ? itm.type || (itm?.block && itm?.block[0]?.module)
                          : 1
                    ),
                  }}
                />
                <div
                  className={
                    itm.isAccessible ? "unit-name" : "unit-name-disabled"
                  }
                >
                  <span className="flex-1">{itm.name}</span>
                  {mode === "default" && (
                    <span className="unit-time">
                      {(itm.isAccessible || itm.isFree) && itm.totalTime
                        ? convertMinutesToTimeString(
                            toNumber(itm.totalTime) / 60
                          )
                        : null}

                      {itm.lockedReason && !itm.isAccessible && (
                        <span className="cost">{itm?.lockedReason}</span>
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Tooltip>
        ),
      })),
    })),
  }));

  return (
    <div
      className={`mooc-menu ${className} ${mode === "default" ? "" : "mooc-menu-small"}`}
    >
      <Menu
        mode="inline"
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        items={items}
      />
    </div>
  );
};

export default MoocMenu;
