import React from "react";
import "./style.scss";
import ResponsiveList from "../list/ResponsiveList";

function StatisticIntro() {
  return (
    <div className="statistics-intro">
      <ResponsiveList
        grid={{
          gutter: 32,
          xs: 1,
          sm: 1,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        data={[
          {
            content: (
              <div className="intro-item">
                <span className="text-primary font-size-60"><b>10k</b></span>
                <p className="font-size-18">Khoá học trên hệ thống</p>
              </div>
            ),
          },
          {
            content: (
              <div className="intro-item">
                <span className="text-primary font-size-60"><b>200%</b></span>
                <p className="font-size-18">
                  Người dùng đăng ký mới sau 01 tháng ra mắt
                </p>
              </div>
            ),
          },
          {
            content: (
              <div className="intro-item">
                <span className="text-primary font-size-60"><b>100+</b></span>
                <p className="font-size-18">Trường đại học liên kết</p>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

export default StatisticIntro;
