// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.record-minimize .ant-modal-content {
  padding: 0 !important;
}

@keyframes signal {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.animate-signal {
  animation: signal 1s ease-out infinite;
}`, "",{"version":3,"sources":["webpack://./src/pages/learning-course-structure/components/RecordMinimize.scss"],"names":[],"mappings":"AAEI;EACI,qBAAA;AADR;;AAIA;EACI;IACI,mBAAA;IACA,UAAA;EADN;EAGE;IACI,UAAA;EADN;EAGE;IACI,mBAAA;IACA,UAAA;EADN;AACF;AAGA;EACI,sCAAA;AADJ","sourcesContent":["\n.record-minimize {\n    .ant-modal-content {\n        padding: 0 !important;\n    }\n}\n@keyframes signal {\n    0% {\n        transform: scale(1);\n        opacity: 0;\n    }\n    50% {\n        opacity: 1;\n    }\n    100% {\n        transform: scale(2);\n        opacity: 0;\n    }\n}\n.animate-signal {\n    animation: signal 1s ease-out infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
