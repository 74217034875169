import {
  AudioMutedOutlined,
  AudioOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  LeftOutlined,
  PauseOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Menu, Popover, Slider, Tooltip, Typography } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import {
  examSupervisorStore,
  learningCourseStore,
} from "../../../../../stores/stores";
import { scrollToElement } from "../../../../../utils/element";
import FaceRecordModal from "../../../components/modals/FaceRecordModal";
import IpCheckingAttendant from "../../../components/modals/IpCheckingAttendant";
import RecordMinimize from "../../../components/RecordMinimize";
import "./MultimediaPlayer.scss";

const { Text } = Typography;
interface MultimediaPlayerProps {
  url: string;
  markers: any[];
  checkingAttendant?: boolean;
  isAudio?: boolean;
  thumbnailUrl?: string;
}

const MultimediaPlayer: React.FC<MultimediaPlayerProps> = ({
  url,
  markers,
  checkingAttendant = false,
  isAudio = false,
  thumbnailUrl,
}) => {
  const { setVideoTime } = learningCourseStore();
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [volume, setVolume] = useState(0.8);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [quality, setQuality] = useState(1080);
  const [duration, setDuration] = useState(0);
  const [hoverTime, setHoverTime] = useState<number | null>(null);
  const [tooltipX, setTooltipX] = useState<number | null>(null);
  const [isExpand, setIsExpand] = useState(true);
  const [stopRecord, setStopRecord] = useState(false);
  const [needFaceRecord, setNeedFaceRecord] = useState(false);
  const [pointRecord, setPointRecord] = useState<any>([]);
  const [stopModal, setStopModal] = useState(false);

  const [currentMenu, setCurrentMenu] = useState<"main" | "quality" | "speed">(
    "main"
  );

  const playerRef = useRef<ReactPlayer>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { examSupervisorSetting }: any = examSupervisorStore();
  const timeoutRefList = useRef<NodeJS.Timeout[]>([]);

  const timeFirstCheckIn = examSupervisorSetting?.mooc_setting_times
    ? examSupervisorSetting?.mooc_setting_times[0]?.minutes
    : 0;

  const isIpAttendanceCheckingEnable =
    examSupervisorSetting?.ip_attendance || false;

  const qualities = [
    { label: "1080p", value: 1080 },
    { label: "720p", value: 720 },
    { label: "480p", value: 480 },
    { label: "360p", value: 360 },
    { label: "240p", value: 240 },
    { label: "144p", value: 144 },
  ];

  const speeds = [
    { label: "0.5x", value: 0.5 },
    { label: "Chuẩn", value: 1 },
    { label: "1.5x", value: 1.5 },
    { label: "2x", value: 2 },
  ];

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(document.fullscreenElement !== null);
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      timeoutRefList.current.forEach((timeId) => clearTimeout(timeId));
      setStopRecord(true);
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handlePlayPause = () => setPlaying(!playing);

  const handleProgress = (state: { played: number; playedSeconds: number }) => {
    setPlayed(state.played);
    setPlayedSeconds(state.playedSeconds);

    if (
      (examSupervisorSetting?.is_active || false) &&
      examSupervisorSetting?.face_attendance &&
      examSupervisorSetting?.lesson &&
      timeFirstCheckIn * 60 - Math.floor(state.playedSeconds) <= 4 &&
      timeFirstCheckIn * 60 - Math.floor(state.playedSeconds) >= 0 &&
      !pointRecord.includes(timeFirstCheckIn)
    ) {
      setNeedFaceRecord(true);
      setPointRecord([...pointRecord, timeFirstCheckIn]);
    }

    examSupervisorSetting?.mooc_setting_times?.forEach(
      (setting: { minutes: number }) => {
        if (
          setting?.minutes * 60 - Math.floor(state.playedSeconds) <= 4 &&
          setting?.minutes * 60 - Math.floor(state.playedSeconds) >= 0 &&
          !pointRecord.includes(setting?.minutes)
        ) {
          setIsExpand(true);
          setPointRecord([...pointRecord, setting?.minutes]);
        } else if (setting?.minutes * 60 - Math.floor(state.playedSeconds) < 0){
          setPointRecord([...pointRecord, setting?.minutes]);
        }
      }
    );
    if (Math.floor(duration) === Math.floor(state.playedSeconds)) {
      setStopModal(true);
    }
  };

  const handleVolumeChange = (value: number) => setVolume(value / 100);

  const handleMuteToggle = () => setIsMuted(!isMuted);

  const handleFullscreenToggle = () => {
    if (!isFullscreen) {
      containerRef.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const progressBar = e.currentTarget as HTMLElement;
    const rect = progressBar.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const hoverTime = (offsetX / rect.width) * duration;
    setHoverTime(hoverTime);
    setTooltipX(offsetX - 12);
  };

  const handleMouseLeave = () => {
    setHoverTime(null);
    setTooltipX(null);
  };

  const handleProgressClick = (e: React.MouseEvent) => {
    const progressBar = e.currentTarget as HTMLElement;
    const rect = progressBar.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const seekTo = (offsetX / rect.width) * duration;
    playerRef.current?.seekTo(seekTo);
  };

  const handleQualityChange = (quality: number) => {
    setQuality(quality);
    setCurrentMenu("main");
  };

  const handleSpeedChange = (speed: number) => {
    setPlaybackRate(speed);
    setCurrentMenu("main");
  };

  const formatTime = (time: number) => {
    if (isNaN(time) || time < 0 || !isFinite(time)) return "00:00";
    return new Date(time * 1000).toISOString().substr(14, 5);
  };

  const handleExpand = useCallback(() => {
    setIsExpand(false);
  }, []);

  const mainMenu = (
    <Menu>
      <Menu.Item onClick={() => setCurrentMenu("speed")}>
        <div className="pop-item">
          <span>Tốc độ phát</span>
          <span>
            {speeds.find((speed) => speed.value === playbackRate)?.label}
          </span>
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => setCurrentMenu("quality")}>
        <div className="pop-item">
          <span>Chất lượng</span>
          <span>
            {
              qualities.find((qualityItem) => qualityItem.value === quality)
                ?.label
            }
          </span>
        </div>
      </Menu.Item>
    </Menu>
  );

  const qualityMenu = (
    <Menu>
      <Menu.Item onClick={() => setCurrentMenu("main")}>
        <div className="pop-item-top">
          <LeftOutlined /> Chất lượng
        </div>
      </Menu.Item>
      {qualities.map((quality) => (
        <Menu.Item
          key={quality.value}
          onClick={() => handleQualityChange(quality.value)}
        >
          <div className="pop-item">{quality.label}</div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const speedMenu = (
    <Menu>
      <Menu.Item onClick={() => setCurrentMenu("main")}>
        <div className="pop-item-top">
          <LeftOutlined /> Tốc độ phát
        </div>
      </Menu.Item>
      {speeds.map((speed) => (
        <Menu.Item
          key={speed.value}
          onClick={() => handleSpeedChange(speed.value)}
        >
          <div className="pop-item">{speed.label}</div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const getMenu = () => {
    if (currentMenu === "quality") return qualityMenu;
    if (currentMenu === "speed") return speedMenu;
    return mainMenu;
  };

  return (
    <div
      ref={containerRef}
      className={`${isAudio ? "audio-player" : ""} multimedia-container ${isFullscreen ? "fullscreen" : ""}`}
    >
      <div
        className="react-player-container"
        onClick={handlePlayPause}
        onDoubleClick={isAudio ? undefined : handleFullscreenToggle}
      >
        <ReactPlayer
          ref={playerRef}
          url={url}
          playing={playing}
          onPause={() => setPlaying(false)}
          onPlay={() => setPlaying(true)}
          volume={isMuted ? 0 : volume}
          onProgress={handleProgress}
          onDuration={(d) => {
            setDuration(d);
            setVideoTime(d);
          }}
          width="100%"
          height={isAudio ? "50px" : "100%"}
          controls={false}
          playbackRate={playbackRate}
          light={thumbnailUrl || false}
        />
      </div>
      <div className={`controls ${isFullscreen ? "fullscreen-controls" : ""}`}>
        <div className="progress-container">
          <div
            className="progress-bar"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            onClick={handleProgressClick}
          >
            <div
              className="progress"
              style={{ width: `${played * 100}%` }}
            ></div>
            {markers.map((marker, index) => (
              <Popover
                key={index}
                content={
                  <Tooltip
                    key={index}
                    title={marker.label}
                    getPopupContainer={() =>
                      containerRef.current || document.body
                    }
                  >
                    <div
                      className="multimedia-pop-content"
                      onClick={(e) => {
                        scrollToElement(`note-${marker?.id}`);
                      }}
                      onMouseMove={(e) => e.stopPropagation()}
                    >
                      <img src="/assets/icons/notification-text.svg" alt="" />
                    </div>
                  </Tooltip>
                }
                trigger="hover"
                getPopupContainer={() => containerRef.current || document.body}
              >
                <div
                  className="marker-icon"
                  style={{ left: `${(marker.time / duration) * 100}%` }}
                ></div>
              </Popover>
            ))}
            {hoverTime !== null && (
              <Tooltip
                title={formatTime(hoverTime)}
                open
                placement="bottom"
                overlayInnerStyle={{
                  position: "absolute",
                  transform: `translateX(${tooltipX}px) translateY(-54px)`,
                  zIndex: 2000,
                }}
                getPopupContainer={() => containerRef.current || document.body}
              >
                <div className="hover-time" />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="control-row">
          <div className="left-controls">
            <Button
              type="text"
              icon={
                playing ? (
                  <PauseOutlined />
                ) : (
                  <img src="/assets/icons/play-icon.svg" alt="" />
                )
              }
              onClick={handlePlayPause}
              className="control-button"
            />
            <Text className="time-color">
              {formatTime(played * duration)} / {formatTime(duration)}
            </Text>
            <Button
              type="text"
              icon={isMuted ? <AudioMutedOutlined /> : <AudioOutlined />}
              onClick={handleMuteToggle}
              className="control-button"
            />
            <Slider
              className="volume-slider"
              value={isMuted ? 0 : volume * 100}
              onChange={handleVolumeChange}
            />
          </div>
          <div className="right-controls">
            {!isAudio && (
              <>
                <Popover
                  content={getMenu()}
                  trigger="click"
                  placement="top"
                  overlayClassName="multimedia-settings-popover"
                  getPopupContainer={() =>
                    containerRef.current || document.body
                  }
                >
                  <Button
                    type="text"
                    icon={<SettingOutlined />}
                    className="control-button"
                  />
                </Popover>
                <Button
                  type="text"
                  icon={
                    isFullscreen ? (
                      <FullscreenExitOutlined />
                    ) : (
                      <FullscreenOutlined />
                    )
                  }
                  onClick={handleFullscreenToggle}
                  className="control-button"
                />
              </>
            )}
          </div>
        </div>
      </div>

      {needFaceRecord && !stopModal && (
        <RecordMinimize
          minimizeText="Điểm danh"
          isExpand={isExpand}
          handleExpand={() => setIsExpand(true)}
        />
      )}
      {!stopRecord && needFaceRecord && !stopModal && (
        <FaceRecordModal
          hasMask={false}
          unitType="video"
          currentVideoTimestamp={Number((played * duration).toFixed(2))}
          type="study"
          description="Vui lòng giữ mặt trong khung hình trong quá trình học"
          title="Điểm danh"
          isOpen={isExpand}
          handleClose={handleExpand}
          playedSeconds={playedSeconds}
        />
      )}
      {isIpAttendanceCheckingEnable && <IpCheckingAttendant />}
    </div>
  );
};

export default MultimediaPlayer;
