import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { CollapseProps } from "antd/lib";
import React from "react";
import MyNoteCard from "../../../../../components/card/my-note-card/MyNoteCard";
import ResponsiveList from "../../../../../components/list/ResponsiveList";
import CustomPagination from "../../../../../components/pagination/Pagination";
import { MyNote } from "../../../../../types/myNote";
import "./NoteList.scss";
type Props = {
  myNoteList: MyNote[];
  myPinnedNoteList: MyNote[];
  isLoading: boolean;
  totalPage: number;
  pageSize: number;
  pageNum: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  selectedNotes: number[];
  handleCheck: (id: number, checked: boolean) => void;
};

const NoteList = (props: Props) => {
  const {
    myNoteList,
    isLoading,
    totalPage,
    pageSize,
    pageNum,
    setPageSize,
    setPageNum,
    selectedNotes,
    handleCheck,
    myPinnedNoteList,
  } = props;

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <div className="text-title-18">Ghi chú đã ghim</div>
          <div className="my-notes-des mt-1 mb-3">
            Tổng hợp những ghi chú bạn đã ghim
          </div>
        </>
      ),
      children: (
        <ResponsiveList
          grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 3 }}
          data={myPinnedNoteList.map((item) => ({
            ...item,
            content: (
              <MyNoteCard
                {...item}
                isChecked={selectedNotes.includes(item.id)}
                onCheck={handleCheck}
              />
            ),
          }))}
          loading={isLoading}
          isViewStatistical={false}
        />
      ),
    },
  ];
  return (
    <div className="note-list">
      <div className="hr-tag"></div>

      {myPinnedNoteList?.length > 0 ? (
        <div className="pinned-notes">
          <Collapse
            accordion
            items={items}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            defaultActiveKey={["1"]}
          />
        </div>
      ) : null}

      <div className="note-other">
        {myPinnedNoteList?.length > 0 ? (
          <>
            <div className="text-title-18">Ghi chú khác</div>
            <div className="my-notes-des mt-1 mb-3">
              Tổng hợp những ghi chú khác của bạn
            </div>
          </>
        ) : null}

        <ResponsiveList
          grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 3 }}
          data={myNoteList.map((item) => ({
            ...item,
            content: (
              <MyNoteCard
                {...item}
                isChecked={selectedNotes.includes(item.id)}
                onCheck={handleCheck}
              />
            ),
          }))}
          loading={isLoading}
          isViewStatistical={false}
        />
      </div>
      <div className="hr-tag-item"></div>
      {myNoteList?.length > 0 ? (
        <CustomPagination
          totalPage={totalPage}
          pageSize={pageSize}
          pageNumber={pageNum}
          setPageSize={setPageSize}
          setPageNumber={setPageNum}
        />
      ) : null}
    </div>
  );
};

export default NoteList;
