import parse, {
  domToReact,
  HTMLReactParserOptions,
  Element,
} from "html-react-parser";
import CourseEvaluationTagAction from "../pages/course-evaluation/course-tag-action/CourseTagAction";
import CourseProges from "../components/course-progess/CourseProges";
import BlogHeaderLink from "../components/blog-and-forum/design-theme-blog-content/blog-header-link";
import LinkDetailSchool from "../components/blog-and-forum/design-theme-blog-content/link-detail-school";
import ListBlogContent from "../components/blog-and-forum/design-theme-blog-content/list-blog-content";
import SearchInputBlock from "../components/blog-and-forum/design-theme-blog-content/search-input-blog";
import { useState } from "react";
import DetailSchool from "../components/detail-school";
import CourseInformation from "../pages/course-info";
import ExploreSchool from "../components/ExploreSchool";
import SearchEducationalPrograms from "../pages/search-educational-programs";
import CourseDetail from "../pages/course-detail";
import SearchEducationalProgramsAdminPortal from "../pages/search-educational-programs/portal";
import CurriculumProgramRoadmapTemplate from "../pages/course-program-detail/curriculum-program-roadmap/CurriculumProgramRoadmapTemplate";
import LoginBlockHeader from "../components/authentication/LoginBlockHeader";
interface TemplateData {
  id: string;
  html: string;
  css: string;
}
export const renderTemplate = (templateData: string): React.ReactNode => {
  if (templateData) {
    try {
      const parsedData: TemplateData = JSON.parse(templateData);
      const { html, css } = parsedData;
      // Create and append style element
      const style = document.createElement("style");
      style.innerHTML = css;
      document.head.appendChild(style);

      // Parse and replace HTML
      const options: HTMLReactParserOptions = {
        replace: (domNode) => {
          const element = domNode as Element;
          if (element.attribs && element.attribs["component-class"]) {
            switch (element.attribs["component-class"]) {
              case "CoursePriceText":
                return <CourseProges />;
              case "CourseTagAction":
                return <CourseEvaluationTagAction />;
              case "BlogHeaderLink":
                return <BlogHeaderLink />;
              case "HeaderLoginDiv":
                return <LoginBlockHeader />;
              // case "LinkDetailSchool":
              //   return <LinkDetailSchool />;
              case "CourseListBlock":
                return <CourseInformation />;
              case "SearchEducationalPrograms":
                return <SearchEducationalProgramsAdminPortal />;
              case "ListBlogContent":
                return <ListBlogContent />;
              case "SearchInputBlock":
                return <SearchInputBlock />;
              case "ReviewSchool":
                return <CourseDetail />;
              case "ExploreSchool":
                return <ExploreSchool />;
              case "DetailProgramCourse":
                return <CurriculumProgramRoadmapTemplate idElmTag="curriculum" />;

              default:
                return null;
            }
          }
          return undefined;
        },
      };

      return parse(html, options);
    } catch (error) {
      console.error("Error parsing template data:", error);
      return null;
    }
  }
  return null;
};
