import React, { useEffect, useState } from 'react'
import { useAuthStore, useUserInfoStore } from '../../stores/stores';
import { Avatar, Dropdown, MenuProps } from 'antd';
import Notification from '../notification';
import { getInitials } from '../../utils/format';
import SafeLink from '../link/SafeLink';
import { routesConfig } from '../../config/routes';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import useModalConfirm from '../../hooks/useModalConfirm';
import { postLogout } from '../../service/login';
import { useNavigate } from 'react-router-dom';

export default function LoginBlockHeader() {
  const showConfirm = useModalConfirm();
  const [loading, setLoading] = useState(false);
  const accessToken = useAuthStore((state) => state.accessToken);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const { setUserInfo, infoUser } = useUserInfoStore();
  const logout = useAuthStore((state) => state.logout);
  const navigate = useNavigate();

  const showConfirmLogout = () => {
    showConfirm({
      title: "Đăng xuất",
      description: "Bạn có chắc chắn muốn đăng xuất?",
      okText: "Đăng xuất",
      cancelText: "Hủy bỏ",
      type: "confirm",
      icon: (
        <img src={`${process.env.PUBLIC_URL}/assets/icons/logout-icon.svg`} />
      ),
      onOk: () => {
        handleLogout();
      },
      onCancel: () => {},
    });
  };
  
  const items: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <div className="account account-drop">
          <a onClick={(e) => e.preventDefault()}>
            <Avatar src={infoUser.image} size={40}>
              {getInitials(String(infoUser?.fullName))}
            </Avatar>
          </a>
          <div className="account__name">
            <a
              className="account__name__wrapper"
              onClick={(e) => e.preventDefault()}
            >
              <span>{infoUser?.fullName}</span>
              <span className="email">{infoUser?.email}</span>
            </a>
          </div>
        </div>
      ),
    },
    {
      key: "1",
      label: (
        <SafeLink
          className="account__menu__item border-bottom"
          rel="profile"
          to={routesConfig.infoUser}
        >
          <UserOutlined />
          <span>Thông tin cá nhân</span>
        </SafeLink>
      ),
    },
    {
      key: "2",
      label: (
        <SafeLink
          className="account__menu__item flex align-center gap-8"
          rel="profile"
          to={routesConfig.myCourses}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8.00016 14L7.93346 13.8999C7.47037 13.2053 7.23882 12.858 6.9329 12.6065C6.66207 12.3839 6.35001 12.2169 6.01457 12.1151C5.63566 12 5.21823 12 4.38338 12H3.46683C2.72009 12 2.34672 12 2.06151 11.8547C1.81063 11.7268 1.60665 11.5229 1.47882 11.272C1.3335 10.9868 1.3335 10.6134 1.3335 9.86667V4.13333C1.3335 3.3866 1.3335 3.01323 1.47882 2.72801C1.60665 2.47713 1.81063 2.27316 2.06151 2.14532C2.34672 2 2.72009 2 3.46683 2H3.7335C5.22697 2 5.97371 2 6.54414 2.29065C7.0459 2.54631 7.45385 2.95426 7.70951 3.45603C8.00016 4.02646 8.00016 4.77319 8.00016 6.26667M8.00016 14V6.26667M8.00016 14L8.06687 13.8999C8.52996 13.2053 8.76151 12.858 9.06743 12.6065C9.33826 12.3839 9.65032 12.2169 9.98576 12.1151C10.3647 12 10.7821 12 11.6169 12H12.5335C13.2802 12 13.6536 12 13.9388 11.8547C14.1897 11.7268 14.3937 11.5229 14.5215 11.272C14.6668 10.9868 14.6668 10.6134 14.6668 9.86667V4.13333C14.6668 3.3866 14.6668 3.01323 14.5215 2.72801C14.3937 2.47713 14.1897 2.27316 13.9388 2.14532C13.6536 2 13.2802 2 12.5335 2H12.2668C10.7734 2 10.0266 2 9.45619 2.29065C8.95442 2.54631 8.54647 2.95426 8.29081 3.45603C8.00016 4.02646 8.00016 4.77319 8.00016 6.26667"
              stroke="#667085"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Khoá học của tôi</span>
        </SafeLink>
      ),
    },
    {
      key: "3",
      label: (
        <SafeLink
          className="account__menu__item flex align-center gap-8"
          rel="profile"
          to={routesConfig.savedCourses}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M10.7409 2C13.0891 2 14.6668 4.235 14.6668 6.32C14.6668 10.5425 8.11868 14 8.00016 14C7.88164 14 1.3335 10.5425 1.3335 6.32C1.3335 4.235 2.91127 2 5.25942 2C6.60757 2 7.48905 2.6825 8.00016 3.2825C8.51127 2.6825 9.39276 2 10.7409 2Z"
              stroke="#667085"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Khoá học đã lưu</span>
        </SafeLink>
      ),
    },
    {
      key: "4",
      label: (
        <SafeLink
          className="account__menu__item flex align-center gap-8"
          rel="profile"
          to={routesConfig.studySchedule}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M14 6.66671H2M10.6667 1.33337V4.00004M5.33333 1.33337V4.00004M5.2 14.6667H10.8C11.9201 14.6667 12.4802 14.6667 12.908 14.4487C13.2843 14.257 13.5903 13.951 13.782 13.5747C14 13.1469 14 12.5868 14 11.4667V5.86671C14 4.7466 14 4.18655 13.782 3.75873C13.5903 3.3824 13.2843 3.07644 12.908 2.88469C12.4802 2.66671 11.9201 2.66671 10.8 2.66671H5.2C4.0799 2.66671 3.51984 2.66671 3.09202 2.88469C2.71569 3.07644 2.40973 3.3824 2.21799 3.75873C2 4.18655 2 4.7466 2 5.86671V11.4667C2 12.5868 2 13.1469 2.21799 13.5747C2.40973 13.951 2.71569 14.257 3.09202 14.4487C3.51984 14.6667 4.0799 14.6667 5.2 14.6667Z"
              stroke="#667085"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Lịch học</span>
        </SafeLink>
      ),
    },
    {
      key: "5",
      label: (
        <SafeLink
          className="account__menu__item border-bottom flex align-center gap-8"
          rel="profile"
          to={`/${routesConfig.mySurveys}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M14 6.66671H2M10.6667 1.33337V4.00004M5.33333 1.33337V4.00004M5.2 14.6667H10.8C11.9201 14.6667 12.4802 14.6667 12.908 14.4487C13.2843 14.257 13.5903 13.951 13.782 13.5747C14 13.1469 14 12.5868 14 11.4667V5.86671C14 4.7466 14 4.18655 13.782 3.75873C13.5903 3.3824 13.2843 3.07644 12.908 2.88469C12.4802 2.66671 11.9201 2.66671 10.8 2.66671H5.2C4.0799 2.66671 3.51984 2.66671 3.09202 2.88469C2.71569 3.07644 2.40973 3.3824 2.21799 3.75873C2 4.18655 2 4.7466 2 5.86671V11.4667C2 12.5868 2 13.1469 2.21799 13.5747C2.40973 13.951 2.71569 14.257 3.09202 14.4487C3.51984 14.6667 4.0799 14.6667 5.2 14.6667Z"
              stroke="#667085"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Khảo sát của tôi</span>
        </SafeLink>
      ),
    },
    // {
    //   key: "6",
    //   label: (
    //     <SafeLink to="/setting" className="account__menu__item" rel="settings">
    //       <SettingOutlined />
    //       <span>Cài đặt</span>
    //     </SafeLink>
    //   ),
    // },
    {
      key: "7",
      label: (
        <a
          className="account__menu__item"
          rel="log-out"
          onClick={showConfirmLogout}
        >
          <LogoutOutlined />
          <span>Đăng xuất</span>
        </a>
      ),
    },
  ];

  const handleLogout = async () => {
    setLoading(true);
    if (accessToken) {
      try {
        await postLogout(accessToken).then((res) => {
          logout();
        });
        navigate(routesConfig.login);
      } catch (error) {
        setLoading(false);
        logout();
        window.location.replace(routesConfig.login);
      }
    }
  };

  const handleSignUp = () => {
    setLoading(true);
    setTimeout(() => {
      navigate(routesConfig.register);
      setLoading(false);
    }, 1000);
  };

  const handleSignIn = () => {
    setLoading(true);
    setTimeout(() => {
      navigate(routesConfig.login);
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (isAuthenticated && infoUser.isLoginFirst) {
      setUserInfo({ ...infoUser, isLoginFirst: false });
    }
  }, [isAuthenticated, infoUser.isLoginFirst, infoUser]);

  return (
    <div className="header__right flex align-center">
      {accessToken ? (
        <>
          <div className="toolbar">
            <Notification />
            <Dropdown
              overlayClassName="header-account"
              trigger={["click"]}
              menu={{ items }}
            >
              <div className="account">
                <a onClick={(e) => e.preventDefault()}>
                  <Avatar src={infoUser.image} size={40}>
                    {getInitials(String(infoUser?.fullName))}
                  </Avatar>{" "}
                </a>
                <div className="account__name">
                  <a
                    className="account__name__wrapper"
                    onClick={(e) => e.preventDefault()}
                  ></a>
                </div>
              </div>
            </Dropdown>
          </div>
        </>
      ) : (
        <>
          <button
            className="register-button fontSemiBold"
            onClick={handleSignIn}
          >
            <span>Đăng nhập</span>
          </button>
          <button
            className="login-button fontSemiBold"
            onClick={handleSignUp}
          >
            <span>Đăng ký</span>
          </button>
        </>
      )}
    </div>
  )
}
