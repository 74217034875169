import { Flex } from "antd";
import React, { ReactNode } from "react";
import './Legend.scss'

export interface LegendItemProps {
  label: string;
  icon: ReactNode;
}

interface LegendCustomProps {
  items: LegendItemProps[];
}

function LegendCustom({ items }: LegendCustomProps) {
  return (
    <div className="chart-legend-custom">
      <Flex gap={24} align="center" justify="center" className="legend-wrapper">
        {items?.map((item: LegendItemProps, index: number) => (
          <Flex align="center" key={index}>
            {item.icon} <span className="font-size-12">{item.label}</span>
          </Flex>
        ))}
      </Flex>
    </div>
  );
}

export default LegendCustom;
