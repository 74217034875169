import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Form,
  FormInstance,
  Input,
  Popover,
  Progress,
  Upload,
  UploadProps,
} from "antd";
import { isEmpty, toNumber } from "lodash";
import { uploadFile } from "../../../../../../../service/my-course/my-course-service";
import { MAX_FILE_UPLOAD_SIZE } from "../../../../../../../constants";
import { notifyWarning } from "../../../../../../../utils/notification";
import {
  CloudIcon,
  DeleteIcon,
  QuestionMarkIcon,
  ReloadIcon,
} from "../../../../../../../components/icons";
import { bytesToMegabytesBinary } from "../../../../../../../utils/format";
import { QuestionProps } from "../../../../../../../types/course";
import SingleMenu from "../../../../../../../components/menu/SingleMenu";


interface FileUploadProps extends QuestionProps {
  form?: FormInstance<any>;
}

const { Dragger } = Upload;

function FileUpload({ form, data, configs }: FileUploadProps) {
  const isTrue = data?.result?.isTrue === true;
  const result = data?.result?.results[0];
  const [fileInfo, setFileInfo] = useState<any>({});
  const [progress, setProgress] = useState<number>(0);
  const [isUploadDone, setIsUploadDone] = useState<boolean>(true);

  const handleChangeFile: UploadProps["onChange"] = async (info) => {
    const file = info.file.originFileObj;

    if (file && isCorrectFile(file)) {
      setFileInfo(file);
      setIsUploadDone(false);

      const formData = new FormData();
      formData.append("file", file);

      try {
        const res = await uploadFile(formData);
        setProgress(100);
        form?.setFieldValue("fileUpload", res?.data.filePath);
        setTimeout(() => setIsUploadDone(true), 500);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    const initFile = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/test-file.doc`);
        const blob = await response.blob();

        setFileInfo(blob);

        setIsUploadDone(true);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    };
    initFile();
  }, []);

  const isCorrectFile = (file: any) => {
    const acceptFile = [
      // pdf
      "application/pdf",
      // excel
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // word
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // powerpoint
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];

    const isJpgOrPng = acceptFile.includes(file.type);
    const isCorrectFile = file.size < MAX_FILE_UPLOAD_SIZE;
    if (isJpgOrPng && isCorrectFile) {
      return true;
    }
    return false;
  };

  const handleDeleteFile = () => {
    setFileInfo({});
    setProgress(0);
  };

  const beforeUpload = (file: any) => {
    if (!isCorrectFile(file)) {
      notifyWarning(
        "Bạn phải tải lên file PDF, Word, excel hoặc PowperPoint (Tối đa 2 MB)!"
      );
      setFileInfo({});
      setProgress(0);
    }
  };

  const renderFileType = () => {
    const type = fileInfo?.type;
    switch (type) {
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xlxs-icon.svg";
      case "application/vnd.ms-excel":
        return "xls-icon.svg";
      case "application/vnd.ms-powerpoint":
        return "ppt-icon.svg";
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return "pptx-icon.svg";
      case "application/msword":
        return "doc-icon.svg";
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "docx-icon.svg";
      case "application/pdf":
        return "pdf-icon.svg";
      default:
        break;
    }
  };

  const renderFeedback = () => {
    if (isEmpty(data?.result) && !isTrue)
      return data?.settingResponse?.notYet || "Không có phản hồi";

    if (isTrue) return data?.settingResponse?.correct || "Không có phản hồi";

    return data?.settingResponse?.incorrect || "Không có phản hồi";
  };

  const renderContent = () => {
    if (isEmpty(fileInfo)) {
      return (
        <Dragger
          className="dragger-record"
          name="file"
          multiple={false}
          showUploadList={false}
          onChange={handleChangeFile}
          beforeUpload={beforeUpload}
        >
          <div className="center">
            <div className="icon-file-upload">
              <CloudIcon />
            </div>
          </div>
          <p>
            <span className="font-weight-6 text-primary">Nhấn để tải tệp</span>{" "}
            hoặc kéo thả tệp vào đây
          </p>
          <p className="font-size-12 mt-1">
            PDF,word, excel hoặc PowperPoint (Tối đa 2 MB)
          </p>
        </Dragger>
      );
    }

    return (
      <div className="flex mt-2">
        <Form.Item name="fileUpload">
          <Input style={{ display: "none" }} />
        </Form.Item>
        <div className="file-uploaded">
          <div className="file-info flex gap-8">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/${renderFileType()}`}
              alt="pdf-icon"
            />

            <div className="file-detail w-full">
              <p className="font-size-14">{fileInfo?.name}</p>
              <p className="font-size-14">
                {bytesToMegabytesBinary(fileInfo?.size)}MB
              </p>
              {!isUploadDone && (
                <Progress strokeColor="#7F56D9" percent={progress} />
              )}
            </div>
          </div>
        </div>

        <div className="file-upload-btn-group flex flex-column justify-space-between ml-2">
          <Button className="file-upload-btn">
            <ReloadIcon />
          </Button>
          <Button
            className="file-upload-btn btn-delete"
            onClick={handleDeleteFile}
          >
            <DeleteIcon />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="question-header mb-3">
        <div className="question-title">
          <Flex
            justify="space-between"
            className="font-size-16 line-height-24 font-weight-6"
          >
            Câu hỏi {toNumber(data?.index) + 1}:{" "}
            {configs?.generalConfig?.showQuizScore && (
              <span className="total-point font-size-14">
                {`(${data?.totalPoints || 0} điểm)`}
              </span>
            )}
          </Flex>
          <Flex align="flex-start" gap={8}>
            <div
              className="font-size-16 line-height-24 font-weight-6"
              dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
            ></div>
            {data?.settingHint?.isDisplayInstruction || data?.isShowHint ? (
              <Popover
                content={
                  <div style={{ padding: "5px 16px" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${data?.settingHint?.content || "Không có gợi ý"}`,
                      }}
                    />
                  </div>
                }
              >
                <span className="quest-icon text-secondary w-max-content">
                  <QuestionMarkIcon />
                </span>
              </Popover>
            ) : (
              ""
            )}
          </Flex>
          <div className="flex align-center gap-8 mt-1">
            {result ? (
              <span
                className={`font-size-14 line-height-20 font-weight-6 ${data?.result?.isTrue ? "score-correct-wrapper text-correct" : "score-incorrect-wrapper text-danger"}`}
              >
                Điểm: {data?.result?.points || 0}/
                {data?.result?.totalPoints || 0}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {renderContent()}
      <div className="flex-1 mt-2">
       {configs?.generalConfig?.showFeedbackContent &&
          !data?.settingResponse?.isDisplayPerResponse && !isEmpty(data?.settingResponse) && (
            <SingleMenu
              titleClassName={`comment-menu ${isTrue ? "report-ans-wrapper-correct" : "report-ans-wrapper"} `}
              contentClassName={`${isTrue ? "bg-green" : "bg-purple"}`}
              className={`${isTrue ? "bg-green" : "bg-purple"}`}
              title={
                <p className="font-size-16 line-height-24 font-weight-6">
                  Phản hồi: {renderFeedback()}
                </p>
              }
              content={
                <p className="font-size-16 line-height-24 mt-1 font-weight-5">
                  {data?.result?.message || "Không có nhận xét"}
                </p>
              }
            />
          )}
      </div>
    </div>
  );
}

export default FileUpload;
