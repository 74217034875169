import React, { useEffect, useState } from "react";
import {
  BaseChartProps,
  StatisticChartPayload,
  StatisticLineChartResponseData,
} from "../../../../../types/statistic";
import { fetchRateUnitAction } from "../../../../../service/statistics";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import LineChart from "../../../../../components/charts/LineChart";
import { DEFAULT_MODULE_GROUP, moduleGroupOptions } from "../../../../../constants/statistics";

function CountDownload({ filterValues }: BaseChartProps) {
  const [moduleGroup, setModuleGroup] = useState<number>(DEFAULT_MODULE_GROUP);
  const [data, setData] = useState<StatisticLineChartResponseData[]>();
  const total = data?.reduce((total, item) => total + item.value, 0);

  const moduleGroupPayload =
    moduleGroup === 0 || moduleGroup === -1 ? null : (moduleGroup as any);

  const getRateUnitAction = async (payload: StatisticChartPayload) => {
    const res = await fetchRateUnitAction(payload);
    setData(res.data?.data);
  };

  useEffect(() => {
    
    if (!isEmpty(filterValues)) {
      getRateUnitAction({
        timeUnit: filterValues?.unit || "day",
        from: filterValues?.date?.length
          ? dayjs(filterValues?.date[0]).toISOString()
          : undefined,
        to: filterValues?.date?.length
          ? dayjs(filterValues?.date[1]).toISOString()
          : undefined,
        moduleGroup: moduleGroupPayload,
        courseStructureType: moduleGroupPayload === null ? "sequence" : "unit",
        unitActionType: 3,
        courseIds: filterValues?.course,
        courseLevelIds: filterValues?.level,
        industryGroupIds: filterValues?.department,
      });
    }
  }, [filterValues, moduleGroupPayload]);

  return (
    <LineChart
      data={data}
      showFilter
      total={total}
      placeholder="Bài kiểm tra đánh giá"
      title="Tải về"
      lineName="Lượt Tải về"
      yDescription="Số lượng"
      stroke="#9B8AFB" 
      xDataKey="criteria"
      lineKey="value"
      legends={[
        {
          label: "Lượt tải về",
          icon: (
            <div className="line-icon mr-1" style={{ background: "#9B8AFB" }} />
          ),
        },
      ]}
      filterOptions={moduleGroupOptions.concat([
        {
          label: "Bài giảng",
          value: 0,
        },
        {
          label: "Khoá học",
          value: -1,
        },
      ])}
      onChange={setModuleGroup}
    />
  );
}

export default React.memo(CountDownload);
