import { Button, Image, Steps, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";

const { Title, Text } = Typography;
const items = [
  {
    title: (
      <Title level={5} className="!text-[#6941C6] !font-semibold">
        Quay Video khuôn mặt
      </Title>
    ),
    description: (
      <Text className="!text-[#7F56D9]">Thực hiện quay các góc khuôn mặt</Text>
    ),
  },
  {
    title: (
      <Title level={5} className="!text-[#6941C6] !font-semibold">
        Chụp giấy tờ tuỳ thân
      </Title>
    ),
    description: (
      <Text className="!text-[#7F56D9]">
        Sử dụng giấy tờ gốc và còn hiệu lực
      </Text>
    ),
  },
  {
    title: (
      <Title level={5} className="!text-[#6941C6] !font-semibold">
        Xác nhận thông tin
      </Title>
    ),
    description: (
      <Text className="!text-[#7F56D9]">Kiểm tra, chỉnh sửa và xác nhận</Text>
    ),
  },
];
const IdentifySuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="container text-center my-16 identify-success-card">
      <Image alt="success" src="/assets/img/success.png" preview={false} />
      <Title level={1} className="!font-semibold">
        Xác thực thành công!
      </Title>
      <Text className="py-4">
        Chúc mừng bạn đã đăng ký định danh thành công trên hệ thống Mooc
      </Text>
      <Steps
        current={3}
        responsive
        items={items}
        className="mt-4 max-w-[1200px] m-auto"
      />
      <div className="mt-4">
        <Button
          key="back"
          size="large"
          type="primary"
          onClick={() => navigate(routesConfig.homePage)}
        >
          Trang chủ
        </Button>
        {/* <Button
          key="submit"
          size="large"
          type="primary"
          onClick={() => navigate(routesConfig.infoUser)}
        >
          Về trang thông tin cá nhân
        </Button> */}
      </div>
    </div>
  );
};

export default IdentifySuccess;
