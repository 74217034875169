import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { axiosConfigToUpload } from "../../config/api/configApiUpload";

export const getCourse: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/open-api/course/${id}`);
};

export const getAllCommentByCourse: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(`/api/unit-discussion/get-all-by-course`, payload);
};

export const getAllCommentByUnit: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(`/api/unit-discussion/get-all-by-unit`, payload);
};


export const discussionReact: (payload: {
  id: number;
  reactionType: number;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/api/unit-discussion/react`, payload);
};

export const deleteComment: (id: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.delete(`/api/unit-discussion/delete/${id}`);
};

export const createComment: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.post(`/api/unit-discussion/react`, payload);
};
export const updateComment: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.put(
    `/v2/api/unit-discussion/update-comment`,
    payload
  );
};
export const createDiscussion: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.post(`/v2/api/unit-discussion/create`, payload);
};

export const replyDiscussion: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.post(`/v2/api/unit-discussion/reply`, payload);
};

export const getAllTotalDiscussByUnit: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/api/unit-discussion/get-count-by-unit/${id}?discussionType=2`);
};

export const getAllTotalDiscussByCourse: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/api/unit-discussion/get-count-by-course/${id}?discussionType=2`);
};

export const pinDiscuss: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(`/api/unit-discussion/pin`, payload);
};

export const repordCmt: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(`/api/unit-discussion/report`, payload);
};