import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { ChartProps } from "../../types/chart";
import { renderTotal } from "./components/ultis";
import LegendCustom from "./components/LegendCustom";
import { Flex } from "antd";
import { isEmpty } from "lodash";
import EmptyComponent from "../empty";

interface AreaChartProps extends ChartProps {
  yDescription?: string;
  dots?: any;
  xDataKey?: string;
}

const MoocAreaChart = ({
  title = "",
  legends = [],
  total,
  yDescription = "",
  data,
  dots = [],
  colors = [],
  dataKeys = [],
  xDataKey = "",
}: AreaChartProps) => {
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            background: "#fff",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          {payload?.map((item: any, index: number) => (
            <Flex gap={8} align="center" key={index}>
              {legends[index]?.icon}
              {`${legends[index]?.label}: ${item.value}`}
            </Flex>
          ))}
        </div>
      );
    }

    return null;
  };
  return (
    <div className="wrapper-chart">
      <div className="chart-title font-weight-5 font-size-18">{title}</div>
      <div className="break-line"></div>
      {isEmpty(data) ? (
        <div className="h-default">
          <EmptyComponent description="Không có kết quả" />
        </div>
      ) : (
        <div>
          {renderTotal(total)}
          <Flex>
            <div className="center">
              <div className="font-weight-5 font-size-12 y-description">
                {yDescription}
              </div>
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart data={data}>
                <CartesianGrid strokeDasharray="3 0" vertical={false} />
                <XAxis dataKey={xDataKey} />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend />

                {dataKeys?.map((dataKey: string, index: number) => (
                  <Area
                    key={dataKey}
                    type="linear"
                    dataKey={dataKey}
                    stackId="1"
                    stroke="#fff"
                    fill={colors[index]}
                    fillOpacity={1}
                    dot={dots[index] as any}
                    activeDot={false}
                    name={legends[index]?.label}
                  />
                ))}
              </AreaChart>
            </ResponsiveContainer>
          </Flex>
          <LegendCustom items={legends} />
        </div>
      )}
    </div>
  );
};

export default MoocAreaChart;
