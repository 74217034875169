import {
  DownOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, MenuProps, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SafeLink from "../../components/link/SafeLink";
import Notification from "../../components/notification";
import { routesConfig } from "../../config/routes";
import useModalConfirm from "../../hooks/useModalConfirm";
import { usePreventRouteChange } from "../../hooks/usePreventRouteChange";
import { postLogout } from "../../service/login";
import {
  globalStore,
  useAuthStore,
  useUserInfoStore,
} from "../../stores/stores";
import { getInitials } from "../../utils/format";
import "./header.scss"; // replace with your styles path

const Header: React.FC = () => {
  const showConfirm = useModalConfirm();
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);
  const accessToken = useAuthStore((state) => state.accessToken);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const { setUserInfo, infoUser } = useUserInfoStore();
  const [loading, setLoading] = useState(false);
  const { isFullscreenQuiz } = globalStore();
  const { confirmNavigation } = usePreventRouteChange();

  const handleLogout = async () => {
    setLoading(true);
    if (accessToken) {
      try {
        await postLogout(accessToken).then((res) => {
          logout();
        });
        navigate(routesConfig.login);
      } catch (error) {
        setLoading(false);
        logout();
        window.location.replace(routesConfig.login);
      }
    }
  };

  const handleSignUp = () => {
    setLoading(true);
    setTimeout(() => {
      navigate(routesConfig.register);
      setLoading(false);
    }, 1000);
  };

  const handleSignIn = () => {
    setLoading(true);
    setTimeout(() => {
      navigate(routesConfig.login);
      setLoading(false);
    }, 1000);
  };

  const showConfirmLogout = () => {
    showConfirm({
      title: "Đăng xuất",
      description: "Bạn có chắc chắn muốn đăng xuất?",
      okText: "Đăng xuất",
      cancelText: "Hủy bỏ",
      type: "confirm",
      icon: (
        <img src={`${process.env.PUBLIC_URL}/assets/icons/logout-icon.svg`} />
      ),
      onOk: () => {
        handleLogout();
      },
      onCancel: () => {},
    });
  };

  const items: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <div className="account account-drop">
          <a onClick={(e) => e.preventDefault()}>
            <Avatar src={infoUser.image} size={40}>
              {getInitials(String(infoUser?.fullName))}
            </Avatar>
          </a>
          <div className="account__name">
            <a
              className="account__name__wrapper"
              onClick={(e) => e.preventDefault()}
            >
              <span>{infoUser?.fullName}</span>
              <span className="email">{infoUser?.email}</span>
            </a>
          </div>
        </div>
      ),
    },
    {
      key: "1",
      label: (
        <SafeLink
          className="account__menu__item border-bottom"
          rel="profile"
          to={routesConfig.infoUser}
        >
          <UserOutlined />
          <span>Thông tin cá nhân</span>
        </SafeLink>
      ),
    },
    {
      key: "2",
      label: (
        <SafeLink
          className="account__menu__item flex align-center gap-8"
          rel="profile"
          to={routesConfig.myCourses}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8.00016 14L7.93346 13.8999C7.47037 13.2053 7.23882 12.858 6.9329 12.6065C6.66207 12.3839 6.35001 12.2169 6.01457 12.1151C5.63566 12 5.21823 12 4.38338 12H3.46683C2.72009 12 2.34672 12 2.06151 11.8547C1.81063 11.7268 1.60665 11.5229 1.47882 11.272C1.3335 10.9868 1.3335 10.6134 1.3335 9.86667V4.13333C1.3335 3.3866 1.3335 3.01323 1.47882 2.72801C1.60665 2.47713 1.81063 2.27316 2.06151 2.14532C2.34672 2 2.72009 2 3.46683 2H3.7335C5.22697 2 5.97371 2 6.54414 2.29065C7.0459 2.54631 7.45385 2.95426 7.70951 3.45603C8.00016 4.02646 8.00016 4.77319 8.00016 6.26667M8.00016 14V6.26667M8.00016 14L8.06687 13.8999C8.52996 13.2053 8.76151 12.858 9.06743 12.6065C9.33826 12.3839 9.65032 12.2169 9.98576 12.1151C10.3647 12 10.7821 12 11.6169 12H12.5335C13.2802 12 13.6536 12 13.9388 11.8547C14.1897 11.7268 14.3937 11.5229 14.5215 11.272C14.6668 10.9868 14.6668 10.6134 14.6668 9.86667V4.13333C14.6668 3.3866 14.6668 3.01323 14.5215 2.72801C14.3937 2.47713 14.1897 2.27316 13.9388 2.14532C13.6536 2 13.2802 2 12.5335 2H12.2668C10.7734 2 10.0266 2 9.45619 2.29065C8.95442 2.54631 8.54647 2.95426 8.29081 3.45603C8.00016 4.02646 8.00016 4.77319 8.00016 6.26667"
              stroke="#667085"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Khoá học của tôi</span>
        </SafeLink>
      ),
    },
    {
      key: "3",
      label: (
        <SafeLink
          className="account__menu__item flex align-center gap-8"
          rel="profile"
          to={routesConfig.savedCourses}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M10.7409 2C13.0891 2 14.6668 4.235 14.6668 6.32C14.6668 10.5425 8.11868 14 8.00016 14C7.88164 14 1.3335 10.5425 1.3335 6.32C1.3335 4.235 2.91127 2 5.25942 2C6.60757 2 7.48905 2.6825 8.00016 3.2825C8.51127 2.6825 9.39276 2 10.7409 2Z"
              stroke="#667085"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Khoá học đã lưu</span>
        </SafeLink>
      ),
    },
    {
      key: "4",
      label: (
        <SafeLink
          className="account__menu__item flex align-center gap-8"
          rel="profile"
          to={routesConfig.studySchedule}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M14 6.66671H2M10.6667 1.33337V4.00004M5.33333 1.33337V4.00004M5.2 14.6667H10.8C11.9201 14.6667 12.4802 14.6667 12.908 14.4487C13.2843 14.257 13.5903 13.951 13.782 13.5747C14 13.1469 14 12.5868 14 11.4667V5.86671C14 4.7466 14 4.18655 13.782 3.75873C13.5903 3.3824 13.2843 3.07644 12.908 2.88469C12.4802 2.66671 11.9201 2.66671 10.8 2.66671H5.2C4.0799 2.66671 3.51984 2.66671 3.09202 2.88469C2.71569 3.07644 2.40973 3.3824 2.21799 3.75873C2 4.18655 2 4.7466 2 5.86671V11.4667C2 12.5868 2 13.1469 2.21799 13.5747C2.40973 13.951 2.71569 14.257 3.09202 14.4487C3.51984 14.6667 4.0799 14.6667 5.2 14.6667Z"
              stroke="#667085"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Lịch học</span>
        </SafeLink>
      ),
    },
    {
      key: "5",
      label: (
        <SafeLink
          className="account__menu__item border-bottom flex align-center gap-8"
          rel="profile"
          to={`/${routesConfig.mySurveys}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M14 6.66671H2M10.6667 1.33337V4.00004M5.33333 1.33337V4.00004M5.2 14.6667H10.8C11.9201 14.6667 12.4802 14.6667 12.908 14.4487C13.2843 14.257 13.5903 13.951 13.782 13.5747C14 13.1469 14 12.5868 14 11.4667V5.86671C14 4.7466 14 4.18655 13.782 3.75873C13.5903 3.3824 13.2843 3.07644 12.908 2.88469C12.4802 2.66671 11.9201 2.66671 10.8 2.66671H5.2C4.0799 2.66671 3.51984 2.66671 3.09202 2.88469C2.71569 3.07644 2.40973 3.3824 2.21799 3.75873C2 4.18655 2 4.7466 2 5.86671V11.4667C2 12.5868 2 13.1469 2.21799 13.5747C2.40973 13.951 2.71569 14.257 3.09202 14.4487C3.51984 14.6667 4.0799 14.6667 5.2 14.6667Z"
              stroke="#667085"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Khảo sát của tôi</span>
        </SafeLink>
      ),
    },
    // {
    //   key: "6",
    //   label: (
    //     <SafeLink to="/setting" className="account__menu__item" rel="settings">
    //       <SettingOutlined />
    //       <span>Cài đặt</span>
    //     </SafeLink>
    //   ),
    // },
    {
      key: "7",
      label: (
        <a
          className="account__menu__item"
          rel="log-out"
          onClick={showConfirmLogout}
        >
          <LogoutOutlined />
          <span>Đăng xuất</span>
        </a>
      ),
    },
  ];

  const curriculumProgramsList: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <SafeLink
          className="account__menu__item"
          rel="account"
          to={routesConfig.curriculumPrograms}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              stroke="#7F56D9"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.7221 8.26596C15.2107 8.10312 15.4549 8.02169 15.6174 8.07962C15.7587 8.13003 15.87 8.24127 15.9204 8.38263C15.9783 8.54507 15.8969 8.78935 15.734 9.27789L14.2465 13.7405C14.2001 13.8797 14.1769 13.9492 14.1374 14.007C14.1024 14.0582 14.0582 14.1024 14.007 14.1374C13.9492 14.1769 13.8797 14.2001 13.7405 14.2465L9.27789 15.734C8.78935 15.8969 8.54507 15.9783 8.38263 15.9204C8.24127 15.87 8.13003 15.7587 8.07962 15.6174C8.02169 15.4549 8.10312 15.2107 8.26596 14.7221L9.75351 10.2595C9.79989 10.1203 9.82308 10.0508 9.8626 9.99299C9.8976 9.94182 9.94182 9.8976 9.99299 9.8626C10.0508 9.82308 10.1203 9.79989 10.2595 9.75351L14.7221 8.26596Z"
              stroke="#7F56D9"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="font-size-16 ml-1">
            <b>Chương trình học</b>
          </span>
        </SafeLink>
      ),
    },
    {
      key: "2",
      label: (
        <SafeLink
          className="account__menu__item"
          rel="account"
          to={routesConfig.courseInfo}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 21L11.8999 20.8499C11.2053 19.808 10.858 19.287 10.3991 18.9098C9.99286 18.5759 9.52476 18.3254 9.02161 18.1726C8.45325 18 7.82711 18 6.57482 18H5.2C4.07989 18 3.51984 18 3.09202 17.782C2.71569 17.5903 2.40973 17.2843 2.21799 16.908C2 16.4802 2 15.9201 2 14.8V6.2C2 5.07989 2 4.51984 2.21799 4.09202C2.40973 3.71569 2.71569 3.40973 3.09202 3.21799C3.51984 3 4.07989 3 5.2 3H5.6C7.84021 3 8.96031 3 9.81596 3.43597C10.5686 3.81947 11.1805 4.43139 11.564 5.18404C12 6.03968 12 7.15979 12 9.4M12 21V9.4M12 21L12.1001 20.8499C12.7947 19.808 13.142 19.287 13.6009 18.9098C14.0071 18.5759 14.4752 18.3254 14.9784 18.1726C15.5467 18 16.1729 18 17.4252 18H18.8C19.9201 18 20.4802 18 20.908 17.782C21.2843 17.5903 21.5903 17.2843 21.782 16.908C22 16.4802 22 15.9201 22 14.8V6.2C22 5.07989 22 4.51984 21.782 4.09202C21.5903 3.71569 21.2843 3.40973 20.908 3.21799C20.4802 3 19.9201 3 18.8 3H18.4C16.1598 3 15.0397 3 14.184 3.43597C13.4314 3.81947 12.8195 4.43139 12.436 5.18404C12 6.03968 12 7.15979 12 9.4"
              stroke="#7F56D9"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="font-size-16 ml-1">
            <b>Danh sách khóa học</b>
          </span>
        </SafeLink>
      ),
    },
    {
      key: "3",
      label: (
        <SafeLink
          className="account__menu__item"
          rel="account"
          to={routesConfig.searchSchool}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5 9.00002V17M9.5 9.00002V17M14.5 9.00002V17M19 9.00002V17M3 18.6L3 19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7952 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7952 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V18.6C21 18.04 21 17.7599 20.891 17.546C20.7951 17.3579 20.6422 17.2049 20.454 17.109C20.2401 17 19.9601 17 19.4 17H4.6C4.03995 17 3.75992 17 3.54601 17.109C3.35785 17.2049 3.20487 17.3579 3.10899 17.546C3 17.7599 3 18.04 3 18.6ZM11.6529 3.07715L4.25291 4.7216C3.80585 4.82094 3.58232 4.87062 3.41546 4.99082C3.26829 5.09685 3.15273 5.24092 3.08115 5.40759C3 5.59654 3 5.82553 3 6.28349L3 7.40002C3 7.96007 3 8.2401 3.10899 8.45401C3.20487 8.64217 3.35785 8.79515 3.54601 8.89103C3.75992 9.00002 4.03995 9.00002 4.6 9.00002H19.4C19.9601 9.00002 20.2401 9.00002 20.454 8.89103C20.6422 8.79515 20.7951 8.64217 20.891 8.45401C21 8.2401 21 7.96007 21 7.40002V6.2835C21 5.82553 21 5.59655 20.9188 5.40759C20.8473 5.24092 20.7317 5.09685 20.5845 4.99082C20.4177 4.87062 20.1942 4.82094 19.7471 4.7216L12.3471 3.07715C12.2176 3.04837 12.1528 3.03398 12.0874 3.02824C12.0292 3.02314 11.9708 3.02314 11.9126 3.02824C11.8472 3.03398 11.7824 3.04837 11.6529 3.07715Z"
              stroke="#7F56D9"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="font-size-16 ml-1">
            <b>Danh sách trường học</b>
          </span>
        </SafeLink>
      ),
    },
    {
      key: "4",
      label: (
        <SafeLink
          className="account__menu__item"
          rel="account"
          to={routesConfig.courseTopics}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M8.4 3H4.6C4.03995 3 3.75992 3 3.54601 3.10899C3.35785 3.20487 3.20487 3.35785 3.10899 3.54601C3 3.75992 3 4.03995 3 4.6V8.4C3 8.96005 3 9.24008 3.10899 9.45399C3.20487 9.64215 3.35785 9.79513 3.54601 9.89101C3.75992 10 4.03995 10 4.6 10H8.4C8.96005 10 9.24008 10 9.45399 9.89101C9.64215 9.79513 9.79513 9.64215 9.89101 9.45399C10 9.24008 10 8.96005 10 8.4V4.6C10 4.03995 10 3.75992 9.89101 3.54601C9.79513 3.35785 9.64215 3.20487 9.45399 3.10899C9.24008 3 8.96005 3 8.4 3Z"
              stroke="#7F56D9"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.4 3H15.6C15.0399 3 14.7599 3 14.546 3.10899C14.3578 3.20487 14.2049 3.35785 14.109 3.54601C14 3.75992 14 4.03995 14 4.6V8.4C14 8.96005 14 9.24008 14.109 9.45399C14.2049 9.64215 14.3578 9.79513 14.546 9.89101C14.7599 10 15.0399 10 15.6 10H19.4C19.9601 10 20.2401 10 20.454 9.89101C20.6422 9.79513 20.7951 9.64215 20.891 9.45399C21 9.24008 21 8.96005 21 8.4V4.6C21 4.03995 21 3.75992 20.891 3.54601C20.7951 3.35785 20.6422 3.20487 20.454 3.10899C20.2401 3 19.9601 3 19.4 3Z"
              stroke="#7F56D9"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.4 14H15.6C15.0399 14 14.7599 14 14.546 14.109C14.3578 14.2049 14.2049 14.3578 14.109 14.546C14 14.7599 14 15.0399 14 15.6V19.4C14 19.9601 14 20.2401 14.109 20.454C14.2049 20.6422 14.3578 20.7951 14.546 20.891C14.7599 21 15.0399 21 15.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V15.6C21 15.0399 21 14.7599 20.891 14.546C20.7951 14.3578 20.6422 14.2049 20.454 14.109C20.2401 14 19.9601 14 19.4 14Z"
              stroke="#7F56D9"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.4 14H4.6C4.03995 14 3.75992 14 3.54601 14.109C3.35785 14.2049 3.20487 14.3578 3.10899 14.546C3 14.7599 3 15.0399 3 15.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H8.4C8.96005 21 9.24008 21 9.45399 20.891C9.64215 20.7951 9.79513 20.6422 9.89101 20.454C10 20.2401 10 19.9601 10 19.4V15.6C10 15.0399 10 14.7599 9.89101 14.546C9.79513 14.3578 9.64215 14.2049 9.45399 14.109C9.24008 14 8.96005 14 8.4 14Z"
              stroke="#7F56D9"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="font-size-16 ml-1">
            <b>Học theo chủ đề</b>
          </span>
        </SafeLink>
      ),
    },
  ];

  useEffect(() => {
    if (isAuthenticated && infoUser.isLoginFirst) {
      setUserInfo({ ...infoUser, isLoginFirst: false });
    }
  }, [isAuthenticated, infoUser.isLoginFirst, infoUser]);
  return (
    <header
      className="header flex align-center"
      style={{ display: isFullscreenQuiz ? "none" : "flex" }}
    >
      <div className="header__container flex-1">
        <div className="header__left flex-1">
          <a className="logo-header" onClick={() => navigate("/")}>
            <img
              className="logo-icon"
              loading="eager"
              alt=""
              src={"../../assets/img/app-logo.png"}
            />
            <div className="logo-title">Mooc</div>
          </a>
          <nav>
            <ul>
              <li>
                <SafeLink to={routesConfig.systemInfo}>
                  <b>Giới thiệu</b>
                </SafeLink>
              </li>
              <li>
                <Dropdown
                  rootClassName="header-menu-dropdown"
                  menu={{ items: curriculumProgramsList }}
                >
                  <Typography.Link className="fontSemiBold">
                    <b>Khám phá</b> <DownOutlined className="ml-1" />{" "}
                  </Typography.Link>
                </Dropdown>
              </li>
            </ul>
          </nav>
        </div>
        <div className="icon-wrapper mr-1">
          <div
            className="pointer"
            onClick={() => confirmNavigation(routesConfig.support)}
          >
            <QuestionCircleOutlined className="font-size-18 text-secondary" />
          </div>
        </div>
        <div className="header__right flex align-center">
          {accessToken ? (
            <>
              <div className="toolbar">
                <Notification />
                <Dropdown
                  overlayClassName="header-account"
                  trigger={["click"]}
                  menu={{ items }}
                >
                  <div className="account">
                    <a onClick={(e) => e.preventDefault()}>
                      <Avatar src={infoUser.image} size={40}>
                        {getInitials(String(infoUser?.fullName))}
                      </Avatar>{" "}
                    </a>
                    <div className="account__name">
                      <a
                        className="account__name__wrapper"
                        onClick={(e) => e.preventDefault()}
                      ></a>
                    </div>
                  </div>
                </Dropdown>
              </div>
            </>
          ) : (
            <>
              <button
                className="register-button fontSemiBold"
                onClick={handleSignIn}
              >
                <span>Đăng nhập</span>
              </button>
              <button
                className="login-button fontSemiBold"
                onClick={handleSignUp}
              >
                <span>Đăng ký</span>
              </button>
            </>
          )}
        </div>
      </div>
      <Spin spinning={loading} fullscreen size="large" />
    </header>
  );
};

export default Header;
