// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mooc-std-banner {
  width: 100%;
  height: 438px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #53389E;
}`, "",{"version":3,"sources":["webpack://./src/components/banner/style.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":[".mooc-std-banner{\n    width: 100%;\n    height: 438px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: #53389E;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
