// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistics-chart {
  padding: 24px 0;
}
.statistics-chart .break-line {
  width: 100%;
  background-color: #eaecf0;
  height: 1px;
  margin: 24px 0;
}
.statistics-chart .chart-content-right {
  width: 100%;
  height: 300px;
  padding-bottom: 24px;
}
.statistics-chart .chart-content {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 24px;
}
.statistics-chart .x-title {
  transform: rotate(-90deg);
  transform-origin: left top;
  white-space: nowrap;
  height: max-content;
  width: max-content;
}
.statistics-chart .chart-in {
  margin-left: -35px;
}`, "",{"version":3,"sources":["webpack://./src/pages/statistics/course/style.scss"],"names":[],"mappings":"AACA;EACI,eAAA;AAAJ;AACI;EACI,WAAA;EACA,yBAAA;EACA,WAAA;EACA,cAAA;AACR;AAEI;EACI,WAAA;EACA,aAAA;EACA,oBAAA;AAAR;AAEI;EACI,yBAAA;EACA,kBAAA;EACA,aAAA;AAAR;AAEI;EACI,yBAAA;EACA,0BAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AAAR;AAEI;EACI,kBAAA;AAAR","sourcesContent":["\n.statistics-chart{\n    padding: 24px 0;\n    .break-line{\n        width: 100%;\n        background-color: #eaecf0;\n        height: 1px;\n        margin: 24px 0;\n    }\n\n    .chart-content-right{\n        width: 100%;\n        height: 300px;\n        padding-bottom: 24px;\n    }\n    .chart-content{\n        border: 1px solid #eaecf0;\n        border-radius: 8px;\n        padding: 24px;\n    }\n    .x-title{\n        transform: rotate(-90deg);\n        transform-origin: left top; \n        white-space: nowrap;\n        height: max-content;\n        width: max-content;\n    }\n    .chart-in{\n        margin-left: -35px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
