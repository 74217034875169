import React, { useEffect, useState } from "react";
import MoocBreadcrumb from "../../../components/breadcrumb";
import { HomeOutlined } from "@ant-design/icons";
import { routesConfig } from "../../../config/routes";
import CourseChart from "../../../components/charts/CourseChart";
import "./style.scss";
import { KeyValue } from "../../../types/app";
import {
  exportExcelCountByUniversity,
  exportExcelCountByYear,
  getChartCountByUniversities,
  getChartCountByYear,
  getStatisticalChartEnterprise,
} from "../../../service/statistics";
import { CourseDataChart } from "../../../types/statistic";
import "../style.scss";

function StatisticsCourses() {
  const [courseByYear, setCourseByYear] = useState<CourseDataChart[]>([]);
  const [courseByUniversity, setCourseByUniversity] = useState<
    CourseDataChart[]
  >([]);

  //---------------filter value---------------------------
  const [filterValueByYear, setFilterValueByYear] = useState<
    string | undefined
  >();
  const [filterValueByUniversity, setFilterValueByUniversity] = useState<
    string | undefined
  >();
  const [filterNameByUniversity, setFilterNameByUniversity] = useState<
    string | undefined
  >();
  //--------------------------------------------------------
  const breadcrumb = [
    {
      href: "/",
      title: <HomeOutlined />,
    },
    {
      href: routesConfig.courseInfo,
      title: <span className="breadcrumb-link">Danh sách khoá học</span>,
    },
    {
      href: routesConfig.statisticsCourses,
      title: (
        <span className="breadcrumb-link breadcrumb-link-active">
          <b> Thống kê số lượng khoá học</b>
        </span>
      ),
    },
  ];

  const options: KeyValue[] = [
    { label: "Mặc định", value: 0 },
    { label: "Thấp đến cao", value: 1 },
    { label: "Cao đến thấp", value: 2 },
  ];

  useEffect(() => {
    // Số lượng khoá học trên MOOC theo năm
    const getChartData = async () => {
      try {
        const res = await getChartCountByYear({
          sort: filterValueByYear,
        });
        setCourseByYear(res.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    getChartData();
  }, [filterValueByYear]);

  useEffect(() => {
    //Số lượng khoá học trên MOOC theo các trường
    const getChartData = async () => {
      try {
        const res = await getChartCountByUniversities({
          sortByCount: filterValueByUniversity,
          sortByName: filterNameByUniversity,
        });
        setCourseByUniversity(res.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    getChartData();
  }, [filterValueByUniversity, filterNameByUniversity]);

  const handleChangeCourseByYear = (value: number) => {
    switch (value) {
      case 0:
        setFilterValueByYear("default");
        break;
      case 1:
        setFilterValueByYear("asc");
        break;
      case 2:
        setFilterValueByYear("desc");
        break;
      default:
        setFilterValueByYear("default");
        break;
    }
  };

  const handleChangeCourseByUniversity = (value: number) => {
    switch (value) {
      case 0:
        setFilterValueByUniversity("default");
        setFilterNameByUniversity("default");
        break;
      case 1:
        setFilterValueByUniversity("asc");
        setFilterNameByUniversity("default");
        break;
      case 2:
        setFilterValueByUniversity("desc");
        setFilterNameByUniversity("default");
        break;
      case 3:
        setFilterValueByUniversity("default");
        setFilterNameByUniversity("desc");
        break;
      case 4:
        setFilterValueByUniversity("default");
        setFilterNameByUniversity("asc");
        break;
      default:
        setFilterValueByUniversity("default");
        setFilterNameByUniversity("default");
        break;
    }
  };

  const handleExportExcelByYear = async () => {
    try {
      const res = await exportExcelCountByYear(filterValueByYear);
      const fileData = res.data;

      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);

      downloadLink.download = "Số lượng khoá học theo năm.xlsx";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  const handleExportExcelByUniversity = async () => {
    try {
      const res = await exportExcelCountByUniversity({
        sortByCount: filterValueByUniversity,
        sortByName: filterNameByUniversity,
      });
      const fileData = res.data;

      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);

      downloadLink.download = "Số lượng khoá học theo các trường.xlsx";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  return (
    <div className="content-layout mb-5">
      <section>
        <MoocBreadcrumb items={breadcrumb} />
      </section>
      <section className="statistics-banner mb-5">
        <p className="text-primary mb-1">
          <b>Danh sách khoá học</b>
        </p>
        <div className="flex justify-space-between align-center gap-24 banner-content">
          <h3 className="font-size-48 font-weight-6">
            Thống kê số lượng khoá học
          </h3>
          <span className="text-secondary font-size-20">
            Tổng hợp các thông số về số lượng khoá học hiện có trên hệ thống
            Mooc
          </span>
        </div>
      </section>
      <section>
        <CourseChart
          selectOptions={options}
          data={courseByYear}
          xKey="year"
          yKey="count"
          tooltipText="khoá học"
          onChangeSort={handleChangeCourseByYear}
          onExportExcel={handleExportExcelByYear}
        />
        <CourseChart
          data={courseByUniversity}
          xKey="name"
          yKey="count"
          tooltipText="khoá học"
          selectOptions={[
            ...options,
            { label: "A - Z", value: 3 },
            { label: "Z - A", value: 4 },
          ]}
          title="Số lượng khoá học trên MOOC theo các trường"
          description="Thống kê số lượng khoá học trên Mooc của 50+ trường liên kết"
          xDescription="Đại học phát hành"
          onChangeSort={handleChangeCourseByUniversity}
          onExportExcel={handleExportExcelByUniversity}
        />
      </section>
    </div>
  );
}

export default StatisticsCourses;
