import React from "react";
import CustomPagination from "../../../../../components/pagination/Pagination";
import AssessmentsSummaryTable from "../assessments-summary-table/AssessmentsSummaryTable";

type Props = {
  totalPage: number;
  pageSize: number;
  pageNumber: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  data: any;
  isLoading: boolean;
  setSortBy: (data: any) => void;
  sortBy: any;
};
const Test = (props: Props) => {
  const {
    pageSize,
    pageNumber,
    setPageSize,
    setPageNumber,
    totalPage,
    data,
    isLoading,
    setSortBy,
    sortBy,
  } = props;

  return (
    <div className="assessments-summary-item">
      <AssessmentsSummaryTable
        tableData={data}
        isLoading={isLoading}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />

      {totalPage > 0 ? (
        <CustomPagination
          totalPage={totalPage}
          pageSize={pageSize}
          pageNumber={pageNumber}
          setPageSize={setPageSize}
          setPageNumber={setPageNumber}
        />
      ) : null}
    </div>
  );
};

export default Test;
