import { Avatar, Breadcrumb, Button, Dropdown, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import {
  DislikeActiveIcon,
  DislikeIcon,
  FeedbackIcon,
  LikeActiveIcon,
  LikeIcon,
} from "../icons";
import "./style.scss";

import { MenuProps } from "antd/lib";
import { useParams } from "react-router-dom";
import ExpandableText from "../../../../../components/expandable-Text/ExpandableText";
import { DISLIKE_REACTION, LIKE_REACTION } from "../../../../../constants";
import useUploadFile from "../../../../../hooks/useUploadFile";
import { pinDiscuss } from "../../../../../service/discussion";
import {
  globalStore,
  learningCourseStore,
  useAuthStore,
} from "../../../../../stores/stores";
import {
  CommentProps,
  courseInfoReply,
  ReactionCount,
  ReplyValue,
} from "../../../../../types/discussion";
import { getCleanText, getInitials } from "../../../../../utils/format";
import { notifySuccess } from "../../../../../utils/notification";
import { getTimeAgo } from "../../../../../utils/renderStarRating";
import { Circle } from "../../../../study-schedule/components/study-schedule-calendar/event-content";
import UploadToDiscuss from "./UploadToDiscuss";
const { TextArea } = Input;

interface ReplyProps {
  comment: CommentProps;
  replies?: CommentProps[];
  commentEditing?: CommentProps;
  onLike?: (value: CommentProps) => void;
  onDelete?: (value: CommentProps) => void;
  onDislike?: (value: CommentProps) => void;
  onSaveReply?: (value: ReplyValue) => void;
  onClickReply?: (value: CommentProps) => void;
  onSaveEditComment?: (value: ReplyValue) => void;
  onClickEdit?: (value: CommentProps | undefined) => void;
  courseInfo: courseInfoReply;
  unitId?: number;
  onReport?: (value: CommentProps) => void;
}

const Reply = ({
  comment,
  replies,
  commentEditing,
  onLike = () => {},
  onDelete = () => {},
  onDislike = () => {},
  onSaveReply = () => {},
  onClickEdit = () => {},
  onClickReply = () => {},
  onSaveEditComment = () => {},
  onReport = () => {},
  unitId,
  courseInfo,
}: ReplyProps) => {
  const { resetFiles } = useUploadFile();
  const { tab, setTab, setSectionId, setSequenceId, setUnitId } =
    learningCourseStore();
  const userInfo = useAuthStore((state) => state.user);
  const { id } = useParams();
  const { reload, setReload } = globalStore();
  //edit
  const [editText, setEditText] = useState<string>("");
  const [addAttachments, setAddAttachments] = useState<any>([]);
  const [attachmentsRemoved, setAttachmentsRemoved] = useState<number[]>([]);

  //reply comment
  const textAreaRef = useRef<any>(null);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<string>("");
  const [filteredReplies, setFilteredReplies] = useState<CommentProps[]>([]);

  // trạng thái edit discussion, comment
  const isEditing =
    commentEditing?.id === comment.id && commentEditing !== undefined;
  // số lượng like & dislike
  const countLike =
    comment.reactionCount?.find(
      (react: ReactionCount) => react.reactionType === LIKE_REACTION
    )?.count || 0;

  const countDislike =
    comment.reactionCount?.find(
      (react: ReactionCount) => react.reactionType === DISLIKE_REACTION
    )?.count || 0;

  // trạng thái like & dislike
  const isLike = comment?.myReaction === LIKE_REACTION;
  const isDislike = comment?.myReaction === DISLIKE_REACTION;

  // các file đính kèm
  const attachments = comment?.attachments;

  const handleClickReply = (comment: CommentProps) => {
    onClickReply(comment);
    setIsReplying(!isReplying);
    setReplyText(`@${comment.author?.name} `);
  };

  const handleClickEdit = (comment: CommentProps | undefined) => {
    setEditText(comment?.content as string);
    onClickEdit(comment);
  };

  const handleSaveReply = (reply: ReplyValue) => {
    onSaveReply(reply);
    setIsReplying(false);
    setReplyText("");
    resetFiles();
  };

  const handleSaveEditComment = (reply: ReplyValue) => {
    onSaveEditComment(reply);
    setEditText("");
    setAddAttachments([]);
    setAttachmentsRemoved([]);
    onClickEdit(undefined);
  };
  const handleLike = (comment: CommentProps) => {
    onLike(comment);
  };

  const handleDislike = (comment: CommentProps) => {
    onDislike(comment);
  };
  const handleDelete = (comment: CommentProps) => {
    onDelete(comment);
  };
  const handleReport = (comment: CommentProps) => {
    onReport(comment);
  };
  const removeDuplicate = (inputText: string, replaceString: string) => {
    const firstIndex = inputText.indexOf(replaceString);
    const beforeFirst = inputText.slice(0, firstIndex + replaceString.length);
    const afterFirst = inputText
      .slice(firstIndex + replaceString.length)
      .replace(new RegExp(replaceString, "g"), "");

    return beforeFirst + afterFirst;
  };
  const renderHighlightedText = (text: string, username: string) => {
    if (text?.includes(username)) {
      const parts = text.split(username);
      return (
        <span>
          {parts[0].endsWith("@") ? parts[0].slice(0, -1) : parts[0]}
          <span className="font-weight-6">{username}</span>
          {parts[1]}
        </span>
      );
    }
    return text;
  };

  useEffect(() => {
    if (isReplying) {
      setTimeout(() => {
        if (textAreaRef.current) {
          const textarea = textAreaRef.current.resizableTextArea
            .textArea as HTMLTextAreaElement;

          textarea.setSelectionRange(
            textarea.value.length,
            textarea.value.length
          );
          textarea.focus();
        }
        const targetElement = document.getElementById(
          `new-reply-${comment.id}`
        );
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 0);
    }
  }, [isReplying]);

  useEffect(() => {
    setFilteredReplies(replies || []);
  }, [replies]);
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <div onClick={() => handleClickEdit(comment)}>Chỉnh sửa</div>,
    },
    {
      key: "2",
      label: <div onClick={() => handleDelete(comment)}>Xoá</div>,
    },
  ];

  const handlePinDiscuss = async (params: any) => {
    const payload = {
      discussionId: params.discussionId,
      courseId: id,
      isPinned: !params.isPinned,
    };
    try {
      await pinDiscuss(payload);
      notifySuccess(
        `${!params.isPinned ? "Ghim" : "Bỏ ghim"} thảo luận thành công`
      );
      setReload(!reload);
    } catch (error) {}
  };

  return (
    <div className="comment">
      <div className="flex gap-16 w-full mt-4">
        <div className="root-comment">
          <Avatar src={comment.avatar} size={56} className="mt-2">
            {!comment.avatar &&
            comment?.author?.username &&
            comment?.author?.username?.length >= 2
              ? getInitials(
                  String(comment?.author?.firstName + comment?.author?.lastName)
                )
              : comment?.author?.username}
          </Avatar>

          {filteredReplies && filteredReplies?.length > 0 && (
            <div className="tree-line-horizontal-wrap">
              <div className="tree-line-horizontal"></div>
            </div>
          )}
        </div>
        {isEditing ? (
          <div className="edit-comment w-full">
            <div className="reply-input-text">
              <TextArea
                value={editText}
                rows={4}
                onChange={(e) => setEditText(getCleanText(e.target.value))}
                placeholder="Nội dung thảo luận của bạn"
              />
            </div>
            <div className="files-reply-list">
              <UploadToDiscuss
                fileData={attachments}
                mode="edit"
                setAttachmentsRemoved={(fileDel: number[]) =>
                  setAttachmentsRemoved(fileDel)
                }
                setAddAttachments={(file: any) => setAddAttachments(file)}
              />
            </div>
            <div className="hr-tag"></div>
            <div className="action-edit-discuss">
              <Button
                className="btn-cancel mt-1"
                onClick={() => {
                  setEditText("");
                  onClickEdit(undefined);
                  setAttachmentsRemoved([]);
                  setAddAttachments([]);
                }}
              >
                Huỷ
              </Button>
              <Button
                className="btn-save mt-1"
                onClick={() => {
                  handleSaveEditComment({
                    file: addAttachments,
                    replyText: editText,
                    comment: comment,
                    attachmentRemoved: attachmentsRemoved,
                  });
                }}
              >
                Lưu bình luận
              </Button>
            </div>
          </div>
        ) : (
          <div className="pb-4 w-full reply-content">
            <div className="cmt-head flex align-center justify-space-between mb-1">
              <div className="flex gap-8 align-center">
                <span className="text-user-name">
                  {comment.author?.name||`${comment.author?.firstName} ${comment.author?.lastName}` ||
                    comment.author?.username ||
                    comment.author?.email ||
                    "Không có tên"}
                  {comment?.isMine && <span className="ml-1">(Của tôi)</span>}
                </span>
                <Circle color="#344054" />
                <span className="text-time">
                  {comment.createdDate && getTimeAgo(comment.createdDate || "")}
                </span>
              </div>
              <div className="flex align-center">
                {!comment?.parentId ? (
                  <div
                    className="pointer"
                    onClick={() => handlePinDiscuss(comment)}
                  >
                    <img
                      src={`/assets/icons/${comment?.isPinned ? "pined-icon.svg" : "pin-icon.svg"}`}
                      alt="a"
                    />
                  </div>
                ) : null}

                {comment?.isMine ? (
                  <Dropdown
                    menu={{ items }}
                    placement="bottomLeft"
                    overlayClassName="reply-action-dropdown"
                  >
                    <Button type="text">
                      <img
                        className="avt-reply"
                        src={"/assets/icons/dots-vertical.svg"}
                        alt="avt"
                      />
                    </Button>
                  </Dropdown>
                ) : (
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: (
                            <div onClick={() => handleReport(comment)}>
                              Báo cáo
                            </div>
                          ),
                        },
                      ],
                    }}
                    placement="bottomLeft"
                    overlayClassName="reply-action-dropdown"
                  >
                    <Button type="text">
                      <img
                        className="avt-reply"
                        src={"/assets/icons/dots-vertical.svg"}
                        alt="avt"
                      />
                    </Button>
                  </Dropdown>
                )}
              </div>
            </div>
            {!unitId ? (
              <div className="discuss-location mb-2">
                <Breadcrumb>
                  <Breadcrumb.Item>{courseInfo.sectionName}</Breadcrumb.Item>
                  <Breadcrumb.Item>{courseInfo.sequenceName}</Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => {
                      setTab("1");
                      setSectionId(courseInfo.sectionId);
                      setSequenceId(courseInfo.sequenceId);
                      setUnitId(courseInfo.unitId);
                    }}
                    className="breadcrumb-unit"
                  >
                    {courseInfo.unitName}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            ) : null}

            <div>
              <div className="text-content">
                {/* {renderHighlightedText(
                  comment?.content as string,
                  comment?.author?.username as string
                )} */}
                <ExpandableText text={comment?.content} maxLines={5} />
              </div>
              <div className="comment-attachments">
                <UploadToDiscuss fileData={attachments} mode="view" />
              </div>
            </div>
            <div className="comment-action flex gap-32 mt-1 align-center">
              <div className="flex align-center gap-12">
                <span className="pointer" onClick={() => handleLike(comment)}>
                  {isLike ? <LikeActiveIcon /> : <LikeIcon />}
                </span>
                <div className="count-discuss">{countLike}</div>
              </div>
              {/* <div className="flex align-center gap-12">
                <span
                  className="pointer"
                  onClick={() => handleDislike(comment)}
                >
                  {isDislike ? <DislikeActiveIcon /> : <DislikeIcon />}
                </span>
                <div className="count-discuss">{countDislike}</div>
              </div> */}
              <div
                className="text-12 flex align-center gap-8 pointer"
                onClick={() => handleClickReply(comment)}
              >
                <FeedbackIcon />
                phản hồi
                <div className="count-discuss">
                  {
                    comment?.replies?.filter((reply: any) => !reply?.isReplying)
                      ?.length
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {filteredReplies && filteredReplies.length > 0 && (
        <div className="comment-replies">
          {filteredReplies.map((reply: CommentProps, index: number) => {
            if (reply.isReplying) {
              return (
                <div key={index}>
                  <div className="comment-reply-new">
                    <div className="flex gap-16">
                      <Avatar
                        src={userInfo?.anhDaiDien || ""}
                        size={56}
                        className="mt-2"
                        style={{ minWidth: "56px" }}
                      >
                        {!userInfo?.anhDaiDien &&
                        userInfo?.hoVaTen &&
                        userInfo?.hoVaTen?.length >= 2
                          ? getInitials(String(userInfo?.hoVaTen))
                          : userInfo?.hoVaTen}
                      </Avatar>

                      <div className="reply-input-text">
                        <div className="reply-input-form">
                          <TextArea
                            ref={textAreaRef}
                            value={replyText}
                            rows={4}
                            onChange={(e) =>
                              setReplyText(
                                removeDuplicate(
                                  e.target.value,
                                  // `@${comment?.author?.username} `
                                  `${comment.author?.name}`
                                )
                              )
                            }
                            id={`new-reply-${comment.id}`}
                          />
                          <UploadToDiscuss
                            fileData={addAttachments}
                            mode="add"
                            setAddAttachments={(file: any) =>
                              setAddAttachments(file)
                            }
                          />
                        </div>
                        <div className="hr-tag"></div>
                        <div className="comment-replies-action">
                          <Button
                            className="mt-1"
                            onClick={() => {
                              setReplyText("");
                              resetFiles();
                              setIsReplying(false);
                              const newFilteredReplies = (replies || []).filter(
                                (reply) => reply.level !== 4
                              );
                              setFilteredReplies(newFilteredReplies);
                            }}
                          >
                            Huỷ
                          </Button>
                          <Button
                            className="btn-primary mt-1"
                            onClick={() =>
                              handleSaveReply({
                                file: addAttachments,
                                replyText: replyText,
                                comment: comment,
                              })
                            }
                          >
                            Lưu phản hồi{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }

            return (
              <div className="comment-reply" key={reply.id}>
                <Reply
                  comment={reply}
                  replies={reply.replies}
                  onClickReply={(reply: CommentProps) =>
                    handleClickReply(reply)
                  }
                  courseInfo={courseInfo}
                  onSaveReply={(reply: ReplyValue) => handleSaveReply(reply)}
                  onSaveEditComment={(reply: ReplyValue) =>
                    handleSaveEditComment(reply)
                  }
                  commentEditing={commentEditing}
                  onClickEdit={(value: CommentProps | undefined) =>
                    handleClickEdit(value)
                  }
                  onLike={(reply: CommentProps) => handleLike(reply)}
                  onDislike={(reply: CommentProps) => handleDislike(reply)}
                  onDelete={(reply: CommentProps) => handleDelete(reply)}
                  unitId={unitId}
                  onReport={(reply: CommentProps) => handleReport(reply)}
                />
                {index !== filteredReplies?.length - 1 && (
                  <div className="reply-line-horizontal"></div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Reply;
