import React, { useEffect, useState } from "react";
import {
  BaseChartProps,
  ChartDataProps,
  StatisticChartPayload,
  StatisticChartResponseData,
} from "../../../../../types/statistic";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { fetchStarRatingExam } from "../../../../../service/statistics";
import BarChartHorizontal from "../../../../../components/charts/BarChartHorizontal";
import {
  getDataKeys,
  getLegends,
  mapToDataPercentChart,
} from "../../components/utils";
import { LegendItemProps } from "../../../../../components/charts/components/LegendCustom";

function RatingExam({ filterValues }: BaseChartProps) {
  const [starRatingExamValues, setStarRatingExamValues] =
    useState<StatisticChartResponseData>();

  useEffect(() => {
    
    if (!isEmpty(filterValues)) {
      // Tỷ lệ kết quả đánh giá học liệu theo lớp
      getStarRatingExam({
        classId: 1,
        from: filterValues?.date?.length
          ? dayjs(filterValues?.date[0]).toISOString()
          : undefined,
        to: filterValues?.date?.length
          ? dayjs(filterValues?.date[1]).toISOString()
          : undefined,
        courseIds: filterValues?.course,
        courseLevelIds: filterValues?.level,
        industryGroupIds: filterValues?.department,
      });
    }
  }, [filterValues]);

  const getStarRatingExam = async (payload: StatisticChartPayload) => {
    const res = await fetchStarRatingExam(payload);
    setStarRatingExamValues(res.data?.data);
  };

  return (
    <BarChartHorizontal
      data={mapToDataPercentChart(
        starRatingExamValues?.statistic as ChartDataProps[]
      )}
      yDataKey="criteria"
      xAxisType="percent"
      title="Tỉ lệ kết quả đánh giá học liệu theo lớp"
      total={starRatingExamValues?.total}
      colors={["#D9D6FE", "#BDB4FE", "#9B8AFB", "#7A5AF8", "#6938EF"]}
      dataKeys={getDataKeys(
        starRatingExamValues?.statistic as ChartDataProps[]
      )}
      legends={
        getLegends(
          getDataKeys(starRatingExamValues?.statistic as ChartDataProps[]),
          [
            "#6938EF",
            "#774ee9",
            "#7A5AF8",
            "#9B8AFB",
            "#BDB4FE",
            "#D9D6FE",
          ].reverse(),
          true
        ) as LegendItemProps[]
      }
    />
  );
}

export default React.memo(RatingExam);
