import { Breadcrumb, Button, Col, MenuProps, Row } from "antd";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import Banner from "../../components/banner/Banner";
var indexDB = require("idbcache").default;

import { DeleteOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import CourseCard from "../../components/card/CourseCard";
import EducationalProgramCard from "../../components/card/educational-program-card/EducationalProgramCard";
import ReviewCourseCard from "../../components/card/review-course/review-course";
import ReviewListModal from "../../components/comment-modal/review-list-modal/ReviewListModal";
import ResponsiveList from "../../components/list/ResponsiveList";
import { routesConfig } from "../../config/routes";
import ReviewCourseModal from "../../modal/review-course-modal";
import SuccessModal from "../../modal/success-modal";
import {
  deleteUniversityReviewReview,
  getAllCommentReview,
  getAllCourse,
  getAllCurriculum,
  getTrainingSchool,
} from "../../service/training-school";
import {
  globalStore,
  useAuthStore,
  useCourseDetailStore,
} from "../../stores/stores";
import { renderStarRating } from "../../utils/renderStarRating";
import CourseTab from "../course-evaluation/course-tab/CourseTab";
import "./index.scss";
type MenuItem = Required<MenuProps>["items"][number];

export default function CourseDetail() {
  const [reviewSchool, setReviewSchool] = useState(false);
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [isOpenReviewListModal, setIsOpenReviewListModal] = useState(false);
  const [isDeleteStatus, setIsDeleteStatus] = useState(false);
  const [listAllComment, setListAllComment] = useState<any>([]);
  const [listAllCourse, setListAllCourse] = useState<any>([]);
  const [listAllCurriculum, setListAllCurriculum] = useState<any>([]);
  const [dataCommonSchoolInfo, setDataCommonSchoolInfo] = useState<any>({});
  const [enterpriseUuid, setEnterpriseUuid] = useState<any>();
  const dataPath = location.search?.split("&slug=")
  const paramSearch = dataPath && dataPath[0].replace("?uuid=", "")
  const [contentModal, setContentModal] = useState<any>({
    title: "Cảm ơn bạn đã đánh giá cho chúng tôi",
    notice: "Đánh giá của bạn sẽ được hiển thị trên hệ thống",
  });

  const contentSuccessModal = {
    title: "Cảm ơn bạn đã đánh giá cho chúng tôi",
    notice: "Đánh giá của bạn sẽ được hiển thị trên hệ thống",
  };
  const params = useParams();
  const { setScreen, reload } = useCourseDetailStore();
  const accessToken = useAuthStore((state) => state.accessToken);
  const navigate = useNavigate();
  const { setSchoolId } = globalStore();

  const getDetailTemplatesByCoueseId = (template: any) => {
    // const webDisplay = JSON.parse(template)
    // let html = '';
    // html = webDisplay?.html;
    // html = html.replace(new RegExp('<body.*?>'), '').replace('</body>', '');
    // const style = document.createElement('style');
    // style.append(webDisplay?.css);
    // const head = document.querySelector('head');
    // const body = document.querySelector('body');
    // head?.appendChild(style);
    // const script = document.createElement("script");
    // script.src = "https://cdn.tailwindcss.com";
    // script.async = true;
    // document.body.appendChild(script);
    // setTimeout(() => {
    //   setHTML(html);
    // }, 100);
  };

  const getListAllCurriculum = async () => {
    await getAllCurriculum(dataPath[1] ?? "HUST").then(async (res) => {
      setListAllCurriculum(res.data);
    });
  };

  const getDetailSchool = async () => {
    await getTrainingSchool(dataPath[1] ?? "HUST").then(async (res) => {
      const data = {
        keyword: "",
        enterpriseUuid: res.data?.body?.uuid,
        starRating: 0,
        starSort: "desc",
        timeSort: "desc",
        page: 1,
        size: 6,
      };
      getDetailTemplatesByCoueseId(res.data?.body?.information);
      setDataCommonSchoolInfo(res.data?.body);
      setEnterpriseUuid(res.data?.body?.uuid);
      setSchoolId(res.data?.body?.uuid);

      await getAllCommentReview(data).then((res) => {
        setListAllComment(res.data?.data?.comments);
      });
      await getAllCourse(params.slug ?? "HUST").then((res) => {
        setListAllCourse(res.data);
      });
    });
  };

  const handleOkDelete = async (id: string) => {
    const objDelete = {
      title: "Bình luận của bạn về trường đã được xoá trên hệ thống!",
      notice: "",
    };
    const paramData = {
      id: id,
      enterpriseUuid: enterpriseUuid,
    };
    await deleteUniversityReviewReview(paramData).then((res) => {
      if (res.status === 200) {
        setContentModal(objDelete);
        setIsDeleteStatus(true);
        getDetailSchool();
      }
    });
  };

  useEffect(() => {
    if (isDeleteStatus) {
      setIsOpenSuccessModal(true);
    }
  }, [isDeleteStatus]);

  useEffect(() => {
    setScreen("school");
  }, []);

  const options1: MenuProps["items"] = [
    {
      key: "0",
      label: "Xóa đánh giá",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  const courses = listAllCourse?.map((item: any) => ({
    content: (
      <CourseCard key={item.id} {...item} id={item.id} title={item.name} />
    ),
  }));

  const coursesEditional = listAllCurriculum?.map((item: any) => ({
    content: (
      <EducationalProgramCard
        key={item.id}
        {...item}
        id={item.id}
        title={item.name}
      />
    ),
  }));

  const reviewCourse = listAllComment?.map((item: any) => ({
    content: (
      <ReviewCourseCard
        key={item.id}
        {...item}
        options={options1}
        handleOkDelete={handleOkDelete}
        enterpriseUuid={enterpriseUuid}
        getDetailSchool={getDetailSchool}
      />
    ),
  }));

  const saveReviewCourse = () => {
    setReviewSchool(false);
    setTimeout(() => {
      setIsOpenSuccessModal(true);
      getDetailSchool();
    }, 300);
  };

  useEffect(() => {
    // getDetailTemplatesByCoueseId()
    getListAllCurriculum();
    getDetailSchool();
  }, [reload]);

  const menuItem: MenuItem[] = [
    {
      label: "Giới thiệu",
      key: "introduction",
    },
    {
      label: "Nhận xét về trường",
      key: "school-review",
    },

    {
      label: "Khóa học",
      key: "course",
    },
    {
      label: "Chương trình học",
      key: "curriculum",
    },
  ];

  const checkDisplay = location.pathname.includes(routesConfig.universityTemplateDetail)

  return (
    <div className={`course-detail ${!checkDisplay ? 'flex' : 'w-full'}`}>
      <Row className="flex justify-center">
        {
          !checkDisplay ?
            <><Col
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
              xs={{ span: 24 }}
              className="z-1"
            >
              <Banner>
                <img src={dataCommonSchoolInfo?.coverImage} alt="" />
                <div className="box-avatar">
                  <img className="avatar-school" src={dataCommonSchoolInfo?.logo} />
                </div>
              </Banner>
            </Col><Col
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
              xs={{ span: 24 }}
              className="max1536 mt-5 mb-5"
            >
                <div className="">
                  <Breadcrumb
                    items={[
                      {
                        href: "",
                        title: (
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/icons/home-line.svg`} />
                        ),
                      },
                      {
                        title: "Danh sách trường đại học liên kết",
                      },
                    ]} />
                  <h1 className="fs-30 mt-2">
                    {dataCommonSchoolInfo?.enterpriseName?.toUpperCase()}
                  </h1>
                  <div className="font-size-16">
                    {dataCommonSchoolInfo?.enterpriseNameEn}
                  </div>
                  <p>{dataCommonSchoolInfo?.shortDescription}</p>
                  <div className="flex mt-5 w-full-100">
                    <div className="flex flex-wrap font-weight-6">
                      <img
                        className="avatar-star"
                        src={`${process.env.PUBLIC_URL}/assets/icons/star.svg`} />
                      <p className="underline text-purple ml-2">
                        {!isEmpty(dataCommonSchoolInfo)
                          ? dataCommonSchoolInfo?.starRate
                          : ""}{" "}
                        ({dataCommonSchoolInfo?.totalCmt} Bình luận)
                      </p>
                    </div>
                    <div className="flex ml-5">
                      <img
                        className="avatar-star"
                        src={`${process.env.PUBLIC_URL}/assets/icons/user-circle.svg`} />
                      <p className="ml-2">
                        {dataCommonSchoolInfo?.totalLearner} học viên
                      </p>
                    </div>
                    <div className="flex ml-5">
                      <img
                        className="avatar-star"
                        src={`${process.env.PUBLIC_URL}/assets/icons/book-open-01.svg`} />
                      <p className="ml-2">
                        {dataCommonSchoolInfo?.totalCourse} khoá học
                      </p>
                    </div>
                  </div>
                </div>
              </Col><Col
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xs={{ span: 24 }}
                className="max1536"
              >
                <CourseTab menuItem={menuItem} />
              </Col><Col
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xs={{ span: 24 }}
                className="max1536"
                id="introduction"
              >
                <div className="mt-5">
                  <div className="flex justify-between">
                    <div>
                      <div className="text-title-18">Giới thiệu</div>
                      <p>Về {dataCommonSchoolInfo?.enterpriseName}</p>
                    </div>
                  </div>
                  <hr className="mt-3" />
                </div>
                {/* <div className="content-course">
      {!isEmpty(html) ? parse(html) : <span></span>}
    </div> */}
              </Col></> : null}

        <Col
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xs={{ span: 24 }}
          className={`mt-10 ${!checkDisplay ? 'max1536' : ''} pt-3`}
          id="school-review"
        >
          <div className="">
            <div className="flex justify-between">
              <div>
                <div className="text-title-18">Nhận xét về trường</div>
                <p>Những đánh giá của học viên về trường</p>
              </div>
              {dataCommonSchoolInfo?.isCompleted &&
                !dataCommonSchoolInfo?.isCommented ? (
                <Button
                  className="height-44 primary-color primary-border"
                  onClick={() => setReviewSchool(true)}
                >
                  Viết nhận xét về trường
                </Button>
              ) : null}
            </div>
            <hr className="mt-3" />
          </div>

          <div className="flex justify-between s mt-5 p-5 review-block">
            <p>Trường đã được các bạn học viên đánh giá thế nào?</p>
            <div className="flex">
              <div className="flex ml-5">
                {renderStarRating(dataCommonSchoolInfo?.starRate)}
                <p className="ml-1">{dataCommonSchoolInfo?.starRate}</p>
              </div>
              <div className="flex ml-5">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/message-chat-square.svg`}
                  alt="card-img"
                  className="card-img"
                />{" "}
                <p className="ml-2">
                  {dataCommonSchoolInfo?.totalCmt} bình luận
                </p>
              </div>
            </div>
          </div>

          {!isEmpty(listAllComment) ? (
            <>
              <div className="list-course mt-10">
                <ResponsiveList
                  grid={{
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    xxl: 2,
                    gutter: 50,
                  }}
                  data={reviewCourse}
                />
              </div>
              <div className="w-full-100 flex justify-center flex align-center">
                <Button
                  className="btn-primary p-2 height-44 mt-3 mb-3 radius-8"
                  onClick={() => setIsOpenReviewListModal(true)}
                >
                  Xem thêm các đánh giá khác
                </Button>
              </div>
            </>
          ) : null}
        </Col>

        {
          !checkDisplay ?

            <><Col
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
              xs={{ span: 24 }}
              className="mt-10 max1536 pt-3 "
              id="course"
            >
              <div className="">
                <div className="text-title-18">Khoá học</div>
                <p>
                  Danh sách khoá học của Đại học Bách khoá Hà Nội trên hệ thống
                  Moocs
                </p>
                <hr className="mt-3" />
              </div>
              <div className="list-course">
                <ResponsiveList
                  grid={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4, gutter: 30 }}
                  data={courses} />
              </div>
              <div className="w-full-100 flex justify-center flex align-center">
                <Button
                  className="btn-primary p-2 height-44 mt-3 mb-3 radius-8"
                  onClick={() => navigate(routesConfig.courseInfo)}
                >
                  Xem thêm các khoá học khác
                </Button>
              </div>
            </Col><Col
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
              xs={{ span: 24 }}
              className="mt-10 max1536 pt-3"
              id="curriculum"
            >
                <div className="">
                  <div className="text-title-18">Chương trình học</div>
                  <p>
                    Danh sách chương trình học của Đại học Bách khoá Hà Nội trên hệ
                    thống Moocs
                  </p>
                  <hr className="mt-3" />
                </div>
                <div className="list-course mt-5">
                  <ResponsiveList
                    grid={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4, gutter: 30 }}
                    data={coursesEditional} />
                </div>
                <div className="w-full-100 flex justify-center flex align-center">
                  <Button
                    className="btn-primary p-2 height-44 mt-3 mb-10 radius-8"
                    onClick={() => navigate(routesConfig.curriculumPrograms)}
                  >
                    Xem thêm các chương trình học khác
                  </Button>
                </div>
              </Col></> : null}
      </Row>

      <ReviewCourseModal
        open={reviewSchool}
        title="Nhận xét về trường"
        handleCancel={() => setReviewSchool(false)}
        handleOk={() => saveReviewCourse()}
        enterpriseUuid={enterpriseUuid}
      />

      <SuccessModal
        open={isOpenSuccessModal}
        title=""
        desc={contentSuccessModal}
        isDeleteStatus={isDeleteStatus}
        handleCancel={() => setIsOpenSuccessModal(false)}
      />

      <ReviewListModal
        open={isOpenReviewListModal}
        title="trường"
        handleCancel={() => setIsOpenReviewListModal(false)}
        enterpriseUuid={enterpriseUuid}
        screen="school"
      />
    </div>
  );
}
