import "../login-page/login-page.scss";
import { Button, Checkbox, Spin } from "antd";
import {
  CustomCaptcha,
  CustomCaptchaElement,
} from "../../../../components/captcha/CustomCaptcha";
import FormInput from "../../../../components/form-input-login/FormInput";
import { useAuthStore, useUserInfoStore } from "../../../../stores/stores";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { getRefreshToken, postLogin } from "../login-page/services";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../../config/routes";
import { getInfoLoginUser } from "../../../../service/login";
import LoginAlert from "../../../../components/alert/LoginAlert";
import Countdown from "../../../../components/countdown/CountDown";
import useModalConfirm from "../../../../hooks/useModalConfirm";
import { notifyError } from "../../../../utils/notification";
import ModalLoginFace from "../login-page/ModalLoginFace";
import { FaceIdIcon } from "../../../../components/icons";

const Cookies = require("js-cookie");
export const LoginCard = (
  loadingLogin: boolean,
  setLoadingLogin: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { login, refreshToken } = useAuthStore((state) => {
    return {
      login: state.login,
      refreshToken: state.refreshToken,
    };
  });
  const [openLoginFace, setOpenLoginFace] = useState(false);
  const showConfirm = useModalConfirm();
  const rememberInfo = useAuthStore((state) => state.rememberInfo);
  const saveUserInfo = useAuthStore((state) => state.saveUserInfo);
  const setExpiresIn = useAuthStore((state) => state.setExpiresIn);
  const accountInfo = JSON.parse(
    localStorage.getItem("auth-storage") as string
  );
  const isRemembered = accountInfo?.state?.isSaveInfo;

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [saveLogin, setSaveLogin] = useState(false);
  const [checkedCaptcha, setCheckedCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [countLoginFailed, setCountLoginFailed] = useState<number>(0);
  const [remainingLockSeconds, setRemainingLockSeconds] = useState<number>(0);

  const { infoUser, setUserInfo } = useUserInfoStore();
  const [isFirstLogin] = useState(infoUser?.isLoginFirst);
  let captchaRef = useRef<CustomCaptchaElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isRemembered) {
      setUserName(accountInfo?.state?.username);
      setSaveLogin(true);
    }
  }, []);

  const handleLoginWithFace = useCallback(async () => {
    if (!refreshToken) return;
    try {
      const response = await getRefreshToken({ refreshToken });
      if (response.status === 200) {
        await handleSaveDataAfterLogin(response);
      }
    } catch (error: any) {
      if (error?.response?.status === 502) {
        notifyError("Có lỗi xảy ra, vui lòng thử lại sau");
        setLoadingLogin(false);
        setCheckedCaptcha(false);
      } else {
        setErrorMessage(error?.response?.data?.message);
        setCheckedCaptcha(false);
        captchaRef.current?.forceUpdate();
        setLoadingLogin(false);
        setCountLoginFailed((prevState: number) => prevState + 1);
      }
    }
  }, [refreshToken]);

  const handleSaveDataAfterLogin = async (res: any) => {
    Cookies.set("accessToken", res?.data?.data?.accessToken);
    login(
      res?.data?.data?.accessToken,
      res?.data?.data?.refreshToken,
      res?.data?.data?.edxToken
    );
    setExpiresIn(res?.data?.data?.expiresIn);
    await getInfoLoginUser().then((res) => {
      saveUserInfo(res.data.data);
    });

    rememberInfo(saveLogin, userName);
  };
  const fetchData = async () => {
    await postLogin({
      username: userName,
      password: password,
      captchaResponse: captchaToken,
      moocSource: "sv",
    })
      .then(async (res) => {
        Cookies.set("accessToken", res?.data?.data?.accessToken);
        login(
          res?.data?.data?.accessToken,
          res?.data?.data?.refreshToken,
          res?.data?.data?.edxToken
        );
        setExpiresIn(res?.data?.data?.expiresIn);
        await getInfoLoginUser().then((res) => {
          saveUserInfo(res.data.data);
          setUserInfo(res.data.data);
        });

        rememberInfo(saveLogin, userName);

        if (countLoginFailed >= 3 && countLoginFailed <= 5) {
          showConfirm({
            title: "Cảnh báo đăng nhập!",
            canDestroy: false,
            description: (
              <span>
                Hệ thống ghi nhận bạn đã đăng nhập sai quá 03 lần. Để bảo vệ tài
                khoản, bạn có muốn <b>đặt lại mật khẩu</b> của tài khoản không?
              </span>
            ),
            okText: "Đặt lại mật khẩu",
            cancelText: "Đến trang chủ",
            type: "confirm",
            icon: (
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/warning-orange-icon.svg`}
              />
            ),
            onOk: () => {
              navigate(routesConfig.infoUser);
            },
            onCancel: () => {
              navigate(routesConfig.homePage);
            },
          });
        }
        await handleSaveDataAfterLogin(res);
      })
      .catch((err: any) => {
        if (err?.response?.status === 502) {
          notifyError("Có lỗi xảy ra, vui lòng thử lại sau");
        } else {
          setErrorMessage(
            err?.response?.data?.retryTime < 2
              ? `${err?.response?.data?.message}`
              : `${err?.response?.data?.message}, Đăng nhập thất bại ${err?.response?.data?.retryTime || 0}/5 lần`
          );
          captchaRef.current?.forceUpdate();
        }
        setCountLoginFailed(err?.response?.data?.retryTime);
        setRemainingLockSeconds(err?.response?.data?.remainingLockSeconds);
        setLoadingLogin(false);
        setCheckedCaptcha(false);
      });
  };
  const handleCloseLoginFace = () => {
    setOpenLoginFace(false);
  };
  const handleLogin = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (userName && password) {
      setLoadingLogin(true);
      fetchData();
    }
  };
  return (
    <div id="login-card" className={`login-card__item`}>
      {errorMessage && (
        <div className="mb-5">
          <LoginAlert
            message={errorMessage}
            onClose={() => setErrorMessage("")}
          />
        </div>
      )}

      <div className="title">
        <span className="title__big">Đăng nhập</span>
        <span className="title__description">
          Chào mừng bạn trở lại hệ thống MOOC!
        </span>
      </div>

      <Spin tip="Loading" size="large" spinning={loadingLogin}>
        <form
          id="login-form"
          className="form-and-button"
          onSubmit={handleLogin}
          autoComplete="off"
        >
          <div className="inputs">
            <FormInput
              disabled={remainingLockSeconds > 0}
              required={true}
              title="Email hoặc tên đăng nhập"
              inputPrefix="/inputprefix@2x.png"
              placeholder="Nhập email hoặc tên đăng nhập"
              errorInvalid="Email hoặc tên đăng nhập không hợp lệ."
              inputSuffix="/inputsuffix@2x.png"
              inputSuffixIcon={false}
              propMinWidth="7.56rem"
              value={userName}
              onChange={setUserName}
              id="email"
              autoComplete="off"
            />
            <div className="mt-2"></div>
            <FormInput
              disabled={remainingLockSeconds > 0}
              required={true}
              title="Mật khẩu"
              inputPrefix="/inputprefix-1@2x.png"
              placeholder="Nhập mật khẩu"
              inputSuffix="/inputsuffix-1@2x.png"
              inputSuffixIcon
              propMinWidth="2.81rem"
              propMinWidth1="4.19rem"
              isPassword={true}
              errorInvalid="Sai mật khẩu."
              value={password}
              onChange={setPassword}
              id="password"
              autoComplete="off"
            />
            <div className="checkbox-and-button1 mt-2">
              <div className="checkbox">
                <Checkbox
                  checked={saveLogin}
                  onChange={() => setSaveLogin(!saveLogin)}
                >
                  Ghi nhớ đăng nhập
                </Checkbox>
              </div>
              <div className="button10">
                <div className="icon-wrapper24">
                  <img
                    className="wrapper-icon40"
                    alt=""
                    src="/wrapper@2x.png"
                  />
                </div>
                <div
                  onClick={() => navigate(routesConfig.forgotPassword)}
                  className="text112"
                >
                  Quên mật khẩu
                </div>
              </div>
            </div>
          </div>

          <div className="captcha-container">
            <CustomCaptcha
              ref={captchaRef}
              setToken={setCaptchaToken}
              checked={checkedCaptcha}
              setChecked={setCheckedCaptcha}
            />
          </div>
          <div className="flex align-center w-full gap-8">
            <Button
              htmlType="submit"
              disabled={remainingLockSeconds > 0}
              className={`button11 btn-login flex-1`}
              type="primary"
              loading={loadingLogin}
            >
              {remainingLockSeconds <= 0 ? (
                "Đăng nhập"
              ) : (
                <span className="font-size-16 text-white font-weight-5">
                  Đăng nhập lại sau{" "}
                  <Countdown initialTime={remainingLockSeconds} />
                </span>
              )}
            </Button>
            {!isFirstLogin && (
              <Button
                size="large"
                onClick={() => setOpenLoginFace(true)}
                disabled={remainingLockSeconds > 0}
              >
                <FaceIdIcon />
              </Button>
            )}
          </div>
        </form>
        {openLoginFace && (
          <ModalLoginFace
            isOpen={openLoginFace}
            handleSubmit={handleLoginWithFace}
            handleClose={handleCloseLoginFace}
          />
        )}
        <div className="register-container">
          <div>Bạn chưa có tài khoản?&nbsp;</div>
          <div
            className="register-text"
            onClick={() => navigate(routesConfig.register)}
          >
            Đăng ký ngay!
          </div>
        </div>
      </Spin>
    </div>
  );
};
