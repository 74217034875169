import React, { useRef, useState, useEffect } from "react";
import { Slider, Flex } from "antd";
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import "./style.scss";

interface AudioPlayerProps {
  src: string;
  duration?: number;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src, duration = 0 }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [timeDown, setTimeDown] = useState<number>(0);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
      setTimeDown(duration - audio.currentTime);
    };

    audio.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (!audio) return;

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSliderChange = (value: number) => {
    const audio = audioRef.current;
    if (audio) audio.currentTime = value;

    setCurrentTime(value);
  };

  const displayTime = (time: number) => {
    if (time > 0) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes > 10 ? minutes : `0${minutes}`}:${seconds < 10 ? "0" + seconds : seconds}`;
    } else return "00:00";
  };

  const formatTime = (seconds?: number) => {
    if (seconds === undefined) return "0:00";
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div className="default-audio-player">
      <audio ref={audioRef} src={src} />

      <div className="audio-display">
        <Flex align="center" gap={8}>
          <div onClick={togglePlayPause} className="pointer control-btn">
            {isPlaying ? (
              <PauseCircleOutlined
                style={{ fontSize: 20 }}
                className="text-primary"
              />
            ) : (
              <PlayCircleOutlined
                style={{ fontSize: 20 }}
                className="text-primary"
              />
            )}
          </div>

          <div className="audio-slider flex-1 mr-2">
            <Slider
              min={0}
              step={0.000001}
              max={duration}
              value={currentTime}
              onChange={handleSliderChange}
              tooltip={{ formatter: formatTime }}
            />
          </div>

          <div>{displayTime(timeDown)}</div>
        </Flex>
      </div>
    </div>
  );
};

export default AudioPlayer;
