import React, { useState, useRef, useEffect } from "react";
import { Button, Popover } from "antd";
import "./TextViewer.scss";
import { learningCourseStore } from "../../../../../stores/stores";
import { scrollToElement } from "../../../../../utils/element";

interface Highlight {
  id: number;
  start: number | null;
  end: number | null;
  text: string | null;
  isNew?: boolean;
}

interface TextViewerProps {
  content: string;
}

const TextViewer: React.FC<TextViewerProps> = ({ content }) => {
  const [selection, setSelection] = useState<Range | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [highlightId, setHighlightId] = useState<number | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const { setHighlights, highlights, isAddMarked, noteId } =
    learningCourseStore();

  const handleMouseUp = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      if (range.toString().trim() !== "") {
        const rect = range.getBoundingClientRect();
        const contentNode = contentRef.current;

        if (contentNode) {
          const parentRect = contentNode.getBoundingClientRect();
          setTooltipPosition({
            x: rect.left - parentRect.left + contentNode.scrollLeft,
            y: rect.top - parentRect.top + contentNode.scrollTop + rect.height,
          });
        }

        setSelection(range);
        setPopoverVisible(false);

        if (!tooltipVisible) {
          setTooltipVisible(true);
        }
      } else {
        setTooltipVisible(false);
        setSelection(null);
      }
    }
  };

  const handleConfirmHighlight = () => {
    if (!isAddMarked) return;
    if (selection) {
      const { start, end, text } = getRangeDetails(selection);
      if (start !== end && text?.trim() !== "") {
        let newHighlight: Highlight;

        if (noteId !== null) {
          newHighlight = { id: noteId, start, end, text, isNew: true };
          removeHighlightFromDOM(noteId);
          const updatedHighlights = highlights.map((h) =>
            h.id === noteId ? newHighlight : h
          ) as Highlight[];

          setHighlights(updatedHighlights as any);
          setHighlightId(noteId);
        } else if (highlightId !== null) {
          newHighlight = { id: highlightId, start, end, text, isNew: true };
          removeHighlightFromDOM(highlightId);

          const updatedHighlights = highlights.map((h) =>
            h.id === highlightId ? newHighlight : h
          ) as Highlight[];

          setHighlights(updatedHighlights as any);
        } else {
          const newId = Math.max(...highlights.map((h) => h.id), 0) + 1;
          newHighlight = { id: newId, start, end, text, isNew: true };

          const updatedHighlights = [newHighlight, ...highlights];
          setHighlights(updatedHighlights as any);
          setHighlightId(newId);
        }

        setTimeout(() => {
          highlightTextByMarker(newHighlight);
        }, 0);
        window.getSelection()?.removeAllRanges();
        setTooltipVisible(false);
        setSelection(null);
      }
    }
  };

  const removeHighlightFromDOM = (id: number) => {
    const contentNode = contentRef.current;
    if (contentNode) {
      const marks = contentNode.querySelectorAll(`mark[data-id='${id}']`);
      marks.forEach((mark) => {
        const parent = mark.parentNode;
        if (parent) {
          while (mark.firstChild) {
            parent.insertBefore(mark.firstChild, mark);
          }
          parent.removeChild(mark);
          parent.normalize();
        }
      });
    }
  };

  const getRangeDetails = (range: Range) => {
    let start = 0;
    let end = 0;
    let text = range.toString();
    const contentNode = contentRef.current;
    if (contentNode) {
      const walker = document.createTreeWalker(
        contentNode,
        NodeFilter.SHOW_TEXT,
        null
      );
      let charCount = 0;
      while (walker.nextNode()) {
        const currentNode = walker.currentNode as Text;
        if (currentNode === range.startContainer) {
          start = charCount + range.startOffset;
        }
        if (currentNode === range.endContainer) {
          end = charCount + range.endOffset;
          break;
        }
        charCount += currentNode.length;
      }
    }
    return { start, end, text };
  };

  const highlightTextByMarker = (marker: Highlight) => {
    if (marker.start === null || marker.end === null) return;

    const range = document.createRange();
    const contentNode = contentRef.current;

    if (contentNode) {
      let charCount = 0;
      let startNode: Node | null = null;
      let endNode: Node | null = null;
      let startOffset = 0;
      let endOffset = 0;

      const walker = document.createTreeWalker(
        contentNode,
        NodeFilter.SHOW_TEXT,
        null
      );

      while (walker.nextNode()) {
        const currentNode = walker.currentNode as Text;
        const nextCharCount = charCount + currentNode.length;

        if (
          !startNode &&
          marker.start >= charCount &&
          marker.start < nextCharCount
        ) {
          startNode = currentNode;
          startOffset = marker.start - charCount;
        }

        if (!endNode && marker.end > charCount && marker.end <= nextCharCount) {
          endNode = currentNode;
          endOffset = marker.end - charCount;
          break;
        }

        charCount = nextCharCount;
      }

      if (startNode && endNode) {
        range.setStart(startNode, startOffset);
        range.setEnd(endNode, endOffset);
        highlightRange(
          range,
          marker.text || "",
          marker.start,
          marker.end,
          marker.id.toString()
        );
      }
    }
  };

  const highlightRange = (
    range: Range,
    content: string,
    start: number,
    end: number,
    id: string
  ) => {
    const startContainer = range.startContainer;
    const endContainer = range.endContainer;

    if (
      startContainer.nodeType === Node.TEXT_NODE &&
      endContainer.nodeType === Node.TEXT_NODE
    ) {
      const textNode = startContainer as Text;
      const parentNode = textNode.parentNode;

      if (textNode.textContent) {
        const beforeText = textNode.textContent.slice(0, range.startOffset);
        const highlightText = textNode.textContent.slice(
          range.startOffset,
          range.endOffset
        );
        const afterText = textNode.textContent.slice(range.endOffset);
        const beforeNode = document.createTextNode(beforeText);
        const afterNode = document.createTextNode(afterText);
        const mark = document.createElement("mark");
        mark.textContent = highlightText;
        mark.dataset.id = id;

        mark.addEventListener("click", (e) =>
          handleMarkerClick(e, content, start, end, id)
        );

        if (parentNode) {
          parentNode.insertBefore(beforeNode, textNode);
          parentNode.insertBefore(mark, textNode);
          parentNode.insertBefore(afterNode, textNode);
          parentNode.removeChild(textNode);
        }
      }
    } else {
      const commonAncestor = range.commonAncestorContainer;
      const walker = document.createTreeWalker(
        commonAncestor,
        NodeFilter.SHOW_TEXT,
        null
      );

      let currentNode = walker.currentNode as Text;
      while (currentNode) {
        if (range.intersectsNode(currentNode)) {
          const nodeRange = document.createRange();
          nodeRange.selectNodeContents(currentNode);

          if (currentNode === startContainer) {
            nodeRange.setStart(currentNode, range.startOffset);
          }

          if (currentNode === endContainer) {
            nodeRange.setEnd(currentNode, range.endOffset);
          }

          const fragment = nodeRange.extractContents();

          const mark = document.createElement("mark");
          mark.appendChild(fragment);
          mark.dataset.id = id;

          mark.addEventListener("click", (e) =>
            handleMarkerClick(e, content, start, end, id)
          );

          nodeRange.insertNode(mark);
        }
        currentNode = walker.nextNode() as Text;
      }
    }
  };

  const applyHighlightsToDOM = (
    contentNode: HTMLElement,
    highlights: Highlight[]
  ) => {
    highlights.forEach((highlight) => {
      if (highlight.start === null || highlight.end === null) return;

      const walker = document.createTreeWalker(
        contentNode,
        NodeFilter.SHOW_TEXT,
        null
      );
      let charCount = 0;

      while (walker.nextNode()) {
        const currentNode = walker.currentNode as Text;
        const nextCharCount = charCount + currentNode.length;

        if (highlight.start < nextCharCount && highlight.end > charCount) {
          const range = document.createRange();

          const startOffset = Math.max(0, highlight.start - charCount);
          const endOffset = Math.min(
            currentNode.length,
            highlight.end - charCount
          );

          if (startOffset < endOffset) {
            range.setStart(currentNode, startOffset);
            range.setEnd(currentNode, endOffset);

            const mark = document.createElement("mark");
            mark.dataset.id = highlight.id.toString();
            mark.appendChild(range.extractContents());
            mark.addEventListener("click", (e) =>
              handleMarkerClick(
                e,
                highlight.text || "",
                highlight.start!,
                highlight.end!,
                highlight.id.toString()
              )
            );
            range.insertNode(mark);
            walker.nextNode();
          }
        }

        charCount = nextCharCount;
      }
    });
  };

  const handleMarkerClick = (
    event: MouseEvent,
    content: string,
    start: number,
    end: number,
    id: string
  ) => {
    if ((event.target as HTMLElement).tagName.toLowerCase() === "mark") {
      const rect = (event.target as HTMLElement).getBoundingClientRect();
      const scrollX = window.scrollX;
      const scrollY = window.scrollY;

      setTooltipPosition({
        x: rect.left + scrollX,
        y: rect.top + scrollY,
      });
      setHighlightId(Number(id));
      setPopoverVisible(true);
      setTooltipVisible(false);
    }
  };

  const handlePopoverContentClick = () => {
    scrollToElement(`note-${highlightId}`);
    setPopoverVisible(false);
  };

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement && highlights.length > 0) {
      applyHighlightsToDOM(contentElement, highlights);
    }
  }, [highlights]);

  useEffect(() => {
    const contentElement = contentRef.current;
    contentElement?.addEventListener("mouseup", handleMouseUp);
    return () => {
      contentElement?.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);
  return (
    <div
      className="text-viewer"
      ref={contentRef}
      style={{ userSelect: isAddMarked ? "auto" : "none" }}
    >
      <div dangerouslySetInnerHTML={{ __html: content }} />

      {/* Custom Tooltip */}
      {tooltipVisible && (
        <div
          style={{
            position: "absolute",
            left: tooltipPosition.x,
            top: tooltipPosition.y,
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "8px",
            zIndex: 1000,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <Button type="primary" onClick={handleConfirmHighlight}>
            Xác nhận vùng chọn
          </Button>
          <Button onClick={() => setTooltipVisible(false)}>Hủy</Button>
        </div>
      )}

      {!isAddMarked && (
        <Popover
          content={
            <div onClick={handlePopoverContentClick}>
              <div className="multimedia-pop-content">
                <img src="/assets/icons/notification-text.svg" alt="" />
              </div>
            </div>
          }
          open={popoverVisible}
          placement="topRight"
          overlayStyle={{
            position: "absolute",
            left: tooltipPosition.x + 80,
            top: tooltipPosition.y - 40,
          }}
          trigger="click"
          onOpenChange={(visible) => setPopoverVisible(visible)}
          overlayClassName="text-viewer-popover"
        >
          <div
            style={{
              position: "absolute",
              left: tooltipPosition.x,
              top: tooltipPosition.y,
              height: 0,
              width: 0,
            }}
          ></div>
        </Popover>
      )}
    </div>
  );
};

export default TextViewer;
