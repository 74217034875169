import { Button, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import VoteStar from "../../components/vote-star";

type Props = {
  open: boolean;
  title: string;
  handleCancel: (value: any) => void;
  isDeleteStatus?: boolean;
  desc: any;
};

const SuccessModal = (props: Props) => {
  const { open, title, handleCancel, desc, isDeleteStatus } = props;

  return (
    <Modal
      title={
        <div>
          {!isDeleteStatus ? (
            <img
              className="success-icon"
              src={`${process.env.PUBLIC_URL}/assets/icons/success-icon.svg`}
            />
          ) : (
            <img
              className="success-icon"
              src={`${process.env.PUBLIC_URL}/assets/icons/success-delete-icon.svg`}
            />
          )}
          <img
            className="success-icon bg-icon-title"
            src={`${process.env.PUBLIC_URL}/assets/img/bg-head-modal.png`}
          />
        </div>
      }
      open={open}
      className="success-modal"
      maskClosable={false}
      // onOk={handleOk}
      width={"385px"}
      onCancel={handleCancel}
      centered
      footer={() => (
        <Button
          onClick={handleCancel}
          className="btn-primary p-2 height-44 mt-3 mb-5 radius-8 w-full-100"
        >
          Xác nhận
        </Button>
      )}
    >
      <div className="mt-5">
        <h1 className="fs-18">{desc.title}</h1>
        <p>{desc.notice}</p>
      </div>
    </Modal>
  );
};

export default SuccessModal;
