import { useEffect } from "react";
import { getCurrentChatBot } from "../../service/chat-bot";
import { triggerReloadChatbot } from "../../utils/triggerReloadChatbot";

const ChatbotComponent = () => {
  const fetchCurrentChatBot = async () => {
    try {
      const chatbotConfig = await getCurrentChatBot();
      if (!chatbotConfig?.data) return;
      const scriptConfig = document.createElement("script");
      scriptConfig.id = "difyChatbotConfig";
      scriptConfig.innerHTML = `
      window.difyChatbotConfig = {
       token: '${chatbotConfig.data.token}',
       isDev: true,
       baseUrl: '${chatbotConfig.data.url}'
      };
    `;
      triggerReloadChatbot();
      document.body.appendChild(scriptConfig);
      if (chatbotConfig.data.token) {
        const existedScript = document.getElementById(chatbotConfig.data.token);
        if (existedScript) {
          existedScript.remove();
          triggerReloadChatbot();
        }
      }
      const script = document.createElement("script");
      script.src = `${chatbotConfig.data.url}/embed.min.js`;
      script.id = chatbotConfig.data.token;
      script.defer = true;
      document.body.appendChild(script);
    } catch (error) {}
  };
  useEffect(() => {
    fetchCurrentChatBot();
  }, []);

  return (
    <div>
      <style>
        {`
          #dify-chatbot-bubble-button {
            background-color: #1C64F2 !important;
            bottom: 4rem !important;
          }
          #dify-chatbot-bubble-window{
            bottom: 7.5rem !important
          }
        `}
      </style>
    </div>
  );
};

export default ChatbotComponent;
