import { DownOutlined, SmileOutlined } from '@ant-design/icons'
import { Dropdown, MenuProps, Space } from 'antd'
import React from 'react'
import "./index.scss"
import { useLocation, useNavigate } from 'react-router-dom'
import { routesConfig } from '../../config/routes'

export default function ExploreSchool() {
  const location = useLocation()
  const navigate = useNavigate()
  const dataPath = location.search?.split("&slug=")
  const paramSearch = dataPath && dataPath[0].replace("?uuid=", "")
  const items: MenuProps['items'] = [
    {
      key: '0',
      label: (
        <p onClick={() => navigate(`${routesConfig.searchSchool}/${routesConfig.universityTemplateDetail}?uuid=${paramSearch}&slug=${dataPath[1]}`)}>
          Trang giới thiệu
        </p>
      ),
    },
    {
      key: '1',
      label: (
        <p onClick={() => navigate(`${routesConfig.searchSchool}/${routesConfig.universityCourseTemplateDetail}?uuid=${paramSearch}&slug=${dataPath[1]}`)}>
          Trang danh sách khóa học
        </p>
      ),
    },
    {
      key: '2',
      label: (
        <p onClick={() => navigate(`${routesConfig.searchSchool}/${routesConfig.universityCourseProgramTemplateDetail}?uuid=${paramSearch}&slug=${dataPath[1]}`)}>
          Trang danh sách chương trình học
        </p>
      ),
    },
  ];
  return (
    <div>
      {/* <a id="i067i" className="text-white hover:bg-red-100 hover:text-red-700 p-4" component-class="ExploreSchool">Khám phá 123</a> */}
      <Dropdown menu={{ items }} className='discover-school-course-template'>
        <a >
          <Space>
            Khám phá
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </div>
  )
}
