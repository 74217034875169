import { AxiosResponse } from "axios";
import { axiosConfigSupervisorToUpload } from "../../config/api/configApiSupervisor";

export const submitFaceVideoRecord: (payload: {
  data: FormData;
}) => Promise<AxiosResponse<void>> = (payload: { data: FormData }) => {
  const { data } = payload;
  return axiosConfigSupervisorToUpload.post(
    `/exam-supervision-result/create`,
    data
  );
};
export const getExamSupervisorSetting: (
  blockId: string
) => Promise<AxiosResponse<any>> = (blockId: string) => {
  return axiosConfigSupervisorToUpload.get(
    `/setting-exam-supervision/get-by-block/${blockId}`
  );
};
export const getAttendanceSetting: (
  courseId: string
) => Promise<AxiosResponse<any>> = (courseId: string) => {
  return axiosConfigSupervisorToUpload.get(
    `/setting-attendance/get/${courseId}`
  );
};
