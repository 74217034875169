import React from "react";
import "./detail.scss";
import { Card, Row, Col } from "antd";


interface SurveyDescriptionProps {
  surveyName: string,
  surveyDescription: string,
}

const SurveyDescription
  : React.FC<SurveyDescriptionProps> = ({ surveyName, surveyDescription }) => {

    return (
      <Card
        key={surveyName}
        className="wrapper-settings--card form-setting--common notPadding"
      >
        <Row className="surveyContainer-row">
          <Row>
            <Col span={24} className="surveyDiscription">
              <Col span={24} className="surveyTitle">{surveyName}</Col>
              <div
                dangerouslySetInnerHTML={{
                  __html: ` ${surveyDescription ?? ""}`,
                }}
              />
            </Col>
          </Row>
        </Row>
      </Card>
    );
  };

export default SurveyDescription;
