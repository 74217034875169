import { CheckCircleFilled } from "@ant-design/icons";
import Receiver, { WidgetConfig } from "@mcaptcha/core-glue";
import { MCaptchaWidget, SiteKey } from "@mcaptcha/react-glue";
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./custom-captcha.scss";

export interface CustomCaptchaElement {
  forceUpdate: () => void;
}
type Props = {
  setToken: Dispatch<SetStateAction<string>>;
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
};
export const CustomCaptcha = forwardRef<CustomCaptchaElement, Props>(
  (_props, ref) => {
    useImperativeHandle(ref, () => ({
      forceUpdate() {
        setKey(Date.now());
      },
    }));
    let keyCaptcha: string = process.env.REACT_APP_CAPTCHA_KEY
      ? process.env.REACT_APP_CAPTCHA_KEY
      : "";
    let urlCaptcha: string = process.env.REACT_APP_CAPTCHA_URL
      ? process.env.REACT_APP_CAPTCHA_URL
      : "";
    const siteKey: SiteKey = {
      // key: "3DD7zOGgHbDK52PStwjdeNDLCfjVqiIH",
      key: keyCaptcha,
      instanceUrl: new URL(urlCaptcha),
    };
    // const [checked, setChecked] = useState(false);
    const [key, setKey] = useState(Date.now());
    const callback = (token: string) => {
      if (!_props.checked) {
        // const currentChecked = _props.checked;
        _props.setChecked(!_props.checked);
      }
      // r.setToken(token)
      if (token) _props.setToken(token);
    };

    const config: WidgetConfig = {
      siteKey,
    };

    const r = new Receiver(config, callback);
    r.listen();
    // r.destroy()
    useEffect(() => {
      if (_props.checked) {
        r.destroy();
      }
    }, [_props.checked]);
    return !_props.checked ? (
      <MCaptchaWidget {...config} key={key} />
    ) : (
      <div className="message-circle">
        <CheckCircleFilled className="icon-success-otp" />
        <div className="success-message-otp">Xác nhận thành công</div>
      </div>
    );
    // return(
    //   <MCaptchaWidget {...config} key={key} />
    // )
  }
);
