import React from "react";
import QuizPanelItem from "./QuizPanelItem";
import { QuizProps, QuizRequest } from "../../../../../../types/course";
import { isEmpty } from "lodash";

interface QuizPanelProps {
  quizzes?: QuizProps[];
  title?: string;
  active?: number;
  mode?: string;
  quizzesDisabled?: number[];
  totalQuizzes?: number;
  quizzesCompleted?: QuizRequest[];
  showCountCompleted?: boolean;
  onClick?: (v: QuizProps) => void;
}
function QuizPanel({
  title = "",
  quizzes = [],
  quizzesDisabled = [],
  active = 0,
  mode = "",
  totalQuizzes = 0,
  quizzesCompleted = [],
  showCountCompleted = true,
  onClick = () => {},
}: QuizPanelProps) {
  const renderCountOfCompleted = () => {
    if (mode === "result") {
      let count = 0;

      quizzes.forEach((quiz: QuizProps) => {
        if (!isEmpty(quiz?.result)) {
          count++;
        }
      });

      return count;
    } else {
      if (quizzesCompleted && quizzesCompleted?.length) {
        let count = 0;

        quizzesCompleted.forEach((quizRq: QuizRequest) => {
          if (quizzes.find((quiz: QuizProps) => quiz.id === quizRq.quizId)) {
            count++;
          }
        });
        return count;
      }
    }
    return 0;
  };
  return (
    <div className="quiz-panel mb-1">
      <div className="quiz-panel-title font-size-14 line-height-20 mb-2">
        <b>
          {title || "Không có tên"}{" "}
          {showCountCompleted && (
            <span>{`(${renderCountOfCompleted()}/${totalQuizzes})`}</span>
          )}
        </b>
      </div>
      <div className="quiz-panel-list">
        {quizzes.map((quiz: QuizProps, index) => (
          <QuizPanelItem
            onClick={() => {
              onClick(quiz);
            }}
            isDone={
              quizzesCompleted?.find(
                (quizReq: QuizRequest) => quizReq.quizId === quiz.id
              )
                ? true
                : false
            }
            disabled={quizzesDisabled?.includes(quiz?.id)}
            key={index}
            active={active === quiz?.id}
            displayNumber={index + 1}
            isCorrect={mode === "result" && quiz?.result?.isTrue === true}
            isIncorrect={
              mode === "result" &&
              (!quiz?.result || quiz?.result?.isTrue === false)
            }
          />
        ))}
      </div>
    </div>
  );
}

export default QuizPanel;
