import React from "react";
import "./style.scss";

interface UniversityCardProps {
  id?: number | string;
  name?: string;
  image?: string;
  slug?: string;
  gotoDetailSchool: (slug: string) => void
}

function UniversityCard({ image = "", name = "", slug= "", gotoDetailSchool }: UniversityCardProps) {
  return (
    <div className="university-card" onClick={() => gotoDetailSchool(slug)}>
      <img className="university-card-logo" src={image || "http://placehold.it/300x200"} alt="university-logo" />
      <h3 className="font-size-18">{name}</h3>
    </div>
  );
}

export default UniversityCard;
