import React from 'react';
import './verification-page.scss'
import {Button, Form, Typography} from 'antd';
import VerificationInput from "react-verification-input";
import {Link, useNavigate} from "react-router-dom";
import {routesConfig} from "../../config/routes";
import {ArrowLeftOutlined} from '@ant-design/icons';
import {deleteInfo} from "../../service/personal/personalApi";

const VerificationPage = () => {
  const navigate = useNavigate()

  const onFinish = (value: any) => {
    deleteInfo(value).then(res => {
      const {data, statusCode} = res.data

      if (statusCode === 200) {
        navigate(routesConfig.resultDelete)
      }

    })
  }

  return (
    <div className="wrapper wrapper-verification--page">
      <section className="container-lg">
        <div className="back-page">
          <Link to={routesConfig.deletePolicy}>
            <ArrowLeftOutlined/> Quay về trang trước
          </Link>
        </div>
        <div className="page-content">
          <div className="form-code">
            <div className="page-title">
              <Typography.Title className="title">
                Xác nhận Mã bảo mật
              </Typography.Title>
              <Typography.Text className="subtitle">
                Nhập mã bảo mật của bạn để thực hiện xoá tài khoản
              </Typography.Text>
            </div>
            <Form className="varification-code" onFinish={onFinish}>
              <Form.Item name="password">
                <div className="verfitication-input">
                  <VerificationInput placeholder={"0"} classNames={{
                    container: "container",
                    character: "character",
                    characterInactive: "character--inactive",
                    characterSelected: "character--selected",
                    characterFilled: "character--filled",
                  }}/>
                </div>
              </Form.Item>
              <Button className="btn-filled--danger" htmlType="submit">
                <Typography.Text>
                  Xóa tài khoản
                </Typography.Text>
              </Button>
            </Form>
            <div className="direction">
              <Typography.Text className="direction-note">
                Bạn quên mã bảo mật? <Link to="#">Hướng dẫn</Link>
              </Typography.Text>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VerificationPage;
