import { useEffect, useRef, useState } from "react";
import { Button, Flex, Modal, Spin, notification } from "antd";
import * as faceTool from "mooc-ai-tool";

import {
  CheckOutlined,
  ExclamationCircleOutlined,
  InfoOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import Webcam from "react-webcam";
import { HelpService } from "../../../../service/helper.service";
import { modelsPath } from "../../../../constants/modelsPath";
import { ekycRegisterUserCard } from "../../../../service/ekyc";
import { NotificationPlacement } from "antd/lib/notification/interface";
import { useIdentifyEkycStore } from "../../../../stores/stores";
import { CardType } from "../../../../constants/cardType";
import ModalIntroduce from "./ModalIntroduce";
import Title from "antd/es/typography/Title";

interface IPropsModal {
  type: "back" | "front";
  isOpen: boolean;
  handleClose: () => void;
  cardType: CardType[];
  title: string;
  setImage: (data: { url: string; type: CardType }) => void;
  isMobile?: boolean;
  sessionId?: string;
  configStep: any;
  configApi: any;
}
const DEFAULT_MESSAGE =
  "Vui lòng đặt giấy tờ của bạn gọn trong khung chụp. Đảm bảo thông tin đều hiển thị rõ ràng";
const ModalTakePhoto = ({
  type,
  isOpen,
  handleClose,
  title,
  setImage,
  cardType,
}: IPropsModal) => {
  const timeoutId = useRef<NodeJS.Timeout>();
  const captureTimeoutId = useRef<NodeJS.Timeout>();
  const updateIdentifyEkycData = useIdentifyEkycStore(
    (state) => state.updateIdentifyEkycData
  );
  const helpService = useRef(new HelpService());
  const [isLoadingModel, setIsLoadingModel] = useState(true);
  const webcamRef = useRef<Webcam>(null);
  const [isProcessing, setProcessing] = useState(false);
  const [typeOfCard, setTypeOfCard] = useState<CardType>();
  const [message, setMessage] = useState({
    code: -1,
    message: DEFAULT_MESSAGE,
  });
  const [isCardValid, setIsCardValid] = useState(false);
  const [videoConstraints] = useState({
    width: 640,
    height: 480,
    facingMode: "environment",
  });

  const [api] = notification.useNotification();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [currentImage, setCurrentImage] = useState<string | null>(null); // Biến lưu trữ URL của ảnh hiện tại
  const [isOpenIntroduce, setIsOpenIntroduce] = useState(false);
  const subTractRef = useRef<HTMLImageElement>(null);
  const stopCamera = () => {
    if (!webcamRef.current?.video) return;
    webcamRef.current.video.pause();
  };
  const countValid = useRef(0);

  const openNotification = (
    placement: NotificationPlacement,
    message: string
  ) => {
    api.open({
      message: message || "Đang thực hiện",
      icon: <Spin />,
      description: <div>Vui lòng chờ trong ít phút</div>,
      placement,
      duration: null,
      key: "notice",
    });
  };

  const handleRefreshPhoto = () => {
    if (isProcessing) return;
    setCurrentImage(null);
    setTypeOfCard(undefined);
    setMessage({ code: -1, message: DEFAULT_MESSAGE });
    webcamRef.current?.video?.play();
    setIsCardValid(false);
    timeoutId.current = setTimeout(() => {
      requestAnimationFrame(executeLoop);
    }, 1000);
  };
  const handleConfirm = async () => {
    if (webcamRef.current && webcamRef.current.video) {
      if (currentImage) {
        await handleSubmitCard(currentImage);
      } else {
        openNotification("top", "Không tìm thấy ảnh chụp");
      }
    }
  };
  const handleValidateCard = (status: number) => {
    let isValid = false;
    let currentCardType = null;
    switch (status) {
      case 0:
        setMessage({
          code: 0,
          message:
            "Vui lòng đặt giấy tờ của bạn gọn trong khung chụp. Đảm bảo thông tin đều hiển thị rõ ràng",
        });
        break;
      case 1:
        if (!cardType.includes(CardType.IDCARD)) {
          setMessage({
            code: 0,
            message: "Vui lòng chụp passport",
          });
          break;
        }
        if (type === "front") {
          isValid = true;
          currentCardType = CardType.IDCARD;
          setMessage({
            code: 1,
            message:
              "Ảnh của bạn đã được hệ thống ghi nhận. Vui lòng xác nhận kiểm tra trước khi sử dụng",
          });
        } else {
          setMessage({
            code: 0,
            message: "Vui lòng chụp mặt sau",
          });
        }
        break;
      case 2:
        if (!cardType.includes(CardType.IDCARD)) {
          setMessage({
            code: 0,
            message: "Vui lòng chụp passport",
          });
          break;
        }
        if (type === "back") {
          isValid = true;
          currentCardType = CardType.IDCARD;
          setMessage({
            code: 1,
            message:
              "Ảnh chụp của bạn đã được hệ thống ghi nhận. Vui lòng xác nhận kiểm tra trước khi sử dụng",
          });
        } else {
          setMessage({
            code: 0,
            message: "Vui lòng chụp mặt trước",
          });
        }
        break;
      case 17:
        if (!cardType.includes(CardType.IDCARD)) {
          setMessage({
            code: 0,
            message: "Vui lòng chụp passport",
          });
          break;
        }
        if (type === "front") {
          isValid = true;
          currentCardType = CardType.IDCARD;
          setMessage({
            code: 1,
            message:
              "Ảnh của bạn đã được hệ thống ghi nhận. Vui lòng xác nhận kiểm tra trước khi sử dụng",
          });
        } else {
          setMessage({
            code: 0,
            message: "Vui lòng chụp mặt sau",
          });
        }
        break;
      case 18:
        if (!cardType.includes(CardType.IDCARD)) {
          setMessage({
            code: 0,
            message: "Vui lòng chụp passport",
          });
          break;
        }
        if (type === "back") {
          isValid = true;
          currentCardType = CardType.IDCARD;
          setMessage({
            code: 1,
            message:
              "Ảnh chụp của bạn đã được hệ thống ghi nhận. Vui lòng xác nhận kiểm tra trước khi sử dụng",
          });
        } else {
          setMessage({
            code: 0,
            message: "Vui lòng chụp mặt trước",
          });
        }
        break;
      case 25:
        if (type === "back") {
          setMessage({
            code: 0,
            message: "Passport không có mặt sau",
          });
          break;
        }
        if (cardType.includes(CardType.PASSPORT)) {
          isValid = true;
          currentCardType = CardType.PASSPORT;
          setMessage({
            code: 1,
            message:
              "Ảnh chụp của bạn đã được hệ thống ghi nhận. Vui lòng xác nhận kiểm tra trước khi sử dụng",
          });
        } else {
          setMessage({
            code: 0,
            message: "Vui lòng chụp căn cước công dân",
          });
          break;
        }
        break;
      default:
        setMessage({
          code: 0,
          message: "Ảnh không hợp lệ",
        });
        break;
    }
    return { isValid, currentCardType };
  };
  const handleSubmitCard = async (dataURL: string) => {
    if (!dataURL || !typeOfCard) {
      openNotification("top", "Không tìm thấy ảnh chụp");
      return;
    }
    const response = await fetch(dataURL);
    const blobImage = await response.blob();
    const imageFile = new File(
      [blobImage],
      type === "front" ? "front.png" : "back.png",
      {
        type: "image/png",
      }
    );
    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("check_liveness", "true");
    try {
      setProcessing(true);
      const response = await ekycRegisterUserCard({ data: formData });
      const responseData = response.data;
      if (responseData.response_code === 200 && responseData.data) {
        if (typeOfCard === CardType.PASSPORT) {
          setImage({ url: dataURL, type: typeOfCard });
          helpService.current.successMessage(responseData.response_message);
          updateIdentifyEkycData(responseData.data);
          handleClose();
        } else if (type === "front" && typeOfCard === CardType.IDCARD) {
          setImage({ url: dataURL, type: typeOfCard });
          helpService.current.successMessage(responseData.response_message);
          handleClose();
        } else {
          setImage({ url: dataURL, type: typeOfCard });
          helpService.current.successMessage(responseData.response_message);
          updateIdentifyEkycData(responseData.data);
          handleClose();
        }
      } else {
        if (type === "front") {
          helpService.current.errorMessage(responseData.response_message);
        } else {
          helpService.current.errorMessage(responseData.response_message);
        }
      }
    } catch (error: any) {
      console.log(error?.message);
      helpService.current.errorMessage(error?.message || "Có lỗi xảy ra");
    }
    setProcessing(false);
  };
  const onLoadedMetadata = () => {
    if (webcamRef.current && webcamRef.current.video) {
      // Execute faceTool for the first time
      faceTool.initialCard(webcamRef.current.video, modelsPath[2]).then(() => {
        setIsLoadingModel(false);
        requestAnimationFrame(executeLoop);
      });
    } else {
      helpService.current.errorMessage("Không tìm thấy camera");
    }
  };
  const handleCapture = async (type: CardType) => {
    if (webcamRef.current?.video) {
      const base64Url = webcamRef.current.getScreenshot();
      setCurrentImage(base64Url);
      setTypeOfCard(type);
      clearTimeout(timeoutId.current);
      stopCamera();
    }
  };
  const executeLoop = async () => {
    if (webcamRef.current && webcamRef.current.video) {
      const { label_id, code, message } = await faceTool.executeCard();
      const { isValid, currentCardType } = handleValidateCard(label_id || -1);
      console.log("ss", countValid.current);
      timeoutId.current = setTimeout(() => {
        requestAnimationFrame(executeLoop);
      }, 1000);
      if (isValid && countValid.current < 3) {
        countValid.current += 1;
      }
      if (!isValid) {
        countValid.current = 0;
      }
      if (currentCardType && countValid.current >= 3) {
        handleCapture(currentCardType);
        setIsCardValid(isValid);
        countValid.current = 0;
      }
    }
  };
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
      clearTimeout(captureTimeoutId.current);
    };
  }, []);
  return (
    <Modal
      title={
        <Flex
          justify="space-between"
          className="relative -top-1.5"
          align="center"
        >
          <Title
            className="!text-lg items-center flex gap-2 !mb-0 !font-semibold"
            level={5}
          >
            {title}
          </Title>
          <ExclamationCircleOutlined
            className="mr-4 flex items-start cursor-pointer"
            onClick={() => setIsOpenIntroduce(true)}
          />
        </Flex>
      }
      centered
      open={isOpen}
      className="!w-auto"
      onCancel={handleClose}
      footer={
        <Flex justify="center" gap={8}>
          {currentImage ? (
            <Button className="flex-1" key="back" onClick={handleRefreshPhoto}>
              <Flex align="center" justify="center">
                <ReloadOutlined />
                <span className="pl-1">Chụp lại</span>
              </Flex>
            </Button>
          ) : null}
          {currentImage && (
            <Button
              className="flex-1"
              key="submit"
              type="primary"
              onClick={() => {
                if (isProcessing) return;
                if (currentImage) {
                  handleConfirm();
                }
              }}
            >
              <Flex align="center" justify="center" gap={8}>
                {!isProcessing ? (
                  <>
                    <CheckOutlined />
                    <span>{currentImage ? "Xác nhận" : ""}</span>
                  </>
                ) : (
                  <LoadingOutlined />
                )}
              </Flex>
            </Button>
          )}
        </Flex>
      }
    >
      <Flex
        gap={"16px"}
        align="center"
        className="border my-5 p-4 w-full font-semibold text-[#344054] rounded-xl"
      >
        {message.code === 0 && (
          <div className="relative top-1 mb-1 flex items-center justify-center pointer-events-none">
            <InfoOutlined style={{ color: "#D92D20" }} />
            <span className=" absolute inset-0 animate-signal border border-red-500 rounded-full"></span>
          </div>
        )}
        {message.code === 1 && (
          <div className="relative mb-1 top-1 flex items-center justify-center pointer-events-none">
            <CheckOutlined style={{ color: "#079455" }} />
            <span className=" absolute inset-0 animate-signal border border-green-500 rounded-full"></span>
          </div>
        )}
        <p className="w-full whitespace-normal">{message.message}</p>
      </Flex>
      <div style={{ textAlign: "center" }}>
        <Flex
          align="center"
          justify="center"
          className={`min-h-[480px] min-w-[640px] relative rounded-lg overflow-hidden`}
        >
          <img
            ref={subTractRef}
            src={"/assets/img/Subtract.png"}
            alt="subtract_image"
            className="absolute inset-0 h-full w-full z-20"
            id="card-overlayer"
          />
          {isCardValid ? (
            <div className="border-2 border-green-500 absolute top-[9.1%] bottom-[9.1%] left-[7.9%] right-[8%] rounded-3xl z-[99]"></div>
          ) : (
            <div className="border-2 border-red-500 absolute top-[9.1%] bottom-[9.1%] left-[7.9%] right-[8%] rounded-3xl z-[99]"></div>
          )}
          {isLoadingModel && (
            <div className="absolute z-20 inset-0 grid place-content-center">
              <LoadingOutlined
                className=" text-3xl"
                style={{ fontSize: "32px" }}
              />
            </div>
          )}

          <Webcam
            width={640}
            height={480}
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/png"
            videoConstraints={videoConstraints}
            onLoadedMetadata={onLoadedMetadata}
          />
          <canvas
            ref={canvasRef}
            width="600"
            height="326"
            style={{ display: "none" }}
          />
        </Flex>
      </div>
      <ModalIntroduce
        isOpen={isOpenIntroduce}
        handleClose={() => setIsOpenIntroduce(false)}
      />
    </Modal>
  );
};

export default ModalTakePhoto;
