"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.faceMesh = void 0;
const face_mesh_1 = require("@mediapipe/face_mesh");
exports.faceMesh = new face_mesh_1.FaceMesh({ locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
    } });
exports.faceMesh.setOptions({
    maxNumFaces: 3,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.5,
});
