import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { axiosConfigToUpload } from "../../config/api/configApiUpload";

export const postAllTopicByBlog: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfig.post(
    `v2/open-api/university-topic/get-all-by-blog`,
    payload
  );
};

export const postAllDiscussionTopic: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfig.post(
    `v2/open-api/mooc-university-topic-discussion/search`,
    payload
  );
};

export const getAllAuthorTopic: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(
    `v2/open-api/university-topic/get-all-author-topic`
  );
};

export const postDiscussionReactBlog: (payload: {
  id: number;
  reactionType: number;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/api/mooc-university-topic-discussion/react`, payload);
};

export const deleteDiscussionBlog: (id: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.delete(`/api/mooc-university-topic-discussion/delete/${id}`);
};

export const postCreateDiscussionBlog: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.post(`v2/api/mooc-university-topic-discussion/create`, payload);
};

export const postUpdateDiscussionBlog: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.put(
    `v2/api/mooc-university-topic-discussion/update-comment`,
    payload
  );
};

export const postReplyDiscussionBlog: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.post(`/v2/api/mooc-university-topic-discussion/reply`, payload);
};


//Topic action bellow 

export const followAll: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-university-topic/follow-alls`, data)
}

export const postTopicView: (topicId: any) => Promise<AxiosResponse<any>> = (
  topicId
) => {
  return axiosConfigV2.post(`api/mooc-university-topic-discussion/view/${topicId}`);
};

export const postReactTopic: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(`api/mooc-university-topic-discussion/react-topic`, payload);
}

export const postTopicFollow: (topicId: any) => Promise<AxiosResponse<any>> = (
  topicId
) => {
  return axiosConfigV2.post(`api/mooc-university-topic-discussion/follow/${topicId}`);
};

export const getTopicData:  (topicId: any) => Promise<AxiosResponse<any>> = (
  topicId
) => {
  return axiosConfig.get(`v2/open-api/university-topic/${topicId}`);
};

