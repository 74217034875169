import { Breadcrumb, Spin, Tabs, TabsProps } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import { usePreventRouteChange } from "../../hooks/usePreventRouteChange";
import {
  getAttendanceSetting,
  getExamSupervisorSetting,
} from "../../service/exam-supervisor";
import { getCourseContentMenu } from "../../service/learning-course";
import {
  examSupervisorStore,
  globalStore,
  learningCourseStore,
} from "../../stores/stores";
import AssessmentsSummary from "./assessments-summary";
import Discussion from "./discussion/Discussion";
import Document from "./Document/Document";
import LearningCourse from "./learning-course/LearningCourse";
import MyNotes from "./my-notes/MyNotes";
import "./style.scss";

type Props = {};

const LearningCourseStructure = (props: Props) => {
  const { confirmRedirect } = usePreventRouteChange();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { learningCourseData, setLearningCourseData, tab, setTab, setUnitId } =
    learningCourseStore();
  const { reload, setReload } = globalStore();
  const setExamSupervisorData = examSupervisorStore(
    (state) => state.setExamSupervisorData
  );
  const setExamSupervisorSetting = examSupervisorStore(
    (state) => state.setExamSupervisorSetting
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const { isFullscreenQuiz } = globalStore();

  const onChange = (key: string) => {
    confirmRedirect(() => {
      setTab(key);
      setUnitId(null);
      navigate(`${routesConfig.learningCourseStructure}/${id}`);
    });
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            confirmRedirect(() => {
              setTab("1");
              setUnitId(null);
              navigate(`${routesConfig.learningCourseStructure}/${id}`);
              setReload(!reload);
            });
          }}
        >
          Cấu trúc khóa học
        </div>
      ),
      children: <LearningCourse tab={tab} />,
    },
    {
      key: "2",
      label: "Bài tập/bài kiểm tra/bài thi",
      children: <AssessmentsSummary tab={tab} />,
    },
    {
      key: "3",
      label: "Tài liệu",
      children: <Document />,
    },
    {
      key: "4",
      label: "Ghi chú",
      children: <MyNotes tab={tab} />,
    },
    {
      key: "5",
      label: "Thảo luận",
      children: <Discussion tab={tab} />,
    },
  ];

  const breadcrumbItem = [
    {
      href: "",
      title: (
        <img src={`${process.env.PUBLIC_URL}/assets/icons/home-line.svg`} />
      ),
    },
    {
      href: routesConfig.myCourses,
      title: (
        <>
          <span>Khoá học của tôi</span>
        </>
      ),
    },
    { title: learningCourseData?.name },
  ];

  useEffect(() => {
    document.body.style.overflow = "scroll";
    if (isFirstLoad) {
      setIsLoading(true);
    }
    const fetchUniversities = async () => {
      try {
        const res = await getCourseContentMenu(Number(id));
        setLearningCourseData(res?.data.data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
        setIsFirstLoad(false);
      }
    };

    fetchUniversities();
  }, [reload]);

  useEffect(() => {
    const blockId = searchParams.get("blockId");
    if (blockId && id) {
      const fetchExamSupervisorData = async () => {
        try {
          const [res1, res2] = await Promise.all([
            getExamSupervisorSetting(blockId),
            getAttendanceSetting(id),
            // getAttendanceSetting(id),
          ]);
          setExamSupervisorData(res1?.data.data);
          setExamSupervisorSetting(res2?.data.data);
        } catch (err) {
          console.error(err);
        }
      };
      fetchExamSupervisorData();
    }
  }, [searchParams.get("blockId")]);
  return (
    <>
      {isLoading ? (
        <div className="learning-course-structure-loading">
          <Spin size="large" />
        </div>
      ) : (
        <div
          className={`learning-course-structure ${isFullscreenQuiz ? "quiz-full-screen" : ""}`}
        >
          <Breadcrumb items={breadcrumbItem} />

          <div className="text-title-30 mt-2 mb-1 learning-title">
            {learningCourseData?.name}
          </div>

          {learningCourseData?.endDate && (
            <div className="course-end-date">
              Ngày kết thúc khoá học:{" "}
              {dayjs(learningCourseData?.endDate).format("DD/MM/YYYY")}
            </div>
          )}
          <Tabs
            // defaultActiveKey="1"
            activeKey={tab}
            items={items}
            onChange={onChange}
            className="mt-4"
          />
        </div>
      )}
    </>
  );
};

export default LearningCourseStructure;
