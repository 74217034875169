import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function useKeyword(key = "keyword") {
  // const location = useLocation();
  // const [keyword, setKeyword] = useState<string>("");

  // function getKeywordValue(str: string) {
  //   const parts = str.split(`${key}=`);
  //   return parts[1];
  // }

  // useEffect(() => {
  //   setKeyword(getKeywordValue(location.search));
  // }, [location]);

  // return keyword ? decodeURIComponent(keyword) : "";
  const currentUrl = window.location.href;
  let urlParams = new URLSearchParams(new URL(currentUrl).search);
  return urlParams.get(key);
}

export default useKeyword;
