import React, { useEffect, useState } from "react";
import {
  BaseChartProps,
  StatisticBarChartResponseData,
  StatisticChartPayload,
} from "../../../../../types/statistic";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { fetchRateUnitReviewAndAccess } from "../../../../../service/statistics";
import BarChartVertical from "../../../../../components/charts/BarChartVertical";
import { DEFAULT_MODULE_GROUP, moduleGroupOptions } from "../../../../../constants/statistics";

function UnitReviewAndAccess({ filterValues }: BaseChartProps) {
  const [rateUnitReviewAndAccessValues, setRateUnitReviewAndAccessValues] =
    useState<StatisticBarChartResponseData[]>();
  const [moduleGroup, setModuleGroup] = useState<number>(DEFAULT_MODULE_GROUP);

  const totalBar = rateUnitReviewAndAccessValues?.reduce(
    (total, item) => total + item.columnValue,
    0
  );

  const totalLine = rateUnitReviewAndAccessValues?.reduce(
    (total, item) => total + item.lineValue,
    0
  );

  useEffect(() => {
    
    if (!isEmpty(filterValues)) {
      getRateUnitReviewAndAccess({
        timeUnit: filterValues?.unit || "day",
        from: filterValues?.date?.length
          ? dayjs(filterValues?.date[0]).toISOString()
          : undefined,
        to: filterValues?.date?.length
          ? dayjs(filterValues?.date[1]).toISOString()
          : undefined,
        moduleGroup: moduleGroup,
        courseIds: filterValues?.course,
        courseLevelIds: filterValues?.level,
        industryGroupIds: filterValues?.department,
      });
    }
  }, [filterValues, moduleGroup]);

  const getRateUnitReviewAndAccess = async (payload: StatisticChartPayload) => {
    const res = await fetchRateUnitReviewAndAccess(payload);
    setRateUnitReviewAndAccessValues(res.data?.data);
  };
  return (
    <BarChartVertical
      barDataKey="columnValue"
      lineName="Lượt truy cập"
      barName="Lượt làm đánh giá"
      lineDataKey="lineValue"
      xDataKey="criteria"
      filterOptions={moduleGroupOptions}
      data={rateUnitReviewAndAccessValues as any}
      // dataKeys={getDataKeyBarChart(rateUnitReviewAndAccessValues)}
      title="Thống kê số lượng đánh giá theo thời gian"
      total={[
        {
          label: "Tổng lượng làm đánh giá",
          value: totalBar,
        },
        {
          label: "Tổng lượt truy cập",
          value: totalLine,
        },
      ]}
      showFilter
      placeholder="Bài kiểm tra đánh giá"
      onChange={setModuleGroup}
    />
  );
}

export default React.memo(UnitReviewAndAccess);
