import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { usePreventRouteChange } from "../../hooks/usePreventRouteChange";

interface SafeLinkProps extends LinkProps {
  to: string;
}

const SafeLink = ({ to, children, ...props }: SafeLinkProps) => {
  const { confirmNavigation } = usePreventRouteChange();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    confirmNavigation(to);
  };

  return (
    <Link to={to} onClick={handleClick} {...props}>
      {children}
    </Link>
  );
};

export default SafeLink;
