import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import { App } from "antd";
import { DirtyProvider } from "./contexts/DirtyProvider";
import WebSocketComponent from "./components/websocket/WebSocketComponent";

function DefaultApp() {
  return (
    <App>
      <DirtyProvider>
        <WebSocketComponent>
          <RouterProvider router={router} />
        </WebSocketComponent>
      </DirtyProvider>
    </App>
  );
}

export default DefaultApp;
