import { Button } from "antd";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormInput from "../../../../components/form-input-login/FormInput";
import { CheckPrimary, CheckSecondary } from "../../../../components/icons";
import { routesConfig } from "../../../../config/routes";
import { postCreateAccount } from "../../../../service/login";
import { notifyError } from "../../../../utils/notification";
import "../../Register.scss";
import "./RegisterForm.scss";

export const RegisterForm = (props: any) => {
  const navigate = useNavigate();
  const [lengthCheck, setLengthCheck] = useState(false);
  const [syntaxCheck, setSyntaxCheck] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);
  const [nameCheck, setNameCheck] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState<string>("");
  const [customError, setCustomError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loadingLogin, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleRegister = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!emailCheck || !nameCheck || !lengthCheck || !syntaxCheck) {
      setCustomError("Vui lòng kiểm tra lại các thông tin đã nhập.");
      setLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      setCustomError("Mật khẩu xác nhận không trùng khớp");
      setLoading(false);
      return;
    }

    setCustomError("");
    setLoading(true);

    try {
      const payload = {
        fullName: name,
        email: email,
        password: newPassword,
      };
      await postCreateAccount(payload);
      navigate(routesConfig.authenticateRegister);
      sessionStorage.setItem("moocAuthenticateRegisterEmail", email);
      setLoading(false);
    } catch (error) {
      notifyError("Có lỗi xảy ra vui lòng thử lại sau");
      setErrorMessage((error as any)?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleSetEmail = (emailChange: string) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidSyntax = regex.test(emailChange);
    if (isValidSyntax) {
      setEmailCheck(true);
    } else {
      setEmailCheck(false);
    }
    setEmail(emailChange);
  };

  const handleSetName = (nameChange: string) => {
    const regex = /.{7,}/;
    const isValidSyntax = regex.test(nameChange);
    if (isValidSyntax) {
      setNameCheck(true);
    } else {
      setNameCheck(false);
    }
    setName(nameChange);
  };

  const handleOnChangePassword = (newPasswordChange: string) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]+$/;
    if (newPasswordChange.length >= 8 && newPasswordChange.length <= 25) {
      setLengthCheck(true);
    } else setLengthCheck(false);
    const isValidSyntax = regex.test(newPasswordChange);
    if (isValidSyntax) {
      setSyntaxCheck(true);
    } else {
      setSyntaxCheck(false);
    }
    setNewPassword(newPasswordChange);
  };
  return (
    <div className="register-form">
      <div className="register-form__container">
        <div className="register-form__header">
          <img
            className="register-icon"
            loading="eager"
            alt="img"
            src={"../../assets/img/app-logo.png"}
          />
          <span className="text-title-30">Đăng ký tài khoản</span>
          <span className="text-description-16 mt-12">
            Chào mừng bạn đến với hệ thống MOOC!
          </span>
          {errorMessage ? (
            <div className="error-register-mess">
              <div className="error-txt">Cảnh báo</div>
              {errorMessage}
            </div>
          ) : null}
        </div>
        <form
          className="form-and-button"
          id="update-password"
          onSubmit={handleRegister}
        >
          <div className="inputs">
            <FormInput
              required={true}
              icon={true}
              regexValue={/.{7,}/}
              title="Họ và tên"
              inputPrefix="/inputprefix-1@2x.png"
              placeholder="Nhập họ và tên của bạn"
              inputSuffix="a"
              inputSuffixIcon
              propMinWidth="2.81rem"
              propMinWidth1="4.19rem"
              isPassword={false}
              errorInvalid="Tên phải dài hơn 6 kí tự"
              value={name}
              onChange={(text: string) => handleSetName(text)}
            />
            <FormInput
              required={true}
              icon={true}
              regexValue={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
              title="Email"
              inputPrefix="/inputprefix-1@2x.png"
              placeholder="Nhập email của bạn"
              inputSuffix="/inputsuffix-1@2x.png"
              inputSuffixIcon
              propMinWidth="2.81rem"
              propMinWidth1="4.19rem"
              isPassword={false}
              errorInvalid="Lỗi định dạng (example@mail.com)"
              value={email}
              onChange={(text: string) => handleSetEmail(text)}
              autoComplete="off"
            />
            <FormInput
              required={true}
              icon={true}
              title="Mật khẩu"
              inputPrefix="/inputprefix-1@2x.png"
              placeholder="Nhập mật khẩu của bạn"
              inputSuffix="/inputsuffix-1@2x.png"
              inputSuffixIcon
              propMinWidth="2.81rem"
              propMinWidth1="4.19rem"
              // customError={customError}
              isPassword={true}
              errorInvalid="Mật khẩu không đúng định dạng."
              value={newPassword}
              onChange={(text: string) => handleOnChangePassword(text)}
              autoComplete="new-password"
            />
            <FormInput
              required={true}
              icon={true}
              title="Xác nhận mật khẩu"
              inputPrefix="/inputprefix-1@2x.png"
              placeholder="Xác nhận mật khẩu"
              inputSuffix="/inputsuffix-1@2x.png"
              inputSuffixIcon
              propMinWidth="2.81rem"
              propMinWidth1="4.19rem"
              customError={customError}
              isPassword={true}
              value={confirmPassword}
              onChange={setConfirmPassword}
            />
          </div>
          <div className="condition-container">
            <div
              className={`password-check-message ${
                lengthCheck ? "valid" : "invalid"
              }`}
            >
              {lengthCheck ? <CheckPrimary /> : <CheckSecondary />}
              Độ dài mật khẩu từ 8-25 ký tự
            </div>
            <div
              className={`password-check-message ${
                syntaxCheck ? "valid" : "invalid"
              }`}
            >
              {syntaxCheck ? <CheckPrimary /> : <CheckSecondary />}
              Bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt
            </div>
          </div>
          <Button
            htmlType="submit"
            className={`register-btn ${
              name && emailCheck && lengthCheck && syntaxCheck
                ? ""
                : "disable-button"
            }`}
            type="primary"
            loading={loadingLogin}
            disabled={name === "" || email == "" || newPassword === ""}
          >
            Đăng ký
          </Button>
        </form>
        <div className="mt-4">
          Bạn đã có tài khoản?{" "}
          <span
            className="font-weight-6 primary-color pointer"
            onClick={() => navigate(routesConfig.login)}
          >
            Đăng nhập
          </span>
        </div>
      </div>
    </div>
  );
};
