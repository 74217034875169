import { Button, Modal } from "antd";
import "./style.scss";

type Props = {
  open: boolean;
  title?: string;
  mode?: string;
  handleCancel: (value: any) => void;
  handleOk: () => void;
  desc: any;
};

const DeleteModal = (props: Props) => {
  const { open, title, handleCancel, desc, handleOk } = props;

  return (
    <Modal
      title={
        <div>
          <img
            className="success-icon"
            src={`${process.env.PUBLIC_URL}/assets/icons/delete-icon.svg`}
          />
          <img
            className="success-icon bg-icon-title"
            src={`${process.env.PUBLIC_URL}/assets/img/bg-head-modal.png`}
          />
        </div>
      }
      open={open}
      className="delete-modal"
      maskClosable={false}
      // onOk={handleOk}
      width={"385px"}
      onCancel={handleCancel}
      centered
      footer={() => (
        <div className="flex">
          <Button
            onClick={handleCancel}
            className="btn-primary p-2 height-44 mt-3 mb-2 radius-8 w-full-100 mr-4 button-default"
          >
            Huỷ
          </Button>
          <Button
            onClick={handleOk}
            className="btn-primary ml-1 p-2 height-44 mt-3 mb-2 radius-8 w-full-100 button-danger"
          >
            Xoá
          </Button>
        </div>
      )}
    >
      <div className="mt-5">
        <h1 className="fs-18">{desc.title}</h1>
        <p>{desc.notice}</p>
      </div>
    </Modal>
  );
};

export default DeleteModal;
