import "./detail.scss";
import { Input, Radio, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { Divider } from "antd";
import { useSurveyRespond } from "../../../../../../../stores/stores";
import { downloadFile } from "../../../../../../../service/my-survey/my-survey-service";


const QuestionType2 = (props: any) => {
  const questionAnswer = props.questionAnswer;
  const { updateAnswer } = useSurveyRespond();
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [updatedOptions, setUpdatedOptions] = useState<any[]>([]);
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const handleRadioChange = (optionId: number) => {
    setSelectedOption(optionId);
    setIsOtherSelected(false);
  };

  const handleOtherSelected = () => {
    setIsOtherSelected(true);
    setSelectedOption(null);
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }

  useEffect(() => {
    if (typeof questionAnswer === "number") {
      setSelectedOption(questionAnswer);
    } else if (typeof questionAnswer === "string") {
      setIsOtherSelected(true);
      setInputValue(questionAnswer);
    }
  }, []);

  const changePathToShow = async (input: string) => {
    try {
      const res = await downloadFile({
        filePath: input,
        destination: "",
      });
      return res.data; // Assuming res.data is the string you want
    } catch (error) {
      console.error("Error downloading file:", error);
      return ""; // Or some default value to handle the error
    }
  };

  useEffect(() => {
    // Cập nhật các đường dẫn hình ảnh
    const updateOptions = async () => {
      const updatedOptions = await Promise.all(
        props.listOptions.map(async (option: any) => {
          if (option.answerImage) {
            const newImagePath = await changePathToShow(option.answerImage);
            return { ...option, answerImage: newImagePath };
          } else {
            return option;
          }
        })
      );
      setUpdatedOptions(updatedOptions);
    };
    updateOptions();
  }, [props.listOptions]); // Chỉ gọi lại khi props.listOptions thay đổi

  useEffect(() => {
    updateAnswer(props.surveyId, props.sectionId, props.questionId, selectedOption)
  }, [selectedOption]);

  useEffect(() => {
    updateAnswer(props.surveyId, props.sectionId, props.questionId, inputValue)
  }, [inputValue]);

  return (
    <Row gutter={24} style={{ width: '100%' }}>
      {updatedOptions.map((option: any) => (
        !option.isOther && (<Col span={12} key={option.answerId}>
          <div className="containerImage"
            onClick={props.typeSurvey !== "2" ? () => handleRadioChange(option.answerId) : undefined}>
            <img src={option.answerImage} alt="description" style={{ cursor: 'pointer' }} />
          </div>
          <Radio
            key={option.answerId}
            checked={selectedOption === option.answerId}
            onChange={() => handleRadioChange(option.answerId)}
            disabled={props.typeSurvey === "2"}
          >
            {option.answerText}
          </Radio>
        </Col>
        )
      ))}
      {props.listOptions.map((option: any) => (
        option.isOther &&
        (
          <Col span={24} style={{ marginTop: "12px" }}>
            <Radio
              checked={isOtherSelected}
              onChange={handleOtherSelected}
              disabled={props.typeSurvey === "2"}
            >
              Khác
            </Radio>
            {isOtherSelected && <><Input placeholder="Vui lòng nhập..." value={inputValue} onChange={handleChangeInput} style={{ marginTop: "12px", marginBottom: "12px" }} disabled={props.typeSurvey === "2"} /></>}
            {!isOtherSelected && <Divider style={{ margin: "12px 0" }} />}
          </Col>
        )
      ))}
    </Row>
  );
};

export default QuestionType2;
