import {
  FileExcelOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Image, Upload } from "antd";
import React, { useState } from "react";

type Props = {
  fileList: any;
};

const AttachmentViewer = ({ fileList }: Props) => {
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<any>(null);
  const handlePreview = (file: any) => {
    const isImage =
      (file.type && file.type.startsWith("image/")) ||
      file?.attachmentType === "image";

    if (isImage) {
      setPreviewImage(file.url);
      setPreviewVisible(true);
    } else {
      window.open(file.url, "_blank");
    }
  };
  const customFileRender = (originNode: React.ReactNode, file: any) => {
    const isImage =
      (file.type && file.type.startsWith("image/")) ||
      file?.attachmentType === "image";

    const icon = getFileIcon(file.type || file?.attachmentType || "");
    const imageUrl = file?.url;

    return (
      <div
        className="custom-upload-list-item"
        onClick={() => handlePreview(file)}
      >
        <div className="custom-upload-list-item-icon">
          {isImage ? (
            <img
              src={imageUrl}
              alt="file"
              style={{ width: "50px", height: "50px" }}
            />
          ) : (
            icon
          )}
        </div>
        <div className="custom-upload-list-item-info">
          <span className="custom-upload-list-item-name">
            <div className="name">{file.name}</div>
          </span>
        </div>
      </div>
    );
  };
  const getFileIcon = (fileType: string) => {
    switch (fileType) {
      case "application/pdf":
      case "pdf":
        return (
          <img
            src={"/assets/icons/pdf-icon.svg"}
            alt="file"
            style={{ width: "48px", height: "48px" }}
          />
        );
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "word":
        return (
          <img
            src={"/assets/icons/word-icon.svg"}
            alt="file"
            style={{ width: "48px", height: "48px" }}
          />
        );
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return (
          <img
            src={"/assets/icons/pptx-icon.svg"}
            alt="file"
            style={{ width: "48px", height: "48px" }}
          />
        );
      case "application/vnd.ms-excel":
      case "ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return (
          <img
            src={"/assets/icons/xlsx-icon.svg"}
            alt="file"
            style={{ width: "48px", height: "48px" }}
          />
        );
      case "text/plain":
      case "text":
        return (
          <img
            src={"/assets/icons/txt-icon.svg"}
            alt="file"
            style={{ width: "48px", height: "48px" }}
          />
        );
      // case "image/jpeg":
      // case "image/png":
      // case "image/gif":
      //   return (
      //     <img
      //    src={URL.createObjectURL(new Blob([fileType], { type: fileType }))}
      //       alt="file"
      //       style={{ width: "48px", height: "48px" }}
      //     />
      //   );
      default:
        return (
          <UploadOutlined style={{ fontSize: "24px", color: "#8c8c8c" }} />
        );
    }
  };
  return (
    <div>
      <Upload
        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
        listType="picture"
        multiple
        itemRender={customFileRender}
        fileList={fileList}
        showUploadList={{ showPreviewIcon: true }}
      ></Upload>
      {previewVisible && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewVisible,
            onVisibleChange: (visible) => setPreviewVisible(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </div>
  );
};

export default AttachmentViewer;
