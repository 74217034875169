import React, { ReactNode } from "react";
import "./style.scss";

interface BannerProps {
  children: ReactNode;
}
function Banner({ children = <>Banner</> }: BannerProps) {
  return <div className="mooc-std-banner">{children}</div>;
}

export default Banner;
