// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-confirm-global .bg-icon-title {
  position: absolute;
  top: -3px;
  left: -120px;
  z-index: 0;
}
.modal-confirm-global .anticon {
  display: none;
}
.modal-confirm-global .ant-modal-content {
  overflow: hidden;
}
.modal-confirm-global .close-modal-icon {
  display: block;
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  font-size: 20px;
  opacity: 0.6;
}
.modal-confirm-global .footer-modal-confirm {
  padding: 10px;
}
.modal-confirm-global .d-none {
  display: none;
}
.modal-confirm-global .ant-modal-confirm-body {
  justify-content: flex-start !important;
}
.modal-confirm-global .ant-modal-confirm-btns {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 32px;
}`, "",{"version":3,"sources":["webpack://./src/hooks/modal-confirm.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,SAAA;EACA,YAAA;EACA,UAAA;AAAJ;AAGE;EACE,aAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAKE;EACE,cAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;AAHJ;AAME;EACE,aAAA;AAJJ;AAOE;EACE,aAAA;AALJ;AAQE;EACE,sCAAA;AANJ;AASE;EACE,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,gBAAA;AAPJ","sourcesContent":[".modal-confirm-global {\n  .bg-icon-title {\n    position: absolute;\n    top: -3px;\n    left: -120px;\n    z-index: 0;\n  }\n\n  .anticon {\n    display: none;\n  }\n\n  .ant-modal-content {\n    overflow: hidden;\n  }\n\n  .close-modal-icon {\n    display: block;\n    position: absolute;\n    right: 25px;\n    top: 25px;\n    cursor: pointer;\n    font-size: 20px;\n    opacity: 0.6;\n  }\n\n  .footer-modal-confirm {\n    padding: 10px;\n  }\n\n  .d-none {\n    display: none;\n  }\n\n  .ant-modal-confirm-body {\n    justify-content: flex-start !important;\n  }\n\n  .ant-modal-confirm-btns {\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n    margin-top: 32px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
