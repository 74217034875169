// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/assets/img/bg_line.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.take-photo-form .ant-image {
  width: 100%;
}

.identify-success-card {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}`, "",{"version":3,"sources":["webpack://./src/pages/identify-ekyc/identify-ekyc.scss"],"names":[],"mappings":"AACI;EACI,WAAA;AAAR;;AAGA;EACI,yDAAA;AAAJ","sourcesContent":[".take-photo-form{\n    .ant-image {\n        width: 100%;\n    }\n}\n.identify-success-card {\n    background-image: url('../../../public/assets/img/bg_line.png');\n}\n// @media screen and (max-width: 768px) {\n//     .take-photo-form{\n//         .ant-image {\n//             width: 33%;\n//         }\n//     }\n//   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
