import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  CartesianGrid,
} from "recharts";
import LegendCustom from "./components/LegendCustom";
import { ChartProps } from "../../types/chart";
import { Flex, Select } from "antd";
import { renderTotal } from "./components/ultis";
import { Circle } from "./components/icons";
import EmptyComponent from "../empty";
import { isEmpty } from "lodash";
import { DEFAULT_MODULE_GROUP } from "../../constants/statistics";

interface BarChartSingleHorizontalProps extends ChartProps {
  borderRadius?: number;
  xAxisType?: "number" | "percent";
  yDataKey?: string;
}
function BarChartSingleHorizontal({
  title = "",
  legends = [],
  total,
  showFilter = false,
  filterOptions = [],
  placeholder = "",
  defaultSelectedValue = DEFAULT_MODULE_GROUP,
  data,
  colors = [],
  dataKeys = [],
  xAxisType = "number",
  yDataKey = "",
  yDescription,
  onChange = () => {},
}: BarChartSingleHorizontalProps) {
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            background: "#fff",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          {payload?.map((item: any, index: number) => (
            <Flex key={index}>
              <Circle fill={colors[0]} />
              <p>{`${item.name}: ${item.value}`}</p>{" "}
            </Flex>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="wrapper-chart">
      <Flex justify="space-between">
        <div className="chart-title font-weight-5 font-size-18">{title}</div>
        {showFilter && (
          <Select
            defaultValue={defaultSelectedValue}
            className="h-40 w-filter"
            placeholder={placeholder}
            onChange={onChange}
            options={filterOptions}
          />
        )}
      </Flex>
      <div className="break-line"></div>
      {isEmpty(data) ? (
        <div className="h-default">
          <EmptyComponent description="Không có kết quả" />
        </div>
      ) : (
        <div>
          {renderTotal(total)}
          <Flex>
            <div className="center">
              <div className="font-weight-5 font-size-12 y-description">
                {yDescription}
              </div>
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart layout="vertical" data={data}>
                <XAxis
                  type="number"
                  tickFormatter={
                    xAxisType === "percent" ? (value) => `${value}%` : undefined
                  }
                />
                <YAxis
                  width={100}
                  tickMargin={10}
                  dataKey={yDataKey}
                  type="category"
                />
                <Tooltip content={<CustomTooltip />} />
                <CartesianGrid strokeDasharray="3 0" horizontal={false} />

                {dataKeys.map((dataKey: string, index: number) => (
                  <Bar
                    key={index}
                    dataKey={dataKey}
                    fill={colors[index]}
                    name={dataKey}
                    barSize={20}
                    radius={[0, 10, 10, 0]}
                    children={
                      dataKeys?.length - 1 === index ? (
                        <LabelList
                          className="font-weight-5"
                          dataKey="count"
                          position="right"
                        />
                      ) : null
                    }
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Flex>
          <LegendCustom items={legends} />
        </div>
      )}
    </div>
  );
}

export default BarChartSingleHorizontal;
