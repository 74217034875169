import { Button, Form, Input, Modal } from "antd";
import "./AccountDeleteModal.scss";
import { useEffect, useState } from "react";
import { GetProp } from "antd/lib";
import { OTPProps } from "antd/es/input/OTP";
import {
  useAppStore,
  useAuthStore,
  useUserInfoStore,
} from "../../../../../stores/stores";
import {
  deleteAccountByOtp,
  deleteAccountByPassword,
  getDeleteAccOtp,
} from "../../../../../service/personal/personalApi";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../../../config/routes";
import { postLogout } from "../../../../../service/login";
import { renderErrorMessageOtp } from "../../../../../utils/getPageFromLink";

type Props = {
  open: boolean;
  handleCancel?: () => void;
  title?: string;
  content?: string;
  btnCancel?: boolean;
  confirmBtntitle?: string;
  handleResetForm: () => void;
};

const AccountDeleteModal = (props: Props) => {
  const { open, handleCancel, handleResetForm } = props;
  const [form] = Form.useForm();
  const { infoUser } = useUserInfoStore();
  const navigate = useNavigate();

  const [useOTP, setUseOTP] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number | null>(60);
  const [errorOtp, setErrorOtp] = useState<any>();
  const [isResendOtp, setIsResendOtp] = useState<boolean>(true);
  const accessToken = useAuthStore((state) => state.accessToken);
  const logout = useAuthStore((state) => state.logout);

  const onChange: GetProp<typeof Input.OTP, "onChange"> = (text) => {};

  const sharedProps: OTPProps = {
    onChange,
  };

  const handleDeleteAccount = async () => {
    try {
      if (useOTP) {
        const response = await deleteAccountByOtp({
          otp: form.getFieldValue("otp"),
        });
      } else {
        const response = await deleteAccountByPassword({
          password: form.getFieldValue("password"),
        });
      }
      if (accessToken) {
        try {
          await postLogout(accessToken).then((res) => {
            logout();
          });
          navigate(routesConfig.login);
        } catch (error) {
          logout();
        }
      }
      navigate(routesConfig.deleteSuccess);
    } catch (error: any) {
      setErrorOtp(error.response.data.messageCode);
    }
  };

  const handleSendOtp = async () => {
    try {
      setIsResendOtp(true);
      const res = await getDeleteAccOtp({});
    } catch (error) {
    } finally {
      setIsResendOtp(false);
    }
    setCountdown(60);
  };

  useEffect(() => {
    form.resetFields();
    setCountdown(60);
    setUseOTP(false);
  }, [open]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown !== null) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev && prev > 0) {
            return prev - 1;
          } else {
            clearInterval(timer);
            return null;
          }
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  useEffect(() => {
    if (errorOtp === "OTP_FAIL_5_TURNS") {
      setTimeout(() => {
        handleCancel && handleCancel();
        handleResetForm();
      }, 2000);
    }
  }, [errorOtp]);

  return (
    <Modal
      open={open}
      title={
        <div className="title-container">
          <img
            className={useOTP ? "bg-icon-img-email" : "bg-icon-img"}
            loading="eager"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/bg_log-out-popup.png`}
          />

          <div className={useOTP ? "flex justify-center" : ""}>
            <img
              className="confirm-modal-icon"
              src={`${process.env.PUBLIC_URL}/assets/icons/${
                useOTP ? "email-primary.svg" : "warning.svg"
              }`}
              alt={"icon"}
            />
          </div>
        </div>
      }
      className="delete-account-modal"
      onCancel={() => {
        setUseOTP(false);
        if (handleCancel) {
          handleCancel();
        }
      }}
      centered
      footer={
        <div className="footer-action">
          <Button
            onClick={() => {
              setUseOTP(false);
              if (handleCancel) {
                handleCancel();
              }
            }}
            className="footer-action-cancel"
          >
            Huỷ bỏ
          </Button>

          <Button onClick={handleDeleteAccount} className="delete-account-btn">
            Xoá tài khoản
          </Button>
        </div>
      }
    >
      <Form form={form} onFinish={handleDeleteAccount}>
        {useOTP ? (
          <div className="otp-height">
            <div className="confirm-modal-title text-center">
              Kiểm tra Email của bạn
            </div>
            <div className="confirm-modal-content text-center">
              Mã bảo mật đã được gửi đến: {infoUser.email}
            </div>
            <div className="mt-3">
              <Form.Item name="otp">
                <Input.OTP length={6} {...sharedProps} />
              </Form.Item>
            </div>

            <div>
              {errorOtp && (
                <div className="error-message">
                  {renderErrorMessageOtp(errorOtp)}
                </div>
              )}
            </div>

            <div className="resend-otp">
              Gửi lại mã xác nhận:{" "}
              {countdown !== null ? (
                <span className="text-primary">{countdown}s</span>
              ) : (
                <Button
                  type="text"
                  loading={isResendOtp}
                  className="resend-otp-txt"
                  onClick={handleSendOtp}
                >
                  Gửi lại
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="password-height">
            <div className="confirm-modal-title">Xoá tài khoản</div>
            <div className="confirm-modal-content">
              Bạn có chắc chắn muốn xoá tài khoản?
            </div>
            <Form.Item name="password">
              <Input.Password
                placeholder="Nhập mật khẩu của bạn"
                className="input-password"
              />
            </Form.Item>

            <div
              className="use-otp"
              onClick={() => {
                setUseOTP(true);
                handleSendOtp();
                setCountdown(60);
              }}
            >
              Sử dụng Mã xác nhận
            </div>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default AccountDeleteModal;
