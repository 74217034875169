import {FunctionComponent} from "react";
import "./page-header.scss";
import {Breadcrumb, Button, Divider, Modal, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import {DeleteOutlined, WarningOutlined} from "@ant-design/icons";

type PageHeaderType = {
    arrowLeft?: string;
    title?: string | null;
    removeButton?: boolean;
    tabs?: boolean;
    breadcrumb?: boolean;
    positionItem?: string;
};


const PageHeader: FunctionComponent<PageHeaderType> = ({
    arrowLeft,
    title,
    removeButton,
    tabs,
    breadcrumb,
    positionItem
}) => {

    const navigation = useNavigate()

    const removeModal = () => {
        Modal.confirm({
            title: 'Xoá tài khoản',
            content: 'Mọi dữ liệu tài khoản đã xoá sẽ không thể khôi phục. Bạn chắc chắn muốn xoá tài khoản?',
            icon: <WarningOutlined />,
            okType: "danger",
            cancelText: 'Huỷ',
            okText: 'Xác nhận',
            wrapClassName: 'modal-custom',
            onCancel: () => {
                // cancel action here
            }
        })
    }
    return (
        <>
        <div className={`page-header ${positionItem ? 'item-vertical' : ''} ${!breadcrumb ? 'only-title' : ''}`}
            id="page-header">
            {
                breadcrumb &&
                <Breadcrumb items={[{ title: "Sinh viên" }, { title: title }]} />
            }
            <div className="heading-left">
                {
                    arrowLeft &&
                    <img
                        className="arrowleft-icon"
                        loading="eager"
                        onClick={() => navigation('/list-account-users')}
                        alt=""
                        src={arrowLeft}
                    />
                }
                <h3 className="title54">{title}</h3>
            </div>
            {
                removeButton &&
                <div className="heading-right">
                    <Button className="btn btn-remove" onClick={removeModal}>
                        <DeleteOutlined />
                        <Typography.Text>Xoá tài khoản</Typography.Text>
                    </Button>
                </div>
            }
            <Divider />
        </div>
            </>
    );
};

export default PageHeader;
