import { Card, Flex, Image, Modal, Typography } from "antd";

const { Title, Text } = Typography;

interface IPropsModal {
  isOpen: boolean;
  handleClose: () => void;
}

const mockPhotoInstructor = [
  { name: "Căn cước công dân", img: "Image2.png" },
  // { name: "Chứng minh nhân dân", img: "Image1.png" },
  { name: "Hộ chiếu", img: "Image.png" },
];
const CommonPhoto = ({ val }: any) => {
  return (
    <div className="w-full sm:w-1/3 p-3 text-center">
      <Image src={`/assets/img/img-kyc/${val.img}`} preview={false} className="w-full"/>
      <p className="lg:text-lg font-semibold">{val.name}</p>
    </div>
  );
};
const ModalIntroduce = ({ isOpen, handleClose }: IPropsModal) => {
  return (
    <Modal
      title={
        <div className="border-b px-4 pb-2">
          <Title level={4} className="!mb-0 !font-semibold">
            Cách chụp giấy tờ hợp lệ
          </Title>
          <Text className="text-[#475467]">
            Vui lòng đọc kĩ hướng dẫn của hệ thống
          </Text>
        </div>
      }
      centered
      open={isOpen}
      onCancel={handleClose}
      className="w-4/5 lg:!w-[1000px]"
      footer={""}
    >
      <Card className="h-[600px] sm:h-fit overflow-auto">
        <Flex wrap="wrap" justify="center">
          {mockPhotoInstructor?.map((val) => <CommonPhoto key={val} val={val} />)}
        </Flex>
        <div>
          <Title>Hướng dẫn</Title>
          <ol className="px-4 py-4 lg:py-8">
            <li className="list-decimal py-1 lg:py-2">
              Đặt giấy tờ trên mặt phẳng tối màu
            </li>
            <li className="list-decimal py-1 lg:py-2">
              Sử dụng nguồn ánh sáng tự nhiên thay vì đèn flash. Ảnh nên được
              chụp vào ban ngày
            </li>
            <li className="list-decimal py-1 lg:py-2">
              Chụp thằng từ trên xuống và đảm báo giấy tờ của bạn nằm trong
              khung chụp
            </li>
            <li className="list-decimal py-1 lg:py-2">
              Lưu ý không để bóng bạn che giấy tờ
            </li>
            <li className="list-decimal py-1 lg:py-2">
              Đảm bảo có đầy đủ các góc của giấy tờ trong ảnh và ảnh không bị
              cháy nắng.
            </li>
            <li className="list-decimal py-1 lg:py-2">
              Tất cả thông tin trên ảnh chụp giấy tờ phải rõ ràng và có thể đọc
              được
            </li>
          </ol>
        </div>
      </Card>
    </Modal>
  );
};

export default ModalIntroduce;
