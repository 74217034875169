import { Button, Col, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/banner/Banner";
import FormInput from "../../components/form-input/FormInput";
import useKeyword from "../../hooks/useKeyword";
import { getGroupIndustry } from "../../service/courses";
import { GroupIndustry } from "../../types/course";
import "./index.scss";
import { routesConfig } from "../../config/routes";
import { objectToQueryString } from "../../utils/getPageFromLink";

type Props = {};
const SearchByTopic = (props: Props) => {
  const navigate = useNavigate();
  const [groupIndustry, setGroupIndustry] = useState<GroupIndustry[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>(useKeyword("keyword") || "");

  const getGroupOfIndustry = async () => {
    setIsLoading(true);

    const res = await getGroupIndustry(keyword ? keyword : "");
    setGroupIndustry(res.data.data);
    setIsLoading(false);
  };
  useEffect(() => {
    getGroupOfIndustry();
  }, []);

  const handleSearch = () => {
    getGroupOfIndustry();
  };
  const handleSearchtoIndustryGroup = (it: any) => {
    navigate(`${routesConfig.curriculumPrograms}?industryGroup=${it?.id}`, {
      state: { dataState: it },
    });
  };
  return (
    <div className="search-topic">
      <Banner>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="search-topic__header">
            <div className="search-programs__list-name">
              Danh sách chuyên ngành
            </div>
            <div className="search-programs__title">
              Các chủ đề hiện có trên Mooc
            </div>
            <div className="search-programs__Overview">
              Với 1000+ các chương trình học với đa dạng chủ đề
            </div>
            <div className="search-programs__search">
              <FormInput
                placeholder="Nhập từ khoá cần tìm"
                onChange={(e) => setKeyword(e.target.value)}
              />
              <Button className="search-btn" onClick={() => handleSearch()}>
                Tìm kiếm
              </Button>
            </div>
          </div>
        </div>
      </Banner>
      {isLoading ? (
        <div className="search-topic-loading">
          <Spin size="large" />
        </div>
      ) : groupIndustry ? (
        <div className="search-topic-results">
          {groupIndustry?.map((pair, index) => (
            <Col
              span={12}
              className={
                index % 2 === 0
                  ? "search-topic-results-col pr-48"
                  : "search-topic-results-col pl-48"
              }
            >
              <img
                className="course-introduction-list__icon"
                alt="icon"
                src="/assets/icons/bar-chart-square.svg"
              />
              <div className="search-topic-results-content">{pair?.name}</div>
              <div className="topic-list">
                {pair?.children?.map((it: any) => (
                  <div
                    className="item"
                    key={it.id}
                    onClick={() => handleSearchtoIndustryGroup(it)}
                  >
                    {it?.name}
                  </div>
                ))}
              </div>
            </Col>
          ))}
        </div>
      ) : (
        <div>aaaaaa</div>
      )}
    </div>
  );
};
export default SearchByTopic;
