import { FunctionComponent } from "react";
import "./login.scss";
import LoginPage from "./components/login-page/LoginPage";
import ChatbotComponent from "../../components/chat-bot/ChatBotComponent";

const Login: FunctionComponent = () => {
  return (
    <main>
      <div className="login">
        <div className="login-container">
          <a className="logo" href="/">
            <img
              className="logo-icon"
              loading="eager"
              alt=""
              src={"../../assets/img/app-logo.png"}
            />
            <div className="logo-title">Mooc</div>
          </a>
          <LoginPage />

          <div className="copyright-2024-b">©Mooc 2024</div>
        </div>
        <div className="left">
          <div className="banner-container">
            <img
              className="banner-login"
              loading="eager"
              alt=""
              src={"../../assets/img/banner-login.png"}
            />
          </div>
        </div>
      </div>
      <ChatbotComponent />
    </main>
  );
};

export default Login;
