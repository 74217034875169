export const periodOfTimeOpt = [
  {
    label: "7 ngày",
    value: 1,
  },
  {
    label: "1 tháng",
    value: 2,
  },
  {
    label: "3 tháng",
    value: 3,
  },
  {
    label: "Tuỳ chọn",
    value: -1,
  },
];

export const unitOpt = [
  {
    label: "Biểu đồ ngày",
    value: "day",
  },
  {
    label: "Biểu đồ tuần",
    value: "week",
  },
  {
    label: "Biểu đồ tháng",
    value: "month",
  },
  {
    label: "Biểu đồ năm",
    value: "year",
  },
];

export const moduleGroupOptions = [
  {
    label: "Đa phương tiện",
    value: 1,
  },
  {
    label: "Tài liệu tham khảo",
    value: 2,
  },
  {
    label: "Bài kiểm tra đánh giá",
    value: 3,
  },
  {
    label: "Scorm & xAPI",
    value: 4,
  },
];

export const DEFAULT_MODULE_GROUP = 3;
