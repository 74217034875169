import {
  DislikeOutlined,
  HomeOutlined,
  LikeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { routesConfig } from "../../../config/routes";
import MoocBreadcrumb from "../../../components/breadcrumb";
import { Button, Input } from "antd";
import { Link } from "react-router-dom";

function DetailSupport() {
  const breadcrumb = [
    {
      title: (
        <Link to={routesConfig.homePage}>
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: (
        <Link className="breadcrumb-link" to={routesConfig.support}>
          Hỗ trợ
        </Link>
      ),
    },
    {
      title: (
        <Link className="breadcrumb-link" to={routesConfig.statisticsCourses}>
          <span className="breadcrumb-link breadcrumb-link-active">
            <b> Thống kê số lượng trường học</b>
          </span>
        </Link>
      ),
    },
  ];
  return (
    <div>
      <section className="content-layout">
        <MoocBreadcrumb items={breadcrumb} />
        <div className="support-detail-header flex justify-space-between align-center">
          <h3 className="font-size-30">
            <b>Thánh toán và hoá đơn</b>
          </h3>
          <Input
            style={{ width: 300 }}
            prefix={
              <SearchOutlined style={{ color: "#667085", fontSize: 20 }} />
            }
            className="height-44"
            placeholder="Tìm kiếm"
          />
        </div>
        <div className="wrap-content-support-detail flex mt-5">
          <div className="sup-sidebar">
            <p className="text-primary font-size-16 line-height-24">
              <b>Làm sao để ghi danh khoá học?</b>
            </p>
            <p className="font-size-16 line-height-24 mt-1 text-secondary pointer">
              <b>Các bài kiểm tra của tôi sẽ được đánh giá như thế nào? </b>
            </p>
            <p className="font-size-16 line-height-24 mt-1 text-secondary pointer">
              <b>Các bài kiểm tra của tôi sẽ được đánh giá như thế nào? </b>
            </p>
            <p className="font-size-16 line-height-24 mt-1 text-secondary pointer">
              <b>Các bài kiểm tra của tôi sẽ được đánh giá như thế nào? </b>
            </p>
            <p className="font-size-16 line-height-24 mt-1 text-secondary pointer">
              <b>Các bài kiểm tra của tôi sẽ được đánh giá như thế nào? </b>
            </p>
          </div>
          <div className="sup-content">
            <h3 className="font-size-30">
              <b>Làm sao để ghi danh khoá học?</b>
            </h3>
            <p className="text-secondary font-size-18 line-height-28 mt-2">
              Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
              suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum
              quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris
              posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At
              feugiat sapien varius id.
              <br />
              <br />
              Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat
              mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu
              quis fusce augue enim. Quis at habitant diam at. Suscipit
              tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum
              molestie aliquet sodales id est ac volutpat.{" "}
            </p>
            <img
              className="support-img mt-4 mb-2"
              src={`${process.env.PUBLIC_URL}/assets/img/image.png`}
              alt="image"
            />
            <p className="text-secondary font-size-14">
              Image courtesy of Edmond Dantès via Pexels
            </p>

            <p className="text-secondary font-size-18 mt-4 mb-4">
              Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim
              mauris id. Non pellentesque congue eget consectetur turpis.
              Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt
              aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue
              felis elit erat nam nibh orci.
            </p>

            <div className="comment mb-5">
              <p className="text-italic font-size-24">
                <b>
                  “In a world older and more complete than ours they move
                  finished and complete, gifted with extensions of the senses we
                  have lost or never attained, living by voices we shall never
                  hear.”
                </b>
              </p>
              <div className="user-info flex align-center gap-12 mt-3">
                <img
                  className="user-avt-sup"
                  src={`${process.env.PUBLIC_URL}/assets/img/person-5.png`}
                  alt="avt"
                />
                <div className="info-text">
                  <p className="font-size-16">
                    <b>Olivia Rhye</b>
                  </p>
                  <span className="text-secondary font-size-16">
                    Product Designer
                  </span>
                </div>
              </div>
            </div>

            <p className="text-secondary font-size-18 mt-4 mb-4 line-height-28">
              Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum,
              nulla odio nisl vitae. In aliquet pellentesque aenean hac
              vestibulum turpis mi bibendum diam. Tempor integer aliquam in
              vitae malesuada fringilla.
              <br />
              <br />
              Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet
              commodo consectetur convallis risus. Sed condimentum enim
              dignissim adipiscing faucibus consequat, urna. Viverra purus et
              erat auctor aliquam. Risus, volutpat vulputate posuere purus sit
              congue convallis aliquet. Arcu id augue ut feugiat donec porttitor
              neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem
              id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.
              <br />
              <br />
              Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim
              mauris id. Non pellentesque congue eget consectetur turpis.
              Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt
              aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue
              felis elit erat nam nibh orci.
            </p>

            <p className="font-size-24 line-height-32 mb-3">
              <b>Software and tools</b>
            </p>

            <p className="text-secondary font-size-18 mt-4 mb-4 line-height-28">
              Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet
              commodo consectetur convallis risus. Sed condimentum enim
              dignissim adipiscing faucibus consequat, urna. Viverra purus et
              erat auctor aliquam. Risus, volutpat vulputate posuere purus sit
              congue convallis aliquet. Arcu id augue ut feugiat donec porttitor
              neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem
              id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.
              <br />
              <br />
              Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim
              mauris id. Non pellentesque congue eget consectetur turpis.
              Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt
              aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue
              felis elit erat nam nibh orci.
            </p>

            <div className="conclusion mb-5">
              <p className="font-size-30 line-height-32 mb-3">
                <b>Software and tools</b>
              </p>

              <p className="text-secondary font-size-18 mt-4 mb-4 line-height-28">
                Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum,
                nulla odio nisl vitae. In aliquet pellentesque aenean hac
                vestibulum turpis mi bibendum diam. Tempor integer aliquam in
                vitae malesuada fringilla.
                <br />
                <br />
                Elit nisi in eleifend sed nisi. Pulvinar at orci, proin
                imperdiet commodo consectetur convallis risus. Sed condimentum
                enim dignissim adipiscing faucibus consequat, urna. Viverra
                purus et erat auctor aliquam. Risus, volutpat vulputate posuere
                purus sit congue convallis aliquet. Arcu id augue ut feugiat
                donec porttitor neque. Mauris, neque ultricies eu vestibulum,
                bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus,
                pharetra, porttitor.
                <br />
                <br />
                Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim
                mauris id. Non pellentesque congue eget consectetur turpis.
                Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt
                aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue
                felis elit erat nam nibh orci.
              </p>
            </div>

            <div className="sup-action flex align-center justify-space-between mb-5">
              <p className="text-secondary font-size-18">
                Câu trả lời hữu ích với bạn không?
              </p>
              <div className="flex align-center gap-8">
                <Button className="btn-primary">
                  <div className="flex align-center gap-8">
                    <LikeOutlined />
                    <span className="font-size-14">
                      <b>Có</b>
                    </span>
                  </div>
                </Button>
                <Button>
                  <div className="flex align-center gap-8">
                    <DislikeOutlined />
                    <span className="font-size-14">
                      <b>Không</b>
                    </span>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DetailSupport;
