// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note-list .pinned-notes .ant-collapse {
  border: none;
}
.note-list .pinned-notes .ant-collapse .ant-collapse-header {
  background-color: #fff;
  padding: 0px;
}
.note-list .pinned-notes .ant-collapse .ant-collapse-content {
  border: none;
}
.note-list .pinned-notes .ant-collapse .ant-collapse-content .ant-collapse-content-box {
  padding: 0px;
}
.note-list .note-other {
  margin-top: 32px;
  margin-bottom: 24px;
}
.note-list .ant-col {
  height: calc(100% - 16px);
}
.note-list .ant-list-item {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/learning-course-structure/my-notes/components/note-list/NoteList.scss"],"names":[],"mappings":"AAEI;EACE,YAAA;AADN;AAGM;EACE,sBAAA;EACA,YAAA;AADR;AAIM;EACE,YAAA;AAFR;AAIQ;EACE,YAAA;AAFV;AAQE;EACE,gBAAA;EACA,mBAAA;AANJ;AASE;EACE,yBAAA;AAPJ;AAUE;EACE,YAAA;AARJ","sourcesContent":[".note-list {\n  .pinned-notes {\n    .ant-collapse {\n      border: none;\n\n      .ant-collapse-header {\n        background-color: #fff;\n        padding: 0px\n      }\n\n      .ant-collapse-content {\n        border: none;\n\n        .ant-collapse-content-box {\n          padding: 0px;\n        }\n      }\n    }\n  }\n\n  .note-other {\n    margin-top: 32px;\n    margin-bottom: 24px;\n  }\n\n  .ant-col {\n    height: calc(100% - 16px);\n  }\n\n  .ant-list-item {\n    height: calc(100%);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
