import { StarOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getCourseRecommend } from "../../../service/courses";
import { formatCurrencyToVND } from "../../../utils/format";
import "./RelatedCourses.scss";
import { Button } from "antd";
import { routesConfig } from "../../../config/routes";
type Props = {
  idElmTag?: string;
};
interface Course {
  image: string;
  title: string;
  lecturer: string;
  price: string;
  rating: number;
  studentCount: number;
}

const RelatedCourses: React.FC = (props: Props) => {
  const { idElmTag } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [recommendCourse, setRecommendCourse] = useState<any>([]);

  useEffect(() => {
    const getCourseAdminApi = async () => {
      const recommendCourse = await getCourseRecommend(Number(id));
      setRecommendCourse(recommendCourse?.data?.data);
    };
    getCourseAdminApi();
  }, []);

  return (
    <div className="relatedCourses" id={idElmTag}>
      <div className="text-title-18">Khóa học tương tự</div>
      <div className="mt-1 mb-1 text-description-14">
        Những khoá học tương tự có thể bạn quan tâm
      </div>
      <div className="hr-tag"></div>
      <div className="courseCards">
        {recommendCourse.slice(0, 3)?.map((it: any) => {
          return (
            <div
              key={it?.id}
              className="course-card-item pointer"
              onClick={() =>
                navigate(`${routesConfig.courseInfo}/detail/${it.id}`)
              }
            >
              <div className="card-img-container">
                <img
                  className="card-img"
                  src={
                    it?.imagePath ||
                    `${process.env.PUBLIC_URL}/assets/icons/empty-course.png`
                  }
                />
              </div>
              <div className="course-info">
                <div className="font-weight-6 mt-1">
                  {it?.enterpriseName}
                  {it?.slug ? "(" + it.slug + ")" : null}
                </div>
                <div className="text-title-18 mt-1 mb-1">{it?.name}</div>
                <div className="font-size-16 mt-1 mb-1">{it?.description}</div>
                <div className="cost">
                  <img
                    className="course-info-img"
                    src={`${process.env.PUBLIC_URL}/assets/icons/wallet.svg`}
                  />
                  {it?.cost && formatCurrencyToVND(it?.cost)}
                </div>
                <div className="course-info-rate">
                  {it?.isTrending ? (
                    <div className="popular">
                      {it?.isTrending ? "Thịnh hành" : ""}
                    </div>
                  ) : (
                    ""
                  )}
                  {it?.star ? (
                    <div className="rate-star flex align-center">
                      <StarOutlined style={{ color: "#EF6820" }} />
                      {Number(it?.rate?.toFixed(1))}
                    </div>
                  ) : (
                    ""
                  )}
                  {it?.industryName ? (
                    <div className="subject">{it?.industryName}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {recommendCourse?.length > 0 && (
        <div className="related-course-view-more">
          <Button
            onClick={() => navigate(routesConfig.courseInfo)}
            className="view-more-btn"
          >
            Xem thêm các khoá học khác
          </Button>
        </div>
      )}
    </div>
  );
};

export default RelatedCourses;
