import React, { useEffect, useState } from "react";
import ChooseText from "./choose-text/ChooseText";
import { Button, Form, Spin } from "antd";
import ChooseImage from "./choose-image";
import ChooseVideo from "./choose-video";
import TrueOrFalse from "./true-or-false";
import DropDown from "./drop-down";
import ChooseMultiText from "./choose-multi-text";
import ChooseMultiImage from "./choose-multi-image";
import ChooseMultiVideo from "./choose-multi-video";
import Connect from "./connect";
import ShortText from "./short-text";
import FileUpload from "./file-upload";
import Video from "./video";
import Mp3 from "./mp3";
import LongText from "./long-text";
import FillText from "./fill-text/FillText";
import {
  LayoutMode,
  QuestionTypeEnum,
} from "../../../../../../constants/course";
import { NextIcon, PreviousIcon } from "../../../../../../components/icons";
import {
  ExamConfigs,
  GroupsExam,
  QuestionBeProps,
  QuizProps,
  QuizRequest,
} from "../../../../../../types/course";
import { toNumber } from "lodash";
import { addOrUpdateQuizRequest } from "../../../../../../utils/arrays";
import EmptyComponent from "../../../../../../components/empty";

interface QuizLayoutProps {
  data?: QuizProps;
  loading?: boolean;
  isPausing?: boolean;
  disableNextBtn?: boolean;
  disablePrevBtn?: boolean;
  quizzes: QuizProps[];
  currentGroup: GroupsExam;
  quizzesCompleted: QuizRequest[];
  layout?: LayoutMode.FLAT | LayoutMode.GROUP | LayoutMode.SINGLE;
  configs?: ExamConfigs;
  quizzesDisabled?: number[];
  groups?: GroupsExam[];
  onPause?: () => void;
  onFinish?: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
  onChange?: (data: QuizRequest[]) => void;
  onFinishSingleQuiz?: () => void;
}

function QuizLayout({
  data,
  loading = false,
  quizzes = [],
  disableNextBtn = false,
  disablePrevBtn = false,
  quizzesCompleted,
  isPausing = false,
  layout = LayoutMode.FLAT,
  currentGroup,
  configs,
  quizzesDisabled = [],
  groups = [],
  onPause = () => {},
  onFinish = () => {},
  onNext = () => {},
  onPrevious = () => {},
  onChange = () => {},
  onFinishSingleQuiz = () => {},
}: QuizLayoutProps) {
  const [form] = Form.useForm();
  const [fieldValues, setFieldValues] = useState<QuizRequest[]>([]);

  useEffect(() => {
    setFieldValues(quizzesCompleted);
  }, []);

  const renderQuestionByType = (currentQuiz: QuizProps) => {
    const initialData = () => {
      return quizzesCompleted?.find(
        (quiz: QuizRequest) => quiz.quizId === currentQuiz?.id
      );
    };
    const currentIndexOfQuiz = quizzes?.findIndex(
      (quiz: QuizProps) => quiz.id === currentQuiz?.id
    );

    const type = currentQuiz?.type;

    const disabledQuiz = quizzesDisabled?.includes(currentQuiz?.id);

    switch (type) {
      case QuestionTypeEnum.CHOOSE_TEXT:
        return (
          <ChooseText
            configs={configs}
            disabled={disabledQuiz}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      case QuestionTypeEnum.CHOOSE_IMAGE:
        return (
          <ChooseImage
            configs={configs}
            disabled={disabledQuiz}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      case QuestionTypeEnum.CHOOSE_VIDEO:
        return (
          <ChooseVideo
            configs={configs}
            disabled={disabledQuiz}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      case QuestionTypeEnum.TRUE_OR_FALSE:
        return (
          <TrueOrFalse
            configs={configs}
            disabled={disabledQuiz}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      case QuestionTypeEnum.DROP_DOWN:
        return (
          <DropDown
            configs={configs}
            disabled={disabledQuiz}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      case QuestionTypeEnum.CHOOSE_MULTI_TEXT:
        return (
          <ChooseMultiText
            configs={configs}
            disabled={disabledQuiz}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      case QuestionTypeEnum.CHOOSE_MULTI_IMAGE:
        return (
          <ChooseMultiImage
            configs={configs}
            disabled={disabledQuiz}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      case QuestionTypeEnum.CHOOSE_MULTI_VIDEO:
        return (
          <ChooseMultiVideo
            configs={configs}
            disabled={disabledQuiz}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      case QuestionTypeEnum.CONNECT:
        return (
          <Connect
            configs={configs}
            disabled={disabledQuiz}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            onChange={
              layout === LayoutMode.SINGLE
                ? handleChangeValuesSingle
                : handleChangeValuesFlat
            }
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      case QuestionTypeEnum.SHORT_TEXT:
        return (
          <ShortText
            configs={configs}
            disabled={disabledQuiz}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      case QuestionTypeEnum.LONG_TEXT:
        return (
          <LongText
            configs={configs}
            disabled={disabledQuiz}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      case QuestionTypeEnum.FILE_UPLOAD:
        return (
          <FileUpload
            configs={configs}
            disabled={disabledQuiz}
            form={form}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            onChange={
              layout === LayoutMode.SINGLE
                ? handleChangeValuesSingle
                : handleChangeValuesFlat
            }
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      case QuestionTypeEnum.VIDEO:
        return (
          <Video
            configs={configs}
            disabled={disabledQuiz}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            onChange={
              layout === LayoutMode.SINGLE
                ? handleChangeValuesSingle
                : handleChangeValuesFlat
            }
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      case QuestionTypeEnum.MP3:
        return (
          <Mp3
            configs={configs}
            disabled={disabledQuiz}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            onChange={
              layout === LayoutMode.SINGLE
                ? handleChangeValuesSingle
                : handleChangeValuesFlat
            }
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      case QuestionTypeEnum.FillText:
        return (
          <FillText
            configs={configs}
            disabled={disabledQuiz}
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            pause={isPausing}
            initialData={initialData()}
            onFinish={onFinishSingleQuiz}
          />
        );

      default:
        break;
    }
  };

  useEffect(() => {
    onChange(fieldValues);
  }, [fieldValues]);

  const handleChangeValuesFlat = (field: any, values?: any) => {
    const fieldKey = Object.keys(field)[0];
    const fieldValue = Object.values(field);

    if (fieldKey) {
      const fieldInfo = fieldKey?.split("-");
      // default quiz payload
      const type = toNumber(fieldInfo[2]);
      const quizId = toNumber(fieldInfo[1]);
      let quizReq: QuizRequest = {
        type: type,
        quizId: quizId,
        questionType: fieldInfo[3],
        answer: fieldValue,
      };

      if (
        type === QuestionTypeEnum.CHOOSE_MULTI_TEXT ||
        type === QuestionTypeEnum.CHOOSE_MULTI_IMAGE ||
        type === QuestionTypeEnum.CHOOSE_MULTI_VIDEO
      ) {
        const current = quizzes.find((quiz: QuizProps) => quiz.id === quizId);
        // Chuyển đổi đối tượng thành mảng các cặp [key, value]
        const entries = Object.entries(values);

        // Lọc các cặp có key chứa chuỗi giá trị của quizId
        const filteredValues = entries
          .filter(([key, value]) => key.includes(`${quizId}`))
          .map(([key, value]) => value);

        if (current) {
          quizReq = {
            type: type,
            quizId: quizId,
            questionType: fieldInfo[3],
            answer: current?.questions
              .map((quest: QuestionBeProps, i: number) =>
                filteredValues[i] ? quest.uuid : ""
              )
              .filter((item: string) => item !== ""),
          };
        }
      }
      if (type === QuestionTypeEnum.CONNECT) {
        quizReq = {
          type: type,
          quizId: quizId,
          questionType: fieldInfo[3],
          answer: fieldValue[0],
        };
      }
      setFieldValues((prevState: QuizRequest[]) =>
        addOrUpdateQuizRequest(prevState, quizReq)
      );
    }
  };

  const handleChangeValuesSingle = (fields: any) => {
    const fieldKey = Object.keys(fields)[0];
    const fieldValue = Object.values(fields);

    if (fieldKey) {
      const fieldInfo = fieldKey?.split("-");
      // default quiz payload
      const type = toNumber(fieldInfo[2]);
      const quizId = toNumber(fieldInfo[1]);
      let quizReq: QuizRequest = {
        type: type,
        quizId: quizId,
        questionType: fieldInfo[3],
        answer: fieldValue,
      };

      if (
        type === QuestionTypeEnum.CHOOSE_MULTI_TEXT ||
        type === QuestionTypeEnum.CHOOSE_MULTI_IMAGE ||
        type === QuestionTypeEnum.CHOOSE_MULTI_VIDEO
      ) {
        const current = quizzes.find((quiz: QuizProps) => quiz.id === quizId);
        if (current) {
          quizReq = {
            type: type,
            quizId: quizId,
            questionType: fieldInfo[3],
            answer: current?.questions
              .map((quest: QuestionBeProps) => quest.uuid)
              .filter((_, index: number) => !!fieldValue[index]),
          };
        }
      }
      if (type === QuestionTypeEnum.CONNECT) {
        quizReq = {
          type: toNumber(fieldInfo[2]),
          quizId: toNumber(fieldInfo[1]),
          questionType: fieldInfo[3],
          answer: fieldValue[0],
        };
      }
      setFieldValues((prevState: QuizRequest[]) =>
        addOrUpdateQuizRequest(prevState, quizReq)
      );
    }
  };

  const renderQuizzesByLayout = () => {
    switch (layout) {
      case LayoutMode.FLAT:
        return quizzes?.map((quiz: QuizProps, index: number) => (
          <div
            id={`quest-${quiz.id}`}
            key={`quest-${quiz.id}-${index}`}
            className="mb-4"
          >
            {renderQuestionByType(quiz)}
          </div>
        ));
      case LayoutMode.GROUP:
        return (
          groups?.length > 0 && (
            <div>
              <div className="exam-group-header mb-2">
                <div className="group-index">
                  Phần{" "}
                  {groups?.findIndex(
                    (group: GroupsExam) => group.id === currentGroup?.id
                  ) + 1}
                  /{groups?.length}
                </div>
                <p className="text-primary font-size-14 font-weight-6 mt-1">
                  {currentGroup?.title}
                </p>
              </div>
              {currentGroup?.quiz?.length > 0 ? (
                currentGroup?.quiz?.map((quiz: QuizProps, index: number) => (
                  <div
                    id={`quest-${quiz.id}`}
                    key={`quest-${quiz.id}-${index}`}
                    className="mb-4"
                  >
                    {renderQuestionByType(quiz)}
                  </div>
                ))
              ) : (
                <EmptyComponent description="Không có câu hỏi" />
              )}
            </div>
          )
        );
      case LayoutMode.SINGLE:
        return renderQuestionByType(data as QuizProps);
      default:
        return <></>;
    }
  };

  return quizzes?.length > 0 ? (
    <div className="quiz-layout flex flex-column">
      <div className="question-content flex-1">
        <Spin spinning={loading}>
          <div className="question-display-content flex-1">
            <Form
              form={form}
              onValuesChange={(currentValue, values) =>
                layout === LayoutMode.SINGLE
                  ? handleChangeValuesSingle(values)
                  : handleChangeValuesFlat(currentValue, values)
              }
            >
              {renderQuizzesByLayout()}
            </Form>
          </div>
        </Spin>
      </div>

      <div className="quiz-layout-footer">
        <div className="btn-group-left flex gap-16">
          {configs?.actionConfig?.executionTimeExpired &&
            configs?.actionConfig?.completionTime > 0 && (
              <Button className="h-36 btn-quiz-footer" onClick={onPause}>
                <span className="font-size-14 font-weight-6">Tạm dừng</span>
              </Button>
            )}

          <Button
            className="h-36 btn-quiz-footer btn-outline-primary"
            onClick={onFinish}
          >
            <span className="font-size-14 font-weight-6 text-primary">
              Nộp bài
            </span>
          </Button>
        </div>

        {layout !== LayoutMode.FLAT && (
          <div className="btn-group-right flex gap-12">
            <Button
              className="btn-paginate center h-36"
              onClick={onPrevious}
              disabled={disablePrevBtn}
            >
              <PreviousIcon />
            </Button>
            <Button
              className="btn-paginate center h-36"
              onClick={onNext}
              disabled={disableNextBtn}
            >
              <NextIcon />
            </Button>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="center">
      <EmptyComponent description="Không có nội dung" />
    </div>
  );
}

export default QuizLayout;
