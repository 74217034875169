// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-pathway {
  padding-top: 64px;
}
.course-pathway .curriculum-panel {
  margin-bottom: 12px;
}
.course-pathway .curriculum-panel .ant-collapse-content {
  padding: 0 16px 16px;
}
.course-pathway .curriculum-panel .ant-collapse-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.course-pathway .curriculum-panel .ant-collapse-content ul li {
  padding: 4px 0;
  border-bottom: 1px solid #f0f0f0;
}
.course-pathway .curriculum-panel .ant-collapse-content ul li:last-child {
  border-bottom: none;
}
.course-pathway .ant-collapse {
  background-color: #f9fafb;
}
.course-pathway .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
  border-radius: 0px;
}
.course-pathway .ant-collapse .ant-collapse-content > .ant-collapse-content-box .ant-collapse-header {
  padding: 0px 24px;
  min-height: 46px;
  display: flex;
  align-items: center;
}
.course-pathway .ant-collapse .ant-collapse-content > .ant-collapse-content-box .ant-collapse {
  border-radius: 0px;
  border: none;
}
.course-pathway .ant-collapse .ant-collapse-content > .ant-collapse-content-box .ant-collapse .ant-collapse-header {
  padding: 0px 24px;
  min-height: 46px;
  display: flex;
  align-items: center;
}
.course-pathway .ant-collapse .ant-collapse-content > .ant-collapse-content-box .ant-collapse .ant-collapse-content-box {
  min-height: 72px;
  padding: 18px 26px 18px 48px;
  display: flex;
  align-items: center;
}
.course-pathway .ant-collapse .ant-collapse-content > .ant-collapse-content-box .ant-collapse .ant-collapse-item:last-child {
  border-radius: 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/course-evaluation/course-pathway/CoursePathway.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AACE;EACE,mBAAA;AACJ;AACI;EACE,oBAAA;AACN;AACM;EACE,gBAAA;EACA,SAAA;EACA,UAAA;AACR;AACQ;EACE,cAAA;EACA,gCAAA;AACV;AACU;EACE,mBAAA;AACZ;AAME;EACE,yBAAA;AAJJ;AAMI;EACE,YAAA;EACA,kBAAA;AAJN;AAMM;EACE,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AAJR;AAOM;EACE,kBAAA;EACA,YAAA;AALR;AAOQ;EACE,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AALV;AAQQ;EACE,gBAAA;EACA,4BAAA;EACA,aAAA;EACA,mBAAA;AANV;AAUU;EACE,kBAAA;AARZ","sourcesContent":[".course-pathway {\n  padding-top: 64px;\n\n  .curriculum-panel {\n    margin-bottom: 12px;\n\n    .ant-collapse-content {\n      padding: 0 16px 16px;\n\n      ul {\n        list-style: none;\n        margin: 0;\n        padding: 0;\n\n        li {\n          padding: 4px 0;\n          border-bottom: 1px solid #f0f0f0;\n\n          &:last-child {\n            border-bottom: none;\n          }\n        }\n      }\n    }\n  }\n\n  .ant-collapse {\n    background-color: #f9fafb;\n\n    .ant-collapse-content > .ant-collapse-content-box {\n      padding: 0px;\n      border-radius: 0px;\n\n      .ant-collapse-header {\n        padding: 0px 24px;\n        min-height: 46px;\n        display: flex;\n        align-items: center;\n      }\n\n      .ant-collapse {\n        border-radius: 0px;\n        border: none;\n\n        .ant-collapse-header {\n          padding: 0px 24px;\n          min-height: 46px;\n          display: flex;\n          align-items: center;\n        }\n\n        .ant-collapse-content-box {\n          min-height: 72px;\n          padding: 18px 26px 18px 48px;\n          display: flex;\n          align-items: center;\n        }\n\n        .ant-collapse-item {\n          &:last-child {\n            border-radius: 0px;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
