import { GetProp } from "antd";
import { UploadProps } from "antd/lib";
import DOMPurify from "dompurify";
import { isEmpty } from "lodash";
import moment from "moment";
import { LESSON_TYPE } from "../constants/course";
import { CourseInfo, TeacherInfo } from "../types/course";
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export function formatCurrency(value: any) {
  // Sử dụng hàm toLocaleString() để định dạng giá trị tiền theo locale của người dùng
  // Ví dụ: 'en-US' cho tiền tệ Mỹ, 'vi-VN' cho tiền tệ Việt Nam
  // Cần chú ý rằng một số quốc gia có phần phụ định dạng khác nhau, như việc sử dụng dấu phẩy thay vì dấu chấm để phân tách hàng nghìn
  // Nên cần kiểm tra locale của ứng dụng hoặc truyền vào tham số cho hàm formatCurrency()
  return value?.toLocaleString("vi-VN", {
    style: "currency",
    currency: "VND", // Thay đổi thành mã tiền tệ mong muốn (ví dụ: 'EUR' cho euro, 'GBP' cho bảng Anh, ...)
  });
}

export const mapCourseInfoBeToFe = (course: CourseInfo) => {
  if (isEmpty(course)) return {};

  return {
    id: course.id,
    image: course.thumbnailUrl,
    school: course.universityName,
    hours: course.completedTime || 0,
    title: course.name,
    price: course.cost || 0,
    teachers: course?.teachers?.map(
      (teacher: TeacherInfo) => `${teacher.firstName} ${teacher.lastName}`
    ),
    slug: course.slug,
    isPopular: true,
    star: course.avgStar || 0,
    students: course.totalStudents || 0,
    skills: [],
    description: course?.reviewDescription?.description,
    isSaved: course?.isSaved,
    isRegistered: course?.isRegistered,
    completedPercentage: course?.completedPercentage,
  };
};

export const convertSomeObjKeyToArray = (obj: any, key: any) => {
  const distArrWithKey: any = [];
  const distArrWithoutKey: any = [];

  for (const objKey in obj) {
    if (key && objKey.includes(key)) {
      distArrWithKey.push(obj[objKey]);
    }
    distArrWithoutKey.push(obj[objKey]);
  }

  if (key) return distArrWithKey;
  return distArrWithoutKey;
};

export const displayStatusNotification = (created: string) => {
  if (isEmpty(created)) return "";
  const startTime = new Date(created);

  const currentTime = new Date();

  const differenceInMilliseconds = +currentTime - +startTime;
  const minutes = differenceInMilliseconds / (1000 * 60);

  if (minutes < 1) {
    return "Vừa xong";
  } else if (minutes < 60) {
    return `${Math.round(minutes)} phút`;
  } else {
    const hours = minutes / 60;
    if (hours < 24) {
      return `${Math.round(hours)} giờ trước`;
    } else {
      const days = hours / 24;
      if (days > 1) {
        return `${Math.round(days)} ngày trước`;
      } else {
        return `${Math.round(hours)} giờ trước`;
      }
    }
  }
};
export const formatCurrencyToVND = (amount: number): string => {
  return amount
    .toLocaleString("vi-VN", { style: "currency", currency: "VND" })
    .split("₫")[0];
};

export function convertToQueryString(obj: any) {
  const filteredPairs = Object.entries(obj).filter(
    ([key, value]) => value !== undefined
  );

  if (filteredPairs.length === 0) {
    return "";
  }

  const queryString = filteredPairs
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  return `?${queryString}`;
}

export function convertMinutesToTimeString(
  minutes?: number,
  format?: "hh:mm:ss" | "mm:ss"
) {
  if (!minutes) return "0 giờ 00 phút";
  if (format) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const seconds = 0;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = mins.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");
    if (format === "mm:ss") return `${formattedMinutes}:${formattedSeconds}`;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  if (hours < 1)
    return `${remainingMinutes.toString().padStart(2, "0")} phút`;
  return `${hours.toString().padStart(2, "0")} giờ ${remainingMinutes.toString().padStart(2, "0")} phút`;
}

export const renderLessonIconByType = (type?: number | string) => {
  switch (type) {
    case LESSON_TYPE.DONE:
      return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_1_34976)">
      <path d="M18.3334 9.23941V10.0061C18.3324 11.8031 17.7505 13.5516 16.6745 14.9909C15.5986 16.4302 14.0862 17.4831 12.3629 17.9927C10.6396 18.5022 8.7978 18.441 7.11214 17.8182C5.42648 17.1955 3.98729 16.0445 3.00922 14.537C2.03114 13.0294 1.56657 11.2461 1.68481 9.453C1.80305 7.65988 2.49775 5.95301 3.66531 4.58697C4.83288 3.22092 6.41074 2.26889 8.16357 1.87286C9.91641 1.47683 11.7503 1.65802 13.3918 2.38941M18.3334 3.33464L10.0001 11.6763L7.50008 9.1763" stroke="#079455" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_1_34976">
      <rect width="20" height="20" fill="white"/>
      </clipPath>
      </defs>
      </svg>`;
    case LESSON_TYPE.VIDEO:
      return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_1350_110374)">
      <path d="M10.0001 18.3346C14.6025 18.3346 18.3334 14.6037 18.3334 10.0013C18.3334 5.39893 14.6025 1.66797 10.0001 1.66797C5.39771 1.66797 1.66675 5.39893 1.66675 10.0013C1.66675 14.6037 5.39771 18.3346 10.0001 18.3346Z" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.91675 7.47241C7.91675 7.07468 7.91675 6.87581 7.99987 6.76478C8.0723 6.66803 8.18318 6.6075 8.30373 6.59889C8.44207 6.58901 8.60935 6.69655 8.94392 6.91163L12.8777 9.44052C13.1681 9.62715 13.3132 9.72047 13.3634 9.83913C13.4072 9.94281 13.4072 10.0598 13.3634 10.1635C13.3132 10.2821 13.1681 10.3755 12.8777 10.5621L8.94392 13.091C8.60935 13.3061 8.44207 13.4136 8.30373 13.4037C8.18318 13.3951 8.0723 13.3346 7.99987 13.2378C7.91675 13.1268 7.91675 12.9279 7.91675 12.5302V7.47241Z" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_1350_110374">
      <rect width="20" height="20" fill="white"/>
      </clipPath>
      </defs>
      </svg>`;
    case LESSON_TYPE.MP3:
      return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M16.4567 4.16595C17.6378 5.80782 18.3334 7.82232 18.3334 9.99929C18.3334 12.1763 17.6378 14.1908 16.4567 15.8326M13.1212 6.66596C13.7802 7.61082 14.1667 8.75991 14.1667 9.99929C14.1667 11.2387 13.7802 12.3878 13.1212 13.3326M8.02868 4.47069L5.39061 7.10876C5.24648 7.25289 5.17442 7.32495 5.09032 7.37649C5.01576 7.42218 4.93447 7.45585 4.84944 7.47626C4.75354 7.49929 4.65162 7.49929 4.4478 7.49929H3.00008C2.53337 7.49929 2.30002 7.49929 2.12176 7.59012C1.96495 7.67001 1.83747 7.79749 1.75758 7.9543C1.66675 8.13256 1.66675 8.36591 1.66675 8.83262V11.166C1.66675 11.6327 1.66675 11.866 1.75758 12.0443C1.83747 12.2011 1.96495 12.3286 2.12176 12.4085C2.30002 12.4993 2.53337 12.4993 3.00008 12.4993H4.4478C4.65162 12.4993 4.75354 12.4993 4.84944 12.5223C4.93447 12.5427 5.01576 12.5764 5.09032 12.6221C5.17442 12.6736 5.24648 12.7457 5.39061 12.8898L8.02868 15.5279C8.38566 15.8849 8.56415 16.0634 8.71739 16.0754C8.85036 16.0859 8.9803 16.0321 9.06692 15.9306C9.16675 15.8137 9.16675 15.5613 9.16675 15.0565V4.9421C9.16675 4.43725 9.16675 4.18483 9.06692 4.06794C8.9803 3.96652 8.85036 3.9127 8.71739 3.92316C8.56415 3.93522 8.38566 4.11371 8.02868 4.47069Z" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`;

    case LESSON_TYPE.TEXT:
      return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M10.0001 17.5L9.9167 17.3749C9.33783 16.5066 9.0484 16.0725 8.666 15.7582C8.32746 15.4799 7.93739 15.2712 7.51809 15.1438C7.04446 15 6.52267 15 5.4791 15H4.33341C3.39999 15 2.93328 15 2.57676 14.8183C2.26316 14.6586 2.00819 14.4036 1.8484 14.09C1.66675 13.7335 1.66675 13.2668 1.66675 12.3333V5.16667C1.66675 4.23325 1.66675 3.76654 1.8484 3.41002C2.00819 3.09641 2.26316 2.84144 2.57676 2.68166C2.93328 2.5 3.39999 2.5 4.33341 2.5H4.66675C6.53359 2.5 7.46701 2.5 8.18005 2.86331C8.80726 3.18289 9.31719 3.69282 9.63677 4.32003C10.0001 5.03307 10.0001 5.96649 10.0001 7.83333M10.0001 17.5V7.83333M10.0001 17.5L10.0835 17.3749C10.6623 16.5066 10.9518 16.0725 11.3342 15.7582C11.6727 15.4799 12.0628 15.2712 12.4821 15.1438C12.9557 15 13.4775 15 14.5211 15H15.6667C16.6002 15 17.0669 15 17.4234 14.8183C17.737 14.6586 17.992 14.4036 18.1518 14.09C18.3334 13.7335 18.3334 13.2668 18.3334 12.3333V5.16667C18.3334 4.23325 18.3334 3.76654 18.1518 3.41002C17.992 3.09641 17.737 2.84144 17.4234 2.68166C17.0669 2.5 16.6002 2.5 15.6667 2.5H15.3334C13.4666 2.5 12.5332 2.5 11.8201 2.86331C11.1929 3.18289 10.683 3.69282 10.3634 4.32003C10.0001 5.03307 10.0001 5.96649 10.0001 7.83333" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`;
    case LESSON_TYPE.PDF:
      return `<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.44067 5.8C6.44067 4.11984 6.44067 3.27976 6.71778 2.63803C6.96152 2.07354 7.35046 1.6146 7.82884 1.32698C8.37269 1 9.08462 1 10.5085 1H15.9322C17.3561 1 18.068 1 18.6119 1.32698C19.0903 1.6146 19.4792 2.07354 19.723 2.63803C20.0001 3.27976 20.0001 4.11984 20.0001 5.8V16.2C20.0001 17.8802 20.0001 18.7202 19.723 19.362C19.4792 19.9265 19.0903 20.3854 18.6119 20.673C18.068 21 17.3561 21 15.9322 21H10.5085C9.08462 21 8.37269 21 7.82884 20.673C7.35046 20.3854 6.96152 19.9265 6.71778 19.362C6.44067 18.7202 6.44067 17.8802 6.44067 16.2V5.8Z" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <rect y="9.79688" width="13.2204" height="8.4" rx="2" fill="#98A2B3"/>
          <path d="M9.24194 16.2257V12.1953H11.7662V12.8979H10.048V13.8583H11.5987V14.5608H10.048V16.2257H9.24194Z" fill="white"/>
          <path d="M6.77947 16.2257H5.42798V12.1953H6.79064C7.17412 12.1953 7.50424 12.276 7.78099 12.4374C8.05774 12.5974 8.27058 12.8277 8.41951 13.1281C8.56967 13.4286 8.64476 13.7881 8.64476 14.2066C8.64476 14.6264 8.56967 14.9872 8.41951 15.289C8.27058 15.5907 8.0565 15.8223 7.77727 15.9837C7.49928 16.1451 7.16668 16.2257 6.77947 16.2257ZM6.23403 15.4956H6.74596C6.98424 15.4956 7.18467 15.451 7.34725 15.3618C7.51107 15.2713 7.63393 15.1315 7.71584 14.9426C7.79899 14.7524 7.84056 14.507 7.84056 14.2066C7.84056 13.9088 7.79899 13.6654 7.71584 13.4765C7.63393 13.2875 7.51169 13.1485 7.34911 13.0593C7.18653 12.97 6.98611 12.9254 6.74783 12.9254H6.23403V15.4956Z" fill="white"/>
          <path d="M2.03394 16.2257V12.1953H3.53808C3.82724 12.1953 4.07359 12.2537 4.27712 12.3705C4.48065 12.4859 4.63578 12.6466 4.74251 12.8526C4.85048 13.0573 4.90446 13.2934 4.90446 13.5611C4.90446 13.8287 4.84986 14.0649 4.74065 14.2696C4.63143 14.4742 4.4732 14.6336 4.26595 14.7478C4.05994 14.8619 3.81049 14.919 3.5176 14.919H2.5589V14.2361H3.38729C3.54242 14.2361 3.67025 14.2079 3.77077 14.1515C3.87254 14.0938 3.94824 14.0144 3.99788 13.9134C4.04877 13.811 4.07421 13.6936 4.07421 13.5611C4.07421 13.4273 4.04877 13.3105 3.99788 13.2108C3.94824 13.1098 3.87254 13.0317 3.77077 12.9766C3.66901 12.9202 3.53994 12.892 3.38357 12.892H2.83999V16.2257H2.03394Z" fill="white"/>
          </svg>
          `;
    case LESSON_TYPE.SCORM:
      return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_1350_79868)">
            <path d="M3.33341 15.0013V14.8346C3.33341 13.4345 3.33341 12.7344 3.6059 12.1997C3.84558 11.7293 4.22803 11.3468 4.69844 11.1071C5.23322 10.8346 5.93328 10.8346 7.33342 10.8346H12.6667C14.0669 10.8346 14.7669 10.8346 15.3017 11.1071C15.7721 11.3468 16.1546 11.7293 16.3943 12.1997C16.6667 12.7344 16.6667 13.4345 16.6667 14.8346V15.0013M3.33341 15.0013C2.41294 15.0013 1.66675 15.7475 1.66675 16.668C1.66675 17.5884 2.41294 18.3346 3.33341 18.3346C4.25389 18.3346 5.00008 17.5884 5.00008 16.668C5.00008 15.7475 4.25389 15.0013 3.33341 15.0013ZM16.6667 15.0013C15.7463 15.0013 15.0001 15.7475 15.0001 16.668C15.0001 17.5884 15.7463 18.3346 16.6667 18.3346C17.5872 18.3346 18.3334 17.5884 18.3334 16.668C18.3334 15.7475 17.5872 15.0013 16.6667 15.0013ZM10.0001 15.0013C9.07961 15.0013 8.33342 15.7475 8.33342 16.668C8.33342 17.5884 9.07961 18.3346 10.0001 18.3346C10.9206 18.3346 11.6667 17.5884 11.6667 16.668C11.6667 15.7475 10.9206 15.0013 10.0001 15.0013ZM10.0001 15.0013V6.66797M5.00008 6.66797H15.0001C15.7767 6.66797 16.1649 6.66797 16.4712 6.5411C16.8796 6.37195 17.2041 6.04749 17.3732 5.63911C17.5001 5.33282 17.5001 4.94454 17.5001 4.16797C17.5001 3.3914 17.5001 3.00311 17.3732 2.69683C17.2041 2.28845 16.8796 1.96399 16.4712 1.79484C16.1649 1.66797 15.7767 1.66797 15.0001 1.66797H5.00008C4.22351 1.66797 3.83523 1.66797 3.52894 1.79484C3.12056 1.96399 2.79611 2.28845 2.62695 2.69683C2.50008 3.00311 2.50008 3.3914 2.50008 4.16797C2.50008 4.94454 2.50008 5.33282 2.62695 5.63911C2.79611 6.04749 3.12056 6.37195 3.52894 6.5411C3.83523 6.66797 4.22351 6.66797 5.00008 6.66797Z" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_1350_79868">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
            </svg>
              `;
    case LESSON_TYPE.XAPI:
      return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clip-path="url(#clip0_1350_79868)">
        <path d="M3.33341 15.0013V14.8346C3.33341 13.4345 3.33341 12.7344 3.6059 12.1997C3.84558 11.7293 4.22803 11.3468 4.69844 11.1071C5.23322 10.8346 5.93328 10.8346 7.33342 10.8346H12.6667C14.0669 10.8346 14.7669 10.8346 15.3017 11.1071C15.7721 11.3468 16.1546 11.7293 16.3943 12.1997C16.6667 12.7344 16.6667 13.4345 16.6667 14.8346V15.0013M3.33341 15.0013C2.41294 15.0013 1.66675 15.7475 1.66675 16.668C1.66675 17.5884 2.41294 18.3346 3.33341 18.3346C4.25389 18.3346 5.00008 17.5884 5.00008 16.668C5.00008 15.7475 4.25389 15.0013 3.33341 15.0013ZM16.6667 15.0013C15.7463 15.0013 15.0001 15.7475 15.0001 16.668C15.0001 17.5884 15.7463 18.3346 16.6667 18.3346C17.5872 18.3346 18.3334 17.5884 18.3334 16.668C18.3334 15.7475 17.5872 15.0013 16.6667 15.0013ZM10.0001 15.0013C9.07961 15.0013 8.33342 15.7475 8.33342 16.668C8.33342 17.5884 9.07961 18.3346 10.0001 18.3346C10.9206 18.3346 11.6667 17.5884 11.6667 16.668C11.6667 15.7475 10.9206 15.0013 10.0001 15.0013ZM10.0001 15.0013V6.66797M5.00008 6.66797H15.0001C15.7767 6.66797 16.1649 6.66797 16.4712 6.5411C16.8796 6.37195 17.2041 6.04749 17.3732 5.63911C17.5001 5.33282 17.5001 4.94454 17.5001 4.16797C17.5001 3.3914 17.5001 3.00311 17.3732 2.69683C17.2041 2.28845 16.8796 1.96399 16.4712 1.79484C16.1649 1.66797 15.7767 1.66797 15.0001 1.66797H5.00008C4.22351 1.66797 3.83523 1.66797 3.52894 1.79484C3.12056 1.96399 2.79611 2.28845 2.62695 2.69683C2.50008 3.00311 2.50008 3.3914 2.50008 4.16797C2.50008 4.94454 2.50008 5.33282 2.62695 5.63911C2.79611 6.04749 3.12056 6.37195 3.52894 6.5411C3.83523 6.66797 4.22351 6.66797 5.00008 6.66797Z" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_1350_79868">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>
          `;
    case LESSON_TYPE.READ:
      return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M10.0001 17.5L9.9167 17.3749C9.33783 16.5066 9.0484 16.0725 8.666 15.7582C8.32746 15.4799 7.93739 15.2712 7.51809 15.1438C7.04446 15 6.52267 15 5.4791 15H4.33341C3.39999 15 2.93328 15 2.57676 14.8183C2.26316 14.6586 2.00819 14.4036 1.8484 14.09C1.66675 13.7335 1.66675 13.2668 1.66675 12.3333V5.16667C1.66675 4.23325 1.66675 3.76654 1.8484 3.41002C2.00819 3.09641 2.26316 2.84144 2.57676 2.68166C2.93328 2.5 3.39999 2.5 4.33341 2.5H4.66675C6.53359 2.5 7.46701 2.5 8.18005 2.86331C8.80726 3.18289 9.31719 3.69282 9.63677 4.32003C10.0001 5.03307 10.0001 5.96649 10.0001 7.83333M10.0001 17.5V7.83333M10.0001 17.5L10.0835 17.3749C10.6623 16.5066 10.9518 16.0725 11.3342 15.7582C11.6727 15.4799 12.0628 15.2712 12.4821 15.1438C12.9557 15 13.4775 15 14.5211 15H15.6667C16.6002 15 17.0669 15 17.4234 14.8183C17.737 14.6586 17.992 14.4036 18.1518 14.09C18.3334 13.7335 18.3334 13.2668 18.3334 12.3333V5.16667C18.3334 4.23325 18.3334 3.76654 18.1518 3.41002C17.992 3.09641 17.737 2.84144 17.4234 2.68166C17.0669 2.5 16.6002 2.5 15.6667 2.5H15.3334C13.4666 2.5 12.5332 2.5 11.8201 2.86331C11.1929 3.18289 10.683 3.69282 10.3634 4.32003C10.0001 5.03307 10.0001 5.96649 10.0001 7.83333" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`;
    case LESSON_TYPE.QUIZ:
      return `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M10.5 1.70215V4.80005C10.5 5.22009 10.5 5.43011 10.5817 5.59055C10.6537 5.73167 10.7684 5.8464 10.9095 5.91831C11.0699 6.00005 11.28 6.00005 11.7 6.00005H14.7979M10.5 12.75H6M12 9.75H6M15 7.49117V12.9C15 14.1601 15 14.7902 14.7548 15.2715C14.539 15.6948 14.1948 16.039 13.7715 16.2548C13.2902 16.5 12.6601 16.5 11.4 16.5H6.6C5.33988 16.5 4.70982 16.5 4.22852 16.2548C3.80516 16.039 3.46095 15.6948 3.24524 15.2715C3 14.7902 3 14.1601 3 12.9V5.1C3 3.83988 3 3.20982 3.24524 2.72852C3.46095 2.30516 3.80516 1.96095 4.22852 1.74524C4.70982 1.5 5.33988 1.5 6.6 1.5H9.00883C9.55916 1.5 9.83432 1.5 10.0933 1.56217C10.3229 1.61729 10.5423 1.7082 10.7436 1.83156C10.9707 1.9707 11.1653 2.16527 11.5544 2.55442L13.9456 4.94558C14.3347 5.33473 14.5293 5.5293 14.6684 5.75636C14.7918 5.95767 14.8827 6.17715 14.9378 6.40673C15 6.66568 15 6.94084 15 7.49117Z" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`;

    case LESSON_TYPE.BLOCK:
      return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M14.1666 8.33333V6.66667C14.1666 4.36548 12.3011 2.5 9.99992 2.5C7.69873 2.5 5.83325 4.36548 5.83325 6.66667V8.33333M9.99992 12.0833V13.75M7.33325 17.5H12.6666C14.0667 17.5 14.7668 17.5 15.3016 17.2275C15.772 16.9878 16.1544 16.6054 16.3941 16.135C16.6666 15.6002 16.6666 14.9001 16.6666 13.5V12.3333C16.6666 10.9332 16.6666 10.2331 16.3941 9.69836C16.1544 9.22795 15.772 8.8455 15.3016 8.60582C14.7668 8.33333 14.0667 8.33333 12.6666 8.33333H7.33325C5.93312 8.33333 5.23306 8.33333 4.69828 8.60582C4.22787 8.8455 3.84542 9.22795 3.60574 9.69836C3.33325 10.2331 3.33325 10.9332 3.33325 12.3333V13.5C3.33325 14.9001 3.33325 15.6002 3.60574 16.135C3.84542 16.6054 4.22787 16.9878 4.69828 17.2275C5.23306 17.5 5.93312 17.5 7.33325 17.5Z" stroke="#D0D5DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
    case LESSON_TYPE.REFERENCE:
      return `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
<path d="M14.6666 8.7513V5.66797C14.6666 4.26784 14.6666 3.56777 14.3941 3.03299C14.1544 2.56259 13.772 2.18014 13.3016 1.94045C12.7668 1.66797 12.0667 1.66797 10.6666 1.66797H5.33325C3.93312 1.66797 3.23306 1.66797 2.69828 1.94045C2.22787 2.18014 1.84542 2.56259 1.60574 3.03299C1.33325 3.56777 1.33325 4.26784 1.33325 5.66797V14.3346C1.33325 15.7348 1.33325 16.4348 1.60574 16.9696C1.84542 17.44 2.22787 17.8225 2.69828 18.0622C3.23306 18.3346 3.93312 18.3346 5.33325 18.3346H7.99992M9.66659 9.16797H4.66659M6.33325 12.5013H4.66659M11.3333 5.83464H4.66659M12.9999 17.5013V12.5013M10.4999 15.0013H15.4999" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

    default:
      return "";
  }
};

export function bytesToMegabytesBinary(bytes: number) {
  return (bytes / 1048576).toFixed(3);
}

export function convertNumberingTypeToText(number: number, formatType: number) {
  const upperAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowerAlphabet = "abcdefghijklmnopqrstuvwxyz";
  const romanNumerals = [
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
    "X",
    "XI",
    "XII",
    "XIII",
    "XIV",
    "XV",
    "XVI",
    "XVII",
    "XVIII",
    "XIX",
    "XX",
  ];

  switch (formatType) {
    case 1:
      return upperAlphabet[number - 1] || "";
    case 2:
      return lowerAlphabet[number - 1] || "";
    case 3:
      return number.toString();
    case 4:
      return romanNumerals[number - 1] || "";
    default:
      return "";
  }
}
export const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const convertSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return {
    hours: hours <= 9 ? "0" + hours : hours,
    minutes: minutes <= 9 ? "0" + minutes : minutes,
    seconds: remainingSeconds <= 9 ? "0" + remainingSeconds : remainingSeconds,
  };
};

export function getTimeDifference(time1: any, time2: any) {
  if (!time1 || !time2) return "00:00:00";

  const mTime1 = moment(time1);
  const mTime2 = moment(time2);

  const duration = moment.duration(mTime2.diff(mTime1));

  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds(); // Làm tròn giây đến hai chữ số thập phân

  return hours > 0
    ? `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
    : `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
}

export function formatSecondTimeToString(seconds: number | undefined | null) {
  if (!seconds) return "00:00:00";
  // Tính số giờ, phút và giây
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsLeft = seconds % 60;

  // Chuyển đổi mỗi giá trị thành chuỗi và thêm số '0' nếu cần
  const paddedHours = hours.toString().padStart(2, "0");
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedSeconds = secondsLeft.toString().padStart(2, "0");

  // Kết hợp thành chuỗi định dạng "hh:mm:ss"
  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

export const getCleanText = (value: string) => {
  return DOMPurify.sanitize(value);
};

export const checkAndCountdown = (targetDate: Date): number => {
  const currentDate = new Date();
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
  const diffInTime = targetDate.getTime() - currentDate.getTime();
  const daysDifference = Math.ceil(diffInTime / oneDay); // Sử dụng Math.ceil để đảm bảo số ngày đúng
  return daysDifference;
};

export const getInitials = (name: string) => {
  if (!name) return "";
  const words = name.trim().split(/\s+/);
  if (words.length === 1) {
    return words[0];
  }
  const firstInitial = words[0][0].toUpperCase();
  const lastInitial = words[words.length - 1][0].toUpperCase();

  return firstInitial + lastInitial;
};

export const stripHtmlTags = (html: string): string => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};
interface YearRange {
  startOfYear: Date;
  endOfYear: Date;
}

export const getStartAndEndOfYear = (date: Date): YearRange => {
  const year = date.getFullYear();

  const startOfYear = new Date(year, 0, 1);
  const endOfYear = new Date(year, 11, 31);

  return {
    startOfYear,
    endOfYear,
  };
};
