// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-modal .ant-modal-content {
  padding: 24px !important;
  width: 400px;
  height: 260px;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/modal/delete-modal/style.scss"],"names":[],"mappings":"AACE;EACE,wBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;AAAJ","sourcesContent":[".delete-modal {\n  .ant-modal-content {\n    padding: 24px !important;\n    width: 400px;\n    height: 260px;\n    overflow: hidden;\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
