"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.executeBodyPose = exports.initialBodyPose = exports.executeCard = exports.initialCard = exports.executeFace = exports.initialFace = void 0;
const components_face_1 = require("./components_face");
Object.defineProperty(exports, "initialFace", { enumerable: true, get: function () { return components_face_1.initial; } });
Object.defineProperty(exports, "executeFace", { enumerable: true, get: function () { return components_face_1.execute; } });
const components_card_1 = require("./components_card");
Object.defineProperty(exports, "initialCard", { enumerable: true, get: function () { return components_card_1.initial; } });
Object.defineProperty(exports, "executeCard", { enumerable: true, get: function () { return components_card_1.execute; } });
const components_body_pose_1 = require("./components_body_pose");
Object.defineProperty(exports, "initialBodyPose", { enumerable: true, get: function () { return components_body_pose_1.initial; } });
Object.defineProperty(exports, "executeBodyPose", { enumerable: true, get: function () { return components_body_pose_1.execute; } });
