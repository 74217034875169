import {
  DeleteOutlined,
  EyeInvisibleOutlined,
  HomeOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Popover, Select, Spin } from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import MoocBreadcrumb from "../../components/breadcrumb";
import EmptyComponent from "../../components/empty";
import CustomPagination from "../../components/pagination/Pagination";
import { routesConfig } from "../../config/routes";
import {
  DEFAULT_LOADING_TIME,
  DEFAULT_PAGE_NUM,
  DEFAULT_PAGE_SIZE,
  SORT_NOTIFICATIONS,
} from "../../constants";
import useDebounce from "../../hooks/useDebounce";
import useKeyword from "../../hooks/useKeyword";
import DeleteModal from "../../modal/delete-modal";
import { HelpService } from "../../service/helper.service";
import {
  deleteAllNotifications,
  deleteNotification,
  editAllNotificationsViewed,
  editNotificationViewed,
  searchNotifications,
} from "../../service/notification";
import { useNotificationStore } from "../../stores/stores";
import {
  NotificationData,
  NotificationItemProps,
} from "../../types/notification";
import NotificationFilter from "./components/NotificationFilter";
import NotificationItem from "./components/NotificationItem";
import "./style.scss";
import { notifySuccess } from "../../utils/notification";
import { useNavigate } from "react-router-dom";

function NotificationPage() {
  const navigate = useNavigate();
  const helpService = new HelpService();
  const [notifyData, setNotifyData] = useState<NotificationData>({
    totalElements: 0,
  });
  const [notifications, setNotifications] = useState<NotificationItemProps[]>(
    []
  );
  const [loadingNotifications, setLoadingNotifications] =
    useState<boolean>(false);
  const [openPopup, setOpen] = useState<boolean>(false);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);

  //------------ payload ---------------
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [pageNum, setPageNum] = useState<number>(DEFAULT_PAGE_NUM);
  const [isViewed, setIsViewed] = useState<boolean | undefined>();
  const [keySearch, setKeySearch] = useState<string>("");
  const [createdFrom, setCreatedFrom] = useState<string | undefined>("");
  const [createdTo, setCreatedTo] = useState<string | undefined>("");
  const [createdSort, setCreatedSort] = useState<string | undefined>("");
  const keywordDebounce = useDebounce(keySearch, 500);
  //-------------------------------------
  const notifyIdClick = Number(useKeyword("id"));
  const { refetch, onRefetch } = useNotificationStore();

  useEffect(() => {
    if (notifyIdClick) {
      const targetElement = document.getElementById(
        `${notifyIdClick}`
      ) as HTMLElement;

      targetElement?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      handleUnSeen(notifyIdClick);
    }
  }, [notifyIdClick]);

  useEffect(() => {
    const getNotifications = async () => {
      try {
        setLoadingNotifications(true);
        const res = await searchNotifications({
          page: pageNum,
          size: pageSize,
          keyword: keywordDebounce ? keywordDebounce.trim() : undefined,
          isViewed: isViewed,
          createdFrom: createdFrom,
          createdTo: createdTo,
          createdSort: createdSort ? createdSort : undefined,
        });
        setNotifyData(res.data);
        setNotifications(res.data.content);
      } catch (err: any) {
        helpService.errorMessage(err);
      } finally {
        setTimeout(() => setLoadingNotifications(false), DEFAULT_LOADING_TIME);
      }
    };
    getNotifications();
  }, [
    pageNum,
    pageSize,
    keywordDebounce,
    isViewed,
    createdFrom,
    createdTo,
    createdSort,
    refetch,
    onRefetch,
  ]);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleRemoveIdFromUrl = () => {
    navigate(routesConfig.notification);
  };

  const handleChangeFilter = (values: any) => {
    setPageNum(1)
    //Lọc theo thời gian
    const from = !isEmpty(values?.date) ? dayjs(values?.date[0]) : undefined;
    const to = !isEmpty(values?.date) ? dayjs(values?.date[1]) : undefined;
    setCreatedFrom(from ? from?.toISOString() : undefined);
    setCreatedTo(to ? to?.toISOString() : undefined);

    //Lọc theo keyword
    setKeySearch(values?.keyword);
    //Lọc thông báo chưa xem/đã xem
    switch (values?.seen) {
      case 0:
        setIsViewed(undefined);
        break;
      case 1:
        setIsViewed(false);
        break;
      case 2:
        setIsViewed(true);
        break;
      default:
        setIsViewed(undefined);
        break;
    }
  };

  const handleChangeSort = (value: number) => {
    setPageNum(1)
    if (notifyIdClick) handleRemoveIdFromUrl();
    switch (value) {
      case 0:
        setCreatedSort(undefined);
        break;
      case 1:
        setCreatedSort("desc");
        break;
      case 2:
        setCreatedSort("asc");
        break;
      default:
        setCreatedSort(undefined);
        break;
    }
  };

  const renderSettingContent = () => {
    return (
      <div className="setting-popover-list">
        <div className="popover-item" onClick={handleViewedAll}>
          <EyeInvisibleOutlined />
          Đánh dấu là xem hết
        </div>
        <div
          className="popover-item"
          onClick={() => {
            setOpenModalConfirm(true);
          }}
        >
          <DeleteOutlined />
          Xoá toàn bộ
        </div>
      </div>
    );
  };

  const handleViewedAll = async () => {
    try {
      await editAllNotificationsViewed();
      onRefetch();
    } catch (err: any) {
      console.error(err);
    } finally {
      hide();
    }
  };

  const handleSeen = async (id?: number, isViewed?: boolean) => {
    if (!isViewed) {
      try {
        await editNotificationViewed(id as number, !isViewed);
        onRefetch();
      } catch (err: any) {
        console.error(err);
      }
    } else return;
  };

  const handleUnSeen = async (id?: number, isViewed?: boolean) => {
    if (isViewed) {
      try {
        await editNotificationViewed(id as number, !isViewed);
        onRefetch();
      } catch (err: any) {
        console.error(err);
      }
    } else return;
  };

  const handleConfirm = () => {
    handleDeleteAllNotifications();
  };

  const handleDeleteAllNotifications = async () => {
    try {
      await deleteAllNotifications();
      onRefetch();
      setOpenModalConfirm(false);
      notifySuccess("Xoá thông báo thành công");
    } catch (err: any) {
      helpService.errorMessage(err);
    } finally {
      hide();
    }
  };

  const handleDeleteNotification = async (id?: number) => {
    try {
      await deleteNotification(id as number);
      onRefetch();
      notifySuccess("Xoá thông báo thành công");
    } catch (err: any) {
      helpService.errorMessage(err);
    }
  };

  const renderNotificationItem = () => {
    if (isEmpty(notifications))
      return (
        <div className="notification-list pb-2 pt-2">
          <EmptyComponent description="Không có kết quả" />
        </div>
      );

    return notifyData?.content?.map((item: NotificationItemProps) => (
      <NotificationItem
        key={item.id}
        {...item}
        onClickViewed={handleSeen}
        onClickUnView={handleUnSeen}
        onShowMore={handleUnSeen}
        onRemove={handleDeleteNotification}
        isViewed={item.isViewed}
      />
    ));
  };

  return (
    <div className="notify-content-layout">
      <MoocBreadcrumb
        items={[
          {
            href: "/",
            title: <HomeOutlined />,
          },
          {
            href: routesConfig.notification,
            title: (
              <>
                <span>Thông báo của tôi</span>
              </>
            ),
          },
        ]}
      />
      <NotificationFilter
        onClearId={handleRemoveIdFromUrl}
        onChange={handleChangeFilter}
      />
      <div className="notify-header flex align-end justify-space-between mt-4   ">
        <div className="notify-sort">
          <p className="font-size-14 text-secondary mb-1">
            <b>Sắp xếp theo</b>
          </p>
          <Select
            defaultValue={0}
            onChange={handleChangeSort}
            className="height-44 font-weight-6 w-200"
            options={SORT_NOTIFICATIONS}
          />
        </div>

        {(notifyData?.totalElements as number) > 0 && (
          <Popover
            open={openPopup}
            content={renderSettingContent()}
            trigger="click"
            placement="bottomRight"
            overlayClassName="notification-popup"
            onOpenChange={handleOpenChange}
          >
            <div className="notify-setting center">
              <SettingOutlined />
            </div>
          </Popover>
        )}
      </div>

      <Spin spinning={loadingNotifications}>
        <div className="notification-list mt-3">{renderNotificationItem()}</div>
      </Spin>
      {(notifyData?.totalElements as number) > 0 && (
        <CustomPagination
          totalPage={notifyData.totalElements}
          pageSize={pageSize}
          pageNumber={pageNum}
          setPageSize={(value) => setPageSize(value)}
          setPageNumber={(value) => setPageNum(value)}
        />
      )}
      <DeleteModal
        desc={{
          title: "Xác nhận xoá thông báo",
          notice: "Thông báo đã xoá sẽ không thể khôi phục.",
        }}
        open={openModalConfirm}
        handleOk={handleConfirm}
        handleCancel={() => setOpenModalConfirm(false)}
      />
    </div>
  );
}

export default NotificationPage;
