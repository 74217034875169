import React from "react";
import {
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  ComposedChart,
} from "recharts";
import LegendCustom from "./components/LegendCustom";
import { ChartProps } from "../../types/chart";
import { Flex, Select } from "antd";
import { Circle } from "./components/icons";
import { isEmpty } from "lodash";
import { renderTotal } from "./components/ultis";
import EmptyComponent from "../empty";
import { DEFAULT_MODULE_GROUP } from "../../constants/statistics";

const data = [
  { name: "24/7", evaluations: 4000, accesses: 2400 },
  { name: "25/7", evaluations: 3000, accesses: 1398 },
  { name: "26/7", evaluations: 3200, accesses: 9800 },
  { name: "27/7", evaluations: 5000, accesses: 3908 },
  { name: "28/7", evaluations: 2000, accesses: 4800 },
  { name: "29/7", evaluations: 1000, accesses: 3800 },
  { name: "30/7", evaluations: 4300, accesses: 4300 },
];

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          background: "#fff",
          padding: "10px",
          borderRadius: "10px",
          boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Flex gap={8} align="center">
          <Circle fill="#BDB4FE" /> {`${payload[0]?.name}: ${payload[0]?.value}`}
        </Flex>
        <Flex gap={8} align="center">
          <div className="line-icon ml-1" />{" "}
          {`${payload[1]?.name}: ${payload[1]?.value}`}
        </Flex>
      </div>
    );
  }

  return null;
};

interface BarChartVerticalProps extends ChartProps {
  borderRadius?: number;
  barDataKey?: string;
  barName?: string;
  lineName?: string;
  lineDataKey?: string
}
function BarChartVertical({
  title = "",
  data,
  legends = [],
  total,
  xDataKey = "",
  defaultSelectedValue = DEFAULT_MODULE_GROUP,
  barName = "",
  lineName = "",
  barDataKey = "",
  lineDataKey = "",
  showFilter = false,
  filterOptions = [],
  placeholder = "",
  onChange = () => {},
}: BarChartVerticalProps) {
  return (
    <div className="wrapper-chart">
      <Flex justify="space-between">
        <div className="chart-title font-weight-5 font-size-18">{title}</div>
        {showFilter && (
          <Select
          defaultValue={defaultSelectedValue}
            className="h-40 w-filter"
            placeholder={placeholder}
            onChange={onChange}
            options={filterOptions}
          />
        )}
      </Flex>
      <div className="break-line"></div>
      {isEmpty(data) ? (
        <div className="h-default">
          <EmptyComponent description="Không có kết quả" />
        </div>
      ) : (
        <div>
          {renderTotal(total)}
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 0" vertical={false} />
              <XAxis dataKey={xDataKey} />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                dataKey={barDataKey}
                barSize={40}
                fill="#BDB4FE"
                name={barName}
              />
              <Line
                type="monotone"
                dataKey={lineDataKey}
                stroke="#7A5AF8"
                strokeWidth={2}
                name={lineName}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <LegendCustom items={legends} />{" "}
        </div>
      )}
    </div>
  );
}

export default BarChartVertical;
