import React from "react";
import DocumentTable from "../document-table/DocumentTable";

interface FilterParamsProps {
  documentType: string;
  keyword: string;
  section: string;
  sequence: string;
}
type Props = {
  filterParams: FilterParamsProps;
  documentTab: string;
  setDocumentDownList: (data: any) => void;
};
const FavoriteDocuments = (props: Props) => {
  const { filterParams, documentTab, setDocumentDownList } = props;
  return (
    <div>
      <DocumentTable
        filterParams={filterParams}
        documentTab={documentTab}
        setDocumentDownList={setDocumentDownList}
      />
    </div>
  );
};

export default FavoriteDocuments;
