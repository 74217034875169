import { Tabs } from "antd";
import { useEffect, useState } from "react";
import ProfileDocument from "../../components/pages/profile-document/ProfileDocument";
import { getIndustry, getSkills, getUniversityForStudent } from "../../service/personal/personalApi";
import { useUserInfoStore } from "../../stores/stores";
import InfoAccount from "./components/InfoAccount";
import "./info-user.scss";
import { getInterest } from "../register/services";
import { get } from "lodash";
type DataOptions = {
  dataProvinces: any[];
  dataDistricts: any[];
  dataWards: any[];
  dataUniversity: any[];
  dataIndustry: any[];
  dataEducationLevel: any[];
  hobbies: any[];
  dataCountry: any[];
};

const InfoUser = () => {
  const { setUserInfo } = useUserInfoStore();
  const [dataUser, setDataUser] = useState({
    email: "",
    language: "",
    timeZone: "",
  });

  const [dataOptions, setDataOptions] = useState<DataOptions>({
    dataProvinces: [],
    dataDistricts: [],
    dataWards: [],
    dataUniversity: [],
    dataIndustry: [],
    dataEducationLevel: [],
    hobbies: [],
    dataCountry: [],
  });

  const fetchDataOptions = () => {
    try {
      Promise.all([
        getUniversityForStudent(),
        getIndustry(),
        getSkills(),
        getInterest(),
      ]).then((res) => {
        const university = res[0].data.data;
        const industry = res[1].data.content;
        const skills = res[2].data.data;
        const hobbies = res[3].data.data.data;

        setDataOptions((prevOptions) => ({
          ...prevOptions,
          dataUniversity: university?.map((data: any) => ({
            value: data.id,
            label: data.name,
          })),
          dataIndustry: industry?.map((data: any) => ({
            value: data.id,
            label: data.name,
          })),
          dataEducationLevel: skills?.map((data: any) => ({
            value: data.id,
            label: data.levelName,
          })),
          hobbies: hobbies?.map((data: any) => ({
            value: data.id,
            label: data.name,
          })),
        }));
      });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchDataOptions();
  }, []);
  const items = [
    {
      key: "infoAccount",
      label: "Thông tin tài khoản",
      children: <InfoAccount dataUser={dataUser} />,
    },
    {
      key: "profileDocument",
      label: "Hồ sơ cá nhân",
      children: <ProfileDocument
        universityList={get(dataOptions, "dataUniversity")}
        educationLevelList={get(dataOptions, "dataEducationLevel")}
        industryList={get(dataOptions, "dataIndustry")}
        hobbyList={get(dataOptions, "hobbies")}
      />,
    },
    {
      key: "settingAccount",
      label: "Cài đặt",
      children: <ProfileDocument
        universityList={get(dataOptions, "dataUniversity")}
        educationLevelList={get(dataOptions, "dataEducationLevel")}
        industryList={get(dataOptions, "dataIndustry")}
        hobbyList={get(dataOptions, "hobbies")}
      />,
    },
  ];

  return (
    <div className="info-user">
      <div className="info-user-content">
        <div className="text-title-30">Thông tin cá nhân</div>
        <div className="hr-tag"></div>
        <Tabs tabPosition="right" items={items} />
      </div>
    </div>
  );
};

export default InfoUser;
