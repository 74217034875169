import { Button, Popover, Progress } from "antd";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import CommentModal from "../../comment-modal/review-modal/CommentModal";
import "./MyCourseCard.scss";
import { getStartLearningCourse } from "../../../service/my-course/my-course-service";
import { notifyWarning } from "../../../utils/notification";
type Props = {
  id?: number;
  name?: string;
  teachers?: Array<any>;
  completedPercentage?: number;
  publicDate?: string;
  enterpriseLogo?: string;
  learningStatus?: number;
  image?: string;
  isCommented?: boolean;
  isAbleToComment: boolean;
};

const MyCourseCard = (props: Props) => {
  const {
    id,
    name,
    teachers,
    completedPercentage,
    publicDate,
    enterpriseLogo,
    learningStatus,
    image,
    isAbleToComment,
  } = props;

  const navigate = useNavigate();
  const [isComment, setIsComment] = useState<boolean>(false);
  const [myCourseId, setMyCourseId] = useState<number>();
  const handleUpgrade = () => {
    notifyWarning("Chức năng đang phát triển");
  };
  const popoverContent = (id: number) => {
    return (
      <div className="my-course-popover__content">
        <Button
          className="popover-item"
          onClick={() => navigate(`${routesConfig.courseInfo}/detail/${id}`)}
          type="text"
        >
          <img
            src="/assets/icons/eye-icon.svg"
            alt="search"
            className="popover-item-icon"
          />
          Xem chi tiết
        </Button>
        <Button className="popover-item" type="text" onClick={handleUpgrade}>
          <img
            src="/assets/icons/zap-icon.svg"
            alt="search"
            className="popover-item-icon"
          />
          Upgrade
        </Button>
        <Button
          className="popover-item"
          onClick={() => {
            setMyCourseId(id);
            setIsComment(true);
          }}
          disabled={!isAbleToComment}
          type="text"
        >
          <img
            src="/assets/icons/message-text-circle.svg"
            alt="search"
            className="popover-item-icon"
          />
          Đánh giá khoá học
        </Button>
      </div>
    );
  };

  const goLearning = () => {
    navigate(`${routesConfig.learningCourseStructure}/${id}`);
  };

  const startLearningCourse = async () => {
    try {
      getStartLearningCourse(Number(id));
      navigate(`${routesConfig.learningCourseStructure}/${id}`);
    } catch (error) {}
  };

  const renderBtn = () => {
    switch (learningStatus) {
      case 1:
        return (
          <Button className="start-learn-btn" onClick={startLearningCourse}>
            Bắt đầu học
          </Button>
        );
      case 2:
        return (
          <Button className="start-learn-btn" onClick={goLearning}>
            Tiếp tục học
          </Button>
        );
      case 3:
        return (
          <Button className="start-learn-btn" onClick={goLearning}>
            Học lại
          </Button>
        );
      default:
        break;
    }
  };
  return (
    <div className="my-course-card">
      <div className="card-banner">
        <img
          src={image ? image : "/assets/img/3_2 screen-mockup.png"}
          alt="search"
          className="card-banner-img"
        />
        <img
          src={enterpriseLogo ? enterpriseLogo : "/assets/img/app-logo.png"}
          alt="search"
          className="card-banner-avatar"
        />
      </div>
      <div className="card-content">
        <div>
          <div className="lecturer">
            <div className="lecturer-name">
              Giảng dạy:&nbsp;
              {teachers?.map((it: any, index: number) => (
                <span key={index}>
                  {it}
                  {index !== (teachers?.length ?? 0) - 1 && ", "}
                  &nbsp;
                </span>
              ))}
            </div>
            <Popover
              placement="bottomLeft"
              content={id ? popoverContent(id) : null}
              className="card-popover"
              arrowPointAtCenter
            >
              <Button className="card-popover-btn">
                <img
                  src="/assets/icons/dots-vertical.svg"
                  alt="search"
                  className="card-popover-img"
                />
              </Button>
            </Popover>
          </div>
          <div className="course-name">{name}</div>
        </div>
        <div>
          <Progress percent={completedPercentage} />
          <div className="publication-date">
            Ngày xuất bản:{" "}
            {publicDate ? moment(publicDate).format("DD/MM/YYYY") : undefined}
          </div>
          <div className="hr-tag-item"></div>
          {renderBtn()}
        </div>
      </div>
      <CommentModal
        open={isComment}
        title="Nhận xét của bạn về khoá học"
        handleCancel={() => setIsComment(false)}
        handleOk={() => setIsComment(false)}
        courseId={myCourseId}
      />
    </div>
  );
};

export default MyCourseCard;
