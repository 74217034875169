import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import VoteStar from "../../vote-star";
import "./LearningReviewModal.scss";

type Props = {
  open: boolean;
  handleOk: () => void;
  handleCancel?: () => void;
  setStarRating?: (star: number) => void;
  title?: string;
  content?: string;
  confirmIcon?: string;
  btnCancel?: boolean;
  confirmBtntitle?: string;
  loading?: boolean;
};

const LearningReviewModal = (props: Props) => {
  const {
    open,
    handleOk,
    handleCancel,
    title,
    content,
    confirmIcon = `${process.env.PUBLIC_URL}/assets/icons/star-01.svg`,
    confirmBtntitle = "Gửi đánh giá",
    loading = false,
    setStarRating,
  } = props;
  const [voteProps, setVoteProps] = useState<number>(5);

  useEffect(() => {
    if (setStarRating) setStarRating(voteProps);
  }, [voteProps]);

  return (
    <Modal
      open={open}
      title={
        <div className="title-container">
          <img
            className="bg-icon-img"
            loading="eager"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/bg_log-out-popup.png`}
          />

          <div className="bg-icon-circle">
            <img
              className="learning-review-icon"
              src={confirmIcon}
              alt={"icon"}
            />
          </div>
        </div>
      }
      className="learning-review"
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      footer={
        <div className="footer-action">
          <Button
            onClick={handleOk}
            className={"footer-action-confirm-full"}
            loading={loading}
          >
            {confirmBtntitle}
          </Button>
        </div>
      }
    >
      <div className="learning-review-title">{title}</div>
      <div className="learning-review-content">{content}</div>
      <div className="mt-3">
        <VoteStar setVoteProps={setVoteProps} star={5} />
      </div>
    </Modal>
  );
};

export default LearningReviewModal;
