import { Button, Select } from "antd";
import React, { memo } from "react";
import { KeyValue } from "../../types/app";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import './style.scss'

interface SimpleChart {
  title?: string;
  description?: string;
  selectOptions?: KeyValue[];
  xKey?: string;
  yKey?: string;
  xDescription?: string;
  yDescription?: string;
  barKey?: string;
  data?: any[];
  barWidth?: number;
  barSize?: number;
  tooltipText?: string;
  tooltipLabel?: string;
  onChangeSort?: (value: any, item?: KeyValue) => void;
  onExportExcel?: (val?: any) => void;
}

function CourseChart({
  title = "Số lượng khoá học trên MOOC theo năm",
  description = "Số lượng khoá học trên MOOC theo năm từ 2024 đến nay",
  selectOptions = [{ label: "Mặc định", value: 0 }],
  data = [],
  xKey,
  yKey,
  xDescription,
  yDescription,
  tooltipText = "",
  tooltipLabel = "",
  onChangeSort = () => {},
  onExportExcel = () => {},
}: SimpleChart) {
  const curData =
    data.length > 0
      ? data
      : Array(12)
          .fill(1)
          .map((item, index) => ({
            year: 2000 + index,
            total: Math.floor(Math.random() * 1001),
          }));

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${tooltipLabel || label}: ${payload[0].value} ${tooltipText}`}</p>
        </div>
      );
    }

    return null;
  };

  const handleChangeSort = (value: number | string) => {
    onChangeSort(value);
  };

  return (
    <div className="statistics-chart">
      <div className="flex justify-space-between statistics-chart-header">
        <div className="left-wrap">
          <h4 className="font-size-24 mb-1">{title}</h4>
          <span className="font-size-14 text-secondary">{description}</span>
        </div>
        <div className="right-wrap flex gap-24">
          <div className="soft-wrap">
            <p className="font-size-14 mb-1">Sắp xếp theo</p>
            <Select
              onChange={handleChangeSort}
              className="default-w-input default-h-input"
              options={selectOptions}
              defaultValue={0}
            />
          </div>
          <div className="flex justify-end flex-column">
            <Button
              className="default-w-input default-h-input"
              onClick={onExportExcel}
            >
              <b>Xuất dữ liệu Excel</b>
            </Button>
          </div>
        </div>
      </div>
      <div className="break-line">&nbsp;</div>

      <div className="chart-content flex justify-center align-center">
        <span className="x-title text-black">
          {" "}
          {yDescription || "Số lượng"}
        </span>
        <div className="chart-content-right">
          <ResponsiveContainer className="chart-in">
            <BarChart width={700} height={100} data={curData}>
              <XAxis dataKey={xKey || "year"} />
              <YAxis dataKey={yKey || "total"} />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                radius={[5, 5, 0, 0]}
                dataKey={yKey || "total"}
                width={20}
                barSize={32}
                fill="#9E77ED"
              />
            </BarChart>
          </ResponsiveContainer>
          <div className="center mb-2">
            <span className="text-black">
              {" "}
              {xDescription || "Năm phát hành"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(CourseChart);
