import React, { ReactNode } from "react";
import { Button, Modal } from "antd";
import "./ReportModal.scss";

type Props = {
  open: boolean;
  handleOk: () => void;
  handleCancel?: () => void;
  title?: string;
  content?: ReactNode;
  confirmIcon?: string;
  btnCancel?: boolean;
  confirmBtntitle?: string;
};

const ReportModal: React.FC<Props> = ({
  open,
  handleOk,
  handleCancel,
  title,
  content,
  confirmBtntitle = "Xác nhận",
}) => {
  return (
    <Modal
      open={open}
      title={
        <div className="title-container">
          <img
            className="bg-icon-img"
            loading="eager"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/bg_log-out-popup.png`}
          />
          <div className="confirm-modal-title">{title}</div>
        </div>
      }
      className="report-discuss-modal"
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      footer={
        <div className="footer-action">
          <Button onClick={handleCancel} className="footer-action-cancel">
            Huỷ
          </Button>
          <Button onClick={handleOk} className="footer-action-confirm">
            {confirmBtntitle}
          </Button>
        </div>
      }
    >
      <div className="confirm-modal-content">{content}</div>
    </Modal>
  );
};

export default ReportModal;
