import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import { suggestedForYou } from "../../service/saved-course/saved-course-service";
import { globalStore } from "../../stores/stores";
import { CurriculumList } from "../../types/curriculum";
import { objectToQueryString } from "../../utils/getPageFromLink";
import CourseCard, { CourseCardProps } from "../card/CourseCard";
import ResponsiveList from "../list/ResponsiveList";
import "./RelevantSimilarCourses.scss";
import { mapCourseInfoBeToFe } from "../../utils/format";
import { CourseInfo } from "../../types/course";

type Props = {};

const RelevantSimilarCourses = (props: Props) => {
  const navigate = useNavigate();
  const { setFooterDefault } = globalStore();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [recommendList, setRecommendList] = useState<CourseCardProps[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const renderCard = (courses: any) => {
    if (!courses.length) return [];

    return courses?.map((item: CourseCardProps) => ({
      content: (
        <CourseCard
          key={item.id}
          {...item}
          onClick={() =>
            navigate(`${routesConfig.courseInfo}/detail/${item.id}`)
          }
          isPopular={false}
          teachers={item.teachers?.filter(
            (teacher: string, index) => teacher !== " "
          )}
        />
      ),
    }));
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const params = {
        page: pageNumber,
        size: 3,
      };
      const response = await suggestedForYou(objectToQueryString(params));
      const { data } = response.data;
      const recommendData = data.courses.map((item: CourseInfo) =>
        mapCourseInfoBeToFe(item)
      );
      setTotalRecords(data.total);
      setRecommendList(
        recommendData?.map((item: CourseCardProps) => ({
          content: (
            <CourseCard
              key={item.id}
              {...item}
              onClick={() =>
                navigate(`${routesConfig.courseInfo}/detail/${item.id}`)
              }
              teachers={item.teachers?.filter(
                (teacher: string, index) => teacher !== " "
              )}
            />
          ),
        }))
      );
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };
  useEffect(() => {
    getData();
  }, [pageNumber]);

  useEffect(() => {
    setFooterDefault();
  }, []);

  return (
    <div className="relevant-similar-courses">
      <div className="text-title-30 flex justify-between align-center">
        Có thể bạn quan tâm
        <Button
          onClick={() => navigate(routesConfig.courseInfo)}
          className="btn-primary height-44"
        >
          Danh sách khoá học
        </Button>
      </div>
      <div className="font-size-16 text-desc">
        Các khoá học tương tự phù hợp với bạn
      </div>
      <ResponsiveList
        loading={isLoading}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 3,
        }}
        data={recommendList}
        showMenu={false}
        isViewReport={false}
      />
      <div className="action">
        <Button
          className="action-item"
          disabled={pageNumber === 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          <ArrowLeftOutlined />
        </Button>
        <Button
          className="action-item"
          disabled={pageNumber * 3 === totalRecords}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          <ArrowRightOutlined />
        </Button>
      </div>
    </div>
  );
};

export default RelevantSimilarCourses;
