import React, { ReactNode, useState } from "react";
import { Modal } from "antd";
import { GroupsExam, QuizProps } from "../../../../../../types/course";
import EmptyComponent from "../../../../../../components/empty";

interface ScoreDetailModalProps {
  children?: ReactNode;
  data: GroupsExam[];
}

function ScoreDetailModal({ children, data }: ScoreDetailModalProps) {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const displayScore = (quizzes: QuizProps[]) => {
    if (!quizzes?.length) return `0/0`;

    let scores = 0;
    let maxScores = 0;
    quizzes.forEach((quiz: QuizProps) => {
      if (quiz?.result) {
        scores += quiz?.result?.points;
        maxScores += quiz?.result?.totalPoints;
      }
    });
    return `${scores}/${maxScores}`;
  };

  const renderContent = () => {
    if (!data?.length)
      return (
        <div className="row center">
          <EmptyComponent description="Chưa có nhận xét" />
        </div>
      );

    return data?.map((group: GroupsExam, index: number) => (
      <div className="row" key={group?.id}>
        <div className="cell">
          Phần {index + 1 || "Không có tên"} <p>({group?.quiz?.length} câu)</p>
        </div>
        <div className="cell font-weight-6">{group?.title}</div>
        <div className="cell">{displayScore(group?.quiz)}</div>
      </div>
    ));
  };

  return (
    <div>
      <div onClick={showModal}>{children}</div>
      <Modal
        title={
          <span className="font-size-24 line-height-32 font-weight-6">
            Bảng điểm chi tiết
          </span>
        }
        width={708}
        open={open}
        maskClosable={false}
        centered
        className="score-detail-modal"
        onCancel={hideModal}
      >
        <div className="score-detail-table">
          <div className="row score-table-header">
            <div className="cell">Phần bài</div>
            <div className="cell">Tên phần</div>
            <div className="cell">Điểm số</div>
          </div>
          {renderContent()}
        </div>
      </Modal>
    </div>
  );
}

export default ScoreDetailModal;
