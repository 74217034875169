import { Avatar } from "antd";
import { useState } from "react";
import {
  deleteCourseComment,
  editCourseComment,
} from "../../../service/courses";
import { getFirstLetter } from "../../../utils/element";
import { getTimeAgo, renderStarRating } from "../../../utils/renderStarRating";
import CommentModal from "../review-modal/CommentModal";
import ConfirmReviewCard from "../confirm-review-modal/ConfirmReviewModal";
import "./ReviewCard.scss";

type Props = {
  itemData: any;
};

const ReviewCard = ({ itemData }: Props) => {
  const [isOpenDeleteReview, setIsOpenDeleteReview] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const contentModal = {
    title: "Xác nhận xoá bình luận",
    notice: "Bạn chắc chắn muốn xoá bình luận này?",
  };
  return (
    <div className="comment-card">
      <div className="flex align-center">
        <Avatar src={itemData?.imagePath} size={48} className="mt-2 mb-2">
          {getFirstLetter(itemData?.username)}
        </Avatar>
        <div className="info">
          <div className="flex-1">
            <div className="text-title-18 flex align justify-between align-center flex-1">
              {itemData?.username}
              {itemData?.isFromCurrentUser && (
                <div className="action">
                  <img
                    className="action-icon"
                    alt="icon"
                    src="/assets/icons/edit.svg"
                    onClick={() => setIsOpenEditModal(true)}
                  />
                  <img
                    className="action-icon"
                    alt="icon"
                    src="/assets/icons/trash.svg"
                    onClick={() => setIsOpenDeleteReview(true)}
                  />
                </div>
              )}
            </div>
            <div className="text-description-16 flex">
              {renderStarRating(itemData?.star)}
              <div className="ml-1">{getTimeAgo(itemData?.createdDate)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className=" text-description-16">{itemData?.comment}</div>
      <div className="hr-tag-item"></div>
      <ConfirmReviewCard
        open={isOpenDeleteReview}
        title=""
        mode="edit"
        desc={contentModal}
        handleOk={() => {
          setIsOpenDeleteReview(false);
        }}
        handleCancel={() => setIsOpenDeleteReview(false)}
        deleteApi={deleteCourseComment}
        id={itemData?.id}
        isDelete={true}
      />
      <CommentModal
        open={isOpenEditModal}
        title="Chỉnh sửa nhận xét về khoá học"
        handleCancel={() => setIsOpenEditModal(false)}
        handleOk={() => {
          setIsOpenEditModal(false);
        }}
        isEdit={true}
        itemData={itemData}
      />
    </div>
  );
};

export default ReviewCard;
