import React, { useEffect, useState } from "react";
import {
  CalendarOutlined,
  DownOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { Button, Col, DatePicker, Flex, Form, Row, Select } from "antd";
import { periodOfTimeOpt, unitOpt } from "../../../../constants/statistics";
import {
  fetchCourseLevel,
  fetchCourseList,
  fetchIndustryGroupFilter,
} from "../../../../service/statistics";
import dayjs, { Dayjs } from "dayjs";
import { isEmpty } from "lodash";
import './style.scss'

const { RangePicker } = DatePicker;

export interface FilterSearchValue {
  periodOfTime?: number;
  unit?: number | string;
  date?: any[];
  level?: number[];
  course?: number[];
  department?: number[];
}

interface IndustryGroupProps {
  name: string;
  id: number;
}

interface CourseLevelProps {
  name: string;
  id: number;
}

interface CourseFilterProps {
  code: number | string;
  enterpriseName: string;
  id: number;
  image: string;
  name: string;
}

interface FilterSearchProps {
  onChange?: (value: FilterSearchValue | undefined) => void;
}

function FilterSearch({ onChange = () => {} }: FilterSearchProps) {
  const [form] = Form.useForm();
  const periodOfTimeWatch = Form.useWatch("periodOfTime", form);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [fieldsValue, setFieldsValue] = useState<any>();
  const [industryGroupFilter, setIndustryGroupFilter] = useState<
    Array<IndustryGroupProps>
  >([]);
  const [courseFilter, setCourseFilter] = useState<Array<CourseFilterProps>>(
    []
  );
  const [courseLevel, setCourseLevel] = useState<Array<CourseLevelProps>>([]);

  const disableBtnFill =
    form.getFieldValue("periodOfTime") === -1 &&
    (!form.getFieldValue("unit") || isEmpty(form.getFieldValue("date")));

  useEffect(() => {
    const getIndustryGroupFilter = async () => {
      const res = await fetchIndustryGroupFilter();
      setIndustryGroupFilter(res.data.data);
    };
    const getCourseList = async () => {
      const res = await fetchCourseList({
        keyword: "",
        page: 1,
        size: 10000,
      });
      setCourseFilter(res.data.data);
    };
    const getCourseLevel = async () => {
      const res = await fetchCourseLevel();
      setCourseLevel(res.data.data);
    };
    getCourseLevel();
    getCourseList();
    getIndustryGroupFilter();
  }, []);

  const handleValuesChange = (_: any, values: any) => {
    setFieldsValue(values);
  };

  const handleClear = () => {
    form.resetFields();
    onChange(undefined);
  };

  const disabledDate = (current: Dayjs | null): boolean => {
    return current ? current.isAfter(dayjs().endOf("day")) : false;
  };

  return (
    <div className="report-filter-wrap">
      <Form form={form} onValuesChange={handleValuesChange}>
        <Flex align="flex-end" gap={12} className="flex-layout-custom">
          <Row gutter={[12, 12]} className="flex-1">
            <Col xs={24} md={12} xl={8} lg={8} xxl={8}>
              <label className="font-size-14" htmlFor="">
                Khoảng thời gian
              </label>
              <Form.Item
                name="periodOfTime"
                initialValue={periodOfTimeOpt[0].value}
              >
                <Select
                  className="h-40"
                  placeholder="Chọn khoảng thời gian"
                  options={periodOfTimeOpt}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8} lg={8} xxl={8}>
              <label className="font-size-14" htmlFor="">
                Đơn vị
              </label>
              <Form.Item name="unit">
                <Select
                  disabled={periodOfTimeWatch !== -1}
                  placeholder="Chọn đơn vị"
                  className="h-40"
                  options={unitOpt}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={8} lg={8} xxl={8}>
              <label className="font-size-14" htmlFor="">
                Chọn ngày
              </label>
              <Form.Item name="date">
                <RangePicker
                  disabled={periodOfTimeWatch !== -1}
                  suffixIcon={<CalendarOutlined />}
                  placeholder={["Chọn ngày", "Chọn ngày"]}
                  className="w-full h-40"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
          </Row>
          <Flex gap={8}>
            <Button
              className="h-40 btn-primary"
              icon={<FilterOutlined className="font-size-18" />}
              disabled={disableBtnFill}
              onClick={() => {
                onChange(fieldsValue);
              }}
            >
              Lọc
            </Button>
            <Button
              className="h-40 btn-filter"
              icon={
                <DownOutlined
                  className={`font-size-16 arrow-icon ${showMore ? "rotate-180" : ""}`}
                />
              }
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "Ẩn bộ lọc nâng cao" : "Bộ lọc nâng cao"}
            </Button>
          </Flex>
        </Flex>

        {showMore && (
          <Flex align="flex-end" gap={12} className="mt-3 flex-layout-custom">
            <Row gutter={[12, 12]} className="flex-1">
              <Col xs={24} md={12} xl={8} lg={8} xxl={8}>
                <label className="font-size-14" htmlFor="">
                  Cấp
                </label>
                <Form.Item name="level">
                  <Select
                    mode="multiple"
                    maxTagCount="responsive"
                    onSearch={(value: string) => console.log(value)}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    className="h-40"
                    placeholder="Chọn cấp"
                    options={courseLevel?.map((item: CourseLevelProps) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8} lg={8} xxl={8}>
                <label className="font-size-14" htmlFor="">
                  Khoa
                </label>
                <Form.Item name="department">
                  <Select
                    mode="multiple"
                    maxTagCount="responsive"
                    placeholder="Chọn khoa"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    className="h-40"
                    options={industryGroupFilter?.map(
                      (item: IndustryGroupProps) => ({
                        label: item.name,
                        value: item.id,
                      })
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} xl={8} lg={8} xxl={8}>
                <label className="font-size-14" htmlFor="">
                  Khoá học
                </label>
                <Form.Item name="course">
                  <Select
                    mode="multiple"
                    maxTagCount="responsive"
                    onSearch={(value: string) => console.log(value)}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    placeholder="Chọn khoa"
                    className="h-40"
                    options={courseFilter?.map((item: CourseFilterProps) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <b
              className="text-primary font-size-16 mb-1 pointer"
              onClick={handleClear}
            >
              Xoá bộ lọc
            </b>
          </Flex>
        )}
      </Form>
    </div>
  );
}

export default FilterSearch;
