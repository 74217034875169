import { Button, ConfigProvider, Form, Select } from "antd";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomRangePicker from "../../../components/custom-range-picker/CustomRangePicker";
import FormInput from "../../../components/form-input/FormInput";
import SecondaryTab from "../../../components/tabs/secondary-tab/SecondaryTab";
import { ExamType } from "../../../constants/course";
import { getExamList } from "../../../service/learning-course";
import { viLocale } from "../../../utils/picker";
import Exam from "./components/exam/Exam";
import Exercise from "./components/exercise/Exercise";
import Test from "./components/test/Test";
import YourExercises from "./components/your-exercises/YourExercises";
import "./style.scss";

interface FormValues {
  keyword?: string;
  sortByName?: number;
  registeredDate?: [dayjs.Dayjs, dayjs.Dayjs];
  resultStatus?: string;
  submissionCount?: string;
}

const TEST_STATUS = [
  { label: "Tất cả", value: 0 },
  { label: "Đã làm", value: 1 },
  { label: "Chưa làm", value: 2 },
  { label: "Sắp đến hạn nộp", value: 3 },
];

const RESULT_STATUS = [
  { label: "Tất cả", value: "all" },
  { label: "Đạt", value: "1" },
  { label: "Chưa đạt", value: "0" },
  { label: "Chưa có kết quả", value: "-1" },
  // { label: "Khác", value: 4 },
];

interface Props {
  tab: string;
}
const AssessmentsSummary = (props: Props) => {
  const { tab } = props;
  const [form] = Form.useForm();
  const { id } = useParams();
  const [isToggle, setIsToggle] = useState(false);
  const [assessmentsSummaryTab, setAssessmentsSummaryTab] =
    useState<string>("1");
  const [totalAssessmentsSummary, setTotalAssessmentsSummary] = useState({
    examNumber: 0,
    exerciseNumber: 0,
    testNumber: 0,
    totalNumber: 0,
  });
  const [assessmentsSummaryData, setAssessmentsSummaryData] = useState([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNum, setPageNum] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<any>(null);

  const fetchExamList = async (values: FormValues) => {
    setIsLoading(true);
    const payload = {
      keyword: values.keyword?.trim() || undefined,
      status: values.sortByName || undefined,
      submitDateFrom: values.registeredDate
        ? values.registeredDate[0].toISOString()
        : undefined,
      submitDateTo: values.registeredDate
        ? values.registeredDate[1].toISOString()
        : undefined,
      resultStatus:
        values.resultStatus === "all" ? undefined : Number(values.resultStatus),
      submitTime: values.submissionCount || undefined,
      type:
        assessmentsSummaryTab === "1"
          ? undefined
          : assessmentsSummaryTab === "2"
            ? ExamType.TEST
            : assessmentsSummaryTab === "3"
              ? ExamType.EXERCISE
              : assessmentsSummaryTab === "4"
                ? ExamType.EXAM
                : undefined,
      sortByName:
        sortBy?.order === "ascend"
          ? "asc"
          : sortBy?.order === "descend"
            ? "desc"
            : undefined,
      page: pageNum,
      size: pageSize,
    };
    try {
      const res = await getExamList(Number(id), payload);
      const examData = res.data.data;
      setAssessmentsSummaryData(examData.exams);
      setTotalAssessmentsSummary({
        examNumber: examData?.examNumber,
        exerciseNumber: examData?.exerciseNumber,
        testNumber: examData?.testNumber,
        totalNumber: examData?.totalNumber,
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (tab === "2") {
      fetchExamList(form.getFieldsValue());
    }
  }, [assessmentsSummaryTab, tab, sortBy, pageSize, pageNum]);
  const debouncedFetchExamList = debounce(fetchExamList, 500);
  const onValuesChange = (changedValues: any, allValues: FormValues) => {
    debouncedFetchExamList(allValues);
  };

  useEffect(() => {
    setSortBy(null);
  }, [assessmentsSummaryTab]);

  const tabItems = [
    {
      key: "1",
      label: (
        <div className="flex align-center gap-8">
          Tất cả
          <div className="count-tab">{totalAssessmentsSummary.totalNumber}</div>
        </div>
      ),
      children: (
        <>
          <div className="hr-tag"></div>

          <YourExercises
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNum}
            setPageNumber={setPageNum}
            totalPage={totalAssessmentsSummary.totalNumber}
            data={assessmentsSummaryData}
            isLoading={isLoading}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex align-center gap-8">
          Bài kiểm tra
          <div className="count-tab">{totalAssessmentsSummary.testNumber}</div>
        </div>
      ),
      children: (
        <>
          <div className="hr-tag"></div>

          <Test
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNum}
            setPageNumber={setPageNum}
            totalPage={totalAssessmentsSummary.testNumber}
            data={assessmentsSummaryData}
            isLoading={isLoading}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </>
      ),
    },
    {
      key: "3",
      label: (
        <div className="flex align-center gap-8">
          Bài tập
          <div className="count-tab">
            {totalAssessmentsSummary.exerciseNumber}
          </div>
        </div>
      ),
      children: (
        <>
          <div className="hr-tag"></div>

          <Exercise
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNum}
            setPageNumber={setPageNum}
            totalPage={totalAssessmentsSummary.exerciseNumber}
            data={assessmentsSummaryData}
            isLoading={isLoading}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </>
      ),
    },
    {
      key: "4",
      label: (
        <div className="flex align-center gap-8">
          Bài thi
          <div className="count-tab">{totalAssessmentsSummary.examNumber}</div>
        </div>
      ),
      children: (
        <>
          <div className="hr-tag"></div>

          <Exam
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNum}
            setPageNumber={setPageNum}
            totalPage={totalAssessmentsSummary.examNumber}
            data={assessmentsSummaryData}
            isLoading={isLoading}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </>
      ),
    },
  ];

  const RenderAdvanceSearch = () => (
    <div className="advanced-filtering-field">
      <div className="item">
        <span className="title">Trạng thái</span>
        <Form.Item name="sortByName">
          <Select defaultValue={0} options={TEST_STATUS} />
        </Form.Item>
      </div>
      <div className="item registered-date">
        <span className="title">Thời gian</span>
        <Form.Item name="registeredDate">
          <ConfigProvider locale={viLocale}>
            <CustomRangePicker
              value={form.getFieldValue("registeredDate") || null}
              onChangeRangePicker={(dates) => {
                form.setFieldsValue({ registeredDate: dates });
                fetchExamList(form.getFieldsValue());
              }}
              placeholder="Chọn thời gian"
              height={"44px"}
            />
          </ConfigProvider>
        </Form.Item>
      </div>

      <div className="item">
        <span className="title">Kết quả</span>
        <Form.Item name="resultStatus">
          <Select defaultValue={"all"} options={RESULT_STATUS} />
        </Form.Item>
      </div>
      <div className="item">
        <span className="title">Số lần nộp bài</span>
        <Form.Item name="submissionCount">
          <FormInput
            placeholder="Nhập số lần nộp bài"
            onChange={(e) => {
              const value = e.target.value;
              form.setFieldsValue({ submissionCount: value });
            }}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>
      </div>

      <div className="item">
        <div className="title">&nbsp;</div>
        <div
          className="text-clear-filters"
          onClick={() => {
            form.resetFields();
            setPageNum(1);
            fetchExamList(form.getFieldsValue());
          }}
        >
          Xóa bộ lọc
        </div>
      </div>
    </div>
  );

  return (
    <div className="assessments-summary">
      <Form form={form} onValuesChange={onValuesChange}>
        <div className="flex justify-between align-center gap-16">
          <div>
            <div className="text-title-18">Danh sách</div>
            <div className="my-notes-des">
              Tổng hợp các bài tập/bài kiểm tra/bài thi của bạn
            </div>
          </div>
          <div className="my-notes-search">
            <Form.Item name="keyword">
              <FormInput
                placeholder="Nhập từ khoá cần tìm"
                prefixIcon={
                  <img
                    src="/assets/icons/search-lg.svg"
                    alt="search"
                    className="search-icon"
                  />
                }
              />
            </Form.Item>

            <div className="flex gap-8">
              <Button
                className="advanced-filtering"
                onClick={() => setIsToggle(!isToggle)}
              >
                <img
                  src="/assets/icons/filter-lines.svg"
                  alt="search"
                  className="search-icon"
                />
                Bộ lọc nâng cao
              </Button>
            </div>
          </div>
        </div>
        {isToggle && <RenderAdvanceSearch />}
      </Form>
      <div className="mt-3">
        <SecondaryTab
          tabItems={tabItems}
          onChange={(tab) => setAssessmentsSummaryTab(tab)}
        />
      </div>
    </div>
  );
};

export default AssessmentsSummary;
