import React, { useEffect, useState } from "react";
import Filter from "../../../components/filter";
import ResponsiveList from "../../../components/list/ResponsiveList";
import CourseCard, {
  CourseCardProps,
} from "../../../components/card/CourseCard";
import BannerSearch from "./BannerSearch";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import {
  COURSE_SCHEDULE_TYPE,
  COURSE_TYPE,
  DEFAULT_PAGE_NUM,
  DEFAULT_PAGE_SIZE,
  FILTER_STAR_ARR,
  FREE_OPTIONS,
  PAID_VALUE_FILTER,
} from "../../../constants";
import { CourseInfo, CoursePayload, IndustryInfo } from "../../../types/course";
import {
  convertSomeObjKeyToArray,
  mapCourseInfoBeToFe,
} from "../../../utils/format";
import {
  exportExcelCourse,
  getCoursesBlockQuiz,
} from "../../../service/courses";
import CustomPagination from "../../../components/pagination/Pagination";
import { useFilters } from "../Filters";
import useKeyword from "../../../hooks/useKeyword";
import {
  getArrOrUndefined,
  getArrayFilterChecked,
} from "../../../utils/arrays";
import { useCoursesStore } from "../../../stores/stores";
import { isEmpty } from "lodash";

function SearchCourseInfo() {
  const navigate = useNavigate();
  const { industries, universities } = useCoursesStore((state) => state);
  const keyWord = useKeyword("keyword");
  const topicId = Number(useKeyword("topic"));
  const topicName = topicId
    ? industries?.find((item: IndustryInfo) => item.id === (topicId as number))
        ?.name
    : "";

  const [courses, setCourses] = useState<CourseCardProps[]>([]);

  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [pageNum, setPageNum] = useState<number>(DEFAULT_PAGE_NUM);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchValues, setSearchValues] = useState<CoursePayload>({
    sortByName: "asc",
  });
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const filterMenus = useFilters({
    hasPaid: searchValues?.isFreeOptions?.includes(PAID_VALUE_FILTER)
      ? true
      : false,
  });

  const renderCard = (courses: any) => {
    if (!courses.length) return [];

    return courses.map((item: CourseCardProps) => ({
      content: (
        <CourseCard
          key={item.id}
          {...item}
          isPopular={false}
          teachers={item.teachers?.filter(
            (teacher: string, index) => teacher !== " "
          )}
          onClick={() =>
            navigate(`${routesConfig.courseInfo}/detail/${item.id}`)
          }
        />
      ),
    }));
  };
  const getCourses = async (payload = {}) => {
    try {
      const res = await getCoursesBlockQuiz(payload);
      const coursesInfo = res.data.data.courses.map((item: CourseInfo) =>
        mapCourseInfoBeToFe(item)
      );
      setTotalRecords(res.data.data.total);
      return coursesInfo;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  useEffect(() => {
    const searchCourse = async () => {
      try {
        setLoadingSearch(true);
        const values = await getCourses({
          ...searchValues,
          keyword: keyWord ? keyWord : undefined,
          page: pageNum,
          size: pageSize,
        });
        setCourses(renderCard(values));
      } catch (err) {
      } finally {
        setTimeout(() => setLoadingSearch(false), 500);
      }
    };
    searchCourse();
  }, [searchValues, pageNum, pageSize, keyWord]);

  useEffect(() => {
    if (!isEmpty(topicId)) {
      setSearchValues((prevState: CoursePayload) => ({
        ...prevState,
        industries: topicId ? [topicId] : undefined,
      }));
    }
  }, [topicId]);

  const handleChangeFilter = (value: any) => {
    // Filter chuyên ngành
    const industriesFilter = getArrayFilterChecked(
      industries,
      convertSomeObjKeyToArray(value, "industry"),
      "id"
    );
    // Xếp hạng
    const stars = getArrayFilterChecked(
      FILTER_STAR_ARR,
      convertSomeObjKeyToArray(value, "rank"),
      "value"
    );
    // Chi phí
    const freeOptions = getArrayFilterChecked(
      FREE_OPTIONS,
      convertSomeObjKeyToArray(value, "price"),
      "value"
    );
    // Filter trường học
    const universitiesFilter = getArrayFilterChecked(
      universities,
      convertSomeObjKeyToArray(value, "university"),
      "uuid"
    );
    // Filter lịch trình học
    const courseScheduleType = getArrayFilterChecked(
      COURSE_SCHEDULE_TYPE,
      convertSomeObjKeyToArray(value, "schedule"),
      "value"
    );
    // Filter loại khoá học
    const courseType = getArrayFilterChecked(
      COURSE_TYPE,
      convertSomeObjKeyToArray(value, "kindOfCourse"),
      "value"
    );
    const payload = {
      industries: getArrOrUndefined(industriesFilter),
      universities: getArrOrUndefined(universitiesFilter),
      stars: getArrOrUndefined(stars),
      courseScheduleType: getArrOrUndefined(courseScheduleType),
      courseType: getArrOrUndefined(courseType),
      isFreeOptions: getArrOrUndefined(freeOptions),
      minCost: freeOptions?.includes(PAID_VALUE_FILTER) ? 0 : undefined,
      maxCost:
        freeOptions?.includes(PAID_VALUE_FILTER) && value?.cost
          ? value?.cost
          : freeOptions?.includes(PAID_VALUE_FILTER) && !value?.cost
            ? 0
            : undefined,
    };
    setPageNum(1)

    setSearchValues((prevState: CoursePayload) => ({
      ...prevState,
      ...payload,
    }));
  };

  const handleChangeSort = (value: number) => {
    switch (value) {
      case 1:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByName: "asc", sortByCreatedAt: undefined },
        }));
        break;
      case 2:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByName: "desc", sortByCreatedAt: undefined },
        }));
        break;
      case 3:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByCreatedAt: "desc", sortByName: undefined },
        }));
        break;
      case 4:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByCreatedAt: "asc", sortByName: undefined },
        }));
        break;
      default:
        break;
    }
  };

  const handleExportExcel = async () => {
    try {
      const res = await exportExcelCourse({
        ...searchValues,
        keyword: keyWord || undefined,
        page: pageNum,
        size: pageSize,
      });
      const fileData = res.data;

      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);

      downloadLink.download = "Danh sách khóa học.xlsx";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  return (
    <div>
      <section>
        <BannerSearch totalRecords={totalRecords} topicName={topicName} />
      </section>
      <section className="content-layout">
        <section className="filter-content mt-5">
          <Filter
            items={filterMenus}
            onChange={handleChangeFilter}
            onChangeSort={handleChangeSort}
          />
          <div className="content-list w-full-100">
            <ResponsiveList
              grid={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 }}
              data={courses}
              loading={loadingSearch}
              className="mt-5"
              showMenu={true}
              totalRecords={totalRecords}
              onClickReport={() => navigate(routesConfig.statisticsCourses)}
              onClickExportExcel={handleExportExcel}
            />
            {totalRecords > 0 && (
              <CustomPagination
                totalPage={totalRecords}
                pageSize={pageSize}
                pageNumber={pageNum}
                setPageSize={(value) => setPageSize(value)}
                setPageNumber={(value) => setPageNum(value)}
              />
            )}
          </div>
        </section>
      </section>
    </div>
  );
}

export default SearchCourseInfo;
