import React, {FunctionComponent} from 'react';

type Props = {
    width: string,
    height: string
}

export const VerifiedIcon: FunctionComponent<Props> = ({width, height}) => {
    return (
        <>
            <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Verified Icon">
                    <path id="Vector"
                          d="M8.79091 24L6.71818 20.3429L2.79091 19.4286L3.17273 15.2L0.5 12L3.17273 8.8L2.79091 4.57143L6.71818 3.65714L8.79091 0L12.5 1.65714L16.2091 0L18.2818 3.65714L22.2091 4.57143L21.8273 8.8L24.5 12L21.8273 15.2L22.2091 19.4286L18.2818 20.3429L16.2091 24L12.5 22.3429L8.79091 24ZM11.3545 16.0571L17.5182 9.6L15.9909 7.94286L11.3545 12.8L9.00909 10.4L7.48182 12L11.3545 16.0571Z"
                          fill="#1890FF"/>
                </g>
            </svg>
        </>
    );
};

