export const scrollToElement = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

export const getFirstLetter = (words: string) => {
  return words?.length >= 2
    ? words[0][0].toUpperCase() + words[words.length - 1][0].toUpperCase()
    : "";
};

interface TemplateData {
  html: string;
  css: string;
}

const removeDiacritics = (str: string) =>
  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");


export const filterOption = (
  input: string,
  option?: { label: string; value: string }
) =>
  removeDiacritics(option?.label ?? "")
    .toLowerCase()
    .includes(removeDiacritics(input).toLowerCase());