import React, { useEffect, useState } from "react";
import { CommentProps, ReplyValue } from "../../../../../types/discussion";
import Reply from "./Reply";

const newReply: any = {
  id: Date.now(),
  level: 4,
  parent: 4,
  replies: [],
  isReplying: true,
};

interface CommentsProps {
  data: CommentProps;
  onReply?: (data: ReplyValue) => void;
  onSaveEditComment?: (value: ReplyValue) => void;
  onLike?: (value: CommentProps) => void;
  onDislike?: (value: CommentProps) => void;
  onDelete?: (value: CommentProps) => void;
  unitId?: number;
  onReport?: (value: CommentProps) => void;
}

function Comment({
  data,
  onReply = () => {},
  onLike = () => {},
  onDislike = () => {},
  onDelete = () => {},
  onReport = () => {},
  onSaveEditComment = () => {},
  unitId,
}: CommentsProps) {
  const initialData = data;
  const [comment, setComment] = useState<CommentProps>(data);
  const [commentEditing, setCommentEditing] = useState<
    CommentProps | undefined
  >(undefined);

  useEffect(() => {
    setComment(data);
  }, [data]);

  const handleClickReply = (comment: CommentProps) => {
    const newComments = addReply(initialData, comment.id as number, newReply);
    setComment(newComments);
  };

  const handleReply = (data: ReplyValue) => {
    onReply(data);
  };

  const handleClickEdit = (comment: CommentProps | undefined) => {
    setCommentEditing(comment);
  };

  const handleLike = (comment: CommentProps) => {
    onLike(comment);
  };

  const handleDislike = (comment: CommentProps) => {
    onDislike(comment);
  };
  const handleDelete = (comment: CommentProps) => {
    onDelete(comment);
  };
  const handleReport = (comment: CommentProps) => {
    onReport(comment);
  };
  const handleSaveEditComment = (data: ReplyValue) => {
    onSaveEditComment(data);
  };

  function addReply(
    cmt: CommentProps,
    id: number,
    newReply: CommentProps
  ): CommentProps {
    const newData = JSON.parse(JSON.stringify(cmt));
    recursiveAdd(newData, null, id, newReply);
    return newData;
  }

  function recursiveAdd(
    data: CommentProps,
    parent: CommentProps | null,
    id: number,
    newReply: CommentProps
  ): boolean {
    if (data.id === id) {
      if (data.level < 3) {
        data.replies.push(newReply);
        return true; // Dừng việc xử lý khi đã thêm phần tử mới
      } else if (data.level === 3 && parent) {
        parent.replies.push(newReply); // Thêm vào mảng replies của phần tử cha
        return true; // Dừng việc xử lý khi đã thêm phần tử mới
      }
    }

    if (data.replies && data.replies.length > 0)
      for (let reply of data.replies) {
        if (recursiveAdd(reply, data, id, newReply)) {
          return true; // Dừng việc xử lý khi đã thêm phần tử mới
        }
      }

    return false;
  }

  return (
    <Reply
      key={comment.id}
      comment={comment}
      replies={comment.replies}
      commentEditing={commentEditing}
      onClickReply={handleClickReply}
      onSaveReply={handleReply}
      onClickEdit={handleClickEdit}
      onReport={handleReport}
      onLike={handleLike}
      onDislike={handleDislike}
      onDelete={handleDelete}
      onSaveEditComment={handleSaveEditComment}
      courseInfo={comment?.courseInfo}
      unitId={unitId}
    />
  );
}

export default Comment;
