import {
  CopyrightOutlined,
  MailOutlined,
  SafetyOutlined,
  SmileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { FunctionComponent } from "react";
import { globalStore } from "../../stores/stores";
import { RegisterInfo } from "./components/RegisterContent/RegisterInfo";
import { RegisterForm } from "./components/RegisterForm/RegisterForm";
import "./Register.scss";

const Register: FunctionComponent = () => {
  const { registerStep } = globalStore();

  return (
    <div className="register">
      <div className="register__container">
        <div className="register__steps">
          <a className="register__logo" href="/">
            <img
              className="register__logo__icon"
              loading="eager"
              alt=""
              src={"../../assets/img/app-logo.png"}
            />
            <div className="register__logo__title">Mooc</div>
          </a>
          <div className="register__copyright">
            <span>
              <CopyrightOutlined />
              Mooc 2024
            </span>
            <span>
              <MailOutlined />
              help@Mooc.com
            </span>
          </div>
          <div
            className={`register__steps__item ${registerStep === 1 ? "" : "inactive"}`}
          >
            <div className="icon">
              <UserOutlined
                className="icon__child"
                style={{ color: "#344054" }}
              />
            </div>
            <div className="name">
              <div className="name__title">
                <span className="register-title">Đăng ký tài khoản</span>
              </div>
              <div className="register-description">
                <span>Thông tin đăng nhập trên hệ thống</span>
              </div>
            </div>
          </div>
          <div
            className={`register__steps__item ${registerStep === 2 ? "" : "inactive"}`}
          >
            <div className="icon">
              <SmileOutlined
                className="icon__child"
                style={{ color: "#344054" }}
              />
            </div>
            <div className="name">
              <div className="name__title">
                <span className="register-title">Thông tin quan tâm</span>
              </div>
              <div className="register-description">
                <span>Chúng ta tìm hiểu về nhau chút nhé</span>
              </div>
            </div>
          </div>
          <div
            className={`register__steps__item ${registerStep === 3 ? "" : "inactive"}`}
          >
            <div className="icon">
              <SafetyOutlined
                className="icon__child"
                style={{ color: "#344054" }}
              />
            </div>
            <div className="name">
              <div className="name__title">
                <span className="register-title">
                  Xác thực thông tin tài khoản
                </span>
              </div>
              <div className="register-description">
                <span>Bảo mật tài khoản</span>
              </div>
            </div>
          </div>
        </div>
        <div className="register__content">
          {registerStep === 1 ? <RegisterForm /> : <RegisterInfo />}
        </div>
      </div>
    </div>
  );
};
export default Register;
