import { AxiosResponse } from "axios";
import { axiosConfigSupervisorToUpload } from "../../config/api/configApiSupervisor";
import {
  EkycRegisterUserResponse,
  EKYCSettingResponse,
} from "../../types/ekycIdentify";

export const ekycRegisterUserVideo: (payload: {
  data: FormData;
}) => Promise<AxiosResponse<EkycRegisterUserResponse>> = (payload: {
  data: FormData;
}) => {
  const { data } = payload;
  return axiosConfigSupervisorToUpload.post(`/ekyc/register_user_video`, data);
};
export const ekycRegisterUserCard: (payload: {
  data: FormData;
}) => Promise<AxiosResponse<EkycRegisterUserResponse>> = (payload: {
  data: FormData;
}) => {
  const { data } = payload;
  return axiosConfigSupervisorToUpload.post(`/ekyc/register_user_card`, data);
};
export const ekycMatchingUserFace: (payload: {
  data: FormData;
}) => Promise<AxiosResponse<EkycRegisterUserResponse>> = (payload: {
  data: FormData;
}) => {
  const { data } = payload;
  return axiosConfigSupervisorToUpload.post(`ekyc/matching_user_face`, data);
};
export const ekycConfirmSession: (payload: {
  data: Partial<EkycRegisterUserResponse["data"]>;
}) => Promise<AxiosResponse<EkycRegisterUserResponse>> = (payload: {
  data: Partial<EkycRegisterUserResponse["data"]>;
}) => {
  const { data } = payload;
  return axiosConfigSupervisorToUpload.post(`ekyc/confirm_session`, data);
};
export const getEkycSettingCard: () => Promise<
  AxiosResponse<{ data: EKYCSettingResponse[] }>
> = () => {
  return axiosConfigSupervisorToUpload.get(`setting-identity-document/get`);
};
