import { LoadingOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Flex, Modal, Typography } from "antd";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Webcam from "react-webcam";
import type { DraggableData, DraggableEvent } from "react-draggable";
import Draggable from "react-draggable";
import { HelpService } from "../../../../service/helper.service";
const { Paragraph, Text } = Typography;
import * as faceTool from "mooc-ai-tool";
import { modelsPath } from "../../../../constants/modelsPath";
import { EXAM_SUPERVISOR } from "../../../../constants/exam-supervisor";
import { examSupervisorStore, useAuthStore } from "../../../../stores/stores";
import { submitAttendanceReport } from "../../../../service/attendance";
import { ekycMatchingUserFace } from "../../../../service/ekyc";
import { toNumber } from "lodash";
import useKeyword from "../../../../hooks/useKeyword";

interface FaceRecordModalProps {
  currentVideoTimestamp?: number;
  isOpen: boolean;
  unitType?: string;
  handleClose: () => void;
  title: string;
  hasMask?: boolean;
  description: string;
  type?: "study" | "exam" | "";
  setCloseCamera?: any;
  isCloseCamera?: string;
  attendanceData?: any;
  playedSeconds?: number;
}
const videoConstraints = {
  width: 372,
  height: 280,
  facingMode: "environment",
};
const listFaceError = [
  "Có nhiều hơn một khuôn mặt khi thi",
  "Bạn đang rời khỏi vị trí",
  "Bạn đang không tập trung vào bài thi",
  "Khuôn mặt không trùng khớp với khuôn mặt đã xác thực",
];
const convertSecondsToTime = (seconds: number) => {
  const date = new Date(0);
  date.setSeconds(seconds); // specify value for SECONDS here
  const timeString = date.toISOString().substring(11, 19);
  return timeString;
};
const FaceRecordModal = ({
  isOpen,
  unitType = "doc",
  handleClose,
  title,
  hasMask = true,
  type = "",
  description,
  setCloseCamera,
  isCloseCamera,
  attendanceData,
  playedSeconds,
}: FaceRecordModalProps) => {
  const timeSeconds = playedSeconds;
  const recordTimeBySeconds = useRef(0);
  const blockId = toNumber(useKeyword("blockId"));
  const timeoutId = useRef<NodeJS.Timeout>();
  const intervalId = useRef<NodeJS.Timeout>();
  const intervalRef = useRef<NodeJS.Timeout>();
  const helpService = useRef(new HelpService());
  const draggleRef = useRef<HTMLDivElement>(null);
  const [isLoadingModel, setIsLoadingModel] = useState(true);
  const [errorHandleAttendance, setErrorHandleAttendance] = useState("");
  const [count, setCount] = useState(3);
  const [error, setError] = useState("");
  const userData = useAuthStore((state) => state.user);
  const repeatMessageData = useRef({
    count: 0,
    message: "",
  });
  const [messagesDisplay, setMessageDisplay] = useState("");
  const listAttendanceData = useRef<
    { is_attendance: boolean; times: number }[]
  >([]);
  const intervalChecking = useRef<NodeJS.Timeout>();
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const {
    setRecordedVideo,
    resetSupervisorData,
    recordedVideo,
    examSupervisorSetting,
    examSupervisorData,
    violenList,
    recordVideoTime,
    setViolenList,
  } = examSupervisorStore();
  const countSequentlyFault = useRef(0);
  const globalFault = useRef("");
  const [
    {
      attendantListTime,
      attendanceRequired,
      attendanceTotal,
      showingError,
      isFaceAttendance,
    },
  ] = useState(() => {
    const attendantListTime = examSupervisorSetting?.mooc_setting_times || [];
    const attendanceRequired = examSupervisorSetting?.min_times || 1;
    const isFaceAttendance = examSupervisorSetting?.face_attendance;
    const isIpAttendance = examSupervisorSetting?.ip_attendance;
    const attendanceTotal = Math.max(
      examSupervisorSetting?.times || 1,
      attendanceRequired
    );

    const showingError = examSupervisorData?.is_notification || false;
    return {
      attendantListTime,
      attendanceRequired,
      attendanceTotal,
      showingError,
      isFaceAttendance,
      isIpAttendance,
    };
  });
  const countAttendance = useRef(0);
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const handleCancel = useCallback(() => {
    handleClose();
  }, []);
  const timeoutCheckingConcentrate = useRef<NodeJS.Timeout>();
  const timeoutCheckingPosition = useRef<NodeJS.Timeout>();
  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  const handleCheckingAttentdantByFace = async () => {
    if (attendantListTime.length > 0) {
      const current = attendantListTime[countAttendance.current];
      if (!current) return;
      const { minutes } = current;
      const minutesToSeconds = minutes * 60;
      const successCode = 0;
      recordTimeBySeconds.current += 1;
      if (minutesToSeconds - Math.floor(timeSeconds as number) <= 5 && minutesToSeconds - Math.floor(timeSeconds as number) >= 0) {
        setCount(minutesToSeconds - Math.floor(timeSeconds as number) - 1)
      }
      if (
        minutesToSeconds - Math.floor(timeSeconds as number) <= 1 ||
        Math.floor(timeSeconds as number) - minutesToSeconds >= 1
      ) {
        countAttendance.current += 1;
        const codesChecked: number[] = [];
        const functionExecute = async () => {
          const { codes } = await faceTool.executeFace({
            up: 50,
            down: 50,
            left: 50,
            right: 50,
          });
          codesChecked.push(...codes);
        };
        const checkOne = new Promise((resolve) => {
          setTimeout(async () => {
            await functionExecute();
            resolve("");
          }, 300);
        });
        const checkTwo = new Promise((resolve) => {
          setTimeout(async () => {
            await functionExecute();
            resolve("");
          }, 600);
        });
        const checkThree = new Promise((resolve) => {
          setTimeout(async () => {
            await functionExecute();
            resolve("");
          }, 900);
        });
        await Promise.all([checkOne, checkTwo, checkThree]);
        const isYou = await handleStopCaptureClick();
        listAttendanceData.current.push({
          is_attendance: codesChecked.includes(successCode) && isYou,
          times: countAttendance.current,
        });
        handleClose();
        if (unitType !== "video") {
          submitAttendanceReport({
            course_block_id: `${examSupervisorData?.mooc_course_block.id}`,
            data: {
              result: codesChecked.includes(successCode) && isYou,
              data: listAttendanceData.current,
            },
          });
          clearInterval(intervalId.current);
          return;
        }
        if (!blockId) {
          helpService.current.errorMessage(
            "Điểm danh thất bại do mã khoá học không tồn tại"
          );
          return;
        }
        if (
          countAttendance.current === attendanceTotal ||
          Math.floor(timeSeconds as number) -
            attendantListTime[attendantListTime.length]?.minutes * 60 >=
            4
        ) {
          const isAttendanceEnough =
            listAttendanceData.current.reduce((total, item) => {
              if (item.is_attendance) {
                return total + 1;
              }
              return total;
            }, 0) === attendanceRequired;
          submitAttendanceReport({
            course_block_id: `${blockId}`,
            data: {
              result: isAttendanceEnough,
              data: listAttendanceData.current,
            },
          });
          clearInterval(intervalId.current);
        }
      }
    } else {
      clearInterval(intervalId.current);
    }
  };

  const handleStartRecord = useCallback(() => {
    if (!webcamRef.current || !webcamRef.current.stream) {
      helpService.current.errorMessage("Không tìm thấy camera");
      return;
    }
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
    intervalId.current = setInterval(() => {
      recordTimeBySeconds.current += 1;
    }, 1000);
  }, [webcamRef, mediaRecorderRef]);
  const handleDataAvailable = useCallback(
    ({ data }: BlobEvent) => {
      if (data.size > 0) {
        setRecordedVideo([...recordedVideo, data]);
      }
    },
    [setRecordedVideo]
  );
  const onLoadedMetadata = async () => {
    if (
      webcamRef.current &&
      webcamRef.current.video &&
      isCloseCamera !== "open" &&
      isFaceAttendance
    ) {
      await faceTool.initialFace(webcamRef.current.video, modelsPath[1]);
      setIsLoadingModel(false);
      if (type === "exam") {
        await faceTool.initialBodyPose(webcamRef.current.video);
        handleStartRecord();
        timeoutId.current = setTimeout(() => {
          requestAnimationFrame(executeLoop);
        }, 2000);
        intervalChecking.current = setInterval(() => {
          handleStopCaptureClick();
        }, 10000);
      } else if (type === "study") {
        intervalRef.current = setInterval(() => {
          setCount((prevCount) => {
            if (prevCount === -1) {
              clearInterval(intervalRef.current);
              setIsLoadingModel(false);
              return -1;
            }
            return prevCount - 1;
          });
        }, 1000);
      } else {
        timeoutId.current = setTimeout(() => {
          requestAnimationFrame(executeLoop);
        }, 2000);
      }
      // .catch(() => {
      //   helpService.current.errorMessage("Có lỗi xảy ra với model");
      //   setIsLoadingModel(false);
      // });
    } else if (isCloseCamera === "open") {
      intervalRef.current = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount === -1) {
            clearInterval(intervalRef.current);
            setIsLoadingModel(false);
            return -1;
          }
          return prevCount - 1;
        });
      }, 1000);
    } else {
      helpService.current.errorMessage("Không tìm thấy camera");
    }
  };
  const handleAttendanceBySocket = async () => {
    try {
      const isYou = await handleStopCaptureClick();
      await submitAttendanceReport({
        course_block_id: `${blockId}`,
        teacher_id: attendanceData?.teacher_id,
        report_id: attendanceData?.report_id,
        is_attendance: isYou,
      });
      setErrorHandleAttendance(isYou ? "success" : "error");
      setTimeout(() => {
        setCloseCamera("close");
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!isLoadingModel && isCloseCamera === "open") {
      setTimeout(async () => {
        handleAttendanceBySocket();
      }, 1500);
    } else if (setCloseCamera) {
      setTimeout(async () => {
        setCloseCamera("close");
      }, 10000);
    }
  }, [isLoadingModel]);

  useMemo(() => {
    if (!isLoadingModel && type === "study") {
      // intervalId.current = setInterval(() => {
      //   handleCheckingAttentdantByFace();
      // }, 1000);
      handleCheckingAttentdantByFace();
    }
  }, [timeSeconds]);
  const executeLoop = async () => {
    if (webcamRef.current && webcamRef.current.video) {
      const { codes: faceCodes, pose: facePose } = await faceTool.executeFace({
        left: 60,
        right: 50,
        up: 20,
        down: 20,
        centerX_left: 50,
        centerX_right: 40,
        centerY_up: 15,
        centerY_down: 15,
      });
      let currentBodyCodes: number[] = [];
      if (type === "exam") {
        const { codes: bodyCodes } = await faceTool.executeBodyPose();
        currentBodyCodes = [...bodyCodes];
      }
      const { yaw, pitch } = facePose;
      if (showingError) {
        handleDisplayFaceError(faceCodes, yaw, pitch);
      }
      handleFormatExamSupervisorData(
        [...faceCodes, ...currentBodyCodes],
        facePose
      );
      timeoutId.current = setTimeout(() => {
        requestAnimationFrame(executeLoop);
      }, 300);
    }
  };
  function handleDisplayFaceError(
    codes: number[],
    yaw: string | null,
    pitch: string | null
  ) {
    const { count, message } = repeatMessageData.current;
    if (codes.includes(12)) {
      if (message !== listFaceError[0]) {
        repeatMessageData.current = {
          count: 1,
          message: listFaceError[0],
        };
      } else {
        repeatMessageData.current = {
          count: count + 1,
          message: listFaceError[0],
        };
      }
    } else if (codes.includes(11)) {
      if (message !== listFaceError[1]) {
        repeatMessageData.current = {
          count: 1,
          message: listFaceError[1],
        };
      } else {
        repeatMessageData.current = {
          count: count + 1,
          message: listFaceError[1],
        };
      }
    } else if (
      (yaw !== "center" && yaw !== "undefined") ||
      (pitch !== "center" && pitch !== "undefined")
    ) {
      if (message !== listFaceError[2]) {
        repeatMessageData.current = {
          count: 1,
          message: listFaceError[2],
        };
      } else {
        repeatMessageData.current = {
          count: count + 1,
          message: listFaceError[2],
        };
      }
    } else if (codes.includes(0) && codes.length === 1) {
      repeatMessageData.current = {
        count: 3,
        message: "",
      };
    }
  }
  function handleFormatExamSupervisorData(
    codes: number[],
    pose: { yaw: string | null; pitch: string | null }
  ) {
    let curFault = "";

    const currentVideoDuration = convertSecondsToTime(
      recordTimeBySeconds.current
    );
    let locationDuration = 0;
    let concentrationDuration = 0;

    if (!codes.includes(0)) {
      if (codes.includes(13)) {
        curFault = "Bạn đang không tập trung.";
      }
      if (codes.includes(16)) {
        curFault = "Bạn đã rời khỏi vị trí";
      }
    } else {
      if (
        (pose.yaw !== "center" && pose.yaw !== "undefined") ||
        (pose.pitch !== "center" && pose.pitch !== "undefined")
      ) {
        curFault = "Bạn đang không tập trung.";
      }
      // nhieu hon 1 khuon mat
      if (codes.includes(12)) {
        curFault = "Có nhiều hơn một khuôn mặt";
      }
    }
    if (curFault === globalFault.current) {
      countSequentlyFault.current += 1;
    } else {
      countSequentlyFault.current = 1;
      globalFault.current = curFault;
    }
    if (countSequentlyFault.current < 3) {
      return;
    }
    if (globalFault.current != "") {
      setMessageDisplay(globalFault.current);
    } else {
      setMessageDisplay("");
    }

    if (examSupervisorData?.location) {
      const [hour, minute, seconds] = examSupervisorData.location.split(":");
      locationDuration = +hour * 3600 + +minute * 60 + +seconds;
    }
    if (examSupervisorData?.concentration) {
      const [hour, minute, seconds] =
        examSupervisorData.concentration.split(":");
      concentrationDuration = +hour * 3600 + +minute * 60 + +seconds;
    }
    if (
      globalFault.current === "Bạn đang không tập trung." &&
      countSequentlyFault.current > (concentrationDuration * 1000) / 300
    ) {
      countSequentlyFault.current = 0;
      setViolenList([
        ...violenList,
        {
          time: currentVideoDuration,
          type: EXAM_SUPERVISOR["concentration"],
        },
      ]);
    }
    if (
      globalFault.current === "Bạn đã rời khỏi vị trí." &&
      countSequentlyFault.current > (locationDuration * 1000) / 300
    ) {
      countSequentlyFault.current = 0;
      setViolenList([
        ...violenList,
        {
          time: currentVideoDuration,
          type: EXAM_SUPERVISOR["position"],
        },
      ]);
    }
  }
  const handleStopCaptureClick = useCallback(async () => {
    if (!webcamRef.current) {
      console.log("fail to stop");
      return false;
    }
    let isYou = false;
    const base64Url = webcamRef.current.getScreenshot();
    if (!base64Url) return isYou;
    if (!userData?.id) {
      helpService.current.errorMessage("Không tìm thấy user");
      return isYou;
    }
    const response = await fetch(base64Url);
    const blobImage = await response.blob();
    const imageFile = new File([blobImage], "login.png", {
      type: "image/png",
    });
    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("user_id", `${userData.id}`);
    formData.append("check_liveness", "true");
    try {
      const res = await ekycMatchingUserFace({ data: formData });
      if (res.data?.data) {
        isYou = true;
      }
    } catch (error) {}

    return isYou;
  }, [mediaRecorderRef.current, webcamRef]);

  useEffect(() => {
    if (!mediaRecorderRef.current) return;
    if (recordVideoTime > 0) {
      mediaRecorderRef.current!.requestData();
    }
  }, [recordVideoTime]);
  useEffect(() => {
    return () => {
      resetSupervisorData();
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.removeEventListener(
          "dataavailable",
          handleDataAvailable
        );
      }

      clearTimeout(timeoutId.current);
      clearInterval(intervalId.current);
      clearTimeout(timeoutCheckingConcentrate.current);
      clearTimeout(timeoutCheckingPosition.current);
      clearInterval(intervalChecking.current);
    };
  }, []);

  return (
    <Modal
      title={
        <Flex align="start" gap={8}>
          <RightOutlined
            onClick={() => {
              if (!error) {
                handleClose();
              }
            }}
            className="p-3 rounded-lg border border-[#D0D5DD]"
          />
          <Flex vertical gap={4} align="center" className="ml-2" wrap="wrap">
            <Paragraph className="!mb-0 w-full">{title}</Paragraph>
            {showingError && (
              <Paragraph className="!mb-0">
                {messagesDisplay || description}
              </Paragraph>
            )}
            {isCloseCamera === "open" ? (
              errorHandleAttendance === "error" ? (
                <Paragraph className="!mb-0 w-full" color="#B42318">
                  Điểm danh thất bại. Vui lòng giữ mặt trong khung hình trong
                  quá trình điểm danh.
                </Paragraph>
              ) : errorHandleAttendance === "success" ? (
                <Paragraph className="!mb-0 w-full">
                  Điểm danh thành công
                </Paragraph>
              ) : (
                ""
              )
            ) : type === "study" && count > 0 ? (
              "Hệ thống chuẩn bị điểm danh. Vui lòng giữ mặt trong khung hình"
            ) : (
              ""
            )}
          </Flex>
        </Flex>
      }
      className={`fixed z-50 ${isOpen ? "" : "invisible"}`}
      closeIcon={null}
      wrapClassName={!hasMask ? "!static" : ""}
      style={{
        top: "48%",
        right: 15,
      }}
      width={420}
      mask={false}
      open={true}
      onCancel={handleCancel}
      footer={null}
      modalRender={(modal) => (
        <Draggable
          disabled={false}
          bounds={bounds}
          nodeRef={draggleRef}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <div className="bg-[#00000060] rounded-md text-center overflow-hidden">
        <Flex
          justify="center"
          className="relative"
          style={{
            width: "100%",
            height: 280,
          }}
        >
          {isLoadingModel && (
            <div className="absolute z-20 inset-0 grid place-content-center">
              <LoadingOutlined
                className=" text-3xl"
                style={{ fontSize: "32px" }}
              />
            </div>
          )}
          <Button
            type="default"
            className="absolute !rounded-full z-30 top-2 right-2 bg-[#FEF3F2] border border-[#FECDCA] px-3 h-auto"
          >
            <Flex gap={6}>
              <div className="mt-[3px]">
                <div className="relative top-1 flex items-center justify-center pointer-events-none">
                  <span className="w-2 h-2 rounded-full bg-[#F04438]"></span>
                  <span className=" absolute inset-0 animate-signal border border-[#D92D20] rounded-full"></span>
                </div>
              </div>
              <Typography.Text className="text-[#B42318]">
                {error ? "Không cấp quyền truy cập camera" : "Đang ghi hình"}
              </Typography.Text>
            </Flex>
          </Button>
          {type === "study" && (
            <Text className="absolute z-30 top-1/2 left-1/2 text-white text-[70px] font-bold	-translate-y-1/2 -translate-x-1/2">
              {count > 0 ? count : ""}
            </Text>
          )}
          <Webcam
            audio={false}
            width={372}
            height={280}
            mirrored={true}
            disabled={!isFaceAttendance}
            ref={webcamRef}
            onUserMedia={(stream) => {
              if (error && stream) {
                setError("");
              }
            }}
            onUserMediaError={() => {
              setError("Not Allowed");
            }}
            videoConstraints={videoConstraints}
            onLoadedMetadata={onLoadedMetadata}
          />
        </Flex>
      </div>
    </Modal>
  );
};

export default memo(FaceRecordModal);
