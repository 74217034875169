import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd";
import { Button, Input, Select, TimePicker, Upload, message } from "antd";
import dayjs from "dayjs";
import { getDocument } from "pdfjs-dist/build/pdf";
import "pdfjs-dist/build/pdf.worker.entry";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ExpandableText from "../../../../../../components/expandable-Text/ExpandableText";
import ConfirmModal from "../../../../../../components/modal/confirm-modal/ConfirmModal";
import {
  addNote,
  deleteNote,
  editNote,
} from "../../../../../../service/learning-course";
import {
  globalStore,
  learningCourseStore,
} from "../../../../../../stores/stores";
import { Note, Unit } from "../../../../../../types/course";
import { convertSeconds } from "../../../../../../utils/format";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../utils/notification";
import AttachmentViewer from "./AttachmentViewer";
import "./UnitNoteItem.scss";
import { getDisabledTimeRanges } from "../../../../../../utils/picker";

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = TimePicker;

const mockUpload = ({ file, onSuccess, onError }: any) => {
  setTimeout(() => {
    if (Math.random() > 0) {
      onSuccess("ok");
    } else {
      onError("upload failed");
    }
  }, 1000);
};

const getFileIcon = (fileType: string) => {
  switch (fileType) {
    case "application/pdf":
    case "pdf":
      return (
        <img
          src={"/assets/icons/pdf-icon.svg"}
          alt="file"
          style={{ width: "48px", height: "48px" }}
        />
      );
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "word":
      return (
        <img
          src={"/assets/icons/word-icon.svg"}
          alt="file"
          style={{ width: "48px", height: "48px" }}
        />
      );
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return (
        <img
          src={"/assets/icons/pptx-icon.svg"}
          alt="file"
          style={{ width: "48px", height: "48px" }}
        />
      );
    case "application/vnd.ms-excel":
    case "ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return (
        <img
          src={"/assets/icons/xlsx-icon.svg"}
          alt="file"
          style={{ width: "48px", height: "48px" }}
        />
      );
    case "text/plain":
    case "text":
      return (
        <img
          src={"/assets/icons/txt-icon.svg"}
          alt="file"
          style={{ width: "48px", height: "48px" }}
        />
      );
    case "image/jpeg":
    case "image/png":
    case "image/gif":
      return (
        <img
          src={URL.createObjectURL(new Blob([fileType], { type: fileType }))}
          alt="file"
          style={{ width: "48px", height: "48px" }}
        />
      );
    default:
      return <UploadOutlined style={{ fontSize: "24px", color: "#8c8c8c" }} />;
  }
};

type UnitNoteItemProps = {
  note: any;
  cancelEdit: (id: number) => void;
  unitId: number;
  position: number;
  noteArea: boolean | undefined;
  unitData: Unit;
  setIsAdding: any;
  isEditing: any;
  onEdit: any;
};

const UnitNoteItem = ({
  note,
  cancelEdit,
  unitId,
  position,
  noteArea,
  unitData,
  setIsAdding,
  isEditing,
  onEdit,
}: UnitNoteItemProps) => {
  const { id } = useParams();
  const { reload, setReload } = globalStore();

  const [noteName, setNoteName] = useState<string>(note.title);
  const [noteContent, setNoteContent] = useState<string>(note.content);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isDelNote, setIsDelNote] = useState<boolean>(false);
  const [videoPositionStart, setVideoPositionStart] = useState<number>(0);
  const [videoPositionEnd, setVideoPositionEnd] = useState<number>(0);
  const [noteItem, setNoteItem] = useState<Note>(note);
  const [noteAreaSelect, setNoteAreaSelect] = useState<boolean>(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pdfPage, setPdfPage] = useState<any>(
    note?.documentPosition ? Number(note?.documentPosition) : 1
  );
  const [fileDelete, setFileDelete] = useState<any[]>();
  const [xApiValue, setXApiValue] = useState<string>("");

  const {
    setIsCheckAddMarked,
    highlights,
    videoTime,
    setIsAddMarked,
    setNoteId,
  } = learningCourseStore();

  const handleDelNote = async () => {
    try {
      await deleteNote({
        ids: [noteItem?.id],
      });
      notifySuccess("Xoá ghi chú thành công");
      setIsDelNote(false);
      setReload(!reload);
    } catch (error) {
      notifyError("Có lỗi xảy ra vui lòng thử lại sau");
    }
  };

  const fetchNumPages = async () => {
    const url = unitData?.documents && unitData?.documents[0]?.mainKeyUrl;

    try {
      const loadingTask = getDocument(url);
      const pdf = await loadingTask.promise;
      setNumPages(pdf.numPages);
    } catch (error) {
      console.error("Error fetching number of pages:", error);
    }
  };

  function getFiveWords(text: string) {
    const words = text?.split(" ");
    if (words?.length > 5) {
      return words?.slice(0, 5)?.join(" ") + "...";
    }
    return words?.join(" ");
  }

  const handleSaveNote = async () => {
    try {
      const formData = new FormData();
      if (fileList && Array.isArray(fileList)) {
        fileList
          .filter((file) => file?.type !== undefined)
          .forEach((file) => {
            formData.append("attachments", file.originFileObj as File);
          });
      }

      if (!isEditing) {
        formData.append("courseId", id ? id : "");
        formData.append("isPinned", "false");
        formData.append("unitId", unitId.toString());
      }
      if (isEditing) {
        formData.append("id", noteItem?.id ? noteItem?.id.toString() : "");
      }

      if (unitData?.module === "text" && highlights[0].text) {
        formData.append("markerTextStart", highlights[0].start.toString());
        formData.append(
          "markerTextEnd",

          highlights[0].end.toString()
        );
        formData.append("markerText", highlights[0].text);
      }
      formData.append("title", noteName);
      formData.append("content", noteContent);
      formData.append("isOfWholeUnit", noteAreaSelect ? "true" : "false");

      if (unitData?.module === "video" || unitData?.module === "mp3") {
        formData.append(
          "videoPositionStart",
          noteAreaSelect ? "0" : videoPositionStart.toString()
        );
        formData.append(
          "videoPositionEnd",
          noteAreaSelect ? "0" : videoPositionEnd.toString()
        );
      }

      if (unitData?.module === "pdf") {
        formData.append("documentPosition", String(pdfPage));
      }
      if (unitData?.module === "scorm" || unitData?.module === "xapi") {
        formData.append("documentPosition", String(xApiValue));
      }

      if (fileDelete) {
        formData.append(
          "deleteAttachmentIds",
          String(fileDelete?.map((it) => it.id).join(",")) ?? undefined
        );
      }

      const url = isEditing ? editNote : addNote;
      await url(formData);

      notifySuccess("Lưu ghi chú thành công");
      setReload(!reload);
      setIsAdding(false);
      setIsAddMarked(false);
      cancelEdit(note.id);
    } catch (error) {}

    setIsCheckAddMarked(false);
  };

  const handleCancelEdit = () => {
    resetForm();
    cancelEdit(note.id);
  };

  const handlePinNote = async () => {
    try {
      const payload = {
        id: noteItem?.id,
        isPinned: !noteItem.isPinned,
      };
      await editNote(payload);
      notifySuccess(
        `${!noteItem.isPinned ? "Ghim" : "Bỏ ghim"} ghi chú thành công`
      );
      setReload(!reload);
      setNoteItem((item) => {
        return { ...item, isPinned: !item?.isPinned };
      });
    } catch (error) {
      notifyError("Có lỗi xảy ra vui lòng thử lại sau");
    }
  };

  const handleFileUploadChange: UploadProps["onChange"] = ({
    file,
    fileList: newFileList,
  }) => {
    if (file.status === "error") {
      message.error(`${file.name} tải lên thất bại.`);
    }
    setFileList(newFileList);
  };

  useEffect(() => {
    const deleteElm = note?.attachments?.filter(
      (item1: any) => !fileList.some((item2) => item1.id === item2?.uid)
    );
    setFileDelete(deleteElm);
  }, [fileList, note]);

  useEffect(() => {
    setFileList(
      note?.attachments?.map((attachment: any) => {
        return {
          uid: attachment.id,
          name: attachment.fileName,
          status: "done",
          url: attachment.mainKey,
          thumbUrl: attachment.mainKey,
          attachmentType: attachment.attachmentType,
        };
      })
    );
  }, [note]);
  const beforeFileUpload = (file: UploadFile) => {
    const isFile =
      file.type &&
      [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/plain",
        "image/jpeg",
        "image/png",
        "image/gif",
      ].includes(file.type);
    if (!isFile) {
      message.error(
        "Bạn chỉ có thể tải lên các tệp có định dạng PDF, DOC, DOCX, PPTX, XLSX, TXT hoặc ảnh!"
      );
    }
    return isFile || Upload.LIST_IGNORE;
  };

  const customFileRender = (originNode: React.ReactNode, file: any) => {
    const isImage =
      (file.type && file.type.startsWith("image/")) ||
      file?.attachmentType === "image";
    const icon = getFileIcon(file.type || file?.attachmentType || "");
    const imageUrl = isImage
      ? file?.url
        ? file?.url
        : URL?.createObjectURL(file?.originFileObj as File)
      : "";
    return (
      <div className="custom-upload-list-item">
        <div className="custom-upload-list-item-icon">
          {isImage ? (
            <img
              src={imageUrl}
              alt="file"
              style={{ width: "40px", height: "40px" }}
            />
          ) : (
            icon
          )}
        </div>
        <div className="custom-upload-list-item-info">
          <span className="custom-upload-list-item-name">
            <div className="name">{file.name}</div>
            {/* {isEditing ? ( */}
            <button
              className="custom-upload-list-item-remove"
              onClick={() => handleRemoveFile(file)}
            >
              <CloseOutlined />
            </button>
            {/* ) : null} */}
          </span>
          {file.attachmentType ? null : (
            <span className="custom-upload-list-item-size">
              {file.size && (file.size / 1024).toFixed(2)} KB
              {file.status === "done" && (
                <span className="custom-upload-list-item-status">
                  {" "}
                  - 100% uploaded
                </span>
              )}
            </span>
          )}
        </div>
      </div>
    );
  };

  const handlePagePdfChange = (value: any) => {
    setPdfPage(value);
  };

  const onChangeTime = (times: any, timeStrings: [string, string]) => {
    if (times) {
      const totalSecondsStart =
        dayjs(timeStrings[0], "HH:mm:ss").hour() * 3600 +
        dayjs(timeStrings[0], "HH:mm:ss").minute() * 60 +
        dayjs(timeStrings[0], "HH:mm:ss").second();
      const totalSecondsEnd =
        dayjs(timeStrings[1], "HH:mm:ss").hour() * 3600 +
        dayjs(timeStrings[1], "HH:mm:ss").minute() * 60 +
        dayjs(timeStrings[1], "HH:mm:ss").second();

      setVideoPositionStart(totalSecondsStart);
      setVideoPositionEnd(totalSecondsEnd);
    } else {
    }
  };

  const handleRemoveFile = (file: UploadFile) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };

  const renderNoteArea = () => {
    if (unitData?.module === "pdf") {
      fetchNumPages();
      return (
        <>
          {numPages !== null && (
            <Select
              placeholder="Chọn trang"
              style={{ height: "44px", width: "100%" }}
              onChange={handlePagePdfChange}
              value={pdfPage}
            >
              {Array.from({ length: numPages }, (_, index) => (
                <Option key={index} value={index}>
                  Trang {index + 1}
                </Option>
              ))}
            </Select>
          )}
        </>
      );
    } else if (unitData?.module === "video" || unitData?.module === "mp3") {
      return (
        <RangePicker
          onChange={onChangeTime}
          format="HH:mm:ss"
          style={{ height: "44px", width: "100%" }}
          placeholder={["Giờ bắt đầu", "Giờ kết thúc"]}
          defaultValue={[
            note?.videoPositionStart
              ? dayjs().startOf("day").add(note?.videoPositionStart, "second")
              : null,
            note?.videoPositionEnd
              ? dayjs().startOf("day").add(note?.videoPositionEnd, "second")
              : null,
          ]}
          disabledTime={getDisabledTimeRanges(videoTime)}
        />
      );
    } else if (unitData?.module === "xapi" || unitData?.module === "scorm") {
      return (
        <Input
          value={xApiValue}
          onChange={(e) => setXApiValue(e.target.value)}
          placeholder="Nhập vùng chọn ghi chú"
          allowClear
          style={{ height: "44px", border: "none" }}
        />
      );
    } else if (unitData?.module === "text") {
      return (
        <div className="note-area">
          Đoạn văn bản:{" "}
          {getFiveWords(
            highlights?.find((it) => it?.isNew === true)?.text || " "
          )}
        </div>
      );
    } else return null;
  };

  const renderAreaDetail = () => {
    if (unitData?.module === "pdf") {
      fetchNumPages();
      return <>Trang {Number(note?.documentPosition) + 1}</>;
    } else if (unitData?.module === "video" || unitData?.module === "mp3") {
      return (
        <>
          {convertSeconds(note?.videoPositionStart).hours}:
          {convertSeconds(note?.videoPositionStart).minutes}:
          {convertSeconds(note?.videoPositionStart).seconds} -&nbsp;
          {convertSeconds(note?.videoPositionEnd).hours}:
          {convertSeconds(note?.videoPositionEnd).minutes}:
          {convertSeconds(note?.videoPositionEnd).seconds}
        </>
      );
    } else if (unitData?.module === "text") {
      return <>Đoạn văn bản: {getFiveWords(note?.markerText)}</>;
    } else if (unitData?.module === "xapi" || unitData?.module === "scorm") {
      return <div>{note?.documentPosition}</div>;
    } else return null;
  };
  useEffect(() => {
    setNoteAreaSelect(noteArea || note?.isOfWholeUnit);
  }, []);

  const resetForm = () => {
    setNoteName(note.title || "");
    setNoteContent(note.content || "");
    setFileList(note?.attachments || []);
    setNoteAreaSelect(note?.isOfWholeUnit || false);
    setPdfPage(note?.documentPosition ? Number(note?.documentPosition) : null);
    setVideoPositionStart(0);
    setVideoPositionEnd(0);
  };

  useEffect(() => {
    if (noteAreaSelect !== undefined) {
      resetForm();
    }
  }, [note, noteArea]);

  return (
    <div
      className={`unit-note-item ${isEditing ? "unit-note-item-change" : ""}`}
      id={`note-${noteItem?.id}`}
    >
      <div>
        {!isEditing && !note.isAdd && (
          <div className="time-create">
            <div>
              Thời gian tạo:&nbsp;
              {dayjs(noteItem.createdDate).format("DD/MM/YYYY")}
              &nbsp;•&nbsp;{dayjs(noteItem.createdDate).format("HH:mm")}
            </div>
          </div>
        )}

        {isEditing || note.isAdd ? (
          <div className="item-field">
            <div>
              <div className="label">Vùng chọn ghi chú</div>
              {isEditing ? (
                <Select
                  placeholder="Chọn vùng ghi chú"
                  style={{ height: "44px", width: "100%" }}
                  onChange={(value) => {
                    setNoteAreaSelect(value);
                  }}
                  defaultValue={note?.isOfWholeUnit}
                >
                  <Option value={true}>Toàn bộ học liệu</Option>
                  <Option value={false}>Chọn vùng ghi chú</Option>
                </Select>
              ) : null}
              {note.isAdd && noteArea ? (
                <Input
                  readOnly
                  value={"Toàn bộ học liệu"}
                  style={{ height: "44px", userSelect: "none" }}
                />
              ) : null}

              {(!noteAreaSelect && !noteArea) || (note.isAdd && !noteArea) ? (
                <div className="mt-2">{renderNoteArea()}</div>
              ) : null}
            </div>
            <Input
              value={noteName}
              onChange={(e) => setNoteName(e.target.value)}
              placeholder="Nhập tiêu đề ghi chú"
              allowClear
              style={{ height: "44px" }}
            />
            <TextArea
              value={noteContent}
              onChange={(e) => setNoteContent(e.target.value)}
              placeholder="Nhập nội dung ghi chú"
              rows={4}
              allowClear
            />
            <Upload
              action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
              listType="picture"
              multiple
              customRequest={mockUpload}
              itemRender={customFileRender}
              onChange={handleFileUploadChange}
              beforeUpload={beforeFileUpload}
              fileList={fileList}
            >
              <div className="upload-note-area">
                Thêm vào tài liệu của bạn
                <Button className="upload-discussion-btn">
                  <img
                    src={"/assets/icons/file-05.svg"}
                    alt="file"
                    style={{ width: "24px", height: "24px" }}
                  />
                  Tải tệp đính kèm
                </Button>
              </div>
            </Upload>
            <div className="hr-tag-item"></div>
            <div className="note-action">
              <Button onClick={handleCancelEdit}>Huỷ</Button>
              <Button className="save-note" onClick={handleSaveNote}>
                Lưu ghi chú
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="note-name">{noteName}</div>

            <div className="note-area">
              Vùng chọn ghi chú:{" "}
              <div className="all-unit">
                {note?.isOfWholeUnit ? (
                  "Toàn bộ học liệu"
                ) : (
                  <>{renderAreaDetail()}</>
                )}
              </div>
            </div>
            <div className="note-content">
              <ExpandableText text={noteContent} maxLines={5} />
            </div>
            <div className="mt-2">
              <AttachmentViewer fileList={fileList} />
            </div>
          </>
        )}
      </div>
      {!isEditing && !note.isAdd ? (
        <div className="note-action">
          <Button
            className={noteItem?.isPinned ? "pinned-note-btn" : ""}
            onClick={handlePinNote}
          >
            <img
              src={`/assets/icons/${noteItem?.isPinned ? "pin-white-icon.svg" : "pin-icon.svg"}`}
              alt="a"
            />
            {noteItem?.isPinned ? "Bỏ ghim ghi chú" : "Ghim ghi chú"}
          </Button>
          <Button
            // onClick={() => {
            //   setIsEditing(true);
            //
            //   setNoteId(noteItem?.id);
            // }}
            onClick={() => {
              onEdit(note.id);
              setIsAddMarked(true);
              setNoteId(noteItem?.id);
            }}
          >
            <img src="/assets/icons/edit-black-icon.svg" alt="edit" />
            Sửa ghi chú
          </Button>
          <Button onClick={() => setIsDelNote(true)}>
            <img src="/assets/icons/trash-icon.svg" alt="trash" />
            Xoá ghi chú
          </Button>
        </div>
      ) : null}
      <ConfirmModal
        open={isDelNote}
        handleOk={handleDelNote}
        handleCancel={() => setIsDelNote(false)}
        title="Xác nhận xoá ghi chú"
        confirmIcon={`${process.env.PUBLIC_URL}/assets/icons/delete-icon.svg`}
        content="Ghi chú đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá ghi chú?"
      />
    </div>
  );
};

export default UnitNoteItem;
