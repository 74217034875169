import {
  FileExcelOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Image, Modal, Tabs, Upload } from "antd";
import { TabsProps } from "antd/lib/tabs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ConfirmModal from "../../../../../components/modal/confirm-modal/ConfirmModal";
import { deleteNote, editNote } from "../../../../../service/learning-course";
import { globalStore } from "../../../../../stores/stores";
import { MyNote } from "../../../../../types/myNote";
import { notifyError, notifySuccess } from "../../../../../utils/notification";
import "./NoteDetailModal.scss";

type Props = {
  open: boolean;
  handleOk: () => void;
  handleCancel?: () => void;
  noteDetail: MyNote;
  id: number;
};

const NoteDetailModal: React.FC<Props> = ({
  open,
  handleOk,
  handleCancel,
  id,
  noteDetail,
}) => {
  const { reload, setReload } = globalStore();
  const [isDelNote, setIsDelNote] = useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<any>(null);

  useEffect(() => {
    if (!isDelNote) {
      setIsConfirmOpen(false);
    }
  }, [isDelNote]);

  const handleConfirmCancel = () => {
    setIsDelNote(false);
  };

  const openConfirmModal = () => {
    setIsDelNote(true);
    setIsConfirmOpen(true);
  };

  const handleDelNote = async () => {
    try {
      await deleteNote({
        ids: [id],
      });
      notifySuccess("Xoá ghi chú thành công");
      setIsDelNote(false);
      handleCancel && handleCancel();
      setReload(!reload);
    } catch (error) {
      notifyError("Có lỗi xảy ra vui lòng thử lại sau");
      console.error("Error deleting note:", error);
    }
  };

  const handlePinNote = async () => {
    try {
      const payload = {
        id: id,
        isPinned: true,
      };
      await editNote(payload);
      notifySuccess(
        `${!noteDetail.isPinned ? "Ghim" : "Bỏ ghim"} ghi chú thành công`
      );
      setReload(!reload);
    } catch (error) {
      notifyError("Có lỗi xảy ra vui lòng thử lại sau");
      console.error("Error pinning note:", error);
    }
  };

  const [activeKey, setActiveKey] = useState<string>("1");

  const onTabChange = (key: string) => {
    setActiveKey(key);
  };

  const renderAttachFile = () => {
    if (!noteDetail?.attachments || noteDetail.attachments.length === 0) {
      return <div>Không có tệp đính kèm</div>;
    }

    const getFileIcon = (fileType: string) => {
      switch (fileType) {
        case "application/pdf":
        case "pdf":
          return (
            <img
              src={"/assets/icons/pdf-icon.svg"}
              alt="file"
              style={{ width: "48px", height: "48px" }}
            />
          );
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "word":
          return (
            <img
              src={"/assets/icons/word-icon.svg"}
              alt="file"
              style={{ width: "48px", height: "48px" }}
            />
          );
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return (
            <img
              src={"/assets/icons/pptx-icon.svg"}
              alt="file"
              style={{ width: "48px", height: "48px" }}
            />
          );
        case "application/vnd.ms-excel":
        case "ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return (
            <img
              src={"/assets/icons/xlsx-icon.svg"}
              alt="file"
              style={{ width: "48px", height: "48px" }}
            />
          );
        case "text/plain":
        case "text":
          return (
            <img
              src={"/assets/icons/txt-icon.svg"}
              alt="file"
              style={{ width: "48px", height: "48px" }}
            />
          );
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          return (
            <img
              src={URL.createObjectURL(
                new Blob([fileType], { type: fileType })
              )}
              alt="file"
              style={{ width: "48px", height: "48px" }}
            />
          );
        default:
          return (
            <UploadOutlined style={{ fontSize: "24px", color: "#8c8c8c" }} />
          );
      }
    };
    const handlePreview = (file: any) => {
      const isImage =
        (file.type && file.type.startsWith("image/")) ||
        file?.attachmentType === "image";

      if (isImage) {
        setPreviewImage(file.mainKey);
        setPreviewVisible(true);
      } else {
        window.open(file.mainKey, "_blank");
      }
    };

    const customFileRender = (originNode: React.ReactNode, file: any) => {
      const isImage =
        (file.type && file.type.startsWith("image/")) ||
        file?.attachmentType === "image";

      const icon = getFileIcon(file.type || file?.attachmentType || "");
      const imageUrl = file?.mainKey;

      return (
        <div
          className="custom-upload-list-item"
          onClick={() => handlePreview(file)}
        >
          <div className="custom-upload-list-item-icon">
            {isImage ? (
              <img
                src={imageUrl}
                alt="file"
                style={{ width: "50px", height: "50px" }}
              />
            ) : (
              icon
            )}
          </div>
          <div className="custom-upload-list-item-info">
            <span className="custom-upload-list-item-name">
              <div className="name">{file.fileName}</div>
            </span>
          </div>
        </div>
      );
    };
    return (
      <div className="attachment-list">
        <Upload
          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
          listType="picture"
          multiple
          itemRender={customFileRender}
          fileList={noteDetail?.attachments}
          showUploadList={{ showPreviewIcon: true }}
        ></Upload>
      </div>
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Nội dung",
      children: <>{noteDetail?.content}</>,
    },
    {
      key: "2",
      label: "Tệp đính kèm",
      children: <>{renderAttachFile()}</>,
    },
  ];

  return (
    <>
      <Modal
        open={open}
        title={
          <>
            <div className="title-container">
              <img src="/assets/icons/note-border-icon.svg" alt="note" />
              <div>
                <div className="text-title-18">{noteDetail?.title}</div>
                <div className="created-time pt-1">
                  Thời gian tạo{" "}
                  {moment(noteDetail?.createdDate).format("HH:mm")} -{" "}
                  {moment(noteDetail?.createdDate).format("DD/MM/YYYY")}
                </div>
              </div>
            </div>
            <div className="note-location mb-2">
              <Breadcrumb>
                <Breadcrumb.Item>{noteDetail?.sectionName}</Breadcrumb.Item>
                <Breadcrumb.Item>{noteDetail?.sequenceName}</Breadcrumb.Item>
                <Breadcrumb.Item>{noteDetail?.unitName}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Tabs defaultActiveKey="1" onChange={onTabChange} items={items} />
          </>
        }
        className="note-detail-modal"
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={
          <>
            {noteDetail?.updatedDate ? (
              <div className="edit-time">
                Đã chỉnh sửa lúc{" "}
                {moment(noteDetail?.updatedDate).format("HH:mm")} -{" "}
                {moment(noteDetail?.updatedDate).format("DD/MM/YYYY")}
              </div>
            ) : null}
            <div className="hr-tag-item"></div>
            <div className="note-action">
              <Button
                className={noteDetail?.isPinned ? "pinned-note-btn" : ""}
                onClick={handlePinNote}
              >
                <img
                  src={`/assets/icons/${noteDetail?.isPinned ? "pin-white-icon.svg" : "pin-icon.svg"}`}
                  alt="a"
                />
                {noteDetail?.isPinned ? "Bỏ ghim ghi chú" : "Ghim ghi chú"}
              </Button>
              <Button onClick={openConfirmModal}>
                <img src="/assets/icons/trash-icon.svg" alt="trash" />
                Xoá ghi chú
              </Button>
            </div>
          </>
        }
      >
        {items.map((tab) => (
          <div
            key={tab.key}
            style={{ display: tab.key === activeKey ? "block" : "none" }}
          >
            {tab.children}
          </div>
        ))}
      </Modal>
      <ConfirmModal
        open={isConfirmOpen}
        handleOk={handleDelNote}
        handleCancel={handleConfirmCancel}
        title="Xác nhận xoá ghi chú"
        confirmIcon={`${process.env.PUBLIC_URL}/assets/icons/delete-icon.svg`}
        content={`Ghi chú đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá ghi chú?`}
      />
      {previewVisible && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewVisible,
            onVisibleChange: (visible) => setPreviewVisible(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};

export default NoteDetailModal;
