import React, { useEffect, useState } from "react";
import { Checkbox, Col, Flex, Form, Row, Spin, Tooltip } from "antd";
import {
  QuestionBeProps,
  QuestionProps,
} from "../../../../../../../types/course";
import EmptyComponent from "../../../../../../../components/empty";
import { convertNumberingTypeToText } from "../../../../../../../utils/format";
import {
  JSON_SUB_TYPE,
  QuestionTypeEnum,
} from "../../../../../../../constants/course";
import {
  HoursGlassIcon,
  QuestionMarkIcon,
} from "../../../../../../../components/icons";
import Countdown from "../../../../../../../components/countdown/CountDown";
import { isEmpty, toNumber } from "lodash";
import useKeyword from "../../../../../../../hooks/useKeyword";
import { startSingleQuiz } from "../../../../../../../service/learning-course";
import { Link } from "react-router-dom";

function ChooseMultiVideo({
  data,
  initialData,
  disabled,
  pause,
  configs,
  onFinish = () => {},
}: QuestionProps) {
  const initialTime =
    data?.timeToCompleted || configs?.actionConfig?.timeForQuiz || 0;

  const blockId = toNumber(useKeyword("blockId"));
  const currentScreen = useKeyword("screen");
  const isSequentiallyQuiz = configs?.generalConfig?.sequentiallyQuiz;
  const [loading, setLoading] = useState<boolean>(false);
  const [canStart, setCanStart] = useState<boolean>(false);

  useEffect(() => {
    // Xử lý câu hỏi có đếm thời gian riêng
    if (currentScreen === "train") {
      if (!isEmpty(data) && isSequentiallyQuiz && initialTime) {
        const startSingle = async () => {
          try {
            setLoading(true);
            await startSingleQuiz({
              blockId: blockId,
              quizId: data?.id,
            });
            setCanStart(true);
          } catch (err: any) {
          } finally {
            setLoading(false);
          }
        };
        startSingle();
      }
    }
  }, [isSequentiallyQuiz]);
  return (
    <div>
      <Spin spinning={loading}>
        <div className="question-header mb-3">
          {configs?.actionConfig?.timeForQuiz && configs?.generalConfig?.sequentiallyQuiz ? (
            <div className="question-time flex align-center gap-8">
              <HoursGlassIcon />
              <div className="text-primary font-size-14 line-height-20 font-weight-6 flex align-center gap-8">
                Trả lời câu hỏi trong:
                <Countdown
                  pause={pause}
                  initialTime={canStart ? toNumber(initialTime) : 0}
                  onFinish={onFinish}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="question-title">
             <Flex
              justify="space-between"
              align="center"
              className="font-size-16 line-height-24 font-weight-6"
            >
              {data?.settingGeneral?.requiredAnswer ? (
                <span className="text-danger">*</span>
              ) : (
                ""
              )}
              <div className="flex-1 ml-1">Câu hỏi {toNumber(data?.index) + 1}:{" "}</div>
              {configs?.generalConfig?.showQuizScore && (
                <span className="total-point font-size-14">
                  {`(${data?.totalPoints || 0} điểm)`}
                </span>
              )}
            </Flex>
            <Flex align="flex-start" gap={8}>
              <div
                className="font-size-16 line-height-24 font-weight-6"
                dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
              ></div>
              {data?.settingHint?.isDisplayInstruction && data?.isShowHint ? (
                <Tooltip
                  placement="bottom"
                  title={
                    <div>
                      {data?.settingHint?.content ? (
                        <p className="text-14 font-weight-5">
                          Hướng dẫn: {data?.settingHint?.content}
                        </p>
                      ) : (
                        "Không có hướng dẫn"
                      )}

                      {data?.settingHint?.schema ? (
                        <p className="font-size-14 mt-2">
                          Đường dẫn tham khảo:{" "}
                          <Link
                            target="_blank"
                            to={`${data?.settingHint?.uri}${data?.settingHint?.schema}`}
                          >
                           {`${data?.settingHint?.uri}${data?.settingHint?.schema}`}
                          </Link>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  }
                >
                  <span className="quest-icon text-secondary w-max-content">
                    <QuestionMarkIcon />
                  </span>
                </Tooltip>
              ) : (
                ""
              )}
            </Flex>
          </div>
        </div>
        <Row gutter={[32, 32]}>
          {data?.questions && data?.questions?.length > 0 ? (
            data?.questions?.map((quiz: QuestionBeProps, index) => (
              <Col key={quiz.uuid + index} xs={24} md={12}>
                <div className={`image-quiz-wrap`}>
                  <Form.Item
                    className="mb-12"
                    name={`chooseMultiVideo-${data?.id}-${QuestionTypeEnum.CHOOSE_MULTI_VIDEO}-${JSON_SUB_TYPE.ChoosingRequest}-${index}`}
                    valuePropName="checked"
                    initialValue={initialData?.answer?.find(
                      (item: string) => item === quiz?.uuid
                    )}
                  >
                    <Checkbox disabled={disabled}>
                      <span className="font-size-16 line-height-24 font-weight-5">
                        {convertNumberingTypeToText(
                          index + 1,
                          data?.settingGeneral?.numberingType
                        )}
                        . {quiz?.content}
                      </span>
                    </Checkbox>
                  </Form.Item>
                  <video className="quest-video" controls>
                    <source src={quiz?.filePath || ""} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </Col>
            ))
          ) : (
            <div className="center">
              <EmptyComponent description="Không có nội dung" />
            </div>
          )}
        </Row>
      </Spin>
    </div>
  );
}

export default ChooseMultiVideo;
