import React, { useState } from "react";
import { Button, Flex, Select, Table } from "antd";
import type { TableProps } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { DEFAULT_PAGE_SIZE } from "../../../../constants";
import { KeyValue } from "../../../../types/app";
import { isEmpty } from "lodash";
import { DEFAULT_MODULE_GROUP } from "../../../../constants/statistics";

interface CustomTableProps<T> extends TableProps<T> {
  pageSize?: number;
  title?: string | any;
  columns?: TableProps<any>["columns"];
  data?: any[];
  pagination?: any;
  placeholder?: string;
  filterOptions?: KeyValue[];
  showFilter?: boolean;
  defaultSelectedValue?: number;
  onChangeFilter?: (v?: any) => void;
}

const TableReport: React.FC<CustomTableProps<any>> = ({
  title = "",
  data,
  columns,
  pagination,
  defaultSelectedValue = DEFAULT_MODULE_GROUP,
  pageSize = DEFAULT_PAGE_SIZE,
  filterOptions = [],
  placeholder = "Chọn",
  showFilter = false,
  onChangeFilter = () => {},
  ...props
}) => {
  return (
    <div className="table-report-wrap">
      <div className="table-title">
        <Flex justify="space-between" align="center">
          <div className="font-size-16 font-weight-6">{title}</div>{" "}
          {showFilter && (
            <Select
              defaultValue={defaultSelectedValue}
              className="h-40 w-filter"
              placeholder={placeholder}
              options={filterOptions}
              onChange={onChangeFilter}
            />
          )}
        </Flex>
      </div>
      <Table
        {...props}
        className="report-table"
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{
          y: 500,
        }}
        summary={isEmpty(data) ? undefined : props.summary}
      />
    </div>
  );
};

export default TableReport;
