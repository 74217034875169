import React, { useEffect, useState } from "react";
import {
  BaseChartProps,
  StatisticChartPayload,
  StatisticChartResponseData,
} from "../../../../../types/statistic";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { fetchSearchKeywordCount } from "../../../../../service/statistics";
import BarChartSingleHorizontal from "../../../../../components/charts/BarChartSingleHorizontal";
import { DEFAULT_MODULE_GROUP, moduleGroupOptions } from "../../../../../constants/statistics";

function KeywordCount({ filterValues }: BaseChartProps) {
  const [moduleGroup, setModuleGroup] = useState<number>(DEFAULT_MODULE_GROUP);
  const [searchKeywordCountValues, setSearchKeywordCountValues] =
    useState<StatisticChartResponseData>();

  const moduleGroupPayload =
    moduleGroup === 0 || moduleGroup === -1 ? null : (moduleGroup as any);

  useEffect(() => {
    
    if (!isEmpty(filterValues)) {
      getSearchKeywordCount({
        timeUnit: filterValues.unit,
        from: filterValues?.date?.length
          ? dayjs(filterValues?.date[0]).toISOString()
          : undefined,
        to: filterValues?.date?.length
          ? dayjs(filterValues?.date[1]).toISOString()
          : undefined,
        moduleGroup: moduleGroupPayload,
        courseStructureType: moduleGroupPayload === null ? "sequence" : "unit",
        courseIds: filterValues?.course,
        courseLevelIds: filterValues?.level,
        industryGroupIds: filterValues?.department,
      });
    }
  }, [filterValues, moduleGroup]);

  const getSearchKeywordCount = async (payload: StatisticChartPayload) => {
    const res = await fetchSearchKeywordCount(payload);
    setSearchKeywordCountValues(res.data?.data);
  };

  return (
    <BarChartSingleHorizontal
      data={searchKeywordCountValues as any}
      yDataKey="criteria"
      xDataKey="count"
      colors={["#9B8AFB"]}
      dataKeys={["count"]}
      yDescription="Từ khoá"
      title="Thống kê số lượng tìm kiếm"
      showFilter
      placeholder="Bài kiểm tra đánh giá"
      filterOptions={moduleGroupOptions.concat([
        {
          label: "Bài giảng",
          value: 0,
        },
        {
          label: "Khoá học",
          value: -1,
        },
      ])}
      onChange={setModuleGroup}
    />
  );
}

export default React.memo(KeywordCount);
