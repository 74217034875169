import AccountDelete from "../account-delete/AccountDelete";
import "./AccountDeletionPolicy.scss";
type Props = {};

const AccountDeletionPolicy = (props: Props) => {
  const policyList = [
    {
      id: 1,
      content: (
        <>
          1. Quyền truy cập và dịch vụ: Xóa tài khoản sẽ dẫn đến mất quyền truy
          cập vào hệ thống và tất cả các tính năng, chức năng, và lợi ích liên
          quan đến tài khoản. Bạn sẽ không thể đăng nhập, sử dụng hay tận dụng
          bất kỳ dịch vụ nào liên quan đến tài khoản sau khi xóa.
        </>
      ),
    },
    {
      id: 2,
      content: (
        <>
          2. Mất dữ liệu: Xóa tài khoản sẽ gây mất mát toàn bộ dữ liệu liên
          quan, bao gồm thông tin cá nhân, cài đặt, lịch sử giao dịch và bất kỳ
          dữ liệu liên quan nào khác. Một khi tài khoản đã bị xóa, bạn sẽ không
          thể khôi phục lại dữ liệu.
        </>
      ),
    },
    {
      id: 3,
      content: (
        <>
          3. Không thể hoàn tác: Việc xóa tài khoản là hành động không thể hoàn
          tác. Một khi tài khoản đã bị xóa, bạn sẽ không thể khôi phục hoặc lấy
          lại tài khoản. Hãy đảm bảo rằng bạn đã xem xét kỹ lưỡng và quyết định
          xóa tài khoản một cách chín chắn
        </>
      ),
    },
    {
      id: 4,
      content: (
        <>
          4. Không thể sử dụng lại thông tin: Các thông tin và dữ liệu liên quan
          đến tài khoản sau khi xóa không thể được sử dụng lại. Điều này có
          nghĩa là tên người dùng, địa chỉ email và bất kỳ thông tin cá nhân nào
          khác không thể được tái sử dụng hoặc khôi phục.
        </>
      ),
    },
    {
      id: 5,
      content: (
        <>
          5. Không thể xóa một phần tài khoản: Việc xóa tài khoản sẽ áp dụng cho
          toàn bộ tài khoản và toàn bộ dữ liệu liên quan. Không có tùy chọn xóa
          một phần hoặc chỉ một phần dữ liệu của tài khoản.
        </>
      ),
    },
    {
      id: 6,
      content: (
        <>
          6. Chú ý đến hậu quả: Hãy cân nhắc kỹ lưỡng hậu quả của việc xóa tài
          khoản. Nếu có thắc mắc hay vấn đề, hãy liên hệ với bộ phận hỗ trợ
          khách hàng hoặc tìm hiểu các tùy chọn và cài đặt bảo mật khác có sẵn
          để xem xét các giải pháp thay thế.
        </>
      ),
    },
  ];

  return (
    <div className="account-deletion-policy">
      <div className="policy-update-time">Cập nhật, 20 tháng 12 năm 2024</div>
      <div className="account-deletion-policy-title">Chính sách hệ thống</div>
      <div className="warning-txt">
        Vui lòng đọc kĩ trước khi thực hiện thao tác xoá tài khoản.
      </div>
      <div className="account-deletion-policy-content">
        <div className="policy">Chính sách xoá tài khoản</div>
        <div className="content">
          {policyList.map((policy) => (
            <p key={policy.id}>{policy.content}</p>
          ))}
        </div>
        <div className="warning-noti">
          Khi bạn thực hiện yêu cầu xoá tài khoản. Tài khoản của bạn sẽ được xoá
          khỏi hệ thống và không thể hoàn tác
        </div>
        <AccountDelete />
      </div>
    </div>
  );
};

export default AccountDeletionPolicy;
