import React from "react";
import { Collapse, Popover } from "antd";
import moment from "moment";
import { ArrowRightOutlined, UpOutlined } from "@ant-design/icons";
import {
  CLASS_SCHEDULE,
  EXAM,
  EXERCISE,
  EXTRA_CLASS_SCHEDULE,
  TEST,
} from "../../../../constants";
import { routesConfig } from "../../../../config/routes";
import { useNavigate } from "react-router-dom";
import {
  learningCourseStore,
  studyScheduleStore,
} from "../../../../stores/stores";
import { convertSeconds } from "../../../../utils/format";
import dayjs from "dayjs";
const { Panel } = Collapse;

interface EventContentProps {
  eventInfo: any;
}

export const renderCalendarDotColor = (type: number) => {
  switch (type) {
    case CLASS_SCHEDULE:
      return "#7f56d9";
    case EXERCISE:
      return "#47cd89";
    case TEST:
      return "#fdb022";
    case EXAM:
      return "#f97066 ";
    case EXTRA_CLASS_SCHEDULE:
      return "#98a2b3";
    default:
      return "";
  }
};

export const renderEventClass = (type: number) => {
  switch (type) {
    case CLASS_SCHEDULE:
      return "class-schedule";
    case EXERCISE:
      return "exercise";
    case TEST:
      return "test";
    case EXAM:
      return "exam ";
    case EXTRA_CLASS_SCHEDULE:
      return "extra-class-schedule";
    default:
      return "";
  }
};

export const renderEventName = (type: number) => {
  switch (type) {
    case CLASS_SCHEDULE:
      return "Lịch học";
    case EXERCISE:
      return "Bài tập";
    case TEST:
      return "Kiểm tra";
    case EXAM:
      return "Bài thi ";
    case EXTRA_CLASS_SCHEDULE:
      return "Học thêm";
    default:
      return "";
  }
};

export const renderPopoverEvent = (type: number, event: any) => {
  const navigate = useNavigate();
  const { calendarSettings } = studyScheduleStore();
  const { tab, setTab, setSectionId, setSequenceId, setUnitId } =
    learningCourseStore();

  switch (type) {
    case CLASS_SCHEDULE:
    case EXTRA_CLASS_SCHEDULE:
      return (
        <div>
          <div className="popover-calendar-time">
            <img src="/assets/icons/clock.svg" alt="clock" />
            {moment.utc(event?.event.start || "").format("HH:mm")} -&nbsp;
            {moment.utc(event?.event.end || "").format("HH:mm")}
          </div>
          <div className="flex gap-8">
            <div>
              <img src="/assets/icons/info-circle.svg" alt="info-circle" />
            </div>
            <div>
              <div className="pop-info-description">Thông tin</div>
              <div
                className="pop-info-name"
                onClick={() => {
                  navigate(
                    `${routesConfig.learningCourseStructure}/${event?.event.extendedProps.courseId}`
                  );
                  setTab("1");
                  setSectionId(event?.event.extendedProps.sectionId);
                  setSequenceId(event?.event.extendedProps.sequenceId);
                  setUnitId(event?.event.extendedProps.unitId);
                }}
              >
                {event.event.title}
              </div>
            </div>
          </div>
        </div>
      );
    case EXERCISE:
    case TEST:
    case EXAM:
      return (
        <div>
          <div className="popover-calendar-time">
            <img src="/assets/icons/clock.svg" alt="clock" />
            Hoàn thành trước ngày{" "}
            {moment(event.event.end).format(
              calendarSettings.dateForm === 1
                ? "DD/MM/YYYY"
                : calendarSettings.dateForm === 2
                  ? "YYYY/MM/DD"
                  : calendarSettings.dateForm === 2
                    ? "MM/DD/YYYY"
                    : "DD/MM/YYYY"
            )}
          </div>
          <div className="flex gap-8">
            <div>
              <img src="/assets/icons/info-circle.svg" alt="info-circle" />
            </div>
            <div>
              <div className="pop-info-description">Thông tin</div>
              <div className="pop-quiz-name">
                <div>
                  <span>Tên:</span>
                  <span> {event.event.title}</span>
                </div>
                <div>
                  <span>Thời gian: </span>
                  <span>
                    <>
                      {convertSeconds(
                        event.event.extendedProps.quizInfo.totalTime
                      ).hours !== "00" ? (
                        <span>
                          {`${convertSeconds(event.event.extendedProps.quizInfo.totalTime).hours} giờ`}
                          &nbsp;
                        </span>
                      ) : null}
                      {convertSeconds(
                        event.event.extendedProps.quizInfo.totalTime
                      ).minutes !== "00" ? (
                        <span>
                          {`${convertSeconds(event.event.extendedProps.quizInfo.totalTime).minutes} phút`}
                          &nbsp;
                        </span>
                      ) : null}
                      {convertSeconds(
                        event.event.extendedProps.quizInfo.totalTime
                      ).seconds !== "00" ? (
                        <span>{`${convertSeconds(event.event.extendedProps.quizInfo.totalTime).seconds} giây`}</span>
                      ) : null}
                    </>
                  </span>
                </div>
                <div>
                  <span>Câu hỏi:</span>
                  <span>
                    {" "}
                    {event.event.extendedProps.quizInfo.totalQuestions} câu
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      return "";
  }
};

export const EventContentDayWeek: React.FC<EventContentProps> = ({
  eventInfo,
}) => {
  if (!eventInfo || !eventInfo.event) return null;
  const navigate = useNavigate();
  const { calendarSettings } = studyScheduleStore();
  const { tab, setTab, setSectionId, setSequenceId, setUnitId } =
    learningCourseStore();

  return (
    <Popover
      placement="right"
      content={
        <div className="calendar-dayWeek-popover">
          <div
            className={`popover-calendar-content ${renderEventClass(eventInfo?.event.extendedProps?.type)}`}
          >
            <Collapse
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <UpOutlined rotate={isActive ? 180 : 0} />
              )}
              expandIconPosition="end"
              bordered={false}
            >
              <Panel
                header={`${renderEventName(eventInfo?.event.extendedProps?.type)} (${eventInfo?.event.extendedProps?.courseName})`}
                key="1"
              >
                {renderPopoverEvent(
                  eventInfo?.event.extendedProps?.type,
                  eventInfo
                )}
                <div
                  className="popover-calendar-action"
                  onClick={() => {
                    navigate(
                      `${routesConfig.learningCourseStructure}/${eventInfo?.event.extendedProps.courseId}`
                    );
                    setTab("1");
                    setSectionId(eventInfo?.event.extendedProps.sectionId);
                    setSequenceId(eventInfo?.event.extendedProps.sequenceId);
                    setUnitId(eventInfo?.event.extendedProps.unitId);
                  }}
                >
                  Đi tới khoá học
                  <ArrowRightOutlined />
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      }
      trigger="hover"
    >
      <div
        className={`${renderEventClass(eventInfo?.event.extendedProps?.type)} fc-event-content`}
      >
        <span className="fc-event-title">
          {renderEventName(eventInfo?.event.extendedProps?.type)}
        </span>
        <div className="flex align-center gap-8 mt-1 mb-1">
          <img src="/assets/icons/clock.svg" />
          <div className="fc-event-time">
            Cần hoàn thành trước&nbsp;
            {moment(eventInfo.event.end).format("HH:mm")} -{" "}
            {moment(eventInfo.event.end).format(
              calendarSettings.dateForm === 1
                ? "DD/MM/YYYY"
                : calendarSettings.dateForm === 2
                  ? "YYYY/MM/DD"
                  : calendarSettings.dateForm === 2
                    ? "MM/DD/YYYY"
                    : "DD/MM/YYYY"
            )}
          </div>
        </div>
      </div>
    </Popover>
  );
};

export const EventContentMonthYear: React.FC<EventContentProps> = ({
  eventInfo,
}) => {
  if (!eventInfo || !eventInfo.event) return null;

  return (
    <div className="fc-month-content">
      <Circle
        color={renderCalendarDotColor(eventInfo.event.extendedProps.type)}
      />

      <span className="month-content">{eventInfo.event.title}</span>
    </div>
  );
};

export const EventContentYear: React.FC<EventContentProps> = ({
  eventInfo,
}) => {
  if (!eventInfo || !eventInfo.event) return null;

  return (
    <div className="fc-month-content">
      <span className="month-content">{eventInfo.event.title}</span>
    </div>
  );
};

interface CircleProps {
  color: string;
}
export const Circle: React.FC<CircleProps> = ({ color }) => {
  const circleStyle = {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: color,
    display: "inline-block",
    margin: "2px",
  };

  return <div style={circleStyle}></div>;
};

export const adjustAllDayEventTimes = (events: any[]) => {
  return events.map((event) => {
    if (event.allDay) {
      return {
        ...event,
        end: dayjs(event.end).add(1, "day").toISOString(),
      };
    }
    return event;
  });
};
