import React from "react";
import "./style.scss";
import { Empty } from "antd";

function EmptyComponent({ description = "" }) {
  return (
    <div className="mooc-empty w-full center pt-5 pb-5">
      <Empty description={description} />
    </div>
  );
}

export default EmptyComponent;
