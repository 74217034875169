// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sortableItem {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  padding: 10px 0;
  background-color: #fff;
  list-style: none;
}
.sortableItem:active {
  box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.2), 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.25);
}

.dragHandle {
  display: flex;
  width: 30px !important;
  padding: 5px 16px;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/sortable-list/components/SoftableItem/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;AACF;AACE;EACE,mIAAA;AACJ;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EAEA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,6BAAA;EACA,wCAAA;AAFF","sourcesContent":[".sortableItem {\n  display: flex;\n  justify-content: space-between;\n  flex-grow: 1;\n  align-items: center;\n  padding: 10px 0;\n  background-color: #fff;\n  list-style: none;\n\n  &:active {\n    box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.2),\n      0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.25);\n  }\n}\n\n.dragHandle {\n  display: flex;\n  width: 30px !important;\n  padding: 5px 16px;\n  // margin-right: 20px;\n  align-items: center;\n  justify-content: center;\n  border: none;\n  outline: none;\n  background-color: transparent;\n  -webkit-tap-highlight-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
