import { SearchOutlined } from "@ant-design/icons";
import { Col, Empty, Form, Input, Modal, Row, Spin } from "antd";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  exportCourseReview,
  getCourseReviewsList,
} from "../../../service/courses";
import {
  exportCurriculumReview,
  getCurriculumReviewList,
} from "../../../service/curriculum/curriculum-service";
import {
  exportSchoolReview,
  getAllCommentReview,
} from "../../../service/training-school";
import { useCourseDetailStore } from "../../../stores/stores";
import { objectToQueryString } from "../../../utils/getPageFromLink";
import { renderStarRating } from "../../../utils/renderStarRating";
import FormItemDropdown from "../../form-dropdown/FormItemDropdown";
import CustomPagination from "../../pagination/Pagination";
import ReviewCard from "../review-card/ReviewCard";
import "./ReviewListModal.scss";

type Props = {
  open?: boolean;
  title?: string;
  enterpriseUuid?: string;
  totalRecords?: string;
  handleCancel?: (value: any) => void;
  screen: string;
};

const ReviewListModal = (props: Props) => {
  const { open, handleCancel, enterpriseUuid, screen, title } = props;

  const { reload } = useCourseDetailStore();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [commentList, setCommentList] = useState<any>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);

  const getApiUrl = () => {
    switch (screen) {
      case "curriculum":
        return getCurriculumReviewList;
      case "course":
        return getCourseReviewsList;

      default:
        return null;
    }
  };

  const exportApiUrl = () => {
    switch (screen) {
      case "curriculum":
        return exportCurriculumReview;
      case "course":
        return exportCourseReview;
      default:
        return null;
    }
  };
  const apiUrl = getApiUrl();
  const exportUrl = exportApiUrl();

  const handleExportReviewList = async () => {
    const payloads = {
      enterpriseUuid: enterpriseUuid,
      keyword: form.getFieldValue("search").trim()
        ? form.getFieldValue("search").trim()
        : undefined,
      starRating:
        form.getFieldValue("star") === "Tất cả"
          ? undefined
          : form.getFieldValue("star"),
      starSort:
        form.getFieldValue("star") !== "Tất cả"
          ? undefined
          : form.getFieldValue("display") === 1
            ? "desc"
            : form.getFieldValue("display") === 2
              ? "asc"
              : undefined,
      timeSort:
        form.getFieldValue("position") === "Mặc định"
          ? "desc"
          : form.getFieldValue("position") === 1
            ? "desc"
            : form.getFieldValue("position") === 2
              ? "asc"
              : undefined,
      sortByContent:
        form.getFieldValue("position") === "Mặc định"
          ? undefined
          : form.getFieldValue("position") === 3
            ? "asc"
            : form.getFieldValue("position") === 4
              ? "desc"
              : undefined,

      page: pageNumber,
      size: pageSize,
    };
    if (exportUrl || screen === "school") {
      try {
        const res =
          screen !== "school"
            ? exportUrl && (await exportUrl(Number(id), payloads))
            : await exportSchoolReview(payloads);
        const fileData = res?.data;

        const blob = new Blob([fileData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        downloadLink.download = `nhan-xet-ve-${title}.xlsx`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error("Error downloading Excel file:", error);
      }
    }
  };

  const getCourseReviewsListAPI = useCallback(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const payload = {
        enterpriseUuid: enterpriseUuid,
        keyword: form.getFieldValue("search").trim()
          ? form.getFieldValue("search").trim()
          : undefined,
        starRating:
          form.getFieldValue("star") === "Tất cả"
            ? undefined
            : form.getFieldValue("star"),
        starSort:
          form.getFieldValue("star") !== "Tất cả"
            ? undefined
            : form.getFieldValue("display") === 1
              ? "desc"
              : form.getFieldValue("display") === 2
                ? "asc"
                : undefined,
        timeSort:
          form.getFieldValue("position") === "Mặc định"
            ? "desc"
            : form.getFieldValue("position") === 1
              ? "desc"
              : form.getFieldValue("position") === 2
                ? "asc"
                : undefined,
        sortByContent:
          form.getFieldValue("position") === "Mặc định"
            ? undefined
            : form.getFieldValue("position") === 3
              ? "asc"
              : form.getFieldValue("position") === 4
                ? "desc"
                : undefined,

        page: pageNumber,
        size: pageSize,
      };
      try {
        let res;
        if (apiUrl && screen !== "school") {
          res = await apiUrl(Number(id), objectToQueryString(payload));
        } else if (screen === "school") {
          res = await getAllCommentReview(payload);
        }

        setCommentList(res?.data?.data?.comments);
        setTotalPage(res?.data?.data?.count);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching review data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [enterpriseUuid, form, apiUrl, screen, id, pageNumber, pageSize]);

  const debouncedSubmit = useCallback(
    debounce(() => {
      getCourseReviewsListAPI();
    }, 1000),
    [getCourseReviewsListAPI]
  );

  const handleChangeKeyword = (e: any) => {
    debouncedSubmit();
  };

  useEffect(() => {
    if (open) {
      getCourseReviewsListAPI();
    }
  }, [open, pageSize, pageNumber, reload, getCourseReviewsListAPI]);

  useEffect(() => {
    form.setFieldsValue({
      star: "Tất cả",
      position: 1,
      display: "Mặc định",
    });
  }, [form]);

  useEffect(() => {
    form.setFieldsValue({ search: "" });
  }, [open, form]);

  useEffect(() => {
    const optionContents = document.querySelectorAll(
      ".ant-select-item-option-content"
    );
    optionContents.forEach((optionContent) => {
      optionContent.setAttribute(
        "style",
        "display: flex; align-items: center;"
      );
      const lastImage = optionContent.querySelector(".ant-image:last-child");
      if (lastImage) {
        (lastImage as HTMLElement).style.marginRight = "8px";
      }
    });
  }, []);

  const rankStar = [
    {
      id: "Tất cả",
      name: "Tất cả",
    },
    {
      id: 1,
      name: <>{renderStarRating(1)} 1 sao</>,
    },
    {
      id: 2,
      name: <>{renderStarRating(2)} 2 sao</>,
    },
    {
      id: 3,
      name: <>{renderStarRating(3)} 3 sao</>,
    },
    {
      id: 4,
      name: <>{renderStarRating(4)} 4 sao</>,
    },
    {
      id: 5,
      name: <>{renderStarRating(5)} 5 sao</>,
    },
  ];

  const sort = [
    {
      id: 1,
      name: "Mới nhất",
    },
    {
      id: 2,
      name: "Cũ nhất",
    },
    {
      id: 3,
      name: "Nội dung A - Z",
    },
    {
      id: 4,
      name: "Nội dung Z - A",
    },
  ];

  const rankTimer = [
    {
      id: "Mặc định",
      name: "Mặc định",
    },
    ...(form.getFieldsValue().star === "Tất cả"
      ? [
          {
            id: 1,
            name: "5 sao đến 1 sao",
          },
          {
            id: 2,
            name: "1 sao đến 5 sao",
          },
        ]
      : []),
  ];
  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        getCourseReviewsListAPI();
      }}
    >
      <Form
        form={form}
        name="review-list-course-modal"
        style={{ maxWidth: 600 }}
      >
        <Modal
          title={
            <>
              <div className="flex justify-between align-center">
                <div className="flex flex-col">
                  <div>
                    <div className="flex align-center">
                      <div className="text-title-18">Nhận xét về {title} •</div>
                      <span className="total-review">
                        {totalPage} bình luận
                      </span>
                    </div>
                  </div>
                  <p className="fs-14 text-desc">
                    Những đánh giá của học viên về {title}
                  </p>
                </div>
                <div className="export-review" onClick={handleExportReviewList}>
                  Xuất dữ liệu Excel
                </div>
              </div>
              <div className="w-full-100">
                <div className="hr-tag"></div>
                <Form.Item name="search">
                  <Input
                    className="search-review-input"
                    prefix={<SearchOutlined style={{ color: "#667085" }} />}
                    placeholder="Nhập từ khoá cần tìm"
                    allowClear={true}
                    onChange={handleChangeKeyword}
                  />
                </Form.Item>
                <Row className="mt-2" gutter={24}>
                  <Col
                    xl={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    xs={{ span: 24 }}
                  >
                    <div className="list-item--title">Xếp hạng</div>
                    <Form.Item name="star">
                      <FormItemDropdown
                        options={rankStar}
                        allowClear={false}
                        onChange={() => {
                          form.submit();
                          form.setFieldsValue({
                            display: "Mặc định",
                          });
                        }}
                        placeholder=""
                        className="search-review-input"
                        showSearch={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xl={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    xs={{ span: 24 }}
                  >
                    <div className="list-item--title">Sắp xếp</div>
                    <Form.Item name="position">
                      <FormItemDropdown
                        options={sort}
                        allowClear={false}
                        placeholder=""
                        className="search-review-input"
                        showSearch={false}
                        onChange={() => {
                          form.submit();
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xl={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    xs={{ span: 24 }}
                  >
                    <div className="list-item--title">Hiển thị</div>
                    <Form.Item name="display">
                      <FormItemDropdown
                        options={rankTimer}
                        allowClear={false}
                        placeholder=""
                        className="search-review-input"
                        showSearch={false}
                        onChange={() => form.submit()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </>
          }
          open={open}
          className="list-review-modal"
          maskClosable={false}
          onCancel={handleCancel}
          centered
          footer={() => (
            <>
              {commentList?.length > 0 && (
                <CustomPagination
                  totalPage={totalPage}
                  pageSize={pageSize}
                  pageNumber={pageNumber}
                  setPageSize={setPageSize}
                  setPageNumber={setPageNumber}
                />
              )}
            </>
          )}
        >
          {isLoading ? (
            <div className="empty-data">
              <Spin size="large" />
            </div>
          ) : (
            <div>
              {isSearch && (
                <div className="mt-1 mb-1">có {totalPage} kết quả hợp lệ</div>
              )}
              {commentList?.length > 0 ? (
                commentList?.map((it: any, index: number) => (
                  <ReviewCard key={index} itemData={it} />
                ))
              ) : (
                <div className="empty-data">
                  <Empty description="Không có kết quả" />
                </div>
              )}
            </div>
          )}
        </Modal>
      </Form>
    </Form.Provider>
  );
};

export default ReviewListModal;
