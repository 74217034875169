import "./Statistics.scss";

type Props = {};

const Statistics = (props: Props) => {
  const statisticsList = [
    { id: 1, total: "10k", description: "Khoá học trên hệ thống" },
    {
      id: 2,
      total: "200%",
      description: "Người dùng đăng ký mới sau 01 tháng ra mắt",
    },
    { id: 3, total: "100+", description: "Trường đại học liên kết" },
  ];

  return (
    <div className="statistics-home">
      <div className="statistics-home-title">
        <div className="text-title-36 text-center">
          Hệ thống học tập trực tuyến Mooc
        </div>
        <div className="text-center font-size-20 mt-3">
          Nền tảng trực tuyến mở cung cấp đa dạng khoá học được tin tưởng
        </div>
        <div className="statistics">
          {statisticsList?.map((it) => (
            <div key={it?.id} className="statistics-item">
              <div className="quantity">{it?.total}</div>
              <div className="description">{it?.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Statistics;
