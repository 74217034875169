export const getCurrentPageFromLink = (url: string) => {
  const pageNumber = url.split('page=')
  return pageNumber[1] ?? undefined
}

export const objectToQueryString = (obj: any) => {
  const parts = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value !== null && value !== undefined) {
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  }
  return parts.join("&");
};

export const renderErrorMessageOtp = (errorMessage: string) => {
  switch (errorMessage) {
    case "EXPIRED_OTP":
      return "Mã bảo mật đã hết hạn. Vui lòng thao tác gửi lại mã bảo mật";
    case "INCORRECT_OTP":
      return "Mã bảo mật chưa chính xác. Vui lòng nhập lại";
    case "OTP_FAIL_5_TURNS":
      return "Bạn đã nhập sai mã bảo mật quá 5 lần!";
    default:
      return errorMessage
  }
};

