import { Empty, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import "./HistoryUpdateModal.scss";
import { getDocumentHistory } from "../../../../../service/learning-course";
import { objectToQueryString } from "../../../../../utils/getPageFromLink";
import { ColumnsType } from "antd/es/table";
import moment from "moment";

const columns: ColumnsType<any> = [
  {
    title: "Thời gian cập nhật",
    dataIndex: "createdDate",
    key: "createdDate",
    render: (value, record, index) => (
      <div className="text-desc">{moment(value).format("DD/MM/YYYY")}</div>
    ),
  },
  {
    title: "Nội dung",
    dataIndex: "title",
    key: "title",
  },
];

type Props = {
  open: boolean;
  handleOk: () => void;
  handleCancel?: () => void;
  id: number;
};

const HistoryUpdateModal = (props: Props) => {
  const { open, handleOk, handleCancel, id } = props;
  const [documentHistory, setDocumentHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchDocumentHistory = async () => {
    setLoading(true);
    const params = {
      page: 1,
      size: 120,
      unitId: id,
    };

    try {
      const res = await getDocumentHistory(objectToQueryString(params));

      setDocumentHistory(res.data.data.content);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDocumentHistory();
    }
  }, [id]);

  return (
    <Modal
      open={open}
      footer={null}
      title={
        <div className="flex align-center gap-16">
          <div className="clock-fast-item">
            <img
              src="/assets/icons/clock-fast-forward.svg"
              alt="search"
              className="clock-fast-icon"
            />
          </div>
          <div>
            <div className="text-title-18">Lịch sử cập nhật</div>
            <div className="text-description-14">
              Thông tin chi tiết về lịch sử cập nhật của tài liệu
            </div>
          </div>
        </div>
      }
      className="history-update-document-modal"
      centered
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className="hr-tag"></div>
      <div className="table-history">
        <Table
          dataSource={documentHistory}
          columns={columns}
          pagination={false}
          scroll={{ y: 300 }}
          loading={loading}
          locale={{ emptyText: <Empty description="Không có kết quả"></Empty> }}
        />
      </div>
    </Modal>
  );
};

export default HistoryUpdateModal;
