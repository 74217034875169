import React, { ReactNode, useState } from "react";
import { Drawer, DrawerProps } from "antd";

interface CustomDrawProps extends DrawerProps {
  children?: ReactNode;
  content?: ReactNode;
}
function CustomDraw({
  children,
  content,
  placement,
  ...props
}: CustomDrawProps) {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div onClick={showDrawer}>{children}</div>

      <Drawer
        title="Basic Drawer"
        onClose={onClose}
        open={open}
        placement={placement}
        {...props}
      >
        {content}
      </Drawer>
    </>
  );
}

export default CustomDraw;
