"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CARD_VALIDATION_CLASSES = exports.FACE_LIVENESS_MODEL_CONFIG = void 0;
exports.FACE_LIVENESS_MODEL_CONFIG = {
    value: "face_liveness",
    label: "FL",
    height: 112,
    width: 112,
    channel: 3,
    metadata_path: "",
    path: "model_tfjs/faceliveness_mobilenetv2_025_mymodel_3_train_01042022_export_01042022/model.json",
};
exports.CARD_VALIDATION_CLASSES = [
    'invalid_background',
    'valid_front_CCCD',
    'valid_back_CCCD',
    'valid_front_CMND',
    'valid_back_CMND',
    'invalid_hand_cover_CCCD_front',
    'invalid_hand_cover_CCCD_back',
    'invalid_cut_edge_CCCD_front',
    'invalid_cut_edge_CCCD_back',
    'invalid_spotlight_CCCD_front',
    'invalid_spotlight_CCCD_back',
    'invalid_hand_cover_CMND_front',
    'invalid_hand_cover_CMND_back',
    'invalid_cut_edge_CMND_front',
    'invalid_cut_edge_CMND_back',
    'invalid_spotlight_CMND_front',
    'invalid_spotlight_CMND_back',
    'valid_front_chip',
    'valid_back_chip',
    'invalid_spotlight_CHIP_front',
    'invalid_spotlight_CHIP_back',
    'invalid_hand_cover_CHIP_front',
    'invalid_hand_cover_CHIP_back',
    'invalid_cut_edge_CHIP_front',
    'invalid_cut_edge_CHIP_back',
    'valid_PASSPORT'
];
