import React from "react";
import "./style.scss";
import { KeyValue } from "../../types/app";

interface TabProps {
  tabs: KeyValue[];
  className?: string;
  show?: number | "full";
  active?: number | string;
  onClick?: (value: any) => void;
  onClickMore?: (val: any) => void;
}

function SimpleTabs({
  tabs = [],
  className = "",
  show = "full",
  active = 0,
  onClick = () => {},
  onClickMore = () => {},
}: TabProps) {
  const renderContent = () => {
    if (show === "full" || show === tabs.length)
      return tabs.map((item: KeyValue, index: number) => (
        <div
          className={`item ${active === item.value ? "active-item" : ""}`}
          key={index}
          onClick={() => onClick(item)}
        >
          {item.label}
        </div>
      ));
    else
      return (
        <>
          {tabs.map((item: KeyValue, index: number) =>
            index + 1 < show ? (
              <div
                className={`item ${active === item.value ? "active-item" : ""}`}
                key={index}
                onClick={() => onClick(item)}
              >
                {item.label}
              </div>
            ) : (
              ""
            )
          )}{" "}
          {tabs.length > 0 && (
            <div className="flex">
              <div className="other">
                Khác <span className="total">{tabs.length - +show}</span>
              </div>
              <div className="item" onClick={onClickMore}>
                Xem thêm
              </div>
            </div>
          )}
        </>
      );
  };
  return <div className={`simple-tabs ${className}`}>{renderContent()}</div>;
}

export default SimpleTabs;
