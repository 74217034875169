import { Form, Slider, SliderSingleProps } from "antd";
import React from "react";
import { formatCurrency } from "../../utils/format";

interface initialValue {
  marks?: SliderSingleProps["marks"];
  initialValue?: number;
  name?: string;
  max?: number;
  min?: number;
}
function FilterPrice({
  initialValue,
  name = "cost",
  marks,
  max = 10000000,
  min = 0,
}: initialValue) {
  const defaultMarks: SliderSingleProps["marks"] = marks || {
    0: "0",
  };
  return (
    <Form.Item name={name} initialValue={initialValue}>
      <Slider
        rootClassName="slider-price"
        marks={defaultMarks}
        max={max}
        min={min}
        tooltip={{
          open: true,
          placement: "bottom",
          rootClassName: "price-tooltip",
          forceRender: true,
          formatter: (value?: number) => formatCurrency(value),
        }}
      />
    </Form.Item>
  );
}

export default FilterPrice;
