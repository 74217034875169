// Modal.tsx
import { CloseOutlined } from "@ant-design/icons";
import { Modal as AntModal, Button, Checkbox } from "antd";
import { CheckboxProps } from "antd/lib";
import React, { useState } from "react";
import { CustomCaptchaElement } from "../captcha/CustomCaptcha";
import "../register-modal/register-modal.scss";

interface ModalProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const RegisterModal: React.FC<ModalProps> = ({
  visible,
  onClose,
  onConfirm,
}) => {
  const handlePrivacy = () => {
    // navigate here
  };
  const [confirmRegister, setConfirmRegister] = useState<boolean>(false);

  const onChange: CheckboxProps["onChange"] = (e) => {
    setConfirmRegister(e.target.checked);
  };
  return (
    <AntModal
      centered={true}
      className="modal-container"
      open={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      closable={false}
    >
      <div className="ant-modal-header">
        <img
          className="icon-container"
          loading="eager"
          alt="img"
          src={"../../assets/img/app-logo.png"}
        />
        <h2 className="ant-modal-title">{"Xác nhận đăng kí tài khoản"}</h2>
        <CloseOutlined className="close-icon" onClick={onClose} />
      </div>
      <div className="ant-modal-body">
        Bằng việc tạo tài khoản, bạn đã đồng ý với các{" "}
        <span onClick={handlePrivacy} className="privacy">
          Chính sách
        </span>{" "}
        của Nền tảng MOOC và bạn đã chấp thuận rằng Nền tảng MOOC được xử lý
        thông tin cá nhân của bạn theo{" "}
        <span onClick={handlePrivacy} className="privacy">
          Chính sách bảo mật
        </span>
      </div>
      <div className="ant-modal-footer">
        <div className="confirm-register">
          <Checkbox onChange={onChange}>Tôi đã đọc và đồng ý</Checkbox>
        </div>
        <Button type="primary" onClick={onConfirm} disabled={!confirmRegister}>
          Xác nhận
        </Button>
      </div>
    </AntModal>
  );
};

export default RegisterModal;
