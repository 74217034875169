import React from "react";
import "./style.scss";
import { CloseOrangeIcon } from "../icons";

interface AlertProps {
  message?: string;
  onClose?: () => void;
}
function LoginAlert({ message = "", onClose = () => {} }: AlertProps) {
  return (
    <div className="default-alert">
      <div className="war-not font-size-14 line-height-20 font-weight-5">
        Cảnh báo
      </div>
      <span className="font-size-14 line-height-20 font-weight-5 flex-1 text-left">
        {message || "Có lỗi xảy ra"}
      </span>
      <div className="close-btn pointer mr-1" onClick={onClose}>
        <CloseOrangeIcon />
      </div>
    </div>
  );
}

export default LoginAlert;
