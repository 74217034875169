import { memo, useEffect, useRef, useState } from "react";
import { HelpService } from "../../../../service/helper.service";
import { examSupervisorStore } from "../../../../stores/stores";
import { submitAttendanceReport } from "../../../../service/attendance";
import { toNumber } from "lodash";
import useKeyword from "../../../../hooks/useKeyword";

interface IpCheckingAttendantProps {}

const IpCheckingAttedant = ({}: IpCheckingAttendantProps) => {
  const recordTimeBySeconds = useRef(0);
  const blockId = toNumber(useKeyword("blockId"));
  const intervalId = useRef<NodeJS.Timeout>();
  const helpService = useRef(new HelpService());
  const listAttendanceData = useRef<
    { is_attendance: boolean; times: number }[]
  >([]);

  const { resetSupervisorData, examSupervisorSetting, examSupervisorData } =
    examSupervisorStore();
  const [{ attendantListTime, attendanceRequired, attendanceTotal }] = useState(
    () => {
      const attendantListTime = examSupervisorSetting?.mooc_setting_times || [];
      const attendanceRequired = examSupervisorSetting?.min_times || 1;
      const isFaceAttendance = examSupervisorSetting?.face_attendance;
      const isIpAttendance = examSupervisorSetting?.ip_attendance;
      const attendanceTotal = Math.max(
        examSupervisorSetting?.times || 1,
        attendanceRequired
      );

      const showingError = examSupervisorData?.is_notification || false;
      return {
        attendantListTime,
        attendanceRequired,
        attendanceTotal,
        showingError,
        isFaceAttendance,
        isIpAttendance,
      };
    }
  );
  const countAttendance = useRef(0);

  const handleCheckingAttentdantByIp = async () => {
    if (attendantListTime.length > 0) {
      const current = attendantListTime[countAttendance.current];
      if (!current) return;
      const { minutes } = current;
      const minutesToSeconds = minutes * 60;
      recordTimeBySeconds.current += 1;
      if (minutesToSeconds === recordTimeBySeconds.current - 1) {
        countAttendance.current += 1;
        listAttendanceData.current.push({
          is_attendance: true,
          times: countAttendance.current,
        });
        // if (unitType !== "video") {
        //   submitAttendanceReport({
        //     course_block_id: `${examSupervisorData?.mooc_course_block.id}`,
        //     data: {
        //       result: true,
        //       data: listAttendanceData.current,
        //     },
        //   });
        //   clearInterval(intervalId.current);
        //   return;
        // }
        if (!blockId) {
          helpService.current.errorMessage(
            "Điểm danh thất bại do mã khoá học không tồn tại"
          );
          return;
        }
        if (
          countAttendance.current === attendanceTotal ||
          countAttendance.current === attendanceRequired
        ) {
          const isAttendanceEnough =
            listAttendanceData.current.reduce((total, item) => {
              if (item.is_attendance) {
                return total + 1;
              }
              return total;
            }, 0) === attendanceRequired;
          if (
            isAttendanceEnough ||
            countAttendance.current === attendanceTotal
          ) {
            submitAttendanceReport({
              course_block_id: `${blockId}`,
              data: {
                result: isAttendanceEnough,
                data: listAttendanceData.current,
              },
            });
            clearInterval(intervalId.current);
          }
        }
      }
    } else {
      clearInterval(intervalId.current);
    }
  };

  useEffect(() => {
    intervalId.current = setInterval(() => {
      handleCheckingAttentdantByIp();
    }, 1000);
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);
  return null;
};

export default memo(IpCheckingAttedant);
