// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-select,
.form-select--multiple {
  height: 44px;
}

.form-select {
  display: flex;
  align-items: flex-start;
  flex: 1 0;
  width: 100%;
}
.form-select span {
  font-size: 16px;
}

.form-select--multiple {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/form-dropdown/form-item-dropdown.scss"],"names":[],"mappings":"AAAA;;EAEE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,SAAA;EACA,WAAA;AACF;AACE;EACE,eAAA;AACJ;;AAGA;EACE,WAAA;AAAF","sourcesContent":[".form-select,\n.form-select--multiple {\n  height: 44px;\n}\n\n.form-select {\n  display: flex;\n  align-items: flex-start;\n  flex: 1 0 0;\n  width: 100%;\n\n  span {\n    font-size: 16px;\n  }\n}\n\n.form-select--multiple {\n  width: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
