// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-content .search {
  gap: 18px;
}
.banner-content .banner-title {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 125% */
  letter-spacing: -0.96px;
}
.banner-content .search-input {
  height: 48px;
}
.banner-content .mooc-btn-violet {
  height: 48px;
  padding: 12px 18px;
}

.filter-group {
  padding: 0 10px;
}

@media screen and (max-width: 620px) {
  h3 {
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/school/style.scss"],"names":[],"mappings":"AACE;EACE,SAAA;AAAJ;AAGE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,uBAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,YAAA;EACA,kBAAA;AAHJ;;AAMA;EACE,eAAA;AAHF;;AAMA;EACE;IACE,kBAAA;EAHF;AACF","sourcesContent":[".banner-content {\n  .search {\n    gap: 18px;\n  }\n\n  .banner-title {\n    font-size: 48px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 60px; /* 125% */\n    letter-spacing: -0.96px;\n  }\n\n  .search-input {\n    height: 48px;\n  }\n\n  .mooc-btn-violet {\n    height: 48px;\n    padding: 12px 18px;\n  }\n}\n.filter-group {\n  padding: 0 10px;\n}\n\n@media screen and (max-width: 620px) {\n  h3{\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
