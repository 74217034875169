import React from 'react';

interface XAPIViewerProps {
	url: string;
}

const XAPIViewer: React.FC<XAPIViewerProps> = ({ url }) => {
	return <iframe src={url} title="xAPI Activity" width="100%" height="500px"></iframe>;
};

export default XAPIViewer;
