import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import debounce from "lodash/debounce";

const { Option } = Select;

interface OptionType {
  value: string;
  label: string;
}

interface PaginatedSelectProps {
  options: OptionType[];
  pageSize?: number;
  debounceTimeout?: number;
  placeholder?: string;
  style?: React.CSSProperties;
  mode?: any;
  allowClear?: boolean;
  disabled?: boolean;
  defaultValue?: any;
  value?: any;
  onChange?: (value: any) => void;
}

const PaginatedSelect: React.FC<PaginatedSelectProps> = ({
  options,
  pageSize = 100,
  debounceTimeout = 800,
  placeholder = "Select an option",
  style,
  mode = "default",
  allowClear,
  disabled,
  defaultValue,
  value,
  onChange,
}) => {
  const [displayedOptions, setDisplayedOptions] = useState<OptionType[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>("");

  const loadOptions = debounce((search: string, page: number) => {
    setFetching(true);
    const filteredOptions = options.filter((option) =>
      removeDiacritics(option.label.toLowerCase()).includes(
        removeDiacritics(search.toLowerCase()).trim()
      )
    );
    const newOptions = filteredOptions.slice(
      page * pageSize,
      (page + 1) * pageSize
    );
    setDisplayedOptions(
      page === 0 ? newOptions : [...displayedOptions, ...newOptions]
    );
    setFetching(false);
  }, debounceTimeout);

  useEffect(() => {
    loadOptions("", 0);
  }, [options]);

  const handleSearch = (value: string) => {
    setPage(0);
    setSearch(value);
    loadOptions(value, 0);
  };

  const handlePopupScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      !fetching
    ) {
      setPage((prevPage) => {
        const newPage = prevPage + 1;
        loadOptions(search, newPage);
        return newPage;
      });
    }
  };

  const handleClear = () => {
    setSearch("");
    setPage(0);
    loadOptions("", 0);
  };

  return (
    <Select
      showSearch
      placeholder={placeholder}
      notFoundContent={
        fetching ? <Spin size="small" /> : <span>Không có kết quả</span>
      }
      filterOption={false}
      onSearch={handleSearch}
      onPopupScroll={handlePopupScroll}
      mode={mode}
      style={style}
      dropdownRender={(menu) => <>{menu}</>}
      allowClear={allowClear}
      onClear={handleClear}
      disabled={disabled}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
    >
      {displayedOptions.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default PaginatedSelect;

// Hàm loại bỏ dấu
function removeDiacritics(str: string): string {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
}
