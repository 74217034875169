import React, { useState } from "react";
import { Breadcrumb, Button, Col, Flex, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";
import { DatabaseOutlined } from "@ant-design/icons";
import FilterSearch, { FilterSearchValue } from "../components/FilterSearch";
import { routesConfig } from "../../../../config/routes";
import { unitOpt } from "../../../../constants/statistics";
import UnitByCourse from "./components/UnitByCourse";
import UnitBySequence from "./components/UnitBySequence";
import UnitByModule from "./components/UnitByModule";
import UnitReviewAndAccess from "./components/UnitReviewAndAccess";
import ClassAction from "./components/ClassAction";
import UnitSequenceAction from "./components/UnitSequenceAction";
import History from "./components/History";
import KeywordCount from "./components/KeywordCount";
import RatingExam from "./components/RatingExam";
import Discuss from "./components/Discuss";
import CountDownload from "./components/CountDownload";
import CountShare from "./components/CountShare";
import CountView from "./components/CountView";
import { getDateFromNow } from "../components/utils";

function StatisticsReports() {
  const navigate = useNavigate();

  const [filterValues, setFilterValues] = useState<
    FilterSearchValue | undefined
  >({
    date: [new Date().setDate(new Date().getDate() - 7), new Date()],
    unit: unitOpt[0].value,
  });

  const handleChangeValues = (values: FilterSearchValue | undefined) => {
    if (!values) {
      setFilterValues({
        date: [new Date().setDate(new Date().getDate() - 7), new Date()],
        unit: unitOpt[0].value,
      });
      return
    }

    if (values?.periodOfTime !== -1) {
      setFilterValues({
        ...values,
        date: [getDateFromNow(values?.periodOfTime as number), new Date()],
      });
    } else {
      setFilterValues(values);
    }
  };

  return (
    <div className="statics-report-page">
      <Breadcrumb
        items={[
          {
            href: "",
            title: (
              <img
                key="home-icon"
                src={`${process.env.PUBLIC_URL}/assets/icons/home-line.svg`}
              />
            ),
          },
          {
            title: <Link to={routesConfig.myCourses}>Khoá học của tôi</Link>,
          },
          { title: "Khoá học của tôi" },
        ]}
      />
      <Flex className="report-page-title" justify="space-between">
        <div>
          <h3 className="font-size-30 line-height-38">Thống kê báo cáo</h3>
          <p className="font-size-16 line-height-24 text_secondary">
            Tổng hợp các hoạt động của bạn trên hệ thống
          </p>
        </div>

        <Flex gap={12} className="mt-1">
          <Button
            icon={<DatabaseOutlined style={{ fontSize: 18 }} />}
            className="btn-primary h-40"
            onClick={() => navigate(routesConfig.statisticsReportDetail)}
          >
            <span className="font-weight-6">Chi tiết thống kê</span>
          </Button>
        </Flex>
      </Flex>

      <div className="mt-3">
        <FilterSearch onChange={handleChangeValues} />
      </div>

      <div className="mt-2 mb-1">
        <div className="session-title font-size-18 font-weight-6 line-height-28">
          Thống kê số lượng phản hồi
        </div>
        <p className="text-secondary font-size-14 mb-2">
          Biểu đồ thống kê chi tiết hoạt động phản hồi
        </p>
        <Discuss filterValues={filterValues} />
      </div>
      <div className="mt-3 mb-1">
        <Row gutter={[32, 32]}>
          <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
            <UnitSequenceAction filterValues={filterValues} />
          </Col>
          <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
            <ClassAction filterValues={filterValues} />
          </Col>
        </Row>
        <div className="mt-2">
          <div className="session-title font-size-18 font-weight-6 line-height-28">
            Thống kê số lượng đánh giá
          </div>
          <p className="text-secondary font-size-14 mb-2">
            Biểu đồ thống kê chi tiết hoạt động đánh giá của bạn
          </p>
          <UnitReviewAndAccess filterValues={filterValues} />
        </div>
        <div className="mt-2">
          <Row gutter={[32, 32]}>
            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
              <UnitByCourse filterValues={filterValues} />
            </Col>
            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
              <UnitByModule filterValues={filterValues} />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <Row gutter={[32, 32]}>
            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
              <UnitBySequence filterValues={filterValues} />
            </Col>
            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
              <RatingExam filterValues={filterValues} />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <div className="session-title font-size-18 font-weight-6 line-height-28">
            Thống kê số lượng tìm kiếm
          </div>
          <p className="text-secondary font-size-14 mb-2">
            Biểu đồ thống kê chi tiết hoạt động tìm kiếm của bạn
          </p>
          <Row gutter={[32, 32]}>
            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
              <KeywordCount filterValues={filterValues} />
            </Col>
            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
              <History filterValues={filterValues} />
            </Col>
          </Row>
        </div>
        <div className="mt-2">
          <div className="session-title font-size-18 font-weight-6 line-height-28">
            Thống kê số lượng tải về/chia sẻ/xem
          </div>
          <p className="text-secondary font-size-14 mb-2">
            Biểu đồ thống kê chi tiết hoạt động của bạn
          </p>
          <Row gutter={[32, 32]}>
            <Col xs={24} md={24} lg={24} xl={8} xxl={8}>
              <CountDownload filterValues={filterValues} />
            </Col>
            <Col xs={24} md={24} lg={24} xl={8} xxl={8}>
              <CountShare filterValues={filterValues} />
            </Col>
            <Col xs={24} md={24} lg={24} xl={8} xxl={8}>
              <CountView filterValues={filterValues} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default StatisticsReports;
