import React from "react";
import { Button, Spin } from "antd";
import { convertMinutesToTimeString } from "../../../../../../utils/format";
import { toNumber } from "lodash";
import { ExamConfigs, UnitInfoProps } from "../../../../../../types/course";
import moment from "moment";
import FinishScreen from "./FinishScreen";

interface StartScreenProps {
  loading?: boolean;
  blockInfo?: UnitInfoProps;
  configs?: ExamConfigs;
  onStart?: (v?: any) => void;
  onRetrain?: (v?: any) => void;
  onViewResult?: (v?: any) => void;
  onContinue?: (v?: any) => void;
}

function StartScreen({
  loading,
  blockInfo,
  configs,
  onStart = () => {},
  onRetrain = () => {},
  onViewResult = () => {},
  onContinue = () => {},
}: StartScreenProps) {
  const handleStart = () => {
    if (blockInfo?.id) {
      onStart();
    }
  };

  return blockInfo?.complete ? (
    <FinishScreen
      isFinalFinish={true}
      loading={loading}
      config={configs}
      isAutoSubmittedPreSession={blockInfo?.isAutoSubmittedPreSession}
      remainingRetryNumber={blockInfo?.remainingRetryNumber}
      waitingTimeForNextExecution={blockInfo?.waitingTimeForNextExecution}
      timeTraining={blockInfo?.totalTimeTrain}
      isAbleToRetry={blockInfo?.isAbleToRetry}
      isShowResult={blockInfo?.isShowResult}
      answerSendTime={blockInfo?.answerSendTime}
      onRetrain={onRetrain}
      onViewResult={onViewResult}
      onContinue={onContinue}
    />
  ) : (
    <div className="start-screen">
      <Spin spinning={loading}>
        <div className="center flex-column content-center">
          <p className="font-size-16 font-weight-5">
            Thời gian làm bài cho bài tập này:{" "}
            <span className="text-primary font-weight-6">
              {convertMinutesToTimeString(
                Math.floor(toNumber(blockInfo?.timeCompletedCondition) / 60)
              )}
            </span>
          </p>
          <p className="font-size-16 font-weight-5 mt-1">
            Bạn cần hoàn thành bài tập trước:{" "}
            <span className="text-primary font-weight-6">
              {blockInfo?.maxTimeCompleted
                ? moment(blockInfo?.maxTimeCompleted).format(
                    "HH:mm:ss - DD/MM/YYYY"
                  )
                : "N/A"}
            </span>
          </p>
        </div>
        <Button
          className="btn-primary h-40 mt-2 btn-start"
          onClick={handleStart}
        >
          Bắt đầu làm bài
        </Button>
      </Spin>
    </div>
  );
}

export default StartScreen;
