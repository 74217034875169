import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCourseDetail, postCourseEnrollment } from "../../service/courses";
import { CourseDetail } from "../../types/course";
import "./style.scss";
import { Button, Col, Spin } from "antd";
import { formatCurrencyToVND } from "../../utils/format";
import { BUY_COURSE, PRE_LEARNING, TRIAL_LEARNING } from "../../constants";
import { globalStore } from "../../stores/stores";
import ConfirmModal from "../../components/modal/confirm-modal/ConfirmModal";
import { routesConfig } from "../../config/routes";
import { notifyError, notifyWarning } from "../../utils/notification";
import PaymentFaceModal from "../login-page/components/login-page/PaymentFaceModal";
import { getPaymentSetting } from "../../service/payment";
import { PaymentSetting, PaymentType } from "../../types/paymentSetting";
import { getStartLearningCourse } from "../../service/my-course/my-course-service";

type Props = {};

const CourseEnrollment: React.FC<Props> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [courseDetail, setCourseDetail] = useState<CourseDetail>();
  const { reload, setReload } = globalStore();
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [enrollType, setEnrollType] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenFaceVerifyModal, setIsOpenFaceVerifyModal] = useState(false);
  const [isHasFaceChecking, setIsHasFaceChecking] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentType>();

  useEffect(() => {
    const getDataFromApi = async () => {
      setIsLoading(true);
      try {
        const detail = await getCourseDetail(Number(id));
        setCourseDetail(detail.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    getDataFromApi();
  }, [id]);
  const CourseEnrollmentItem = [
    {
      id: 0,
      title: "Học trước",
      cost: 0,
      payType: "Thanh toán 01 lần",
      description: [
        {
          id: 1,
          content: "Xem các nội dung học liệu miễn phí",
          isCanView: true,
        },
        { id: 2, content: "Thời gian mở theo khoá học", isCanView: true },
        { id: 3, content: "Hạn chế các nội dung mất phí", isCanView: false },
        {
          id: 4,
          content: "Hạn chế các bài tập/bài thi/kiểm tra ",
          isCanView: false,
        },
        { id: 5, content: "Hạn chế tài liệu tải về mất phí", isCanView: false },
      ],
    },
    {
      id: 1,
      title: "Mua khoá học",
      cost: courseDetail?.cost,
      payType: "Thanh toán 01 lần",
      description: [
        { id: 1, content: "Mở khoá toàn bộ nội dung học tập", isCanView: true },
        { id: 2, content: "Thời gian mở theo khoá học", isCanView: true },
        {
          id: 3,
          content: "Mở khoá bài tập/bài thi/bài kiểm tra",
          isCanView: true,
        },
        { id: 4, content: "Mở khoá toàn bộ tài liệu tải về", isCanView: true },
        { id: 5, content: "Miễn phí cập nhật (nếu có)", isCanView: true },
      ],
    },
    {
      id: 2,
      title: "Học thử",
      cost: 0,
      payType: "Thanh toán 01 lần",
      description: [
        { id: 1, content: "Mở khoá toàn bộ nội dung học tập", isCanView: true },
        { id: 4, content: "Mở khoá toàn bộ tài liệu", isCanView: true },
        { id: 2, content: "Thời gian mở trong 07 ngày", isCanView: false },
        {
          id: 3,
          content: "Hạn chế bài tập/bài thi/bài kiểm tra",
          isCanView: false,
        },
        { id: 5, content: "" },
      ],
    },
  ];

  const handleConfirmEnroll = async () => {
    const payload = {
      courseId: id,
      registrationForm:
        enrollType === 0
          ? PRE_LEARNING
          : enrollType === 1
            ? BUY_COURSE
            : enrollType === 2
              ? BUY_COURSE
              : enrollType === 3
                ? TRIAL_LEARNING
                : undefined,
    };
    if (!payload.registrationForm) return;

    try {
      const res = await postCourseEnrollment(payload);
      if (res.status === 200) {
        setReload(!reload);
        setIsConfirm(false);
        setIsSuccess(true);
      } else {
        console.log(`Error: Received status code ${res.status}`);
      }
    } catch (error) {
      notifyError((error as any)?.response?.data?.message);
    }
  };

  const moveToCourse = async () => {
    setIsConfirm(false);
    navigate(`${routesConfig.learningCourseStructure}/${id}`);
    try {
      getStartLearningCourse(Number(id));
      navigate(`${routesConfig.learningCourseStructure}/${id}`);
    } catch (error) {}
  };
  useEffect(() => {
    const fetchPaymentSetting = async () => {
      const response = await getPaymentSetting();
      if (response.data?.data) {
        setPaymentMethod(response.data.data.payment);
      }
    };
    fetchPaymentSetting();
  }, []);
  return (
    <div className="course-enrollment">
      {isLoading ? (
        <div className="course-enrollment-loading">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="course-enrollment-title">Ghi danh khoá học</div>
          <div className="course-enrollment-name">{courseDetail?.name}</div>
          <div className="course-enrollment-description">
            Lựa chọn phương án phù hợp với bạn cho khoá học bạn thích
          </div>
          <div className="course-enrollment-content">
            {CourseEnrollmentItem.map((it, index) => (
              <Col key={it.id} span={7} className="item">
                {index === 1 ? (
                  <div className="item-header"></div>
                ) : (
                  <div className="item-header-hide"></div>
                )}
                <div className="course-enrollment-content-item">
                  <div className="course-enrollment-name text-center">
                    {it.title}
                  </div>
                  <div className="item-cost">
                    Chi phí:{" "}
                    {it.cost && it.cost !== 0
                      ? formatCurrencyToVND(it?.cost) + "VND"
                      : "Miễn phí"}
                  </div>
                  <div className="item-pay">{it.payType}</div>
                  <div className="item-content">
                    {it.description.map((des) => (
                      <div key={des.id} className="flex align-center">
                        {des.isCanView && des.content ? (
                          <img
                            src="/assets/icons/check-circle.svg"
                            alt="a"
                            className="mr-2"
                          />
                        ) : !des.isCanView && des.content ? (
                          <img
                            src="/assets/icons/x-circle.svg"
                            alt="a"
                            className="mr-2"
                          />
                        ) : null}
                        {des.content}
                      </div>
                    ))}
                  </div>
                  <Button
                    className="enrollment-btn"
                    onClick={() => {
                      if (!courseDetail?.isRegistered) {
                        setIsConfirm(true);
                        setEnrollType(++index);
                        if (it?.cost && paymentMethod === PaymentType.FACE) {
                          setIsHasFaceChecking(true);
                        } else {
                          setIsHasFaceChecking(false);
                        }
                      } else notifyWarning("Bạn đã ghi danh khóa học này rồi!");
                    }}
                  >
                    Ghi danh
                  </Button>
                </div>
              </Col>
            ))}
          </div>
          <ConfirmModal
            open={isConfirm}
            handleOk={async () => {
              if (isHasFaceChecking) {
                setIsOpenFaceVerifyModal(true);
              } else {
                await handleConfirmEnroll();
              }
            }}
            handleCancel={() => {
              setIsConfirm(false);
            }}
            title="Xác nhận ghi danh khoá học"
            content={`Bạn xác nhận muốn ghi danh khoá học ${courseDetail?.name}`}
          />
          <ConfirmModal
            open={isSuccess}
            handleOk={moveToCourse}
            btnCancel={false}
            handleCancel={() => {
              setIsConfirm(false);
              setIsSuccess(false);
            }}
            title="Ghi danh thành công!"
            confirmIcon={`${process.env.PUBLIC_URL}/assets/icons/check-circle-border.svg`}
            content={`Chúc mừng bạn đã ghi danh thành công khoá học`}
            confirmBtntitle="Đi đến khóa học"
          />

          {isOpenFaceVerifyModal && (
            <PaymentFaceModal
              isOpen={isOpenFaceVerifyModal}
              handleClose={() => {
                setIsOpenFaceVerifyModal(false);
              }}
              handleNextStep={async () => {
                await handleConfirmEnroll();
                setIsOpenFaceVerifyModal(false);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CourseEnrollment;
