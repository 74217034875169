// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf-viewer .viewer-container {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.pdf-viewer .viewer-wrapper {
  display: flex;
  flex-grow: 1;
}
.pdf-viewer .sidebar {
  width: 20%;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}
.pdf-viewer .main-viewer {
  width: 80%;
  position: relative;
  overflow-y: auto;
}
.pdf-viewer .marker {
  position: absolute;
  font-size: 24px;
  color: red;
  top: 0;
  left: 0;
  transform: translate(-100%, 0);
  cursor: pointer;
}
.pdf-viewer .rpv-core__page-layer {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/pages/learning-course-structure/learning-course/components/PDF-viewer/PDFViewer.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;AAAJ;AAGE;EACE,aAAA;EACA,YAAA;AADJ;AAIE;EACE,UAAA;EACA,4BAAA;EACA,gBAAA;AAFJ;AAKE;EACE,UAAA;EACA,kBAAA;EACA,gBAAA;AAHJ;AAME;EACE,kBAAA;EACA,eAAA;EACA,UAAA;EACA,MAAA;EACA,OAAA;EACA,8BAAA;EACA,eAAA;AAJJ;AAOE;EACE,kBAAA;AALJ","sourcesContent":[".pdf-viewer {\n  .viewer-container {\n    position: relative;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n  }\n\n  .viewer-wrapper {\n    display: flex;\n    flex-grow: 1;\n  }\n\n  .sidebar {\n    width: 20%;\n    border-right: 1px solid #ccc;\n    overflow-y: auto;\n  }\n\n  .main-viewer {\n    width: 80%;\n    position: relative;\n    overflow-y: auto;\n  }\n\n  .marker {\n    position: absolute;\n    font-size: 24px;\n    color: red;\n    top: 0;\n    left: 0;\n    transform: translate(-100%, 0);\n    cursor: pointer;\n  }\n\n  .rpv-core__page-layer {\n    position: relative;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
