import React, { ReactNode } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Menu, MenuProps } from "antd";

interface SingleMenuProps {
  item?: any;
  className?: string;
  titleClassName?: string;
  contentClassName?: string;
  content?: ReactNode;
  title?: ReactNode;
  expendIcon?: ReactNode;
}
type MenuItem = Required<MenuProps>["items"][number];

function SingleMenu({
  className = "",
  content = <div>content</div>,
  title = <div>title</div>,
  titleClassName = "",
  contentClassName = "",
  expendIcon,
}: SingleMenuProps) {
  const items: MenuItem[] = [
    {
      key: "title",
      label: title,
      className: titleClassName,
      children: [
        { key: "content", label: content, className: contentClassName },
      ],
    },
  ];

  return (
    <div className={`single-menu ${className}`}>
      <Menu
        mode="inline"
        items={items}
        expandIcon={({ isOpen }) =>
          isOpen ? (
            <div className="expand-icon open">
              {expendIcon || <DownOutlined />}
            </div>
          ) : (
            <div className="expand-icon close">
              {expendIcon || <DownOutlined />}
            </div>
          )
        }
      />
    </div>
  );
}

export default SingleMenu;
