// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-tabs {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  flex-wrap: wrap;
  gap: 10px;
}
.simple-tabs .other {
  height: 44px;
  padding: 8px 12px;
  color: #667085;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.simple-tabs .total {
  padding: 3px 10px;
  border-radius: 20px;
  border: 1px solid #eaecf0;
  background: #f9fafb;
}
.simple-tabs .item {
  height: 44px;
  padding: 8px 12px;
  color: #667085;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  transition: all 333ms ease-in-out;
  cursor: pointer;
  border-radius: 10px;
  width: max-content;
}
.simple-tabs .item:hover,
.simple-tabs .item .active-item {
  color: white;
  background: #6941c6;
}
.simple-tabs .item .span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  -webkit-line-clamp: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/tabs/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,SAAA;AACF;AACE;EACE,YAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AAEE;EACE,iBAAA;EACA,mBAAA;EACA,yBAAA;EAEA,mBAAA;AADJ;AAIE;EACE,YAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,iCAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;AAFJ;AAII;;EAEE,YAAA;EACA,mBAAA;AAFN;AAKI;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,WAAA;EACA,qBAAA;AAHN","sourcesContent":[".simple-tabs {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  background-color: white;\n  flex-wrap: wrap;\n  gap: 10px;\n\n  .other {\n    height: 44px;\n    padding: 8px 12px;\n    color: #667085;\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 24px;\n  }\n\n  .total {\n    padding: 3px 10px;\n    border-radius: 20px;\n    border: 1px solid #eaecf0;\n\n    background: #f9fafb;\n  }\n\n  .item {\n    height: 44px;\n    padding: 8px 12px;\n    color: #667085;\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 24px;\n    transition: all 333ms ease-in-out;\n    cursor: pointer;\n    border-radius: 10px;\n    width: max-content;\n\n    &:hover,\n    .active-item {\n      color: white;\n      background: #6941c6;\n    }\n\n    .span {\n      white-space: nowrap;\n      overflow: hidden;\n      text-overflow: ellipsis;\n      width: 100%;\n      -webkit-line-clamp: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
