// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-alert {
  display: flex;
  align-items: center;
  padding: 4px;
  background: #fffaeb;
  border: 1px solid #fedf89;
  color: #b54708;
  border-radius: 9999px;
  gap: 8px;
}
.default-alert .war-not {
  border-radius: 9999px;
  border: 1px solid #fedf89;
  background: #fff;
  padding: 0 10px;
}
.default-alert .text-left {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/alert/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,cAAA;EACA,qBAAA;EACA,QAAA;AACF;AAAE;EACE,qBAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ","sourcesContent":[".default-alert {\n  display: flex;\n  align-items: center;\n  padding: 4px;\n  background: #fffaeb;\n  border: 1px solid #fedf89;\n  color: #b54708;\n  border-radius: 9999px;\n  gap: 8px;\n  .war-not {\n    border-radius: 9999px;\n    border: 1px solid #fedf89;\n    background: #fff;\n    padding: 0 10px;\n  }\n  .text-left{\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
