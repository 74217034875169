import React, { useEffect, useState } from "react";
import TableReport from "../../components/TableReport";
import { Table, TableProps } from "antd";
import {
  BaseChartProps,
  StatisticChartPayload,
  StatisticLineChartResponseData,
} from "../../../../../types/statistic";
import { fetchRateUnitAction } from "../../../../../service/statistics";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { DEFAULT_MODULE_GROUP, moduleGroupOptions } from "../../../../../constants/statistics";

interface DataType {
  key: string;
  criteria: string;
  value: number;
}

function CountView({ filterValues }: BaseChartProps) {
  const [data, setData] = useState<StatisticLineChartResponseData[]>();
  const [moduleGroup, setModuleGroup] = useState<number>(DEFAULT_MODULE_GROUP);

  const moduleGroupPayload =
    moduleGroup === 0 || moduleGroup === -1 ? null : (moduleGroup as any);

  useEffect(() => {
    
    if (!isEmpty(filterValues)) {
      getData({
        timeUnit: filterValues?.unit || "day",
        from: filterValues?.date?.length
          ? dayjs(filterValues?.date[0]).toISOString()
          : undefined,
        to: filterValues?.date?.length
          ? dayjs(filterValues?.date[1]).toISOString()
          : undefined,
        moduleGroup: moduleGroupPayload,
        courseStructureType: moduleGroupPayload === null ? "sequence" : "unit",
        unitActionType: 1,
        courseIds: filterValues?.course,
        courseLevelIds: filterValues?.level,
        industryGroupIds: filterValues?.department,
      });
    }
  }, [filterValues, moduleGroup]);

  const getData = async (payload: StatisticChartPayload) => {
    const res = await fetchRateUnitAction(payload);
    setData(res.data?.data);
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Thời gian",
      dataIndex: "criteria",
      key: "criteria",
      render: (text) => <span className="font-weight-5">{text}</span>,
    },
    {
      title: "Số lượng",
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <TableReport
      data={data}
      columns={columns}
      title="Lượt xem"
      placeholder="Bài kiểm tra đánh giá"
      showFilter
      filterOptions={moduleGroupOptions.concat([
        {
          label: "Bài giảng",
          value: 0,
        },
        {
          label: "Khoá học",
          value: -1,
        },
      ])}
      onChangeFilter={setModuleGroup}
      summary={(pageData) => {
        let total = 0;

        pageData.forEach(({ value = 0 }) => {
          total += value;
        });

        return (
          <Table.Summary.Row className="text-primary font-weight-5 sticky-summary-row">
            <Table.Summary.Cell index={0}>Tổng</Table.Summary.Cell>
            <Table.Summary.Cell index={1}>{total}</Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
    />
  );
}

export default CountView;
