import { DownOutlined, UpOutlined } from "@ant-design/icons";
import React, { useState } from "react";

interface ExpandableTextProps {
  text: string | undefined;
  maxLines: number;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({ text, maxLines }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded(!expanded);

  return (
    <>
      <div>
        {expanded ? (
          <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>
        ) : (
          <div
            style={{
              display: "-webkit-box",
              WebkitLineClamp: maxLines,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              whiteSpace: "pre-wrap", // Thêm white-space ở đây
            }}
          >
            <div>{text}</div>
          </div>
        )}
      </div>
      {text && text.split("\n").length > maxLines && (
        <div
          onClick={toggleExpand}
          className="text-primary font-weight-6 flex gap-8 mt-1 pointer"
        >
          {expanded ? (
            <>
              Thu gọn
              <UpOutlined />
            </>
          ) : (
            <>
              Xem thêm
              <DownOutlined />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ExpandableText;
