import React from "react";
import { Button } from "antd";
import moment from "moment";

interface PauseScreenProps {
  dateTimePause: number;
  timeDeadline?: number | string;
  onContinue?: (v?: any) => void;
  onFinish?: (v?: any) => void;
}
function PauseScreen({
  dateTimePause = 0,
  timeDeadline = 0,
  onContinue = () => {},
  onFinish = () => {},
}: PauseScreenProps) {
  return (
    <div className="pause-screen">
      <div className="center flex-column content-center">
        <span className="font-weight-6 font-size-16 mb-1">
          Bạn đang tạm dừng làm bài
        </span>
        <p className="font-size-16 font-weight-5">
          Thời gian tạm dừng:{" "}
          <span className="text-primary">
            {moment(dateTimePause).format("HH:mm:ss - DD/MM/YYYY")}
          </span>
        </p>
        <p className="font-size-16 mt-1 font-weight-5">
          Hạn nộp bài:{" "}
          <span className="text-primary">
            {timeDeadline
              ? moment(timeDeadline).format("HH:mm:ss - DD/MM/YYYY")
              : "N/A"}
          </span>
        </p>
      </div>
      <div className="pause-screen-footer">
        <Button
          style={{ width: 154 }}
          className="h-40 btn-start font-weight-6"
          onClick={onContinue}
        >
          Tiếp tục
        </Button>
        <Button
          style={{ width: 154 }}
          className="btn-outline-primary h-40 btn-start font-weight-6"
          onClick={onFinish}
        >
          <span className="text-primary">Nộp bài</span>
        </Button>
      </div>
    </div>
  );
}

export default PauseScreen;
