import React, { useEffect, useRef, useState } from "react";
import { Flex, Form, FormInstance, Popover } from "antd";
import {
  QuestionBeProps,
  QuestionProps,
} from "../../../../../../../types/course";
import { SortableList } from "../../../../../../../components/sortable-list";
import { convertNumberingTypeToText } from "../../../../../../../utils/format";
import { isEmpty, toNumber } from "lodash";
import { QuestionMarkIcon } from "../../../../../../../components/icons";
import SingleMenu from "../../../../../../../components/menu/SingleMenu";

interface ConnectProps extends QuestionProps {
  form?: FormInstance<any>;
}
function Connect({ data, configs }: ConnectProps) {
  const isTrue = data?.result?.isTrue === true;
  const [answers, setAnswers] = useState<any>([]);

  const connectRef = useRef<HTMLDivElement | null>(null);
  const [itemHeight, setItemHeight] = useState<number | undefined>(0);

  const result = data?.result;

  useEffect(() => {
    setItemHeight(connectRef.current?.clientHeight);
  }, [connectRef.current, window.innerWidth]);

  useEffect(() => {
    if (data?.questions && data?.questions?.length > 0) {
      setAnswers(
        data?.questions?.map((ans: QuestionBeProps) => ({
          id: ans?.content?.right.key,
          value: ans?.content?.right.content,
        }))
      );
    }
  }, [data]);

  const handleDragChange = () => {};

  const isCorrect = (key: string) => {
    if (!result) return false;

    if (result?.results && result?.results[0]?.length > 0) {
      const results = result?.results[0];
      for (let i = 0; i < results?.length; i++) {
        if (results[i]?.content?.left?.key === key) return results[i]?.isTrue;
      }
    }
  };

  const renderFeedback = () => {
    if (isEmpty(data?.result) && !isTrue)
      return data?.settingResponse?.notYet || "Không có phản hồi";

    if (isTrue) return data?.settingResponse?.correct || "Không có phản hồi";

    return data?.settingResponse?.incorrect || "Không có phản hồi";
  };

  const renderConnect = (isTrue: boolean | null) => {
    if (isTrue === true) return "";
    if (isTrue === false) return "connect-wrong";

    return "not-connect";
  };

  return (
    <div>
      <div className="question-header mb-3">
        <div className="question-title">
          <Flex
            justify="space-between"
            className="font-size-16 line-height-24 font-weight-6"
          >
            Câu hỏi {toNumber(data?.index) + 1}:{" "}
            {configs?.generalConfig?.showQuizScore && (
              <span className="total-point font-size-14">
                {`(${data?.totalPoints || 0} điểm)`}
              </span>
            )}
          </Flex>
          <Flex align="flex-start" gap={8}>
            <div
              className="font-size-16 line-height-24 font-weight-6"
              dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
            ></div>
            {data?.settingHint?.isDisplayInstruction || data?.isShowHint ? (
              <Popover
                content={
                  <div style={{ padding: "5px 16px" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${data?.settingHint?.content || "Không có gợi ý"}`,
                      }}
                    />
                  </div>
                }
              >
                <span className="quest-icon text-secondary w-max-content">
                  <QuestionMarkIcon />
                </span>
              </Popover>
            ) : (
              ""
            )}
          </Flex>
          <div className="flex align-center gap-8 mt-1">
            {result ? (
              <span
                className={`font-size-14 line-height-20 font-weight-6 ${data?.result?.isTrue ? "score-correct-wrapper text-correct" : "score-incorrect-wrapper text-danger"}`}
              >
                Điểm: {data?.result?.points || 0}/
                {data?.result?.totalPoints || 0}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="connect-quest w-50 mr-3">
          {data?.questions &&
            data?.questions?.length > 0 &&
            data?.questions?.map((quiz: QuestionBeProps, index) => (
              <div
                ref={connectRef}
                className={`connect-quest-item-connecting ${renderConnect(isCorrect(quiz?.content?.left?.key))}`}
                key={`${quiz.uuid}-${index}-left`}
              >
                <span
                  className={`font-size-14 line-height-24 ${quiz?.isTrue && "text-result"}`}
                >
                  {convertNumberingTypeToText(
                    index + 1,
                    data?.settingGeneral?.numberingType
                  )}
                  . {quiz?.content?.left?.content}
                </span>
              </div>
            ))}
        </div>
        <Form.Item className="w-50 ml-3">
          <SortableList
            items={answers}
            disabled
            className="gap-16"
            onChange={handleDragChange}
            renderItem={(record: any) => (
              <SortableList.Item
                className="connect-item"
                id={record.value}
                styles={{ height: toNumber(itemHeight) + 2 }}
              >
                <SortableList.DragHandle />
                <div className="font-size-14">{record.value}</div>
              </SortableList.Item>
            )}
          />
        </Form.Item>
      </div>
      <div className="flex-1 mt-2">
        {configs?.generalConfig?.showFeedbackContent &&
          !data?.settingResponse?.isDisplayPerResponse &&
          !isEmpty(data?.settingResponse) && (
            <SingleMenu
              titleClassName={`comment-menu ${isTrue ? "report-ans-wrapper-correct" : "report-ans-wrapper"} `}
              contentClassName={`${isTrue ? "bg-green" : "bg-purple"}`}
              className={`${isTrue ? "bg-green" : "bg-purple"}`}
              title={
                <p className="font-size-16 line-height-24 font-weight-6">
                  Phản hồi: {renderFeedback()}
                </p>
              }
              content={
                <p className="font-size-16 line-height-24 mt-1 font-weight-5">
                  {data?.result?.message || "Không có nhận xét"}
                </p>
              }
            />
          )}
      </div>
    </div>
  );
}

export default Connect;
