import { Button, Empty, Input, Spin } from "antd";
import { toNumber } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ConfirmModal from "../../../components/modal/confirm-modal/ConfirmModal";
import CustomPagination from "../../../components/pagination/Pagination";
import SecondaryTab from "../../../components/tabs/secondary-tab/SecondaryTab";
import { DISLIKE_REACTION, LIKE_REACTION } from "../../../constants";
import "../styles.scss";
import useUploadFile from "../../../hooks/useUploadFile";
import {
  createDiscussion,
  deleteComment,
  discussionReact,
  getAllCommentByCourse,
  getAllCommentByUnit,
  getAllTotalDiscussByCourse,
  getAllTotalDiscussByUnit,
  replyDiscussion,
  repordCmt,
  updateComment,
} from "../../../service/discussion";
import { globalStore, useAuthStore } from "../../../stores/stores";
import {
  CommentProps,
  CommentsBeProps,
  DiscussionProps,
  ReplyValue,
} from "../../../types/discussion";
import { notifyError, notifySuccess } from "../../../utils/notification";
import Comment from "./components/comment";
import UploadToDiscuss from "./components/comment/UploadToDiscuss";
import ReportModal from "./components/report-modal/ReportModal";
import "./discussion.scss";
import {
  deleteDiscussionBlog,
  postAllDiscussionTopic,
  postCreateDiscussionBlog,
  postDiscussionReactBlog,
  postReplyDiscussionBlog,
  postUpdateDiscussionBlog,
} from "../../../service/blog/blog";

interface DiscussProp {
  onRefetch?: (v: boolean) => void;
  setAllLoading?: (v: boolean) => void;
  tab?: string;
  unitId?: number;
}
const { TextArea } = Input;

function Discuss({
  onRefetch = () => {},
  setAllLoading = () => {},
  unitId,
  tab,
}: DiscussProp) {
  const params = useParams();

  const courseId = toNumber(params?.id);
  const { reload, setReload } = globalStore();
  const [reFetch, setRefetch] = useState(false);
  const [isCreatingDiscussion, setIsCreatingDiscussion] = useState<boolean>();
  const [totalDiscuss, setTotalDiscuss] = useState<any>({
    totalNumber: 0,
    pinnedNumber: 0,
    mineNumber: 0,
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const topicId = searchParams.get("topicId");
  const blogId = searchParams.get("blogId");
  const uuidValue = searchParams.get("uuid");
  const slugValue = searchParams.get("slug");
  const [pageSizeAll, setPageSizeAll] = useState<number>(10);
  const [pageNumberAll, setPageNumberAll] = useState<number>(1);
  const [pageSizeMine, setPageSizeMine] = useState<number>(10);
  const [pageNumberMine, setPageNumberMine] = useState<number>(1);
  const [pageSizePinned, setPageSizePinned] = useState<number>(10);
  const [pageNumberPinned, setPageNumberPinned] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addAttachments, setAddAttachments] = useState<any>([]);
  const [isReportCmt, setIsReportCmt] = useState<boolean>(false);
  const [reportComment, setReportComment] = useState<CommentProps | null>(null);
  const [reportCommentContent, setReportCommentContent] = useState<string>("");
  const [isDelCmt, setIsDelCmt] = useState<boolean>(false);
  //create discussion payload
  const { resetFiles } = useUploadFile();

  const [discussionText, setDiscussionText] = useState<string>("");
  const [isLoadingAction, setLoadingAction] = useState<boolean>(false);
  // discussion data
  const [comments, setComments] = useState<{
    total: number;
    discussions: DiscussionProps[];
  }>();

  const discussions =
    comments?.discussions.map((dis: DiscussionProps) => ({
      ...dis.rootComment,
      isPinned: dis.isPinned,
      courseInfo: {
        sectionId: dis.sectionId,
        sequenceId: dis.sequenceId,
        unitId: dis.unitId,
        sectionName: dis.sectionName,
        sequenceName: dis.sequenceName,
        unitName: dis.unitName,
      },
      level: 1,
      replies:
        dis.subComments
          .filter((comment: CommentsBeProps) => comment.level === 2)
          .map((commentLv2: CommentsBeProps) => ({
            ...commentLv2,
            parent: commentLv2.parentId,
            replies:
              dis.subComments
                .filter(
                  (cmt: CommentsBeProps) =>
                    cmt.level === 3 && cmt.parentId === commentLv2.id
                )
                .map((commentLv3: CommentsBeProps) => ({
                  ...commentLv3,
                  parent: commentLv3.parentId,
                  replies: [],
                })) || [],
          })) || [],
    })) || [];

  const handleLike = async (comment: CommentProps) => {
    try {
      await postDiscussionReactBlog({
        id: comment.id,
        reactionType: LIKE_REACTION,
      });
      setRefetch(!reFetch);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDislike = async (comment: CommentProps) => {
    try {
      await postDiscussionReactBlog({
        id: comment.id,
        reactionType: LIKE_REACTION,
        // reactionType: DISLIKE_REACTION,
      });
      setRefetch(!reFetch);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteComment = (comment: CommentProps) => {
    setReportComment(comment);
    setIsDelCmt(true);
  };
  const handleDelNote = async (comment: CommentProps) => {
    try {
      await deleteDiscussionBlog(comment.id);
      notifySuccess({
        message: "Xoá thảo luận thành công",
        description: "Thông tin đã được cập nhật trên hệ thống Mooc!",
      });

      setRefetch(!reFetch);
      setReload(!reload);
      setIsDelCmt(false);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleOpenReportModal = (comment: CommentProps) => {
    // setReportComment(comment);
    // setIsReportCmt(true);
  };

  const handleReportComment = async (comment: CommentProps) => {
    setIsReportCmt(true);
    setReportComment(comment);
    if (reportComment) {
      try {
        await repordCmt({
          commentId: comment.id,
          content: reportCommentContent,
        });
        notifySuccess({
          message: "Báo cáo thảo luận đã được gửi thành công",
          description: "Thông tin đã được cập nhật trên hệ thống Mooc!",
        });

        setIsReportCmt(false);
      } catch (error) {
        notifyError({ error });
        setIsReportCmt(false);
      }
    }
  };

  const handleAddDiscussion = async () => {
    const formData: any = new FormData();
    formData.append("topicId", `${topicId}`);
    // formData.append("courseId", `${courseId}`);
    formData.append("content", discussionText);
    // formData.append("discussionType", `2`);

    if (addAttachments && Array.isArray(addAttachments)) {
      addAttachments
        .filter((file) => file?.type !== undefined)
        .forEach((file) => {
          formData.append("attachments", file.originFileObj as File);
        });
    }
    try {
      setLoadingAction(true);
      await postCreateDiscussionBlog(formData);
      setRefetch(!reFetch);
      setIsCreatingDiscussion(false);

      setDiscussionText("");
      resetFiles();
      setReload(!reload);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingAction(false);
    }
  };

  const handleReplyComment = async (value: ReplyValue) => {
    const formData: any = new FormData();
    formData.append("content", `${value?.replyText}`);
    formData.append("parentId", `${value?.comment?.id}`);

    if (value?.file && Array.isArray(value?.file)) {
      value?.file
        .filter((file) => file?.type !== undefined)
        .forEach((file) => {
          formData.append("attachments", file.originFileObj as File);
        });
    }
    try {
      setLoadingAction(true);
      await postReplyDiscussionBlog(formData);
      setRefetch(!reFetch);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingAction(false);
    }
  };

  const handleDiscussionTextChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setDiscussionText(e.target.value);
    },
    [setDiscussionText]
  );
  const handleSaveEditComment = async (value: ReplyValue) => {
    const formData: any = new FormData();
    formData.append("id", `${value?.comment?.id}`);
    formData.append("content", `${value?.replyText}`);

    if (value?.file && Array.isArray(value?.file)) {
      value?.file
        .filter((file) => file?.type !== undefined)
        .forEach((file) => {
          formData.append("attachments", file.originFileObj as File);
        });
    }

    if (value?.attachmentRemoved) {
      const attachmentRemoved = value.attachmentRemoved as any[];
      formData.append(
        "deleteAttachmentIds",
        String(attachmentRemoved.map((it) => it.id).join(",")) ?? undefined
      );
    }

    try {
      setLoadingAction(true);
      await postUpdateDiscussionBlog(formData);
      setRefetch(!reFetch);
      notifySuccess(`Sửa bình luận thành công`);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingAction(false);
    }
  };
  const tabItems = [
    {
      key: "1",
      label: (
        <div className="flex align-center gap-8">
          Tất cả thảo luận{" "}
          <div className="count-discuss">{totalDiscuss.totalNumber}</div>
        </div>
      ),
      children: (
        <div className={unitId ? "discuss-tab" : "mt-3"}>
          <div>
            <div className="text-title-18">Tất cả thảo luận</div>
            <div className="text-description-14 mt-1">
              Tổng hợp các thảo luận{" "}
              {unitId ? "trong học liệu" : "trong khoá học"}
            </div>
          </div>
          {isLoading ? (
            <div className="loading-screen">
              <Spin />
            </div>
          ) : (
            <>
              {discussions?.length > 0 ? (
                discussions?.map((discussion: any) => (
                  <div className="discuss-content" key={discussion.id}>
                    <Comment
                      data={discussion}
                      onDelete={handleDeleteComment}
                      onLike={handleLike}
                      onDislike={handleDislike}
                      onReply={handleReplyComment}
                      onSaveEditComment={handleSaveEditComment}
                      unitId={unitId}
                      onReport={handleOpenReportModal}
                    />
                  </div>
                ))
              ) : (
                <div className="center">
                  <Empty description="Chưa có bình luận"></Empty>
                </div>
              )}
            </>
          )}
          <div className="hr-tag-item"></div>
          {comments && comments?.discussions.length > 0 ? (
            <CustomPagination
              totalPage={totalDiscuss.totalNumber}
              pageSize={pageSizeAll}
              pageNumber={pageNumberAll}
              setPageSize={setPageSizeAll}
              setPageNumber={setPageNumberAll}
            />
          ) : null}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex align-center gap-8">
          Thảo luận của tôi
          <div className="count-discuss">{totalDiscuss.mineNumber}</div>
        </div>
      ),
      children: (
        <div className={unitId ? "discuss-tab" : "mt-3"}>
          <div>
            <div className="text-title-18">Thảo luận của tôi</div>
            <div className="text-description-14 mt-1">
              Tổng hợp các thảo luận của bạn{" "}
              {unitId ? "trong học liệu" : "trong khoá học"}
            </div>
          </div>
          {discussions?.length > 0 ? (
            discussions?.map((discussion: any) => (
              <div className="discuss-content" key={discussion.id}>
                <Comment
                  data={discussion}
                  onDelete={handleDeleteComment}
                  onLike={handleLike}
                  onDislike={handleDislike}
                  onReply={handleReplyComment}
                  onSaveEditComment={handleSaveEditComment}
                  unitId={unitId}
                  onReport={handleOpenReportModal}
                />
              </div>
            ))
          ) : (
            <div className="center">
              <Empty description="Không có kết quả"></Empty>
            </div>
          )}
          <div className="hr-tag-item"></div>
          {comments && comments?.discussions.length > 0 ? (
            <CustomPagination
              totalPage={totalDiscuss.mineNumber}
              pageSize={pageSizeMine}
              pageNumber={pageNumberMine}
              setPageSize={setPageSizeMine}
              setPageNumber={setPageNumberMine}
            />
          ) : null}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="flex align-center gap-8">
          Thảo luận đã ghim
          <div className="count-discuss">{totalDiscuss.pinnedNumber}</div>
        </div>
      ),
      children: (
        <div className={unitId ? "discuss-tab" : "mt-3"}>
          <div>
            <div className="text-title-18">Thảo luận đã ghim</div>
            <div className="text-description-14 mt-1">
              Tổng hợp các thảo luận bạn quan tâm{" "}
              {unitId ? "trong học liệu" : "trong khoá học"}
            </div>
          </div>
          {discussions?.length > 0 ? (
            discussions?.map((discussion: any) => (
              <div className="discuss-content" key={discussion.id}>
                <Comment
                  data={discussion}
                  onDelete={handleDeleteComment}
                  onLike={handleLike}
                  onDislike={handleDislike}
                  onReply={handleReplyComment}
                  onSaveEditComment={handleSaveEditComment}
                  unitId={unitId}
                  onReport={handleOpenReportModal}
                />
              </div>
            ))
          ) : (
            <div className="center">
              <Empty description="Không có kết quả"></Empty>
            </div>
          )}
          <div className="hr-tag-item"></div>
          {comments && comments?.discussions.length > 0 ? (
            <CustomPagination
              totalPage={totalDiscuss.mineNumber}
              pageSize={pageSizePinned}
              pageNumber={pageNumberPinned}
              setPageSize={setPageSizePinned}
              setPageNumber={setPageNumberPinned}
            />
          ) : null}
        </div>
      ),
    },
  ];
  useEffect(() => {
    setIsLoading(true);
    const getComments = async () => {
      setLoadingAction(true);
      const payload = {
        page: pageNumberAll,
        size: pageSizeAll,
        topicId: Number(topicId),
        enterpriseUUID: uuidValue,
      };
      const res = await postAllDiscussionTopic(payload);
      setLoadingAction(false);
      setAllLoading(false);
      setComments(res.data.data);
      setIsLoading(false);
    };

    getComments();
    onRefetch(reFetch);
  }, [
    reFetch,
    reload,
    tab,
    unitId,
    pageNumberAll,
    pageNumberMine,
    pageNumberPinned,
    pageSizeAll,
    pageSizeMine,
    pageSizePinned,
  ]);
  // useEffect(() => {
  //   const fetchAllTotalDiscuss = async () => {
  //     try {
  //       const apiUrl = unitId
  //         ? getAllTotalDiscussByUnit(unitId)
  //         : getAllTotalDiscussByCourse(courseId);
  //       const res = await apiUrl;

  //       const total = res?.data.data;
  //       setTotalDiscuss({
  //         totalNumber: total.totalNumber,
  //         pinnedNumber: total.pinnedNumber,
  //         mineNumber: total.mineNumber,
  //       });
  //     } catch (error) {}
  //   };
  //      fetchAllTotalDiscuss();
  //  }, [tab, reload, unitId]);

  const TabBarExtraContent = () => {
    return (
      <div className="discuss-header flex align-center justify-space-between">
        <Button
          onClick={() => {
            setIsCreatingDiscussion(true);
            setAddAttachments([]);
          }}
          className="add-discuss-btn"
        >
          <img src="/assets/icons/plus-icon.svg" alt="" />
          Thêm thảo luận
        </Button>
      </div>
    );
  };

  const commonContent = (
    <>
      {isCreatingDiscussion ? (
        <div className="discuss-create-form">
          <div className="create-form-content">
            <div className="form-content w-full">
              <TextArea
                value={discussionText}
                rows={4}
                onChange={handleDiscussionTextChange}
                placeholder="Nội dung thảo luận của bạn"
              />
            </div>
          </div>

          <UploadToDiscuss
            fileData={addAttachments}
            mode="add"
            setAddAttachments={(file: any) => setAddAttachments(file)}
          />
          <div className="hr-tag-item"></div>
          <div className="create-form-footer">
            <Button
              onClick={() => {
                setIsCreatingDiscussion(false);
                setDiscussionText("");
                resetFiles();
              }}
              className="cancel-btn"
            >
              Huỷ
            </Button>
            <Button
              className="save-btn"
              onClick={handleAddDiscussion}
              loading={isLoadingAction}
            >
              Lưu bình luận
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
  return (
    <>
      <div className="statistic-course-discuss">
        {/* <SecondaryTab
          tabItems={tabItems}
          onChange={(tab) => setDiscussTab(tab)}
          tabBarExtraContent={unitId ? <TabBarExtraContent /> : null}
          commonContent={commonContent}
        /> */}

        <div className={unitId ? "discuss-tab" : "mt-3"}>
          <div className="comment-component-container">
            <div className="header-component">
              <div className="title-comment">Tất cả bình luận</div>
              <div>Tổng hợp tất cả các bình luận của bài đăng</div>
            </div>
            <Button
              onClick={() => {
                setIsCreatingDiscussion(true);
                setAddAttachments([]);
              }}
              className="add-button bg-primary"
            >
              <img src="/assets/icons/plus-icon.svg" alt="" />
              Thêm bình luận
            </Button>
          </div>
          {isLoading ? (
            <div className="loading-screen">
              <Spin />
            </div>
          ) : (
            <>
              {/* {commonContent} */}
              {discussions?.length > 0 ? (
                discussions?.map((discussion: any) => (
                  <div className="discuss-content" key={discussion.id}>
                    <Comment
                      data={discussion}
                      onDelete={handleDeleteComment}
                      onLike={handleLike}
                      onDislike={handleDislike}
                      onReply={handleReplyComment}
                      onSaveEditComment={handleSaveEditComment}
                      unitId={unitId}
                      onReport={handleOpenReportModal}
                    />
                  </div>
                ))
              ) : (
                <div className="center">
                  <Empty description="Không có kết quả"></Empty>
                </div>
              )}
            </>
          )}
          <div className="hr-tag-item"></div>
          {comments && comments?.discussions.length > 0 ? (
            <CustomPagination
              totalPage={totalDiscuss.totalNumber}
              pageSize={pageSizeAll}
              pageNumber={pageNumberAll}
              setPageSize={setPageSizeAll}
              setPageNumber={setPageNumberAll}
            />
          ) : null}
        </div>
      </div>
      <ReportModal
        open={isReportCmt}
        handleOk={() => {
          if (reportComment) {
            handleReportComment(reportComment);
            setReportCommentContent("");
          } else setIsReportCmt(false);
        }}
        handleCancel={() => {
          setIsReportCmt(false);
        }}
        title="Báo cáo thảo luận!"
        confirmIcon={`${process.env.PUBLIC_URL}/assets/icons/check-circle-border.svg`}
        content={
          <>
            <p>
              Đừng chần chừ hãy mô tả vấn đề bất thường bạn phát hiện ra cho
              chúng tôi!
            </p>
            <div className="mt-2">
              <TextArea
                rows={4}
                placeholder="Mô tả lý do tại đây"
                onChange={(e) => setReportCommentContent(e.target.value)}
                value={reportCommentContent}
              />
            </div>
          </>
        }
        confirmBtntitle="Gửi báo cáo"
      />
      <ConfirmModal
        open={isDelCmt}
        handleOk={() => {
          if (reportComment) {
            handleDelNote(reportComment);
          } else setIsReportCmt(false);
        }}
        handleCancel={() => {
          setIsDelCmt(false);
        }}
        title="Xác nhận xoá phản hồi"
        confirmIcon={`${process.env.PUBLIC_URL}/assets/icons/delete-icon.svg`}
        content={`Phản hồi đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá phản hồi?`}
      />
    </>
  );
}

export default Discuss;
