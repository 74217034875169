import { get, isEmpty } from "lodash";
import { QuizRequest } from "../types/course";

export const convertArrayToString = (arr?: Array<string> | Array<any>) => {
  if (arr) {
    const newArray = [get(arr, "name")];
    if (arr.length > 0) {
      return newArray.join(", ");
    }
  }
  return arr;
};

export const isArray = (input: any) => {
  return input instanceof Array;
};

export const getArrayFilterChecked = (
  arr: any[], // Mảng dữ liệu ban đầu
  arrFilter: any[], // Mảng sau khi đã lọc thường sẽ là 1 mảng boolean và undefind
  key: string // dữ liệu của key muốn lấy từ mảng ban đầu
) => {
  if (isEmpty(arr) || !arr?.length) return [];

  const distArr: any = [];
  arrFilter.forEach((item: boolean | undefined, index) => {
    if (item) distArr.push(arr[index][key]);
  });

  return distArr;
};

export const getArrOrUndefined = (arr: any[]) => {
  if (arr && arr.length > 0) return arr;

  return undefined;
};

export function addOrUpdateQuizRequest(
  array: QuizRequest[],
  newItem: QuizRequest
) {
  if (!array?.length) return [newItem];

  const newArr = [...array];
  const index = newArr.findIndex((item) => item.quizId === newItem.quizId);

  if (index !== -1) {
    // Nếu tìm thấy quizId trong mảng, cập nhật phần tử tại vị trí tìm được
    if (isEmpty(newItem.answer) || isEmpty(newItem.answer[0]))
      return newArr.filter(
        (item: QuizRequest) => item.quizId !== newItem.quizId
      );
    else newArr[index] = newItem;
  } else {
    // Nếu không tìm thấy, thêm mới vào mảng
    newArr.push(newItem);
  }
  return newArr;
}
