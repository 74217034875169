import { useEffect } from "react";
import "./style.scss";
import { globalStore } from "../../stores/stores";
import SafeLink from "../link/SafeLink";
import { routesConfig } from "../../config/routes";
import { Col, Row } from "antd";

function Footer() {
  const { isFooterDefault, isFullscreenQuiz } = globalStore();

  useEffect(() => {});
  return (
    <section
      style={{ display: isFullscreenQuiz ? "none" : "block" }}
      className={`mooc-footer ${isFooterDefault === false ? "bg-footer-primary" : "bg-footer-default"}`}
    >
      <div className="logo-footer flex align-center gap-12">
        <img
          src={process.env.PUBLIC_URL + "/assets/img/app-logo.png"}
          alt="logo"
        />
        <h2>Mooc</h2>
      </div>
      <div className="footer-link">
        <Row gutter={[32, 32]}>
          <Col>
            <SafeLink
              className={`${isFooterDefault === true ? "footer-link-item" : "footer-link-item-p"}`}
              to={routesConfig.welcome}
            >
              Giới thiệu
            </SafeLink>
          </Col>
          <Col>
            <SafeLink
              className={`${isFooterDefault === true ? "footer-link-item" : "footer-link-item-p"}`}
              to={routesConfig.welcome}
            >
              Chính sách quyền riêng tư
            </SafeLink>
          </Col>
          <Col>
            <SafeLink
              className={`${isFooterDefault === true ? "footer-link-item" : "footer-link-item-p"}`}
              to={routesConfig.welcome}
            >
              Điều khoản sử dụng
            </SafeLink>
          </Col>
          <Col>
            <SafeLink
              className={`${isFooterDefault === true ? "footer-link-item" : "footer-link-item-p"}`}
              to={routesConfig.welcome}
            >
              Trung tâm liên hệ
            </SafeLink>
          </Col>
        </Row>
      </div>
      <div className="hr-tag"></div>
      <div
        className={`${isFooterDefault === true ? "copy-right" : "copy-right-p"}`}
      >
        <span>© 2024 Mooc. All rights reserved.</span>
        <div className="flex gap-12">
          <span>Terms</span>
          <span>Privacy</span>
          <span>Cookies</span>
        </div>
      </div>
    </section>
  );
}

export default Footer;
