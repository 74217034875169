export type PaymentSetting = {
  message: string;
  statusCode: number;
  data: {
    attendance: string;
    created_at: string;
    exam: string;
    id: number;
    login: string;
    organization_id: 3;
    payment?: PaymentType;
    register_course: string;
    updated_at: string;
    user_id: string;
  };
};
export enum PaymentType {
  FACE = "face",
  BOTH = "both",
  PASSWORD = "password",
}
