import { axiosConfig } from "../../config/api/configApi";
import { AxiosResponse } from "axios";
import {
  StatisticalChartEnterprisePayload,
  StatisticChartPayload,
} from "../../types/statistic";
import { convertToQueryString } from "../../utils/format";
import { axiosConfigWithoutNotify } from "../../config/api/configApiWithoutNotify";

export const getStatisticalChartEnterprise: (
  payload: StatisticalChartEnterprisePayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(
    `v2/open-api/enterprise/statistical-chart-enterprise`,
    payload
  );
};

export const exportExcelCountByYear: (
  sort?: string
) => Promise<AxiosResponse<any>> = (sort) => {
  return axiosConfig.get(
    `v2/open-api/course/statistic/export-count-by-year${sort ? `?sort=${sort}` : ""}`,
    {
      responseType: "arraybuffer",
    }
  );
};

export const exportExcelCountByUniversity: (payload?: {
  sortByCount: string | undefined;
  sortByName: string | undefined;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.get(
    `v2/open-api/course/statistic/export-count-by-uni${convertToQueryString(payload)}`,
    {
      responseType: "arraybuffer",
    }
  );
};

export const getStatisticalUniversitiesByYear: (
  payload?: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(
    `v2/open-api/enterprise/statistical-chart-enterprise`,
    payload
  );
};

export const getChartCountByYear: (
  payload?: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.get(
    `v2/open-api/course/statistic/count-by-year${convertToQueryString(payload)}`
  );
};

export const getChartCountByUniversities: (
  payload?: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.get(
    `v2/open-api/course/statistic/count-by-university${convertToQueryString(payload)}`
  );
};

// Tỷ lệ kết quả đánh giá khóa học
export const fetchRateUnitByCourse: (
  payload?: StatisticChartPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigWithoutNotify.post(
    `v2/api/chart-student/get-rate-unit-by-course`,
    payload
  );
};

// Tỷ lệ kết quả làm đánh giá
export const fetchRateUnitByModule: (
  payload?: StatisticChartPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigWithoutNotify.post(
    `v2/api/chart-student/get-rate-unit-by-module`,
    payload
  );
};

// Tỷ lệ kết quả đánh giá theo bài giảng
export const fetchRateUnitBySequence: (
  payload?: StatisticChartPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigWithoutNotify.post(
    `v2/api/chart-student/get-rate-unit-by-sequence`,
    payload
  );
};

// Thống kê số lượng tải về/chia sẻ/xem
export const fetchRateUnitAction: (
  payload?: StatisticChartPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigWithoutNotify.post(
    `v2/api/chart-student/by-time/get-unit-action`,
    payload
  );
};

// Thống kê số lượng đánh giá theo thời gian
export const fetchRateUnitReviewAndAccess: (
  payload?: StatisticChartPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigWithoutNotify.post(
    `v2/api/chart-student/by-time/get-unit-review-and-access`,
    payload
  );
};

// Thống kê số lượng phản hồi theo bài giảng
export const fetchRateSequenceAction: (
  payload?: StatisticChartPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigWithoutNotify.post(
    `v2/api/chart-student/get-rate-sequence-action`,
    payload
  );
};

// Thống kê số lượng phản hồi theo lớp
export const fetchRateClassAction: (
  payload?: StatisticChartPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigWithoutNotify.post(
    `v2/api/chart-student/get-rate-class-action`,
    payload
  );
};

// Thống kê tìm kiếm theo thời gian
export const fetchSearchHistory: (
  payload?: StatisticChartPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigWithoutNotify.post(
    `v2/api/chart-teacher/by-time/get-search-history`,
    payload
  );
};

// Thống kê tìm kiếm theo thời gian
export const fetchSearchKeywordCount: (
  payload?: StatisticChartPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigWithoutNotify.post(
    `v2/api/chart-student/get-search-keyword-count`,
    payload
  );
};

// Thống kê số lượng phản hồi theo thời gian
export const fetchUnitDiscuss: (
  payload?: StatisticChartPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigWithoutNotify.post(
    `v2/api/chart-student/by-time/get-unit-discuss`,
    payload
  );
};

// Tỉ lệ kết quả đánh giá học liệu theo lớp
export const fetchStarRatingExam: (
  payload?: StatisticChartPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigWithoutNotify.post(
    `v2/api/chart-student/get-star-rating-exam`,
    payload
  );
};

export const fetchIndustryGroupFilter: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfigWithoutNotify.get(
    `v2/api/my-registration/get-industry-group-filter`
  );
};

export const fetchCourseList: (payload?: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigWithoutNotify.get(
    `v2/api/my-registration/get-all-my-course${convertToQueryString(payload)}`
  );
};

export const fetchStudentClassFilter: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfigWithoutNotify.get(
    `v2/api/my-registration/get-student-class-filter`
  );
};

export const fetchCourseLevel: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfigWithoutNotify.get(
    `v2/api/mooc-courses/get-course-level`
  );
};
