import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const postLogin: (body: any) => Promise<AxiosResponse<any>> = (
  body: any
) => {
  return axiosConfig.post(`/authentications/login`, body);
};

export const getMailOtp: (param: string) => Promise<AxiosResponse<any>> = (
  param: string
) => {
  return axiosConfig.post(`/accounts/get-otp-forgot-password`, {
    email: param,
  });
};

export const putChangePassword: (body: any) => Promise<AxiosResponse<any>> = (
  body
) => {
  return axiosConfig.put(`/accounts/forgot-change-password`, body);
};

export const checkOtpForgotPassword: (
  email: string,
  otp: string
) => Promise<AxiosResponse<any>> = (email, otp) => {
  return axiosConfig.post(`/accounts/check-otp-forgot-password`, {
    email: email,
    otp: otp,
  });
};

export const getMeId: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/authentications/me`);
};

export const getAccountInfo: (param: number) => Promise<AxiosResponse<any>> = (
  param: number
) => {
  return axiosConfig.get(`/accounts/get-by-id/${param}`);
};

export const getInfoLoginUser: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/api/account/get-student-profile`);
};

export const postLogout: (body: any) => Promise<AxiosResponse<any>> = (
  body: any
) => {
  return axiosConfig.post(`/v2/auth/logout`, body);
};

export const postCreateAccount: (body: any) => Promise<AxiosResponse<any>> = (
  body: any
) => {
  return axiosConfig.post(`v2/account/register-account`, body);
};

export const authenticateRegisterByOtp: (
  email: string
) => Promise<AxiosResponse<any>> = (email: string) => {
  return axiosConfig.post(`/v2/account/resend-otp-to-register?email=${email}`);
};

export const reSendOtp: (email: string) => Promise<AxiosResponse<any>> = (
  email
) => {
  return axiosConfig.get(`/v2/account/resend-otp-to-register?email=${email}`);
};

export const endCreateAccount: (
  email: string,
  otp: any
) => Promise<AxiosResponse<any>> = (email: string, otp: any) => {
  return axiosConfig.get(
    `/v2/account/complete-register-account?email=${email}&otp=${otp}`
  );
};
