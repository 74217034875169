import React, { useEffect, useState } from "react";
import Banner from "../../components/banner/Banner";
import { Button, Form, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import "./style.scss";
import ResponsiveList from "../../components/list/ResponsiveList";
import UniversityCard from "../../components/card/university/UniversityCard";
import CustomPagination from "../../components/pagination/Pagination";
import { DEFAULT_PAGE_NUM, DEFAULT_PAGE_SIZE } from "../../constants";
import useMetaData from "../../hooks/useMetaData";
import {
  IndustryInfo,
  // , TrainingLevel
} from "../../types/course";
import FilterStar from "../../components/filter/FilterStar";
import {
  exportExcelListEnterprise,
  getUniversities,
} from "../../service/university";
import { UniversityProps } from "../../types/university";
import useKeyword from "../../hooks/useKeyword";

function SearchSchools() {
  const navigate = useNavigate();
  const {
    industriesFilterUniversity,
    //  trainingLevelsFilter
  } = useMetaData();

  const [universities, setUniversities] = useState([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [keySearch, setKeySearch] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  //-----------------filter--------------------------
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [pageNum, setPageNum] = useState<number>(DEFAULT_PAGE_NUM);
  const keyword = useKeyword("keyword");
  const [industryFilter, setIndustryFilter] = useState<number>(0);
  const [trainingLevelFilter, setTrainingLevelFilter] = useState<number>(0);
  const [rankFilter, setRankFilter] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string | number>("");
  // ------------------------------------------------

  useEffect(() => {
    const searchUniversities = async () => {
      try {
        setLoading(true);
        const res = await getUniversities({
          page: pageNum,
          size: pageSize,
          keyword: keyword ? keyword : undefined,
          industryGroupId: industryFilter ? industryFilter : undefined,
          traningLevelId: trainingLevelFilter ? trainingLevelFilter : undefined,
          rate: rankFilter ? rankFilter : undefined,
          orderBy: orderBy ? orderBy : undefined,
        });

        setUniversities(res.data?.content);
        setTotalRecords(res.data?.totalElements);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    searchUniversities();
  }, [
    pageSize,
    pageNum,
    keyword,
    industryFilter,
    trainingLevelFilter,
    rankFilter,
    orderBy,
  ]);

  const handleClickSearch = () => {
    setPageNum(1)
    if (keySearch) {
      navigate(`${routesConfig.searchSchool}?keyword=${keySearch?.trim()}`);
    } else {
      navigate(`${routesConfig.searchSchool}`);
    }
  };

  const onKeyBoardEnter = (e: any) => {
    if (e.key === "Enter") {
      navigate(`${routesConfig.searchSchool}?keyword=${e.target.value.trim()}`);
    }
  };

  const industriesOptions =
    industriesFilterUniversity?.length > 0
      ? industriesFilterUniversity?.map((item: IndustryInfo, index) => ({
          label: item.name,
          value: item.id,
        }))
      : [];

  // const trainingSystemOptions =
  //   trainingLevelsFilter?.length > 0
  //     ? trainingLevelsFilter?.map((item: TrainingLevel) => ({
  //       value: item.id,
  //       label: item.name,
  //     }))
  //     : [];

  const rankOptions = [
    { value: 0, label: "Mặc định" },
    { value: 4.5, label: <FilterStar star={5} /> },
    { value: 4, label: <FilterStar star={4} /> },
    { value: 3, label: <FilterStar star={3} /> },
    { value: 2, label: <FilterStar star={2} /> },
    { value: -2, label: <FilterStar star={1.5} /> },
  ];

  const sortNameOptions = [
    { value: 0, label: "Mặc định" },
    { value: "name,asc", label: "A - Z" },
    { value: "name,desc", label: "Z - A" },
  ];

  const filters = [
    {
      content: (
        <>
          <p className="text-secondary mb-1">Chuyên ngành</p>
          <Form.Item name="industry" initialValue={0}>
            <Select
              className="height-44"
              options={[{ value: 0, label: "Tất cả" }].concat(
                industriesOptions as any
              )}
            />
          </Form.Item>
        </>
      ),
    },
    // {
    //   content: (
    //     <>
    //       <p className="text-secondary mb-1">Hệ đào tạo</p>
    //       <Form.Item name="system" initialValue={0}>
    //         <Select
    //           className="height-44"
    //           options={[{ value: 0, label: "Tất cả" }].concat(
    //             trainingSystemOptions as any
    //           )}
    //         />
    //       </Form.Item>
    //     </>
    //   ),
    // },
    {
      content: (
        <>
          <p className="text-secondary mb-1">Xếp hạng</p>
          <Form.Item name="rank" initialValue={0}>
            <Select className="height-44" options={rankOptions} />
          </Form.Item>
        </>
      ),
    },
    {
      content: (
        <>
          <p className="text-secondary mb-1">Sắp xếp theo</p>
          <Form.Item name="orderBy" initialValue={0}>
            <Select className="height-44" options={sortNameOptions} />
          </Form.Item>
        </>
      ),
    },
  ];

  const handleChangeFilter = (val: any, values: any) => {
    setIndustryFilter(+values?.industry);
    setTrainingLevelFilter(+values?.system);
    setRankFilter(+values?.rank);
    setOrderBy(values?.orderBy);
  };

  const gotoDetailSchool = (
    uuid: string | undefined,
    slug: string | undefined
  ) => {
    // navigate(`${routesConfig.universityDetail}/${slug}`);
    window.open(
      `${routesConfig.searchSchool}/${routesConfig.universityTemplateDetail}?uuid=${uuid}&slug=${slug}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleViewChart = () => {
    navigate(`${routesConfig.statisticsUniversities}`);
  };

  const handleExportExcel = async () => {
    try {
      const res = await exportExcelListEnterprise();
      const fileData = res.data;

      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);

      downloadLink.download = "Danh sách trường.xlsx";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };
  return (
    <div>
      <Banner>
        <section className="center flex-column banner-content">
          <h3 className="mb-3 text-white banner-title">
            Các trường đại học liên kết
          </h3>
          <span className="mb-4 text-violet">
            Với 50+ các trường đại học liên kết tại Việt Nam
          </span>

          <div className="search flex align-center">
            <Input
              className="search-input"
              value={keySearch}
              placeholder="Nhập từ khoá cần tìm"
              onKeyDown={onKeyBoardEnter}
              onChange={(e) => setKeySearch(e.target.value)}
            />
            <Button
              className="mooc-btn-violet btn-primary"
              onClick={handleClickSearch}
            >
              Tìm kiếm
            </Button>
          </div>
        </section>
      </Banner>
      <section className="content-layout">
        <div className="filter-group">
          <Form onValuesChange={handleChangeFilter}>
            <ResponsiveList
              grid={{ gutter: 24, xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 }}
              data={filters}
            />
          </Form>
        </div>
        <ResponsiveList
          showMenu
          loading={loading}
          textTotal="trường"
          className="mt-2"
          title="Danh sách trường"
          totalRecords={totalRecords}
          grid={{ gutter: 24, xs: 1, sm: 2, md: 2, lg: 2, xl: 4, xxl: 4 }}
          data={universities?.map((item: UniversityProps) => ({
            content: (
              <UniversityCard
                image={item.logo}
                name={item.name}
                slug={item.slug}
                id={item.uuid}
                gotoDetailSchool={() => gotoDetailSchool(item.uuid, item.slug)}
              />
            ),
          }))}
          onClickReport={handleViewChart}
          onClickExportExcel={handleExportExcel}
        />
        {totalRecords > 0 && (
          <CustomPagination
            totalPage={totalRecords}
            pageSize={pageSize}
            pageNumber={pageNum}
            setPageSize={(value) => setPageSize(value)}
            setPageNumber={(value) => setPageNum(value)}
          />
        )}
      </section>
    </div>
  );
}

export default SearchSchools;
