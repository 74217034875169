import { useCallback, useEffect, useRef, useState } from "react";
import * as faceTool from "mooc-ai-tool";

import { Flex, Modal, Typography, Image, Button } from "antd";
import {
  CheckOutlined,
  ExclamationCircleOutlined,
  InfoOutlined,
  LoadingOutlined,
  PauseCircleOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { useIdentifyEkycStore } from "../../../../../stores/stores";
import Webcam from "react-webcam";
import { HelpService } from "../../../../../service/helper.service";
import { modelsPath } from "../../../../../constants/modelsPath";
import {
  DownArrowIcon,
  LeftArrowIcon,
  PlayIcon,
  RightArrowIcon,
  UpArrowIcon,
} from "../../../../../components/icons";
import { ekycRegisterUserVideo } from "../../../../../service/ekyc";
import Countdown from "../../../../../components/countdown/CountDown";

const { Title, Text } = Typography;

interface IPropsModal {
  isOpen: boolean;
  handleClose: () => void;
}
const videoConstraints = {
  width: 280,
  height: 280,
  facingMode: "environment",
};
const successCode = 0;
const DEFAULT_FACE = [
  "Giữ thẳng",
  "Quay phải",
  "Quay trái",
  "Ngửa lên",
  "Cúi xuống",
];
const CommonFaceDirection = ({
  value,
  index,
  currentInstructionIndex,
}: {
  value: string;
  index: number;
  currentInstructionIndex: number;
}) => {
  return (
    <div className="w-1/6 text-start">
      <div
        className={`transition-all border-t-4 ${index <= currentInstructionIndex ? "border-[#7F56D9]" : "border-transparent"}`}
      >
        <p
          className={`transition-all ${index == currentInstructionIndex ? "text-[#6941C6]" : "text-black"} font-semibold`}
        >
          0{index + 1}
        </p>
        <p
          className={`transition-all ${index == currentInstructionIndex ? "text-[#7F56D9]" : "text-black"}`}
        >
          {value}
        </p>
      </div>
    </div>
  );
};
const ModalCameraEkyc = ({ isOpen, handleClose }: IPropsModal) => {
  let timeoutId = useRef<NodeJS.Timeout>();
  const [isPass, setIsPass] = useState(false);
  const [isPlayingPreview, setIsPlayingPreview] = useState(false);
  const previewVideoRef = useRef<HTMLVideoElement>(null);
  const [isOpenIntroduce, setIsOpenIntroduce] = useState(false);
  const { changeStep } = useIdentifyEkycStore();
  const [currentInstructionIndex, setCurrentInstructionIndex] = useState(0);
  const [isChecking, setIsChecking] = useState(false);
  const [previewVideo, setPreviewVideo] = useState<string | ArrayBuffer>("");
  const webcamRef = useRef<Webcam>(null);
  const consecutiveCounterRef = useRef(0); // Ref to keep track of consecutive counts
  const helpService = useRef(new HelpService());
  const [isLoadingModel, setIsLoadingModel] = useState(true);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const videoRef = useRef(null) as any;
  const mediaRecorderRef = useRef(null) as any;
  const onLoadedMetadata = () => {
    if (webcamRef.current && webcamRef.current.video) {
      // Execute faceTool for the first time
      faceTool.initialFace(webcamRef.current.video, modelsPath[1]).then(() => {
        setIsLoadingModel(false);
        handleStartRecord();
        timeoutId.current = setTimeout(() => {
          requestAnimationFrame(executeLoop);
        }, 200);
      });
    } else {
      helpService.current.errorMessage("Không tìm thấy camera");
    }
  };
  const [identifyStatus, setIdentifyStatus] = useState<{
    messages: string[];
    status: number[];
  }>({
    messages: [],
    status: [],
  });
  const handleStartRecord = useCallback(() => {
    if (!webcamRef.current || !webcamRef.current.stream) {
      helpService.current.errorMessage("Không tìm thấy camera");
      return;
    }
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/mp4",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, mediaRecorderRef]);
  const handleSubmitVideo = async () => {
    setIsChecking(true);
    const formData = new FormData();
    const fileRecord = new File(recordedChunks, "step-1", {
      type: "video/mp4",
    });
    formData.append("file", fileRecord);
    formData.append("check_liveness", "true");
    try {
      const response = await ekycRegisterUserVideo({ data: formData });
      const data = response.data;
      if (data.response_code === 200 && response.status === 200) {
        helpService.current.successMessage("Thành công");
        handleConfirm();
      } else {
        helpService.current.errorMessage(data.response_message);
      }
    } catch (error) {
      helpService.current.errorMessage("Thất bại!!!");
    }
    setIsChecking(false);
  };
  const handleDataAvailable = useCallback(
    async ({ data }: BlobEvent) => {
      if (data.size > 0) {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          if (e.target && e.target.result) {
            setPreviewVideo(e.target.result);
          }
        };
        fileReader.readAsDataURL(data);
      } else {
        helpService.current.errorMessage("Thời lượng video không có!!!");
      }
      setRecordedChunks((prev) => prev.concat(data));
    },
    [isPass]
  );
  const executeLoop = async () => {
    if (webcamRef.current && webcamRef.current.video) {

      const { codes, messages, pose } = await faceTool.executeFace({
        left: 40,
        right: 40,
        up: 20,
        down: 20,
        centerX_left: 30,
        centerX_right: 30,
        centerY_up: 15,
        centerY_down: 12,
      });

      handleVerifyEachStep(consecutiveCounterRef.current, {
        codes,
        yaw: pose.yaw,
        pitch: pose.pitch,
        messages: [...messages],
        status: [...codes],
      });
      if (consecutiveCounterRef.current >= 5) {
        consecutiveCounterRef.current = 0;
      } else {
        // Continue the loop
        timeoutId.current = setTimeout(() => {
          requestAnimationFrame(executeLoop);
        }, 100);
      }
    }
  };
  const handleVerifyEachStep = (
    currentStep: number,
    data: {
      status: number[];
      yaw: string | null;
      pitch: string | null;
      messages: string[];
      codes: number[];
    }
  ) => {
    const { codes, yaw, pitch, messages } = data;

    if (codes.includes(12)) return;

    if (codes.length === 1 && codes.includes(successCode)) {
      switch (currentStep) {
        case 0:
          if (yaw === "center" && pitch === "center") {
            setIsPass(true);
            consecutiveCounterRef.current = 1;
            setCurrentInstructionIndex(1);
          } else {
            setIdentifyStatus((prev) => ({
              ...prev,
              messages: ["Bạn vui lòng quay khuôn mặt đúng theo hướng dẫn"],
              status: [...codes],
            }));
          }

          break;
        case 1:
          if (yaw === "right" && pitch === "center") {
            setIsPass(true);
            consecutiveCounterRef.current = 2;
            setCurrentInstructionIndex(2);
          } else {
            setIdentifyStatus((prev) => ({
              ...prev,
              messages: ["Bạn vui lòng quay khuôn mặt đúng theo hướng dẫn"],
              status: [...codes],
            }));
          }
          break;
        case 2:
          if (yaw === "left" && pitch === "center") {
            setIsPass(true);
            consecutiveCounterRef.current = 3;
            setCurrentInstructionIndex(3);
          } else {
            setIdentifyStatus((prev) => ({
              ...prev,
              messages: ["Bạn vui lòng quay khuôn mặt đúng theo hướng dẫn"],
              status: [...codes],
            }));
          }
          break;
        case 3:
          if (pitch === "up" && yaw === "center") {
            setIsPass(true);
            consecutiveCounterRef.current = 4;
            setCurrentInstructionIndex(4);
          } else {
            setIdentifyStatus((prev) => ({
              ...prev,
              messages: ["Bạn vui lòng quay khuôn mặt đúng theo hướng dẫn"],
              status: [...codes],
            }));
          }
          break;
        case 4:
          if (pitch === "down" && yaw === "center") {
            consecutiveCounterRef.current = 5;
            setIsPass(true);
            setCurrentInstructionIndex(5);
          } else {
            setIdentifyStatus((prev) => ({
              ...prev,
              messages: ["Bạn vui lòng quay khuôn mặt đúng theo hướng dẫn"],
              status: [...codes],
            }));
          }
          break;
        default:
          break;
      }
    } else {
      // mediaRecorderRef.current.stop();
      // handleRefresh();
      // setRecordedChunks([]);
      // setPreviewVideo("");
      // setCurrentInstructionIndex(0);
      // if (codes.includes(14)) {
      setIdentifyStatus((prev) => ({
        ...prev,
        messages: [...messages],
        status: [...codes],
      }));
      // }
    }
  };
  const handleCancel = useCallback(() => {
    handleClose();
  }, []);
  const handleConfirm = () => {
    changeStep(1);
  };
  const handleRefresh = () => {
    if (isChecking) return;
    if (mediaRecorderRef.current) {
      setRecordedChunks([]);
      setPreviewVideo("");
      setCurrentInstructionIndex(0);
      mediaRecorderRef.current.start();
      requestAnimationFrame(executeLoop);
    }
  };
  const handleStopCaptureClick = useCallback(() => {
    if (!mediaRecorderRef.current) return;
    mediaRecorderRef.current.stop();
    webcamRef.current?.video?.pause();
  }, [mediaRecorderRef.current, webcamRef, videoRef.current]);
  useEffect(() => {
    // Cleanup function to stop the media stream when the component is unmounted
    return () => {
      if (videoRef?.current && videoRef?.current?.srcObject) {
        let tracks = videoRef?.current?.srcObject?.getTracks();
        tracks.forEach((track: any) => track.stop());
      }
    };
  }, []);
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
      handleStopCaptureClick();
      mediaRecorderRef.current?.removeEventListener(
        "dataavailable",
        handleDataAvailable
      );
    };
  }, [isOpen]);
  useEffect(() => {
    if (isPass && currentInstructionIndex < 5) {
      setTimeout(() => {
        setIsPass(false);
      }, 500);
    }
    if (isPass && currentInstructionIndex === 5) {
      handleStopCaptureClick();
    }
  }, [currentInstructionIndex]);
  return (
    <>
      <Modal
        title={
          <Flex
            justify="space-between"
            className="relative -top-2"
            align="center"
          >
            <Title
              className="!text-lg items-center flex gap-2 !mb-0 !font-semibold"
              level={5}
            >
              Quay Video khuôn mặt
              {!isLoadingModel && !previewVideo && (
                <span className="text-sm flex text-white bg-green-400 rounded-lg px-4 py-2 items-center">
                  <Countdown
                    pause={currentInstructionIndex === 5}
                    initialTime={60}
                    format="hh:mm:ss"
                    onFinish={() => {
                      helpService.current.errorNotification(
                        "Hết thời gian",
                        "top"
                      );
                      handleClose();
                    }}
                  />
                </span>
              )}
            </Title>
            <ExclamationCircleOutlined
              className="mr-4 flex items-start cursor-pointer"
              onClick={() => setIsOpenIntroduce(true)}
            />
          </Flex>
        }
        centered
        open={isOpen}
        onCancel={() => {
          handleStopCaptureClick();
          handleCancel();
        }}
        footer={
          <div>
            {!previewVideo && (
              <Flex key={"Cancel"} className="mt-3" justify="space-between">
                {DEFAULT_FACE.map((face: string, index: number) => (
                  <CommonFaceDirection
                    key={index}
                    currentInstructionIndex={currentInstructionIndex}
                    value={face}
                    index={index}
                  />
                ))}
              </Flex>
            )}
            {previewVideo && (
              <Flex key={"Submit"} className="mt-2 w-full" gap={16}>
                <Button
                  key="refresh"
                  type="default"
                  className="flex-1 justify-center flex"
                  onClick={handleRefresh}
                >
                  <Flex align="center">
                    <UndoOutlined />
                    <span className="pl-1">Thực hiện lại</span>
                  </Flex>
                </Button>
                <Button
                  key="submit"
                  type="primary"
                  className="flex-1 justify-center flex"
                  onClick={handleSubmitVideo}
                >
                  {isChecking ? (
                    <LoadingOutlined />
                  ) : (
                    <Flex align="center">
                      <CheckOutlined />
                      <span className="pl-1">Xác nhận</span>
                    </Flex>
                  )}
                </Button>
              </Flex>
            )}
          </div>
        }
      >
        <Flex
          gap={"16px"}
          className="border p-4 h-20 items-center w-full font-semibold text-[#344054] rounded-xl"
        >
          {!previewVideo ? (
            <>
              <div className="flex items-center mb-1">
                <div className="relative top-1 flex items-center justify-center pointer-events-none">
                  <InfoOutlined style={{ color: "#7F56D9" }} />
                  <span className=" absolute inset-0 animate-signal border border-[#7F56D9] rounded-full"></span>
                </div>
              </div>

              <p>
                {identifyStatus.messages[0] ||
                  "Vui lòng quay mặt theo hướng mũi tên. Hệ thống sẽ tự động bắt đầu khi nhận diện được khuôn mặt"}
              </p>
            </>
          ) : (
            <p>
              Vui lòng kiểm tra video khuôn mặt của bạn đã được hệ thống ghi
              nhận
            </p>
          )}
        </Flex>
        <div className="!mt-5 bg-[#00000080] p-4 rounded-md text-center">
          <Flex
            justify="center"
            className="relative"
            style={{
              width: "100%",
              height: 280,
            }}
          >
            {isLoadingModel && (
              <div className="absolute z-20 inset-0 grid place-content-center bg-white rounded-lg">
                <LoadingOutlined
                  className=" text-3xl"
                  style={{ fontSize: "32px" }}
                />
              </div>
            )}{" "}
            {isChecking && (
              <div className="absolute inset-0 grid place-content-center bg-black/30 rounded-lg z-[9999]">
                <span className="text-white">Đang kiểm tra...</span>
              </div>
            )}
            {!isLoadingModel &&
              currentInstructionIndex >= 0 &&
              !previewVideo && (
                <div
                  className={`absolute grid place-content-center rounded-full border-2  aspect-square h-full z-20 ${isPass ? "border-green-400" : "border-red-500"}`}
                >
                  <div className="scale-150">
                    {currentInstructionIndex === 1 && <RightArrowIcon />}
                    {currentInstructionIndex === 2 && <LeftArrowIcon />}
                    {currentInstructionIndex === 3 && <UpArrowIcon />}
                    {currentInstructionIndex === 4 && <DownArrowIcon />}
                  </div>
                </div>
              )}
            {previewVideo && (
              <div
                className={`absolute grid place-content-center rounded-full border-2  aspect-square h-full z-20 ${isPass ? "border-green-400" : "border-red-500"}`}
              >
                {!isPlayingPreview ? (
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      if (!previewVideoRef.current) {
                        return;
                      }
                      setIsPlayingPreview(true);
                      previewVideoRef.current.play();
                    }}
                  >
                    <PlayIcon />
                  </span>
                ) : (
                  <PauseCircleOutlined
                    onClick={() => {
                      if (!previewVideoRef.current) {
                        return;
                      }
                      setIsPlayingPreview(false);
                      previewVideoRef.current.pause();
                    }}
                    style={{ color: "#7F56D9" }}
                    className="text-5xl cursor-pointer"
                  />
                )}
              </div>
            )}
            {!previewVideo ? (
              <Webcam
                audio={false}
                width={280}
                height={280}
                mirrored={true}
                ref={webcamRef}
                className="rounded-full"
                videoConstraints={videoConstraints}
                onLoadedMetadata={onLoadedMetadata}
              />
            ) : (
              <video
                src={previewVideo.toString()}
                ref={previewVideoRef}
                className="rounded-full"
                width={280}
                height={280}
              />
            )}
          </Flex>
        </div>
      </Modal>
      <Modal
        width={936}
        onCancel={() => {
          setIsOpenIntroduce(false);
        }}
        footer={null}
        open={isOpenIntroduce}
      >
        <Flex wrap="wrap">
          <div className="w-full sm:w-1/3 text-center h-full">
            <Image
              src="/assets/img/kyc.png"
              className="h-full"
              preview={false}
            />
          </div>
          <div className="w-full sm:w-2/3 sm:px-10 pt-4 sm:pt-0">
            <Title level={4} className="!mb-0 !font-semibold">
              Hướng dẫn chi tiết
            </Title>
            <Text className="text-[#475467]">
              Vui lòng đọc kĩ hướng dẫn của hệ thống
            </Text>
            <ol className="px-4 py-4 lg:py-8">
              <li className="list-decimal py-1 lg:py-2">
                Đảm bảo khuôn mặt ở trong khung hình
              </li>
              <li className="list-decimal py-1 lg:py-2">
                Thực hiện di chuyển khuôn mặt theo hướng dẫn
              </li>
              <li className="list-decimal py-1 lg:py-2">
                Không đeo kính, đội mũ, đeo khẩu trang hoặc trùm toc khi thực
                hiện
              </li>
              <li className="list-decimal py-1 lg:py-2">
                Đảm bảo thực hiện ở nơi có đủ ánh sáng
              </li>
            </ol>
          </div>
        </Flex>
      </Modal>
    </>
  );
};

export default ModalCameraEkyc;
