import axios, { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { useAuthStore } from "../../stores/stores";
import { axiosConfigV2 } from "../../config/api/configApiv2";
const accessToken = useAuthStore.getState().accessToken;

export const getMyCourseList: (payload: any) => Promise<AxiosResponse<any>> = (
  payload: any
) => {
  return axiosConfig.post(`/v2/api/my-registration/filter-my-courses`, payload);
};

export const uploadFile: (params: any) => Promise<AxiosResponse<any>> = (
  params: any
) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_API_URL}v2/api/mooc-course-block-quiz/upload-file`,
    params,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const getTotalMyCourseList: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload: any) => {
  return axiosConfig.post(
    `/v2/api/my-registration/statistic-learning-status`,
    payload
  );
};

export const getUniversityInMyCourse: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.get(`/v2/api/my-registration/enterprise`);
};

export const getIndustryInMyCourse: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload: any) => {
  return axiosConfig.post(`/v2/api/my-registration/industry`, payload);
};

export const getEnrollmentHistory: (
  id: number
) => Promise<AxiosResponse<any>> = (id: number) => {
  return axiosConfig.get(`/v2/api/my-registration/history/${id}`);
};

export const getStartLearningCourse: (
  id: number
) => Promise<AxiosResponse<any>> = (id: number) => {
  return axiosConfigV2.get(`/api/my-registration/start-learning-course/${id}`);
};
