import { isEmpty, isNumber, toNumber } from "lodash";
import {
  ChartDataProps,
  ChartStatisticValue,
  StatisticBarChartResponseData,
} from "../../../../types/statistic";
import {
  Circle,
  Diamond,
  Square,
  Triangle,
} from "../../../../components/charts/components/icons";

type OutputType = {
  [key: string]: number | string;
};

export const mapToDataChart = (inputArray: ChartDataProps[]): OutputType[] => {
  if (isEmpty(inputArray)) return [];

  return inputArray.map((item) => {
    const result: OutputType = item.values.reduce((acc, curr) => {
      acc[curr.criteria] = curr.count;
      acc["criteria"] = item.criteria;
      return acc;
    }, {} as OutputType);

    return result;
  });
};

export const mapToDataPercentChart = (
  inputArray: ChartDataProps[]
): OutputType[] => {
  if (isEmpty(inputArray)) return [];

  return inputArray.map((item) => {
    const result: OutputType = item.values.reduce((acc, curr) => {
      acc[curr.criteria] = curr.percentage;
      acc["criteria"] = item.criteria;
      return acc;
    }, {} as OutputType);

    return result;
  });
};

export const getDataKeys = (inputArray: ChartDataProps[]) => {
  if (isEmpty(inputArray)) return [];

  if (isEmpty(inputArray[0]?.values)) return [];

  return inputArray[0].values.map((item: ChartStatisticValue) => item.criteria);
};

const renderLegendsArea = (criteria: string, fill: string) => {
  switch (criteria) {
    case "Scorm & xAPI":
      return { icon: <Circle fill={fill} />, label: "Scorm & xAPI" };
    case "Tài liệu tham khảo":
      return { icon: <Diamond fill={fill} />, label: "Tài liệu tham khảo" };
    case "Bài tập/Bài kiểm tra/Bài thi":
      return {
        icon: <Square fill={fill} />,
        label: "Bài tập/bài thi/bài kiểm tra",
      };
    case "Bài giảng":
      return { icon: <Triangle fill={fill} />, label: "Bài giảng" };
    case "Khóa học":
      return { icon: <Circle fill={fill} />, label: "Khóa học" };
    case "Đa phương tiện":
      return { icon: <Circle fill={fill} />, label: "Đa phương tiện" };
    default:
      return {};
  }
};

const renderLegendsCircle = (criteria: string, fill: string) => {
  switch (criteria) {
    case "1 sao":
      return { icon: <Circle fill={fill} />, label: "1 sao" };
    case "2 sao":
      return { icon: <Circle fill={fill} />, label: "2 sao" };
    case "3 sao":
      return { icon: <Circle fill={fill} />, label: "3 sao" };
    case "4 sao":
      return { icon: <Circle fill={fill} />, label: "4 sao" };
    case "5 sao":
      return { icon: <Circle fill={fill} />, label: "5 sao" };
    default:
      return {};
  }
};

export const getLegends = (
  criteria: string[],
  colors: string[],
  isCircle?: boolean
) => {
  if (isEmpty(criteria)) return [];

  if (isCircle)
    return criteria.map((item: string, index: number) =>
      renderLegendsCircle(item, colors[index])
    );

  return criteria.map((item: string, index: number) =>
    renderLegendsArea(item, colors[index])
  );
};

export const getDataKeyBarChart = (
  data: StatisticBarChartResponseData[] | undefined
) => {
  if (!data || data?.length === 0) return [];

  const criteria = data[0];
  return Object.keys(criteria);
};

export const getPercent = (curValue: number, parent: any) => {
  if (isNumber(parent) && parent > 0) {
    return toNumber(((toNumber(curValue) / parent) * 100).toFixed(2));
  }

  const total = toNumber(
    parent["one"] +
      parent["two"] +
      parent["three"] +
      parent["four"] +
      parent["five"]
  );

  if (total === 0) return 0;

  return toNumber(((toNumber(curValue) / total) * 100).toFixed(2));
};

export function getDateFromNow(value: number): Date | undefined {
  const now = new Date();

  switch (value) {
    case 1:
      now.setDate(now.getDate() - 7);
      break;
    case 2:
      now.setMonth(now.getMonth() - 1);
      break;
    case 3:
      now.setMonth(now.getMonth() - 3);
      break;
    default:
      return undefined;
  }

  return now;
}
