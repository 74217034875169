import React from "react";
import "./style.scss";
import StatisticIntro from "../../components/statistic-intro/StatisticIntro";
import ResponsiveList from "../../components/list/ResponsiveList";
import { Button, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";

function SystemInfo() {
  const navigate = useNavigate();
  const missionData = [
    {
      content: (
        <div className="mission-card">
          <div className="mission-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6.09436 11.2288C6.03221 10.8282 5.99996 10.4179 5.99996 10C5.99996 5.58172 9.60525 2 14.0526 2C18.4999 2 22.1052 5.58172 22.1052 10C22.1052 10.9981 21.9213 11.9535 21.5852 12.8345C21.5154 13.0175 21.4804 13.109 21.4646 13.1804C21.4489 13.2512 21.4428 13.301 21.4411 13.3735C21.4394 13.4466 21.4493 13.5272 21.4692 13.6883L21.8717 16.9585C21.9153 17.3125 21.9371 17.4895 21.8782 17.6182C21.8266 17.731 21.735 17.8205 21.6211 17.8695C21.4911 17.9254 21.3146 17.8995 20.9617 17.8478L17.7765 17.3809C17.6101 17.3565 17.527 17.3443 17.4512 17.3448C17.3763 17.3452 17.3245 17.3507 17.2511 17.3661C17.177 17.3817 17.0823 17.4172 16.893 17.4881C16.0097 17.819 15.0524 18 14.0526 18C13.6344 18 13.2237 17.9683 12.8227 17.9073M7.63158 22C10.5965 22 13 19.5376 13 16.5C13 13.4624 10.5965 11 7.63158 11C4.66668 11 2.26316 13.4624 2.26316 16.5C2.26316 17.1106 2.36028 17.6979 2.53955 18.2467C2.61533 18.4787 2.65322 18.5947 2.66566 18.6739C2.67864 18.7567 2.68091 18.8031 2.67608 18.8867C2.67145 18.9668 2.65141 19.0573 2.61134 19.2383L2 22L4.9948 21.591C5.15827 21.5687 5.24 21.5575 5.31137 21.558C5.38652 21.5585 5.42641 21.5626 5.50011 21.5773C5.5701 21.5912 5.67416 21.6279 5.88227 21.7014C6.43059 21.8949 7.01911 22 7.63158 22Z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <p className="font-size-20 mt-3 mb-2">
            <b>Chia sẻ kiến thức</b>
          </p>
          <p className="font-size-16 text-secondary text-center line-height-24">
            Lorem ipsum dolor sit amet consectetur. Tellus ac cursus volutpat
            vel. Commodo tincidunt urna quam eu ut vel quis at ut.{" "}
          </p>
        </div>
      ),
    },
    {
      content: (
        <div className="mission-card">
          <div className="mission-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M13 2L4.09344 12.6879C3.74463 13.1064 3.57023 13.3157 3.56756 13.4925C3.56524 13.6461 3.63372 13.7923 3.75324 13.8889C3.89073 14 4.16316 14 4.70802 14H12L11 22L19.9065 11.3121C20.2553 10.8936 20.4297 10.6843 20.4324 10.5075C20.4347 10.3539 20.3663 10.2077 20.2467 10.1111C20.1092 10 19.8368 10 19.292 10H12L13 2Z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <p className="font-size-20 mt-3 mb-2">
            <b>Bắt kịp xu hướng mới</b>
          </p>
          <p className="font-size-16 text-secondary text-center line-height-24">
            Lorem ipsum dolor sit amet consectetur. Tellus ac cursus volutpat
            vel. Commodo tincidunt urna quam eu ut vel quis at ut.{" "}
          </p>
        </div>
      ),
    },
    {
      content: (
        <div className="mission-card">
          <div className="mission-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20.5 7.27442L12 11.9966M12 11.9966L3.49997 7.27442M12 11.9966L12 21.4967M21 16.0552V7.93811C21 7.59547 21 7.42415 20.9495 7.27135C20.9049 7.13617 20.8318 7.01209 20.7354 6.9074C20.6263 6.78906 20.4766 6.70586 20.177 6.53946L12.777 2.42835C12.4934 2.27079 12.3516 2.19201 12.2015 2.16113C12.0685 2.13379 11.9315 2.13379 11.7986 2.16113C11.6484 2.19201 11.5066 2.27079 11.223 2.42835L3.82297 6.53946C3.52345 6.70586 3.37369 6.78907 3.26463 6.9074C3.16816 7.01209 3.09515 7.13617 3.05048 7.27135C3 7.42415 3 7.59547 3 7.93811V16.0552C3 16.3979 3 16.5692 3.05048 16.722C3.09515 16.8572 3.16816 16.9812 3.26463 17.0859C3.37369 17.2043 3.52345 17.2875 3.82297 17.4539L11.223 21.565C11.5066 21.7225 11.6484 21.8013 11.7986 21.8322C11.9315 21.8595 12.0685 21.8595 12.2015 21.8322C12.3516 21.8013 12.4934 21.7225 12.777 21.565L20.177 17.4539C20.4766 17.2875 20.6263 17.2043 20.7354 17.0859C20.8318 16.9812 20.9049 16.8572 20.9495 16.722C21 16.5692 21 16.3979 21 16.0552Z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <p className="font-size-20 mt-3 mb-2">
            <b>Quản lý học tập dễ dàng</b>
          </p>
          <p className="font-size-16 text-secondary text-center line-height-24">
            Lorem ipsum dolor sit amet consectetur. Tellus ac cursus volutpat
            vel. Commodo tincidunt urna quam eu ut vel quis at ut.{" "}
          </p>
        </div>
      ),
    },
    {
      content: (
        <div className="mission-card">
          <div className="mission-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 21L11.8999 20.8499C11.2053 19.808 10.858 19.287 10.3991 18.9098C9.99286 18.5759 9.52476 18.3254 9.02161 18.1726C8.45325 18 7.82711 18 6.57482 18H5.2C4.07989 18 3.51984 18 3.09202 17.782C2.71569 17.5903 2.40973 17.2843 2.21799 16.908C2 16.4802 2 15.9201 2 14.8V6.2C2 5.07989 2 4.51984 2.21799 4.09202C2.40973 3.71569 2.71569 3.40973 3.09202 3.21799C3.51984 3 4.07989 3 5.2 3H5.6C7.84021 3 8.96031 3 9.81596 3.43597C10.5686 3.81947 11.1805 4.43139 11.564 5.18404C12 6.03968 12 7.15979 12 9.4M12 21V9.4M12 21L12.1001 20.8499C12.7947 19.808 13.142 19.287 13.6009 18.9098C14.0071 18.5759 14.4752 18.3254 14.9784 18.1726C15.5467 18 16.1729 18 17.4252 18H18.8C19.9201 18 20.4802 18 20.908 17.782C21.2843 17.5903 21.5903 17.2843 21.782 16.908C22 16.4802 22 15.9201 22 14.8V6.2C22 5.07989 22 4.51984 21.782 4.09202C21.5903 3.71569 21.2843 3.40973 20.908 3.21799C20.4802 3 19.9201 3 18.8 3H18.4C16.1598 3 15.0397 3 14.184 3.43597C13.4314 3.81947 12.8195 4.43139 12.436 5.18404C12 6.03968 12 7.15979 12 9.4"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <p className="font-size-20 mt-3 mb-2">
            <b>Đa dạng khoá học</b>
          </p>
          <p className="font-size-16 text-secondary text-center line-height-24">
            Lorem ipsum dolor sit amet consectetur. Tellus ac cursus volutpat
            vel. Commodo tincidunt urna quam eu ut vel quis at ut.{" "}
          </p>
        </div>
      ),
    },
    {
      content: (
        <div className="mission-card">
          <div className="mission-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M22 7L14.1314 14.8686C13.7354 15.2646 13.5373 15.4627 13.309 15.5368C13.1082 15.6021 12.8918 15.6021 12.691 15.5368C12.4627 15.4627 12.2646 15.2646 11.8686 14.8686L9.13137 12.1314C8.73535 11.7354 8.53735 11.5373 8.30902 11.4632C8.10817 11.3979 7.89183 11.3979 7.69098 11.4632C7.46265 11.5373 7.26465 11.7354 6.86863 12.1314L2 17M22 7H15M22 7V14"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <p className="font-size-20 mt-3 mb-2">
            <b>Phát triển kỹ năng bản thân</b>
          </p>
          <p className="font-size-16 text-secondary text-center line-height-24">
            Lorem ipsum dolor sit amet consectetur. Tellus ac cursus volutpat
            vel. Commodo tincidunt urna quam eu ut vel quis at ut.{" "}
          </p>
        </div>
      ),
    },
    {
      content: (
        <div className="mission-card">
          <div className="mission-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M2.66113 18.3376L7.25499 13.7437M18.2175 2.78125C21.9275 6.49123 21.9275 12.5063 18.2175 16.2163C14.5075 19.9263 8.49243 19.9263 4.78245 16.2163M17 21.9988H6.99997M12 21.9988V18.9988M17.5 9.49876C17.5 12.8125 14.8137 15.4988 11.5 15.4988C8.18626 15.4988 5.49997 12.8125 5.49997 9.49876C5.49997 6.18506 8.18626 3.49876 11.5 3.49876C14.8137 3.49876 17.5 6.18506 17.5 9.49876Z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <p className="font-size-20 mt-3 mb-2">
            <b>Học tập mọi lúc mọi nơi</b>
          </p>
          <p className="font-size-16 text-secondary text-center line-height-24">
            Lorem ipsum dolor sit amet consectetur. Tellus ac cursus volutpat
            vel. Commodo tincidunt urna quam eu ut vel quis at ut.{" "}
          </p>
        </div>
      ),
    },
  ];
  
  const joinPlatform = () => {
    navigate(`/${routesConfig.joinPlatform}`)
  }

  return (
    <div>
      <section className="system-banner">
        <p className="text-primary font-size-18 mb-2">
          <b> Chào mừng bạn đến với hệ thống Mooc</b>
        </p>
        <div className="content-wrap flex justify-space-between gap-32">
          <p className="font-size-48 w-50 line-height-60">
            <b> Hệ thống phát triển nền tảng cung cấp khoá học trực tuyến</b>
          </p>
          <p className="text-secondary font-size-20 w-50 line-height-30">
            Mooc (Massive open online course) là hệ thống phát triển nền tảng
            cung cấp khoá học trực tuyến đại chúng mở. Học tập thông qua
            Internet không giới hạn, giáo dục từ xa. Khóa học trực tuyến đại
            chúng mở có tiềm năng rất lớn đối với giáo dục, đặc biệt là giáo dục
            đại học
          </p>
        </div>
      </section>
      <section className="content-layout mt-2 mb-5">
        <StatisticIntro />
      </section>
      <section className="mission">
        <p className="text-primary font-size-20 text-center mb-2">
          <b>Sứ mệnh và tầm nhìn</b>
        </p>
        <h3 className="font-size-60 text-center mb-5">
          Giáo dục từ xa - Xu hướng giáo dục mới
        </h3>
        <div className="mission-list">
          <ResponsiveList
            grid={{
              gutter: 32,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 3,
            }}
            data={missionData}
          />
        </div>
      </section>
      <section className="content-layout mt-2 mb-5">
        <div className="course-section flex align-center justify-space-between">
          <div className="course-left w-50">
            <p className="text-primary mb-2">
              <b>Danh sách khoá học</b>
            </p>
            <span className="font-size-36 mb-2 line-height-44">
              <b>Cùng tìm hiểu những khoá học phù hợp với bạn nhé!</b>
            </span>
            <p className="text-secondary font-size-20 mt-2 line-height-30">
              Lorem ipsum dolor sit amet consectetur. In eget nec et et vitae.
              Fames eu consequat elementum varius at euismod. Lorem ipsum dolor
              sit amet consectetur. In eget nec et et vitae. Fames eu consequat
              elementum varius at euismod.
            </p>
            <div className="flex gap-12 mt-3">
              <Button
                className="height-48"
                onClick={() => navigate(routesConfig.courseTopics)}
              >
                <b>Danh sách chủ đề</b>
              </Button>{" "}
              <Button
                className="height-48 btn-primary"
                onClick={() => navigate(routesConfig.courseInfo)}
              >
                Danh sách khoá học
              </Button>
            </div>
          </div>
          <div className="course-right w-50">
            <div className="right-top w-full-100 text-center">
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/person-5.png`}
                alt="person"
              />
              <img
                className="ml-2"
                src={`${process.env.PUBLIC_URL}/assets/img/person-1.png`}
                alt="person"
              />
            </div>
            <div className="right-bot w-full-100 mt-2">
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/person-2.png`}
                alt="person"
              />
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/person-3.png`}
                alt="person"
              />
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/person-4.png`}
                alt="person"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="partner-section flex">
        <div className="pn-left">
          <p className="font-size-36 line-height-44 mb-2">
            <b>Trở thành đối tác phát triển khoá học</b>
          </p>
          <p className="text-secondary font-size-20">
            Cùng Mooc xây dựng phát triển hệ thống học trực tuyến
          </p>
        </div>
        <div className="pn-right">
          <div className="flex gap-16">
            <Input placeholder="Địa chỉ Email của bạn" className="height-44" />{" "}
            <Button className="btn-primary height-44" onClick={joinPlatform}>Đăng ký</Button>
          </div>
          <p className="text-secondary font-size-16 mt-1">
            Xem thêm tại<Link to={"/"}> Chính sách riêng tư.</Link>
          </p>
        </div>
      </section>
    </div>
  );
}

export default SystemInfo;
