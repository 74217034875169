import React, { useEffect, useRef, useState } from "react";
import { Button, Flex, Spin, Tooltip } from "antd";
import { isEmpty, toNumber } from "lodash";

import {
  ExportIcon,
  HoursGlassIcon,
  MicroIcon,
  QuestionMarkIcon,
} from "../../../../../../../components/icons";
import EmptyComponent from "../../../../../../../components/empty";
import { QuestionProps, QuizProps } from "../../../../../../../types/course";
import { FormInstance } from "antd/lib";
import {
  JSON_SUB_TYPE,
  MAX_QUIZ_FILE_UPLOAD_SIZE,
  QuestionTypeEnum,
} from "../../../../../../../constants/course";
import { uploadFile } from "../../../../../../../service/my-course/my-course-service";
import Countdown from "../../../../../../../components/countdown/CountDown";
import useKeyword from "../../../../../../../hooks/useKeyword";
import { startSingleQuiz } from "../../../../../../../service/learning-course";
import { Link } from "react-router-dom";
import AudioPlayer from "../../../../../../../components/audio-player/AudioPlayer";

interface Mp3Props extends QuestionProps {
  data?: QuizProps;
  form?: FormInstance<any>;
  onChange?: (value: any) => void;
}

function Mp3({
  data,
  disabled,
  initialData,
  pause,
  configs,
  onFinish = () => {},
  onChange = () => {},
}: Mp3Props) {
  const initialTime =
    data?.timeToCompleted || configs?.actionConfig?.timeForQuiz || 0;
  const maxSize = data?.settingGeneral?.fileCapacity
    ? data?.settingGeneral?.fileCapacity * 1024 * 1024
    : MAX_QUIZ_FILE_UPLOAD_SIZE;
  // audio file data
  const [audioURL, setAudioURL] = useState<string>("");
  const [uploading, setUploading] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>();

  // control
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const [mode, setMode] = useState<"begin" | "recording" | "view" | "error">(
    "begin"
  );
  const [loading, setLoading] = useState<boolean>(false);

  const blockId = toNumber(useKeyword("blockId"));
  const currentScreen = useKeyword("screen");
  const isSequentiallyQuiz = configs?.generalConfig?.sequentiallyQuiz;
  const [canStart, setCanStart] = useState<boolean>(false);

  useEffect(() => {
    // Xử lý câu hỏi có đếm thời gian riêng
    if (currentScreen === "train") {
      if (!isEmpty(data) && isSequentiallyQuiz && initialTime) {
        const startSingle = async () => {
          try {
            setLoading(true);
            await startSingleQuiz({
              blockId: blockId,
              quizId: data?.id,
            });
            setCanStart(true);
          } catch (err: any) {
          } finally {
            setLoading(false);
          }
        };
        startSingle();
      }
    }
  }, [isSequentiallyQuiz]);

  const startRecording = async () => {
    setMode("recording");
    try {
      const start = new Date();
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        uploadAudio(audioBlob);
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioURL(audioUrl);
        audioChunksRef.current = [];
        clearInterval(checkSizeInterval); // Dừng interval khi dừng ghi âm
        setDuration(
          Math.round((new Date().getTime() - start.getTime()) / 1000)
        );
      };

      // Đo kích thước dữ liệu mỗi giây
      const checkSizeInterval = setInterval(() => {
        const currentSize = audioChunksRef.current.reduce(
          (total, chunk) => total + chunk.size,
          0
        );

        if (currentSize >= maxSize) {
          stopRecording();
        }
      }, 500); // kiểm tra mỗi giây (0.5 s)

      mediaRecorder.start(1000); // Bắt đầu ghi âm với timeslice là 1000ms (1 giây)
    } catch (err) {
      console.error("Error accessing the microphone", err);
      setMode("error");
    }
  };

  const uploadAudio = async (audioBlob: Blob | null) => {
    if (!audioBlob) return;

    const formData = new FormData();
    formData.append("file", audioBlob);

    try {
      setUploading(true);
      const response = await uploadFile(formData);

      onChange({
        [`recorderMp3-${data?.id}-${QuestionTypeEnum.MP3}-${JSON_SUB_TYPE.ContentRequest}`]:
          response?.data?.filePath,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploading(false);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();

      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
    }
    setMode("view");
  };

  const handleDeleteRecording = () => {
    setAudioURL("");
    setMode("begin");
    onChange({
      [`recorderMp3-${data?.id}-${QuestionTypeEnum.MP3}-${JSON_SUB_TYPE.ContentRequest}`]:
        null,
    });
  };

  useEffect(() => {
    if (!isEmpty(initialData?.answer[0])) {
      const getFile = async () => {
        setAudioURL(initialData?.answer[0]);
        setMode("view");
      };
      getFile();
    }
  }, []);

  useEffect(() => {
    return () => stopRecording();
  }, []);

  const renderContent = () => {
    switch (mode) {
      case "begin":
        return (
          <div className="begin-screen">
            <Button
              disabled={disabled}
              className="record-item mt-2"
              onClick={startRecording}
            >
              <div className="center">
                <MicroIcon />
                <span className="font-size-14 font-weight-6 ml-1">
                  Bắt đầu ghi âm câu trả lời của bạn
                </span>
              </div>
            </Button>
          </div>
        );
      case "recording":
        return (
          <div className="micro-recording-screen center flex-column mt-3">
            <div className="record-micro micro-recording-animation">
              <MicroIcon />
            </div>

            <Tooltip title="Kết thúc">
              <Button
                className="mt-2 btn-primary btn-action"
                onClick={stopRecording}
              >
                <ExportIcon />
              </Button>
            </Tooltip>
          </div>
        );
      case "view":
        return (
          <div className="view-screen flex gap-32 align-center">
            <div className="w-full">
              <AudioPlayer src={audioURL} duration={duration} />
            </div>

            <Button
              disabled={disabled}
              className="font-size-14 text-primary font-weight-6 pointer"
              onClick={startRecording}
            >
              Ghi âm lại
            </Button>
            <Button
              disabled={disabled}
              className="text-danger font-size-14 font-weight-6 pointer"
              onClick={handleDeleteRecording}
            >
              Xoá
            </Button>
          </div>
        );
      case "error":
        return (
          <div className="w-full center">
            <EmptyComponent description="Không tìm thấy thiết bị được yêu cầu" />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div>
      <Spin spinning={uploading || loading}>
        <div className="question-header mb-3">
          {configs?.actionConfig?.timeForQuiz &&
          configs?.generalConfig?.sequentiallyQuiz ? (
            <div className="question-time flex align-center gap-8">
              <HoursGlassIcon />
              <div className="text-primary font-size-14 line-height-20 font-weight-6 flex align-center gap-8">
                Trả lời câu hỏi trong:
                <Countdown
                  pause={pause}
                  initialTime={canStart ? toNumber(initialTime) : 0}
                  onFinish={() => {
                    if (!uploading) {
                      onFinish();
                      stopRecording();
                    }
                  }}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="question-title">
            <Flex
              justify="space-between"
              align="center"
              className="font-size-16 line-height-24 font-weight-6"
            >
              {data?.settingGeneral?.requiredAnswer ? (
                <span className="text-danger">*</span>
              ) : (
                ""
              )}
              <div className="flex-1 ml-1">
                Câu hỏi {toNumber(data?.index) + 1}:{" "}
              </div>
              {configs?.generalConfig?.showQuizScore && (
                <span className="total-point font-size-14">
                  {`(${data?.totalPoints || 0} điểm)`}
                </span>
              )}
            </Flex>
            <Flex align="flex-start" gap={8}>
              <div
                className="font-size-16 line-height-24 font-weight-6"
                dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
              ></div>
              {data?.settingHint?.isDisplayInstruction && data?.isShowHint ? (
                <Tooltip
                  placement="bottom"
                  title={
                    <div>
                      {data?.settingHint?.content ? (
                        <p className="text-14 font-weight-5">
                          Hướng dẫn: {data?.settingHint?.content}
                        </p>
                      ) : (
                        "Không có hướng dẫn"
                      )}

                      {data?.settingHint?.schema ? (
                        <p className="font-size-14 mt-2">
                          Đường dẫn tham khảo:{" "}
                          <Link
                            target="_blank"
                            to={`${data?.settingHint?.uri}${data?.settingHint?.schema}`}
                          >
                            {`${data?.settingHint?.uri}${data?.settingHint?.schema}`}
                          </Link>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  }
                >
                  <span className="quest-icon text-secondary w-max-content">
                    <QuestionMarkIcon />
                  </span>
                </Tooltip>
              ) : (
                ""
              )}
            </Flex>
          </div>
        </div>
        {renderContent()}
      </Spin>
    </div>
  );
}

export default Mp3;
