import { Button, Modal } from "antd";
import "./ConfirmReviewModal.scss";
import { globalStore, useCourseDetailStore } from "../../../stores/stores";
import { notifySuccess } from "../../../utils/notification";
import { deleteCurriculumReview } from "../../../service/curriculum/curriculum-service";
import { deleteCourseComment } from "../../../service/courses";
import { deleteUniversityReviewReview } from "../../../service/training-school";

type Props = {
  open: boolean;
  title?: string;
  mode?: string;
  handleCancel?: (value: any) => void;
  handleOk?: () => void;
  desc: any;
  deleteApi?: any;
  id?: number;
  isDelete?: boolean;
};

const ConfirmReviewModal = ({
  open,
  isDelete = false,
  handleCancel,
  desc,
  handleOk,
  id,
}: Props) => {
  const { screen } = useCourseDetailStore();
  const { schoolId, setReload, reload } = globalStore();
  const deleteApiUrl = () => {
    switch (screen) {
      case "curriculum":
        return deleteCurriculumReview;
      case "course":
        return deleteCourseComment;
      case "school":
        return deleteUniversityReviewReview;
      default:
        return null;
    }
  };

  const apiUrl = deleteApiUrl();

  const handleDelete = async () => {
    const paramData = {
      id,
      ...(screen === "school" && { enterpriseUuid: schoolId }),
    };
    try {
      const res = await (apiUrl
        ? apiUrl(paramData)
        : deleteUniversityReviewReview(paramData));
      if (res.status === 200) {
        handleCancel;
        setReload(!reload);
        notifySuccess("Xoá bình luận thành công");
      }
    } catch (error) {
      console.error("Failed to delete review:", error);
    }
  };

  return (
    <Modal
      title={
        <div>
          <img
            className="confirm-comment-modal-icon"
            src={`${process.env.PUBLIC_URL}/assets/icons/${isDelete ? "delete-icon" : "check-circle"}.svg`}
            alt={isDelete ? "Delete" : "Confirm"}
          />
          <div className="bg-icon-circle" />
        </div>
      }
      open={open}
      className="confirm-comment-modal"
      maskClosable={false}
      width={"385px"}
      onCancel={handleCancel}
      centered
      footer={
        isDelete ? (
          <div className="flex">
            <Button
              onClick={handleCancel}
              className="btn-primary p-2 height-44 mt-3 mb-2 radius-8 w-full-100 mr-4 button-default"
            >
              Huỷ
            </Button>
            <Button
              onClick={handleDelete}
              className="btn-primary ml-1 p-2 height-44 mt-3 mb-2 radius-8 w-full-100 button-danger"
            >
              Xoá
            </Button>
          </div>
        ) : (
          <>
            <div className="hr-tag"></div>
            <Button onClick={handleOk} className="button-confirm">
              Xác nhận
            </Button>
          </>
        )
      }
    >
      <div className="mt-2">
        <h1 className="fs-18">{desc.title}</h1>
        <p>{desc.notice}</p>
      </div>
    </Modal>
  );
};

export default ConfirmReviewModal;
