import React, { useState, useEffect } from "react";

interface CountDownProps {
  initialTime: number;
  pause?: boolean;
  format?: "hh:mm:ss" | "mm:ss";
  onChange?: (time: number) => void;
  onFinish?: () => void;
}

const Countdown = ({
  initialTime,
  format,
  pause = false,
  onChange = () => {},
  onFinish = () => {},
}: CountDownProps) => {
  const [timeLeft, setTimeLeft] = useState(-1);

  useEffect(() => {
    if (initialTime) setTimeLeft(initialTime);
  }, [initialTime]);

  useEffect(() => {
    if (!pause && timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
      onChange(timeLeft);
      return () => clearInterval(timerId);
    } else if (timeLeft === 0) onFinish();
  }, [timeLeft, pause]);

  const formatTime = (seconds: number) => {
    if (seconds <= 0) return "00:00";

    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    const hrsDisplay = hrs > 0 ? String(hrs).padStart(2, "0") : "";
    const minsDisplay = String(mins).padStart(2, "0");
    const secsDisplay = String(secs).padStart(2, "0");

    return format === "hh:mm:ss"
      ? `${hrsDisplay || "00"}:${minsDisplay}:${secsDisplay}`
      : `${hrs > 0 ? hrsDisplay + ":" : ""}${minsDisplay}:${secsDisplay}`;
  };

  return <span>{formatTime(timeLeft)}</span>;
};

export default Countdown;
