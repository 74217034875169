import { LeftOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Button, Flex, Modal, Typography } from "antd";
import { useRef, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import "./RecordMinimize.scss";
type RecordMinimizeProps = {
  isExpand: boolean;
  handleExpand: VoidFunction;
  minimizeText: string;
};
export default function RecordMinimize({
  isExpand,
  handleExpand,
  minimizeText,
}: RecordMinimizeProps) {
  const draggleRef = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  return (
    <div id="record-minimize">
      <Modal
        title={null}
        closeIcon={null}
        style={{
          top: "62%",
          right: 15,
          padding: 0,
        }}
        className="fixed z-50"
        maskClosable={false}
        rootClassName="record-minimize"
        width={"fit-content"}
        mask={false}
        wrapClassName="!static"
        open={!isExpand}
        footer={null}
        modalRender={(modal) => (
          <Draggable
            disabled={false}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <Button
          hidden={isExpand}
          type="default"
          className="!transition-none border border-[#FDA29B] rounded-md p-4 h-auto"
        >
          <Flex gap={12}>
            <LeftOutlined
              onClick={handleExpand}
              className="border border-transparent hover:border-[#FDA29B] p-1 rounded-full transition-all"
              style={{ color: "#D92D20" }}
            />
            <div>
              <div className="relative top-1 flex items-center justify-center pointer-events-none">
                <VideoCameraOutlined style={{ color: "#D92D20" }} />
                <span className=" absolute inset-0 animate-signal border border-[#D92D20] rounded-full"></span>
              </div>
            </div>
            <Typography.Text className="text-[#D92D20]">
              {minimizeText || "Đang ghi hình"}
            </Typography.Text>
          </Flex>
        </Button>
      </Modal>
    </div>
  );
}
