import React, { useEffect, useState } from "react";
import TableReport from "../../components/TableReport";
import { Table, TableProps } from "antd";
import {
  BaseChartProps,
  ChartDataProps,
  StatisticChartPayload,
  StatisticChartResponseData,
} from "../../../../../types/statistic";
import { isEmpty } from "lodash";
import { fetchUnitDiscuss } from "../../../../../service/statistics";
import dayjs from "dayjs";
import { mapToDataChart } from "../../components/utils";

interface DataType {
  key: string;
  timeCreated: number;
  document: number;
  exam: number;
  scorm: number;
  multimedia: number;
}

function UnitDiscuss({ filterValues }: BaseChartProps) {
  const [unitDiscussValues, setUnitDiscussValues] =
    useState<StatisticChartResponseData>();
  const dataTable = mapToDataChart(
    unitDiscussValues?.statistic as ChartDataProps[]
  ).map((item: any, index) => ({
    key: `${index}`,
    timeCreated: item?.criteria,
    document: item["Tài liệu tham khảo"],
    exam: item["Bài tập/Bài kiểm tra/Bài thi"],
    scorm: item["Scorm & xAPI"],
    multimedia: item["Đa phương tiện"],
  }));

  useEffect(() => {
    
    if (!isEmpty(filterValues)) {
      getUnitDiscuss({
        timeUnit: filterValues?.unit || "day",
        from: filterValues?.date?.length
          ? dayjs(filterValues?.date[0]).toISOString()
          : undefined,
        to: filterValues?.date?.length
          ? dayjs(filterValues?.date[1]).toISOString()
          : undefined,
        courseIds: filterValues?.course,
        courseLevelIds: filterValues?.level,
        industryGroupIds: filterValues?.department,
      });
    }
  }, [filterValues]);

  const getUnitDiscuss = async (payload: StatisticChartPayload) => {
    const res = await fetchUnitDiscuss(payload);
    setUnitDiscussValues(res.data?.data);
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Thời gian tạo",
      dataIndex: "timeCreated",
      key: "timeCreated",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Đa phương tiện",
      dataIndex: "multimedia",
      key: "multimedia",
    },
    {
      title: "Tài liệu tham khảo",
      dataIndex: "document",
      key: "document",
    },
    {
      title: "Bài tập/Bài kiểm tra/Bài thi",
      key: "exam",
      dataIndex: "exam",
    },
    {
      title: "Scorm & xAPI",
      key: "scorm",
      dataIndex: "scorm",
    },
    {
      title: "Tổng",
      key: "total",
      dataIndex: "total",
      render(value, record, index) {
        const { document = 0, exam = 0, multimedia = 0, scorm = 0 } = record;

        return (
          <span className="font-size-14 font-weight-5 text-primary">
            {document + exam + multimedia + scorm}
          </span>
        );
      },
    },
  ];

  return (
    <TableReport
      data={dataTable}
      columns={columns}
      title="Theo thời gian từng loại học liệu"
      onChangeFilter={(v) => console.log(v)}
      summary={(pageData) => {
        let totalMaterial = 0;
        let totalDocument = 0;
        let totalExam = 0;
        let totalScorm = 0;

        pageData.forEach(
          ({ document = 0, exam = 0, multimedia = 0, scorm = 0 }) => {
            totalMaterial += multimedia;
            totalDocument += document;
            totalExam += exam;
            totalScorm += scorm;
          }
        );

        return (
          <Table.Summary.Row className="text-primary font-weight-5 sticky-summary-row">
            <Table.Summary.Cell index={0}>Tổng</Table.Summary.Cell>
            <Table.Summary.Cell index={1}>{totalMaterial}</Table.Summary.Cell>
            <Table.Summary.Cell index={2}>{totalDocument}</Table.Summary.Cell>
            <Table.Summary.Cell index={3}>{totalExam}</Table.Summary.Cell>
            <Table.Summary.Cell index={4}>{totalScorm}</Table.Summary.Cell>
            <Table.Summary.Cell index={5}>
              {totalMaterial + totalDocument + totalExam + totalScorm}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
    />
  );
}

export default UnitDiscuss;
