import React from "react";
import {
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  ComposedChart,
} from "recharts";
import LegendCustom from "./components/LegendCustom";
import { ChartProps } from "../../types/chart";
import { Flex, Select } from "antd";
import { Circle } from "./components/icons";
import { renderTotal } from "./components/ultis";
import EmptyComponent from "../empty";
import { isEmpty } from "lodash";
import { DEFAULT_MODULE_GROUP } from "../../constants/statistics";

interface LineChartProps extends ChartProps {
  borderRadius?: number;
  yDescription?: string;
  lineName?: string;
  lineKey?: string;
}
function LineChart({
  title = "",
  legends = [],
  total,
  data,
  showFilter = false,
  filterOptions = [],
  defaultSelectedValue = DEFAULT_MODULE_GROUP,
  placeholder = "",
  yDescription,
  lineName,
  stroke = "#7A5AF8",
  xDataKey = "",
  lineKey = "",
  onChange = () => {},
}: LineChartProps) {
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            background: "#fff",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          <p className="text-secondary">{payload[0]?.name}</p>
          <Flex>
            <Circle fill={stroke} /> <span>{payload[0]?.value}</span>
          </Flex>
        </div>
      );
    }

    return null;
  };
  return (
    <div className="wrapper-chart">
      <Flex justify="space-between">
        <div className="chart-title font-weight-5 font-size-18">{title}</div>
        {showFilter && (
          <Select
            defaultValue={defaultSelectedValue}
            className="h-40 w-filter"
            placeholder={placeholder}
            onChange={onChange}
            options={filterOptions}
          />
        )}
      </Flex>
      <div className="break-line"></div>
      {isEmpty(data) ? (
        <div className="h-default">
          <EmptyComponent description="Không có kết quả" />
        </div>
      ) : (
        <div>
          {renderTotal(total)}
          <Flex>
            <div className="center">
              <div className="font-weight-5 font-size-12 y-description">
                {yDescription}
              </div>
            </div>
            <ResponsiveContainer width="100%" height={400}>
              <ComposedChart
                data={data}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 0" vertical={false} />
                <XAxis dataKey={xDataKey} />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />

                <Line
                  type="monotone"
                  dataKey={lineKey}
                  stroke={stroke}
                  strokeWidth={2}
                  name={lineName}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Flex>

          <LegendCustom items={legends} />
        </div>
      )}
    </div>
  );
}

export default LineChart;
