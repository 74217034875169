import { Tabs } from "antd";
import { TabsProps } from "antd";
import React from "react";
import "./SecondaryTab.scss";
interface TabItem {
  key: string;
  label: React.ReactNode;
  children: React.ReactNode;
}

interface Props {
  tabItems: TabItem[];
  onChange?: (key: string) => void;
  tabBarExtraContent?: React.ReactNode;
  commonContent?: React.ReactNode;
}

const SecondaryTab: React.FC<Props> = ({
  tabItems,
  onChange,
  tabBarExtraContent,
  commonContent,
}) => {
  const items: TabsProps["items"] = tabItems.map((item) => ({
    key: item.key,
    label: item.label,
    children: (
      <>
        {commonContent}
        {item.children}
      </>
    ),
  }));

  return (
    <div className="secondary-tab">
      <Tabs
        defaultActiveKey="1"
        onChange={onChange}
        items={items}
        tabBarExtraContent={tabBarExtraContent}
      />
    </div>
  );
};

export default SecondaryTab;
