import React, { useEffect, useState } from "react";
import TableReport from "../../components/TableReport";
import { Table, TableProps } from "antd";
import {
  BaseChartProps,
  StatisticBarChartResponseData,
  StatisticChartPayload,
} from "../../../../../types/statistic";
import { fetchRateUnitReviewAndAccess } from "../../../../../service/statistics";
import { isEmpty } from "lodash";
import dayjs from "dayjs";

interface DataType {
  key: string;
  createdDate: number;
  trafficVolume: number;
  evaluationCount: number;
}

function EvaluateByTime({ filterValues }: BaseChartProps) {
  const [data, setData] = useState<StatisticBarChartResponseData[]>();

  const dataTable = data?.map((item: any, index) => ({
    key: `${index}`,
    createdDate: item?.criteria,
    trafficVolume: item["columnValue"],
    evaluationCount: item["lineValue"],
  }));

  useEffect(() => {
    
    if (!isEmpty(filterValues)) {
      getData({
        timeUnit: filterValues?.unit || "day",
        from: filterValues?.date?.length
          ? dayjs(filterValues?.date[0]).toISOString()
          : undefined,
        to: filterValues?.date?.length
          ? dayjs(filterValues?.date[1]).toISOString()
          : undefined,
        courseIds: filterValues?.course,
        courseLevelIds: filterValues?.level,
        industryGroupIds: filterValues?.department,
      });
    }
  }, [filterValues]);

  const getData = async (payload: StatisticChartPayload) => {
    const res = await fetchRateUnitReviewAndAccess(payload);
    setData(res.data?.data);
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Lớp",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => <span className="font-weight-5">{text}</span>,
    },
    {
      title: "Lượng truy cập",
      dataIndex: "trafficVolume",
      key: "trafficVolume",
    },
    {
      title: "Lượng làm đánh giá",
      dataIndex: "evaluationCount",
      key: "evaluationCount",
    },
  ];

  return (
    <TableReport
      data={dataTable}
      columns={columns}
      title="Số lượng làm đánh giá theo thời gian"
      onChangeFilter={(v) => console.log(v)}
      summary={(pageData) => {
        let totalTrafficVolume = 0;
        let totalEvaluationCount = 0;

        pageData.forEach(({ trafficVolume = 0, evaluationCount = 0 }) => {
          totalTrafficVolume += trafficVolume;
          totalEvaluationCount += evaluationCount;
        });

        return (
          <Table.Summary.Row className="text-primary font-weight-5 sticky-summary-row">
            <Table.Summary.Cell index={0}>Tổng</Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              {totalTrafficVolume}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              {totalEvaluationCount}
            </Table.Summary.Cell>

            <Table.Summary.Cell index={5}>
              {totalTrafficVolume + totalEvaluationCount}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
    />
  );
}

export default EvaluateByTime;
