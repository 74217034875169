export enum LESSON_TYPE {
  DONE = 1,
  VIDEO = "video",
  READ = 3,
  MP3 = "mp3",
  TEXT = "text",
  SCORM = "scorm",
  PDF = "pdf",
  XAPI = "xapi",
  QUIZ = "quiz",
  BLOCK = "block",
  REFERENCE = "reference",
}

export enum QuestionTypeEnum {
  CHOOSE_TEXT = 1,
  CHOOSE_IMAGE = 2,
  CHOOSE_VIDEO = 3,
  TRUE_OR_FALSE = 4,
  DROP_DOWN = 5,
  CHOOSE_MULTI_TEXT = 6,
  CHOOSE_MULTI_IMAGE = 7,
  CHOOSE_MULTI_VIDEO = 8,
  SHORT_TEXT = 9,
  LONG_TEXT = 10,
  CONNECT = 11,
  FILE_UPLOAD = 12,
  VIDEO = 13,
  MP3 = 14,
  FillText = 15,
}

export const NumberingType = [
  {
    label: "A, B, C",
    value: 1,
  },
  {
    label: "a, b, c",
    value: 2,
  },
  {
    label: "1, 2, 3",
    value: 3,
  },
  {
    label: "I, II, III",
    value: 4,
  },
];

export enum JSON_SUB_TYPE {
  ChoosingRequest = "ChoosingRequest",
  ConnectingRequest = "ConnectingRequest",
  ContentRequest = "ContentRequest",
}

export enum ExamType {
  EXERCISE = 1, // 1 bai tap
  TEST = 2, // 2 kiem tra
  EXAM = 3, // 3 bai thi
}

export const MAX_QUIZ_FILE_UPLOAD_SIZE = 524288000; // bytes

export enum LayoutMode {
  FLAT = 0, // hiển thị tất cả trên 1 trang
  GROUP = 1, // hiển thị mỗi trang một phần
  SINGLE = 2, // hiển thị mỗi trang một câu
}

export enum AutoResultOptions {
  SHOW_TOTAL_CORRECT = 0, // hiển thị tổng số câu trả lời đúng
  SHOW_TOTAL_POINT = 1, // hiển thị tổng điểm
  SHOW_CORRECT_BY_GROUP = 2, // hiển thị số câu trả lời đúng theo từng phần
  SHOW_POINT_BY_GROUP = 3, // hiển thị điểm theo từng phần
  SHOW_TIME_TRAIN = 4, // hiển thị thời gian thực hiện
  SHOW_TIME_FINISH = 5, // hiển thị thời gian hoàn tất
  SHOW_VIEW_RESULT_BTN = 6, // hiển thị nút xem chi tiết đáp án
}

export enum TimeExpiredOption {
  AUTO = 0, // tự động
  CONTINUE = 1, // nới
  CANCEL = 2, // hủy
}

export enum FeedbackAnswerOption {
  ALL = 0, // Tât cả câu hỏi
  JUST_HAS_ANSWER = 1, // chỉ các câu đã trả lời
  JUST_CORRECT = 2, // chỉ câu trả lời đúng
  JUST_INCORRECT = 3, // chỉ câu trả lời sai
}
export const FEED_BACK_ANS_OPTIONS_ARR = [0, 1, 2, 3];
