export const config = {
  api: {
    token: "THE_API_TOKEN",
    apiUrl: "https://sandbox-apim.savis.vn/ekyc-api/v1/api/",
    source: "demo",
    sessionId: "Optional",
    options: {
      maxSessionTime: "10m",
      useModel: false,
    },
  },
  steps: [
    {
      type: "welcome",
      options: {},
    },
    {
      type: "otp",
      maxRequestOpt: 3,
      minRequestTime: "10s",
      maxFail: 5,
      onSuccess: function () {
        console.log("everything is completed");
      },
      onError: function (userExitCode: string) {
        console.log(userExitCode);
      },
    },
    {
      type: "document",
      options: {
        type: ["passport", "cccd_chip", "cccd", "cmnd"],
        side: "front",
        maxFail: 10,
        captchaAppearance: 5,
        showTutorial: true,
        showReview: true,
        checkLiveness: "True",
        minResolution: [640, 480],
        maxResolution: [1280, 720],
        onSuccess: function () {
          console.log("everything is completed");
        },
        onError: function (userExitCode: string) {
          console.log(userExitCode);
        },
      },
    },
    {
      type: "document",
      options: {
        type: ["cccd_chip", "cccd", "cmnd"],
        side: "back",
        maxFail: 10,
        captchaAppearance: 5,
        showTutorial: false,
        showReview: true,
        checkLiveness: "True",
        minResolution: [640, 480],
        maxResolution: [1280, 720],
        onSuccess: function () {
          console.log("everything is completed");
        },
        onError: function (userExitCode: string) {
          console.log(userExitCode);
        },
      },
    },
    {
      type: "face",
      options: {
        actions: ["center", "left", "right"],
        maxFail: 10,
        captchaAppearance: 5,
        showTutorial: true,
        showReview: true,
        checkLiveness: "True",
        cardMatchingThreshold: 0.8,
        faceSearchThreshold: 0.8,
        minResolution: [640, 480],
        maxResolution: [1280, 720],
        maxFps: 20,
        maxTime: "7s",
        thresholdValidFrame: 0.5,
        minFaceRatio: 0.3,
        maxFaceRatio: 0.8,
        minMissFrame: 0,
        onSuccess: function () {
          console.log("everything is completed");
        },
        onError: function (userExitCode: string) {
          console.log(userExitCode);
        },
      },
    },
    {
      type: "review",
      options: {
        showFields: [
          "id",
          "name",
          "gender",
          "ngay_sinh",
          "nguyen_quan",
          "ho_khau_thuong_tru",
          "noi_cap",
          "ngay_het_han",
        ],
        fixFields: ["name", "gender", "nguyen_quan", "ho_khau_thuong_tru"],
        canCheckError: true,
      },
      onSuccess: function () {
        console.log("everything is completed");
      },
      onError: function (userExitCode: string) {
        console.log(userExitCode);
      },
    },
    {
      type: "email",
      onSuccess: function () {
        console.log("everything is completed");
      },
      onError: function (userExitCode: StringConstructor) {
        console.log(userExitCode);
      },
    },
    {
      type: "complete",
      options: {},
    },
  ],
  UI: {
    companyLogo: "",
    mainLogo: "",
    navigatorIcon: {
      frontCardIcon: "",
      backCardIcon: "",
      faceIcon: "",
      resultIcon: "",
    },
    colorPrimary: "#2d88ff",
    colorSecondary: "#87b9fc",
    colorTitleContent: "#514b4b",
    colorDescriptionContent: "#848181",
    colorButtonContent: "#fff",
    colorActiveIcon: "#fff",
    colorDisableObject: "#848181",
  },
};
