const themeSurvey = [
    {
      value: "1",
      themeName: "theme__1",
      WapperBackgroundColor: "#f5f5f5",
      color: "#e6f7ff",
      textColor: "#000000D9",
    },
    {
      value: "2",
      themeName: "theme__2",
      WapperBackgroundColor: "#969D94",
      color: "#673AB7",
      textColor: "#FFFFFFD9",
    },
    {
      value: "3",
      themeName: "theme__3",
      WapperBackgroundColor: "#A5C59C",
      color: "#53A940",
      textColor: "#FFFFFFD9",
    },
    {
      value: "4",
      themeName: "theme__4",
      WapperBackgroundColor: "#869D80",
      color: "#1B7C6B",
      textColor: "#FFFFFFD9",
    },
  ];
  export default themeSurvey;