import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  CartesianGrid,
} from "recharts";
import LegendCustom from "./components/LegendCustom";
import { ChartProps } from "../../types/chart";
import { Flex, Select } from "antd";
import { renderTotal } from "./components/ultis";
import { Circle } from "./components/icons";
import { isEmpty } from "lodash";
import EmptyComponent from "../empty";
import "./style.scss";
import { DEFAULT_MODULE_GROUP } from "../../constants/statistics";

const COLORS = ["#D9D6FE", "#BDB4FE", "#9B8AFB", "#7A5AF8"];

interface BarChartHorizontalProps extends ChartProps {
  borderRadius?: number;
  xAxisType?: "number" | "percent";
}
function BarChartHorizontal({
  title = "",
  legends = [],
  total,
  showFilter = false,
  defaultSelectedValue = DEFAULT_MODULE_GROUP,
  filterOptions = [],
  placeholder = "",
  data,
  yDataKey,
  xDataKey,
  colors = COLORS,
  dataKeys = [],
  xAxisType = "number",
  onChange = () => {},
}: BarChartHorizontalProps) {
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            background: "#fff",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          {payload?.map((item: any, index: number) => (
            <Flex key={index}>
              <Circle fill={colors[index]} />
              <p>{`${item.name}: ${item.value}${xAxisType === "percent" ? "%" : ""}`}</p>{" "}
            </Flex>
          ))}
        </div>
      );
    }

    return null;
  };
  return (
    <div className="wrapper-chart">
      <Flex justify="space-between">
        <div className="chart-title font-weight-5 font-size-18">{title}</div>
        {showFilter && (
          <Select
            defaultValue={defaultSelectedValue}
            className="h-40 w-filter"
            placeholder={placeholder}
            onChange={onChange}
            options={filterOptions}
          />
        )}
      </Flex>
      <div className="break-line"></div>
      {isEmpty(data) ? (
        <div className="h-default">
          <EmptyComponent description="Không có kết quả" />
        </div>
      ) : (
        <div className="h-max-400">
          {renderTotal(total)}
          <ResponsiveContainer width="100%" minHeight={400}>
            <BarChart layout="vertical" data={data} barGap={30}>
              <XAxis
                dataKey={xDataKey}
                type="number"
                tickFormatter={
                  xAxisType === "percent" ? (value) => `${value}%` : undefined
                }
              />
              <YAxis
                tick={{
                  style: {
                    whiteSpace: "pre-line",
                    textAnchor: "end",
                  },
                }}
                dataKey={yDataKey}
                type="category"
                tickMargin={10}
                width={100}
              />
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid strokeDasharray="3 0" horizontal={false} />

              {dataKeys.map((dataKey: string, index: number) => (
                <Bar
                  key={index}
                  dataKey={dataKey}
                  stackId="a"
                  fill={colors[index]}
                  name={dataKey}
                  barSize={20}
                  children={
                    dataKeys?.length - 1 === index ? (
                      <LabelList dataKey="total" position="right" />
                    ) : null
                  }
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
          <LegendCustom items={legends} />
        </div>
      )}
    </div>
  );
}

export default BarChartHorizontal;
