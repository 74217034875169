import React, { useEffect, useState } from "react";
import { Empty, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableProps } from "antd/lib";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { learningCourseStore } from "../../../../../stores/stores";
import { checkAndCountdown, convertSeconds } from "../../../../../utils/format";
import { tableLocale } from "../../../../../utils/renderStarRating";

type Props = {
  tableData: DataType[];
  isLoading: boolean;
  setSortBy: (data: any) => void;
  sortBy: any;
};
interface DataType {
  key: string;
  name: string;
  deadline: string;
  submissionDate: string;
  attempts: number;
  status: string;
  mandatory: string;
  alert: boolean;
  totalTime: number;
  allowedSubmitTime: number;
  totalQuestions: number;
  duration: number;
  dateToCompleteBefore: string;
  sectionId: number;
  sequenceId: number;
  unitId: number;
  id: number;
  submitTime: number;
  submitDate: string;
  overdue: boolean;
  isRequired: boolean;
}

const AssessmentsSummaryTable = (props: Props) => {
  const { tableData, isLoading, setSortBy, sortBy } = props;
  const { tab, setTab, setSectionId, setSequenceId, setUnitId } =
    learningCourseStore();
  const navigate = useNavigate();

  const handleTableChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setSortBy(sorter);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Tên bài",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortOrder: sortBy?.columnKey === "name" ? sortBy.order : undefined,
      render: (text, record) => {
        return (
          <>
            <div
              className="exercise-name"
              onClick={() => {
                setTab("1");
                setSectionId(record.sectionId);
                setSequenceId(record.sequenceId);
                setUnitId(record.unitId);
                navigate(
                  `${location.pathname}?blockId=${record.id}&unitId=${record.unitId}`
                );
              }}
            >
              {record.name}
            </div>
            <div>
              ({`${record.totalQuestions} câu`}
              {record.duration && " - "}
              {record.duration && (
                <>
                  {convertSeconds(record.duration).hours !== "00" ? (
                    <span>
                      {`${convertSeconds(record.duration).hours} giờ`}&nbsp;
                    </span>
                  ) : null}
                  {convertSeconds(record.duration).minutes !== "00" ? (
                    <span>
                      {`${convertSeconds(record.duration).minutes} phút`}&nbsp;
                    </span>
                  ) : null}
                  {convertSeconds(record.duration).seconds !== "00" ? (
                    <span>{`${convertSeconds(record.duration).seconds} giây`}</span>
                  ) : null}
                </>
              )}
              )
            </div>
          </>
        );
      },
    },
    {
      title: "Cần hoàn thành trước",
      dataIndex: "dateToCompleteBefore",
      key: "dateToCompleteBefore",
      render: (text, record) => {
        return (
          <div className="flex gap-16">
            {record.dateToCompleteBefore &&
            checkAndCountdown(new Date(record.dateToCompleteBefore)) <= 7 &&
            checkAndCountdown(new Date(record.dateToCompleteBefore)) > 0 ? (
              <Tooltip
                title={`Còn ${Math.abs(checkAndCountdown(new Date(record.dateToCompleteBefore)))} ngày nữa hết hạn nộp bài`}
              >
                <div className="flex align-center justify-center gap-16">
                  {moment(text).format("DD/MM/YYYY")}
                  <img
                    src="/assets/icons/alert-circle.svg"
                    alt="preview"
                    className="pointer"
                  />
                </div>
              </Tooltip>
            ) : record.dateToCompleteBefore &&
              checkAndCountdown(new Date(record.dateToCompleteBefore)) > 7 &&
              checkAndCountdown(new Date(record.dateToCompleteBefore)) < 0 ? (
              "b"
            ) : (
              "--"
            )}
          </div>
        );
      },
    },
    {
      title: "Thời gian nộp bài",
      dataIndex: "submitDate",
      key: "submitDate",
      render: (text, record) => {
        return (
          <span>
            {record.isRequired && !record.submitDate ? (
              "N/A"
            ) : !record.isRequired && !record.submitDate ? (
              "--"
            ) : record.overdue ? (
              <span className="submission-deadline">Quá hạn</span>
            ) : record.submitDate ? (
              moment(record.submitDate).format("DD/MM/YYYY")
            ) : null}
          </span>
        );
      },
    },
    {
      title: "Kết quả",
      dataIndex: "result",
      key: "result",
      // render: (status) => (
      //   <div
      //     className={`flex align-center gap-4 ${
      //       status === "1"
      //         ? "tag-pass"
      //         : status === "2"
      //           ? "tag-fail"
      //           : status === "N/A"
      //             ? "tag-epmpty"
      //             : ""
      //     }`}
      //   >
      //     {status === "1" ? (
      //       <span>
      //         <img src="/assets/icons/check-green.svg" />
      //         Đạt
      //       </span>
      //     ) : status === "2" ? (
      //       <span>
      //         <img src="/assets/icons/x-close.svg" />
      //         Chưa đạt
      //       </span>
      //     ) : status === "3" ? (
      //       <span>
      //         <img src="/assets/icons/_Dot.svg" />
      //         N/A
      //       </span>
      //     ) : (
      //       <span>--</span>
      //     )}

      //     {status}
      //   </div>
      // ),
    },
    {
      title: "Số lần nộp",
      dataIndex: "submitTime",
      key: "submitTime",
      render: (text, record) => (
        <>
          <div>
            {record?.submitTime}
            {record?.allowedSubmitTime
              ? `/${record?.allowedSubmitTime} `
              : null}
          </div>
        </>
      ),
    },
    {
      title: "Bắt buộc",
      dataIndex: "isRequired",
      key: "isRequired",
      render: (isRequired) => <span>{isRequired ? "Có" : "Không"}</span>,
    },
  ];

  return (
    <Table
      dataSource={tableData}
      columns={columns}
      pagination={false}
      className="assessments-summary-table"
      loading={isLoading}
      onChange={handleTableChange}
      locale={tableLocale}
    />
  );
};

export default AssessmentsSummaryTable;
