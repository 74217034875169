export const PauseIconPrimary = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5 4.98951C5 4.01835 5 3.53277 5.20249 3.2651C5.37889 3.03191 5.64852 2.88761 5.9404 2.87018C6.27544 2.85017 6.67946 3.11953 7.48752 3.65823L18.0031 10.6686C18.6708 11.1137 19.0046 11.3363 19.1209 11.6168C19.2227 11.8621 19.2227 12.1377 19.1209 12.383C19.0046 12.6635 18.6708 12.886 18.0031 13.3312L7.48752 20.3415C6.67946 20.8802 6.27544 21.1496 5.9404 21.1296C5.64852 21.1122 5.37889 20.9679 5.20249 20.7347C5 20.467 5 19.9814 5 19.0103V4.98951Z"
      stroke="#7F56D9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const QuestionMarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_572_3377)">
      <path
        d="M7.57484 7.50008C7.77076 6.94314 8.15746 6.4735 8.66647 6.17436C9.17547 5.87521 9.77392 5.76586 10.3558 5.86567C10.9377 5.96549 11.4655 6.26802 11.8457 6.71969C12.226 7.17136 12.4341 7.74302 12.4332 8.33342C12.4332 10.0001 9.93317 10.8334 9.93317 10.8334M9.99984 14.1667H10.0082M18.3332 10.0001C18.3332 14.6025 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6025 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_572_3377">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const HoursGlassIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M6 6L3.86356 4.21963C3.54613 3.95511 3.38742 3.82285 3.27332 3.66072C3.17222 3.51707 3.09714 3.35677 3.05151 3.18714C3 2.99569 3 2.7891 3 2.3759V1M6 6L8.13644 4.21963C8.45387 3.95511 8.61258 3.82285 8.72668 3.66072C8.82778 3.51707 8.90286 3.35677 8.94849 3.18714C9 2.99569 9 2.7891 9 2.3759V1M6 6L3.86356 7.78037C3.54613 8.04489 3.38742 8.17715 3.27332 8.33928C3.17222 8.48293 3.09714 8.64323 3.05151 8.81286C3 9.00431 3 9.2109 3 9.6241V11M6 6L8.13644 7.78037C8.45387 8.04489 8.61258 8.17715 8.72668 8.33928C8.82778 8.48293 8.90286 8.64323 8.94849 8.81286C9 9.00431 9 9.2109 9 9.6241V11M2 1H10M2 11H10"
      stroke="#9E77ED"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ClockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0003 7.91675V11.2501L12.0837 12.5001M10.0003 4.16675C6.08831 4.16675 2.91699 7.33806 2.91699 11.2501C2.91699 15.1621 6.08831 18.3334 10.0003 18.3334C13.9123 18.3334 17.0837 15.1621 17.0837 11.2501C17.0837 7.33806 13.9123 4.16675 10.0003 4.16675ZM10.0003 4.16675V1.66675M8.33366 1.66675H11.667M16.9411 4.66012L15.6911 3.41012L16.3161 4.03512M3.05951 4.66012L4.30951 3.41012L3.68451 4.03512"
      stroke="#6941C6"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const NextIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M3.33301 10H16.6663M16.6663 10L11.6663 5M16.6663 10L11.6663 15"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PreviousIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.8337 9.99999H4.16699M4.16699 9.99999L10.0003 15.8333M4.16699 9.99999L10.0003 4.16666"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CloudIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M6.66602 13.3333L9.99935 10M9.99935 10L13.3327 13.3333M9.99935 10V17.5M16.666 13.9524C17.6839 13.1117 18.3327 11.8399 18.3327 10.4167C18.3327 7.88536 16.2807 5.83333 13.7493 5.83333C13.5673 5.83333 13.3969 5.73833 13.3044 5.58145C12.2177 3.73736 10.2114 2.5 7.91602 2.5C4.46424 2.5 1.66602 5.29822 1.66602 8.75C1.66602 10.4718 2.36222 12.0309 3.48847 13.1613"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PdfICon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="40"
    viewBox="0 0 32 40"
    fill="none"
  >
    <path
      d="M0.75 4C0.75 2.20508 2.20508 0.75 4 0.75H20C20.1212 0.75 20.2375 0.798159 20.3232 0.883885L31.1161 11.6768C31.2018 11.7625 31.25 11.8788 31.25 12V36C31.25 37.7949 29.7949 39.25 28 39.25H4C2.20507 39.25 0.75 37.7949 0.75 36V4Z"
      stroke="#D0D5DD"
      strokeWidth="1.5"
    />
    <path
      d="M20 0.5V8C20 10.2091 21.7909 12 24 12H31.5"
      stroke="#D0D5DD"
      strokeWidth="1.5"
    />
  </svg>
);

export const ReloadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.4993 8.33333C17.4993 8.33333 15.8285 6.05685 14.4712 4.69854C13.1138 3.34022 11.238 2.5 9.16602 2.5C5.02388 2.5 1.66602 5.85786 1.66602 10C1.66602 14.1421 5.02388 17.5 9.16602 17.5C12.5853 17.5 15.4701 15.2119 16.3729 12.0833M17.4993 8.33333V3.33333M17.4993 8.33333H12.4993"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5M8.33333 8.75V12.9167M11.6667 8.75V12.9167"
      stroke="#B42318"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MicroIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M16.3327 8.33317V9.99984C16.3327 13.2215 13.721 15.8332 10.4993 15.8332M4.66602 8.33317V9.99984C4.66602 13.2215 7.27769 15.8332 10.4993 15.8332M10.4993 15.8332V18.3332M7.16602 18.3332H13.8327M10.4993 12.4998C9.11864 12.4998 7.99935 11.3805 7.99935 9.99984V4.1665C7.99935 2.78579 9.11864 1.6665 10.4993 1.6665C11.8801 1.6665 12.9993 2.78579 12.9993 4.1665V9.99984C12.9993 11.3805 11.8801 12.4998 10.4993 12.4998Z"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RecordIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M18.3327 7.44313C18.3327 6.93829 18.3327 6.68586 18.2329 6.56898C18.1462 6.46756 18.0163 6.41373 17.8833 6.4242C17.7301 6.43626 17.5516 6.61475 17.1946 6.97173L14.166 10.0003L17.1946 13.0289C17.5516 13.3859 17.7301 13.5644 17.8833 13.5765C18.0163 13.5869 18.1462 13.5331 18.2329 13.4317C18.3327 13.3148 18.3327 13.0624 18.3327 12.5575V7.44313Z"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66602 8.16699C1.66602 6.76686 1.66602 6.0668 1.9385 5.53202C2.17818 5.06161 2.56063 4.67916 3.03104 4.43948C3.56582 4.16699 4.26588 4.16699 5.66602 4.16699H10.166C11.5661 4.16699 12.2662 4.16699 12.801 4.43948C13.2714 4.67916 13.6538 5.06161 13.8935 5.53202C14.166 6.0668 14.166 6.76686 14.166 8.16699V11.8337C14.166 13.2338 14.166 13.9339 13.8935 14.4686C13.6538 14.939 13.2714 15.3215 12.801 15.5612C12.2662 15.8337 11.5661 15.8337 10.166 15.8337H5.66602C4.26588 15.8337 3.56582 15.8337 3.03104 15.5612C2.56063 15.3215 2.17818 14.939 1.9385 14.4686C1.66602 13.9339 1.66602 13.2338 1.66602 11.8337V8.16699Z"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RefreshIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M18.1693 8.33333C18.1693 8.33333 16.4985 6.05685 15.1411 4.69854C13.7837 3.34022 11.9079 2.5 9.83594 2.5C5.6938 2.5 2.33594 5.85786 2.33594 10C2.33594 14.1421 5.6938 17.5 9.83594 17.5C13.2552 17.5 16.14 15.2119 17.0428 12.0833M18.1693 8.33333V3.33333M18.1693 8.33333H13.1693"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RemuseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M4.16797 4.1576C4.16797 3.3483 4.16797 2.94365 4.33671 2.72059C4.48371 2.52626 4.7084 2.40601 4.95163 2.39149C5.23083 2.37482 5.56752 2.59928 6.2409 3.0482L15.0039 8.89018C15.5603 9.26111 15.8385 9.44658 15.9354 9.68035C16.0202 9.88473 16.0202 10.1144 15.9354 10.3188C15.8385 10.5526 15.5603 10.738 15.0039 11.109L6.2409 16.951C5.56752 17.3999 5.23083 17.6243 4.95163 17.6077C4.7084 17.5931 4.48371 17.4729 4.33671 17.2786C4.16797 17.0555 4.16797 16.6509 4.16797 15.8416V4.1576Z"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PauseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.5 15V9M14.5 15V9M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z"
      stroke="#344054"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RightArrowIcon = () => {
  return (
    <svg
      width="44"
      height="34"
      viewBox="0 0 44 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33398 17H42.6673M42.6673 17L27.1673 1.5M42.6673 17L27.1673 32.5"
        stroke="#7F56D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const LeftArrowIcon = () => {
  return (
    <svg
      width="44"
      height="34"
      viewBox="0 0 44 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.6673 17H1.33398M1.33398 17L16.834 32.5M1.33398 17L16.834 1.5"
        stroke="#7F56D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const UpArrowIcon = () => {
  return (
    <svg
      width="34"
      height="44"
      viewBox="0 0 34 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 42.6668V1.3335M17 1.3335L1.5 16.8335M17 1.3335L32.5 16.8335"
        stroke="#7F56D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const DownArrowIcon = () => {
  return (
    <svg
      width="34"
      height="44"
      viewBox="0 0 34 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 1.3335V42.6668M17 42.6668L32.5 27.1668M17 42.6668L1.5 27.1668"
        stroke="#7F56D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const PausePrimaryIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_869_113375)">
      <path
        d="M7.91797 12.4998V7.49984M12.0846 12.4998V7.49984M18.3346 9.99984C18.3346 14.6022 14.6037 18.3332 10.0013 18.3332C5.39893 18.3332 1.66797 14.6022 1.66797 9.99984C1.66797 5.39746 5.39893 1.6665 10.0013 1.6665C14.6037 1.6665 18.3346 5.39746 18.3346 9.99984Z"
        stroke="#7F56D9"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_869_113375">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ExportIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 6.66667L10 2.5M10 2.5L5.83333 6.66667M10 2.5V12.5"
      stroke="white"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_513_119066)">
      <path
        d="M10.0013 18.3332C14.6037 18.3332 18.3346 14.6022 18.3346 9.99984C18.3346 5.39746 14.6037 1.6665 10.0013 1.6665C5.39893 1.6665 1.66797 5.39746 1.66797 9.99984C1.66797 14.6022 5.39893 18.3332 10.0013 18.3332Z"
        stroke="#7F56D9"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91797 7.47095C7.91797 7.07321 7.91797 6.87434 8.00109 6.76332C8.07352 6.66657 8.1844 6.60603 8.30495 6.59742C8.44329 6.58754 8.61057 6.69508 8.94514 6.91016L12.879 9.43905C13.1693 9.62569 13.3145 9.719 13.3646 9.83766C13.4084 9.94134 13.4084 10.0583 13.3646 10.162C13.3145 10.2807 13.1693 10.374 12.879 10.5606L8.94514 13.0895C8.61057 13.3046 8.44329 13.4121 8.30495 13.4023C8.1844 13.3936 8.07352 13.3331 8.00109 13.2364C7.91797 13.1253 7.91797 12.9265 7.91797 12.5287V7.47095Z"
        stroke="#7F56D9"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_513_119066">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ExpandDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5 7.5L10 12.5L15 7.5"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const QuestionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_869_110422)">
      <path
        d="M9.99984 13.3327V9.99935M9.99984 6.66602H10.0082M18.3332 9.99935C18.3332 14.6017 14.6022 18.3327 9.99984 18.3327C5.39746 18.3327 1.6665 14.6017 1.6665 9.99935C1.6665 5.39698 5.39746 1.66602 9.99984 1.66602C14.6022 1.66602 18.3332 5.39698 18.3332 9.99935Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_869_110422">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ArrowLeftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.8332 9.99935H4.1665M4.1665 9.99935L9.99984 15.8327M4.1665 9.99935L9.99984 4.16602"
      stroke="#6941C6"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const WarningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_869_110422)">
      <path
        d="M9.99984 13.3327V9.99935M9.99984 6.66602H10.0082M18.3332 9.99935C18.3332 14.6017 14.6022 18.3327 9.99984 18.3327C5.39746 18.3327 1.6665 14.6017 1.6665 9.99935C1.6665 5.39698 5.39746 1.66602 9.99984 1.66602C14.6022 1.66602 18.3332 5.39698 18.3332 9.99935Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_869_110422">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CloseOrangeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M11.3332 5.16669L4.6665 11.8334M4.6665 5.16669L11.3332 11.8334"
      stroke="#F79009"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CheckPrimary = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="#7F56D9"
    />
    <path
      d="M6.25 10L8.75 12.5L13.75 7.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CheckSecondary = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="#D0D5DD"
    />
    <path
      d="M6.25 10L8.75 12.5L13.75 7.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FaceIdIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M6.66667 3H6.5C5.09987 3 4.3998 3 3.86502 3.27248C3.39462 3.51217 3.01217 3.89462 2.77248 4.36502C2.5 4.8998 2.5 5.59987 2.5 7V7.16667M6.66667 18H6.5C5.09987 18 4.3998 18 3.86502 17.7275C3.39462 17.4878 3.01217 17.1054 2.77248 16.635C2.5 16.1002 2.5 15.4001 2.5 14V13.8333M17.5 7.16667V7C17.5 5.59987 17.5 4.8998 17.2275 4.36502C16.9878 3.89462 16.6054 3.51217 16.135 3.27248C15.6002 3 14.9001 3 13.5 3H13.3333M17.5 13.8333V14C17.5 15.4001 17.5 16.1002 17.2275 16.635C16.9878 17.1054 16.6054 17.4878 16.135 17.7275C15.6002 18 14.9001 18 13.5 18H13.3333M6.25 7.16667V8.41667M13.75 7.16667V8.41667M9.16667 11.0001C9.83333 11.0001 10.4167 10.4167 10.4167 9.75008V7.16667M12.6668 13.1666C11.1668 14.6666 8.75016 14.6666 7.25016 13.1666"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
