import {
  DislikeOutlined,
  HomeOutlined,
  LikeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { routesConfig } from "../../../config/routes";
import MoocBreadcrumb from "../../../components/breadcrumb";
import { Button, Input } from "antd";
import { Link } from "react-router-dom";

function AccountRegister() {
  const breadcrumb = [
    {
      title: (
        <Link to={routesConfig.homePage}>
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: (
        <Link className="breadcrumb-link" to={routesConfig.support}>
          Hỗ trợ
        </Link>
      ),
    },
    {
      title: (
        <span className="breadcrumb-link breadcrumb-link-active">
          <b> Hướng dẫn đăng ký tài khoản</b>
        </span>
      ),
    },
  ];
  return (
    <div>
      <section className="content-layout">
        <MoocBreadcrumb items={breadcrumb} />
        <div className="support-detail-header flex justify-space-between align-center">
          <h3 className="font-size-30">
            <b>Đăng ký tài khoản</b>
          </h3>
          <Input
            style={{ width: 300 }}
            prefix={
              <SearchOutlined style={{ color: "#667085", fontSize: 20 }} />
            }
            className="height-44"
            placeholder="Tìm kiếm"
          />
        </div>
        <div className="wrap-content-support-detail flex mt-5">
          <div className="sup-sidebar">
            <p className="text-primary font-size-16 line-height-24">
              <b>Làm sao để đăng ký tài khoản MOOC?</b>
            </p>
          </div>
          <div className="sup-content">
            <h3 className="font-size-30">
              <b>Làm sao để đăng ký tài khoản MOOC?</b>
            </h3>
            <p className="text-secondary font-size-18 line-height-28 mt-2">
              Bạn chọn button Đăng ký trên thanh tiêu đề của hệ thống
            </p>
            <img
              className="support-img mt-4 mb-2"
              src={`${process.env.PUBLIC_URL}/assets/img/dang-ky-tai-khoan.png`}
              alt="image"
            />
            <p className="text-secondary font-size-14">
              Giao diện đăng ký tài khoản của hệ hống Mooc
            </p>

            <p className="text-secondary font-size-18 mt-4 mb-4">
              Sau khi nhập xong các thông tin. Người dùng nhấn Đăng ký
            </p>

            <p className="text-secondary font-size-18 mt-4 mb-4 line-height-28">
              Hệ thống hiển thị giao diện Xác nhận bảo mật, gửi mã bảo mật qua
              mail người dùng đăng ký.
            </p>

            <img
              className="support-img mt-4 mb-2 img-contain"
              src={`${process.env.PUBLIC_URL}/assets/img/otp.png`}
              alt="image"
            />
            <p className="text-secondary font-size-18 mt-4 mb-4 line-height-28">
              Người dùng nhập mã bảo mật, nhấn xác nhận
            </p>
            <p className="text-secondary font-size-18 mt-4 mb-4 line-height-28">
              Hệ thống hiển thị popup xác nhận đăng ký tài khoản
            </p>
            <img
              className="support-img mt-4 mb-2 img-contain"
              src={`${process.env.PUBLIC_URL}/assets/img/xac-nhan-dang-ky.png`}
              alt="image"
            />
            <p className="text-secondary font-size-18 mt-4 mb-4 line-height-28">
              Người dùng tích chọn và nhấn Xác nhận
            </p>
            <p className="text-secondary font-size-18 mt-4 mb-4 line-height-28">
              Hệ thống chuyển đến giao diện thu nhập thông tin của học viên gồm
              Chuyên ngành, Sở thích và Xác thực thông tin tài khoản để chúng
              tôi có thể đề xuất các khoá học phù hợp với mục đích học tập của
              bạn. Người dùng nhấn Tiếp theo để chuyển đến màn hình sau:
            </p>

            <img
              className="support-img mt-4 mb-2 img-contain"
              src={`${process.env.PUBLIC_URL}/assets/img/chuyen-nganh.png`}
              alt="image"
            />
            <p className="text-secondary font-size-18 mt-4 mb-4 line-height-28">
              Hoàn tất việc đăng ký. Hệ thống hiển thị giao diện tạo tài khoản
              thành công
            </p>

            <img
              className="support-img mt-4 mb-2 img-contain"
              src={`${process.env.PUBLIC_URL}/assets/img/dang-ky-thanh-cong.png`}
              alt="image"
            />

            <div className="sup-action flex align-center justify-space-between mb-5">
              <p className="text-secondary font-size-18">
                Câu trả lời hữu ích với bạn không?
              </p>
              <div className="flex align-center gap-8">
                <Button className="btn-primary">
                  <div className="flex align-center gap-8">
                    {" "}
                    <LikeOutlined />
                    <span className="font-size-14">
                      <b>Có</b>
                    </span>
                  </div>
                </Button>
                <Button>
                  <div className="flex align-center gap-8">
                    <DislikeOutlined />
                    <span className="font-size-14">
                      <b>Không</b>
                    </span>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AccountRegister;
