import { Button, Form, Select } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormInput from "../../../components/form-input/FormInput";
import SecondaryTab from "../../../components/tabs/secondary-tab/SecondaryTab";
import { DOCUMENT_TYPE } from "../../../constants";
import {
  dowloadDocument,
  downloadAllDocument,
  getSectionByCourse,
  getSequenceBySection,
  getTotalDocument,
} from "../../../service/learning-course";
import { globalStore } from "../../../stores/stores";
import { filterOption } from "../../../utils/element";
import "./Document.scss";
import AllDocument from "./components/all-document/AllDocument";
import FavoriteDocuments from "./components/favorite-documents/FavoriteDocuments";

type Props = {};
const Document = (props: Props) => {
  const { reload, setReload } = globalStore();

  const { id } = useParams();
  const [form] = Form.useForm();
  const sectionId = Form.useWatch("section", form);

  const [isToggle, setIsToggle] = useState(false);
  const [sections, setSections] = useState([]);
  const [sequences, setSequences] = useState([]);
  const [documentTab, setDocumentTab] = useState<string>("1");
  const [formValues, setFormValues] = useState<any>();
  const [documentDownList, setDocumentDownList] = useState<number[]>([]);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [isDelField, setIsDelField] = useState<boolean>(false);
  const [totalTab, setTotalTab] = useState({
    allDocument: 0,
    favoriteDocument: 0,
  });

  const fetchSections = async () => {
    try {
      const res = await getSectionByCourse(Number(id));
      setSections(
        res.data.map((section: any) => ({
          value: section.id,
          label: section.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  const fetchSequences = async (sectionId: number) => {
    try {
      const res = await getSequenceBySection(sectionId);
      setSequences(
        res.data.map((sequence: any) => ({
          value: sequence.id,
          label: sequence.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching sequences:", error);
    }
  };

  useEffect(() => {
    fetchSections();
  }, [id]);

  useEffect(() => {
    if (sectionId) {
      fetchSequences(sectionId);
    } else {
      setSequences([]);
    }
    form.setFieldsValue({ sequence: undefined });
  }, [sectionId]);

  const fetchTotalDocument = async () => {
    const payloads = {
      keyword:
        formValues && formValues.keyword
          ? formValues.keyword.trim()
          : undefined,
      courseId: Number(id),
      sectionId:
        formValues && formValues.section ? formValues.section : undefined,
      sequenceId:
        formValues && formValues.sequence ? formValues.sequence : undefined,
      type:
        formValues && formValues.documentType
          ? formValues.documentType
          : undefined,
      isFavorite: documentTab === "1" ? undefined : true,
    };

    try {
      const res = await getTotalDocument(payloads);

      setTotalTab({
        allDocument: res?.data.data.countAll,
        favoriteDocument: res?.data.data.countFavorite,
      });
    } catch (error) {}
  };
  useEffect(() => {
    fetchTotalDocument();
  }, [formValues, reload]);

  const RenderAdvanceSearch = () => (
    <div className="advanced-filtering-field">
      <div className="item">
        <span className="title">Loại tài liệu</span>
        <Form.Item name="documentType">
          <Select
            defaultValue={0}
            options={DOCUMENT_TYPE}
            style={{ width: "100%", height: 44 }}
            allowClear
          />
        </Form.Item>
      </div>
      <div className="item">
        <span className="title">Chương</span>
        <Form.Item name="section">
          <Select
            showSearch
            className="btn-filter"
            style={{ width: "100%", height: 44 }}
            filterOption={filterOption}
            allowClear
            placeholder="Chọn chương"
            notFoundContent={<span>Không có kết quả</span>}
            options={sections}
          />
        </Form.Item>
      </div>
      <div className="item">
        <span className="title">Bài</span>
        <Form.Item name="sequence">
          <Select
            showSearch
            className="btn-filter"
            style={{ width: "100%", height: 44 }}
            filterOption={filterOption}
            allowClear
            placeholder="Chọn bài"
            notFoundContent={<span>Không có kết quả</span>}
            disabled={!sectionId}
            options={sequences}
          />
        </Form.Item>
      </div>

      <div className="item">
        <div className="title">&nbsp;</div>
        <div
          className="text-clear-filters"
          onClick={() => {
            setReload(!reload);
            setIsDelField(!isDelField);
            form.resetFields();
          }}
        >
          Xóa bộ lọc
        </div>
      </div>
    </div>
  );

  const handleDownload = async () => {
    setDownloading(true);

    try {
      const res = await dowloadDocument({ unitIds: documentDownList });
      const fileData = res.data;

      const blob = new Blob([fileData], { type: "application/zip" });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "Documents.zip";

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      setDownloading(false);
    } catch (error) {
      console.error("Download failed", error);
      setDownloading(false);
    }
  };

  const handleDownloadAll = async () => {
    setDownloading(true);
    try {
      const res = await downloadAllDocument(Number(id));

      const fileData = res.data;

      const blob = new Blob([fileData], { type: "application/zip" });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "Documents.zip";

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      setDownloading(false);
    } catch (error) {
      console.error("Download failed", error);
      setDownloading(false);
    }
  };

  const tabItems = [
    {
      key: "1",
      label: (
        <div className="flex align-center gap-8">
          Tất cả tài liệu
          <div className="count-tab">{totalTab.allDocument}</div>
        </div>
      ),
      children: (
        <>
          <div className="hr-tag"></div>
          <AllDocument
            filterParams={formValues}
            documentTab={documentTab}
            setDocumentDownList={setDocumentDownList}
          />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex align-center gap-8">
          Tài liệu yêu thích
          <div className="count-tab">{totalTab.favoriteDocument}</div>
        </div>
      ),

      children: (
        <>
          <div className="hr-tag"></div>
          <FavoriteDocuments
            filterParams={formValues}
            documentTab={documentTab}
            setDocumentDownList={setDocumentDownList}
          />
        </>
      ),
    },
  ];
  const onValuesChange = (changedValues: any, allValues: any) => {
    setFormValues(allValues);
  };

  useEffect(() => {
    setFormValues({
      documentType: form.getFieldValue("documentType"),
      keyword: form.getFieldValue("keyword"),
      section: form.getFieldValue("section"),
      sequence: form.getFieldValue("sequence"),
    });
  }, [isDelField]);

  return (
    <div className="document">
      <Form form={form} onValuesChange={onValuesChange}>
        <div className="flex justify-between align-center gap-16">
          <div>
            <div className="text-title-18">Danh sách tài liệu</div>
            <div className="document-des">
              Tổng hợp các tài liệu tham khảo của khoá học{" "}
            </div>
          </div>
          <div className="document-search">
            <Form.Item name="keyword">
              <FormInput
                placeholder="Nhập từ khóa cần tìm"
                prefixIcon={
                  <img
                    src="/assets/icons/search-lg.svg"
                    alt="search"
                    className="search-icon"
                  />
                }
              />
            </Form.Item>

            <div className="flex gap-8">
              <Button
                className="advanced-filtering"
                onClick={() => setIsToggle(!isToggle)}
              >
                <img
                  src="/assets/icons/filter-lines.svg"
                  alt="search"
                  className="search-icon"
                />
                Bộ lọc nâng cao
              </Button>
            </div>
            <div className="flex gap-8">
              {documentDownList?.length > 0 ? (
                <Button
                  className="download-document"
                  loading={downloading}
                  onClick={handleDownload}
                >
                  <img
                    src="/assets/icons/download-cloud-02-white.svg"
                    alt="search"
                    className="search-icon"
                  />
                  Tải xuống
                </Button>
              ) : (
                <Button
                  className="advanced-filtering"
                  loading={downloading}
                  onClick={handleDownloadAll}
                >
                  <img
                    src="/assets/icons/download-cloud-02.svg"
                    alt="search"
                    className="search-icon"
                  />
                  Tải xuống tất cả
                </Button>
              )}
            </div>
          </div>
        </div>
        {isToggle && <RenderAdvanceSearch />}
      </Form>
      <div className="mt-3">
        <SecondaryTab
          tabItems={tabItems}
          onChange={(tab) => setDocumentTab(tab)}
        />
      </div>
    </div>
  );
};

export default Document;
