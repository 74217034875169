import { Button, Card, Flex, Image, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ModalCameraEkyc from "./modal-camera-ekyc";

const { Title, Text } = Typography;

const IdentifyEkycVideoFace = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="border-b py-4">
        <Title level={4} className="!mb-0 !font-semibold mt-4">
          Hướng dẫn quay Video khuôn mặt
        </Title>
        <Text className="text-[#475467]">
          Vui lòng đọc kĩ các thông tin hướng dẫn của hệ thống
        </Text>
      </div>
      <Card style={{ background: "#ffffff" }}>
        <Text>
          Thông tin của bạn sẽ được lưu trữ một cách an toàn, đảm bảo tuân thủ
          luật pháp và các quy định nhà nước. Bạn có thể tham khảo{" "}
          <Link to={"#"}>Chính sách bảo mật</Link> của chúng tôi để biết thêm
          chi tiết
        </Text>
      </Card>
      <Card>
        <Flex wrap="wrap">
          <div className="w-full sm:w-1/4 text-center">
            <Image src="/assets/img/kyc.png" preview={false} />
          </div>
          <div className="w-full sm:w-3/4 sm:px-10 pt-4 sm:pt-0">
            <Title level={4} className="!mb-0 !font-semibold">
              Hướng dẫn chi tiết
            </Title>
            <Text className="text-[#475467]">
              Vui lòng đọc kĩ hướng dẫn của hệ thống
            </Text>
            <ol className="px-4 py-4 lg:py-8">
              <li className="list-decimal py-1 lg:py-2">
                Đảm bảo khuôn mặt ở trong khung hình
              </li>
              <li className="list-decimal py-1 lg:py-2">
                Thực hiện di chuyển khuôn mặt theo hướng dẫn
              </li>
              <li className="list-decimal py-1 lg:py-2">
                Không đeo kính, đội mũ, đeo khẩu trang hoặc trùm toc khi thực
                hiện
              </li>
              <li className="list-decimal py-1 lg:py-2">
                Đảm bảo thực hiện ở nơi có đủ ánh sáng
              </li>
              <li className="list-decimal py-1 lg:py-2">
                Trường hợp gian lận trong quá trình xác thực sẽ không được tham
                dự các khoá học trên hệ thống MOOC
              </li>
            </ol>
          </div>
        </Flex>
      </Card>
      <div className="w-full py-4 mb-8">
        <Button
          className="w-full"
          size="large"
          type="primary"
          onClick={handleOpen}
        >
          Bắt đầu quay video
        </Button>
      </div>
      {open && <ModalCameraEkyc isOpen={open} handleClose={handleClose} />}
    </div>
  );
};

export default IdentifyEkycVideoFace;
