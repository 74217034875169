import {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  ReactNode,
} from "react";
import "./form-input.scss";
import { Input } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

type FormItemInputProps = {
  prefixIcon?: ReactNode;
  afterPrefixIcon?: ReactNode;
  placeholder?: string;
  value?: string | string[];
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  passwordInput?: boolean;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  suffix?: ReactNode;
  readOnly?: boolean;
  onPressEnter?: any;
  onClick?: any
};

const FormItemInput: FunctionComponent<FormItemInputProps> = ({
  prefixIcon,
  afterPrefixIcon,
  placeholder,
  value,
  onChange,
  passwordInput,
  onKeyPress,
  suffix,
  readOnly,
  onPressEnter,
  onClick
}) => {
  return (
    <>
      {passwordInput ? (
        <Input.Password
          className="form-input"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onClick={onClick}

        />
      ) : (
        <Input
          className="form-input"
          prefix={prefixIcon}
          addonAfter={afterPrefixIcon}
          readOnly={readOnly}
          defaultValue={value}
          value={value}
          suffix={suffix}
          onChange={onChange}
          onClick={(e) => {
            e.stopPropagation()
            onClick()
          }}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          onPressEnter={onPressEnter}
          allowClear
        />
      )}
    </>
  );
};

export default FormItemInput;
