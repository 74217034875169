import { Breadcrumb, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import React from "react";
import CustomPagination from "../../../../../components/pagination/Pagination";
import { NoteHistoryType } from "../../../../../types/myNote";
import "./NoteHistory.scss";

type Props = {
  noteHistory: NoteHistoryType[];
  totalPage: number;
  pageSize: number;
  pageNum: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setPageNum: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
};
const NoteHistory = (props: Props) => {
  const {
    noteHistory,
    totalPage,
    pageSize,
    pageNum,
    setPageSize,
    setPageNum,
    isLoading,
  } = props;

  const columns: ColumnsType<NoteHistoryType> = [
    {
      title: "Thời gian",
      dataIndex: "createdDate",
      key: "createdDate",
      sorter: (a, b) =>
        new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
      render: (value, record, index) => (
        <div className="text-desc">
          {moment(value).format("DD/MM/YYYY")} - {moment(value).format("HH:mm")}
        </div>
      ),
    },
    {
      title: "Hoạt động",
      dataIndex: "active",
      key: "active",
      render: (action) => (
        <Tag color={action === "Tạo mới" ? "blue" : "magenta"}>{action}</Tag>
      ),
    },
    {
      title: "Ghi chú",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Vị trí",
      dataIndex: "location",
      key: "location",
      render: (value, record, index) => (
        <div className="text-desc">
          <Breadcrumb>
            <Breadcrumb.Item>{record.sectionName}</Breadcrumb.Item>
            <Breadcrumb.Item>{record.sequenceName}</Breadcrumb.Item>
            <Breadcrumb.Item>{record.unitName}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="hr-tag-item"></div>

      <div className="note-history">
        <div className="note-history-header">
          <div className="text-title-18 mt-1">Lịch sử ghi chú của bạn</div>
          <div className="my-notes-des">
            Tổng hợp thời gian hoạt động ghi chú của bạn
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={noteHistory}
          rowKey="time"
          pagination={false}
          loading={isLoading}
        />
        <CustomPagination
          totalPage={totalPage}
          pageSize={pageSize}
          pageNumber={pageNum}
          setPageSize={(value) => setPageSize(value)}
          setPageNumber={(value) => setPageNum(value)}
        />
      </div>
    </>
  );
};

export default NoteHistory;
