// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-survey .ant-card {
  margin: 30px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/my-surveys/components/survey-respond/component/detail-content/detail.scss"],"names":[],"mappings":"AACI;EACI,cAAA;AAAR","sourcesContent":[".detail-survey{\n    .ant-card{\n        margin: 30px 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
