"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pose = void 0;
const pose_1 = require("@mediapipe/pose");
exports.pose = new pose_1.Pose({ locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
    } });
exports.pose.setOptions({
    modelComplexity: 1,
    smoothLandmarks: true,
    enableSegmentation: true,
    smoothSegmentation: true,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.5
});
