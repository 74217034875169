import { Button, ConfigProvider, DatePicker, Input } from "antd";
import dayjs, { Dayjs } from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/vi";
import React, { useEffect, useState } from "react";
import viVN from "antd/es/locale/vi_VN";
import "./style.scss";
import { DatePickerProps } from "antd/lib";
import { Circle } from "../study-schedule-calendar/event-content";

dayjs.extend(updateLocale);
dayjs.updateLocale("vi", {
  monthsShort: [
    "Tháng 01",
    "Tháng 02",
    "Tháng 03",
    "Tháng 04",
    "Tháng 05",
    "Tháng 06",
    "Tháng 07",
    "Tháng 08",
    "Tháng 09",
    "Tháng 10",
    "Tháng 11",
    "Tháng 12",
  ],
});

const { RangePicker } = DatePicker;

interface CustomRangePickerProps {
  onChangeRangePicker?: (dates: [Dayjs, Dayjs] | null) => void;
  value?: [Dayjs, Dayjs] | null;
  placeholder?: string;
  height?: any;
  isDelete?: boolean;
  onDelete?: (data: any) => void;
}

const CustomRangePickerCalendar: React.FC<CustomRangePickerProps> = ({
  onChangeRangePicker,
  value = null,
  placeholder = "Chọn ngày cần tìm",
  height,
  isDelete = false,
  onDelete,
}) => {
  const [dates, setDates] = useState<[Dayjs, Dayjs] | null>(value);
  const [tempDates, setTempDates] = useState<[Dayjs, Dayjs] | null>(value);
  const [openRangePicker, setOpenRangePicker] = useState<boolean>(false);

  useEffect(() => {
    setDates(value || null);
    setTempDates(value || null);
  }, [value]);

  const renderDatePickerFooter = () => (
    <>
      <div className="max-day-choose">
        <Circle color={"#D92D20"} />
        <span className="ml-1">Chọn tối đa 07 ngày</span>
      </div>

      <div className="range-picker-footer">
        <div className="flex flex-1 gap-1">
          {tempDates ? (
            <>
              <div className="date-preview">
                <span className="font-size-16">
                  {dayjs(tempDates[0]).format("DD/MM/YYYY")}
                </span>
              </div>
              &nbsp;-&nbsp;
              <div className="date-preview">
                <span className="font-size-16">
                  {dayjs(tempDates[1]).format("DD/MM/YYYY")}
                </span>
              </div>
            </>
          ) : (
            <div></div>
          )}
        </div>
        <div className="btn-footer flex justify-end gap-16">
          <Button
            className="h-40"
            onClick={() => {
              setTempDates(dates);
              setOpenRangePicker(false);
            }}
          >
            Huỷ
          </Button>
          {isDelete ? (
            <Button
              className="btn-danger h-40"
              onClick={() => {
                setDates(null);
                setTempDates(null);
                if (onChangeRangePicker) {
                  onChangeRangePicker(null);
                }
                setOpenRangePicker(false);
                if (onDelete) {
                  onDelete(null);
                }
              }}
            >
              Xóa
            </Button>
          ) : null}
          <Button
            className="btn-primary h-40"
            onClick={() => {
              if (tempDates && tempDates[0] && tempDates[1]) {
                setDates(tempDates);
                if (onChangeRangePicker) {
                  onChangeRangePicker(tempDates);
                }
                setOpenRangePicker(false);
              }
            }}
          >
            Áp dụng
          </Button>
        </div>
      </div>
    </>
  );
  const disabled7DaysDate: DatePickerProps["disabledDate"] = (
    current,
    { from }
  ) => {
    if (from) {
      return Math.abs(current.diff(from, "days")) >= 7;
    }

    return false;
  };
  return (
    <div className="custom-range-picker-p">
      <ConfigProvider locale={viVN}>
        <div className="custom-input" onClick={() => setOpenRangePicker(true)}>
          <Input
            placeholder={placeholder}
            value={
              dates
                ? `${dayjs(dates[0]).format("DD/MM/YYYY")} - ${dayjs(dates[1]).format("DD/MM/YYYY")}`
                : ""
            }
            prefix={
              <img
                src="/assets/icons/calendar.svg"
                alt="search"
                className="search-icon"
              />
            }
            readOnly
          />
        </div>

        <RangePicker
          open={openRangePicker}
          onOpenChange={() => setOpenRangePicker(true)}
          value={tempDates}
          onChange={(dates) => {
            if (dates && dates[0] && dates[1]) {
              setTempDates([dates[0], dates[1]]);
            } else {
              setTempDates(null);
            }
          }}
          disabledDate={disabled7DaysDate}
          format="DD/MM/YYYY"
          renderExtraFooter={renderDatePickerFooter}
          style={{ opacity: 0, height: height }}
          popupClassName="custom-range-popup-p"
        />
      </ConfigProvider>
    </div>
  );
};

export default CustomRangePickerCalendar;
