import React, { useState } from "react";
import { Breadcrumb, Button, Col, Flex, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";
import { DatabaseOutlined } from "@ant-design/icons";
import FilterSearch, { FilterSearchValue } from "../components/FilterSearch";
import { routesConfig } from "../../../../config/routes";
import UnitDiscuss from "./FeedbackQuantityStatistics/UnitDiscuss";
import RateSequenceAction from "./FeedbackQuantityStatistics/RateSequenceAction";
import ActionClass from "./FeedbackQuantityStatistics/ActionClass";
import EvaluateByTime from "./EvaluateQuantityStatistics/EvaluateByTime";
import RateByMaterial from "./EvaluateQuantityStatistics/RateByMaterial";
import RateByCourse from "./EvaluateQuantityStatistics/RateByCourse.";
import RateByLesson from "./EvaluateQuantityStatistics/RateByLesson";
import RateByClass from "./EvaluateQuantityStatistics/RateByClass";
import SearchByKeyword from "./ReportSearch/SearchByKeyword";
import SearchByExam from "./ReportSearch/SearchByExam";
import CountView from "./ReportAction/CountView";
import CountDownload from "./ReportAction/CountDownload";
import CountShare from "./ReportAction/CountShare";
import { unitOpt } from "../../../../constants/statistics";
import { getDateFromNow } from "../components/utils";

function StatisticsReportsDetail() {
  const navigate = useNavigate();
  const [filterValues, setFilterValues] = useState<
    FilterSearchValue | undefined
  >({
    date: [new Date().setDate(new Date().getDate() - 7), new Date()],
    unit: unitOpt[0].value,
  });

  const handleChangeValues = (values: FilterSearchValue | undefined) => {

    if (!values) {
      setFilterValues({
        date: [new Date().setDate(new Date().getDate() - 7), new Date()],
        unit: unitOpt[0].value,
      });
      return
    }

    if (values?.periodOfTime !== -1) {
      setFilterValues({
        ...values,
        date: [getDateFromNow(values?.periodOfTime as number), new Date()],
      });
    } else {
      setFilterValues(values);
    }
  };

  return (
    <div className="statics-report-page">
      <Breadcrumb
        items={[
          {
            href: "",
            title: (
              <img
                key="home-icon"
                src={`${process.env.PUBLIC_URL}/assets/icons/home-line.svg`}
              />
            ),
          },
          {
            title: <Link to={routesConfig.myCourses}>Khoá học của tôi</Link>,
          },
          { title: "Khoá học của tôi" },
        ]}
      />
      <Flex className="report-page-title" justify="space-between">
        <div>
          <h3 className="font-size-30 line-height-38">Thống kê báo cáo</h3>
          <p className="font-size-16 line-height-24 text_secondary">
            Tổng hợp các hoạt động của bạn trên hệ thống
          </p>
        </div>

        <Flex gap={12} className="mt-1">
          <Button
            icon={<DatabaseOutlined style={{ fontSize: 18 }} />}
            className="btn-primary h-40"
            onClick={() => navigate(routesConfig.statisticsReport)}
          >
            <span className="font-weight-6">Chi tiết biểu đồ</span>
          </Button>
        </Flex>
      </Flex>

      <div className="mt-3">
        <FilterSearch onChange={handleChangeValues} />
      </div>

      <div className="mt-2">
        <div className="mb-2">
          <h3 className="font-size-30 line-height-38">
            Thống kê số lượng phản hồi
          </h3>
          <p className="font-size-16 line-height-24 text_secondary">
            Bảng chi tiết thống kê số lượng phản hồi
          </p>
        </div>
        <UnitDiscuss filterValues={filterValues} />
        <Row gutter={[32, 32]} className="mt-3">
          <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
            <RateSequenceAction filterValues={filterValues} />
          </Col>
          <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
            <ActionClass filterValues={filterValues} />
          </Col>
        </Row>
      </div>
      <div className="mt-2">
        <div className="mb-2">
          <h3 className="font-size-30 line-height-38">
            Thống kê số lượng đánh giá
          </h3>
          <p className="font-size-16 line-height-24 text_secondary">
            Bảng chi tiết thống kê số lượng đánh giá
          </p>
        </div>

        <EvaluateByTime filterValues={filterValues} />

        <Row gutter={[32, 32]} className="mt-3">
          <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
            <RateByMaterial filterValues={filterValues} />
          </Col>
          <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
            <RateByCourse filterValues={filterValues} />
          </Col>
        </Row>

        <Row gutter={[32, 32]} className="mt-3">
          <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
            <RateByLesson filterValues={filterValues} />
          </Col>
          <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
            <RateByClass filterValues={filterValues} />
          </Col>
        </Row>
      </div>

      <div className="mt-2">
        <div className="mb-2">
          <h3 className="font-size-30 line-height-38">
            Thống kê số lượng tìm kiếm
          </h3>
          <p className="font-size-16 line-height-24 text_secondary">
            Bảng chi tiết thống kê số lượng tìm kiếm trên hệ thống
          </p>
        </div>
        <Row gutter={[32, 32]} className="mt-3">
          <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
            <SearchByKeyword filterValues={filterValues} />
          </Col>
          <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
            <SearchByExam filterValues={filterValues} />
          </Col>
        </Row>
      </div>

      <div className="mt-2">
        <div className="mb-2">
          <h3 className="font-size-30 line-height-38">
            Thống kê số lượng tải về/xem/chia sẻ học liệu
          </h3>
          <p className="font-size-16 line-height-24 text_secondary">
            Bảng chi tiết thống kê số lượng hoạt động
          </p>
        </div>
        <Row gutter={[32, 32]} className="mt-3">
          <Col xs={24} md={24} lg={24} xl={8} xxl={8}>
            <CountDownload filterValues={filterValues} />
          </Col>
          <Col xs={24} md={24} lg={24} xl={8} xxl={8}>
            <CountShare filterValues={filterValues} />
          </Col>
          <Col xs={24} md={24} lg={24} xl={8} xxl={8}>
            <CountView filterValues={filterValues} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default StatisticsReportsDetail;
