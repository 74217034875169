"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.showPaddingImage = exports.showTransformedImage = void 0;
const opencv_js_1 = require("@techstark/opencv-js");
function showTransformedImage(matFaces) {
    const transformedContainer = document.getElementById('transformedContainer');
    if (!transformedContainer) {
        console.error("transformed container not found");
        return [];
    }
    transformedContainer.innerHTML = '';
    matFaces.forEach((matFace, i) => {
        const canvas = document.createElement('canvas');
        (0, opencv_js_1.imshow)(canvas, matFace);
        transformedContainer.appendChild(canvas);
    });
}
exports.showTransformedImage = showTransformedImage;
function showPaddingImage(matFaces) {
    const transformedContainer = document.getElementById('paddingContainer');
    if (!transformedContainer) {
        console.error("transformed container not found");
        return [];
    }
    transformedContainer.innerHTML = '';
    matFaces.forEach((matFace, i) => {
        const canvas = document.createElement('canvas');
        (0, opencv_js_1.imshow)(canvas, matFace);
        transformedContainer.appendChild(canvas);
    });
}
exports.showPaddingImage = showPaddingImage;
