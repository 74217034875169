import React, { useEffect, useState } from "react";
import "./EnrollmentHistory.scss";
import { Card, Space, Table } from "antd";
import { getEnrollmentHistory } from "../../../service/my-course/my-course-service";
import { useParams } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
type Props = {};

interface EnrollmentHistory {
  code: string;
  createdDate: string;
  endDate: string;
  id: number;
  name: string;
  registrationForm: string;
}
const EnrollmentHistory = (props: Props) => {
  const { id } = useParams();
  const [enrollmentHistory, setEnrollmentHistory] =
    useState<EnrollmentHistory>();

  useEffect(() => {
    const fetchEnrollmentHistory = async () => {
      const res = await getEnrollmentHistory(Number(id));
      setEnrollmentHistory(res?.data.data);
    };
    fetchEnrollmentHistory();
  }, []);
  const columns = [
    {
      title: "Thông tin",
      dataIndex: "info",
      key: "info",
      width: "30%",
    },
    {
      title: "Chi tiết",
      dataIndex: "details",
      key: "details",
    },
  ];

  const data = [
    {
      key: "1",
      info: "Thời gian ghi danh",
      // "01/04/2024 - 14:00:00"
      details:
        moment.utc(enrollmentHistory?.createdDate)?.format("DD/MM/YYYY") +
        " - " +
        moment.utc(enrollmentHistory?.createdDate)?.format("HH:mm:ss"),
    },
    {
      key: "2",
      info: "Thời gian kết thúc khoá học",
      details: (
        <>
          {enrollmentHistory?.endDate ? (
            <>
              {moment.utc(enrollmentHistory?.endDate)?.format("DD/MM/YYYY") +
                " - " +
                moment.utc(enrollmentHistory?.endDate)?.format("HH:mm:ss")}

              <br />
            </>
          ) : undefined}
          {enrollmentHistory?.endDate && "hoặc "}
          Theo lịch giảng viên
        </>
      ),
    },
    {
      key: "3",
      info: "Hình thức ghi danh",
      details: enrollmentHistory?.registrationForm,
    },
  ];
  return (
    <div className="enrollment-history">
      <div className="text-title-18">Lịch sử ghi danh</div>
      <div className="enrollment-history-description">
        Thông tin chi tiết về hoạt động ghi danh của bạn
      </div>
      <div className="hr-tag"></div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        className="enrollment-history-table"
      />
    </div>
  );
};

export default EnrollmentHistory;
