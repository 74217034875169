import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { Card, Dropdown, Image, MenuProps, Space, message } from "antd";
import { useState } from "react";
import "./index.scss";
// import { formatCurrency } from "../../../utils/format";
import DeleteModal from "../../../modal/delete-modal";
import ReviewCourseModal from "../../../modal/review-course-modal";
import { getTimeAgo, renderStarRating } from "../../../utils/renderStarRating";

export interface ReviewCourseCardProps {
  username?: string;
  id?: string;
  imagePath?: string;
  comment?: string;
  star?: string;
  image?: string;
  enterpriseUuid?: string;
  options?: any[];
  handleOkDelete: (id: string) => void;
  getDetailSchool: () => void;
  createdDate?: string;
  isFromCurrentUser?: boolean;
}

const options2: MenuProps["items"] = [
  {
    key: "0",
    label: "Sửa đánh giá",
    icon: <EditOutlined />,
  },
  {
    key: "1",
    label: "Xóa đánh giá",
    icon: <DeleteOutlined />,
    className: "dropdown-delete",
    danger: true,
  },
];

function ReviewCourseCard(props: ReviewCourseCardProps) {
  const {
    image,
    options,
    username,
    imagePath,
    star,
    comment,
    id,
    enterpriseUuid,
    createdDate,
    handleOkDelete,
    getDetailSchool,
    isFromCurrentUser
  } = props;
  const [isOpenDeleteReview, setIsOpenDeleteReview] = useState(false);
  const [isOpenReviewCourseModal, setIsOpenReviewCourseModal] = useState(false);

  const editReviewCourse = () => {
    message.success("Chỉnh sửa đánh giá thành công");
    setIsOpenReviewCourseModal(false);
    getDetailSchool();
  };

  // const renderStarIcon = [1, 2, 3, 4, 5].map((item: any) => (
  //   <Image
  //     preview={false}
  //     className={`start-icon ${!isEmpty(star) && Number(star) < 5 ? "star-gray" : ""}`}
  //     src={`${process.env.PUBLIC_URL}/assets/icons/star.svg`}
  //     alt="card-img"
  //   />
  // ));

  const contentModal = {
    title: "Xác nhận xoá bình luận",
    notice: "Bạn chắc chắn muốn xoá bình luận này?",
  };

  return (
    <div className="review-course-card">
      <Card style={{ width: "100%" }} className="transition-3">
        <div className="card-content">
          <div className="card-img-container flex-col">
            <div className="flex justify-between">
              <div className="flex">
                <Image
                  preview={false}
                  className="card-img"
                  src={imagePath || "./assets/img/card.png"}
                  alt="card-img"
                />
                <div className="flex flex-col ml-4">
                  <b>{username}</b>
                  <div className="flex">
                    {renderStarRating(Number(star))}
                    <p className="ml-3">{getTimeAgo(String(createdDate))}</p>
                  </div>
                </div>
              </div>

              {
                isFromCurrentUser ?
                  <Space className="items-start">
                    <Dropdown
                      menu={{
                        items: options2,
                        onClick: ({ key }) => {
                          if (key === "0") {
                            setIsOpenReviewCourseModal(true);
                          }
                          if (key === "1") {
                            setIsOpenDeleteReview(true);
                          }
                        },
                      }}
                      placement="bottomRight"
                    >
                      <MoreOutlined className="more-outline" />
                    </Dropdown>
                  </Space>
                  : null
              }

            </div>
            <p className="mt-3">{comment}</p>
          </div>
        </div>
      </Card>
      <DeleteModal
        open={isOpenDeleteReview}
        title=""
        mode="edit"
        desc={contentModal}
        handleOk={() => {
          setIsOpenDeleteReview(false);
          setTimeout(() => {
            handleOkDelete(id ?? "");
          }, 200);
        }}
        handleCancel={() => setIsOpenDeleteReview(false)}
      />

      <ReviewCourseModal
        open={isOpenReviewCourseModal}
        title="Chỉnh sửa nhận xét về trường"
        handleCancel={() => setIsOpenReviewCourseModal(false)}
        mode="edit"
        comment={comment}
        star={star}
        id={id}
        enterpriseUuid={enterpriseUuid}
        handleOk={() => editReviewCourse()}
      />
    </div>
  );
}

export default ReviewCourseCard;
