export const DropdownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M12.8125 3H11.6406C11.561 3 11.486 3.03906 11.4391 3.10313L7.00001 9.22188L2.56095 3.10313C2.51408 3.03906 2.43908 3 2.35939 3H1.18751C1.08595 3 1.02658 3.11563 1.08595 3.19844L6.59533 10.7937C6.79533 11.0687 7.2047 11.0687 7.40314 10.7937L12.9125 3.19844C12.9735 3.11563 12.9141 3 12.8125 3Z"
      fill="#1677FF"
    />
  </svg>
);

export const LikeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5.83334 18.3327V9.16602M1.66667 10.8327V16.666C1.66667 17.5865 2.41286 18.3327 3.33334 18.3327H14.5219C15.7558 18.3327 16.8052 17.4324 16.9928 16.2128L17.8902 10.3795C18.1232 8.8651 16.9515 7.49935 15.4193 7.49935H12.5C12.0398 7.49935 11.6667 7.12625 11.6667 6.66602V3.72088C11.6667 2.58601 10.7467 1.66602 9.61181 1.66602C9.34112 1.66602 9.09582 1.82543 8.98589 2.07278L6.05329 8.67113C5.91954 8.97207 5.6211 9.16602 5.29178 9.16602H3.33334C2.41286 9.16602 1.66667 9.91221 1.66667 10.8327Z"
      stroke="#475467"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DislikeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M14.1667 1.66602V10.8327M18.3333 8.16602V4.33268C18.3333 3.39926 18.3333 2.93255 18.1517 2.57603C17.9919 2.26243 17.7369 2.00746 17.4233 1.84767C17.0668 1.66602 16.6001 1.66602 15.6667 1.66602H6.76501C5.54711 1.66602 4.93816 1.66602 4.44632 1.88888C4.01284 2.0853 3.64442 2.40137 3.38437 2.79995C3.08931 3.25218 2.99672 3.85405 2.81153 5.05779L2.37563 7.89112C2.13137 9.47878 2.00925 10.2726 2.24484 10.8903C2.45162 11.4324 2.84054 11.8858 3.34494 12.1726C3.91961 12.4993 4.72278 12.4993 6.32912 12.4993H7C7.46671 12.4993 7.70007 12.4993 7.87833 12.5902C8.03513 12.6701 8.16261 12.7976 8.24251 12.9544C8.33334 13.1326 8.33334 13.366 8.33334 13.8327V16.2778C8.33334 17.4127 9.25333 18.3327 10.3882 18.3327C10.6589 18.3327 10.9042 18.1733 11.0141 17.9259L13.8148 11.6245C13.9421 11.3379 14.0058 11.1946 14.1065 11.0895C14.1955 10.9966 14.3048 10.9256 14.4258 10.882C14.5627 10.8327 14.7195 10.8327 15.0332 10.8327H15.6667C16.6001 10.8327 17.0668 10.8327 17.4233 10.651C17.7369 10.4912 17.9919 10.2363 18.1517 9.92267C18.3333 9.56615 18.3333 9.09944 18.3333 8.16602Z"
      stroke="#475467"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FeedbackIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M18 10C18 14.1421 14.6421 17.5 10.5 17.5C9.50238 17.5 8.55025 17.3052 7.67958 16.9516C7.51294 16.8839 7.42962 16.8501 7.36227 16.835C7.29638 16.8202 7.24763 16.8148 7.18011 16.8148C7.11109 16.8148 7.03591 16.8273 6.88554 16.8524L3.92063 17.3466C3.61015 17.3983 3.45491 17.4242 3.34265 17.376C3.2444 17.3339 3.16611 17.2556 3.12397 17.1573C3.07582 17.0451 3.10169 16.8898 3.15344 16.5794L3.64759 13.6145C3.67265 13.4641 3.68518 13.3889 3.68517 13.3199C3.68516 13.2524 3.67976 13.2036 3.665 13.1377C3.6499 13.0704 3.61606 12.9871 3.54839 12.8204C3.19478 11.9497 3 10.9976 3 10C3 5.85786 6.35786 2.5 10.5 2.5C14.6421 2.5 18 5.85786 18 10Z"
      stroke="#475467"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const LikeActiveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16.9928 16.2128C16.8051 17.4324 15.7558 18.3327 14.5218 18.3327H3.33329C2.41282 18.3327 1.66663 17.5865 1.66663 16.666V10.8327C1.66663 9.91221 2.41282 9.16602 3.33329 9.16602H5.29173C5.62106 9.16602 5.91949 8.97207 6.05324 8.67113L8.98584 2.07278C9.09578 1.82543 9.34107 1.66602 9.61176 1.66602C10.7466 1.66602 11.6666 2.58601 11.6666 3.72088V6.66602C11.6666 7.12625 12.0397 7.49935 12.5 7.49935H15.4193C16.9515 7.49935 18.1232 8.8651 17.8902 10.3795L16.9928 16.2128Z"
      fill="#F4EBFF"
    />
    <path
      d="M5.83329 18.3327V9.16602M1.66663 10.8327V16.666C1.66663 17.5865 2.41282 18.3327 3.33329 18.3327H14.5218C15.7558 18.3327 16.8051 17.4324 16.9928 16.2128L17.8902 10.3795C18.1232 8.8651 16.9515 7.49935 15.4193 7.49935H12.5C12.0397 7.49935 11.6666 7.12625 11.6666 6.66602V3.72088C11.6666 2.58601 10.7466 1.66602 9.61176 1.66602C9.34107 1.66602 9.09578 1.82543 8.98584 2.07278L6.05324 8.67113C5.91949 8.97207 5.62106 9.16602 5.29173 9.16602H3.33329C2.41282 9.16602 1.66663 9.91221 1.66663 10.8327Z"
      stroke="#7F56D9"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DislikeActiveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M2.81153 5.05779C2.99672 3.85405 3.08931 3.25218 3.38437 2.79995C3.64442 2.40137 4.01284 2.0853 4.44632 1.88888C4.93816 1.66602 5.54711 1.66602 6.76501 1.66602H15.6667C16.6001 1.66602 17.0668 1.66602 17.4233 1.84767C17.7369 2.00746 17.9919 2.26243 18.1517 2.57603C18.3333 2.93255 18.3333 3.39926 18.3333 4.33268V8.16602C18.3333 9.09944 18.3333 9.56615 18.1517 9.92267C17.9919 10.2363 17.7369 10.4912 17.4233 10.651C17.0668 10.8327 16.6001 10.8327 15.6667 10.8327H15.0332C14.7195 10.8327 14.5627 10.8327 14.4258 10.882C14.3048 10.9256 14.1955 10.9966 14.1065 11.0895C14.0058 11.1946 13.9421 11.3379 13.8148 11.6245L11.0141 17.9259C10.9042 18.1733 10.6589 18.3327 10.3882 18.3327C9.25333 18.3327 8.33334 17.4127 8.33334 16.2778V13.8327C8.33334 13.366 8.33334 13.1326 8.24251 12.9544C8.16261 12.7976 8.03513 12.6701 7.87833 12.5902C7.70007 12.4993 7.46671 12.4993 7 12.4993H6.32912C4.72278 12.4993 3.91961 12.4993 3.34494 12.1726C2.84054 11.8858 2.45162 11.4324 2.24484 10.8903C2.00925 10.2726 2.13137 9.47878 2.37563 7.89112L2.81153 5.05779Z"
      fill="#F4EBFF"
    />
    <path
      d="M14.1667 1.66602V10.8327M18.3333 8.16602V4.33268C18.3333 3.39926 18.3333 2.93255 18.1517 2.57603C17.9919 2.26243 17.7369 2.00746 17.4233 1.84767C17.0668 1.66602 16.6001 1.66602 15.6667 1.66602H6.76501C5.54711 1.66602 4.93816 1.66602 4.44632 1.88888C4.01284 2.0853 3.64442 2.40137 3.38437 2.79995C3.08931 3.25218 2.99672 3.85405 2.81153 5.05779L2.37563 7.89112C2.13137 9.47878 2.00925 10.2726 2.24484 10.8903C2.45162 11.4324 2.84054 11.8858 3.34494 12.1726C3.91961 12.4993 4.72278 12.4993 6.32912 12.4993H7C7.46671 12.4993 7.70007 12.4993 7.87833 12.5902C8.03513 12.6701 8.16261 12.7976 8.24251 12.9544C8.33334 13.1326 8.33334 13.366 8.33334 13.8327V16.2778C8.33334 17.4127 9.25333 18.3327 10.3882 18.3327C10.6589 18.3327 10.9042 18.1733 11.0141 17.9259L13.8148 11.6245C13.9421 11.3379 14.0058 11.1946 14.1065 11.0895C14.1955 10.9966 14.3048 10.9256 14.4258 10.882C14.5627 10.8327 14.7195 10.8327 15.0332 10.8327H15.6667C16.6001 10.8327 17.0668 10.8327 17.4233 10.651C17.7369 10.4912 17.9919 10.2363 18.1517 9.92267C18.3333 9.56615 18.3333 9.09944 18.3333 8.16602Z"
      stroke="#7F56D9"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
