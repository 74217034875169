import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const getTrainingSchool: (
  slug: string | undefined
) => Promise<AxiosResponse<any>> = (slug) => {
  return axiosConfigV2.get(`/open-api/enterprise/get-enterprise/${slug}`);
};

export const createCommentReview: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/api/university-review/comment`, data);
};

export const editCourseCommentReview: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/api/university-review/edit`, data);
};

export const deleteUniversityReviewReview: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.delete(`/api/university-review/delete`, { data });
};

export const getAllCommentReview: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/open-api/comment/university`, data);
};

export const exportSchoolReview: (
  payload?: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(`/v2/open-api/comment/university/export-excel`, payload, {
    responseType: "arraybuffer",
  });
};


export const getAllCurriculum: (slug: string) => Promise<AxiosResponse<any>> = (
  slug
) => {
  return axiosConfigV2.get(
    `open-api/enterprise/get-top-curriculum-by-enterprise/${slug}`
  );
};

export const getAllCourse: (slug: string) => Promise<AxiosResponse<any>> = (
  slug
) => {
  return axiosConfigV2.get(
    `open-api/enterprise/get-top-course-by-enterprise/${slug}`
  );
};
