import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { useDirty } from "../contexts/DirtyProvider";
import { cancelExam } from "../service/learning-course";
import useKeyword from "./useKeyword";
import { toString } from "lodash";

interface UsePreventRouteChange {
  confirmNavigation: (path: string) => void;
  confirmRedirect: (next: (v?: any) => void) => void;
}

export const usePreventRouteChange = (): UsePreventRouteChange => {
  const sessionId = useKeyword("sessionId");
  const { isDirty, setDirty } = useDirty();

  const navigate = useNavigate();
  const [pendingPath, setPendingPath] = useState<string | null>(null);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = "Rời khỏi trang?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [pendingPath, isDirty, navigate]);

  useEffect(() => {
    if (pendingPath && !isDirty) {
      navigate(pendingPath);
      setPendingPath(null); // Clear the pending path after navigating
    }
  }, [pendingPath, isDirty, navigate]);

  const confirmNavigation = (path: string) => {
    if (isDirty) {
      Modal.confirm({
        title: "Rời khỏi trang?",
        content: "Các thay đổi bạn đã thực hiện có thể không được lưu.",
        okText: "Rời khỏi",
        cancelText: "Huỷ",
        onOk: () => {
          setDirty(false);
          setPendingPath(path);
          if (sessionId) cancelExam(toString(sessionId));
        },
        onCancel() {
          // Optional: Handle the cancel event
        },
      });
    } else {
      navigate(path);
    }
  };

  const confirmRedirect = (next = () => {}) => {
    if (isDirty) {
      Modal.confirm({
        title: "Rời khỏi trang?",
        content: "Các thay đổi bạn đã thực hiện có thể không được lưu.",
        okText: "Rời khỏi",
        cancelText: "Huỷ",
        onOk: () => {
          setDirty(false);
          next();
          if (sessionId) cancelExam(toString(sessionId));
        },
        onCancel() {
          // Optional: Handle the cancel event
        },
      });
    } else {
      next();
      setDirty(false);
    }
  };

  return { confirmNavigation, confirmRedirect };
};
