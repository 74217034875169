import { useEffect, useState } from 'react';
import { Checkbox, Input, Space, Divider } from 'antd';
import { useSurveyRespond } from '../../../../../../../stores/stores';

const QuestionType5 = (props: any) => {
  const questionAnswer = props.questionAnswer;
  const { updateAnswer } = useSurveyRespond();
  const [selectedOptions, setSelectedOptions] = useState(new Set());
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleCheckboxChange = (optionId: any) => {
    setIsOtherSelected(false)
    setSelectedOptions((prevSelectedOptions) => {
      const newSelection = new Set(prevSelectedOptions);
      if (newSelection.has(optionId)) {
        newSelection.delete(optionId);
      } else {
        newSelection.add(optionId);
      }
      return newSelection;
    });
  };

  const handleOtherChange = (e: any) => {
    setSelectedOptions(new Set())
    setIsOtherSelected(e.target.checked);
  };

  const handleChangeInput = (e: any) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (Array.isArray(questionAnswer)) {
      setSelectedOptions(new Set(questionAnswer));
    } else if (typeof questionAnswer === "string") {
      setIsOtherSelected(true);
      setInputValue(questionAnswer);
    }
  }, []);

  useEffect(() => {
    const selectedOptionsArray = Array.from(selectedOptions);
    updateAnswer(props.surveyId, props.sectionId, props.questionId, selectedOptionsArray)
  }, [selectedOptions]);

  useEffect(() => {
    updateAnswer(props.surveyId, props.sectionId, props.questionId, inputValue)
  }, [inputValue]);

  return (
    <div style={{ width: '100%' }}>
      <Space direction="vertical" size="small" className="surveyQuestion" style={{ width: '100%' }}>
        {props.listOptions.map((option: any) => (
          !option.isOther && (
            <Checkbox
              key={option.answerId}
              checked={selectedOptions.has(option.answerId)}
              onChange={() => handleCheckboxChange(option.answerId)}
              disabled={props.typeSurvey === "2"}
            >
              {option.answerText}
            </Checkbox>
          )
        ))}
        {props.listOptions.map((option: any) => (
          option.isOther && (
            <>
              <Checkbox
                checked={isOtherSelected}
                onChange={handleOtherChange}
                disabled={props.typeSurvey === "2"}
              >
                Khác
              </Checkbox>
              {isOtherSelected && (
                <Input
                  placeholder="Vui lòng nhập..."
                  value={inputValue}
                  onChange={handleChangeInput}
                  style={{ marginTop: '12px', marginBottom: '12px' }}
                  disabled={props.typeSurvey === "2"}
                />
              )}
              {!isOtherSelected && <Divider style={{ margin: '12px 0' }} />}
            </>
          )
        ))}
      </Space>
    </div>
  );
};

export default QuestionType5;
