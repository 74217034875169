import { message, notification } from "antd";
import type { NotificationArgsProps } from 'antd';
type NotificationPlacement = NotificationArgsProps['placement'];
export class HelpService {
  configNotification = {
    duration: 3,
    className: 'toast-notification',
  }

  configMessage = {
    className: 'toast-message',
  };

  defaultPostionToast: NotificationPlacement = 'topRight';

  constructor() { }

  successNotification(message: string, placement: NotificationPlacement = this.defaultPostionToast) {
    notification.success({
      message: message,
      duration: this.configNotification.duration,
      className: this.configNotification.className,
      placement: placement,
    });
  }

  errorNotification(message: string, placement: NotificationPlacement = this.defaultPostionToast) {
    notification.error({
      message: message,
      duration: this.configNotification.duration,
      className: this.configNotification.className,
      placement: placement,
    });
  }

  successMessage(content: string) {
    message.open({
      type: 'success',
      content: content,
      className: this.configMessage.className,
    });
  }

  errorMessage(content: string) {
    message.open({
      type: 'error',
      content: content,
      className: this.configMessage.className,
    });
  }

  setTitleTabBrower(title: string) {
    const element = document.getElementById("title-tab-brower");
    if (element) {
      element.innerHTML = title;
    }
  }
}
