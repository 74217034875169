import React, { useEffect, useState } from "react";
import MoocBreadcrumb from "../../../components/breadcrumb";
import { HomeOutlined } from "@ant-design/icons";
import { routesConfig } from "../../../config/routes";
import "./style.scss";
import { KeyValue } from "../../../types/app";
import { getStatisticalUniversitiesByYear } from "../../../service/statistics";
import { CourseDataChart } from "../../../types/statistic";
import CourseChart from "../../../components/charts/CourseChart";

import { StatisticsCardProps } from "../../../components/card/statistic-card";
import "../style.scss";
import { exportExcelListEnterprise } from "../../../service/university";

function StatisticsUniversities() {
  const [universities, setUniversities] = useState<CourseDataChart[]>([]);
  const [filterValueByYear, setFilterValueByYear] = useState<
    string | undefined
  >();
  const breadcrumb = [
    {
      href: "",
      title: <HomeOutlined />,
    },
    {
      href: routesConfig.searchSchool,
      title: (
        <span className="breadcrumb-link">
          Danh sách trường đại học liên kết
        </span>
      ),
    },
    {
      href: routesConfig.statisticsCourses,
      title: (
        <span className="breadcrumb-link breadcrumb-link-active">
          <b> Thống kê số lượng trường học</b>
        </span>
      ),
    },
  ];

  const options: KeyValue[] = [
    { label: "Mặc định", value: 0 },
    { label: "Thấp đến cao", value: 1 },
    { label: "Cao đến thấp", value: 2 },
  ];

  const statistic: StatisticsCardProps[] = [
    {
      id: 1,
      total: "50+",
      title: "Trường đại học liên kết",
      description: "Hệ thống Mooc có các trường học tốt và hot nhất hiện nay",
    },
    {
      id: 2,
      total: "20k+",
      title: "Khoá học hiện có",
      description:
        "Số lượng khoá học trên hệ thống Mooc được cập nhật liên tục",
    },
    {
      id: 3,
      total: "10k+",
      title: "Nguồn tài nguyên học tập",
      description:
        "Tài nguyên hữu ích được cung cấp trong các khoá học trên Mooc",
    },
    {
      id: 4,
      total: "200+",
      title: "Đánh giá 5 sao",
      description:
        "Khoá học trên hệ thống Mooc được đánh giá cao từ người dùng",
    },
  ];

  useEffect(() => {
    const getChartData = async () => {
      try {
        const res = await getStatisticalUniversitiesByYear({
          type: 1,
          sortByValue: filterValueByYear,
        });
        setUniversities(res.data);
      } catch (err) {
        console.error(err);
      }
    };
    getChartData();
  }, [filterValueByYear]);

  const handleChangeSort = (value: number) => {
    switch (value) {
      case 0:
        setFilterValueByYear(undefined);
        break;
      case 1:
        setFilterValueByYear("asc");
        break;
      case 2:
        setFilterValueByYear("desc");
        break;
      default:
        setFilterValueByYear(undefined);
        break;
    }
  };

  const handleExportExcel = async () => {
    try {
      const res = await exportExcelListEnterprise();
      const fileData = res.data;

      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);

      downloadLink.download = "Danh sách trường.xlsx";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  return (
    <div className="mb-5">
      <section className="content-layout">
        <MoocBreadcrumb items={breadcrumb} />
      </section>
      <section className="content-layout statistics-banner mb-5">
        <p className="text-primary mb-1">
          <b>Danh sách trường đại học liên kết</b>
        </p>
        <div className="flex justify-space-between align-center gap-24 banner-content">
          <h3 className="font-size-48 font-weight-6 mt-1">
            Thống kế số lượng trường
          </h3>
          <span className="text-secondary font-size-20 line-height-24">
            Tổng hợp các thông số về số trường đại học liên kết hiện có trên hệ
            thống Mooc
          </span>
        </div>
      </section>
      {/* <section className="statistic-university">
        <ResponsiveList
          grid={{
            gutter: 32,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 4,
            xl: 4,
            xxl: 4,
          }}
          data={statistic.map((item: StatisticsCardProps) => ({
            content: <StatisticsCard {...item} />,
          }))}
        />
      </section> */}
      <section className="content-layout">
        <CourseChart
          data={universities}
          xKey="name"
          yKey="value"
          tooltipLabel="Số lượng"
          selectOptions={options}
          title="Số lượng trường theo các năm"
          description="Số lượng trường liên kết với hệ thống Mooc theo các từ 2024 đến nay"
          xDescription="Tất cả chuyên ngành"
          onChangeSort={handleChangeSort}
          onExportExcel={handleExportExcel}
        />
      </section>
    </div>
  );
}

export default StatisticsUniversities;
