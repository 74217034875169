import { AxiosResponse } from "axios";
import { axiosConfigSupervisorToUpload } from "../../config/api/configApiSupervisor";
import { axiosConfigV3 } from "../../config/api/configApiV3";

export const submitAttendanceReport: (payload: {
  course_block_id: string;
  teacher_id?: number;
  report_id?: number;
  is_attendance?: boolean;
  data?: any;
}) => Promise<AxiosResponse<void>> = (payload: {
  course_block_id: string;
  teacher_id?: number;
  report_id?: number;
  is_attendance?: boolean;
  data?: {
    result: boolean;
    data: { is_attendance: boolean; times: string }[];
  };
}) => {
  return axiosConfigV3.post(`/attendance-report/create-by-student`, payload);
};
export const getAttendanceSetting: (
  blockId: string
) => Promise<AxiosResponse<any>> = (blockId: string) => {
  return axiosConfigSupervisorToUpload.get(
    `/setting-attendance/get/${blockId}`
  );
};
