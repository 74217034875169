import {
  Button,
  DatePicker,
  DatePickerProps,
  Dropdown,
  Input,
  MenuProps,
  message,
  Select,
  Space,
  Spin,
  Tabs,
  TabsProps,
} from "antd";
import MoocBreadcrumb from "../../../components/breadcrumb";
import { routesConfig } from "../../../config/routes";
import "../styles.scss";
import { DownOutlined, HeartOutlined, SearchOutlined } from "@ant-design/icons";
import { TopicItem } from "./topic-item";
import {
  followAll,
  getAllAuthorTopic,
  postAllTopicByBlog,
} from "../../../service/blog/blog";
import { SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { stripHtmlTags } from "../../../utils/format";
import { filterOption } from "../../../utils/element";
import Search from "antd/es/input/Search";

interface BlogData {
  title: string;
  id: number;
  name: string;
  description: string;
  enterpriseUuid: string;
  status: number;
  imagePath: string;
  createdBy: number;
  createdDate: string;
  updatedDate: string;
  amountTopic: number;
}

export const TopicComponent = () => {
  const [page, setPage] = useState<number>(1);
  const [newPage, setNewPage] = useState(false);
  const [pageSize, setPageSize] = useState<number>(6);
  const [topicData, setTopicData] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("blogId");
  const uuidValue = searchParams.get("uuid");
  const slugValue = searchParams.get("slug");
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const blogState = location.state;
  const [searchValue, setSearchValue] = useState("");
  const [sortByView, setSortByView] = useState(false);
  const [sortByName, setSortByName] = useState(false);
  const [selectDate, setSelectDate] = useState<Date>();
  const [author, setAuthor] = useState(0);
  const [authorList, setAuthorList] = useState<any[]>([]);
  const [blogData, setBlogData] = useState<BlogData>();
  const [lastDiscuss, setLastDiscuss] = useState(false);

  const handleGetTopic = async () => {
    setLoading(true);
    try {
      const dataParam = {
        page: page,
        size: pageSize,
        sort: sortByName?  [`name, desc`]: null,
        blogId: id,
        keyword: search,
        sortByView: sortByView ? "desc" : "",
        userId: author == 0 ? null : author,
        sortByLastDiscussDate: lastDiscuss ? "desc" : "",
        createdDate: selectDate,
      };
      const res = postAllTopicByBlog(dataParam).then((result) => {
        setTotal(result.data.data.total);
        setBlogData(result.data.data.blogData);
        if (newPage) {
          setTopicData((prevItems) => [
            ...prevItems,
            ...result.data.data.moocUniversityTopicData,
          ]);
          setLoading(false);
          setNewPage(false);
        } else {
          setTopicData(result.data.data.moocUniversityTopicData);
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const handleGetAllAuthorTopic = async () => {
    setLoading(true);
    try {
      getAllAuthorTopic().then((result) => {
        setLoading(false);
        const newArray = convertArrayAuthor(result.data.data);
        setAuthorList(newArray);
      });
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const handleLoadMore = () => {
    setNewPage(true);
    setPage((prevPage) => prevPage + 1);
  };
  const handleNavigate = (item: any, uuid: any) => {
    const newPath = `comment?topicId=${item.id}&uuid=${uuid}&slug=${slugValue}&blogId=${id}`;
    navigate(newPath, { state: item });
  };

  const onShowSizeChange = (current: any, pageSize: SetStateAction<number>) => {
    setPageSize(pageSize);
    setPage(1); // Reset to the first page when items per page changes
  };

  const convertArrayAuthor = (arr: any[]) =>
    arr.map((item: { id: number; name: string }) => ({
      value: item.id,
      label: item.name,
    }));

  useEffect(() => {
    handleGetAllAuthorTopic();
  }, []);

  useEffect(() => {
    handleGetTopic();
  }, [
    id,
    page,
    pageSize,
    search,
    sortByName,
    sortByView,
    selectDate,
    lastDiscuss,
    author,
  ]);

  const breadcrumbItem = [
    {
      href: "",
      title: (
        <img src={`${process.env.PUBLIC_URL}/assets/icons/home-line.svg`} />
      ),
    },
    {
      href: `${routesConfig.searchSchool}/${routesConfig.universityTemplateDetail}/${routesConfig.blog}?uuid=${uuidValue}&slug=${slugValue}`,
      title: (
        <>
          <span>Danh sách bài đăng</span>
        </>
      ),
    },
    { title: `${stripHtmlTags(blogData?.name || "Tiêu đề bài đăng")}` },
  ];

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div className="flex align-center">
          Tất cả chủ đề
          <div className="total">{total}</div>
        </div>
      ),
      children: loading ? (
        <div className="view-more">
          <Spin />
        </div>
      ) : topicData.length !== 0 ? (
        <TopicItem
          setPageSize={onShowSizeChange}
          onPageChange={setPage}
          page={page}
          pageSize={pageSize}
          total={total}
          items={topicData}
          handleLoadMore={handleLoadMore}
          handleNavigate={handleNavigate}
        />
      ) : (
        <h3>Không có kết quả</h3>
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex align-center">
          Chủ đề của tôi
          <div className="total">{0}</div>
        </div>
      ),
      children: "",
    },
  ];
  const options: MenuProps["items"] = [
    {
      key: "0",
      label: "Bảng chữ cái",
      onClick: () => {
        setSortByName(true)
        setSortByView(false)
        setLastDiscuss(false)
      }
    },
    {
      key: "1",
      label: "Lượt xem",
      onClick: () => {
        setSortByName(false)
        setSortByView(true)
        setLastDiscuss(false)
      }
    },
    {
      key: "2",
      label: "Xem gần đây",
      onClick: () => {
        setSortByName(false)
        setSortByView(false)
        setLastDiscuss(true)
      }
    },
  ];

  const followAllTopic = async () => {
    const data = {
      isFollowAll: true,
    };
    await followAll(data).then((res) => {
      if (res.status === 200) {
        message.success("Đã theo dõi tất cả các chủ đề");
        handleGetTopic();
      }
    });
  };

  const onChangeKey = (key: string) => {};
  const tabBarExtraContent = () => {
    return (
      <div className="btn-container">
        <Button
          onClick={() => {
            followAllTopic();
          }}
          className="add-button"
        >
          <HeartOutlined />
          Theo dõi tất cả chủ đề
        </Button>
        <Dropdown
          menu={{
            items: options,
            selectable: true,
          }}
          placement="bottom"
          arrow
        >
          <Button onClick={() => {}} className="add-button">
            Sắp xếp theo
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    );
  };

  const onChangeDate: DatePickerProps["onChange"] = (date, dateString) => {
    const newDate = new Date(dateString as string);
    setSelectDate(newDate);
    setPage(1);
  };

  const onChangeSelect = (value: string) => {
    setAuthor(Number(value));
    setPage(1);
  };

  const onSearchSelect = (value: string) => {
    console.log("search:", value);
  };

  return (
    <div className="blog-comment">
      <div className="container">
        {/* Header */}
        <div className="header-topic">
          <div className="header-left">
            <div className="header-breadcrumb">
              <MoocBreadcrumb
                items={breadcrumbItem}
                className={"header-breadcrumb"}
              />
            </div>
            <div className="text-4xl mt-2 mb-2 learning-title font-semibold">
              {stripHtmlTags(blogData?.name || "Bài đăng của tôi")}
            </div>
            <p className="header-2">Tổng hợp các chủ đề thuộc bài đăng</p>
            <div className="filter-container">
              <div className="search-box">
                <p>Tìm kiếm</p>
                <Space direction="vertical" size="middle">
                  <Space.Compact style={{ width: "100%" }}>
                    <Input
                      onChange={(e) => setSearchValue(e.target.value)}
                      onPressEnter={() => {
                        setSearch(searchValue);
                        setPage(1);
                      }}
                      allowClear
                      // addonAfter={<SearchOutlined />}
                      placeholder="Nhập từ khóa tìm kiếm"
                    />
                    <Button
                      onClick={() => {
                        setSearch(searchValue);
                        setPage(1);
                      }}
                      className="btn-search"
                      type="primary"
                    >
                      Tìm
                    </Button>
                  </Space.Compact>
                </Space>
              </div>
              <div className="">
                <p>Ngày tạo</p>
                <DatePicker
                  className="filter-date-bar"
                  placeholder="Chọn ngày"
                  onChange={onChangeDate}
                />
              </div>
              <div>
                <p>Người tạo</p>
                <Select
                  allowClear
                  className="filter-bar"
                  showSearch
                  placeholder="Chọn người tạo"
                  optionFilterProp="label"
                  onChange={onChangeSelect}
                  filterOption={filterOption}
                  options={authorList}
                />
              </div>
            </div>
          </div>
          {/* Img */}
          <div className="header-right">
            <img className="" src={blogData ? blogData.imagePath : undefined} />
          </div>
        </div>
        <div>
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChangeKey}
            tabBarExtraContent={tabBarExtraContent()}
          />
        </div>
      </div>
    </div>
  );
};
